export const GetItemColsPerWidth = () => {
    const _width = window.innerWidth;
    // let _scrollWidth = window.document.body.scrollWidth;
    // console.log('with : ', _width, ' : ', _scrollWidth, ' : ', window.innerWidth, )//window.resize()
    if (_width <= 610) {
        return 1;
    }
    else if (_width > 610 && _width <= 910) {
        return 2;
    }
    else if (_width > 910 && _width <= 1200) {
        return 3;
    }
    else {
        return 4;
    }
    // 5
    // else if (_width > 1200 && _width <= 1900) return 4;
};
export const isMobile = () => {
    // console.log('ismobile : ', navigator.userAgent)
    const checkRows = (GetItemColsPerWidth() === 1);
    if (checkRows) {
        return checkRows;
    }
    else if (navigator.userAgent.match(/(iPad|Android)/i) && (window.innerWidth > 800)) {
        return false;
    }
    else {
        return (navigator.userAgent.match(/(Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i)) ?
            true : false;
    }
};
export const isTablet = () => {
    const _tablet = (navigator.userAgent.match(/(iPad|Android)/i) && (window.innerWidth > 720)) ? true : false;
    return (_tablet && isMobile());
};
var Browser;
(function (Browser) {
    Browser["MSIE"] = "MSIE";
    Browser["Firefox"] = "Firefox";
    Browser["Safari"] = "Safari";
    Browser["Chrome"] = "Chrome";
    Browser["Opera"] = "Opera";
})(Browser || (Browser = {}));
export const isFirefox = () => (navigator.userAgent.indexOf(Browser.Firefox) > -1);
export const isChrome = () => (navigator.userAgent.indexOf(Browser.Chrome) > -1);
export const isOpera = () => (navigator.userAgent.indexOf(Browser.Opera) > -1);
export const isSafari = () => (Boolean(navigator.vendor.match(/apple/gi)));
// return (navigator.userAgent.indexOf(Browser.Safari) > -1);
