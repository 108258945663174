import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import FlexView from 'react-flexview/lib';
import { remove, path } from "ramda";
import AddImage from "./AddImage";
import PreviewImage from "./PreviewImage";
const styles = () => ({
    input: {
        display: 'none',
    },
});
class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
        if (typeof props.getRef === 'function') {
            props.getRef(this);
        }
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    onFileAdded(file) {
        this.onFilesChange(this.state.files.concat(file));
    }
    onFileRemoved(index) {
        this.onFilesChange(remove(index, 1, this.state.files));
    }
    onFilesChange(files) {
        this.setState({ files }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(files);
            }
        });
    }
    onClickAdd() {
        this.inputRef.click();
    }
    render() {
        const { accept, classes, multiple = false, hideAddButton = false } = this.props;
        return (<div>
                <FlexView wrap>
                    {this.renderPreview()}
                    {this.renderDefault()}
                    {!hideAddButton && this.renderAdd()}
                </FlexView>
                <input ref={(ref) => this.inputRef = ref} accept={accept} className={classes.input} type="file" onChange={this.onFileChange} multiple={multiple}/>
            </div>);
    }
    renderDefault() {
        return (this.props.defaultImages || []).map((each, index) => {
            const key = `preview${index}`;
            return (<PreviewImage key={key} alt={key} removable={false} 
            // onClick={() => this.onFileRemoved(index)}
            src={each}/>);
        });
    }
    renderPreview() {
        return this.state.files.map((each, index) => {
            const key = `preview${index}`;
            return (<PreviewImage key={key} alt={key} onClick={() => this.onFileRemoved(index)} src={each.base64Image}/>);
        });
    }
    renderAdd() {
        return <AddImage onClick={this.onClickAdd}/>;
    }
    onFileChange(event) {
        const files = event.target.files;
        // const { accept } = this.props;
        // let wrongType = false;
        // for (const file of files) {
        //     wrongType = wrongType || accept.indexOf(file.type) === -1;
        // }
        // console.warn(files);
        // if (wrongType) {
        //     console.warn('wrong file type');
        // } else {
        if (files.length > 0) {
            const reader = new FileReader();
            const readFile = (index = 0) => {
                reader.onloadend = (e) => {
                    this.onFileAdded({
                        file: files[index],
                        base64Image: path(['target', 'result'], e),
                    });
                    if (index < files.length - 1) {
                        readFile(index + 1);
                    }
                    else {
                        this.inputRef.value = null;
                    }
                };
                reader.readAsDataURL(files[index]);
            };
            readFile();
        }
        // }
    }
}
export default withStyles(styles)(FileUploader);
