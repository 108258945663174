import * as React from 'react';
import Google from 'react-google-login';
import config from '../../../config';
import { Svg, Fonts } from "../../../themes";
import RaisedButton from '../RaisedButton';
import { userServices } from "../../../services";
import { socialType } from "../../../constant";
import localization from "../../../services/localization";
const GoogleLogin = (props) => {
    return (<Google tag="div" clientId={config.social.google.clientId} onSuccess={(res) => { userServices.onSocialCallback(res, socialType.GOOGLE, props.handleLoginSuccess); }} onFailure={() => { }} style={{
        backgroundColor: 'transparent',
        display: 'inline',
        padding: 0,
        width: '100%',
    }}>
            <RaisedButton buttonStyle={{ backgroundColor: '#fff', padding: '0 20px', width: '100%' }} label={props.label || localization(`sign_in_with_google`, `AuthenLabel`)} labelStyle={{
        color: 'rgba(0, 0, 0, 0.54)',
        fontFamily: Fonts.family.avenir,
        fontSize: '14px',
        fontWeight: 900,
        letterSpacing: '.21px',
        margin: '0 8px',
    }} onClick={() => localStorage.setItem('loginGoogle', "true")} icon={(<div style={{
        alignItems: 'center',
        display: 'flex',
        height: '40px',
        margin: '0 8px',
        width: '22px',
    }}>
                        <Svg.google style={{ width: '22px', height: '22px' }}/>
                    </div>)}/>
        </Google>);
};
export default GoogleLogin;
