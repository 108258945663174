import React from 'react';
import { path } from "ramda";
const RescaleImage = (props) => {
    const { scale } = props;
    return (<img {...props} onLoad={(e) => {
        if (!!scale) {
            const imgWidth = path(['target', 'naturalWidth'], e) || 0;
            // const imgHeight = path(['target', 'naturalHeight'], e) || 0;
            const imgStyle = path(['target', 'style'], e);
            imgStyle.width = imgWidth * scale + 'px';
        }
    }}/>);
};
export default RescaleImage;
