import red from "@material-ui/core/colors/red";
export const Colors = {
    border: '#979797',
    header: {
        background: "#292929",
        color: "#b6b6b6",
        disable: '#fff',
        enabled: '#0F5F9E',
        select: '#FFFFFF',
    },
    panel: {
        header: '#353535',
        headerCollapActive: '#4a4a4a',
        headerCollapDefault: '#5B5B5B',
        content: '#f3f3f3',
        background: '#D5D5D5',
    },
    // gradient: "linear-gradient(180deg, #4188C6 0%, #2D609C 100%)",
    gradient: "linear-gradient(180deg, #0f5f9ede 0%, #0F5F9E 100%)",
    darkBlue: "#295899",
    lightBlue: "#346DAA",
    textLabel: '#353535',
    input: {
        // label: 'rgba(0,0,0,0.54)',
        label: '#333',
        border: '#c8c8c8',
    },
    orange: '#EA4F48',
    error: red.A400,
    headerColor: '#0F5F9E',
    footer: '#3a3a3a',
    tabSearch: '#FFFFFF',
    login: '#02294E',
    icon: '#353535',
    grayScale: {
        '1e': '#1e1e1e',
        '71': '#717171',
        '3a': '#3a3a3a',
        'bc': '#BCBCBC',
        '52': '#525252',
        '82': '#828282',
        '4a': '#4a4a4a',
        'eb': '#ebebeb',
        '5b': '#5B5B5B',
        '23': '#232323',
        '9b': '#9B9B9B',
        'a8': '#a8a8a8',
        '9e': '#9e9e9e',
        '35': '#353535',
        '86': '#868686',
        'f3': '#f3f3f3',
        'e9': '#e9e9e9',
    },
    profile: {
        enabledTab: '#0F5F9E',
        disabledTab: '#ECECEC',
        enabledText: '#FFFFFF',
        disabledText: '#5B5B5B',
    },
    theme: '#0F5F9E',
    themeLight: '#D92F29',
    link: 'rgb(0, 0, 238)',
    colorsAvatar: [
        '#5E005E',
        '#4194A6',
        '#F2855C',
        '#B81807',
        '#a8cb8a',
        '#e79a91',
        '#d7ca8f',
        '#9d76b0',
        '#d86e9c',
        '#1d9ea3',
        '#72f71f',
        '#1d9ea3',
    ],
};
