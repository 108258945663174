import React from 'react';
import Radio from "@material-ui/core/Radio";
import { connect } from "react-redux";
import { path } from "ramda";
// import { Form } from '../form';
// import formtypes from '../../formtypes';
import Img from "../img";
import { Styles, Images } from "../../themes";
import { isMobile } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
import localization from '../../services/localization';
import { reduxKeys } from '../../constant';
const steps = [
    `step1_ibanking`,
    `step2_ibanking`,
    `step3_ibanking`,
];
const bankList = [
    {
        bank: 'internet_banking_scb',
        img: 'scb',
    },
    {
        bank: 'internet_banking_bbl',
        img: 'bkk',
    },
    {
        bank: 'internet_banking_bay',
        img: 'krungsri',
    },
    {
        bank: 'internet_banking_ktb',
        img: 'krungthai',
    },
];
class InternetBanking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
        };
        // this.handleChange = this.handleChange.bind(this);
    }
    processingFeeBanking(type) {
        switch (type) {
            case 'internet_banking_scb':
            case 'internet_banking_bay':
                return 15;
            case 'internet_banking_bbl':
            case 'internet_banking_ktb':
                return 0;
            default: return 0;
        }
    }
    render() {
        const paymentTimes = path([reduxKeys.other, 'paymentTimes', 0, 'time', 'hour'], this.props);
        const fee = this.props.type;
        return (<div style={{
            padding: '30px',
            ...Styles.unselected,
            color: '#717171',
        }}>
                

                
                <div style={{ fontSize: '18px' }}>
                    {`${localization(`ibank_detail`, `BookingLabel`)}`}
                </div>
                <div style={{
            marginTop: '40px',
            display: isMobile() ? 'inline-grid' : 'flex',
        }}>
                    {bankList.map((each, i) => (<div onClick={() => this.props.handleChange(each.bank)} key={i} style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            padding: 5,
            flex: 1,
        }}>
                                <Radio color="primary" checked={this.props.type === each.bank}/>
                                <Img.Sharpen alt="scb" src={Images.booking[each.img]} style={{
            width: 'calc(100% - 48px)',
        }}/>
                            </div>))}
                </div>

                <div style={{
            color: '#9b9b9b',
            fontSize: '16px',
            marginTop: '35px',
        }}>
                    <div>
                        {localization(`read_before_pay`, `BookingLabel`)}
                    </div>
                    <ul style={{
            marginBottom: 0,
        }}>
                        <li>{localization(`ibank1`, `BookingLabel`)}</li>
                        <li>{localization(`ibank2`, `BookingLabel`)}</li>
                        <li>{localization(`ibank3`, `BookingLabel`)}</li>
                        <li>{localization(`ibank4`, `BookingLabel`)}</li>
                        
                    </ul>
                </div>
            </div>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.other]: state[reduxKeys.other],
});
export default connect(mapStateToProps)(InternetBanking);
