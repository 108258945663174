export const paymentMethod = {
    Omise: 'Omise',
    Card: 'card',
    Alienation: 'Alienation',
    iBanking: 'iBanking',
    Alipay: 'alipay',
    billPayment: 'BillPayment',
};
export const internet_banking = {
    Krungsri: 'internet_banking_bay',
    Bualuang: 'internet_banking_bbl',
    KTB: 'internet_banking_ktb',
    SCB: 'internet_banking_scb',
};
