// const isTH = true;// isLanguage === constLang.TH;
import { isSafari } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
const _isSafari = isSafari();
export const Fonts = {
    family: {
        helvetica: 'Helvetica',
        roboto: 'Roboto',
        serif: 'PT Serif Caption',
        avenir: _isSafari ? 'Avenir,Prompt' : 'Avenir LT W01_65 Medium1475532,Prompt',
        avenirHeavy: _isSafari ? 'Avenir-Heavy,Prompt' : 'Avenir LT W01_85 Heavy1475544,Prompt',
        avenirBlack: _isSafari ? 'Avenir-Black,Prompt' : 'Avenir LT W01_95 Black1475556,Prompt',
    },
    size: {
        input: {
            label: 14,
            error: '0.75rem',
        },
        header: 16,
    },
};
