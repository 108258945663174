import templateLabel from './TH/Message/templateLabel';
import packageLabel from './TH/Message/packageLabel';
import FAQLabel from './TH/Message/FAQLabel';
import AnnouncementLable from './TH/Message/Messages';
import AuthenLabel from './TH/Message/Authen';
import BookingLabel from './TH/Message/Booking';
import DialogLabel from './TH/Message/Dialog';
import LandingLabel from './TH/Message/Landing';
import ProfileLabel from './TH/Message/Profile';
export default {
    blank: ' ',
    hello: 'สวัสดี',
    // error
    error_require_email: 'อีเมลต้องไม่เป็นค่าว่าง',
    error_require_password: 'รหัสผ่านต้องไม่เป็นค่าว่าง',
    error_require_confirm_password: 'ยืนยันรหัสผ่านต้องไม่เป็นค่าว่าง',
    error_require_firstname: 'ชื่อจริงต้องไม่เป็นค่าว่าง',
    error_require_lastname: 'นามสกุลต้องไม่เป็นค่าว่าง',
    error_require_tel: 'เบอร์โทรศัพท์ต้องไม่เป็นค่าว่าง',
    error_require_bank: 'ธนาคารต้องไม่เป็นค่าว่าง',
    error_require_bank_branch: 'สาขาธนาคารต้องไม่เป็นค่าว่าง',
    error_require_date: 'วันที่ต้องไม่เป็นค่าว่าง',
    error_require_time: 'เวลาต้องไม่เป็นค่าว่าง',
    error_require_bank_statement: 'สลิปต้องไม่เป็นค่าว่าง',
    error_require_title: 'กรุณาเลือกคำนำหน้า',
    error_require_credit_card_name: 'ชื่อต้องไม่เป็นค่าว่าง',
    error_require_credit_card_number: 'เลขบัตรต้องไม่เป็นค่าว่าง',
    error_require_credit_card_expire: 'เดือนหมดอายุต้องไม่เป็นค่าว่าง',
    error_require_credit_card_code: 'cvv ต้องไม่เป็นค่าว่าง',
    error_wrong_credit_card_number: 'เลขบัตรไม่ถูกต้อง',
    error_wrong_credit_card_expire: 'เดือนหมดอายุไม่ถูกต้อง',
    error_wrong_tel: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
    error_wrong_email: 'อีเมลไม่ถูกต้อง',
    error_password: 'รหัสผ่านต้องมีอย่างน้อย 8ตัว',
    error_confirm_password: 'ยืนยันรหัสผ่านต้องตรงกับรหัสผ่าน',
    error_money_notGreaterThan: 'จำนวนเงินต้องมากกว่า 0',
    error_money_notValid: 'จำนวนเงินไม่ถูกต้อง',
    error_require_request: 'จำเป็นต้องระบุความต้องการพิเศษ',
    error_require_pickup_detail: 'จำเป็นต้องระบุรายละเอียดสถานที่รับ',
    error_require_pick_up_location: 'จำเป็นต้องระบุรายละเอียดสถานที่',
    error_require_pickup_note: 'จำเป็นต้องระบุที่อยู่ที่คุณต้องการให้เราไปรับ',
    error_require_dropoff_note: 'จำเป็นต้องระบุที่อยู่ที่คุณต้องการให้เราไปส่ง',
    error_require_arrival_airline: 'จำเป็นต้องระบุชื่อสายการบิน',
    error_require_arrival_flight_no: 'จำเป็นต้องระบุหมายเลขเที่ยวบิน',
    error_require_arrival_time: 'จำเป็นต้องระบุเวลาถึง',
    error_require_country_code: 'จำเป็นต้องระบุรหัสประเทศ',
    error_require_gender: 'จำเป็นต้องระบุเพศ',
    error_require_nationality: 'จำเป็นต้องระบุสัญชาติ',
    error_require_nationality_id: 'จำเป็นต้องระบุรหัสบัตรประจำตัวประชาชน / หมายเลขหนังสือเดินทาง',
    error_require_date_of_birth: 'จำเป็นต้องระบุวันเกิด',
    error_require_refund_reasons: 'Refund Reasons is required',
    error_require_refund_other: 'Other is required',
    error_require_room_no: 'จำเป็นต้องระบุหมายเลขห้อง',
    error_require_social_id: 'จำเป็นต้องระบุ Social Id',
    // error_require_money: 'จำนวนเงินต้องไม่เป็นค่าว่าง',
    default_error: 'เกิดข้อผิดพลาด',
    error_title_connection: 'หมดเวลาเชื่อมต่อ',
    error_content_connection: 'โปรดตรวจสอบการเชื่อมต่อ',
    error_title_omise: 'เกิดข้อผิดพลาด',
    error_wrong_firstname: 'ชื่อเป็นภาษาอังกฤษเท่านั้น',
    error_wrong_lastname: 'นามสกุลเป็นภาษาอังกฤษเท่านั้น',
    // system
    payment_method_alienation: 'โอนเงิน',
    payment_method_omise: 'Omise',
    empty_table: 'Empty',
    price: '฿ {price, number}',
    amount: '{amount, number}',
    // month
    jan: 'มกราคม',
    feb: 'กุมพาพันธ์',
    mar: 'มีนาคม',
    apr: 'เมษายน',
    may: 'พฤษภาคม',
    jun: 'มิถุนายน',
    jul: 'กรกฎาคม',
    aug: 'สิงหาคม',
    sep: 'กันยายน',
    oct: 'ตุลาคม',
    nov: 'พฤศจิกายน',
    dec: 'ธันวาคม',
    // province
    krabi: 'กระบี่',
    phuket: 'ภูเก็ต',
    chiangmai: 'เชียงใหม่',
    pattaya: 'พัทยา',
    // templateLabel
    templateLabel,
    packageLabel,
    FAQLabel,
    AnnouncementLable,
    DialogLabel,
    ProfileLabel,
    AuthenLabel,
    BookingLabel,
    LandingLabel,
};
