export default {
    email: {
        presence: {
            allowEmpty: false,
            message: '^error_require_email',
        },
        email: {
            message: '^error_wrong_email',
        },
    },
    password: {
        presence: {
            allowEmpty: false,
            message: '^error_require_password',
        },
        length: {
            minimum: 8,
            tooShort: '^error_password',
        },
    },
    confirmPassword: {
        presence: {
            allowEmpty: false,
            message: '^error_require_confirm_password',
        },
        length: {
            minimum: 8,
            tooShort: '^error_password',
        },
        equality: {
            attribute: 'password',
            message: '^error_confirm_password',
        },
    },
    firstname: {
        presence: {
            allowEmpty: false,
            message: '^error_require_firstname',
        },
        format: {
            pattern: "[a-zA-Z]+",
            message: '^error_wrong_firstname',
        },
    },
    lastname: {
        presence: {
            allowEmpty: false,
            message: '^error_require_lastname',
        },
        format: {
            pattern: "[a-zA-Z]+",
            message: '^error_wrong_lastname',
        },
    },
    tel: {
        presence: {
            allowEmpty: false,
            message: '^error_require_tel',
        },
        format: {
            pattern: "[0-9]+",
            message: '^error_wrong_tel',
        },
    },
    countryCode: {
        presence: {
            allowEmpty: false,
            message: '^error_require_country_code',
        },
    },
    gender: {
        presence: {
            allowEmpty: false,
            message: '^error_require_gender',
        },
    },
    nationality: {
        presence: {
            allowEmpty: false,
            message: '^error_require_nationality',
        },
    },
    nationalityId: {
        presence: {
            allowEmpty: false,
            message: '^error_require_nationality_id',
        },
    },
    date: {
        presence: {
            allowEmpty: false,
            message: '^error_require_date_of_birth',
        },
    },
    // social: {
    //     presence: {
    //         allowEmpty: false,
    //         message: '^error_require_pickup_note',
    //     },
    // },
    socialId: {
        presence: {
            allowEmpty: false,
            message: '^error_require_social_id',
        },
    },
};
