import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '../button';
const gridStyle = { textAlign: 'center' };
const SocialButtons = ({ handleLoginSuccess }) => {
    return (<div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} style={gridStyle}>
                    <Button.Social.facebook.login handleLoginSuccess={handleLoginSuccess}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={gridStyle}>
                    <Button.Social.google.login handleLoginSuccess={handleLoginSuccess}/>
                </Grid>
            </Grid>
        </div>);
};
export default SocialButtons;
