export default {
    class: 'Class',
    email: 'Email',
    registered_date: 'Registered date',
    tel: 'Tel.',
    reserveTel: 'Reserve Tel.',
    dialingCode: 'Dialing Code',
    booking_history: 'Booking History',
    review_package: 'Review',
    marketing_channel: 'Marketing Channel',
    find_booking: 'Find Booking',
    type: 'Type',
    status: 'Status',
    booking_id: 'Booking No.',
    program_tour: 'Program Tour',
    payment_method: 'Payment Method',
    travel_date: 'Travel Date',
    profile_date: 'Date',
    price_booking: 'Price',
    waiting: 'Waiting',
    inprocess: 'Inprocess',
    approved: 'Approved',
    reject: 'Reject',
    ticket: 'Ticket',
    confirm_booking: 'Confirm Booking',
    wallet: 'Wallet',
    sold_product: 'Sold Product',
    program_name: 'Program , Name',
    year: 'Year',
    sold: 'Sold',
    income: 'Income',
    find_program: 'Find Program',
    month: 'Month',
    program_id: 'Program id',
    totel_sold: 'Total Sold',
    edit_profile: 'Edit profile',
    upload: 'Upload',
    title: 'Title',
    firstname: 'Firstname',
    lastname: 'Lastname',
    submit: 'SUBMIT',
    change_password: 'Change password',
    old_password: 'Old password',
    new_password: 'New password',
    confirm_new_password: 'Confirm new password',
    more_info: 'more info ...',
    bookind_id_name: 'Booking ID , Name',
    refund_reasons: 'Refund Reasons',
    apply_refund: 'Apply for Refund',
    refund_detail: 'Refund Details',
    refund_amount: 'Refund Amount',
    view_refound: 'View Refund Policy',
    manage_booking: 'Manage Booking',
    refund: 'Refund',
    accept_refund_condition: 'Accept Refund Condition',
    view_more: 'View More',
    order_no: 'Booking No',
    booking_date: 'Booking Date',
    actual_payment: 'Actual Payment',
    order_placed: 'Select Package',
    payment_successful: 'Secure Payment',
    order_confirmed: 'Confirm Payment',
    on_delivery: 'On-delivery',
    in_process: 'In-process',
    complete: 'Complete',
    booking_cancel: 'This booking has been canceled',
    for_detail: 'For details, please to the email sent to info@Travisgo.com',
    discount: 'Discount',
    adult: 'Adult',
    kid: 'Kid',
    infant: 'Infant',
    abandon: 'Abandon',
    request_refund: 'Request Refund',
    success: 'Refund Success',
    refuse: 'Refund Refuse',
    failed: 'Refund Failed',
    wait_approved: 'Wait-approved',
    bill_payment: 'Bill Payment',
};
