import React, { Component } from 'react';
import { connect } from "react-redux";
import { path, startsWith } from "ramda";
import Panel, { PanelHeader, PanelContent } from "../Panel";
import { reduxKeys } from "../../constant";
import { CheckBoxWithStyle } from "../form/CheckBox";
import { Form } from '../form';
import formtypes, { fieldsName, } from '../../formtypes';
import { Colors } from "../../themes";
import localization from "../../services/localization";
import transform from '../../utils/transform';
class GuestInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            useUserData: true,
            checkSocial: false,
        };
        this.selectSocial = this.selectSocial.bind(this);
    }
    selectSocial() {
        this.setState({ checkSocial: true });
    }
    render() {
        const { formRef, user, [reduxKeys.authen]: { token }, [reduxKeys.settings]: { language }, isMobile, dialingCode, } = this.props;
        const defaultValues = {};
        if (this.state.useUserData) {
            Object.keys(fieldsName.guestInfoFieldsName).forEach((each) => {
                defaultValues[each] = path(['data', each], user);
            });
        }
        const tel = transform.telForm(defaultValues[fieldsName.guestInfoFieldsName.tel]);
        const code = startsWith('+', `${defaultValues[fieldsName.guestInfoFieldsName.tel]}`) ?
            defaultValues[fieldsName.guestInfoFieldsName.tel].substr(0, 3) : dialingCode;
        return (<Panel>
                <PanelHeader style={{
            backgroundColor: Colors.panel.header,
            padding: isMobile ? 0 : '0 0 0 40px',
            justifyContent: isMobile ? 'center' : 'flex-start',
        }}>
                    {localization(`customer_information`, `BookingLabel`)}
                </PanelHeader>
                <PanelContent style={{ backgroundColor: Colors.panel.content }}>
                    {token && (<CheckBoxWithStyle label={localization(`use_my_account`, `BookingLabel`)} rootStyle={{
            marginLeft: '-14px',
            marginBottom: '-14px',
        }} value={this.state.useUserData} onChange={(val) => {
            this.setState({ useUserData: val });
        }}/>)}

                    <Form form={formtypes.guestInfo({
            // onPressEnter: this.onSubmit,
            defaultValues,
            language,
            isMobile,
            dialingCode,
            tel,
            code,
            checkSocial: this.state.checkSocial,
            selectSocial: this.selectSocial,
        })} ref={(ref) => {
            if (typeof formRef === 'function') {
                formRef(ref);
            }
        }} style={{ marginTop: 15 }}/>
                </PanelContent>
            </Panel>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.user]: state[reduxKeys.user],
    [reduxKeys.settings]: state[reduxKeys.settings],
    [reduxKeys.authen]: state[reduxKeys.authen],
    isMobile: state[reduxKeys.screen].isMobile,
});
export default connect(mapStateToProps)(GuestInfo);
