import { all, takeLatest } from 'redux-saga/effects';
import API from '../services/apiSauce';
/* ------------- Types ------------- */
import { AuthenTypes } from '../redux/AuthenRedux';
import { UserTypes } from '../redux/UserRedux';
import { ErrorTypes } from '../redux/ErrorRedux';
import { BookingTypes } from '../redux/BookingRedux';
import { PackageTypes } from '../redux/PackageRedux';
import { OtherTypes } from '../redux/OtherRedux';
/* ------------- Sagas ------------- */
import { loginRequest, logoutRequest, } from './AuthenSaga';
import { registerRequest, forgotPasswordRequest, getUserRequest, updateUserRequest, changePasswordRequest, createAffiliateIdRequest, getAffiliateRequest, } from "./UserSaga";
import { getErrorRequest } from "./ErrorSaga";
import { confirmPaymentRequest, confirmPaymentWithOutTokenRequest, bookingRequest, bookingInfoRequest, bookingListRequest, bookingConfirmRequest, getCustomerRequestRequest, bookingCheckRequest, bookingTransferCheckRequest, refundRequest, refundBookingRequest, } from "./BookingSaga";
import { packageListRequest, createReviewRequest, getReviewRequest, getPromotionCodesRequest, usePromotionCodesRequest, getWishListRequest, postWishListRequest, linkStatRequest, requestReviewsRequest, requestReviewUpdateRequest, getPackageCategoryRequest, } from "./PackageSaga";
import { getBanksRequest, getLocationServicesRequest, getDestinationsRequest, getActivitiesRequest, getAppointmentsRequest, getPaymentTimesRequest, getCurrencyRequest, getAnnouncementsRequest, getAffiliateStatisticsRequest, } from "./OtherSaga";
// import { startup } from './StartupSaga';
/* ------------- Connect Types To Sagas ------------- */
const api = API.create();
function* root() {
    yield all([
        // takeLatest(StartupTypes.STARTUP, startup),
        takeLatest(AuthenTypes.LOGIN_REQUEST, loginRequest, api),
        takeLatest(AuthenTypes.LOGOUT_REQUEST, logoutRequest, api),
        takeLatest(UserTypes.REGISTER_REQUEST, registerRequest, api),
        takeLatest(UserTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordRequest, api),
        takeLatest(UserTypes.UPDATE_USER_REQUEST, updateUserRequest, api),
        takeLatest(UserTypes.GET_USER_REQUEST, getUserRequest, api),
        takeLatest(UserTypes.CHANGE_PASSWORD_REQUEST, changePasswordRequest, api),
        takeLatest(UserTypes.CREATE_AFFILIATE_ID_REQUEST, createAffiliateIdRequest, api),
        takeLatest(UserTypes.GET_AFFILIATE_REQUEST, getAffiliateRequest, api),
        takeLatest(ErrorTypes.GET_ERROR_CODE_REQUEST, getErrorRequest, api),
        takeLatest(BookingTypes.CONFIRM_PAYMENT_REQUEST, confirmPaymentRequest, api),
        takeLatest(BookingTypes.CONFIRM_PAYMENT_WITH_OUT_TOKEN_REQUEST, confirmPaymentWithOutTokenRequest, api),
        takeLatest(BookingTypes.BOOKING_REQUEST, bookingRequest, api),
        takeLatest(BookingTypes.BOOKING_INFO_REQUEST, bookingInfoRequest, api),
        takeLatest(BookingTypes.BOOKING_LIST_REQUEST, bookingListRequest, api),
        takeLatest(BookingTypes.BOOKING_CONFIRM_REQUEST, bookingConfirmRequest, api),
        takeLatest(BookingTypes.BOOKING_CHECK_REQUEST, bookingCheckRequest, api),
        takeLatest(BookingTypes.BOOKING_TRANSFER_CHECK_REQUEST, bookingTransferCheckRequest, api),
        takeLatest(BookingTypes.GET_CUSTOMER_REQUEST_REQUEST, getCustomerRequestRequest, api),
        takeLatest(BookingTypes.REFUND_REQUEST, refundRequest, api),
        takeLatest(BookingTypes.REFUND_BOOKING_REQUEST, refundBookingRequest, api),
        takeLatest(PackageTypes.PACKAGE_LIST_REQUEST, packageListRequest, api),
        takeLatest(PackageTypes.CREATE_REVIEW_REQUEST, createReviewRequest, api),
        takeLatest(PackageTypes.GET_REVIEW_REQUEST, getReviewRequest, api),
        takeLatest(PackageTypes.GET_PROMOTION_CODES_REQUEST, getPromotionCodesRequest, api),
        takeLatest(PackageTypes.USE_PROMOTION_CODES_REQUEST, usePromotionCodesRequest, api),
        takeLatest(PackageTypes.GET_WISH_LIST_REQUEST, getWishListRequest, api),
        takeLatest(PackageTypes.POST_WISH_LIST_REQUEST, postWishListRequest, api),
        takeLatest(PackageTypes.LINK_STAT_REQUEST, linkStatRequest, api),
        takeLatest(PackageTypes.REQUEST_REVIEWS_REQUEST, requestReviewsRequest, api),
        takeLatest(PackageTypes.REQUEST_REVIEW_UPDATE_REQUEST, requestReviewUpdateRequest, api),
        takeLatest(PackageTypes.GET_PACKAGE_CATEGORY_REQUEST, getPackageCategoryRequest, api),
        takeLatest(OtherTypes.GET_BANKS_REQUEST, getBanksRequest, api),
        takeLatest(OtherTypes.GET_LOCATION_SERVICES_REQUEST, getLocationServicesRequest, api),
        takeLatest(OtherTypes.GET_DESTINATIONS_REQUEST, getDestinationsRequest, api),
        takeLatest(OtherTypes.GET_ACTIVITIES_REQUEST, getActivitiesRequest, api),
        takeLatest(OtherTypes.GET_APPOINTMENTS_REQUEST, getAppointmentsRequest, api),
        takeLatest(OtherTypes.GET_PAYMENT_TIMES_REQUEST, getPaymentTimesRequest, api),
        takeLatest(OtherTypes.GET_CURRENCY_REQUEST, getCurrencyRequest, api),
        takeLatest(OtherTypes.GET_ANNOUNCEMENTS_REQUEST, getAnnouncementsRequest, api),
        takeLatest(OtherTypes.GET_AFFILIATE_STATISTICS_REQUEST, getAffiliateStatisticsRequest, api),
    ]);
}
export default root;
