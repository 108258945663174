import register, { fieldsName as regiserFieldsName } from './register';
import login from './login';
import forgotPassword from './forgotPassword';
import confirmPayment, { fieldsName as confirmPaymentFieldsName } from './confirmPayment';
import profile, { fieldsName as profileFieldsName } from './profile';
import guestInfo, { fieldsName as guestInfoFieldsName } from "./guestInfo";
import creditcard from "./creditcard";
import changePassword from "./changePassword";
import bookingHistory from './bookingHistory';
import additionGuestInfo from "./additionGuestInfo";
import travelerInfo, { fieldsName as travelerInfoFieldsName } from "./travelerInfo";
import refund from "./refund";
// import preview from './preview';
export const fieldsName = {
    regiserFieldsName,
    profileFieldsName,
    confirmPaymentFieldsName,
    guestInfoFieldsName,
    bookingHistory,
    travelerInfoFieldsName,
};
export default {
    register,
    login,
    forgotPassword,
    confirmPayment,
    profile,
    guestInfo,
    creditcard,
    changePassword,
    bookingHistory,
    additionGuestInfo,
    travelerInfo,
    refund,
};
