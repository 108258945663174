import Store from '../redux';
import { history } from '../services';
import AuthenActions from "../redux/AuthenRedux";
export const CLEAR_STORE = 'CLEAR_STORE';
export function logout() {
    Store.dispatch(AuthenActions.logoutRequest(clearStore));
}
export function clearStore(redirect = true) {
    Store.dispatch({ type: CLEAR_STORE });
    if (redirect) {
        history.replace('/');
    }
}
