import * as React from 'react';
import { withRouter } from 'react-router';
import { compose } from "recompose";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
// import { pathName } from "../constant";
// import Step from "@material-ui/core/Step";
// import Stepper from "@material-ui/core/Stepper";
// import Typography from '@material-ui/Typography';
// import { withStyles } from "@material-ui/core/styles";
// import StepButton from "@material-ui/core/StepButton";
// enum Page { ChoosePackage = 0, BookingPreview, ConfirmBooking, ConfrimPaymant }
import { Svg } from '../themes';
import { packageType, language as conLanguage } from '../constant';
import styled, { css } from 'styled-components';
// import { isMobile } from '../containers/PackageInfoPanel/GetItemColsPerWidth';
// const { stepPanelDesktop1, stepPanelDesktop2, stepPanelDesktop3, stepPanelDesktop4,
//     stepPanelTDesktop1, stepPanelTDesktop2, stepPanelTDesktop3, stepPanelTDesktop4 } = Images.package;
const maxStep = [1, 2, 3, 4];
// const {
//     stepPanelMobile1, stepPanelMobile2, stepPanelMobile3, stepPanelMobile4,
//     stepPanelDesktop1, stepPanelDesktop2, stepPanelDesktop3, stepPanelDesktop4,
//     stepPanelTMobile1, stepPanelTMobile2, stepPanelTMobile3, stepPanelTMobile4,
//     stepPanelTDesktop1, stepPanelTDesktop2, stepPanelTDesktop3, stepPanelTDesktop4 } = Images.package;
// const { step1Default, step2Default, step3Default, step4Default,
//  step1Active, step2Active, step3Active, step4Active,}=Images.package;
// import { isMobile, } from '../containers/PackageInfoPanel/GetItemColsPerWidth';
function getSteps() { return ['Select Package', 'Booking Preview', 'Secure Payment', 'Payment Confirm']; }
class StepperPanel extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { activeStep: this.props.isStap, completed: new Set(), skipped: new Set() };
        this.totalSteps = () => getSteps().length;
        this.isStepOptional = (step) => step === 1;
        this.handleSkip = () => {
            const { activeStep } = this.state;
            // =======================================================================================================
            // You probably want to guard against something like this
            // it should never occur unless someone's actively trying to break something.
            if (!this.isStepOptional(activeStep)) {
                throw new Error("You can't skip a step that isn't optional.");
            }
            // =======================================================================================================
            const skipped = new Set(this.state.skipped);
            skipped.add(activeStep);
            this.setState({ activeStep: this.state.activeStep + 1, skipped });
        };
        this.handleNext = () => {
            let activeStep;
            // =========================================================================================================
            // It's the last step, but not all steps have been completed
            // find the first step that has been completed
            if (this.isLastStep() && !this.allStepsCompleted()) {
                const steps = getSteps();
                activeStep = steps.findIndex(({}, index) => !this.state.completed.has(index));
            }
            else {
                activeStep = this.state.activeStep + 1;
            }
            // ========================================================================================================
            this.setState({ activeStep });
        };
        // public handleBack = () => { this.setState({ activeStep: this.state.activeStep - 1 }); };
        this.handleStep = (step) => {
            const { activeStep } = this.state;
            if ((step !== activeStep) && (step < activeStep)) {
                const backTo = (step - activeStep);
                const { history } = this.props;
                // const { BookingList } = this.props.location.state;
                // console.log('is step : ', step, ' : ', activeStep, ' : ', backTo);
                history.go(backTo);
            }
            return undefined;
        };
        this.handleComplete = () => {
            const completed = new Set(this.state.completed);
            completed.add(this.state.activeStep);
            this.setState({ completed });
            // =================================================================================================
            /**
             * Sigh... it would be much nicer to replace the following if conditional with
             * `if (!this.allStepsComplete())` however state is not set when we do this,
             * thus we have to resort to not being very DRY.
             */
            if (completed.size !== this.totalSteps() - this.skippedSteps()) {
                this.handleNext();
            }
            // =================================================================================================
        };
        // ========================================================================================
    }
    isStepComplete(step) {
        return this.state.completed.has(step);
    }
    completedSteps() {
        return this.state.completed.size;
    }
    allStepsCompleted() {
        return this.completedSteps() === this.totalSteps() - this.skippedSteps();
    }
    isLastStep() {
        return this.state.activeStep === this.totalSteps() - 1;
    }
    isStepSkipped(step) {
        return this.state.skipped.has(step);
    }
    skippedSteps() {
        return this.state.skipped.size;
    }
    // handleReset = () => { this.setState({ activeStep: 0, completed: new Set(), skipped: new Set(), }); };
    render() {
        // console.log('Stepper : ', this.props);
        const { classes, isMobile } = this.props;
        const { activeStep } = this.state;
        return (<div className={classes.root}>
                <StepsPanelContainer isMobile={isMobile}>
                    {(isMobile) ?
            <StepsContainer isMobile={isMobile} isPaymentStep={(activeStep > 1)}>
                            {this.getStepStyleWithID(activeStep)}
                        </StepsContainer> :
            <StepsContainer isPaymentStep={(activeStep > 1)}>
                            {this.getStepImageWithID(activeStep)}
                        </StepsContainer>}
                    <ButtonSetCotianer isMobile={isMobile}>
                        {maxStep.map((stepNumber, index) => <ButtonStepContiner key={stepNumber}>
                                    <Button fullWidth={true} className={isMobile ? classes.buttonMobileStyle : classes.buttonStyle} onClick={() => this.handleStep(index)}>{''}</Button>
                                </ButtonStepContiner>)}
                    </ButtonSetCotianer>
                </StepsPanelContainer>
            </div>);
    }
    // =============================================================================================================
    getStepImageWithID(step) {
        // console.log(`getStepImageWithID : `, step, ' : ', Language);
        const { productType = packageType.OneDayTrip, Language } = this.props;
        const panelStyle = { width: 1012, height: 56 };
        switch (productType) {
            case packageType.Transfer:
                {
                    switch (Language) {
                        case conLanguage.TH: {
                            switch (step) {
                                case 0: return <Svg.Step.StepPanel_DT_TF_TH_0 />;
                                case 1: return <Svg.Step.StepPanel_DT_TF_TH_1 />;
                                case 2: return <Svg.Step.StepPanel_DT_TF_TH_2 />;
                                case 3: return <Svg.Step.StepPanel_DT_TF_TH_3 />;
                            }
                        }
                        default: {
                            switch (step) {
                                case 0: return <Svg.Step.StepPanel_DT_TF_EN_0 />;
                                case 1: return <Svg.Step.StepPanel_DT_TF_EN_1 />;
                                case 2: return <Svg.Step.StepPanel_DT_TF_EN_2 />;
                                case 3: return <Svg.Step.StepPanel_DT_TF_EN_3 />;
                            }
                        }
                    }
                }
                break;
            case packageType.OneDayTrip:
            default:
                {
                    switch (Language) {
                        case conLanguage.TH: {
                            switch (step) {
                                case 0: return <Svg.Step.StepPanel_DT_OT_TH_0 />;
                                case 1: return <Svg.Step.StepPanel_DT_OT_TH_1 />;
                                case 2: return <Svg.Step.StepPanel_DT_OT_TH_2 />;
                                case 3: return <Svg.Step.StepPanel_DT_OT_TH_3 />;
                            }
                        }
                        default: {
                            switch (step) {
                                case 0: return <Svg.Step.StepPanel_DT_OT_EN_0 />;
                                case 1: return <Svg.Step.StepPanel_DT_OT_EN_1 />;
                                case 2: return <Svg.Step.StepPanel_DT_OT_EN_2 />;
                                case 3: return <Svg.Step.StepPanel_DT_OT_EN_3 />;
                            }
                        }
                    }
                }
            // =======================================================================================================
        }
    }
    // ===============================================================================================================
    getStepStyleWithID(step) {
        const { productType = packageType.OneDayTrip, Language } = this.props;
        const panelStyle = { width: `${window.innerWidth - 20}px` };
        switch (productType) {
            case packageType.Transfer: {
                switch (Language) {
                    case conLanguage.TH: {
                        switch (step) {
                            case 0: return <Svg.Step.StepPanel_TF_TH_0 style={panelStyle}/>;
                            case 1: return <Svg.Step.StepPanel_TF_TH_1 style={panelStyle}/>;
                            case 2: return <Svg.Step.StepPanel_TF_TH_2 style={panelStyle}/>;
                            case 3: return <Svg.Step.StepPanel_TF_TH_3 style={panelStyle}/>;
                        }
                    }
                    default: {
                        switch (step) {
                            case 0: return <Svg.Step.StepPanel_TF_EN_0 style={panelStyle}/>;
                            case 1: return <Svg.Step.StepPanel_TF_EN_1 style={panelStyle}/>;
                            case 2: return <Svg.Step.StepPanel_TF_EN_2 style={panelStyle}/>;
                            case 3: return <Svg.Step.StepPanel_TF_EN_3 style={panelStyle}/>;
                        }
                    }
                }
            }
            case packageType.OneDayTrip:
            default:
                {
                    switch (Language) {
                        case conLanguage.TH: {
                            switch (step) {
                                case 0: return <Svg.Step.StepPanel_OT_TH_0 style={panelStyle}/>;
                                case 1: return <Svg.Step.StepPanel_OT_TH_1 style={panelStyle}/>;
                                case 2: return <Svg.Step.StepPanel_OT_TH_2 style={panelStyle}/>;
                                case 3: return <Svg.Step.StepPanel_OT_TH_3 style={panelStyle}/>;
                            }
                        }
                        default: {
                            switch (step) {
                                case 0: return <Svg.Step.StepPanel_OT_EN_0 style={panelStyle}/>;
                                case 1: return <Svg.Step.StepPanel_OT_EN_1 style={panelStyle}/>;
                                case 2: return <Svg.Step.StepPanel_OT_EN_2 style={panelStyle}/>;
                                case 3: return <Svg.Step.StepPanel_OT_EN_3 style={panelStyle}/>;
                            }
                        }
                    }
                }
        }
    }
}
const styles = (theme) => ({
    root: { width: '100%' },
    button: {
    // marginRight: theme.spacing.unit,
    // color: 'red',
    // backgroundImage: `url(${step1Active})`,
    },
    buttonStyle: { width: '100%', padding: '0px 20px', height: '36px' },
    buttonMobileStyle: { width: '100%', height: '36px' },
    backButton: {
    // marginRight: theme.spacing.unit,
    },
    completed: { display: 'inline-block' },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});
StepperPanel.propTypes = { classes: PropTypes.object };
export default compose(withRouter, withStyles(styles))(StepperPanel);
// const {avenir, avenirHeavy, avenirBlack } = Fonts.family;
const StepsPanelContainer = styled.div `
      background-color: rgb(255, 255, 255);
      padding: 0px 0px 15px;
      margin-bottom: 60px;
${(props) => props.isMobile && css `margin-bottom:0px;`}`;
const StepsImage = styled.img `
      width: 100%;
      height: auto;
      padding: 0px 9px 10px;
      min-height: 42.05px;
${(props) => props.isMobile && css `padding:0px 10px 10px 10px`}
${(props) => (props.isMobile && props.isPaymentStep) && css `padding:0px 0px 10px 0px`} `;
const StepsContainer = styled.div `
      width: 100%;
      height: auto;
      padding: 0px 9px 10px;
      min-height: 42.05px;
${(props) => props.isMobile && css `padding:0px 10px 10px 10px`}
${(props) => (props.isPaymentStep) && css `padding:0px 0px 10px 0px`} `;
const ButtonSetCotianer = styled.div `
    display:flex;
    width:100%;
    padding:0px 5px;
    margin-top:-60px;
${(props) => props.isMobile && css `margin-top:-50px`} `;
const ButtonStepContiner = styled.div `flex:1;padding: 0px 5px;`;
// =========================================================================================
// OldStepper() {
//     console.log('Stepper : ', this.props);
//     const { classes } = this.props;
//     const { activeStep } = this.state;
//     const _buttomStyle = { width: '100%', padding: '0px 20px', height: 'auto' };
//     const stepsWidth = (268);
//     const steps = getSteps();
//     const _isMobile = isMobile();
//     return (
//         <div className={classes.root}>
//             {/* <div style={{ display: 'flex', width: '100%' }}>
//                 <div style={{ flex: 1, }}>
//                     <img src={(activeStep === 0) ? step1Active : step1Default} />
//                 </div>
//                 <div style={{ flex: 1 }}>
//                     <img src={(activeStep === 1) ? step2Active : step2Default}
//  style={{ width: stepsWidth, marginLeft: '-14px' }} />
//                 </div>
//                 <div style={{ flex: 1 }}>
//                     <img src={(activeStep === 2) ? step3Active : step3Default}
// style={{ width: stepsWidth, marginLeft: '-15px' }} />
//                 </div>
//                 <div style={{ flex: 1 }}>
//                     <img src={(activeStep === 3) ? step4Active : step4Default}
//  style={{ width: stepsWidth, marginLeft: '-15px' }} />
//                 </div>
//             </div> */}
//             <Stepper alternativeLabel nonLinear activeStep={activeStep}
// style={{ backgroundColor: '#ffffff', padding: '0px 0px 15px 0px' }}>
//                 {steps.map((label, index) => {
//                     const props = {};
//                     const buttonProps = {};
//                     // if (this.isStepOptional(index)) {
//                     //     buttonProps.optional = <Typography type="caption">Optional</Typography>;
//                     // }
//                     // if (this.isStepSkipped(index)) {
//                     //     props.completed = false;
//                     // }
//                     return (
//                         <Step key={label} {...props}>
//                             <StepButton
//                                 className={classes.button}
//                                 onClick={() => this.handleStep(index)}
//                                 completed={this.isStepComplete(index)} {...buttonProps}>
//                                 {label}
//                             </StepButton>
//                         </Step>
//                     );
//                 })}
//             </Stepper>
//         </div >
//     );
// }
// =================================================================================================
