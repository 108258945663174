import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Collapse from "@material-ui/core/Collapse";
import styled, { css } from 'styled-components';
import { Fonts, Images } from '../../themes';
import { isMobile, isSafari } from '../PackageInfoPanel/GetItemColsPerWidth';
import localization from "../../services/localization";
import { FAQKeys } from "../../constant/enum/FAQKeys";
let FAQLabels;
const { up, down, } = Images.package;
const FAQ = [
    { id: 'FAQ00001', active: false, questions: '', ask: '', },
    { id: 'FAQ00002', active: false, questions: '', ask: '', },
];
class FAQPanel extends Component {
    constructor() {
        super(...arguments);
        this.state = { FAQActive: false };
        this.ActiveAll = () => {
            const { FAQActive } = this.state;
            FAQ.map((faq) => faq.active = !FAQActive);
            this.setState({ FAQActive: !FAQActive });
        };
    }
    render() {
        FAQLabels = localization(FAQKeys.FAQLabel);
        FAQ.map((faqItem) => { faqItem.questions = FAQLabels[faqItem.id].questions || ''; faqItem.ask = FAQLabels[faqItem.id].ask || ''; });
        return (<FAQContianer isMobile={isMobile()}>
                <TopicsContianer onClick={this.ActiveAll}>{FAQLabels[FAQKeys.FAQTopics]} </TopicsContianer>
                {FAQ.map((faq) => this.AskPanel(faq))}
            </FAQContianer>);
    }
    ActiveByQuestions(faq) {
        faq.active = !faq.active;
        this.setState({ FAQActive: FAQ.every((faq) => Boolean(faq.active)) });
        //this.state.FAQActive = FAQ.every((faq) => Boolean(faq.active));
        //this.forceUpdate();
    }
    AskPanel(faq) {
        return (<React.Fragment key={faq.id}>
                <ItemsContianer onClick={() => this.ActiveByQuestions(faq)}>
                    <UpDownIcon src={faq.active ? up : down} alt=''/>
                    {faq.questions}
                </ItemsContianer>
                <Collapse in={(faq.active)} timeout="auto" unmountOnExit style={_style.callpse_container}>
                    <AskLabel>{faq.ask}</AskLabel>
                </Collapse>
            </React.Fragment>);
    }
}
export default withRouter(FAQPanel);
const { avenirHeavy, avenir } = Fonts.family;
const _isSafari = isSafari();
// ========================================================================================================================================================
const FAQContianer = styled.div `
width: 100%;
padding:10px;
color: #5B5B5B;	
border-bottom: 1px solid rgb(223, 223, 223);
margin-top: 30px;
box-shadow: rgba(126, 126, 126, 0.5) 0px 2px 10px 0px;
margin-bottom: 10px;
cursor: pointer;
${(props) => props.isMobile && css `margin-bottom: 60px;`}`; //border-top: 5px solid rgb(184, 24, 7);
const TopicsContianer = styled.div `    
height: 19px;	
padding: 20px 10px;
width: 327px;	
color: rgb(53,53,53);	
font-family: ${avenirHeavy};	
font-size: 15px;	
font-weight: 900;	
letter-spacing: ${_isSafari ? -0.8 : -0.65}px;
line-height: 19px;`;
const ItemsContianer = styled.div `    
width: 100%;
padding: 10px;
font-size: 14px;
border-bottom: 1px solid rgb(223, 223, 223);
margin-bottom: 10px;
font-family: ${avenirHeavy};
letter-spacing: ${_isSafari ? -0.8 : 0}px;
font-weight: 900;
cursor: pointer;`;
const AskLabel = styled.label `    
color: rgb(130, 130, 130);
font-size: 14px;
display: inline-grid;
letter-spacing: ${_isSafari ? -0.8 : 0}px;
font-family: ${avenir};`;
const UpDownIcon = styled.img `    
padding-right: 10px;`;
const _style = {
    callpse_container: {
        height: '100%',
        width: '100%',
        padding: '1px 1px 10px 10px',
        marginRight: '5px',
    },
};
