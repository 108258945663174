import { pathName } from "../../../../../../constant";
import { isMobile } from "../../../../../../containers/PackageInfoPanel/GetItemColsPerWidth";
const { CHOOSE_PACKAGE, SEARCH_PRODUCT, BOOKING_PREVIEW, PAYMENT_CHANNEL, CONFIRM_PAYMMENT, SEEMORE_TRANFER, FERRIES_TRANFER, WISH_LIST, CONTACT_US } = pathName;
export default {
    [CHOOSE_PACKAGE]: 'กำหนดแผนการเดินทาง',
    [SEARCH_PRODUCT]: 'ค้นหาเส้นทาง',
    [BOOKING_PREVIEW]: 'ตรวจสอบการจอง',
    [PAYMENT_CHANNEL]: (isMobile()) ? 'ช่องทางการชำระเงิน' : 'เลือกช่องทางการชำระเงิน',
    [CONFIRM_PAYMMENT]: 'ยืนยันการชำระเงิน',
    [SEEMORE_TRANFER]: 'ค้นหารถรับ-ส่ง สนามบิน',
    [FERRIES_TRANFER]: 'ค้นหารถรับ-ส่ง เรือ',
    [WISH_LIST]: 'สินค้าที่ฉันชอบ',
    [CONTACT_US]: 'ติดต่อเรา',
    [`${"Special Promotion" /* SPECIAL_PROMOTION */}`]: 'โปรโมชั่นพิเศษ',
    [`${"New Attractions" /* NEW_ATTRACTIONS */}`]: 'โปรแกรมใหม่ล่าสุด',
    [`${"Recommend" /* RECOMMEND */}`]: 'โปรแกรมแนะนำ',
    [`${"Recommend Trip" /* RECOMMEND_TRIP */}`]: 'โปรแกรมแนะนำ',
    [`${"Airport Transfer" /* AIRPORT_TRNSFER */}`]: 'รถรับ-ส่ง สนามบิน',
    [`${"Ferries Transfer" /* FERRIES_TRNSFER */}`]: 'เฟอร์รี่',
    [`${"Top Destination" /* TOP_DESTINATION */}`]: 'เส้นทางยอดนิยม',
    [`${"Customer Reviews" /* CUSTOMER_REVIEWS */}`]: 'ความคิดเห็นจากผู้เดินทาง',
    [`${"WishList" /* WISHLIST */}`]: 'สินค้าที่ฉันชอบ',
    sentence1: 'ได้รับราคาที่ดีที่สุด',
    sentence2: ' เฉพาะที่',
    sentence3: ' ทราวิสโก้',
    seeMoreLable: 'ดูเพิ่มเติม',
    ResultOf: 'ผลลัพธ์ จาก',
    AtLocation: 'ใน',
    Total: 'ทั้งหมด',
    FilterTopics: 'ตัวกรอง',
    Destinations: 'เส้นทาง',
    Appointments: 'ตำแหน่ง',
    Activities: 'กิจกรรม',
    ConveyanceType: 'ประเภทรถ',
    Special: 'พิเศษ',
    PriceRange: 'ช่วงราคา',
    ReviewStars: 'คะแนนรีวิว',
    PickUp: 'สถานที่รับ',
    DropOff: 'สถานที่ส่ง',
    Date: 'วันที่',
    Passenger: 'ผู้โดยสาร',
    Members: 'ผู้เดินทาง',
    Destination: 'จุดหมาย',
    Find: 'ค้นหา',
    AppTitle: `TravisGo : Book things to do,Tours,Activities & Tickets`,
    AppDescription: `Plan and book your day-trips with TravisGO. Read reviews on hundreds of day-trips and activities in Thailand. Thailand's #1 online day-trip booking website. Best Price Guaranteed.`,
};
