// import {isPlatForm} from "../config";
console.log(' === util : ');
/**
 *
 * @param num A number that can be undefined.
 * @param defaultNum A number that return when first parameter is undefined,
 * default is 0.
 */
export const undefinedToNumber = (num, defaultNum = 0) => {
    return num || defaultNum;
};
export const initWidth = () => {
    const width = window.innerWidth;
    const breakpoints = {
        // xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    };
    let result = 'md';
    let prevKey = 'xs';
    Object.keys(breakpoints).some((key) => {
        const breakpoint = breakpoints[key];
        if (width < breakpoint) {
            result = prevKey;
            return true;
        }
        prevKey = key;
        return false;
    });
    return result;
};
export const getLocationSearch = (key) => {
    const search = window.location.search.slice(1);
    if (search.length > 0) {
        const searchList = search.split('=');
        const index = searchList.indexOf(key);
        return index > -1 ? searchList[index + 1] : null;
    }
    return null;
};
