import * as React from 'react';
import RaisedButton from "./RaisedButton";
import { Colors, Fonts } from "../../themes";
const Theme = ({ onClick, 
// background,
buttonColor, label, disabled, letterSpacing = '2.85px', lineHeight = '25px', fontSize = '18px', height = '100%', width = '100%', fontFamily = Fonts.family.avenir, buttonStyle = {}, labelStyle = {}, fontWeight = 500, borderRadius = 0, }) => {
    fontFamily = fontFamily === 'black' ?
        Fonts.family.avenirBlack
        :
            fontFamily === 'heavy' ?
                Fonts.family.avenirHeavy
                :
                    Fonts.family.avenir;
    return (<RaisedButton onClick={onClick} disabled={disabled || false} buttonColor={buttonColor} buttonStyle={{
        background: !!disabled ? '#9B9B9B' : Colors.theme,
        borderRadius,
        padding: '7px 0px',
        height,
        lineHeight,
        width,
        boxShadow: 'none',
        ...buttonStyle,
    }} label={label} labelStyle={{
        fontSize,
        fontFamily,
        fontWeight,
        letterSpacing,
        lineHeight,
        color: '#fff',
        ...labelStyle,
    }}/>);
};
export default Theme;
