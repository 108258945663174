import * as React from 'react';
import ReactDOM from 'react-dom';
import config from "./config";
import App from './App';
import "react-image-gallery/styles/css/image-gallery.css";
// import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
if (!!window.Omise) {
    window.Omise.setPublicKey(config.omiseKey);
}
ReactDOM.render(<App />, document.getElementById('root'));
