import { path } from 'ramda';
import { put, call } from 'redux-saga/effects';
import BookingActions, { BOOKING_KEY } from '../redux/BookingRedux';
import errorGenerator from "../services/errorGenerator";
import localization from "../services/localization";
import Dialog from "../components/dialog";
import config from '../config';
function* confirmPaymentRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.confirmPayment, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(BOOKING_KEY);
            // Dialog.info({
            //     key: BOOKING_KEY,
            //     title: localization('info_title_confirm_payment_success'),
            // });
            yield put(BookingActions.confirmPaymentSuccess());
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(BOOKING_KEY);
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.confirmPaymentFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.confirmPaymentFailure());
    }
}
function* confirmPaymentWithOutTokenRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.confirmPaymentWithOutToken, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(BOOKING_KEY);
            // Dialog.info({
            //     key: BOOKING_KEY,
            //     title: localization('info_title_confirm_payment_success'),
            // });
            yield put(BookingActions.confirmPaymentWithOutTokenSuccess());
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(BOOKING_KEY);
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.confirmPaymentWithOutTokenFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.confirmPaymentWithOutTokenFailure());
    }
}
function* bookingRequest(api, { payload, isLoggedIn, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(isLoggedIn ? api.booking : api.bookingGuest, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(BOOKING_KEY);
            // Dialog.info({
            //     key: BOOKING_KEY,
            //     title: localization('info_title_confirm_payment_success'),
            // });
            const { paymentInfo } = payload;
            const { subPaymentMethod } = paymentInfo;
            const check = subPaymentMethod !== undefined ? subPaymentMethod.split('_') : '';
            const response = {
                bookingDetail: {
                    ...path(['data', 'result', 'bookingDetail'], res),
                    omiseData: path(['data', 'result', 'omiseData'], res),
                },
            };
            yield put(BookingActions.bookingSuccess());
            if (typeof nextAction === 'function') {
                if (config.isDevelop) {
                    if (path(['paymentInfo', 'subPaymentMethod'], payload) === "card" || path(['paymentInfo', 'paymentMethod'], payload) === 'Alienation') {
                        nextAction(path(['data', 'result', 'bookingDetail'], res));
                    }
                    else if (path(['paymentInfo', 'subPaymentMethod'], payload) === "bill_payment_tesco_lotus" ||
                        check.indexOf('banking') > 0 || path(['paymentInfo', 'subPaymentMethod'], payload) === "alipay") {
                        nextAction(path(['bookingDetail'], response));
                    }
                    else {
                        nextAction(path(['data', 'result'], res));
                    }
                }
                else {
                    if (path(['paymentInfo', 'subPaymentMethod'], payload) === "bill_payment_tesco_lotus" ||
                        check.indexOf('banking') > 0 || path(['paymentInfo', 'subPaymentMethod'], payload) === "alipay") {
                        nextAction(path(['bookingDetail'], response));
                    }
                    else {
                        nextAction(path(['data', 'result'], res));
                    }
                }
            }
        }
        else {
            Dialog.loading().remove(BOOKING_KEY);
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.bookingFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.bookingFailure());
    }
}
function* bookingInfoRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.bookingInfo, payload);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: BOOKING_KEY,
            //     title: localization('info_title_confirm_payment_success'),
            // });
            // console.log("bookingInfoRequest : ", res);
            yield put(BookingActions.bookingInfoSuccess(res));
            if (typeof nextAction === 'function') {
                nextAction(path(['data', 'result'], res));
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
        else {
            Dialog.loading().remove(BOOKING_KEY);
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.bookingInfoFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.bookingInfoFailure());
    }
}
function* bookingListRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.bookingList, payload);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: BOOKING_KEY,
            //     title: localization('info_title_confirm_payment_success'),
            // });
            yield put(BookingActions.bookingListSuccess());
            if (typeof nextAction === 'function') {
                // console.log('bookingListRequest : ', res)
                nextAction(path(['data', 'result'], res));
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
        else {
            Dialog.loading().remove(BOOKING_KEY);
            // Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.bookingListFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.bookingListFailure());
    }
}
function* bookingConfirmRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.bookingConfirm, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(BOOKING_KEY);
            // Dialog.info({
            //     key: BOOKING_KEY,
            //     title: localization('info_title_confirm_payment_success'),
            // });
            yield put(BookingActions.bookingConfirmSuccess(res.data.result));
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(BOOKING_KEY);
            // Dialog.error({
            //     key: BOOKING_KEY,
            //     ...errorGenerator(res),
            // });
            yield put(BookingActions.bookingConfirmFailure(res.data.result));
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.bookingConfirmFailure(''));
    }
}
function* getCustomerRequestRequest(api, { payload }) {
    try {
        const res = yield call(api.getCustomerRequest, payload);
        if (path(['status'], res) === 200) {
            yield put(BookingActions.getCustomerRequestSuccess(path(['data'], res)));
        }
        else {
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.getCustomerRequestFailure());
        }
    }
    catch (e) {
        yield put(BookingActions.getCustomerRequestFailure());
    }
}
function* bookingCheckRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.bookingCheck, payload);
        if (path(['status'], res) === 200) {
            yield put(BookingActions.bookingCheckSuccess(res.data.result));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
        else {
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.bookingCheckFailure());
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.bookingCheckFailure());
    }
}
function* bookingTransferCheckRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.bookingTransferCheck, payload);
        if (path(['status'], res) === 200) {
            yield put(BookingActions.bookingTransferCheckSuccess(res.data.result));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
        else {
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.bookingTransferCheckFailure());
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.bookingTransferCheckFailure());
    }
}
function* refundRequest(api, { payload }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.refund, payload);
        if (path(['status'], res) === 200) {
            // console.log(res);
            yield put(BookingActions.refundSuccess(res.data));
            // if (typeof nextAction === 'function') {
            //     nextAction();
            // }
            window.setTimeout(() => { Dialog.loading().remove(BOOKING_KEY); }, 4000);
        }
        else {
            Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            yield put(BookingActions.refundFailure());
            // if (typeof nextAction === 'function') {
            //     nextAction();
            // }
            Dialog.loading().remove(BOOKING_KEY);
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.refundFailure());
    }
}
function* refundBookingRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: BOOKING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.refundBooking, payload);
        if (path(['status'], res) === 200) {
            // console.log(res);
            Dialog.info({
                key: BOOKING_KEY,
                title: 'Success',
            });
            yield put(BookingActions.refundBookingSuccess(res));
            if (typeof nextAction === 'function') {
                nextAction(res);
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
        else {
            // Dialog.error({ key: BOOKING_KEY, ...errorGenerator(res) });
            Dialog.info({
                key: BOOKING_KEY,
                title: res.data.result.message || res.data.result,
            });
            yield put(BookingActions.refundBookingFailure());
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(BOOKING_KEY);
        }
    }
    catch (e) {
        Dialog.loading().remove(BOOKING_KEY);
        yield put(BookingActions.refundBookingFailure());
    }
}
export { confirmPaymentRequest, confirmPaymentWithOutTokenRequest, bookingRequest, bookingInfoRequest, bookingListRequest, bookingConfirmRequest, getCustomerRequestRequest, bookingCheckRequest, bookingTransferCheckRequest, refundRequest, refundBookingRequest, };
