import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import config from '../config';
/* ------------- Types and Action Creators ------------- */
export const SETTING_KEY = 'SETTING';
const { Types, Creators } = createActions({
    changeLanguage: ['language'],
    changeCurrency: ['currency'],
}, { prefix: 'SETTING_' });
export const SettingsTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    language: config.default.language,
    currency: config.default.currency,
});
/* ------------- Reducers ------------- */
// request the data from an api
export const changeLanguage = (state, { language }) => Object.assign({}, state, { language });
export const changeCurrency = (state, { currency }) => Object.assign({}, state, { currency });
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.CHANGE_LANGUAGE]: changeLanguage,
    [Types.CHANGE_CURRENCY]: changeCurrency,
});
