import * as React from 'react';
import FlexView from 'react-flexview/lib';
import { Metrics } from "../themes";
import { pathName } from '../constant';
const MaxWidthWrapper = ({ children, rootStyle, style }) => {
    return (<FlexView hAlignContent="center" style={rootStyle}>
            <div style={{
        maxWidth: Metrics.maxContentWidth,
        width: window.location.pathname === pathName.PAYMENT_DETAIL ||
            window.location.pathname === pathName.CONFIRM_PAYMMENT ? '' : '100%',
        padding: '0 10px',
        ...style,
    }}>
                {children}
            </div>
        </FlexView>);
};
export default MaxWidthWrapper;
