import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import Text from "../text";
import { Colors, Fonts } from "../../themes";
const styles = (theme) => ({
    label: {
        color: (props) => props.error ? theme.palette.error.main : Colors.input.label,
        fontSize: Fonts.size.input.label,
        lineHeight: '17px',
        display: 'flex',
    },
});
const FieldLabel = ({ label, classes, style, require }) => {
    if (!label) {
        return null;
    }
    return (<div className={classes.label} style={style}>
            <div style={{ color: 'red' }}>{require === true ? '*' : null}</div>
            <Text.Etext text={label}/>
        </div>);
};
export default withStyles(styles)(FieldLabel);
