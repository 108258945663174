import styled from "styled-components";
import { Fonts } from "../../themes";
export default styled('div') `
    font-family: ${(props) => props.family === 'black' ?
    Fonts.family.avenirBlack
    :
        props.family === 'heavy' ?
            Fonts.family.avenirHeavy
            :
                Fonts.family.avenir};
    ${(props) => props.color ? `color: ${props.color};` : ''}
    ${(props) => props.size ? `font-size: ${props.size}${typeof props.size === 'number' ? 'px' : ''};` : ''}
    ${(props) => props.pointer ? `cursor: pointer;` : ''}
    ${(props) => props.weight ? `font-weight: ${props.weight};` : ''}
    ${(props) => props.spacing ? `letter-spacing: ${props.spacing}${typeof props.spacing === 'number' ? 'px' : ''};`
    : ''}
    ${(props) => props.lineHeight ? `line-height: ${props.lineHeight};` : ''}
    ${(props) => `display: ${props.block ? '' : 'inline'};}`}
    ${(props) => props.enableNewline ? `white-space: pre-line` : ''}
`;
