import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { path } from "ramda";
import MCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Colors } from "../../themes";
const styles = (theme) => ({
    label: {
        color: (props) => path(['labelStyle', 'color'], props),
        fontSize: (props) => path(['labelStyle', 'fontSize'], props),
        marginLeft: (props) => path(['labelStyle', 'marginLeft'], props),
    },
    labelRoot: {
        margin: 0,
    },
    root: {
        '&$checked': {
            color: Colors.theme,
        },
    },
    checked: {},
});
class CheckBox extends Component {
    render() {
        const { classes, value, 
        // error, errorId,
        label, 
        // placeholder,
        // validate,
        onChange, disabled, 
        // style,
        buttonRootStyle, rootStyle, } = this.props;
        return (<div style={{ textAlign: 'start', ...rootStyle }}>
                <FormControlLabel classes={{
            root: classes.labelRoot,
            label: classes.label,
        }} control={<MCheckbox classes={{
            root: classes.root,
            checked: classes.checked,
        }} style={buttonRootStyle} disabled={disabled} checked={value} onChange={(event, checked) => {
            if (typeof onChange === 'function') {
                onChange(checked, event);
            }
        }}/>} label={label}/>
            </div>);
    }
}
export const CheckBoxWithStyle = withStyles(styles)(CheckBox);
