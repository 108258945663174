import { path } from 'ramda';
import { put, call } from 'redux-saga/effects';
import PackageActions, { PACKAGE_KEY } from '../redux/PackageRedux';
import localization from "../services/localization";
import errorGenerator from "../services/errorGenerator";
import Dialog from "../components/dialog";
function* packageListRequest(api, { payload }) {
    try {
        // Dialog.loading({ key: PACKAGE_KEY, text: localization(`loading_please_wait`, `DialogLabel`), });
        const res = yield call(api.getPackageList, payload);
        if (path(['status'], res) === 200) {
            // console.log('packageListRequest : ', res.data.result.package);
            yield put(PackageActions.packageListSuccess(res.data.result.package));
            // Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            // Dialog.loading().remove(PACKAGE_KEY);
            // Dialog.error({ key: PACKAGE_KEY, ...errorGenerator(res), });
            yield put(PackageActions.packageListFailure([]));
        }
    }
    catch (e) {
        yield put(PackageActions.packageListFailure(e));
        // Dialog.loading().remove(PACKAGE_KEY);
    }
}
function* createReviewRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: PACKAGE_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.createReview, payload);
        if (path(['status'], res) === 200) {
            Dialog.info({
                key: PACKAGE_KEY,
                title: `${localization(`info_title_create_review_success`, `DialogLabel`)}`,
            });
            yield put(PackageActions.createReviewSuccess(res.data.details));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            Dialog.info({
                key: PACKAGE_KEY,
                title: path(['data', 'error', 'name'], res),
                content: path(['data', 'error', 'message'], res),
            });
            yield put(PackageActions.createReviewFailure());
            Dialog.loading().remove(PACKAGE_KEY);
        }
    }
    catch (e) {
        Dialog.loading().remove(PACKAGE_KEY);
        yield put(PackageActions.createReviewFailure());
    }
}
function* getReviewRequest(api, { payload }) {
    try {
        Dialog.loading({ key: PACKAGE_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.getReview, payload);
        if (path(['status'], res) === 200) {
            yield put(PackageActions.getReviewSuccess(res.data.result.datas));
            // if (typeof nextAction === 'function') {
            //     nextAction(path(['data', 'result', 'datas'], res));
            // }
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            Dialog.loading().remove(PACKAGE_KEY);
            Dialog.error({
                key: PACKAGE_KEY,
                ...errorGenerator(res),
            });
            yield put(PackageActions.getReviewFailure());
        }
    }
    catch (e) {
        yield put(PackageActions.getReviewFailure());
    }
}
function* getPromotionCodesRequest(api, { payload, }) {
    try {
        Dialog.loading({ key: PACKAGE_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.getPromotionCodes, payload);
        if (path(['status'], res) === 200) {
            Dialog.info({
                key: PACKAGE_KEY,
                title: `${localization(`info_title_get_promotion_code_success`, `DialogLabel`)}`,
                content: `${localization(`info_subtitle_get_promotion_code_success`, `DialogLabel`)}`,
                status: path(['status'], res),
            });
            yield put(PackageActions.getPromotionCodesSuccess(res.data));
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            if (path(['status'], res) !== 701) {
                Dialog.info({
                    key: PACKAGE_KEY,
                    status: path(['status'], res),
                    ...errorGenerator(res),
                });
                Dialog.loading().remove(PACKAGE_KEY);
                yield put(PackageActions.getPromotionCodesFailure());
            }
            else {
                Dialog.info({
                    key: PACKAGE_KEY,
                    title: `${localization(`info_title_get_promotion_code_fail`, `DialogLabel`)}`,
                    status: path(['status'], res),
                });
                // Dialog.error({
                //     key: PACKAGE_KEY,
                //     ...errorGenerator(res),
                // });
                Dialog.loading().remove(PACKAGE_KEY);
                yield put(PackageActions.getPromotionCodesFailure());
            }
        }
    }
    catch (e) {
        yield put(PackageActions.getPromotionCodesFailure());
    }
}
function* usePromotionCodesRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({ key: PACKAGE_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.usePromotionCodes, payload);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: PACKAGE_KEY,
            //     title: localization('info_title_use_promotion_code_success'),
            // });
            yield put(PackageActions.usePromotionCodesSuccess(res.data.result));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            Dialog.loading().remove(PACKAGE_KEY);
            // Dialog.error({
            //     key: PACKAGE_KEY,
            //     ...errorGenerator(res),
            // });
            // console.log(res);
            yield put(PackageActions.usePromotionCodesFailure());
        }
    }
    catch (e) {
        yield put(PackageActions.usePromotionCodesFailure());
    }
}
function* getWishListRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: PACKAGE_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const res = yield call(api.getWishList, payload);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: USER_KEY,
            //     title: localization('info_title_request_affiliate_id_success'),
            // });
            yield put(PackageActions.getWishListSuccess(res.data.result.datas));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            Dialog.error({
                key: PACKAGE_KEY,
                ...errorGenerator(res),
            });
            Dialog.loading().remove(PACKAGE_KEY);
            yield put(PackageActions.getWishListFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(PACKAGE_KEY);
        yield put(PackageActions.getWishListFailure());
    }
}
function* postWishListRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: PACKAGE_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const res = yield call(api.postWishList, payload);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: PACKAGE_KEY,
            //     title: 'Success',
            // });
            yield put(PackageActions.postWishListSuccess(res.data));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            Dialog.error({
                key: PACKAGE_KEY,
                ...errorGenerator(res),
            });
            yield put(PackageActions.postWishListFailure());
            Dialog.loading().remove(PACKAGE_KEY);
        }
    }
    catch (e) {
        Dialog.loading().remove(PACKAGE_KEY);
        yield put(PackageActions.postWishListFailure());
    }
}
function* linkStatRequest(api, { payload, nextAction }) {
    try {
        // Dialog.loading({ key: PACKAGE_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.createReview, payload);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: PACKAGE_KEY,
            //     title: `${localization('info_title_create_review_success')}`,
            // });
            yield put(PackageActions.linkStatSuccess(res.data.result));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            // Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            // Dialog.info({
            //     key: PACKAGE_KEY,
            //     title: path(['data', 'error', 'name'], res),
            //     content: path(['data', 'error', 'message'], res),
            // });
            yield put(PackageActions.linkStatFailure());
            // Dialog.loading().remove(PACKAGE_KEY);
        }
    }
    catch (e) {
        // Dialog.loading().remove(PACKAGE_KEY);
        yield put(PackageActions.linkStatFailure());
    }
}
function* requestReviewsRequest(api, { payload, nextAction }) {
    try {
        Dialog.loading({ key: PACKAGE_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.requestReviews, payload);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: PACKAGE_KEY,
            //     title: `${localization('info_title_create_review_success')}`,
            // });
            yield put(PackageActions.requestReviewsSuccess(res.data.result));
            if (typeof nextAction === 'function') {
                nextAction();
            }
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            Dialog.error({
                key: PACKAGE_KEY,
                ...errorGenerator(res),
            });
            yield put(PackageActions.requestReviewsFailure());
            Dialog.loading().remove(PACKAGE_KEY);
        }
    }
    catch (e) {
        // Dialog.loading().remove(PACKAGE_KEY);
        yield put(PackageActions.requestReviewsFailure());
    }
}
function* requestReviewUpdateRequest(api, { payload, bookingId, nextAction }) {
    try {
        Dialog.loading({ key: PACKAGE_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        const res = yield call(api.requestReviewUpdate, payload, bookingId);
        if (path(['status'], res) === 200) {
            // Dialog.info({
            //     key: PACKAGE_KEY,
            //     title: `${localization('info_title_create_review_success')}`,
            // });
            yield put(PackageActions.requestReviewUpdateSuccess(res.data));
            if (typeof nextAction === 'function') {
                nextAction(res.data);
            }
            Dialog.loading().remove(PACKAGE_KEY);
        }
        else {
            Dialog.error({
                key: PACKAGE_KEY,
                ...errorGenerator(res),
            });
            yield put(PackageActions.requestReviewUpdateFailure());
            Dialog.loading().remove(PACKAGE_KEY);
        }
    }
    catch (e) {
        Dialog.loading().remove(PACKAGE_KEY);
        yield put(PackageActions.requestReviewUpdateFailure());
    }
}
function* getPackageCategoryRequest(api, { payload, nextAction }) {
    try {
        const res = yield call(api.getPackageCategory, payload);
        if (path(['status'], res) === 200) {
            yield put(PackageActions.getPackageCategorySuccess(res.data.result.packageCategoryList));
            if (typeof nextAction === 'function') {
                nextAction(res.data.result.packageCategoryList);
            }
        }
        else {
            yield put(PackageActions.getPackageCategoryFailure());
        }
    }
    catch (e) {
        yield put(PackageActions.getPackageCategoryFailure());
    }
}
export { packageListRequest, createReviewRequest, getReviewRequest, getPromotionCodesRequest, usePromotionCodesRequest, getWishListRequest, postWishListRequest, linkStatRequest, requestReviewsRequest, requestReviewUpdateRequest, getPackageCategoryRequest, };
