import { path } from "ramda";
import { inputType } from "../constant";
import { validateActions } from '../utils/validator';
import { fieldsName as regiterFieldsName } from "./register";
import localization from "../services/localization";
export const fieldsName = {
    bookingId: 'bookingId',
    [regiterFieldsName.firstName]: regiterFieldsName.firstName,
    [regiterFieldsName.lastName]: regiterFieldsName.lastName,
    [regiterFieldsName.email]: regiterFieldsName.email,
    [regiterFieldsName.tel]: regiterFieldsName.tel,
    bankId: 'bankId',
    amount: 'amount',
    date: 'date',
    time: 'time',
    note: 'note',
};
export default ({ defaultValues, onChangePaymentDate, bankChoices }) => ({
    // [fieldsName.bookingId]: {
    //     label: 'Booking NO.',
    //     placeholder: 'Booking NO.',
    //     type: inputType.TEXT_INPUT,
    //     // validate: validateActions.user_email,
    //     value: path([fieldsName.bookingId], defaultValues),
    //     md: 6,
    //     style: {
    //         md: {
    //             paddingRight: '20px',
    //         },
    //     },
    //     disabled: true,
    // },
    // divider_1: {
    // },
    // [fieldsName.firstName]: {
    //     label: 'Firstname',
    //     placeholder: 'Firstname',
    //     type: inputType.TEXT_INPUT,
    //     // validate: validateActions.user_firstname,
    //     value: path([fieldsName.firstName], defaultValues),
    //     md: 6,
    //     style: {
    //         default: {
    //             paddingTop: '10px',
    //         },
    //         md: {
    //             paddingTop: '10px',
    //             paddingRight: '20px',
    //         },
    //     },
    //     disabled: true,
    // },
    // [fieldsName.lastName]: {
    //     label: 'Lastname',
    //     placeholder: 'Lastname',
    //     type: inputType.TEXT_INPUT,
    //     // validate: validateActions.user_lastname,
    //     value: path([fieldsName.lastName], defaultValues),
    //     onPressEnter,
    //     md: 6,
    //     style: {
    //         default: {
    //             // paddingTop: '20px',
    //         },
    //         md: {
    //             paddingTop: '10px',
    //             paddingLeft: '20px',
    //         },
    //     },
    //     disabled: true,
    // },
    // [fieldsName.email]: {
    //     label: 'E-mail',
    //     placeholder: 'Email',
    //     type: inputType.TEXT_INPUT,
    //     // validate: validateActions.user_email,
    //     value: path([fieldsName.email], defaultValues),
    //     md: 6,
    //     style: {
    //         md: {
    //             paddingRight: '20px',
    //         },
    //     },
    //     disabled: true,
    // },
    // [fieldsName.tel]: {
    //     label: 'Tel',
    //     placeholder: 'Tel',
    //     type: inputType.TEXT_INPUT,
    //     // validate: validateActions.user_tel,
    //     value: path([fieldsName.tel], defaultValues),
    //     md: 6,
    //     style: {
    //         md: {
    //             paddingLeft: '20px',
    //         },
    //     },
    //     disabled: true,
    // },
    // divider_2: {
    // },
    [fieldsName.bankId]: {
        label: localization(`payment_to_bank`, `BookingLabel`),
        type: inputType.SELECT,
        validate: (val) => {
            if (val === 0 || val === '') {
                return 'error_require_bank';
            }
        },
        value: path([fieldsName.bankId], defaultValues),
        choices: [
            {
                id: 0,
                label: 'Select bank',
                default: true,
            },
            ...bankChoices,
        ],
        md: 6,
        style: {
            default: {
                paddingTop: '10px',
            },
            md: {
                paddingTop: '10px',
                paddingRight: '20px',
            },
        },
    },
    // bank_branch: {
    //     label: 'Branch of Bank',
    //     type: inputType.SELECT,
    //     validate: (val) => {
    //         if (val === '') { return 'error_require_bank_branch'; }
    //     },
    //     value: '',
    // },
    [fieldsName.amount]: {
        label: localization(`amount_of_money`, `BookingLabel`),
        placeholder: localization(`amount_of_money`, `BookingLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.payment_money,
        value: path([fieldsName.amount], defaultValues),
        md: 6,
        style: {
            md: {
                paddingTop: '10px',
                paddingLeft: '20px',
            },
        },
    },
    // divider_3: {
    // },
    [fieldsName.date]: {
        label: localization(`profile_date`, `ProfileLabel`),
        type: inputType.DATE_PICKER,
        // validate: validateActions.user_firstname,
        value: path([fieldsName.date], defaultValues),
        md: 6,
        style: {
            default: {
            // paddingTop: '10px',
            },
            md: {
                paddingRight: '20px',
            },
        },
        inputProps: {
            disableFuture: true,
        },
        onChange: onChangePaymentDate,
    },
    [fieldsName.time]: {
        label: localization(`time`, `BookingLabel`),
        type: inputType.TIME_PICKER,
        // validate: validateActions.user_firstname,
        value: path([fieldsName.time], defaultValues),
        md: 6,
        style: {
            default: {
            // paddingTop: '10px',
            },
            md: {
                paddingLeft: '20px',
            },
        },
        onChange: onChangePaymentDate,
    },
    [fieldsName.note]: {
        label: localization(`note`, `BookingLabel`),
        type: inputType.TEXT_INPUT,
        multiline: true,
        minHeight: 90,
        // validate: validateActions.user_firstname,
        value: path([fieldsName.note], defaultValues),
    },
});
