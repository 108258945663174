import React from 'react';
import { connect } from "react-redux";
import { path } from "ramda";
import { Images, Colors } from "../../themes";
import Text from "../text";
import localization from '../../services/localization';
import { reduxKeys } from '../../constant';
class BillPayment extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const paymentTimes = path([reduxKeys.other, 'paymentTimes', 0, 'time', 'hour'], this.props);
        return (<React.Fragment>
                <div style={{
            marginTop: '5px', color: '#717171',
        }}>
                    {`${localization(`fee`, `BookingLabel`)} (VAT) 10 THB`}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0px', color: '#9b9b9b' }}>
                    <div style={{ marginTop: 6, marginRight: 20 }}>
                        <div style={{
            padding: 2,
            border: `1px solid ${Colors.theme}`,
            borderRadius: '50%', display: 'inline-block',
        }}>
                            <Text.Bullet size={11} color={Colors.theme} style={{ margin: 0 }}/>
                        </div>
                    </div>
                    <img alt="tesco" src={Images.booking.tesco} style={{ marginRight: 12, width: 150, height: 120 }}/>
                </div>
                <div style={{
            color: '#9b9b9b',
            fontSize: '16px',
        }}>
                    <div>
                        {localization(`conditions`, `BookingLabel`)}
                    </div>
                    <ul style={{
            marginBottom: 0,
        }}>
                        <li>{localization(`for_payment`, `BookingLabel`)}</li>
                        <li>{localization(`condition_bill`, `BookingLabel`)}</li>
                        <li>{`${localization(`completed_payment`, `BookingLabel`)} ${paymentTimes}
                        ${localization(`hours`, `BookingLabel`)} ${localization(`to_avoid`, `BookingLabel`)}`}</li>
                    </ul>
                </div>
            </React.Fragment>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.other]: state[reduxKeys.other],
});
export default connect(mapStateToProps)(BillPayment);
