import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */
export const BOOKING_KEY = 'BOOKING';
const { Types, Creators } = createActions({
    confirmPaymentRequest: ['payload', 'nextAction'],
    confirmPaymentSuccess: null,
    confirmPaymentFailure: null,
    confirmPaymentWithOutTokenRequest: ['payload', 'nextAction'],
    confirmPaymentWithOutTokenSuccess: null,
    confirmPaymentWithOutTokenFailure: null,
    bookingRequest: ['payload', 'isLoggedIn', 'nextAction'],
    bookingSuccess: null,
    bookingFailure: null,
    bookingInfoRequest: ['payload', 'nextAction'],
    bookingInfoSuccess: ['bookingInfo'],
    bookingInfoFailure: null,
    bookingListRequest: ['payload', 'nextAction'],
    bookingListSuccess: null,
    bookingListFailure: null,
    bookingConfirmRequest: ['payload', 'nextAction'],
    bookingConfirmSuccess: ['bookingConfirm'],
    bookingConfirmFailure: ['bookingConfirm'],
    getCustomerRequestRequest: ['payload'],
    getCustomerRequestSuccess: ['customerRequest'],
    getCustomerRequestFailure: null,
    bookingCheckRequest: ['payload', 'nextAction'],
    bookingCheckSuccess: ['checkBooking'],
    bookingCheckFailure: ['checkBooking'],
    bookingTransferCheckRequest: ['payload', 'nextAction'],
    bookingTransferCheckSuccess: ['checkBookingTransfer'],
    bookingTransferCheckFailure: ['checkBookingTransfer'],
    selectBooking: ['selectedBooking'],
    selectBookingWithId: ['selectedBookingWithId'],
    clearSelectedBooking: null,
    clearSelectedBookingWithId: null,
    clearBookingConfirm: null,
    refundRequest: ['payload', 'nextAction'],
    refundSuccess: ['refundList'],
    refundFailure: [],
    refundBookingRequest: ['payload', 'nextAction'],
    refundBookingSuccess: ['refundBooking'],
    refundBookingFailure: [],
}, { prefix: BOOKING_KEY + '_' });
export const BookingTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    error: null,
    loading: false,
    selectedBooking: null,
    selectedBookingWithId: null,
    checkBooking: null,
    bookingConfirm: null,
    checkBookingTransfer: null,
    customerRequest: [],
    bookingInfo: [],
    refundList: [],
    refundBooking: [],
});
/* ------------- Reducers ------------- */
// request the data from an api
export const confirmPaymentRequest = (state) => Object.assign({}, state, { loading: true });
export const confirmPaymentSuccess = (state) => Object.assign({}, state, { loading: false });
export const confirmPaymentFailure = (state) => Object.assign({}, state, { loading: false });
export const confirmPaymentWithOutTokenRequest = (state) => Object.assign({}, state, { loading: true });
export const confirmPaymentWithOutTokenSuccess = (state) => Object.assign({}, state, { loading: false });
export const confirmPaymentWithOutTokenFailure = (state) => Object.assign({}, state, { loading: false });
export const bookingRequest = (state) => Object.assign({}, state, { loading: true });
export const bookingSuccess = (state) => Object.assign({}, state, { loading: false });
export const bookingFailure = (state) => Object.assign({}, state, { loading: false });
export const bookingInfoRequest = (state) => Object.assign({}, state, { loading: true });
export const bookingInfoSuccess = (state, { bookingInfo }) => Object.assign({}, state, { bookingInfo, loading: false });
export const bookingInfoFailure = (state) => Object.assign({}, state, { loading: false });
export const bookingListRequest = (state) => Object.assign({}, state, { loading: true });
export const bookingListSuccess = (state) => Object.assign({}, state, { loading: false });
export const bookingListFailure = (state) => Object.assign({}, state, { loading: false });
export const bookingConfirmRequest = (state) => Object.assign({}, state, { loading: true });
export const bookingConfirmSuccess = (state, { bookingConfirm }) => Object.assign({}, state, { bookingConfirm, loading: false });
export const bookingConfirmFailure = (state, { bookingConfirm }) => Object.assign({}, state, { bookingConfirm, loading: false });
export const getCustomerRequestRequest = (state) => Object.assign({}, state, { loading: true });
export const getCustomerRequestSuccess = (state, { customerRequest }) => Object.assign({}, state, { loading: false, customerRequest });
export const getCustomerRequestFailure = (state) => Object.assign({}, state, { loading: false });
export const bookingCheckRequest = (state) => Object.assign({}, state, { loading: true });
export const bookingCheckSuccess = (state, { checkBooking }) => Object.assign({}, state, { checkBooking, loading: false });
export const bookingCheckFailure = (state, { checkBooking }) => Object.assign({}, state, { checkBooking, loading: false });
export const bookingTransferCheckRequest = (state) => Object.assign({}, state, { loading: true });
export const bookingTransferCheckSuccess = (state, { checkBookingTransfer }) => Object.assign({}, state, { checkBookingTransfer, loading: false });
export const bookingTransferCheckFailure = (state, { checkBookingTransfer }) => Object.assign({}, state, { checkBookingTransfer, loading: false });
export const selectBooking = (state, { selectedBooking }) => Object.assign({}, state, { selectedBooking });
export const selectBookingWithId = (state, { selectedBookingWithId }) => Object.assign({}, state, { selectedBookingWithId });
export const clearSelectedBooking = (state) => Object.assign({}, state, { selectedBooking: null });
export const clearSelectedBookingWithId = (state) => Object.assign({}, state, { selectedBookingWithId: null });
export const clearBookingConfirm = (state) => Object.assign({}, state, { bookingConfirm: null });
export const refundRequest = (state) => Object.assign({}, state, { loading: true });
export const refundSuccess = (state, { refundList }) => Object.assign({}, state, { refundList, loading: false });
export const refundFailure = (state) => Object.assign({}, state, { loading: false });
export const refundBookingRequest = (state) => Object.assign({}, state, { loading: true });
export const refundBookingSuccess = (state, { refundBooking }) => Object.assign({}, state, { refundBooking, loading: false });
export const refundBookingFailure = (state) => Object.assign({}, state, { loading: false });
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.CONFIRM_PAYMENT_REQUEST]: confirmPaymentRequest,
    [Types.CONFIRM_PAYMENT_SUCCESS]: confirmPaymentSuccess,
    [Types.CONFIRM_PAYMENT_FAILURE]: confirmPaymentFailure,
    [Types.CONFIRM_PAYMENT_WITH_OUT_TOKEN_REQUEST]: confirmPaymentWithOutTokenRequest,
    [Types.CONFIRM_PAYMENT_WITH_OUT_TOKEN_SUCCESS]: confirmPaymentWithOutTokenSuccess,
    [Types.CONFIRM_PAYMENT_WITH_OUT_TOKEN_FAILURE]: confirmPaymentWithOutTokenFailure,
    [Types.BOOKING_REQUEST]: bookingRequest,
    [Types.BOOKING_SUCCESS]: bookingSuccess,
    [Types.BOOKING_FAILURE]: bookingFailure,
    [Types.BOOKING_INFO_REQUEST]: bookingInfoRequest,
    [Types.BOOKING_INFO_SUCCESS]: bookingInfoSuccess,
    [Types.BOOKING_INFO_FAILURE]: bookingInfoFailure,
    [Types.BOOKING_LIST_REQUEST]: bookingListRequest,
    [Types.BOOKING_LIST_SUCCESS]: bookingListSuccess,
    [Types.BOOKING_LIST_FAILURE]: bookingListFailure,
    [Types.BOOKING_CONFIRM_REQUEST]: bookingConfirmRequest,
    [Types.BOOKING_CONFIRM_SUCCESS]: bookingConfirmSuccess,
    [Types.BOOKING_CONFIRM_FAILURE]: bookingConfirmFailure,
    [Types.GET_CUSTOMER_REQUEST_REQUEST]: getCustomerRequestRequest,
    [Types.GET_CUSTOMER_REQUEST_SUCCESS]: getCustomerRequestSuccess,
    [Types.GET_CUSTOMER_REQUEST_FAILURE]: getCustomerRequestFailure,
    [Types.BOOKING_CHECK_REQUEST]: bookingCheckRequest,
    [Types.BOOKING_CHECK_SUCCESS]: bookingCheckSuccess,
    [Types.BOOKING_CHECK_FAILURE]: bookingCheckFailure,
    [Types.BOOKING_TRANSFER_CHECK_REQUEST]: bookingTransferCheckRequest,
    [Types.BOOKING_TRANSFER_CHECK_SUCCESS]: bookingTransferCheckSuccess,
    [Types.BOOKING_TRANSFER_CHECK_FAILURE]: bookingTransferCheckFailure,
    [Types.SELECT_BOOKING]: selectBooking,
    [Types.SELECT_BOOKING_WITH_ID]: selectBookingWithId,
    [Types.CLEAR_SELECTED_BOOKING]: clearSelectedBooking,
    [Types.CLEAR_SELECTED_BOOKING_WITH_ID]: clearSelectedBookingWithId,
    [Types.CLEAR_BOOKING_CONFIRM]: clearBookingConfirm,
    [Types.REFUND_REQUEST]: refundRequest,
    [Types.REFUND_SUCCESS]: refundSuccess,
    [Types.REFUND_FAILURE]: refundFailure,
    [Types.REFUND_BOOKING_REQUEST]: refundBookingRequest,
    [Types.REFUND_BOOKING_SUCCESS]: refundBookingSuccess,
    [Types.REFUND_BOOKING_FAILURE]: refundBookingFailure,
});
