import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { isWidthDown } from "@material-ui/core/withWidth";
import { initWidth } from "../utils";
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    setWidth: ['width'],
}, { prefix: 'SCREEN_' });
export const SettingsTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
const width = initWidth();
export const INITIAL_STATE = Immutable({
    width,
    isMobile: isWidthDown('sm', width),
});
/* ------------- Reducers ------------- */
// request the data from an api
export const setWidth = (state, { width: newWidth }) => {
    const isMobile = isWidthDown('sm', newWidth);
    return Object.assign({}, state, { width: newWidth, isMobile });
};
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_WIDTH]: setWidth,
});
