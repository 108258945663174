import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';
import { Helmet } from "react-helmet";
import config from '../../config';
class DBDCertification extends Component {
    constructor() {
        super(...arguments);
        this.state = { isLoading: true };
    }
    render() {
        const { isLoading } = this.state;
        const { isMobile } = this.props;
        return (<PanelContianer isMobile={isMobile}>
                <CertificateContianer id="Certificate-banners" isMobile={isMobile}/>
                {(isLoading) &&
            <Helmet>
                        <script id="dbd-init" src={config.dbdCertificationURL}/>
                    </Helmet>}
            </PanelContianer>);
    }
    componentDidUpdate() {
        const { isLoading } = this.state;
        // const _cerTag = document.getElementById("Certificate-banners");
        (isLoading) && this.setState({ isLoading: false });
        // console.warn('DBDCertification : ', _cerTag, ' : ');
    }
}
export default withRouter(DBDCertification);
const PanelContianer = styled.div `
                    background-color: rgb(255, 255, 255);
                    width: 130px;
                    height: 60px;
                    border-radius: 8px;
${(props) => props.isMobile && css `height: 40px;`}`;
const CertificateContianer = styled.div `
${(props) => props.isMobile && css `width:80%;`}`;
