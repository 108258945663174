import Etext from './Etext';
import Avenir from "./Avenir";
import Bullet from "./Bullet";
import Header from "./Header";
import Content from "./Content";
export default {
    Etext,
    Avenir,
    Bullet,
    Header,
    Content,
};
