import * as React from 'react';
import FlexView from 'react-flexview/lib';
import FacebookProvider, { Page } from 'react-facebook';
// import ButtonBase from "@material-ui/core/ButtonBase";
import MaxWidthWrapper from "../MaxWidthWrapper";
import Logo from "../Logo";
// import CustomerSupport from "./CustomerSupport";
import Social from "./Social";
import Text from "../text";
import Img from "../img";
import CustomLink from "../CustomLink";
import { Metrics, Colors, Images } from '../../themes';
import { device, pathName } from "../../constant";
import DBDCertification from "./DBDCertification";
import config, { isPlatForm } from "../../config";
import { PLATFORM_NAME } from "../../config/platform";
import localization, { isLanguage } from "../../services/localization";
import TraceFacebookPixel, { TrackType } from '../TraceInfo/FacebookPixel';
const { footer, license: AllLicese } = config;
const { license: licenseURL, fb: facebookURL } = footer.link;
const { appId } = config.social.facebook;
const details = [
    [
        { text: 'support', header: true },
        { text: 'contact', to: pathName.CONTACT_US, action: TraceFacebookPixel({ trackType: TrackType.Contact }) },
        // { text: 'Legal' },
        { text: 'privacy_policy', to: pathName.PRIVACY_POLICY, newTab: true },
        { text: 'term_conditions', to: pathName.TERMS, newTab: true },
        { text: 'complaint_policy', to: pathName.COMPLAINT_POLICY, newTab: true },
        { text: 'cookie_policy', to: pathName.COOKIE, newTab: true },
    ],
    [
        { text: 'company', header: true },
        // { text: 'Contact' },
        // { text: 'Legal' },
        // { text: 'Policy Privacy' },
        // { text: 'Terms of Use' },
        { text: 'aboutus', to: pathName.ABOUT_US },
    ],
    [
        { text: 'way_you_can_pay', header: true },
        // { text: 'Supplier' },
        // { text: 'Bussiness Partners' },
        // { text: 'Way You Can pay' },
        { images: Images.landing.footer.pay },
    ],
];
const detailsMobile = [
    [
        { text: 'term_conditions', to: pathName.TERMS, newTab: true },
        { text: 'contact', to: pathName.CONTACT_US, action: TraceFacebookPixel({ trackType: TrackType.Contact }) },
        { text: `License : ${AllLicese.TATLicense}` },
    ],
];
const MobileFooter = ({ isMobile }) => (<React.Fragment>
        <FlexView style={{ display: 'inherit' }}>
            <FlexView column style={{ justifyContent: 'space-around', height: '100%', flexFlow: 'unset', marginTop: 3 }}>
                
                
                <Social isMobile={isMobile}/>
            </FlexView>
            <FlexView style={{ justifyContent: 'space-between' }}>
                {detailsMobile.map((detail) => {
    return (detail.map((each, index) => {
        return (<FlexView column key={`icon_${index}`} style={{ margin: '5px 0' }}>
                                    <Text.Avenir size={window.innerWidth < 375 ? 10 : 12} color={'#fff'} lineHeight={isMobile ? '33px' : 'unset'} style={{
            textDecoration: each.to ? 'underline' : 'unset',
            paddingRight: 5,
        }}>
                                        {each.to ?
            <CustomLink href={each.to} target={each.newTab ? '_blank' : ''} rel="noopener">
                                                    {localization(each.text, `LandingLabel`)}
                                                </CustomLink>
            :
                each.text}
                                    </Text.Avenir>
                                </FlexView>);
    }));
})}
            </FlexView>
            <Text.Avenir color="#fff" size={14} block style={{
    height: 50,
    textAlign: 'center',
    lineHeight: '50px',
    borderTop: '1px solid #fff',
}}>
                {` © 2017 ${isPlatForm} All right reserved`}
            </Text.Avenir>
        </FlexView>
    </React.Fragment>);
const DesktopFooter = ({ isMobile }) => (<FlexView grow={1} column style={{ height: '100%' }}>
        <FlexView grow={1} style={{ padding: '18px 4px', justifyContent: 'space-between' }}>
            <FlexView column style={{ justifyContent: 'space-between' }}>
                <div>
                    <Logo width={179}/>
                    
                </div>
                <Social isMobile={isMobile}/>
            </FlexView>

            <FlexView column style={{
    color: "#fff",
    fontSize: 14,
    justifyContent: 'space-between',
    width: 400,
}}>
                <FlexView style={{ justifyContent: 'space-between' }}>
                    {details.map((detail, i) => {
    return (<FlexView column key={i}>
                                    {detail.map((each, index) => (<div key={index} style={{
        marginBottom: each.header ? 10
            :
                index === detail.length - 1 ? 0 : 5,
    }}>
                                                {!!each.images ?
        each.images.map((image, ii) => (<Img.Sharpen alt="bank-logo" src={image} key={ii} style={{
            marginRight: 4,
        }}/>))
        :
            <Text.Avenir family={each.header ? 'heavy' : undefined} pointer={!!each.action} onClick={each.action}>
                                                            {each.to ?
                <CustomLink href={each.to} target={each.newTab ? '_blank' : ''} rel="noopener">
                                                                        {localization(each.text, `LandingLabel`)}
                                                                    </CustomLink>
                :
                    localization(each.text, `LandingLabel`)}
                                                        </Text.Avenir>}
                                            </div>))}
                                </FlexView>);
})}
                </FlexView>
                
            </FlexView>

            <CustomLink href={`${licenseURL}?language=${isLanguage()}`} target="_blank" rel="noopener">
                <FlexView column hAlignContent='center'>
                    <img alt="tat" src={Images.landing.footer.tat} style={{ marginBottom: 8 }}/>
                    <Text.Avenir family="heavy" size={16} color="#fff" style={{ width: 142, textAlign: 'center' }}>
                        {`${localization(`license`, `LandingLabel`)} : ${AllLicese.TATLicense}`}
                    </Text.Avenir>
                </FlexView>
            </CustomLink>

            {(isPlatForm === `${PLATFORM_NAME.TRAVISGO}`) && <DBDCertification isMobile={isMobile}/>}

            <FlexView>
                
                
                <FacebookProvider appId={appId}>
                    <Page href={facebookURL} tabs="timeline" width={182} height={207} smallHeader/>
                </FacebookProvider>
            </FlexView>
        </FlexView>
        <Text.Avenir color="#fff" size={14} family="heavy" block style={{
    height: 50,
    textAlign: 'center',
    lineHeight: '50px',
    borderTop: '1px solid #fff',
}}>
            {` © 2017 ${isPlatForm} All right reserved`}
        </Text.Avenir>
    </FlexView>);
const Footer = ({ isMobile }) => {
    const redBarHeight = isMobile ? 5 : 7;
    return (<React.Fragment>
            
            <div style={{ height: redBarHeight, backgroundColor: Colors.theme }}/>
            <MaxWidthWrapper rootStyle={{
        backgroundColor: Colors.footer,
        height: Metrics.footer[isMobile ? device.mobile : device.desktop].height
            -
                // (isMobile ? 0 : redBarHeight),
                redBarHeight,
    }} style={{
        width: isMobile ? '' : '100%',
        padding: isMobile ? '0 20px' : 0,
    }}>
                {isMobile ?
        <MobileFooter isMobile={isMobile}/>
        :
            <DesktopFooter isMobile={isMobile}/>}
            </MaxWidthWrapper>
        </React.Fragment>);
};
export default Footer;
