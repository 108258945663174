import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */
export const ERROR_KEY = 'ERROR';
const { Types, Creators } = createActions({
    getErrorCodeRequest: null,
    getErrorCodeSuccess: ['errorCodes'],
    getErrorCodeFailure: ['error'],
}, { prefix: `${ERROR_KEY}_` });
export const ErrorTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    errorCodes: null,
});
/* ------------- Reducers ------------- */
// request the data from an api
export const getErrorCodeRequest = (state) => Object.assign({}, state, { loading: true });
export const getErrorCodeSuccess = (state, { errorCodes }) => (Object.assign({}, state, { errorCodes, loading: false }));
export const getErrorCodeFailure = (state, { error }) => Object.assign({}, state, { error, loading: false });
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_ERROR_CODE_REQUEST]: getErrorCodeRequest,
    [Types.GET_ERROR_CODE_SUCCESS]: getErrorCodeSuccess,
    [Types.GET_ERROR_CODE_FAILURE]: getErrorCodeFailure,
});
