import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';
// import moment from "moment";
// require("moment/min/locales.min");
import { Fonts, Images, Colors } from '../../themes';
import { promotionType } from "../../constant";
import localization from "../../services/localization";
import { GetSymbol, } from '../../components/common/GetSymbol';
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
import { isSafari } from '../PackageInfoPanel/GetItemColsPerWidth';
import { DateWithLocal } from '../../components/common/SetDate';
import { NumberFormat } from '../../components/common/SetNumberFormat';
let pageLabel;
const { iconBookingDate, IconTraveldate } = Images.package;
class SpecialInfoPanel extends Component {
    render() {
        const { packageInfo, isMobile, language } = this.props;
        const { promotionInfo, price } = packageInfo;
        const { type, currency } = promotionInfo;
        pageLabel = localization(wordingKeys.packageLabel);
        // console.log('SpecialInfoPanel : ', packageInfo);
        let disCountInfo;
        let disCountPrice;
        switch (type) {
            case promotionType.FixPrice:
                {
                    disCountInfo = ` ${GetSymbol(currency)}${NumberFormat(price.adult)} `;
                    disCountPrice = ` ${GetSymbol(currency)}${NumberFormat(promotionInfo.adult)} `;
                }
                break;
            case promotionType.Percent:
                {
                    disCountInfo = ` ${promotionInfo[`percent-adult`]}% `;
                    disCountPrice = ` ${GetSymbol(currency)}${NumberFormat(promotionInfo.adult)} `;
                }
                break;
            case promotionType.Price:
                {
                    disCountInfo = ` ${GetSymbol(currency)}${NumberFormat(price.adult - promotionInfo.adult)} `;
                    disCountPrice = ` ${GetSymbol(currency)}${NumberFormat(promotionInfo.adult)} `;
                }
                break;
            default:
                {
                    disCountInfo = ` ${GetSymbol(currency)}${NumberFormat(price.adult)} `;
                    disCountPrice = ` ${GetSymbol(currency)}${NumberFormat(promotionInfo.adult)} `;
                }
        }
        return (<SpecialInfoContianer isMobile={isMobile}>
                <TopiscContianer>
                    <TopiscLable>{pageLabel[wordingKeys.SpecialInfoTopics]}</TopiscLable>
                </TopiscContianer>
                <PriceContainer>
                    <PriceItemsContainer>
                        <div>
                            <SaveInfoLable>{pageLabel[wordingKeys.Save]}</SaveInfoLable>
                        </div>
                        <DiscountContainer>
                            <DiscountLable>{disCountInfo}</DiscountLable>
                        </DiscountContainer>
                    </PriceItemsContainer>
                    <PriceItemsContainer>
                        <div>
                            <SavePriceLable>{` ${GetSymbol(price.currency)}${NumberFormat(price.adult)} `}</SavePriceLable>
                        </div>
                        <DiscountContainer>
                            <DiscountLable>{disCountPrice}</DiscountLable>
                        </DiscountContainer>
                    </PriceItemsContainer>
                </PriceContainer>
                <DiscountInfoPanel packageInfo={packageInfo} language={language}/>
            </SpecialInfoContianer>);
    }
}
function DiscountInfoPanel(props) {
    const { packageInfo, language, isOld } = props;
    const { promotionInfo, } = packageInfo;
    const { bookingDate, travelDate, } = promotionInfo;
    const { startDate: bookingStart, endDate: bookingEnd } = bookingDate;
    const { startDate: travelStart, endDate: travelEnd } = travelDate;
    return (isOld) ?
        (<React.Fragment>
            <InfoItemsContainer>
                <ItemsTopicsContainer>
                    <ItemsLabel>{pageLabel[wordingKeys.BookingOn]}</ItemsLabel>
                    <ItemsIcon src={iconBookingDate} alt='BookingDate'/>
                </ItemsTopicsContainer>
                <div style={{ flex: 2 }}>
                    <DateLabel>{`${DateWithLocal(bookingStart, language)} - ${DateWithLocal(bookingEnd, language)}`}</DateLabel>
                </div>
            </InfoItemsContainer>
            <InfoItemsContainer>
                <ItemsTopicsContainer>
                    <ItemsLabel>{pageLabel[wordingKeys.TravelDate]}</ItemsLabel>
                    <ItemsIcon src={IconTraveldate} alt='BookingDate'/>
                </ItemsTopicsContainer>
                <div style={{ flex: 2 }}>
                    <DateLabel>{`${DateWithLocal(travelStart, language)} - ${DateWithLocal(travelEnd, language)}`}</DateLabel>
                </div>
            </InfoItemsContainer>
        </React.Fragment>) :
        (<React.Fragment>
            <InfoItemsContainer>
                <DateLabel>
                    {`${pageLabel[wordingKeys.BookingOn]}`}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                                {`${DateWithLocal(bookingStart, language)} - ${DateWithLocal(bookingEnd, language)}`}
                </DateLabel>
            </InfoItemsContainer>
            <InfoItemsContainer>
                <DateLabel>
                    {`${pageLabel[wordingKeys.TravelDate]}`}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                                {`${DateWithLocal(travelStart, language)} - ${DateWithLocal(travelEnd, language)}`}
                </DateLabel>
            </InfoItemsContainer>
        </React.Fragment>);
}
export default withRouter(SpecialInfoPanel);
const { avenir, avenirBlack } = Fonts.family;
const _isSafari = isSafari();
const SpecialInfoContianer = styled.div `
height: 100%;
width: 100%;
margin-bottom: 15px;
padding:0px;
background-color: #FFFFFF;
border: 3px solid ${Colors.theme};
box-shadow: rgba(126,126,126,0.5) 0px 2px 10px 0px;
${(props) => props.isMobile && css `padding:0px;`}`;
const TopiscContianer = styled.div `
text-align: center;
background-color: ${Colors.theme};
padding: 12px 0px;`;
const InfoItemsContainer = styled.div `
display: flex;
text-align: center;
margin-bottom: 3px;
margin-left: 5px;`;
const ItemsTopicsContainer = styled.div `
flex: 1 1 0%;
display: contents;`;
const PriceContainer = styled.div `
display: flex;`;
const PriceItemsContainer = styled.div `
flex: 1 1 0%;
text-align: center;
padding: 20px 5px 15px 5px`;
const TopiscLable = styled.label `
height: 19px;		
color: #FFFFFF;	
font-family: ${avenirBlack};	
min-width: 75px;
font-size: 17px;
font-weight: 900;	
letter-spacing: ${_isSafari ? -0.5 : 0}px;
line-height: 16px;`;
const SaveInfoLable = styled.label `
height: 19px;		
color: ${Colors.theme};	
font-family: ${avenir};	
min-width: 75px;
font-size: 20px;
font-weight: 900;	
letter-spacing: ${_isSafari ? -0.5 : 0}px;
line-height: 19px;`;
const SavePriceLable = styled.label `
height: 19px;		
color: ${Colors.theme};	
font-family: ${avenir};	
min-width: 75px;
font-size: 20px;
font-weight: 900;	
letter-spacing: ${_isSafari ? -0.5 : 0}px;
text-decoration: line-through;
line-height: 19px;`;
const DiscountContainer = styled.div `
margin-top: 5px;`;
const DiscountLable = styled.label `
height: 19px;		
color: ${Colors.theme};	
font-family: ${avenirBlack};	
min-width: 75px;
font-size: 26px;
font-weight: 900;	
letter-spacing: ${_isSafari ? -0.5 : 0}px;
line-height: 19px;`;
const ItemsLabel = styled.label `	
height: 19px;	
color: ${Colors.theme};	
font-family: ${avenir};	
font-size: 14px;	
-weight: 900;	
letter-spacing: ${_isSafari ? -0.5 : 0}px;
line-height: 19px;`;
const DateLabel = styled.label `	
width: 100%;
height: 19px;	
color: ${Colors.theme};	
font-family: ${avenir};	
font-size: 12.5px;	
-weight: 900;	
letter-spacing: ${_isSafari ? -0.5 : 0}px;
line-height: 19px;`;
const ItemsIcon = styled.img `    
margin: 3px 5px 0px;`;
