export default {
    seeMoreLable: 'ดูเพิ่มเติม',
    Duration: 'ระยะเวลา',
    Passengers: 'ผู้โดยสาร',
    ShortPassengers: 'ผู้โดยสาร',
    LBPassengers: 'ผู้โดยสาร',
    LBQuantity: 'ผู้โดยสาร',
    PassengersUnit: 'คน',
    StandardBags: 'กระเป๋าเดินทาง',
    Reviews: 'รีวิว',
    Booked: 'จองแล้ว',
    BestSeller: 'ยอดนิยม',
    ShareTrip: 'แชร์โปรแกรมนี้',
    ShouldKnowTopics: 'สิ่งที่คุณควรรู้',
    HotelPickUpInformation: 'รายละเอียดรถรับ - ส่ง',
    CancellationPolicy: 'นโยบายการยกเลิก',
    InclusionTopics: 'ราคานี้รวม',
    TourProgramTopics: 'รายละเอียดโปรแกรม',
    ReviewsTopics: 'ความคิดเห็นจากผู้เดินทาง',
    ReviewPackageTopics: 'ความคิดเห็น',
    BookedTopics: 'รายละเอียดการจอง',
    BookedTransfer: 'รายละเอียดการจอง',
    ProCodeTopics: 'กรอกรหัสเพื่อรับส่วนลด',
    Area: 'พื้นที่',
    PickUpTime: 'เวลารับ',
    ExtraCharge: 'ค่าใช้จ่าย',
    EmptySeat: 'ที่นั่งว่าง',
    Save: 'ประหยัด',
    Date: 'วันที่',
    Adult: 'ผู้ใหญ่',
    Kid: 'เด็ก',
    Infant: 'ทารก',
    hour: 'ชั่วโมง',
    minute: 'นาที',
    hourShot: 'ชม.',
    minuteShot: 'นาที',
    AgeAdult: '10+ ขวบ',
    AgeKid: '3 - 10 ขวบ',
    AgeInfant: '0 - 3 ขวบ',
    Quantity: 'จำนวนรถ',
    Seat: 'เบาะเด็ก',
    Guide: 'ไกด์',
    Discount: 'ส่วนลด',
    ServiceCharge: 'ค่าบริการ ( Service Charge )',
    Total: 'ยอดรวม',
    Activity: 'กิจกรรม',
    BookButton: 'จองตอนนี้',
    ChatWithUs: 'CHAT WITH US',
    SBookButton: 'จอง',
    CouponButton: 'ใช้โค้ดลดราคา',
    Highlight: 'Highlight',
    ReviewHolder: 'Review the package here.',
    LoadMore: 'ดูเพิ่ม ...',
    HideReview: 'ซ่อน ความคิดเห็น',
    Submit: 'แสดงความคิดเห็น',
    isReverse: 'ยกเลิกฟรี',
    isTicket: 'มีเอกสารยืนยันการจอง',
    isLang: 'ไกด์ภาษาอังกฤษ',
    isGroup: 'เดินทางร่วมกับผู้อื่น',
    isProtect: 'ประกันอุบัติเหตุ',
    isCar: 'รถรับส่ง (โรงแรม-ท่าเรือ)',
    isInsant: 'ยืนยันการจองทันที',
    isMaximum: 'เดินทางสูงสุด 8 ท่าน/คัน',
    isNoRefund: 'ไม่คืนเงิน',
    isTraveler: 'เดินทางขั้นต่ำ 1 คน',
    isCalendra: 'ออกเดินทางทุกวัน',
    isVan: 'รถรับ-ส่งส่วนตัว',
    isCraditFee: 'ไม่มีค่าธรรมเนียมการจอง',
    SpecialInfoTopics: 'โปรโมชั่นราคาพิเศษ',
    BookingOn: 'ช่วงเวลาการจอง',
    TravelDate: 'ช่วงเวลาการเดินทาง',
    changeDateDetail: 'เปลี่ยนแปลงวันเดินทางหรือรายละเอียดอื่นๆ',
    WarningMSG: 'ขออภัยค่ะ',
    NoServiceMSG: 'ขออภัยค่ะ สินค้านี้ไม่มีให้บริการในวันดังกล่าว กรุณาเปลี่ยนแปลงวันเดินทาง',
    FullServiceMSG: 'ขออภัยค่ะ สินนี้นี้ถูกจองต็มแล้วในวันดังกล่าว กรุณาเปลี่ยนแปลงวันเดินทาง',
    AppTitle: `TravisGo : Book things to do,Tours,Activities & Tickets`,
    AppDescription: `Plan and book your day-trips with TravisGO. Read reviews on hundreds of day-trips and activities in Thailand. Thailand's #1 online day-trip booking website. Best Price Guaranteed.`,
};
