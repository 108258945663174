import Text from "../text";
import { Colors } from "../../themes";
import styled from 'styled-components';
const HeaderText = styled(Text.Avenir) `
    color: ${Colors.theme};
    font-size: 29px;
`;
HeaderText.defaultProps = {
    family: 'heavy',
    block: true,
};
export default HeaderText;
