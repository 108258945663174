import { omit, path } from "ramda";
import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import CustomDialog from './CustomDialog';
// import Text from "../text";
import { Metrics, ZIndex, Colors, Images } from "../../themes";
import { isMobile } from "../../containers/PackageInfoPanel/GetItemColsPerWidth";
const styles = {
    paper: {
        // height: (props) => props.style.height || "100%",
        // maxWidth: (props) => props.style.width || "",
        // overflow: "initial",
        width: (props) => path(['dialogStyle', 'width'], props) || '',
        maxWidth: (props) => isWidthDown('xs', props.width) ? '350px' : '600px',
        minWidth: (props) => isWidthDown('xs', props.width) ? '350px' : '600px',
        borderRadius: Metrics.modal.borderRadius,
        maxHeight: 'unset',
    },
};
const AlertWithStyles = compose(withWidth(), withStyles(styles), withMobileDialog())((props) => {
    return (<Dialog fullScreen={props.fullScreen} open={props.open} 
    // onClose={props.onClose}
    classes={{
        paper: path(['classes', 'paper'], props),
    }} style={{
        zIndex: ZIndex.dialog.info,
        // width: '350px',
        height: '120px',
        bottom: 0,
        right: 0,
        margin: 'auto',
    }}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'center', padding: '24px 24px 0px' }}>
                <img src={props.status === 200 ? Images.dialog.finish : Images.dialog.error} style={{ width: 50 }}/>
            </DialogTitle>
            <DialogContent style={{
        padding: '10px 24px', textAlign: 'center',
        display: 'inline-grid', justifyContent: 'center',
    }}>
                <DialogContentText style={{
        fontSize: isMobile ? '20px' : '1.5rem', color: Colors.textLabel, width: isMobile ? 330 : 440,
    }}>
                    {props.title}
                </DialogContentText>
                <DialogContentText style={{
        fontSize: isMobile ? '16px' : '1.1rem', color: Colors.textLabel,
    }}>
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {Array.isArray(props.actions) && (props.actions.map((each, index) => {
        return (<Button key={index} style={{ backgroundColor: Colors.theme, color: '#FFFFFF', minWidth: 105 }} onClick={() => {
            // may close as default
            props.hide();
            if (typeof each.action === 'function') {
                each.action();
            }
        }}>
                                    {each.label}
                                </Button>);
    }))}
            </DialogActions>
        </Dialog>);
});
class Alert extends CustomDialog {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            title: '',
            content: '',
            status: '',
            actions: null,
        };
    }
    // public loading(text = 'Loading') {
    //     this.setState({ loadingText: text }, () => {
    //         if (this.state.open !== true) {
    //             this.show();
    //         }
    //     });
    // }
    ask(props) {
        this.setState({
            ...this.state,
            ...props,
            open: true,
        });
        // return new Promise((resolve) => {
        // })
    }
    render() {
        const { open, title, content, actions, status, customContent, } = this.state;
        return (<AlertWithStyles open={open} title={title} content={content} status={status} customContent={customContent} actions={actions} hide={this.hide} 
        // onClose={this.onClose}
        // renderCloseMark={this.renderCloseMark.bind(this)}
        {...omit(['open'], this.props)}/>);
    }
}
Alert.defaultProps = {
    dismiss: false,
    circle: false,
    fullScreen: false,
};
export default Alert;
