export const packageType = {
    OneDayTrip: 'OneDayTrip',
    Transfer: 'Transfer',
    Packages: 'Packages',
    Hotel: 'Hotel',
    Rental: 'Rental',
};
export const typeOfTransfer = {
    Join: 'join',
    Private: 'private',
};
export const rountingType = {
    AirPort: 'airPort',
    Direct: 'direct',
    Port: 'port',
    PointToPoint: 'pointToPoint',
    Island: 'island',
};
export const promotionType = {
    FixPrice: 'fixPrice',
    Percent: 'percent',
    Price: 'price',
};
