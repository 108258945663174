import { equals } from "ramda";
import * as React from 'react';
import { Svg, Device, Colors } from "../../themes";
// interface IProps {
//     open?: boolean;
//     dismiss?: boolean;
//     style?;
//     beforeHide?: () => (Promise<boolean> | boolean);
//     text?: string;
//     listenWidth?: boolean;
// }
// interface IState {
//     open: boolean;
// }
class CustomDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open || false,
        };
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    show() {
        if (this.state.open === false) {
            if (Device.isiOS) {
                document.body.style.position = "fixed";
            }
            this.setState({ open: true });
            if (typeof this.props.openRenderFunction === 'function') {
                this.props.openRenderFunction();
            }
        }
    }
    async hide(force = false) {
        if (this.state.open === true) {
            const { beforeHide } = this.props;
            if (typeof beforeHide === 'function' && force === false) {
                const result = await beforeHide();
                if (!!result === false) {
                    if (Device.isiOS) {
                        document.body.style.position = "";
                    }
                    this.setState({ open: false });
                }
            }
            else {
                if (Device.isiOS) {
                    document.body.style.position = "";
                }
                this.setState({ open: false });
            }
            if (typeof this.props.hideRenderFunction === 'function') {
                this.props.hideRenderFunction();
            }
        }
    }
    getStatus() {
        return this.state.open;
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.open, this.props.open) && !equals(nextProps.open, this.state.open)) {
            this.setState({ open: nextProps.open });
        }
    }
    renderCloseMark() {
        const { ColorCloseMark } = this.props;
        return (<div onClick={() => this.hide()} style={{
            position: "absolute",
            right: 30,
            top: 30,
            cursor: "pointer",
            zIndex: 20,
        }}>
                <Svg.xWhite color={!!ColorCloseMark ? ColorCloseMark : Colors.theme}/>
            </div>);
    }
    onClose() {
        if (this.props.dismiss) {
            this.hide();
        }
    }
}
CustomDialog.defaultProps = {
    dismiss: true,
    closeMark: true,
};
export default CustomDialog;
