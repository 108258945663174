// ===================================================================================================================================
export const api = {
    host: '',
    loopbackHost: '',
    // auth ==========================================================================================================================
    login: `/api/login`,
    logout: `/api/client/user/logout`,
    // ===============================================================================================================================
    // user ==========================================================================================================================
    register: `/api/register`,
    forgotPassword: `/api/user/forgotPassword`,
    getUser: `/api/client/user/profile`,
    updateUser: `/api/client/user/update`,
    changePassword: `/api/client/user/changePassword`,
    // ===============================================================================================================================
    // landing =======================================================================================================================
    getActivities: `/api/activities`,
    getAppointments: `/api/appointments/getActiveAppointment`,
    // ===============================================================================================================================
    // booking  ======================================================================================================================
    booking: `/api/client/booking`,
    bookingInfo: `/api/client/bookingInfo`,
    bookingGuest: `/api/client/BookingGuest`,
    confirmPayment: `/api/client/sendPayment`,
    confirmPaymentWithOutToken: `/api/client/sendPaymentWithOutToken`,
    bookingList: `/api/client/bookingList`,
    bookingConfirm: `/api/client/bookingDetailForConfirmPayment`,
    getCustomerRequest: `/api/customer_requests`,
    bookingCheck: `/api/client/bookingCheckLimitNTimeOutBooking`,
    bookingTransferCheck: `/api/client/bookingTransferSchedulesList`,
    refund: `/api/refund_policies`,
    refundBooking: `/api/client/refundBooking`,
    // ===============================================================================================================================
    // other  ========================================================================================================================
    uploads: `/api/client/uploads`,
    uploadsWithOutToken: `/api/client/uploadsWithOutToken`,
    getBanks: `/home/getBanks`,
    getErrorCode: `/home/getErrorCode`,
    getPaymentTimes: `/api/payment_times`,
    createAffiliateId: `/api/customers/createAffiliateId`,
    getLocationServices: `/api/location_services`,
    getDestinations: `/api/destinations`,
    getCurrency: `/api/currencies`,
    getAffiliates: `/api/affiliates`,
    announcements: `/api/announcements/getAnnouncement`,
    affiliateStat: `/api/affiliate_statistics`,
    // ===============================================================================================================================
    // package =======================================================================================================================
    packageList: `/home/getPackage`,
    packageReview: `/api/package_reviews/paging`,
    createReview: `/api/package_reviews`,
    getPromotionCodes: `/api/promotion_codes/getPromotion`,
    usePromotionCodes: `/api/promotion_codes/usePromotion`,
    getWishList: `/api/customers/getWishListCustomer`,
    postWishList: `/api/customers/postWishListCustomer`,
    linkStat: `/api/link_statistics/countLinkStat`,
    requestReviews: `/api/request_reviews/requestReviews`,
    requestReviewUpdate: `/api/request_reviews/update`,
    getPackageCategory: `/home/getPackageCategory`,
};
// ===================================================================================================================================
