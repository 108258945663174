import { inputType } from "../constant";
import { validateActions } from '../utils/validator';
import { fieldsName as regiterFieldsName } from "./register";
import localization from "../services/localization";
export default ({ onPressEnter, isMobile }) => ({
    [regiterFieldsName.email]: {
        label: localization(`email`, `ProfileLabel`),
        inputProps: {
            type: 'email',
        },
        placeholder: localization(`email`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_email,
        value: '',
        md: isMobile ? 12 : 6,
        style: {
            default: {
                paddingRight: 5,
            },
            md: {
                paddingRight: 5,
            },
        },
    },
    [regiterFieldsName.password]: {
        label: localization(`password`, `AuthenLabel`),
        inputProps: {
            type: 'password',
        },
        placeholder: localization(`password`, `AuthenLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_password,
        value: '',
        onPressEnter,
        md: isMobile ? 12 : 6,
        style: {
            default: {
                paddingRight: 5,
            },
            md: {
                paddingLeft: 5,
            },
        },
    },
});
