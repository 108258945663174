import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
const RaisedButton = (props) => (<Button variant="contained" disabled={props.disabled || props.loading} style={props.buttonStyle} onClick={props.onClick}>
        {props.loading ?
    <CircularProgress style={{ color: props.loadingColor, width: '24px', height: '24px' }}/>
    :
        <Fragment>
                    {props.icon}
                    <div style={{
            color: '#fff',
            textTransform: 'none',
            ...props.labelStyle,
        }}>
                        {props.label}
                    </div>
                </Fragment>}
    </Button>);
export default RaisedButton;
