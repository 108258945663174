import * as React from 'react';
import { connect } from 'react-redux';
import { compose, onlyUpdateForKeys, withProps } from "recompose";
import styled from 'styled-components';
import { language as languageConst, reduxKeys } from "../../../constant";
import SettingsActions from '../../../redux/SettingsRedux';
import { Images } from "../../../themes";
const Flag = styled.img `
    width: ${(props) => !!props.size ? props.size : '20'}px;
    height: ${(props) => !!props.size ? props.size : '20'}px;
    cursor: pointer;
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    filter: ${(props) => props.language !== props.flag ? 'opacity(0.5)' : ''};
`;
const getTitle = (flag) => {
    switch (flag) {
        case languageConst.EN:
            return 'English';
        case languageConst.TH:
            return 'ภาษาไทย';
        case languageConst.CN:
            return '中国';
        default:
            return 'English';
    }
};
export const FlagButton = ({ size, flag, language, style, dispatch }) => (<div onClick={() => dispatch(SettingsActions.changeLanguage(flag))} style={{ display: 'flex', ...style }} title={getTitle(flag)}>
        <Flag src={Images.Flag[flag]} size={size} language={language} flag={flag}/>
    </div>);
const mapStateToProps = (state) => {
    return {
        language: state[reduxKeys.settings].language,
    };
};
const ComposedFlagButton = compose(onlyUpdateForKeys(['language']), connect(mapStateToProps), withProps(({ size = 20, flag, language, dispatch }) => {
    return { size, flag, language, dispatch };
}))(FlagButton);
export default ComposedFlagButton;
