export default {
    seeMoreLable: 'See More',
    Duration: 'Duration',
    Passengers: 'Max Passengers',
    ShortPassengers: 'Max Pax',
    LBQuantity: 'Max.',
    LBPassengers: 'Passengers',
    PassengersUnit: 'Paxs',
    StandardBags: 'Standard bags',
    Reviews: 'Reviews',
    Booked: 'Booked',
    BestSeller: 'BestSeller',
    ShareTrip: 'Share This Trip',
    ShouldKnowTopics: 'What you should know',
    HotelPickUpInformation: 'Hotel Pick Up Information',
    CancellationPolicy: 'Cancellation Policy',
    InclusionTopics: `What's Included ?`,
    TourProgramTopics: 'Tour Program',
    ReviewsTopics: 'Reviews',
    ReviewPackageTopics: 'Review Package',
    BookedTopics: 'Your Ticket Detail',
    BookedTransfer: 'Special Request',
    ProCodeTopics: 'Do you have promotion code ?',
    Area: 'Area',
    PickUpTime: 'PickUp Time',
    ExtraCharge: 'Extra Charge',
    EmptySeat: 'Empty Seat',
    Save: 'SAVE',
    Date: 'Date',
    Adult: 'Adult',
    Kid: 'Kid',
    Infant: 'Infant',
    hour: 'Hours',
    minute: 'Minutes',
    hourShot: 'Hrs.',
    minuteShot: 'Min.',
    AgeAdult: '10+ Yrs',
    AgeKid: '3 - 10 Yrs',
    AgeInfant: '0 - 2 Yrs',
    BoatQuantity: 'Boat Qty.',
    BoatSeat: 'Boat Seat',
    Quantity: 'Car Qty.',
    Seat: 'Car Seat',
    Guide: 'Guide',
    Discount: 'DISCOUNT',
    ServiceCharge: 'ServiceCharge',
    Total: 'TOTAL',
    Activity: 'Activity',
    BookButton: 'BOOK THIS TRIP',
    ChatWithUs: 'CHAT WITH US',
    SBookButton: 'BOOK',
    CouponButton: 'USE COUPON',
    Highlight: 'Highlight',
    ReviewHolder: 'Review the package here.',
    LoadMore: 'Load More ...',
    HideReview: 'Hide the Review',
    Submit: 'Submit',
    isReverse: 'Free Cancellation',
    isTicket: 'E-Ticket',
    isLang: 'English Guide',
    isGroup: 'Join In Group',
    isProtect: 'Accident Insurance',
    isCar: 'Hotel Transfer',
    isInsant: 'Instant Confirmation',
    isMaximum: 'Maximum 8 Paxs / Van',
    isNoRefund: 'No Refund',
    isTraveler: 'Minimum 1 Travelers',
    isCalendra: 'Time Period : Daily',
    isVan: 'Private Van',
    isCraditFee: 'No Credit Card Fees',
    SpecialInfoTopics: 'Get Special Price',
    BookingOn: 'Booking on',
    TravelDate: 'Travel date',
    changeDateDetail: 'Change dates & details',
    WarningMSG: 'Sorry',
    NoServiceMSG: 'Sorry , your selected service is not available today. Please change the date.',
    FullServiceMSG: 'Sorry, your selected service is full. Please change the date.',
    AppTitle: `PhiPhiTours : Book things to do,Tours,Activities & Tickets`,
    AppDescription: `Plan and book your day-trips with PhiPhiTours. Read reviews on hundreds of day-trips and activities in Thailand. Thailand's #1 online day-trip booking website. Best Price Guaranteed.`,
};
