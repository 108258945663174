import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { equals, path } from "ramda";
import MAvatar from '@material-ui/core/Avatar';
// import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { shadows } from '@material-ui/system';
// import Settings from '@material-ui/icons/Settings';
import ButtonBase from "@material-ui/core/ButtonBase";
import { 
// Colors,
Images, } from "../themes";
const DISPLAY_MODE = {
    IMAGE: 'IMAGE',
    DEFAULT: 'DEFAULT',
};
const imageType = ['image/jpeg', 'image/jpg', 'image/png'];
const getDisplayMode = (avatar) => {
    return !!avatar ? DISPLAY_MODE.IMAGE : DISPLAY_MODE.DEFAULT;
};
// const getPositionOnGirthOfAvatar = (avatarSize = 200, componentSize = 32) => {
//     const avataRadius = avatarSize / 2;
//     return avataRadius - ((avataRadius * Math.sqrt(2)) / 2) - (componentSize / 2);
// };
const styles = () => ({
    avartarWrapper: {
        borderRadius: '50%',
        boxShadow: shadows[2],
        width: (props) => `${props.size || 210}px`,
        height: (props) => `${props.size || 210}px`,
        position: 'relative',
        background: 'transparent',
    },
    avatar: {
        width: (props) => `${props.size || 210}px`,
        height: (props) => `${props.size || 210}px`,
        fontSize: (props) => `${props.size / 2 || 100}px`,
        textTransform: 'uppercase',
    },
    avatarImg: {
        // 'imageRendering': 'crisp-edges',
        imageRendering: '-webkit-optimize-contrast',
    },
    button: {
        "width": (props) => `${props.size || 210}px`,
        "height": (props) => `${props.size || 210}px`,
        "minHeight": 'unset',
        "overflow": 'hidden',
        "background": 'transparent',
        '&:hover': {
            background: 'transparent',
        },
    },
    input: {
        display: 'none',
    },
    editIconWrapper: {
        // boxShadow: shadows[2],
        position: 'absolute',
        right: '15px',
        bottom: '1px',
        backgroundColor: 'transparent',
    },
    editIcon: {
        width: '20px',
        height: '20px',
        color: '#888',
    },
});
class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMode: getDisplayMode(props.avatar),
            initAvatar: props.avatar,
            avatar: props.avatar,
        };
        this.onFileChange = this.onFileChange.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.getAvatar = this.getAvatar.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.avatar, this.state.initAvatar) && typeof nextProps.avatar === 'string') {
            this.setState({
                displayMode: getDisplayMode(nextProps.avatar),
                initAvatar: nextProps.avatar,
                avatar: nextProps.avatar,
            });
        }
    }
    getAvatar(type) {
        switch (type) {
            case 'base64':
                return this.base64Image;
            case 'file':
                return this.imageFile;
            default:
                return this.state.avatar;
        }
    }
    displayAvatar() {
        if (this.state.displayMode === DISPLAY_MODE.DEFAULT) {
            return this.props.dark ? Images.defaultAvatar.blue : Images.defaultAvatar.white;
        }
        return this.state.avatar;
    }
    render() {
        const { onClick, editable, classes } = this.props;
        const clickAble = typeof onClick === 'function' || editable;
        return (<Fragment>
                <Paper className={classes.avartarWrapper} style={this.props.style}>
                    <ButtonBase 
        // variant="fab"
        disableRipple={!clickAble} 
        // className={classes.button}
        classes={{
            root: classes.button,
        }} onClick={() => {
            if (typeof onClick === 'function') {
                onClick();
            }
            else {
                if (editable === true) {
                    this.inputRef.click();
                }
            }
        }}>
                        <MAvatar classes={{
            root: classes.avatar,
            img: classes.avatarImg,
        }} 
        // className={classes.avatar}
        alt='' onError={this.onImageError} src={this.displayAvatar()}/>
                    </ButtonBase>
                    {editable === true && (<ButtonBase 
        // variant="fab"
        className={classes.editIconWrapper} onClick={() => {
            if (typeof onClick === 'function') {
                onClick();
            }
            else {
                if (editable === true) {
                    this.inputRef.click();
                }
            }
        }}>
                                <img alt='header' src={Images.profile.uploadAvatar} style={{ width: '180px' }}/>
                                
                            </ButtonBase>)}
                </Paper>
                {editable === true && (<input ref={(ref) => this.inputRef = ref} accept=".jpg, .png" className={classes.input} type="file" onChange={this.onFileChange}/>)}
            </Fragment>);
    }
    onFileChange(event) {
        const files = event.target.files;
        const { onChange } = this.props;
        if (files.length === 1) {
            const fileType = files[0].type;
            if (typeof onChange === 'function') {
                onChange(files[0]);
            }
            if (imageType.indexOf(fileType) > -1) {
                this.imageFile = files[0];
                const reader = new FileReader();
                reader.onloadend = (e) => {
                    this.base64Image = path(['target', 'result'], e);
                    this.setState({
                        avatar: this.base64Image,
                        displayMode: DISPLAY_MODE.IMAGE,
                    });
                    // this.props.onBase64Loaded && this.props.onBase64Loaded(this.base64Image);
                };
                reader.readAsDataURL(files[0]);
            }
            else {
                // this.refs.alert_dialog.fail(
                //     basicDialogLabel.wrong_image_file_type.message);
                this.inputRef.value = null;
            }
        }
        else {
            // click cencel
        }
    }
    onImageError() {
        if (this.props.onImageError) {
            this.props.onImageError();
        }
        const newState = {
            ...this.state,
        };
        if (!equals(this.state.initAvatar, this.state.avatar)) {
            newState.avatar = this.state.initAvatar;
        }
        else {
            // newState.displayMode = getDisplayMode(this.props.name, this.props.avatar);
            if (newState.displayMode === DISPLAY_MODE.IMAGE) {
                newState.displayMode = DISPLAY_MODE.DEFAULT;
            }
        }
        this.setState(newState);
    }
}
export default withStyles(styles)(Avatar);
