import * as React from 'react';
import { connect } from "react-redux";
import Nav from "./nav";
import Footer from "./Footer";
import DesktopWrapper from './nav/DesktopWrapper';
import MaxWidthWrapper from "./MaxWidthWrapper";
import { path } from "ramda";
import { reduxKeys } from "../constant";
import { Metrics, Fonts } from "../themes";
const ContentWrapper = (props) => {
    const { [reduxKeys.screen]: { width, isMobile }, children, withMaxWidth, maxWidthProps, hideFooter = false, customFooter, } = props;
    const { header, footer } = Metrics;
    const headerHeight = isMobile ? header.mobileHeight(width) : header.height;
    const content = (withMaxWidth) ? <MaxWidthWrapper {...maxWidthProps}>{children}</MaxWidthWrapper> : children;
    const _strictCustomFooter = path(['strict'], customFooter);
    const _heightCustomFooter = path(['height'], customFooter);
    const footerHeight = (_heightCustomFooter) ? _heightCustomFooter : (isMobile ? footer.mobile.height : footer.desktop.height);
    return (<React.Fragment>
            {!isMobile && (<DesktopWrapper />)}
            <Nav.Mobile />
            {isMobile && (<div style={{ height: headerHeight }}/>)}
            <div style={{
        minHeight: _strictCustomFooter ? '' : `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
        height: _strictCustomFooter ? `calc(100vh - ${headerHeight}px - ${footerHeight}px)` : '',
        fontFamily: Fonts.family.avenir,
        overflow: _strictCustomFooter ? 'auto' : 'initial',
    }}>
                {content}
            </div>
            {!hideFooter && (<Footer isMobile={isMobile}/>)}
            {path(['element'], customFooter)}
        </React.Fragment>);
};
const mapStateToProps = (state) => ({ [reduxKeys.screen]: state[reduxKeys.screen] });
export default connect(mapStateToProps)(ContentWrapper);
