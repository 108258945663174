import { path, pick, values, isEmpty } from 'ramda';
import { put, call } from 'redux-saga/effects';
import { socialKeys, reduxKeys } from "../constant";
import AuthenActions, { AUTHEN_KEY } from '../redux/AuthenRedux';
import UserActions from '../redux/UserRedux';
import errorGenerator from "../services/errorGenerator";
import Store from "../redux";
import localization from "../services/localization";
import { pathName } from "../constant";
import { history } from '../services';
import Dialog from "../components/dialog";
function* fetchDataAfterLogin() {
    if (window.location.pathname === pathName.BOOKING_SUCCESS) {
        if (window.location.hash !== "#omiseWaiting" /* omiseKey */) {
            history.replace(pathName.INDEX);
        }
    }
    // fetch more data
    yield put(UserActions.getUserRequest());
}
function* afterLoginSuccess({ response }) {
    const token = path(['result', 'token'], response);
    if (!!Dialog.loading()) {
        Dialog.loading().remove(AUTHEN_KEY);
    }
    if (!!token) {
        yield put(AuthenActions.loginSuccess({ token }));
        yield call(() => fetchDataAfterLogin());
    }
    else if (!!path([reduxKeys.authen, 'token'], Store.getState())) {
        // wrong way handle this
        yield put(AuthenActions.loginSuccess({ token: path([reduxKeys.authen, 'token'], Store.getState()) }));
        yield call(() => fetchDataAfterLogin());
    }
}
function* loginRequest(api, { payload, nextAction }) {
    const loadingDialog = Dialog.loading();
    try {
        let body;
        // case login with social
        if (!!path(['socialType'], payload)) {
            body = { [socialKeys[payload.socialType]]: payload.id };
        }
        else {
            if (!!payload) {
                // case login after register with social
                const regiserWithSocial = pick(values(socialKeys), payload);
                if (!isEmpty(regiserWithSocial)) {
                    body = regiserWithSocial;
                }
                else { // case login with email
                    body = payload;
                }
            }
        }
        // login with token do nothing
        if (!!loadingDialog) {
            loadingDialog.loading({ key: AUTHEN_KEY, text: `${localization(`loading_login`, `DialogLabel`)}` });
        }
        const res = yield call(api.login, body);
        if (path(['status'], res) === 200) {
            yield call(() => afterLoginSuccess({ response: res.data }));
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            if (!!path(['socialType'], payload) && path(['status'], res) === 401) {
                if (!!loadingDialog) {
                    loadingDialog.remove(AUTHEN_KEY);
                }
                if (!!Dialog.info()) {
                    // Dialog.info({
                    //     key: AUTHEN_KEY,
                    //     title: `${localization('question_title_register')}`,
                    //     content: `${localization('question_content_regiser')}`,
                    // });
                    yield put(UserActions.setSocialCache(payload));
                }
            }
            else {
                if (!!loadingDialog) {
                    loadingDialog.remove(AUTHEN_KEY);
                }
                if (path(['status'], res) === 403) {
                    Dialog.error({
                        key: AUTHEN_KEY, ...errorGenerator(res),
                        content: localization(`question_content_regiser_fail`, `DialogLabel`),
                    });
                }
                if (!!Dialog.error()) {
                    Dialog.error({ key: AUTHEN_KEY, ...errorGenerator(res) });
                }
                yield put(AuthenActions.loginFailure());
            }
        }
    }
    catch (e) {
        if (!!loadingDialog) {
            loadingDialog.remove(AUTHEN_KEY);
        }
        yield put(AuthenActions.loginFailure());
    }
}
function* logoutRequest(api, { nextAction }) {
    try {
        const res = yield call(api.logout);
        if (path(['status'], res) === 200) {
            yield put(AuthenActions.logoutSuccess());
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.error({ key: AUTHEN_KEY, ...errorGenerator(res) });
            yield put(AuthenActions.logoutFailure());
        }
    }
    catch (e) {
        yield put(AuthenActions.logoutFailure());
    }
}
export { loginRequest, logoutRequest };
