import React, { Component } from 'react';
import { path } from "ramda";
import { Link } from 'react-router-dom';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import FlexView from 'react-flexview/lib';
import Button from "../components/button";
import Dialog from "../components/dialog";
import ContentWrapper from "../components/ContentWrapper";
import { isSafari } from '../containers/PackageInfoPanel/GetItemColsPerWidth';
import { Fonts, Styles, Images, Colors } from "../themes";
import localization from "../services/localization";
import { pathName, reduxKeys, paymentMethod as paymentMethodConst, bookingListKeys } from "../constant";
import config from '../config';
import BookingActions, { BOOKING_KEY } from "../redux/BookingRedux";
import dialog from '../components/dialog';
import TraceFacebookPixel, { TrackType } from '../components/TraceInfo/FacebookPixel';
const { isProduction, is3DSecureActive } = config;
const bookingInfoKey = bookingListKeys.bookingInfoKey;
const { success, unsuccess } = Images.booking;
let isWating = false;
let isBookingSuccess = true;
let isBookingInProcess = true;
let isCallBookingList = false;
let isResponse = false;
const _isSafari = isSafari();
class BookingSuccess extends Component {
    render() {
        const { user, location, isMobile, [reduxKeys.authen]: { token } } = this.props;
        const locationState = path(['state'], location) || '';
        const pdfLink = path(['ticketLink'], locationState) || '';
        const bookingInfo = path(['booking'], locationState) || JSON.parse(`${localStorage.getItem(bookingInfoKey)}`);
        const bookingId = path(['bookingId'], locationState) || path(['bookingId'], bookingInfo) || '';
        const bookingNo = path(['bookingNo'], locationState) || path(['bookingNo'], bookingInfo) || '';
        const paymentInfo = path(['paymentInfo'], locationState) || path(['paymentInfo'], bookingInfo) || '';
        // const bookingStatus = path(['bookingStatus'], locationState) || path(['bookingStatus'], bookingInfo) || '';
        const { subPaymentMethod = 'card' } = paymentInfo;
        const isLoggedIn = !!token;
        const title = path(['buyerInfo', 'title'], locationState) || '';
        const firstname = path(['buyerInfo', 'firstName'], locationState) || '';
        const lastname = path(['buyerInfo', 'lastName'], locationState) || '';
        const fullname = path(['data', 'fullName'], user) || `${title} ${firstname} ${lastname}`;
        // console.warn('[booking]', this.props);
        // console.warn('paymentMethod [booking] : ', bookingId, ':', bookingInfo, ' : ', subPaymentMethod);
        // console.warn('payload : ', locationState);
        const isWaitngSet = (is3DSecureActive || (subPaymentMethod !== paymentMethodConst.Card));
        if (isWaitngSet) {
            // const { selectedBooking, paymentMethod } = locationState;
            const { paymentMethod } = locationState;
            if ((window.location.hash === "#omiseWaiting" /* omiseKey */) && (!isCallBookingList)) {
                // console.warn('paymentMethod [omiseWaiting] : ', window.location.hash);
                isCallBookingList = true;
                isBookingSuccess = false;
                isBookingInProcess = false;
                isResponse = false;
                isWating = true;
                const _oldBookingInfo = JSON.parse(`${localStorage.getItem(bookingInfoKey)}`);
                const _parma = { bookingId: Boolean(_oldBookingInfo) ? _oldBookingInfo.bookingId : '' };
                setTimeout(() => { this.getBookingList(_parma); }, 2800);
            }
            switch (paymentMethod) {
                case paymentMethodConst.Omise:
                    {
                        // const {  authorized, return_uri } = locationState;
                        // const { status, authorize_uri } = omiseData;
                        const status = path(['status'], locationState) || path(['omiseData', 'status'], locationState);
                        const authorize_uri = path(['authorize_uri'], locationState) || path(['omiseData', 'authorize_uri'], locationState);
                        // console.warn('payload : ', locationState);
                        // console.warn('payload : ', status);
                        switch (status) {
                            case "successful" /* successful */:
                                {
                                }
                                break;
                            case "pending" /* pending */:
                                {
                                    isWating = true;
                                    localStorage.setItem(bookingInfoKey, JSON.stringify(bookingInfo));
                                    window.location.replace(authorize_uri);
                                    // window.location = authorize_uri;
                                }
                                break;
                            case "failed" /* failed */:
                                {
                                    const { failure_code, failure_message } = locationState;
                                    if (!!Dialog.error()) {
                                        Dialog.error({
                                            key: BOOKING_KEY,
                                            title: 'Omise error.',
                                            content: (failure_message || localization(failure_code)),
                                        });
                                    }
                                }
                                break;
                            default:
                                {
                                }
                                break;
                        }
                    }
                    break;
                case paymentMethodConst.Alienation:
                    {
                        isBookingSuccess = true;
                    }
                    break;
            }
        }
        else {
            isBookingSuccess = true;
        }
        // console.warn('isWating : ', isWating, ' isBookingSuccess : ', isBookingSuccess, ' isCallBookingList : ', isCallBookingList)
        switch (true) {
            case (isWaitngSet && isWating && !isResponse): {
                return this.initOmiseCalling();
            }
            case (is3DSecureActive && isWating && !isResponse): {
                return this.initOmiseCalling();
            }
            case (isResponse && isWating): {
                (!!dialog.loading()) && dialog.loading();
                return this.waitingPanel();
            }
            case (isBookingSuccess): {
                return this.successPanel(isMobile, (bookingNo || bookingId), fullname, pdfLink, isLoggedIn);
            }
            case (isBookingInProcess): {
                return this.inProcessPanel(isMobile, (bookingNo || bookingId), fullname, isLoggedIn);
            }
            default:
                {
                    return this.inCompletePanel(isMobile, (bookingNo || bookingId), fullname);
                }
        }
    }
    initOmiseCalling() {
        return <div />;
    }
    waitingPanel() {
        return (<ContentWrapper withMaxWidth><div /></ContentWrapper>);
    }
    inCompletePanel(isMobile, bookingId, fullname) {
        const { buttonStyle } = pageStyle;
        return (<ContentWrapper withMaxWidth>
                <FlexView vAlignContent='center' hAlignContent='center' column style={Styles.unselected}>
                    <div style={{ paddingTop: isMobile ? 40 : 130, paddingBottom: isMobile ? '25px' : '50px' }}>
                        <img src={unsuccess} alt="unsuccess" width={isMobile ? '200px' : '331px'}/>
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: isMobile ? '18px' : '29px',
            fontWeight: 500,
            color: '#5B5B5B',
            lineHeight: '40px',
        }}>
                        {fullname}
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: isMobile ? '28px' : '48px',
            color: Colors.textLabel,
            lineHeight: isMobile ? '50px' : '66px',
        }}>
                        {localization(`your_booking_incomplete`, `BookingLabel`)}
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: '21px',
            fontWeight: 300,
            color: '#5B5B5B',
            lineHeight: '29px',
            textAlign: 'center',
        }}>
                        {`${localization(`booking_no`, `BookingLabel`)} : `} {bookingId}
                    </div>
                    <FlexView wrap style={{ marginTop: isMobile ? '10px' : '0px', justifyContent: 'center' }}>
                        <Link to={pathName.INDEX} style={{
            textDecoration: 'none',
            width: '283px',
            height: isMobile ? '40px' : '45px',
            margin: isMobile ? '10px 8px' : '24px 15px',
        }}>
                            <Button.Theme buttonStyle={buttonStyle} label={localization(`go_landing_page`, `BookingLabel`)}/>
                        </Link>
                    </FlexView>
                </FlexView>
            </ContentWrapper>);
    }
    successPanel(isMobile, bookingId, fullname, pdfLink, isLoggedIn) {
        const { buttonStyle } = pageStyle;
        return (<ContentWrapper withMaxWidth>
                <FlexView vAlignContent='center' hAlignContent='center' column style={Styles.unselected}>
                    <div style={{ paddingTop: isMobile ? 40 : 130, paddingBottom: isMobile ? '25px' : '50px' }}>
                        <img src={success} alt="success" width={isMobile ? '200px' : '331px'}/>
                    </div>
                    
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: isMobile ? '18px' : '29px',
            fontWeight: 500,
            color: '#5B5B5B',
            lineHeight: '40px',
        }}>
                        {fullname}
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: isMobile ? '28px' : '48px',
            color: Colors.textLabel,
            lineHeight: isMobile ? '50px' : '66px',
        }}>
                        {localization(`your_booking_success`, `BookingLabel`)}
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: isMobile ? '18px' : '21px',
            fontWeight: 300,
            color: '#5B5B5B',
            lineHeight: '29px',
            textAlign: 'center',
        }}>
                        {`${localization(`booking_no`, `BookingLabel`)} : `} {bookingId}
                    </div>
                    <FlexView wrap style={{ marginTop: isMobile ? '10px' : '0px', justifyContent: 'center' }}>
                        <a href={pdfLink} target='_blank' rel="noopener" style={{
            color: 'unset',
            textDecoration: 'none',
            width: '283px',
            height: isMobile ? '40px' : '45px',
            margin: isMobile ? '10px 8px' : '24px 15px',
        }}>
                            <Button.Theme buttonStyle={buttonStyle} label={localization(`open_ticket`, `BookingLabel`)}/>
                        </a>
                        {isLoggedIn && (<Link to={pathName.PROFILE} style={{
            textDecoration: 'none',
            width: '283px',
            height: isMobile ? '40px' : '45px',
            margin: isMobile ? '10px 8px' : '24px 15px',
        }}>
                                    <Button.Theme buttonStyle={buttonStyle} label={localization(`manage_my_booking`, `BookingLabel`)}/>
                                </Link>)}
                    </FlexView>
                </FlexView>
            </ContentWrapper>);
    }
    inProcessPanel(isMobile, bookingId, fullname, isLoggedIn) {
        const { buttonStyle } = pageStyle;
        return (<ContentWrapper withMaxWidth>
                <FlexView vAlignContent='center' hAlignContent='center' column style={Styles.unselected}>
                    <div style={{ paddingTop: isMobile ? 40 : 130, paddingBottom: isMobile ? '25px' : '50px' }}>
                        <img src={success} alt="success" width={isMobile ? '200px' : '331px'}/>
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: isMobile ? '18px' : '29px',
            fontWeight: 500,
            color: '#5B5B5B',
            lineHeight: '40px',
        }}>
                        {fullname}
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: isMobile ? '28px' : '48px',
            color: Colors.textLabel,
            lineHeight: isMobile ? '50px' : '66px',
        }}>
                        {localization(`your_booking_inprocess`, `BookingLabel`)}
                    </div>
                    <div style={{
            fontFamily: Fonts.family.avenir,
            fontSize: '21px',
            fontWeight: 300,
            color: '#5B5B5B',
            lineHeight: '29px',
            textAlign: 'center',
        }}>
                        {`${localization(`booking_no`, `BookingLabel`)} : `} {bookingId}
                    </div>
                    <FlexView wrap style={{ marginTop: isMobile ? '10px' : '0px', justifyContent: 'center' }}>
                        <Link to={pathName.INDEX} style={{
            textDecoration: 'none',
            width: '283px',
            height: isMobile ? '40px' : '45px',
            margin: isMobile ? '10px 8px' : '24px 15px',
        }}>
                            <Button.Theme buttonStyle={buttonStyle} label={localization(`go_landing_page`, `BookingLabel`)}/>
                        </Link>
                        {isLoggedIn && (<Link to={pathName.PROFILE} style={{
            textDecoration: 'none',
            width: '283px',
            height: isMobile ? '40px' : '45px',
            margin: isMobile ? '10px 8px' : '24px 15px',
        }}>
                                    <Button.Theme buttonStyle={buttonStyle} label={localization(`manage_my_booking`, `BookingLabel`)}/>
                                </Link>)}
                    </FlexView>
                </FlexView>
            </ContentWrapper>);
    }
    getBookingList(payload) {
        // console.log(payload);
        this.props.dispatch(BookingActions.bookingInfoRequest(payload, (res) => {
            console.warn('bookingListRequest : ', res);
            if (!!res) {
                const { booking } = res;
                const { bookingStatus, bookingId } = booking;
                const _oldBookingInfo = JSON.parse(`${localStorage.getItem(bookingInfoKey)}`);
                isResponse = true;
                // console.warn('bookingListRequest : ', bookingId, ' : ', _oldBookingInfo.bookingId, ' : ', bookingId === _oldBookingInfo.bookingId)
                if (bookingId === _oldBookingInfo.bookingId) {
                    switch (bookingStatus) {
                        case "approved" /* APPROVED */:
                            {
                                isBookingSuccess = true;
                                isWating = false;
                                // console.log('isBookingSuccess : ', booking);
                                const { packageInfo, priceTotal } = booking;
                                const { packageId, type, bookingInfo, name } = Array.isArray(packageInfo) ? packageInfo[0] : packageInfo;
                                const { adult, infant, kid } = bookingInfo;
                                const { subTotal, currency } = priceTotal;
                                const newInfo = { packageId, type, name, amount: (adult + infant + kid), summary: subTotal, currency };
                                TraceFacebookPixel({ trackType: TrackType.Purchase, data: newInfo });
                                this.props.history.replace({ pathname: pathName.BOOKING_SUCCESS, state: { ...booking }, search: '', hash: 'omiseWaiting' });
                            }
                            break;
                        case "wait-approved" /* WAITAPPROVED */:
                            {
                                isBookingInProcess = true;
                                isWating = false;
                                // console.log('isBookingInProcess : ', booking);
                                const { packageInfo, priceTotal } = booking;
                                const { packageId, type, bookingInfo, name } = Array.isArray(packageInfo) ? packageInfo[0] : packageInfo;
                                const { adult, infant, kid } = bookingInfo;
                                const { subTotal, currency } = priceTotal;
                                const newInfo = { packageId, type, name, amount: (adult + infant + kid), summary: subTotal, currency };
                                TraceFacebookPixel({ trackType: TrackType.Purchase, data: newInfo });
                                this.props.history.replace({ pathname: pathName.BOOKING_SUCCESS, state: { ...booking }, search: '', hash: 'omiseWaiting' });
                            }
                            break;
                        default:
                            {
                                try {
                                    const { packageInfo, priceTotal } = booking;
                                    const { packageId, type, bookingInfo, name } = Array.isArray(packageInfo) ? packageInfo[0] : packageInfo;
                                    const { adult, infant, kid } = bookingInfo;
                                    const { subTotal, currency } = priceTotal;
                                    const newInfo = { status: bookingStatus, packageId, type, name, amount: (adult + infant + kid), summary: subTotal, currency };
                                    TraceFacebookPixel({ trackType: TrackType.CustomizeProduct, data: newInfo });
                                }
                                catch (error) {
                                    const newInfo = { status: bookingStatus, error: 'data error' };
                                    TraceFacebookPixel({ trackType: TrackType.CustomizeProduct, data: newInfo });
                                }
                                isWating = false;
                                isBookingSuccess = false;
                                isBookingInProcess = false;
                                this.forceUpdate();
                            }
                            break;
                    }
                }
            }
            else {
                this.props.history.replace({ pathname: pathName.INDEX });
            }
        }));
    }
}
const mapStatetoProps = (state) => ({
    [reduxKeys.user]: state[reduxKeys.user],
    [reduxKeys.authen]: state[reduxKeys.authen],
    [reduxKeys.booking]: state[reduxKeys.booking],
    isMobile: state[reduxKeys.screen].isMobile,
});
export default compose(connect(mapStatetoProps), withRouter)(BookingSuccess);
const pageStyle = {
    buttonStyle: {
        padding: '10px 5px',
        letterSpacing: _isSafari ? '-1px' : '1.85px',
    },
};
// interface IScreen { isMobile?: boolean; isUnderDefault?: boolean; }
// const { avenir } = Fonts.family;
// const fullNameContianer = styled.div`
// font-family:${avenir};
// font-size: 29px;
// font-weight: 500;
// color: rgb(91, 91, 91);
// line-height: 40px;
// ${(props: IScreen) => props.isMobile && css`font-size:18px;`}`;
