// ===================================================================================================================================
// Main Themes selector.
// ===================================================================================================================================
import { isPlatForm } from "../config/";
import { PLATFORM_NAME } from "../config/platform";
console.log(` === Themes : ${isPlatForm} ===`);
let platforms;
switch (`${isPlatForm}`) {
    case PLATFORM_NAME.PHIPHITOURS:
        {
            platforms = require("./PhiPhiTours");
        }
        break;
    case PLATFORM_NAME.TRAVISGO:
        {
            platforms = require("./TravisGo");
        }
        break;
    default:
        {
            platforms = require("./TravisGo");
        }
        break;
}
const { Metrics, Svg, Colors, Images, Fonts, ZIndex, Styles, Device, MockReviews } = platforms;
export { Metrics, Svg, Colors, Images, Fonts, ZIndex, Styles, Device, MockReviews };
// ===================================================================================================================================
// export * from "./TravisGo/Metrics";
// export * from "./TravisGo/Svg";
// export * from "./TravisGo/Colors";
// export * from "./TravisGo/Images";
// export * from "./TravisGo/Fonts";
// export * from "./TravisGo/ZIndex";
// export * from "./TravisGo/Styles";
// export * from "./TravisGo/Device";
// import { isPlatForm } from "../config/";
// import { PLATFORM_NAME } from "../config/platform";
// console.log(` === Build Themes`, PLATFORM_NAME.PHIPHITOURS, ' : ', isPlatForm)
// export * from "./TravisGo";
// // export * from "./PhiPhiTours";
