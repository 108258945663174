// ===================================================================================================================================
// PhiPhiTours
// ===================================================================================================================================
import { language } from "../../../constant";
import { currency } from "../../../constant/json/currency";
import * as mainAPI from "../../api";
// ===================================================================================================================================
const isFirebase = true;
const isDev = process.env.NODE_ENV === `development`; // `phiphi_develop`;
const isDevelop = (isDev || isFirebase);
const isProduction = !isDevelop;
const buildVer = (isDevelop) ? '1.0.3' : '1.0.1';
// console.warn('isDevelop : ', process.env.NODE_ENV)
const host = (isDevelop) ? `https://api-ppdev.travisgo.com` : `https://api.phiphitours.com`;
const loopbackHost = (isDevelop) ? `https://api-ppdev.travisgo.com/lb` : `https://api.phiphitours.com/lb`;
const directionURL = (isDevelop) ? `https://phiphi-dev.firebaseapp.com/` : `https://www.phiphitours.com/`;
const businessURL = (isDevelop) ? `https://osppdev.travisgo.com/` : `https://os.phiphitours.com/`;
const dbdCertificationURL = `https://www.trustmarkthai.com/callbackData/initialize.js?t=8444c-26-5-94012d564c55a2423f40b5775262906a7e4838e4`;
// ===================================================================================================================================
// (!isDevelop) && console.warn(` === Build Ver.(${buildVer}) === `);
// ===================================================================================================================================
const api = mainAPI.api;
api.host = host;
api.loopbackHost = loopbackHost;
// ===================================================================================================================================
const defaultSetting = { language: language.EN, currency: currency[0].value };
const social = {
    facebook: {
        appId: "1088707564672598",
        pageId: (isDevelop ? "374650436604380" : "165657040271752"),
        permission: "name,email,picture",
    },
    google: { clientId: "953111485803-ra470233bn531m4gu7c3qfsja1rrm572.apps.googleusercontent.com" },
};
const trace = { facebookPixelId: '1727426540703026' };
const mockApiResponse = false;
const omiseKey = isDevelop ? 'pkey_test_5fnki8c4z6dxt6bwa27' : 'pkey_5gn5n9n1crk5amo19l0'; // 'pkey_test_5cxkrvshiy016tyszma';
const is3DSecureActive = true; // case phiphi active all branch;
const license = {
    TATLicense: '31/00836',
    DBDLicense: '',
};
const footer = {
    link: {
        license: `http://103.80.100.92:8087/mobiletourguide/info/license/tour/`,
        ig: 'https://www.instagram.com/phiphitours',
        fb: 'https://www.facebook.com/kohphiphitourskrabi',
        youtube: 'https://www.youtube.com/user/phiphitours11',
        line: 'https://line.me/R/ti/p/%40phiphitours',
        twt: '',
    },
};
const partner = {
    id: undefined,
    name: undefined
};
// ==================================================================================================================================
export { api, defaultSetting, host, directionURL, dbdCertificationURL, businessURL, social, mockApiResponse, omiseKey, 
// debugSetting,
// reduxPersist,
footer, isDev, isDevelop, isProduction, isFirebase, trace, buildVer, license, is3DSecureActive, partner };
