import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { packageType } from '../../constant';
import { GetForm } from './SummaryPanel';
export var FormType;
(function (FormType) {
    FormType["Foreign"] = "Foreign";
    FormType["ForeignAdult"] = "ForeignAdult";
    FormType["ForeignKid"] = "ForeignKid";
    FormType["ForeignInfant"] = "ForeignInfant";
})(FormType || (FormType = {}));
const _foreign = { generation: ['Adult', 'Kid', 'Infant'], currency: '฿' };
let AmountSelect;
class ForeignPanel extends Component {
    constructor() {
        super(...arguments);
        this.state = { Foreign: 0, ForeignAdult: 1, ForeignKid: 0, ForeignInfant: 0 };
    }
    render() {
        const { packageInfo, amoutSelect, type } = this.props;
        const { foreignPrice, optionLists, packageId } = packageInfo.packageInfo; // promotionInfo;
        const isOneDayTrips = (type === packageType.OneDayTrip); // || isJoinType);
        AmountSelect = amoutSelect;
        return (<div key={`Foreign_${packageId}`} style={_style.summary_container}>
                <div style={{ width: '100%', display: 'flex', backgroundColor: '#346DAA', padding: '10px 0px' }}>
                    <div style={{ flex: '7', margin: '5px 10px' }}>
                        <label style={_style.label_packageName}>{FormType.Foreign}</label>
                    </div>
                </div>
                {this.SelectedForeignContainer(foreignPrice, optionLists, isOneDayTrips)}
            </div>);
    }
    SelectedForeignContainer(price, optionLists, istype) {
        console.log('SelectedForeign : ', _foreign, ' : ', optionLists);
        const { generation, currency } = _foreign;
        return generation.map((generations, index) => {
            return (<div key={index} style={{
                width: '100%', height: '50px',
                display: 'flex', textAlign: 'left', borderBottom: '1px solid #DFDFDF ',
            }}>
                    <div style={{ flex: '2', margin: '5px 10px' }}>
                        <label style={_style.label_topic}>{generations}</label>
                    </div>
                    <div style={{ flex: 4, paddingTop: '5px' }}>
                        {GetForm(`${FormType.Foreign}${generations}`, AmountSelect, istype)}</div>
                    <div style={{ flex: 2 }}>
                        {(price[generations.toLocaleLowerCase()] !== undefined) &&
                <label style={_style.label_topic}>
                                {`${currency} ${price[generations.toLocaleLowerCase()]}`}</label>}
                    </div>
                </div>);
        });
    }
}
export default withRouter(ForeignPanel);
const _style = {
    summary_container: {
        width: '100%',
        minHeight: '138px',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
        marginBottom: '20px',
    },
    label_topic: {
        height: '31px',
        width: '100%',
        color: '#346DAA',
        fontSize: '16px',
        lineHeight: '31px',
        display: 'inline-grid',
        marginTop: '5px',
    },
    label_packageName: {
        height: '31px',
        width: '100%',
        color: '#FFFFFF',
        fontSize: '16px',
    },
};
