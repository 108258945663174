export default {
    class: 'ระดับ',
    email: 'อีเมล',
    registered_date: 'วันที่สมัครสมาชิก',
    tel: 'เบอร์โทรศัพท์',
    reserveTel: 'เบอร์โทรศัพท์สำรอง',
    dialingCode: 'รหัสประเทศ',
    booking_history: 'ประวัติการจอง',
    review_package: 'รีวิว',
    marketing_channel: 'ช่องทางการตลาด',
    find_booking: 'ค้นหาการจอง',
    type: 'ประเภท',
    status: 'สถานะ',
    booking_id: 'หมายเลขการจอง',
    program_tour: 'โปรแกรมทัวร์',
    payment_method: 'วิธีการชำระเงิน',
    travel_date: 'วันที่',
    profile_date: 'วันที่',
    price_booking: 'ราคา',
    waiting: 'รอตรวจสอบ',
    inprocess: 'กำลังเดินการ',
    approved: 'อนุมัติ',
    reject: 'ปฏิเสธ',
    ticket: 'ตั๋ว',
    confirm_booking: 'ยืนยันการจอง',
    wallet: 'รายได้รวม',
    sold_product: 'ประวัติการขาย',
    program_name: 'เลขโปรแกรม , ชื่อ',
    year: 'ปี',
    sold: 'ยอดขาย',
    income: 'ยอดเงิน',
    find_program: 'ค้นหาโปรแกรม',
    month: 'เดือน',
    program_id: 'หมายเลขโปรแกรม',
    totel_sold: 'ยอดขาย',
    edit_profile: 'แก้ไขโปรไฟล์',
    upload: 'อัพโหลด',
    title: 'คำนำหน้าชื่อ',
    firstname: 'ชื่อ',
    lastname: 'นามสกุล',
    submit: 'บันทึก',
    change_password: 'เปลี่ยนรหัสผ่าน',
    old_password: 'รหัสผ่านเก่า',
    new_password: 'รหัสผ่านใหม่',
    confirm_new_password: 'ยืนยันรหัสผ่านใหม่',
    more_info: 'ข้อมูลเพิ่มเติม ...',
    bookind_id_name: 'เลขการจอง , ชื่อสินค้า',
    refund_reasons: 'สาเหตุการยกเลิกการจอง',
    apply_refund: 'ยืนยันการขอเงินคืน',
    refund_detail: 'รายละเอียดการคืนเงิน',
    refund_amount: 'จำนวนเงินคืน',
    view_refound: 'รายละเอียดนโยบายการคืนเงิน',
    manage_booking: 'จัดการการจอง',
    refund: 'ขอคืนเงิน',
    accept_refund_condition: 'ยอมรับเงื่อนไขการคืนเงิน',
    view_more: 'ดูเพิ่มเติม',
    order_no: 'หมายเลขการจอง',
    booking_date: 'วันที่จอง',
    actual_payment: 'จ่ายจริง',
    order_placed: 'เลือกโปรแกรม',
    payment_successful: 'ชำระเงิน',
    order_confirmed: 'ยืนยันการชำระเงิน',
    on_delivery: 'กำลังใช้บริการ',
    complete: 'เสร็จสมบูรณ์',
    in_process: 'กำลังดำเนินการ',
    booking_cancel: 'การจองนี้ถูกยกเลิก',
    for_detail: 'สำหรับรายละเอียดโปรดส่งอีเมลไปที่ info@Travisgo.com',
    discount: 'ส่วนลด',
    adult: 'ผู้ใหญ่',
    kid: 'เด็ก',
    infant: 'ทารก',
    abandon: 'ไม่มาใช้บริการ',
    request_refund: 'ขอคืนเงิน',
    success: 'คืนเงินสำเร็จ',
    refuse: 'ปฏิเสธการขอคืนเงิน',
    failed: 'ขอคืนเงินไม่สำเร็จ',
    wait_approved: 'รอการอนุมัติ',
    bill_payment: 'Bill Payment',
};
