import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { compose } from "recompose";
import { path } from "ramda";
import Rating from 'react-rating';
import Dialog from "../../components/dialog";
import { reduxKeys } from "../../constant";
import PackageActions from "../../redux/PackageRedux";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHeart as Heart } from '@fortawesome/free-solid-svg-icons'
// <FontAwesomeIcon icon={['far', 'heart']} style={{ width: 28, height: 32, color: '#ffffff' }} />
// import AuthenActions from "../../redux/AuthenRedux";
import { Images } from '../../themes';
import styled, { css } from 'styled-components';
import localization from '../../services/localization';
import TraceFacebookPixel, { TrackType } from '../../components/TraceInfo/FacebookPixel';
const isAskDialog = false;
const { wishListActive, wishListDefault } = Images.package;
class WishListIconPanel extends Component {
    constructor(props) {
        super(props);
        this.infoModal = null;
        this.DialogAddWishList = (packageInfo, rating) => {
            const isLoggedIn = !!path([reduxKeys.user, 'data'], this.props);
            if (isLoggedIn) {
                const { packageId } = packageInfo;
                TraceFacebookPixel({ trackType: TrackType.AddToWishlist, data: packageInfo });
                this.postWishList(packageId);
                // ==================================================================================================
                // Disable dialog ask before add/remove wishlist.
                if (isAskDialog) {
                    const isAddWishList = (rating === 0);
                    this.infoModal.ask({
                        title: (isAddWishList) ? 'Do you want to add wishlist ?' : 'Do you want to delete wishlist ?',
                        actions: [
                            {
                                label: 'No',
                                action: () => { (isAddWishList) && this.setState({ value: 0 }); },
                            },
                            {
                                label: 'Yes',
                                action: () => {
                                    const { packageId } = packageInfo;
                                    TraceFacebookPixel({ trackType: TrackType.AddToWishlist, data: packageInfo });
                                    this.postWishList(packageId);
                                },
                            },
                        ],
                    });
                }
                // ==================================================================================================
            }
            else {
                this.infoModal.ask({
                    title: localization(`please_login`, `LandingLabel`),
                    actions: [
                        {
                            label: localization(`cancel`, `BookingLabel`),
                            action: () => { this.setState({ value: 0 }); },
                        },
                        {
                            label: localization(`ok`, `DialogLabel`),
                            action: () => { Dialog.authen().openModal(); },
                        },
                    ],
                });
            }
        };
        this.state = { value: 0 };
    }
    postWishList(packageId) {
        const { dispatch, user } = this.props;
        const userId = path(['data', '_id'], user);
        dispatch(PackageActions.postWishListRequest({ userId, packageId }, () => {
            dispatch(PackageActions.getWishListRequest({ userId }));
        }));
        this.setState({ value: 1 });
    }
    render() {
        const { isMobile, packageInfo, [reduxKeys.package]: { wishLists = [] } } = this.props;
        const { packageId } = packageInfo;
        const isLogin = Boolean(path([reduxKeys.user, 'data'], this.props));
        const rating = (isLogin) ? (Boolean((wishLists && wishLists.find((item) => item.packageId === packageId))) ? 1 : 0) : 0;
        // const { value } = this.state;
        // console.log('wishLists : ', wishLists, ' : ', rating, ' : ', this.props);
        return (<WishListContianer>
                <Rating onClick={() => this.DialogAddWishList(packageInfo, rating)} emptySymbol={<WishIcon isMobile={isMobile} src={wishListDefault} alt='wishListDefault'/>} fullSymbol={<WishIcon isMobile={isMobile} src={wishListActive} alt='wishListActive'/>} placeholderSymbol={<WishIcon isMobile={isMobile} src={wishListActive} alt='wishListActive'/>} placeholderRating={0} stop={1} initialRating={rating}/>
                <Dialog.Info ref={(ref) => this.infoModal = ref}/>
            </WishListContianer>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.package]: state[reduxKeys.package],
    [reduxKeys.user]: state[reduxKeys.user],
});
export default compose(withRouter, connect(mapStateToProps))(WishListIconPanel);
const WishListContianer = styled.div `
top: 1rem;
width: 100%;
text-align: left;
position: absolute;
padding-left: 13px; `;
const WishIcon = styled.img `
width: 34px;
height: 30px;
${(props) => props.isMobile && css `width: 28px;height: 25px;`}`;
// =============================================================================================================
