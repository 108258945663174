export const apiResponse = {
    destinationList: {
        destinations: [
            {
                id: 1,
                name: 'Krabi',
            },
            {
                id: 2,
                name: 'Phuket',
            },
            {
                id: 3,
                name: 'Samui',
            },
            {
                id: 4,
                name: 'Pha-Ngan',
            },
        ],
    },
    activityList: {
        activities: [
            {
                id: 1,
                name: 'Diving',
                imageUrl: '',
            },
            {
                id: 2,
                name: 'Kayaking',
                imageUrl: '',
            },
            {
                id: 3,
                name: 'Hiking',
                imageUrl: '',
            },
            {
                id: 4,
                name: 'Eating',
                imageUrl: ''
            },
            {
                id: 5,
                name: 'Shopping',
                imageUrl: '',
            },
        ],
    },
    userData: {
        user: {
            active: 1,
            email: "title_tanapon@hotmail.com",
            fbid: "10204211833012108",
            firstName: "Tanapon",
            isAcceptInformation: true,
            lastName: "Kanokpattanakorn",
            tel: "+66890555511",
            type: "facebook",
        },
    },
    updateUser: {
        user: {
            firstName: "Foo",
            lastName: "Bar",
        },
    },
};
export const mockOptions = {
    title: [
        {
            id: '1',
            label: 'Mr.',
        },
        {
            id: '2',
            label: 'Ms.',
        },
        {
            id: '3',
            label: 'Mrs.',
        },
    ],
    title_TH: [
        {
            id: '1',
            label: 'นาย',
        },
        {
            id: '2',
            label: 'นางสาว',
        },
        {
            id: '3',
            label: 'นาง',
        },
    ],
    bookingStatus: [
        {
            id: 0,
            label: 'All',
            default: true,
        },
        // {
        //     id: 'waiting',
        //     label: 'Waiting',
        // },
        {
            id: 'in-process',
            label: 'In-process',
        },
        {
            id: 'wait-approved',
            label: 'Wait-approved',
        },
        {
            id: 'approved',
            label: 'Approved',
        },
        {
            id: 'on-delivery',
            label: 'On-delivery',
        },
        {
            id: 'complete',
            label: 'Complete',
        },
        {
            id: 'refund',
            label: 'Refund',
        },
        {
            id: 'abandon',
            label: 'Abandon',
        },
    ],
    bookingType: [
        {
            id: 0,
            label: 'All',
            default: true,
        },
        {
            id: 'OneDayTrip',
            label: 'OneDayTrip',
        },
        {
            id: 'Transfer',
            label: 'Transfer',
        },
    ],
    paymentMethod: [
        {
            id: 0,
            label: 'Filter payment method',
            default: true,
        },
        {
            id: 'Alienation',
            label: 'Alienation',
        },
        {
            id: 'Omise',
            label: 'Credit card',
        },
    ],
    Year: [
        {
            id: 0,
            label: '2018',
            default: true,
        },
        {
            id: 2019,
            label: '2019',
        },
        {
            id: 2020,
            label: '2020',
        },
        {
            id: 2021,
            label: '2021',
        },
    ],
    Month: [
        {
            id: 0,
            label: "All",
            short: "All",
            days: 0,
            default: true,
        },
        {
            id: 1,
            label: "January",
            short: "Jan",
            days: 31,
        },
        {
            id: 2,
            label: "February",
            short: "Feb",
            days: 28,
        },
        {
            id: 3,
            label: "March",
            short: "Mar",
            days: 31,
        },
        {
            id: 4,
            label: "April",
            short: "Apr",
            days: 30,
        },
        {
            id: 5,
            label: "May",
            short: "May",
            days: 31,
        },
        {
            id: 6,
            label: "June",
            short: "Jun",
            days: 30,
        },
        {
            id: 7,
            label: "July",
            short: "Jul",
            days: 31,
        },
        {
            id: 8,
            label: "August",
            short: "Aug",
            days: 31,
        },
        {
            id: 9,
            label: "September",
            short: "Sep",
            days: 30,
        },
        {
            id: 10,
            label: "October",
            short: "Oct",
            days: 31,
        },
        {
            id: 11,
            label: "November",
            short: "Nov",
            days: 30,
        },
        {
            id: 12,
            label: "December",
            short: "Dec",
            days: 31,
        },
    ],
    Month_TH: [
        {
            id: 0,
            label: "ทั้งหมด",
            short: "ทั้งหมด",
            days: 0,
            default: true,
        },
        {
            id: 1,
            label: "มกราคม",
            short: "ม.ค.",
            days: 31,
        },
        {
            id: 2,
            label: "กุมพาพันธ์",
            short: "ก.พ.",
            days: 28,
        },
        {
            id: 3,
            label: "มีนาคม",
            short: "มี.ค.",
            days: 31,
        },
        {
            id: 4,
            label: "เมษายน",
            short: "เม.ย.",
            days: 30,
        },
        {
            id: 5,
            label: "พฤษภาคม",
            short: "พ.ค.",
            days: 31,
        },
        {
            id: 6,
            label: "มิถุนายน",
            short: "มิ.ย.",
            days: 30,
        },
        {
            id: 7,
            label: "กรกฎาคม",
            short: "ก.ค.",
            days: 31,
        },
        {
            id: 8,
            label: "สิงหาคม",
            short: "ส.ค.",
            days: 31,
        },
        {
            id: 9,
            label: "กันยายน",
            short: "ก.ย.",
            days: 30,
        },
        {
            id: 10,
            label: "ตุลาคม",
            short: "ต.ค.",
            days: 31,
        },
        {
            id: 11,
            label: "พฤศจิกายน",
            short: "พ.ย.",
            days: 30,
        },
        {
            id: 12,
            label: "ธันวาคม",
            short: "ธ.ค.",
            days: 31,
        },
    ],
    socialOption: [{
            label: "WhatsApp",
            id: "WhatsApp",
        }, {
            label: "Wechat",
            id: "Wechat",
        }, {
            label: "Line",
            id: "Line",
        }],
};
