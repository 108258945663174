import { language } from "../../constant";
import moment from 'moment';
export function SetDate(_date) {
    const strDate = new Date(_date);
    const date = `${getFormatted(strDate.getDate())}-${getFormatted((strDate.getMonth() + 1))}-${strDate.getFullYear()} : ${getFormatted(strDate.getHours())}:${getFormatted(strDate.getMinutes())}`;
    return date;
}
export function SetDateFormat(_date) {
    const _selectDate = new Date(_date);
    return `${_selectDate.getFullYear()}-${getFormatted(_selectDate.getMonth() + 1)}-${getFormatted(_selectDate.getDate())}`;
}
export function SetDayFormat(_date) {
    const _selectDate = new Date(_date);
    return `${_selectDate.getDate()}-${_selectDate.getMonth() + 1}-${_selectDate.getFullYear()}`;
}
export function DisplayDateFormat(_date, _space = '-') {
    const _selectDate = new Date(_date);
    return `${getFormatted(_selectDate.getDate())}${_space}${getFormatted(_selectDate.getMonth() + 1)}${_space}${_selectDate.getFullYear()}`;
}
export function DateWithLocal(_date, _language = language.EN) {
    const _selectDate = new Date(_date);
    return `${getFormatted(_selectDate.getDate())} ${(monthS(_language)[_selectDate.getMonth()])} ${_selectDate.getFullYear()}`;
}
export function ConvertDate(_date) {
    return (_date[2] !== '-') ? _date.substr(0, 10).split("-").reverse().join("-") : _date;
}
export function ConvertDateAll(_date) {
    const regex = /\//gi;
    const shotDate = _date.replace(regex, '-');
    return shotDate.substr(0, 10).split("-").reverse().join("-");
}
export function getFormatted(_info) {
    return _info < 10 ? `0${_info}` : _info;
}
export function InPeriodTime(start, end, check) {
    const isDate = Date.parse(check);
    // const startDate = Date.parse(start);
    // const endDate = Date.parse(end);
    return (isDate <= Date.parse(end) && isDate >= Date.parse(start));
}
export function ExPeriodTime(start, end, check) {
    const isDate = Date.parse(check);
    // const startDate = Date.parse(start);
    // const endDate = Date.parse(end);
    return (isDate <= Date.parse(start));
}
export function getDayWithFilterDate(filterDate) {
    const isDate = new Date(filterDate || '');
    const _fiterDate = getDay.find((_date) => _date.id === isDate.getDay());
    return (_fiterDate.label || '');
}
export const getDay = [
    { id: 0, label: 'Sun' },
    { id: 1, label: 'Mon' },
    { id: 2, label: "Tue" },
    { id: 3, label: 'Wed' },
    { id: 4, label: 'Thu' },
    { id: 5, label: 'Fri' },
    { id: 6, label: 'Sat' },
];
export var Month;
(function (Month) {
    Month["Jan"] = "Jan";
    Month["Feb"] = "Feb";
    Month["Mar"] = "Mar";
    Month["Apr"] = "Apr";
    Month["May"] = "May";
    Month["June"] = "June";
    Month["July"] = "July";
    Month["Aug"] = "Aug";
    Month["Sept"] = "Sept";
    Month["Oct"] = "Oct";
    Month["Nov"] = "Nov";
    Month["Dec"] = "Dec";
})(Month || (Month = {}));
export const monthL = (Language = language.EN) => {
    switch (Language) {
        case language.TH: return ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
        case language.EN: return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        default: return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    }
};
export const monthS = (Language = language.EN) => {
    switch (Language) {
        case language.TH: return ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
        case language.EN: return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        default: return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    }
};
export const generateDateRange = (startDate, endDate, step = 1, modifier = 'day', format = 'YYYY-MM-DD') => {
    // simple dates = generateDateRange('2018-11-07', '2018-12-01', 2, 'day') = result ['2018-11-09','2018-11-11',...]
    // generateDateRange || use moment-range
    const dates = [];
    let currentDate = moment(startDate);
    const lastDate = moment(endDate);
    while (currentDate <= lastDate) {
        dates.push(currentDate.format(format));
        currentDate = currentDate.add(step, modifier);
    }
    return dates;
};
