import React, { Component } from 'react';
import { connect } from "react-redux";
import { path } from "ramda";
import Panel, { PanelHeader, PanelContent } from "../Panel";
import { reduxKeys } from "../../constant";
import { CheckBoxWithStyle } from "../form/CheckBox";
import { Form } from '../form';
import formtypes, { fieldsName, } from '../../formtypes';
import { Colors } from "../../themes";
import localization from "../../services/localization";
class TravelerInfo extends Component {
    constructor(props) {
        super(props);
        const { adult, kid, infant, passengers } = props.bookingInfo;
        const travelerAll = props.isTransfer === true ? passengers : adult + kid + infant;
        if (props.isAllTraveller === true) {
            this.travelRef = new Array(travelerAll).fill('');
            props.formRef(this);
        }
        this.state = {
            useUserData: true,
        };
    }
    submit() {
        if (this.props.isAllTraveller === true) {
            return this.travelRef.map((each) => each.submit());
        }
    }
    render() {
        const { user, [reduxKeys.authen]: { token }, [reduxKeys.settings]: { language }, isMobile, bookingInfo, isAllTraveller, isTransfer, } = this.props;
        const defaultValues = {};
        const { adult, kid, infant, passengers } = bookingInfo;
        const travelerAll = isTransfer === true ? passengers : adult + kid + infant;
        if (this.state.useUserData) {
            Object.keys(fieldsName.guestInfoFieldsName).forEach((each) => {
                defaultValues[each] = path(['data', each], user);
            });
        }
        if (isAllTraveller === false) {
            return null;
        }
        return (<Panel>
                <PanelHeader style={{
            backgroundColor: Colors.panel.header,
            padding: isMobile ? 0 : '0 0 0 40px',
            justifyContent: isMobile ? 'center' : 'flex-start',
        }}>
                    {`Traveler's Detail`}
                </PanelHeader>
                <PanelContent style={{ backgroundColor: Colors.panel.content }}>
                    {new Array(travelerAll).fill('').map((v, i) => {
            const firstForm = i === 0;
            return <div key={i} style={{ fontSize: 22, paddingTop: 10, borderBottom: '1px solid #c8c8c8' }}>
                            {`Traveller ${i + 1}`}
                            {i === 0 && token && (<CheckBoxWithStyle label={localization(`use_my_account`, `BookingLabel`)} rootStyle={{
                marginLeft: '-14px',
                marginBottom: '-14px',
            }} value={this.state.useUserData} onChange={(val) => {
                this.setState({ useUserData: val });
            }}/>)}
                            <Form form={formtypes.travelerInfo({
                defaultValues,
                firstForm,
                language,
                isMobile,
            })} ref={(ref) => {
                this.travelRef[i] = ref;
            }} style={{
                marginTop: 15,
            }}/>
                        </div>;
        })}
                </PanelContent>
            </Panel>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.user]: state[reduxKeys.user],
    [reduxKeys.settings]: state[reduxKeys.settings],
    [reduxKeys.authen]: state[reduxKeys.authen],
    isMobile: state[reduxKeys.screen].isMobile,
});
export default connect(mapStateToProps)(TravelerInfo);
