import React, { Component } from 'react';
import { omit, equals, path, } from "ramda";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
// import { withStyles } from 'material-ui/styles';
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
// import injectSheet from 'react-jss';
import { fieldsName } from "../../formtypes";
import { inputType } from "../../constant";
import validator from '../../utils/validator';
import { CheckBoxWithStyle } from "./CheckBox";
import { InputWithStyle } from "./TextInput";
import Select from "./Select";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import DateTimePicker from "./DateTimePicker";
import CalendarForm from "./Calendar";
import CustomCalendar from "./CustomCalendar";
import Avatar from "../Avatar";
import Radio from "./Radio";
class ChildrenForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorId: 'blank',
            value: this.getStateValue(props.form.value),
        };
        if (typeof props.formRef === 'function') {
            props.formRef(this);
        }
        this.validate = this.validate.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.require_value, this.props.require_value) && this.state.value !== '') {
            const { form: { validate, fieldName } } = this.props;
            if (fieldName === fieldsName.regiserFieldsName.confirm_password) {
                const errorId = validator({
                    confirmPassword: this.state.value,
                    password: nextProps.require_value,
                }, validate);
                if (!!errorId) {
                    this.setState({ error: true, errorId });
                }
                else {
                    this.clearError();
                }
            }
        }
        if (!equals(nextProps.form.value, this.props.form.value)) {
            this.setState({ value: this.getStateValue(nextProps.form.value) }, () => {
                this.clearError();
            });
        }
    }
    getStateValue(value) {
        const { form: { type } } = this.props;
        let defaultValue = '';
        if (type === inputType.CHECKBOX) {
            defaultValue = false;
        }
        return (typeof value === 'undefined' || value == null) ? defaultValue : value;
    }
    validate(value = this.state.value) {
        const { form: { validate, fieldName, transform, }, require_value, } = this.props;
        let errorId = null;
        if (typeof validate === 'function') {
            errorId = validate(value);
        }
        else if (!!validate) {
            if (fieldName === fieldsName.regiserFieldsName.confirm_password) {
                errorId = validator({ confirmPassword: value, password: require_value }, validate);
            }
            else {
                errorId = validator(value, validate, transform);
            }
        }
        else {
            return true;
        }
        if (!!errorId) {
            this.setState({ error: true, errorId });
        }
        else {
            this.clearError();
        }
        return !errorId;
    }
    clearError() {
        this.setState({ error: false, errorId: 'blank' });
    }
    renderInput() {
        const { form, reRenderForm, filter } = this.props;
        const { value, error, errorId } = this.state;
        const props = {
            value, error, errorId,
            validate: this.validate,
            ...omit(['value', 'type', 'validate'], form),
            onChange: (val) => {
                if (form.disabled) {
                    return;
                }
                this.setState({ value: val }, () => {
                    if (form.fieldName === 'password') {
                        reRenderForm();
                    }
                    if (typeof form.onChange === 'function') {
                        form.onChange(val);
                    }
                });
            },
        };
        switch (form.type) {
            case inputType.TEXT_INPUT:
                return <InputWithStyle {...props}/>;
            case inputType.CHECKBOX:
                return <CheckBoxWithStyle {...props}/>;
            case inputType.SELECT:
                if (filter === true) {
                    return <Select {...props} filter/>;
                }
                else {
                    return <Select {...props}/>;
                }
            case inputType.DATE_PICKER:
                return <DatePicker {...props}/>;
            case inputType.TIME_PICKER:
                return <TimePicker {...props}/>;
            case inputType.CALENDAR:
                return <CalendarForm {...props}/>;
            case inputType.CUSTOM_CALENDAR:
                return <CustomCalendar {...props}/>;
            case inputType.DATE_TIME_PICKER:
                return <DateTimePicker {...props}/>;
            case inputType.AVATAR:
                return (<div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Avatar {...props} avatar={props.value}/>
                    </div>);
            case inputType.RADIO:
                return (<Radio {...props}/>);
            default:
                return <div />;
        }
    }
    getStyle() {
        const { width } = this.props;
        let style = {};
        if (isWidthUp('md', width)) {
            style = path(['style', 'md'], this.props.form);
        }
        else {
            style = path(['style', width], this.props.form);
        }
        return style || path(['style', 'default'], this.props.form);
    }
    render() {
        const { form } = this.props;
        return (form.type === null ? null :
            <Grid item xs={form.md || 12} sm={form.md || 12} md={form.md || 12} style={this.getStyle()}>
                    {this.renderInput()}
                </Grid>);
    }
}
export default compose(withWidth())(ChildrenForm);
