import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { path } from "ramda";
import ButtonBase from "@material-ui/core/ButtonBase";
import Popover from '@material-ui/core/Popover';
import { withStyles } from "@material-ui/core/styles";
import Logo from "../Logo";
import Dialog from "../dialog";
import Text from "../text";
// import Img from "../img";
import { AUTHEN_DIALOG_MODE } from "../../containers/AuthenDialogWrapper";
import Select from "../form/Select";
import { pathName, reduxKeys, language as constantLanguage } from "../../constant";
import { Metrics, Colors, Images, Fonts } from "../../themes";
import { logout } from "../../sagas/ManageStoreSaga";
import SettingActions, { SETTING_KEY } from "../../redux/SettingsRedux";
import localization from "../../services/localization";
import { optionsServices } from "../../services";
import config, { isPlatForm } from '../../config';
import reloadService from '../../services/reloadService';
import { PLATFORM_NAME } from '../../config/platform';
const { EN, TH } = constantLanguage;
const pic = [
    {
        id: 0,
        picture: <img src={Images.Flag.EN}/>,
        value: 'EN',
    },
    {
        id: 0,
        picture: <img src={Images.Flag.TH}/>,
        value: 'TH',
    },
];
const Lang = [
    {
        id: 0,
        label: <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <img src={Images.Flag.EN}/> {EN}</div>,
        value: 'EN',
    },
    {
        id: 1,
        label: <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <img src={Images.Flag.TH}/> {TH}</div>,
        value: 'TH',
    },
];
const styles = () => ({
    loginButton: {
        backgroundColor: Colors.login,
        color: '#fff',
        padding: '0px 20px',
        borderRadius: 20,
        marginLeft: 20,
        fontFamily: Fonts.family.avenirHeavy,
        fontSize: 18,
        height: 34,
    },
    paper: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: Colors.login,
        color: '#fff',
        borderTop: '1px solid',
    },
});
const TextLink = ({ path = '/', children, style, ...rest }) => (<Link to={path} style={{
    margin: '0 15px',
    textDecoration: 'none', ...style,
}} {...rest}>
        <Text.Avenir color={Colors.headerColor} family="heavy" size={Fonts.size.header} pointer>
            {children}
        </Text.Avenir>
    </Link>);
const CustomLink = ({ to = '', children, style, ...rest }) => (<a href={to} target={'_blank'} rel="noopener" style={{ margin: '0 15px', textDecoration: 'none', ...style }} {...rest}>
        <Text.Avenir color={Colors.headerColor} family="heavy" size={Fonts.size.header} pointer>
            {children}
        </Text.Avenir>
    </a>);
class DesktopWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = { anchorEl: undefined };
        this.timeoutId = null;
        this.stillOpen = false;
        this.isProfilePage = false;
        this.isLoggedIn = false;
        this.currencyOptions = null;
        this.currencys = null;
        this.lang = null;
        this.handlePopoverOpen = (event, setAnchor = true) => {
            this.stillOpen = true;
            if (!this.isProfilePage && this.isLoggedIn && setAnchor) {
                this.setState({ anchorEl: event.target });
            }
        };
        this.handlePopoverClose = () => {
            this.stillOpen = false;
            this.timeoutId = setTimeout(() => {
                if (!this.stillOpen) {
                    this.setState({ anchorEl: undefined });
                }
            }, 100);
        };
        this.currencyOptions = optionsServices.getCurrencyOptions(false);
    }
    componentWillUnmount() {
        if (!!this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
        localStorage.setItem('loginFace', "false");
        localStorage.setItem('loginGoogle', "false");
    }
    getChoice() {
        const choice = this.currencyOptions.filter((each) => each.label !== path(['settings', 'currency'], this.props));
        return choice;
    }
    async loadService() {
        Dialog.loading({ key: SETTING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        await reloadService();
        window.setTimeout(() => { Dialog.loading().remove(SETTING_KEY); }, 1000);
    }
    selectLanguage(val) {
        const findLang = Lang.find((each) => each.id === val);
        this.sentLanguage(findLang);
    }
    async sentLanguage(language) {
        this.props.dispatch(SettingActions.changeLanguage(language.value));
        this.refreshPage(language.value);
        this.loadService();
    }
    selectCurrency(id) {
        const findCurrency = this.currencyOptions.find((each) => each.id === id);
        this.sentCurrency(findCurrency);
    }
    async sentCurrency(currency) {
        this.props.dispatch(SettingActions.changeCurrency(currency.label));
        this.refreshPage();
        this.loadService();
    }
    refreshPage(language) {
        const searchLang = this.props.location.search.split('=');
        if (this.props.location.pathname === '/') {
            this.props.history.push('/refresh/');
        }
        else if (this.props.location.pathname === '/confirmPayment') {
            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: language === undefined ? this.props.location.search :
                    this.props.location.search.indexOf('&') > 1 ?
                        `${searchLang[0]}=${searchLang[1]}=${searchLang[2]}=${language}` :
                        `${this.props.location.search}&language=${language}`,
            });
        }
        else {
            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: language === undefined ? this.props.location.search :
                    `${this.props.location.search === "" ? 'language' :
                        this.props.location.search.indexOf('&') > 0 ? searchLang[0] :
                            searchLang[0] === "?language" ? searchLang[0] : searchLang[0] + '&language'}=${language}`,
                state: this.props.location.state,
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        const isLoginF = localStorage.getItem("loginFace");
        const isLoginG = localStorage.getItem("loginGoogle");
        if (!!path(['data', 'firstName'], nextProps.user) && !!Dialog.authen()) {
            Dialog.authen().hideModal();
        }
        if (!!path(['socialCache'], nextProps.user)) {
            Dialog.authen().changeMode(AUTHEN_DIALOG_MODE.REGISTER, false, true);
        }
        if (path(['token'], nextProps.authen) !== null || path(['token'], this.props.authen) !== null) {
            if (path(['data'], nextProps.user) === null || path(['data'], this.props.user) === null) {
                if (isLoginF === "true" || isLoginG === "true") {
                    Dialog.authen(AUTHEN_DIALOG_MODE.REGISTER);
                }
            }
            else {
                if (isLoginF === "true" || isLoginG === "true") {
                    Dialog.authen(AUTHEN_DIALOG_MODE.LOGIN);
                }
                else {
                    // Dialog.authen().hideModal();
                    localStorage.setItem('loginFace', "false");
                    localStorage.setItem('loginGoogle', "false");
                }
            }
        }
        else {
            // Dialog.authen().hideModal();
            localStorage.setItem('loginFace', "false");
            localStorage.setItem('loginGoogle', "false");
        }
    }
    render() {
        const { classes, [reduxKeys.authen]: { token }, history } = this.props;
        const { anchorEl } = this.state;
        const open = !!anchorEl;
        this.isLoggedIn = !!token;
        this.isProfilePage = window.location.pathname === pathName.PROFILE;
        const filterLang = Lang.filter((each) => each.value !== path(['settings', 'language'], this.props));
        const defaultCurrency = path(['settings', 'currency'], this.props);
        const defaultLanguage = path(['settings', 'language'], this.props);
        this.lang = this.getLang(defaultLanguage);
        this.currencys = this.getCurren(this.currencyOptions, defaultCurrency);
        const isPhiPhi = (isPlatForm === PLATFORM_NAME.PHIPHITOURS);
        const hash = window.location.hash;
        const isPartner = hash === config.partner.id;
        return (<React.Fragment>
                <div style={{ height: Metrics.header.height }}>
                    <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${Images.headerBG})`,
            backgroundSize: '100% 100%',
            padding: `0 5px`,
        }}>
                        <Link to={pathName.INDEX} style={{
            marginRight: 20,
            maxWidth: 130,
            width: '15%',
        }}>
                            <Logo style={{ width: '100%' }} color='blue' onClick={() => this.props.history.push('/index')}/>
                        </Link>
                        {isPartner && <Link to={pathName.INDEX} style={{
            marginRight: 20,
            maxWidth: 130,
            width: '15%',
        }}>
                            <Logo style={{ width: '80%' }} isPartner={isPartner} onClick={() => this.props.history.push('/index')}/>
                        </Link>}
                        <div>
                            <TextLink path={pathName.ABOUT_US}>
                                {localization(`about_us`, `LandingLabel`)}
                            </TextLink>
                            <TextLink path={pathName.HOW_TO_BOOK}>
                                {localization(`how_to_book`, `LandingLabel`)}
                            </TextLink>
                            <CustomLink to={config.businessURL}>
                                {localization(`business_partners`, `LandingLabel`)}
                            </CustomLink>
                            {this.isLoggedIn ? (<TextLink path={pathName.WISH_LIST}>{localization(`wish_list`, `LandingLabel`)}</TextLink>)
            :
                <div style={{ width: 115, display: 'inline-block' }}/>}
                        </div>
                        <Select onChange={(val) => this.selectLanguage(val)} labelStyle={{
            color: Colors.headerColor, fontFamily: Fonts.family.avenirHeavy,
            fontSize: 18, lineHeight: '18px',
        }} label={path(['picture'], this.lang) || <img src={Images.Flag.EN}/>} value={path(['settings', 'language'], this.props)} choices={filterLang} options={{
            displayError: true,
            selector: {
                label: { width: 'unset', fontSize: '0px' },
                choiceStyle: {
                    height: 35, lineHeight: 'unset',
                    padding: '4px 10px',
                    backgroundColor: Colors.login,
                    color: Colors.header.select,
                },
                style: {
                    border: `unset`, width: 0,
                },
                collapseStyle: { width: 75, marginLeft: -30 },
                dropdownColor: Colors.headerColor,
                labelWrapperStyle: { minWidth: 'unset', width: 15 },
            },
        }}/>
                        {window.location.pathname === pathName.BOOKING_PREVIEW ||
            window.location.pathname === pathName.PAYMENT_DETAIL ?
            this.ButtonCurrency(true)
            :
                this.ButtonCurrency(false)}
                        <ButtonBase classes={{ root: classes.loginButton }} onMouseOver={this.handlePopoverOpen} onMouseOut={this.handlePopoverClose} onClick={() => {
            if (this.isLoggedIn) {
                if (this.isProfilePage) {
                    logout();
                }
                else {
                    history.push(pathName.PROFILE);
                }
            }
            else {
                Dialog.authen(AUTHEN_DIALOG_MODE.LOGIN);
            }
        }}>
                            <Text.Avenir family="heavy" size={18}>
                                {this.isLoggedIn ?
            this.isProfilePage ?
                localization(`log_out`, `LandingLabel`)
                :
                    localization(`profile`, `LandingLabel`)
            :
                localization(`log_in`, `LandingLabel`)}
                            </Text.Avenir>
                        </ButtonBase>
                        <Popover style={{
            pointerEvents: 'none',
        }} classes={{
            paper: classes.paper,
        }} open={open} anchorEl={anchorEl} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }} transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }} onClose={this.handlePopoverClose}>
                            <ButtonBase style={{
            pointerEvents: 'auto', width: 89,
            textAlign: 'center', padding: 5,
            cursor: 'pointer',
        }} onMouseOver={(e) => {
            this.handlePopoverOpen(e, false);
        }} onMouseOut={this.handlePopoverClose} onClick={() => {
            this.setState({ anchorEl: undefined });
            logout();
        }}>
                                <Text.Avenir family="heavy">{localization(`log_out`, `LandingLabel`)}</Text.Avenir>
                            </ButtonBase>
                        </Popover>
                    </div>
                </div>
                {(isPhiPhi) && <div style={{ height: 5, backgroundColor: '#000' }}/>}
            </React.Fragment>);
    }
    ButtonCurrency(disabled) {
        return (<React.Fragment>
                <Select onChange={(val) => this.selectCurrency(val)} labelStyle={{
            color: Colors.headerColor, fontFamily: Fonts.family.avenirHeavy,
            fontSize: 16, lineHeight: '21px',
        }} choices={this.getChoice()} disabled={disabled} label={path(['label'], this.currencys) || 'THB'} options={{
            displayError: true,
            selector: {
                label: { width: 0, fontSize: '0px', color: '#fff' },
                choiceStyle: {
                    height: 35, lineHeight: 'unset',
                    padding: '4px 10px',
                    backgroundColor: Colors.login,
                    color: Colors.header.select,
                },
                style: {
                    border: `unset`, width: 0,
                },
                collapseStyle: { width: 55, marginLeft: -30 },
                dropdownColor: !!disabled ? Colors.header.disable : Colors.header.enabled,
                labelWrapperStyle: { minWidth: 'unset', width: 50 },
            },
        }}/>
            </React.Fragment>);
    }
    getCurren(currencyOptions, defaultCurrency) {
        let getCurency = null;
        if (Array.isArray(currencyOptions) && currencyOptions.length > 0) {
            getCurency = currencyOptions.find((each) => each.label === defaultCurrency);
        }
        return getCurency;
    }
    getLang(defaultLanguage) {
        let getLanguage = null;
        if (Array.isArray(pic) && pic.length > 0) {
            getLanguage = pic.find((each) => each.value === defaultLanguage);
        }
        return getLanguage;
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.authen]: state[reduxKeys.authen],
    [reduxKeys.user]: state[reduxKeys.user],
    [reduxKeys.settings]: state[reduxKeys.settings],
});
export default compose(withRouter, withStyles(styles), connect(mapStateToProps))(DesktopWrapper);
