export var PLATFORM_NAME;
(function (PLATFORM_NAME) {
    PLATFORM_NAME["TRAVISGO"] = "TravisGo";
    PLATFORM_NAME["PHIPHITOURS"] = "PhiPhiTours";
})(PLATFORM_NAME || (PLATFORM_NAME = {}));
;
const path = './platform/';
export const getPlatFormPath = (isPlatFrom) => {
    switch (isPlatFrom) {
        case PLATFORM_NAME.PHIPHITOURS: return `${path}${PLATFORM_NAME.PHIPHITOURS}`;
        case PLATFORM_NAME.TRAVISGO:
        default: return `${path}${PLATFORM_NAME.TRAVISGO}`;
    }
};
