import store from "../redux";
import { reduxKeys } from "../constant";
export const getAppointmentOptions = (withDefault = false) => {
    const { [reduxKeys.other]: { appointments }, [reduxKeys.settings]: { language } } = store.getState();
    let appointmentOptions = withDefault ?
        [{
                id: '',
                label: 'None',
                default: true,
            }]
        :
            [];
    if (Array.isArray(appointments)) {
        appointmentOptions = appointmentOptions.concat(
        // language === 'EN' ? appointments.map(({ appointmentId: id, name_EN: label, ...rest }) =>
        //     ({ ...rest, id, label })) :
        appointments.map((each) => ({ ...each, id: each.appointmentId, label: each[language].name })));
    }
    return appointmentOptions;
};
export const getCustomerRequestOptions = (withDefault = false) => {
    const { [reduxKeys.booking]: { customerRequest }, [reduxKeys.settings]: { language } } = store.getState();
    let customerRequestOptions = withDefault ?
        [{
                id: '',
                label: 'None',
                default: true,
            }]
        :
            [];
    if (Array.isArray(customerRequest)) {
        customerRequestOptions = customerRequestOptions.concat(language === 'EN' ? customerRequest.map(({ requestId: id, langugeENG }) => ({ id, label: langugeENG.requestName })) :
            customerRequest.map(({ requestId: id, langugeTH }) => ({ id, label: langugeTH.requestName })));
    }
    return customerRequestOptions;
};
export const getLocationServicesOptions = (withDefault = true) => {
    const { [reduxKeys.other]: { locationServices }, [reduxKeys.settings]: { language } } = store.getState();
    let locationServicesOptions = withDefault ?
        [{
                id: '',
                label: 'None',
                default: true,
            }]
        :
            [];
    if (Array.isArray(locationServices)) {
        locationServicesOptions = locationServicesOptions.concat(
        // locationServices.map(({ locationServiceId: id, name_EN: label, code: code }) =>
        //     ({ id, label, code })));
        locationServices.map((each) => ({ ...each, id: each.locationServiceId, label: each[language].name })));
    }
    return locationServicesOptions;
};
export const getActivitiesOptions = (withDefault = true) => {
    const { [reduxKeys.other]: { activities }, [reduxKeys.settings]: { language } } = store.getState();
    let activitiesOptions = withDefault ?
        [{
                id: '',
                label: 'None',
                default: true,
            }]
        :
            [];
    if (Array.isArray(activities)) {
        activitiesOptions = activitiesOptions.concat(
        // activities.map(({ activityId: id, label }) =>
        //     ({ id, label })));
        activities.map((each) => ({ ...each, id: each.activityId, label: each[language].name })));
    }
    return activitiesOptions;
};
export const getCurrencyOptions = (withDefault = true) => {
    const { [reduxKeys.other]: { currency } } = store.getState();
    let currencyOptions = withDefault ?
        [{
                id: '',
                label: 'None',
                default: true,
            }]
        :
            [];
    if (Array.isArray(currency)) {
        currencyOptions = currencyOptions.concat(currency.map(({ currencyId: id, currencyType: label }) => ({ id, label })));
    }
    return currencyOptions;
};
export const getRefundOptions = (withDefault = false) => {
    const { [reduxKeys.booking]: { refundList }, [reduxKeys.settings]: { language } } = store.getState();
    let refundOptions = withDefault ?
        [{
                id: '',
                label: 'None',
                default: true,
            }]
        :
            [];
    if (Array.isArray(refundList)) {
        refundOptions = refundOptions.concat(refundList.map((each) => ({ ...each, id: each.refundPolicyId, label: each[language].reasons })));
    }
    return refundOptions;
};
