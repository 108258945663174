import { pathName } from "../../../../../../constant";
import { isMobile } from "../../../../../../containers/PackageInfoPanel/GetItemColsPerWidth";
const { CHOOSE_PACKAGE, SEARCH_PRODUCT, BOOKING_PREVIEW, PAYMENT_CHANNEL, CONFIRM_PAYMMENT, SEEMORE_TRANFER, FERRIES_TRANFER, WISH_LIST, CONTACT_US } = pathName;
export default {
    [CHOOSE_PACKAGE]: 'Choose Your Plan',
    [SEARCH_PRODUCT]: 'Find Destinations',
    [BOOKING_PREVIEW]: 'Booking Preview',
    [PAYMENT_CHANNEL]: (isMobile()) ? 'Payment Channel' : 'Select Payment Channel',
    [CONFIRM_PAYMMENT]: 'Confirm Payment',
    [SEEMORE_TRANFER]: 'Airport Transfer',
    [FERRIES_TRANFER]: 'Ferries Transfer',
    [WISH_LIST]: 'Wish List',
    [CONTACT_US]: 'Contact Us',
    [`${"Special Promotion" /* SPECIAL_PROMOTION */}`]: 'Special Promotion',
    [`${"New Attractions" /* NEW_ATTRACTIONS */}`]: 'New Attractions',
    [`${"Recommend" /* RECOMMEND */}`]: 'Recommend',
    [`${"Recommend Trip" /* RECOMMEND_TRIP */}`]: 'Recommend Trip',
    [`${"Airport Transfer" /* AIRPORT_TRNSFER */}`]: 'Airport Transfer',
    [`${"Ferries Transfer" /* FERRIES_TRNSFER */}`]: 'Ferries Transfer',
    [`${"Top Destination" /* TOP_DESTINATION */}`]: 'Top Destination',
    [`${"Customer Reviews" /* CUSTOMER_REVIEWS */}`]: 'Customer Reviews',
    [`${"WishList" /* WISHLIST */}`]: 'WishList',
    sentence1: 'You get best rates',
    sentence2: ' only at',
    sentence3: ' TravisGO',
    seeMoreLable: 'See More',
    ResultOf: 'Result of',
    AtLocation: '',
    Total: 'Total',
    FilterTopics: 'Filter',
    Destinations: 'Destinations',
    Appointments: 'Appointments',
    Activities: 'Activities',
    ConveyanceType: 'ConveyanceType',
    Special: 'Special',
    PriceRange: 'Price Range',
    ReviewStars: 'Review Stars',
    PickUp: 'PickUp',
    DropOff: 'DropOff',
    Date: 'Date',
    Passenger: 'Passenger',
    Members: 'Members',
    Destination: 'Destinations',
    Find: 'Find',
    AppTitle: `TravisGo : Book things to do,Tours,Activities & Tickets`,
    AppDescription: `Plan and book your day-trips with TravisGO. Read reviews on hundreds of day-trips and activities in Thailand. Thailand's #1 online day-trip booking website. Best Price Guaranteed.`,
};
