import { path } from 'ramda';
import { put, call } from 'redux-saga/effects';
import OtherActions, { OTHER_KEY } from "../redux/OtherRedux";
import Dialog from "../components/dialog";
import errorGenerator from "../services/errorGenerator";
function* getBanksRequest(api) {
    try {
        const res = yield call(api.getBanks);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getBanksSuccess(path(['data', 'result', 'banks'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getBanksFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getBanksFailure());
    }
}
function* getLocationServicesRequest(api, { payload }) {
    try {
        const res = yield call(api.getLocationServices, payload);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getLocationServicesSuccess(path(['data'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getLocationServicesFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getLocationServicesFailure());
    }
}
function* getDestinationsRequest(api, { payload }) {
    try {
        const res = yield call(api.getDestinations, payload);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getDestinationsSuccess(path(['data'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getDestinationsFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getDestinationsFailure());
    }
}
function* getActivitiesRequest(api, { payload }) {
    try {
        const res = yield call(api.getActivities, payload);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getActivitiesSuccess(path(['data'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getActivitiesFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getActivitiesFailure());
    }
}
function* getAppointmentsRequest(api, { payload }) {
    try {
        const res = yield call(api.getAppointments, payload);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getAppointmentsSuccess(path(['data', 'result', 'datas'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getAppointmentsFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getAppointmentsFailure());
    }
}
function* getPaymentTimesRequest(api) {
    try {
        const res = yield call(api.getPaymentTimes);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getPaymentTimesSuccess(path(['data'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getPaymentTimesFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getPaymentTimesFailure());
    }
}
function* getCurrencyRequest(api, { payload }) {
    try {
        const res = yield call(api.getCurrency, payload);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getCurrencySuccess(path(['data'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getCurrencyFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getCurrencyFailure());
    }
}
function* getAnnouncementsRequest(api) {
    try {
        const res = yield call(api.getAnnouncements);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getAnnouncementsSuccess(path(['data', 'result'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getAnnouncementsFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getAnnouncementsFailure());
    }
}
function* getAffiliateStatisticsRequest(api, { payload }) {
    try {
        console.log('payload', payload);
        const res = yield call(api.getAffiliateStatistics, payload);
        if (path(['status'], res) === 200) {
            yield put(OtherActions.getAffiliateStatisticsSuccess(path(['data', 'result'], res)));
        }
        else {
            Dialog.error({
                key: OTHER_KEY,
                ...errorGenerator(res),
            });
            yield put(OtherActions.getAffiliateStatisticsFailure());
        }
    }
    catch (e) {
        yield put(OtherActions.getAffiliateStatisticsFailure());
    }
}
export { getBanksRequest, getLocationServicesRequest, getDestinationsRequest, getActivitiesRequest, getAppointmentsRequest, getPaymentTimesRequest, getCurrencyRequest, getAnnouncementsRequest, getAffiliateStatisticsRequest, };
