import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */
export const OTHER_KEY = 'OTHER';
const { Types, Creators } = createActions({
    getBanksRequest: null,
    getBanksSuccess: ['banks'],
    getBanksFailure: null,
    getLocationServicesRequest: ['payload'],
    getLocationServicesSuccess: ['locationServices'],
    getLocationServicesFailure: null,
    getDestinationsRequest: ['payload'],
    getDestinationsSuccess: ['destinations'],
    getDestinationsFailure: null,
    getActivitiesRequest: ['payload'],
    getActivitiesSuccess: ['activities'],
    getActivitiesFailure: null,
    getAppointmentsRequest: ['payload'],
    getAppointmentsSuccess: ['appointments'],
    getAppointmentsFailure: null,
    getPaymentTimesRequest: null,
    getPaymentTimesSuccess: ['paymentTimes'],
    getPaymentTimesFailure: null,
    getCurrencyRequest: ['payload'],
    getCurrencySuccess: ['currency'],
    getCurrencyFailure: null,
    getAnnouncementsRequest: null,
    getAnnouncementsSuccess: ['announcements'],
    getAnnouncementsFailure: null,
    clearAnnouncements: null,
    getAffiliateStatisticsRequest: ['payload'],
    getAffiliateStatisticsSuccess: ['payload'],
    getAffiliateStatisticsFailure: null,
}, { prefix: `${OTHER_KEY}_` });
export const OtherTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    banks: [],
    locationServices: [],
    destinations: [],
    activities: [],
    appointments: [],
    paymentTimes: null,
    currency: [],
    announcements: null,
});
/* ------------- Reducers ------------- */
// request the data from an api
export const getBanksSuccess = (state, { banks }) => Object.assign({}, state, { banks });
export const getLocationServicesSuccess = (state, { locationServices }) => Object.assign({}, state, { locationServices });
export const getDestinationsSuccess = (state, { destinations }) => Object.assign({}, state, { destinations });
export const getActivitiesSuccess = (state, { activities }) => Object.assign({}, state, { activities });
export const getAppointmentsSuccess = (state, { appointments }) => Object.assign({}, state, { appointments });
export const getPaymentTimesSuccess = (state, { paymentTimes }) => Object.assign({}, state, { paymentTimes });
export const getCurrencySuccess = (state, { currency }) => Object.assign({}, state, { currency });
export const getAnnouncementsSuccess = (state, { announcements }) => Object.assign({}, state, { announcements });
export const clearAnnouncements = (state) => Object.assign({}, state, { announcements: { type: 'notFound' } });
export const getAffiliateStatisticsRequest = (state) => Object.assign({}, state, { loading: true });
export const getAffiliateStatisticsSuccess = (state, { payload }) => Object.assign({}, state, { payload, loading: false });
export const getAffiliateStatisticsFailure = (state) => Object.assign({}, state, { loading: false });
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_BANKS_SUCCESS]: getBanksSuccess,
    [Types.GET_LOCATION_SERVICES_SUCCESS]: getLocationServicesSuccess,
    [Types.GET_DESTINATIONS_SUCCESS]: getDestinationsSuccess,
    [Types.GET_ACTIVITIES_SUCCESS]: getActivitiesSuccess,
    [Types.GET_APPOINTMENTS_SUCCESS]: getAppointmentsSuccess,
    [Types.GET_PAYMENT_TIMES_SUCCESS]: getPaymentTimesSuccess,
    [Types.GET_CURRENCY_SUCCESS]: getCurrencySuccess,
    [Types.GET_ANNOUNCEMENTS_SUCCESS]: getAnnouncementsSuccess,
    [Types.CLEAR_ANNOUNCEMENTS]: clearAnnouncements,
    [Types.GET_AFFILIATE_STATISTICS_REQUEST]: getAffiliateStatisticsRequest,
    [Types.GET_AFFILIATE_STATISTICS_SUCCESS]: getAffiliateStatisticsSuccess,
    [Types.GET_AFFILIATE_STATISTICS_FAILURE]: getAffiliateStatisticsFailure,
});
