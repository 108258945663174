import * as React from "react";
const dropdownColor = () => (<svg width="21" height="12" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
                <stop stopColor="#4188C6" offset="0%"/>
                <stop stopColor="#2D609C" offset="100%"/>
            </linearGradient>
        </defs>
        <path d="M711.183622 934L709 935.969069l10.26163 9.962988 10.788776-9.962988L728.005546 934l-8.743916 8.008949z" transform="translate(-709 -934)" fill="url(#a)" fillRule="evenodd"/>
    </svg>);
const grey = ({ color = '#6E6E6E' }) => {
    color = !!color ? color : '#6E6E6E';
    return (<svg width="11" height="6" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.858937 0L11 .99014 5.637732 6 0 .99014 1.068552 0l4.56918 4.027277z" fill={color} fillRule="evenodd"/>
        </svg>);
};
const white = () => (<svg width="18" height="11" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.132806 0L18 1.6837316l-8.774621 8.5192563L0 1.6837316 1.74854 0l7.476839 6.8483758z" fill="#FFF" fillRule="evenodd"/>
    </svg>);
export default {
    color: dropdownColor,
    grey,
    white,
};
