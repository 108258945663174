import React, { Component, Fragment } from 'react';
import { DateTimePicker as MDateTimePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/styles';
import { equals } from "ramda";
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";
import { format } from "../../constant";
import { Colors, Metrics } from "../../themes";
const styles = (theme) => ({
    // root: {
    //     width: '100%',
    // },
    textFieldRoot: {
        "padding": 0,
        'label + &': {
            marginTop: theme.spacing.unit * 2.2,
        },
    },
    textFieldInput: {
        cursor: 'pointer',
        // height: '12px',
        borderRadius: Metrics.modal.borderRadius,
        background: theme.palette.common.white,
        border: (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
        fontSize: 16,
        padding: '10px 12px',
        width: '100%',
    },
});
class DateTimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: props.value || new Date(),
        };
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.timestamp)) {
            this.setState({
                timestamp: nextProps.value || new Date(),
            });
        }
    }
    render() {
        const { label, error, errorId, classes, onChange, } = this.props;
        return (<Fragment>
                <FieldLabel label={label} error={error}/>
                <MDateTimePicker 
        // classes={{
        //     root: classes.root,
        // }}
        InputProps={{
            disableUnderline: true,
            classes: {
                root: classes.textFieldRoot,
                input: classes.textFieldInput,
            },
        }} value={this.state.timestamp} onChange={(val) => {
            this.setState({
                timestamp: val.toISOString(),
            }, () => {
                // onChange(val.valueOf());
                onChange(val.toISOString());
            });
        }} format={`${format.DATE} ${format.TIME}`}/>
                <FieldError error={error} errorId={errorId}/>
            </Fragment>);
    }
}
export default withStyles(styles)(DateTimePicker);
