import { pathName } from "../../../../constant";
import { isMobile } from '../../../../containers/PackageInfoPanel/GetItemColsPerWidth';
const { CHOOSE_PACKAGE, SEARCH_PRODUCT, BOOKING_PREVIEW, PAYMENT_CHANNEL, CONFIRM_PAYMMENT, SEEMORE_TRANFER, FERRIES_TRANFER, WISH_LIST, CONTACT_US } = pathName;
export default {
    [CHOOSE_PACKAGE]: '選擇你的計劃',
    [SEARCH_PRODUCT]: '查找目的地',
    [BOOKING_PREVIEW]: '預訂預覽',
    [PAYMENT_CHANNEL]: (isMobile()) ? '選擇付款頻道' : '選擇付款頻道',
    [CONFIRM_PAYMMENT]: '確認付款',
    [SEEMORE_TRANFER]: '機場接送',
    [FERRIES_TRANFER]: '渡輪轉運',
    [WISH_LIST]: '願望清單',
    [CONTACT_US]: '聯繫我們',
    [`${"Special Promotion" /* SPECIAL_PROMOTION */}`]: '特別促銷',
    [`${"New Attractions" /* NEW_ATTRACTIONS */}`]: '新景點',
    [`${"Recommend" /* RECOMMEND */}`]: '推薦',
    [`${"Recommend Trip" /* RECOMMEND_TRIP */}`]: '推薦旅行',
    [`${"Airport Transfer" /* AIRPORT_TRNSFER */}`]: '機場接送',
    [`${"Ferries Transfer" /* FERRIES_TRNSFER */}`]: '渡輪轉運',
    [`${"Top Destination" /* TOP_DESTINATION */}`]: '熱門目的地',
    [`${"Customer Reviews" /* CUSTOMER_REVIEWS */}`]: '顧客評論',
    [`${"WishList" /* WISHLIST */}`]: '願望清單',
    sentence1: 'You get best rates',
    sentence2: ' only at',
    sentence3: ' TravisGO',
    seeMoreLable: '查看更多',
    ResultOf: 'Result of',
    AtLocation: '',
    Total: 'Total',
    FilterTopics: 'Filter',
    Destinations: 'Destinations',
    Appointments: 'Appointments',
    Activities: 'Activities',
    ConveyanceType: 'ConveyanceType',
    Special: 'Special',
    PriceRange: 'Price Range',
    ReviewStars: 'Review Stars',
    PickUp: 'PickUp',
    DropOff: 'DropOff',
    Date: 'Date',
    Passenger: 'Passenger',
    Members: 'Members',
    Destination: 'Destinations',
    Find: 'Find',
    AppTitle: `TravisGo : Book things to do,Tours,Activities & Tickets`,
    AppDescription: `Plan and book your day-trips with TravisGO. Read reviews on hundreds of day-trips and activities in Thailand. Thailand's #1 online day-trip booking website. Best Price Guaranteed.`,
};
