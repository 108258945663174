import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import { Fonts, Metrics } from "../../themes";
const styles = (theme) => ({
    error: {
        color: theme.palette.error.main,
        fontSize: Fonts.size.input.error,
        marginTop: `${Metrics.input.error.marginTop}px`,
        lineHeight: Metrics.input.error.lineHeight,
        minHeight: Metrics.input.error.lineHeight,
    },
});
const FieldError = ({ display = true, error, errorId, classes }) => {
    if (display === false) {
        return null;
    }
    return (<div className={classes.error}>
            {error && <FormattedMessage id={errorId}/>}
        </div>);
};
export default withStyles(styles)(FieldError);
