import React from 'react';
import styled from "styled-components";
import { AUTHEN_DIALOG_MODE } from "../../containers/AuthenDialogWrapper";
import Text from "../text";
import Divider from "./Divider";
import { Colors } from "../../themes";
import localization from "../../services/localization";
const TabText = styled(Text.Avenir) `
    color: ${Colors.grayScale['52']};
    font-size: ${window.innerWidth < 390 ? '18px' : '22px'};
`;
TabText.defaultProps = {
    family: 'heavy',
};
const WithUnderline = styled('div') `
    line-height: 30px;
    display: inline-block;
    ${(props) => props.active ?
    `border-bottom: 3px solid ${Colors.grayScale['52']};`
    : ''}
`;
const Tab = ({ activeTab, onClickLogin, onClickRegister }) => {
    return (<React.Fragment>
            <WithUnderline style={{ marginRight: 20 }} active={activeTab === AUTHEN_DIALOG_MODE.LOGIN} onClick={onClickLogin}>
                <TabText pointer>
                    {localization(`login`, `AuthenLabel`)}
                </TabText>
            </WithUnderline>
            <WithUnderline active={activeTab === AUTHEN_DIALOG_MODE.REGISTER} onClick={onClickRegister}>
                <TabText pointer>
                    {localization(`register`, `AuthenLabel`)}
                </TabText>
            </WithUnderline>
            <Divider />
        </React.Fragment>);
};
export default Tab;
