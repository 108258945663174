export default {
    login: 'เข้าสู่ระบบ',
    register: 'สมัครสมาชิก',
    forgot_password: 'ลืมรหัสผ่าน ?',
    password: 'รหัสผ่าน',
    member_login: 'เข้าสู่ระบบ',
    continue_with_facebook: 'ลงชื่อเข้าใช้ด้วย Facebook',
    sign_in_with_google: 'ลงชื่อเข้าใช้ด้วย Google',
    password_recovery: 'แก้ไขรหัสผ่าน',
    back_to_login: 'กลับไปหน้า เข้าสู่ระบบ',
    reset_password: 'แก้ไขรหัสผ่าน',
    recovery_method: 'ขั้นตอนการแก้ไขรหัสผ่านจะถูกส่งไปที่อีเมล',
    confirm_passord: 'ยืนยันรหัสผ่าน',
    login_to_get: 'กรุณาลงชื่อเข้าใช้ระบบสมาชิกเพื่อรับสิทธิพิเศษในการจอง หรือกด ดําเนินการต่อด้วยการจองแบบลูกค้าทั่วไป',
    login_book: 'ลงชื่อเข้าใฃ้',
    continue_guest: 'ดำเนินการต่อ',
    firstname_register: 'ชื่อ (ภาษาอังกฤษเท่านั้น)',
    lastname_register: 'นามสกุล (ภาษาอังกฤษเท่านั้น)',
    social: 'โซเชียล',
    social_id: 'รหัสโซเชียล',
};
