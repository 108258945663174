// import { path } from "ramda";
import { socialType as socialTypes } from "../constant";
import Store from "../redux";
import AuthenActions from "../redux/AuthenRedux";
export const onSocialCallback = (data, socialType, loginNextAction) => {
    let firstName = '';
    let lastName = '';
    let email;
    let id = '';
    // let avatar: any;
    if (socialType === socialTypes.FACEBOOK) {
        if (data.id === undefined) {
            return;
        }
        const name = data.name.split(' ');
        firstName = name[0];
        lastName = name[1];
        email = data.email;
        id = data.id;
        // console.warn('FACEBOOK : ', loginNextAction);
        // avatar = path(['picture', 'data', 'url'], data);
    }
    else if (socialType === socialTypes.GOOGLE) {
        firstName = data.profileObj.givenName;
        lastName = data.profileObj.familyName;
        email = data.profileObj.email;
        id = data.googleId;
        // avatar = data.profileObj.imageUrl;
    }
    const payload = {
        socialType,
        firstName,
        lastName,
        email: email || '',
        id,
    };
    Store.dispatch(AuthenActions.loginRequest(payload, loginNextAction));
};
