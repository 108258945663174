// import React from 'react';
// import { connect } from "react-redux";
// import { isLanguage } from "../../services/localization";
import ReactPixel from 'react-facebook-pixel';
import { Num2Digit } from '../common/SetNumberFormat';
import config from '../../config';
const { trace, isProduction, isDevelop } = config;
let isInit = false;
export var TrackType;
(function (TrackType) {
    TrackType["Lead"] = "Lead";
    TrackType["Search"] = "Search";
    TrackType["Contact"] = "Contact";
    TrackType["Schedule"] = "Schedule";
    TrackType["PageView"] = "PageView";
    TrackType["Purchase"] = "Purchase";
    TrackType["AddToCart"] = "AddToCart";
    TrackType["StartTrial"] = "StartTrial";
    TrackType["ViewContent"] = "ViewContent";
    TrackType["FindLacation"] = "FindLacation";
    TrackType["AddToWishlist"] = "AddToWishlist";
    TrackType["ViewAndAddCart"] = "ViewAndAddCart";
    TrackType["ViewAndAddAction"] = "ViewAndAddAction";
    TrackType["AddPaymentInfo"] = "AddPaymentInfo";
    TrackType["CustomizeProduct"] = "CustomizeProduct";
    TrackType["InitiateCheckout"] = "InitiateCheckout";
    TrackType["SubmitApplication"] = "SubmitApplication";
    TrackType["CompleteRegistration"] = "CompleteRegistration";
})(TrackType || (TrackType = {}));
const TraceFacebookPixel = (props = {}) => {
    if (isProduction) {
        const { data, trackType } = props;
        console.log('TraceFacebookPixel : ', props);
        if (!isInit) {
            FacebookPixelInit();
        }
        // ReactPixel.pageView();
        // ReactPixel.trackCustom(event, data);
        // ReactPixel.fbq('track', 'ViewContent');
        let paramiter;
        switch (trackType) {
            case TrackType.PageView:
                {
                    // นี่คือพิกเซลตามค่าเริ่มต้นที่ติดตามการเยี่ยมชมเพจคนก็จะมาถึงหน้าเว็บไซต์ของคุณ
                    // Done
                    paramiter = '';
                    ReactPixel.pageView();
                }
                break;
            case TrackType.ViewContent:
                {
                    // เมื่อมีการดูหน้าหลัก เช่น หน้าของสินค้าคนก็จะมาถึงหน้ารายละเอียดสินค้า
                    // content_ids, content_name, content_type, contents, currency, value ไม่จำเป็น
                    // ต้องระบุสำหรับโฆษณาแบบไดนามิก: content_ids, content_type และ contents
                    // Done
                    if (data) {
                        const { packageId, type, name, shotDes } = data;
                        paramiter = { content_ids: packageId, content_name: name || '', content_type: type || '', contents: shotDes || '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.ViewContent, (paramiter || ''));
                }
                break;
            case TrackType.Contact:
                {
                    // เมื่อมีคนเริ่มติดต่อกับธุรกิจของคุณผ่านทางโทรศัพท์, SMS, อีเมล, แชท และอื่นๆคนก็จะส่งคำถามเกี่ยวกับสินค้า
                    // ไม่จำเป็น
                    // Done
                    paramiter = '';
                    ReactPixel.track(trackType, paramiter);
                }
                break;
            case TrackType.Search:
                {
                    // เมื่อมีการค้นหาคนก็จะค้นหาสินค้าบนเว็บไซต์ของคุณ
                    // content_category, content_ids, contents, currency, search_string, value
                    // Done
                    if (data) {
                        const { searchType, conveyanceType } = data;
                        paramiter = { content_ids: '', content_type: searchType || '', search_string: conveyanceType || '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.Search, (paramiter || ''));
                }
                break;
            case TrackType.Schedule:
                {
                    // เมื่อมีคนทำการนัดหมายเพื่อไปยังตำแหน่งที่ตั้งใดที่ตั้งหนึ่งของคุณคนก็จะเลือกวันที่และเวลาสำหรับการนัดหมาย
                    // ไม่จำเป็น
                    if (data) {
                        paramiter = { content_ids: '', content_type: '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.Schedule, (paramiter || ''));
                }
                break;
            case TrackType.StartTrial:
                {
                    // เมื่อมีคนเริ่มใช้สินค้าหรือบริการที่คุณเสนอให้ทดลองใช้งานฟรีคนก็จะเลือกสัปดาห์ฟรี
                    // currency, predicted_ltv, value
                    if (data) {
                        paramiter = { content_ids: '', content_type: '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.StartTrial, (paramiter || ''));
                }
                break;
            case TrackType.AddToCart:
                {
                    // เมื่อมีการหยิบสินค้าใส่ตะกร้าสินค้าคนก็จะคลิกที่ปุ่มหยิบใส่ตะกร้า
                    // content_ids, content_name, content_type, contents, currency, value
                    // Dane
                    if (data) {
                        const { packageId, type, name } = data;
                        paramiter = { content_ids: packageId || '', content_type: type || '', content_name: name || '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.AddToCart, (paramiter || ''));
                }
                break;
            case TrackType.ViewAndAddCart:
                {
                    // เมื่อมีการหยิบสินค้าใส่ตะกร้าสินค้าคนก็จะคลิกที่ปุ่มหยิบใส่ตะกร้า
                    if (data) {
                        const { packageId, type, name } = data;
                        paramiter = { content_ids: packageId || '', content_type: type || '', content_name: name || '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.pageView();
                    ReactPixel.fbq('track', TrackType.AddToCart, (paramiter || ''));
                }
                break;
            case TrackType.InitiateCheckout:
                {
                    // เมื่อมีคนเข้าสู่กระบวนการชำระเงินก่อนที่จะดำเนินการตามกระบวนการดังกล่าวจนเสร็จสิ้นคนก็จะคลิกที่ปุ่มชำระเงิน
                    // content_category, content_ids, contents, currency, num_items, value
                    // Done
                    if (data) {
                        const { packageId, type, name, amount, summary, currency } = data;
                        paramiter = {
                            content_category: type || '',
                            content_ids: packageId || '',
                            contents: name || '',
                            currency: currency || 'THB',
                            num_items: amount || 0,
                            value: Num2Digit(summary || 0),
                        };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.InitialeCheckout, (paramiter || ''));
                }
                break;
            case TrackType.Purchase:
                {
                    // เมื่อมีการซื้อหรือกระบวนการชำระเงินเสร็จสมบูรณ์คนก็ได้ซื้อสินค้าหรือดำเนินการตามกระบวนการชำระเงินจนเสร็จสิ้น และมาถึงหน้าขอบคุณหรือหน้ายืนยัน
                    // content_ids, content_name, content_type, contents, currency, num_items, value ต้องระบุ: currency และ value
                    // ต้องระบุสำหรับโฆษณาแบบไดนามิก: content_ids, content_type และ contents
                    // Done
                    if (data) {
                        const { packageId, type, name, summary, currency, amount } = data;
                        paramiter = {
                            content_ids: packageId || '',
                            content_name: name || '',
                            content_type: type || '',
                            contents: name || '',
                            currency: currency || 'THB',
                            num_items: amount || 0,
                            value: Num2Digit(summary || 0),
                        };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.Purchase, (paramiter || ''));
                }
                break;
            case TrackType.AddPaymentInfo:
                {
                    // เมื่อมีการเพิ่มข้อมูลการชำระเงินในกระบวนการชำระเงินคนก็จะคลิกที่ปุ่มบันทึกข้อมูลการเรียกเก็บเงิน
                    // content_category, content_ids, contents, currency, value
                    if (data) {
                        const { packageId, type, name, summary, currency } = data;
                        paramiter = {
                            content_category: type,
                            content_ids: packageId,
                            contents: name,
                            currency: currency || 'THB',
                            value: Num2Digit(summary || 0),
                        };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.AddPaymentInfo, (paramiter || ''));
                }
                break;
            case TrackType.CustomizeProduct:
                {
                    // เมื่อมีคนกำหนดสินค้าเอง
                    if (data) {
                        paramiter = { content_ids: '', content_type: '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.CustomizeProduct, '');
                }
                break;
            case TrackType.FindLacation:
                {
                    // เมื่อมีคนค้นหาตำแหน่งที่ตั้งร้านของคุณผ่านทางเว็บไซต์หรือแอพด้วยความตั้งใจที่จะไปสถานที่จริงคนก็จะต้องการหาสินค้าที่เจาะจงที่ร้านในพื้นที่
                    // ไม่จำเป็น
                    // Done
                    paramiter = '';
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.FindLacation, (paramiter || ''));
                }
                break;
            case TrackType.Lead:
                {
                    // เมื่อการสมัครใช้งานเสร็จสมบูรณ์คนก็จะคลิกที่การกำหนดราคา
                    // content_category, content_name, currency, value
                    // Done
                    if (data) {
                        const { type, name, amount, currency } = data;
                        paramiter = { content_category: type || '', content_name: name || '', currency: currency || '', value: amount || '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.Lead, (paramiter || ''));
                }
                break;
            case TrackType.AddToWishlist:
                {
                    // เมื่อมีการเพิ่มสินค้าลงในรายการสิ่งที่อยากได้คนก็จะคลิกที่ปุ่มเพิ่มลงในรายการสิ่งที่อยากได้
                    // content_name, content_category, content_ids, contents, currency, value
                    // Done Use
                    if (data) {
                        const { packageId, type, name } = data;
                        paramiter = { content_ids: packageId, content_type: type || '', content_name: name || '' };
                    }
                    else {
                        paramiter = '';
                    }
                    ReactPixel.track(trackType, paramiter);
                }
                break;
            case TrackType.SubmitApplication:
                {
                    // เมื่อมีคนสมัครใช้สินค้า บริการ หรือโปรแกรมที่คุณนำเสนอคน
                    // ไม่จำเป็น
                    // Done
                    paramiter = '';
                    ReactPixel.track(trackType, paramiter);
                }
                break;
            case TrackType.CompleteRegistration:
                {
                    // เมื่อกรอกแบบฟอร์มลงทะเบียนเสร็จสมบูรณ์คนก็จะส่งแบบฟอร์มสมัครรับข้อมูลหรือสมัครใช้งานที่กรอกข้อมูลครบถ้วน
                    // content_name, currency, status, value
                    // Done
                    paramiter = '';
                    ReactPixel.track(trackType, paramiter);
                    // ReactPixel.fbq('track', TrackType.CompletRegisration, (paramiter || ''));
                }
                break;
            case TrackType.ViewAndAddAction:
                {
                    if (data) {
                        const { action } = data;
                        paramiter = { content_ids: '', content_type: '' };
                        ReactPixel.pageView();
                        if (Boolean(action)) {
                            ReactPixel.track(action, paramiter);
                        }
                    }
                    else {
                        paramiter = '';
                        ReactPixel.pageView();
                    }
                }
            default:
                {
                    paramiter = '';
                    ReactPixel.track(trackType, paramiter);
                }
        }
    }
    return null;
};
const FacebookPixelInit = (props = {}) => {
    if (!isInit) {
        const advancedMatching = { em: 'basdee_7@hotmail.com' };
        const options = { autoConfig: true, debug: isDevelop };
        ReactPixel.init(trace.facebookPixelId, advancedMatching, options);
        isInit = true;
    }
};
// const data = {
//     value: 10,
//     currency: 'THB',
//     content_ids: '',
//     content_type: 'product_group_id ',
// };
export default TraceFacebookPixel;
