import { isWidthDown } from "@material-ui/core/withWidth";
import { device } from "../../constant/json/device";
import { initWidth } from "../../utils";
export const Metrics = {
    maxContentWidth: 1145,
    maxInnerWidth: 1032,
    header: {
        height: 60,
        blackHeight: 10,
        mobileHeight: (width) => width === 'xs' ? 56 : 64,
    },
    content: {
        [device.desktop]: { padding: 40 },
        [device.mobile]: { padding: 15 },
    },
    // headerHeight: 110,
    // headerMobileHeight: (width) => width === 'xs' ? 56 : 64,
    navBarHight: 90,
    footer: {
        [device.desktop]: { height: 300 },
        [device.mobile]: { height: 179 },
    },
    modal: {
        borderRadius: '2px',
        authenDialogWidth: 580,
    },
    register: {
        buttonPanel: {
            height: 64,
        },
    },
    menu: {
        height: 48,
    },
    input: {
        label: {
            marginBottom: 3,
        },
        error: {
            marginTop: 5,
            lineHeight: '1em',
        },
        select: {
            height: 50,
        },
    },
    get: (key = '') => {
        if (!!key) {
            const isMobile = isWidthDown('sm', initWidth());
            return Metrics[key][isMobile ? device.mobile : device.desktop];
        }
        return {};
    },
};
