import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */
export const USER_KEY = 'USER';
const { Types, Creators } = createActions({
    registerRequest: ['payload', 'nextAction'],
    registerSuccess: null,
    registerFailure: null,
    forgotPasswordRequest: ['payload', 'nextAction'],
    forgotPasswordSuccess: null,
    forgotPasswordFailure: null,
    getUserRequest: null,
    getUserSuccess: ['payload'],
    getUserFailure: null,
    updateUserRequest: ['payload', 'nextAction'],
    updateUserSuccess: ['payload'],
    updateUserFailure: null,
    changePasswordRequest: ['payload', 'nextAction'],
    changePasswordSuccess: null,
    changePasswordFailure: null,
    createAffiliateIdRequest: ['payload', 'nextAction'],
    createAffiliateIdSuccess: ['payload'],
    createAffiliateIdFailure: null,
    setSocialCache: ['socialCache'],
    clearSocialCache: null,
    getAffiliateRequest: ['payload'],
    getAffiliateSuccess: ['affiliates'],
    getAffiliateFailure: null,
}, { prefix: USER_KEY + '_' });
export const UserTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    error: null,
    loading: false,
    affiliates: null,
    // loadingText: '',
    data: null,
    socialCache: null,
});
/* ------------- Reducers ------------- */
// request the data from an api
export const registerRequest = (state) => Object.assign({}, state, { loading: true });
export const registerSuccess = (state) => (Object.assign({}, state, { loading: false, socialCache: null }));
export const registerFailure = (state) => Object.assign({}, state, { loading: false });
export const forgotPasswordRequest = (state) => Object.assign({}, state, { loading: true });
export const forgotPasswordSuccess = (state) => (Object.assign({}, state, { loading: false }));
export const forgotPasswordFailure = (state) => Object.assign({}, state, { loading: false });
export const getUserRequest = (state) => Object.assign({}, state, { loading: true });
export const getUserSuccess = (state, { payload: { data } }) => (Object.assign({}, state, { data, loading: false }));
export const getUserFailure = (state) => Object.assign({}, state, { loading: false });
export const changePasswordRequest = (state) => Object.assign({}, state, { loading: true });
export const changePasswordSuccess = (state) => (Object.assign({}, state, { loading: false }));
export const changePasswordFailure = (state) => Object.assign({}, state, { loading: false });
export const updateUserRequest = (state) => Object.assign({}, state, { loading: true });
export const updateUserSuccess = (state, { payload: { data } }) => (Object.assign({}, state, {
    data: { ...state.data, ...data },
    loading: false,
}));
export const updateUserFailure = (state) => Object.assign({}, state, { loading: false });
export const createAffiliateIdRequest = (state) => Object.assign({}, state, { loading: true });
export const createAffiliateIdSuccess = (state, { payload: { data } }) => (Object.assign({}, state, {
    data: { ...state.data, ...data },
    loading: false,
}));
export const setSocialCache = (state, { socialCache }) => Object.assign({}, state, { socialCache });
export const clearSocialCache = (state) => Object.assign({}, state, { socialCache: null });
export const getAffiliateRequest = (state) => Object.assign({}, state, { loading: true });
export const getAffiliateSuccess = (state, { affiliates }) => Object.assign({}, state, { affiliates, loading: false });
export const getAffiliateFailure = (state) => Object.assign({}, state, { loading: false });
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.REGISTER_REQUEST]: registerRequest,
    [Types.REGISTER_SUCCESS]: registerSuccess,
    [Types.REGISTER_FAILURE]: registerFailure,
    [Types.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
    [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
    [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,
    [Types.GET_USER_REQUEST]: getUserRequest,
    [Types.GET_USER_SUCCESS]: getUserSuccess,
    [Types.GET_USER_FAILURE]: getUserFailure,
    [Types.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
    [Types.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
    [Types.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
    [Types.UPDATE_USER_REQUEST]: updateUserRequest,
    [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
    [Types.UPDATE_USER_FAILURE]: updateUserFailure,
    [Types.CREATE_AFFILIATE_ID_REQUEST]: createAffiliateIdRequest,
    [Types.CREATE_AFFILIATE_ID_SUCCESS]: createAffiliateIdSuccess,
    [Types.SET_SOCIAL_CACHE]: setSocialCache,
    [Types.CLEAR_SOCIAL_CACHE]: clearSocialCache,
    [Types.GET_AFFILIATE_REQUEST]: getAffiliateRequest,
    [Types.GET_AFFILIATE_SUCCESS]: getAffiliateSuccess,
    [Types.GET_AFFILIATE_FAILURE]: getAffiliateFailure,
});
