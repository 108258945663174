import * as React from "react";
import { Colors, Fonts } from "../../themes";
export default (props) => (<div style={{
    color: Colors.profile.disabledText,
    fontFamily: Fonts.family.avenir,
    fontSize: '29px',
    ...props.style,
}}>
        {props.title}
    </div>);
