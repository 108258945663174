import React, { Component } from 'react';
import { connect } from "react-redux";
import { path } from "ramda";
import Dialog from "../components/dialog";
import Authen from "../components/authen";
import localization from "../services/localization";
import UserActions from "../redux/UserRedux";
// import { Metrics, Device } from "../themes";
import { reduxKeys } from "../constant";
export const AUTHEN_DIALOG_MODE = {
    REGISTER: 'REGISTER',
    LOGIN: 'LOGIN',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};
class AuthenDialogWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: AUTHEN_DIALOG_MODE.LOGIN,
        };
        Dialog.ModalInstances.getInstance().setModal('authen', this);
        this.changeMode = this.changeMode.bind(this);
        this.openModal = this.openModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
        this.handleRegisterSuccess = this.handleRegisterSuccess.bind(this);
        this.handleForgotSuccess = this.handleForgotSuccess.bind(this);
        this.askingDiscard = this.askingDiscard.bind(this);
    }
    openModal(mode = AUTHEN_DIALOG_MODE.LOGIN) {
        if (!!this.modal) {
            this.setState({ mode }, () => {
                this.modal.show();
            });
        }
    }
    hideModal() {
        if (!!this.modal) {
            this.modal.hide();
        }
    }
    async changeMode(mode, openModal = true, force = false) {
        if (mode === this.state.mode) {
            return;
        }
        if (force) {
            this.setState({ mode });
            return;
        }
        const result = await this.askingDiscard();
        const hasSocial = !!path(['socialCache', 'firstName'], this.props.user);
        if (result === false) {
            if (hasSocial && mode !== AUTHEN_DIALOG_MODE.REGISTER) {
                this.props.dispatch(UserActions.clearSocialCache());
            }
            this.setState({ mode }, () => {
                if (openModal === true && this.modal.getStatus() === false) {
                    this.modal.show();
                }
            });
        }
    }
    handleForgotSuccess() {
        this.changeMode(AUTHEN_DIALOG_MODE.LOGIN, false, true);
    }
    handleLoginSuccess() {
        this.modal.hide(true);
    }
    handleRegisterSuccess() {
        this.modal.hide(true);
    }
    renderContent() {
        const { dispatch, isMobile } = this.props;
        switch (this.state.mode) {
            case AUTHEN_DIALOG_MODE.REGISTER:
                return <Authen.Register hocRef={(ref) => this[this.state.mode] = ref} handleRegisterSuccess={this.handleRegisterSuccess} handleLoginSuccess={this.handleLoginSuccess} changeMode={this.changeMode} onHide={!!this.modal ? this.modal.hide : () => null} isMobile={isMobile}/>;
            case AUTHEN_DIALOG_MODE.LOGIN:
                return <Authen.Login dispatch={dispatch} hocRef={(ref) => this[this.state.mode] = ref} handleLoginSuccess={this.handleLoginSuccess} changeMode={this.changeMode} onHide={!!this.modal ? this.modal.hide : () => null}/>;
            case AUTHEN_DIALOG_MODE.FORGOT_PASSWORD:
                return <Authen.ForgotPassword dispatch={dispatch} hocRef={(ref) => this[this.state.mode] = ref} handleForgotSuccess={this.handleForgotSuccess} changeMode={this.changeMode} onHide={!!this.modal ? this.modal.hide : () => null} isMobile={isMobile}/>;
            default:
                return null;
        }
    }
    askingDiscard() {
        const hasSocial = !!path(['socialCache', 'firstName'], this.props.user);
        if (!!this[this.state.mode] && (this[this.state.mode].isChanged() || hasSocial)) {
            return new Promise((resolve) => {
                this.infoModal.ask({
                    title: localization(`confirm_discard`, `DialogLabel`),
                    actions: [
                        {
                            label: localization(`confirm_no`, `DialogLabel`),
                            action: () => resolve(true),
                        },
                        {
                            label: localization(`confirm_yes`, `DialogLabel`),
                            action: () => {
                                resolve(false);
                                if (hasSocial) {
                                    this.props.dispatch(UserActions.clearSocialCache());
                                }
                            },
                        },
                    ],
                });
            });
        }
        else {
            return false;
        }
    }
    render() {
        const { isMobile } = this.props;
        // const paddingRight = isMobile ? 15 : 30;
        const checkModal = this.state.mode === AUTHEN_DIALOG_MODE.REGISTER;
        return (<React.Fragment>
                <Dialog.Modal ref={(ref) => this.modal = ref} beforeHide={this.askingDiscard} listenWidth closeMark={false} withOutFullDialog styleMargin={{ margin: isMobile && checkModal ? 20 : '' }}>
                    <div style={{
            // height: Device.isiOS ?
            //     window.innerHeight - Metrics.header.mobileHeight(width)
            //     :
            //     '',
            padding: isMobile ? 15 : '20px 30px',
            paddingRight: this.state.mode === 'FORGOT_PASSWORD' ? 30 : 0,
            width: isMobile ? 'calc(100vw - 32px)' : '100%',
        }}>
                        {this.renderContent()}
                    </div>
                </Dialog.Modal>
                <Dialog.Info ref={(ref) => this.infoModal = ref}/>
            </React.Fragment>);
    }
}
const mapSteteToProps = (state) => ({
    width: state[reduxKeys.screen].width,
    isMobile: state[reduxKeys.screen].isMobile,
});
export default connect(mapSteteToProps)(AuthenDialogWrapper);
