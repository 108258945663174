export var wordingKeys;
(function (wordingKeys) {
    wordingKeys["templateLabel"] = "templateLabel";
    wordingKeys["packageLabel"] = "packageLabel";
    wordingKeys["sentence1"] = "sentence1";
    wordingKeys["sentence2"] = "sentence2";
    wordingKeys["sentence3"] = "sentence3";
    wordingKeys["Duration"] = "Duration";
    wordingKeys["Passengers"] = "Passengers";
    wordingKeys["ShortPassengers"] = "ShortPassengers";
    wordingKeys["LBQuantity"] = "LBQuantity";
    wordingKeys["LBPassengers"] = "LBPassengers";
    wordingKeys["PassengersUnit"] = "PassengersUnit";
    wordingKeys["StandardBags"] = "Standard bags";
    wordingKeys["Reviews"] = "Reviews";
    wordingKeys["Booked"] = "Booked";
    wordingKeys["BestSeller"] = "BestSeller";
    wordingKeys["ShareTrip"] = "ShareTrip";
    wordingKeys["ShouldKnowTopics"] = "ShouldKnowTopics";
    wordingKeys["CancelationPolicy"] = "CancellationPolicy";
    wordingKeys["HotelPickUpInformation"] = "HotelPickUpInformation";
    wordingKeys["InclusionTopics"] = "InclusionTopics";
    wordingKeys["TourProgramTopics"] = "TourProgramTopics";
    wordingKeys["ReviewsTopics"] = "ReviewsTopics";
    wordingKeys["ReviewPackageTopics"] = "ReviewPackageTopics";
    wordingKeys["BookedTopics"] = "BookedTopics";
    wordingKeys["BookedTransfer"] = "BookedTransfer";
    wordingKeys["ProCodeTopics"] = "ProCodeTopics";
    wordingKeys["Area"] = "Area";
    wordingKeys["PickUpTime"] = "PickUpTime";
    wordingKeys["ExtraCharge"] = "ExtraCharge";
    wordingKeys["EmptySeat"] = "EmptySeat";
    wordingKeys["Date"] = "Date";
    wordingKeys["Adult"] = "Adult";
    wordingKeys["Kid"] = "Kid";
    wordingKeys["Hour"] = "hour";
    wordingKeys["Minute"] = "minute";
    wordingKeys["AgeDate"] = "AgeDate";
    wordingKeys["AgeAdult"] = "AgeAdult";
    wordingKeys["AgeKid"] = "AgeKid";
    wordingKeys["Infant"] = "Infant";
    wordingKeys["Quantity"] = "Quantity";
    wordingKeys["Seat"] = "Seat";
    wordingKeys["Guide"] = "Guide";
    wordingKeys["Discount"] = "Discount";
    wordingKeys["ServiceCharge"] = "ServiceCharge";
    wordingKeys["Total"] = "Total";
    wordingKeys["Activity"] = "Activity";
    wordingKeys["BookButton"] = "BookButton";
    wordingKeys["ChatWithUsButton"] = "ChatWithUs";
    wordingKeys["SBookButton"] = "SBookButton";
    wordingKeys["CouponButton"] = "CouponButton";
    wordingKeys["Highlight"] = "Highlight";
    wordingKeys["ReviewHolder"] = "ReviewHolder";
    wordingKeys["LoadMore"] = "LoadMore";
    wordingKeys["HideReview"] = "HideReview";
    wordingKeys["Submit"] = "Submit";
    wordingKeys["seeMoreLable"] = "seeMoreLable";
    wordingKeys["ResultOf"] = "ResultOf";
    wordingKeys["AtLocation"] = "AtLocation";
    wordingKeys["FilterTopics"] = "FilterTopics";
    wordingKeys["PickUp"] = "PickUp";
    wordingKeys["DropOff"] = "DropOff";
    wordingKeys["Passenger"] = "Passenger";
    wordingKeys["Members"] = "Members";
    wordingKeys["Destination"] = "Destination";
    wordingKeys["Find"] = "Find";
    wordingKeys["SpecialInfoTopics"] = "SpecialInfoTopics";
    wordingKeys["BookingOn"] = "BookingOn";
    wordingKeys["TravelDate"] = "TravelDate";
    wordingKeys["changeDateDetail"] = "changeDateDetail";
    wordingKeys["WarningMSG"] = "WarningMSG";
    wordingKeys["NoServiceMSG"] = "NoServiceMSG";
    wordingKeys["FullServiceMSG"] = "FullServiceMSG";
    wordingKeys["Save"] = "Save";
    wordingKeys["AppTitle"] = "AppTitle";
    wordingKeys["AppDescription"] = "AppDescription";
})(wordingKeys || (wordingKeys = {}));
