export const Images = {
    Flag: {
        EN: require('./assets/svg/icon_eng.svg'),
        TH: require('./assets/svg/icon_thai.svg'),
        CN: require('./assets/images/flag/zh-lang.png'),
    },
    register: {
    // background: require('./assets/images/register/1.png'),
    },
    landing: {
        calendar: require('./assets/images/landing/calendar-alt.png'),
        bannerWebp: [
            require('./assets/images/landing/banner2.jpg'),
            require('./assets/images/landing/banner.jpg'),
            require('./assets/images/landing/banner5.jpg'),
        ],
        bannerJpg: [
            require('./assets/images/landing/banner2.jpg'),
            require('./assets/images/landing/banner.jpg'),
            require('./assets/images/landing/banner5.jpg'),
        ],
        bannersMobileWebp: [
            require('./assets/images/landing/bannerMobile1.jpg'),
            require('./assets/images/landing/bannerMobile2.jpg'),
            require('./assets/images/landing/bannerMobile3.jpg'),
        ],
        bannersMobileJpg: [
            require('./assets/images/landing/bannerMobile1.jpg'),
            require('./assets/images/landing/bannerMobile2.jpg'),
            require('./assets/images/landing/bannerMobile3.jpg'),
        ],
        arrowDown: require('./assets/images/landing/arrowDown.png'),
        customer: {
            Profile1: require('./assets/images/landing/Home1.jpg'),
            Profile2: require('./assets/images/landing/Home2.jpg'),
            Profile3: require('./assets/images/landing/Home3.jpg'),
            Profile4: require('./assets/images/landing/Home4.jpg'),
            Profile5: require('./assets/images/landing/Home5.jpg'),
        },
        extendFooter: {
            partners: [
                require('./assets/images/landing/Partner1.png'),
                require('./assets/images/landing/Partner2.png'),
                require('./assets/images/landing/Partner3.png'),
                // require('./assets/images/landing/Partner4.png'),
                // require('./assets/images/landing/Partner5.png'),
                require('./assets/images/landing/Partner6.png'),
                require('./assets/images/landing/Partner7.png'),
            ],
        },
        footer: {
            support: require('./assets/images/landing/customerSupport.png'),
            social: {
                line: require('./assets/images/landing/line@.png'),
                facebook: require('./assets/images/landing/facebook1.png'),
                instagram: require('./assets/images/landing/instagram1.png'),
                googlePlus: require('./assets/images/landing/google+.png'),
                youtube: require('./assets/images/landing/youtube1.png'),
            },
            pay: [
                // require('./assets/images/landing/Paypal.png'),
                require('./assets/svg/icon_visa.svg'),
                require('./assets/svg/icon_mastercard.svg'),
                require('./assets/svg/icon_jcb.svg'),
            ],
            // appStore: require('./assets/images/landing/App Store Copy.png'),
            // playStore: require('./assets/images/landing/Google Play Copy.png'),
            tat: require('./assets/svg/logo_tat.svg'),
        },
    },
    logo: {
        // white: require('./assets/images/Logo_White.png'),
        Logo: require('./assets/images/Logo.png'),
        LogoMobile: require('./assets/images/Logo.png'),
        Logo_footer: require('./assets/images/Logo.png'),
        Logo_partner: require('./assets/images/header/bangkok_airways.png'),
    },
    profile: {
        // headerBackground: require('./assets/images/profile/headerBackground.png'),
        search: require('./assets/images/profile/Search.png'),
        uploadAvatar: require('./assets/images/profile/Upload.png'),
        edit: require('./assets/images/profile/edit.png'),
        back: require('./assets/images/profile/Down@2x.png'),
        filter: require('./assets/images/profile/Filter.png'),
        Calendra: require('./assets/images/profile/Calendra.png'),
        wallet: require('./assets/images/profile/Wallet.png'),
        product: require('./assets/images/profile/Product.png'),
        productblack: require('./assets/images/profile/Product-black.png'),
        password: require('./assets/images/profile/password.png'),
        productwhite: require('./assets/images/profile/Product-white.png'),
    },
    // headerBG: require('./assets/images/HeaderBarBG.png'),
    headerBG: require('./assets/images/header/Header@3x.jpg'),
    popularBG: require('./assets/images/promotions/PopularBG.png'),
    defaultAvatar: {
        white: require('./assets/images/AvatarWhite.png'),
        blue: require('./assets/images/AvatarBlue.png'),
    },
    nav: {
        dropdown: {
            white: require('./assets/images/nav/DropdownWhite.png'),
            blue: require('./assets/images/nav/DropdownBlue.png'),
        },
        login: require('./assets/images/nav/Login.png'),
        thai: require('./assets/images/nav/Thai.png'),
    },
    booking: {
        scb: require('./assets/images/booking/debit-WP.png'),
        bkk: require('./assets/images/booking/debit-DB.png'),
        krungsri: require('./assets/images/booking/debit-DA.png'),
        krungthai: require('./assets/images/booking/debit-DT.png'),
        alipay: require('./assets/images/booking/china-AL.png'),
        atm: [
            require('./assets/images/booking/55.png'),
            require('./assets/images/booking/11.png'),
            require('./assets/images/booking/22.png'),
            require('./assets/images/booking/33.png'),
            require('./assets/images/booking/44.png'),
        ],
        counter: [
            require('./assets/images/booking/66.png'),
            require('./assets/images/booking/77.png'),
            require('./assets/images/booking/88.png'),
            require('./assets/images/booking/99.png'),
            require('./assets/images/booking/100.png'),
        ],
        credit: {
            jcb: require('./assets/images/booking/jcb.png'),
            mastercard: require('./assets/images/booking/mastercard.png'),
            visa: require('./assets/images/booking/visa.png'),
        },
        success: require('./assets/images/booking/success.png'),
        unsuccess: require('./assets/images/booking/unsuccess.png'),
        uploadButton: require('./assets/images/booking/Upload.png'),
        cvv: require('./assets/images/booking/cvv.png'),
        tesco: require('./assets/images/booking/tesco.png'),
    },
    package: {
        fee: require('./assets/images/package/Fee.png'),
        // tips: require('./assets/images/package/Tips.png'),
        // gift: require('./assets/images/package/Gift.png'),
        sale: require('./assets/images/package/Sale.png'),
        // cancelPolicy: require('./assets/images/package/CancelPolicy.png'),
        // hotelPickup: require('./assets/images/package/HotelPickup.png'),
        duration: require('./assets/images/package/Duration.png'),
        // safeBook: require('./assets/images/package/SafeBook.png'),
        // bestPrice: require('./assets/images/package/BestPrice.png'),
        // bestSeller: require('./assets/images/package/Bestseller.png'),
        // destination: require('./assets/images/package/Destination.png'),
        // ratingActiveReview: require('./assets/images/package/activeReview.png'),
        // ratingDefaultReview: require('./assets/images/package/defaultReview.png'),
        ratingActive: require('./assets/images/package/ActiveStar.png'),
        ratingDefault: require('./assets/images/package/DefaultStar.png'),
        ratingActiveDestination: require('./assets/images/package/DStarActive.png'),
        ratingDefaultDestination: require('./assets/images/package/DStarDefaul.png'),
        wishListActive: require('./assets/images/package/ActiveHeart.png'),
        wishListDefault: require('./assets/images/package/DefaultHeart.png'),
        passengers: require('./assets/images/package/Passengers.png'),
        bags: require('./assets/images/package/Bags.png'),
        // step1Default: require('./assets/images/package/Step1Default.png'),
        // step2Default: require('./assets/images/package/Step2Default.png'),
        // step3Default: require('./assets/images/package/Step3Default.png'),
        // step4Default: require('./assets/images/package/Step4Default.png'),
        // step1Active: require('./assets/images/package/Step1Active.png'),
        // step2Active: require('./assets/images/package/Step2Active.png'),
        // step3Active: require('./assets/images/package/Step3Active.png'),
        // step4Active: require('./assets/images/package/Step4Active.png'),
        bookingPreview: require('./assets/images/package/BookingPreview.png'),
        chooseYour: require('./assets/images/package/ChooseYour.png'),
        paymentChannel: require('./assets/images/package/PaymentChannel.png'),
        search: require('./assets/images/package/Search.png'),
        confirmPayment: require('./assets/images/package/ConfirmPayment.png'),
        wishlist: require('./assets/images/package/Wishlist.png'),
        edit: require('./assets/images/package/Edit.png'),
        // cart: require('./assets/images/package/Shop.png'),
        contactUs: require('./assets/images/package/Vector.png'),
        sub: require('./assets/images/package/Sub.png'),
        add: require('./assets/images/package/Add.png'),
        subDisable: require('./assets/images/package/SubDisable.png'),
        addDisable: require('./assets/images/package/AddDisable.png'),
        down: require('./assets/images/package/Down.png'),
        up: require('./assets/images/package/Up.png'),
        iconPassenger: require('./assets/images/package/IconPassengers.png'),
        iconCalendar: require('./assets/images/package/IconCalendar.png'),
        chooseCalendar: require('./assets/images/package/ChooseCalendar.png'),
        iconCart: require('./assets/images/package/IconCart.png'),
        iconBookingDate: require('./assets/images/package/IconBookingDate.png'),
        IconTraveldate: require('./assets/images/package/IconTraveldate.png'),
        iconPin: require('./assets/images/package/IconPin.png'),
        IconChat: require('./assets/images/package/IconChat.png'),
        iconDown: require('./assets/images/package/IconDown.png'),
        iconUp: require('./assets/images/package/IconUp.png'),
        // iconFacebook: require('./assets/images/package/IconFacebook.png'),
        // iconGooglePlus: require('./assets/images/package/IconGooglePlus.png'),
        // iconTwitter: require('./assets/images/package/IconTwitter.png'),
        filter: require('./assets/images/package/Filter.png'),
        stepPanelDesktop1: require('./assets/images/package/DStepPanel1.png'),
        stepPanelDesktop2: require('./assets/images/package/DStepPanel2.png'),
        stepPanelDesktop3: require('./assets/images/package/DStepPanel3.png'),
        stepPanelDesktop4: require('./assets/images/package/DStepPanel4.png'),
        // stepPanelMobile1: require('./assets/images/package/MStepPanel1.png'),
        // stepPanelMobile2: require('./assets/images/package/MStepPanel2.png'),
        // stepPanelMobile3: require('./assets/images/package/MStepPanel3.png'),
        // stepPanelMobile4: require('./assets/images/package/MStepPanel4.png'),
        stepPanelDesktopTH1: require('./assets/images/package/DStepPanelTH1.png'),
        stepPanelDesktopTH2: require('./assets/images/package/DStepPanelTH2.png'),
        stepPanelDesktopTH3: require('./assets/images/package/DStepPanelTH3.png'),
        stepPanelDesktopTH4: require('./assets/images/package/DStepPanelTH4.png'),
        // stepPanelMobileTH1: require('./assets/images/package/MStepPanelTH1.png'),
        // stepPanelMobileTH2: require('./assets/images/package/MStepPanelTH2.png'),
        // stepPanelMobileTH3: require('./assets/images/package/MStepPanelTH3.png'),
        // stepPanelMobileTH4: require('./assets/images/package/MStepPanelTH4.png'),
        stepPanelTDesktop1: require('./assets/images/package/TStepPanel1.png'),
        stepPanelTDesktop2: require('./assets/images/package/TStepPanel2.png'),
        stepPanelTDesktop3: require('./assets/images/package/TStepPanel3.png'),
        stepPanelTDesktop4: require('./assets/images/package/TStepPanel4.png'),
        // stepPanelTMobile1: require('./assets/images/package/MTStepPanel1.png'),
        // stepPanelTMobile2: require('./assets/images/package/MTStepPanel2.png'),
        // stepPanelTMobile3: require('./assets/images/package/MTStepPanel3.png'),
        // stepPanelTMobile4: require('./assets/images/package/MTStepPanel4.png'),
        stepPanelTDesktopTH1: require('./assets/images/package/TStepPanelTH1.png'),
        stepPanelTDesktopTH2: require('./assets/images/package/TStepPanelTH2.png'),
        stepPanelTDesktopTH3: require('./assets/images/package/TStepPanelTH3.png'),
        stepPanelTDesktopTH4: require('./assets/images/package/TStepPanelTH4.png'),
        // stepPanelTMobileTH1: require('./assets/images/package/MTStepPanelTH1.png'),
        // stepPanelTMobileTH2: require('./assets/images/package/MTStepPanelTH2.png'),
        // stepPanelTMobileTH3: require('./assets/images/package/MTStepPanelTH3.png'),
        // stepPanelTMobileTH4: require('./assets/images/package/MTStepPanelTH4.png'),
        calendra: require('./assets/images/package/calendra.png'),
        // dualRingLoading: require('./assets/images/package/DualRingLoading.gif'),
        car: require('./assets/images/package/Car.png'),
        group: require('./assets/images/package/Group.png'),
        insant: require('./assets/images/package/Insant.png'),
        lang: require('./assets/images/package/Lang.png'),
        maximum: require('./assets/images/package/Maximum.png'),
        noRefund: require('./assets/images/package/norefund.png'),
        protect: require('./assets/images/package/Protect.png'),
        reverse: require('./assets/images/package/Reverse.png'),
        ticket: require('./assets/images/package/Ticket.png'),
        traveler: require('./assets/images/package/Traveler.png'),
        van: require('./assets/images/package/Van.png'),
    },
    howtobook: {
        cover: require('./assets/images/howtobook/Cover.png'),
        Step1: require('./assets/images/howtobook/Step1.png'),
        Step2: require('./assets/images/howtobook/Step2.png'),
        Step3: require('./assets/images/howtobook/Step3.png'),
        Step4: require('./assets/images/howtobook/Step4.png'),
        Step5: require('./assets/images/howtobook/Step5.png'),
        // s1: require('./assets/images/howtobook/1.png'),
        // s2: require('./assets/images/howtobook/2.png'),
        // s3: require('./assets/images/howtobook/3.png'),
        // s4: require('./assets/images/howtobook/4.png'),
        // s5: require('./assets/images/howtobook/5.png'),
        pay1: require('./assets/images/howtobook/image1.png'),
        pay2: require('./assets/images/howtobook/image2.png'),
    },
    about: {
        header: require('./assets/images/about/group.png'),
        // image1: require('./assets/images/about/Image1.png'),
        // image2: require('./assets/images/about/Image2.png'),
        image3: require('./assets/images/about/Image3.png'),
    },
    tranfer: {
        krabi: require('./assets/images/tranfer/krabi.png'),
        phuket: require('./assets/images/tranfer/phuket.png'),
        chiangmai: require('./assets/images/tranfer/chiangmai.png'),
        pattaya: require('./assets/images/tranfer/pattaya.png'),
        car1: require('./assets/images/tranfer/camry.png'),
        car2: require('./assets/images/tranfer/van.png'),
        car3: require('./assets/images/tranfer/bus1.png'),
    },
    contact: {
        address: require('./assets/images/contact/map.png'),
        email: require('./assets/images/contact/envelope.png'),
        web: require('./assets/images/contact/Web.png'),
        line: require('./assets/images/contact/line.png'),
        clock: require('./assets/images/contact/clock.png'),
    },
    dialog: {
        error: require('./assets/images/Error.png'),
        finish: require('./assets/images/Finish.png'),
    },
};
