import { conformToMask } from 'react-text-mask';
import { path, startsWith } from "ramda";
import { mask, mockOptions } from "../constant";
const tel = (phoneNumber) => {
    if (new RegExp("^0\\d{9}").test(phoneNumber)) {
        return phoneNumber.replace(/^0/, '+66');
    }
    else {
        return phoneNumber;
    }
};
const creditCardNumber = (cardNumber) => {
    if (cardNumber.indexOf('-') > 0) {
        return cardNumber.replace(/-/g, '');
    }
    else {
        return conformToMask(cardNumber, mask.creditCardNumber).conformedValue;
    }
};
const telForm = (phoneNumber) => {
    if (startsWith('+', `${phoneNumber}`)) {
        return phoneNumber.slice(-9);
    }
    else {
        return phoneNumber;
    }
};
const userTitle = (val) => {
    if (typeof val === 'number' || val.length < 3) {
        return path(['label'], mockOptions.title.find((each) => each.id === val));
    }
    else {
        return path(['id'], mockOptions.title.find((each) => each.label === val));
    }
};
export default {
    tel,
    creditCardNumber,
    userTitle,
    telForm,
};
