import { createBrowserHistory } from 'history';
import Store from '../redux';
const browserHistory = createBrowserHistory({
/* pass a configuration object here if needed */
// debug: process.env.NODE_ENV === `development`
// forceRefresh: true
});
const push = (path, state) => {
    browserHistory.push(path, state);
    Store.dispatch({ type: 'PUSH_HISTORY', payload: path });
};
const pop = () => {
    if (browserHistory.length > 0) {
        browserHistory.goBack();
    }
    else {
        browserHistory.replace('/');
    }
    Store.dispatch({ type: 'POP_HISTORY' });
};
const replace = (path) => {
    browserHistory.replace(path);
    Store.dispatch({ type: 'REPLACE_HISTORY', payload: path });
};
export const history = {
    browserHistory,
    pop,
    push,
    replace,
};
// export default history;
