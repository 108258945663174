import React, { Fragment } from 'react';
import { path } from "ramda";
import { withStyles } from '@material-ui/styles';
import Collapse from "@material-ui/core/Collapse";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { shadows } from '@material-ui/system';
import { Metrics, Colors } from "../../themes";
const styles = () => ({
    menu_item: {
        'font-size': '12px',
        'text-align': 'center',
        'color': (props) => props.dark ? Colors.lightBlue : '#fff',
        // 'width': '200px',
        'padding': '8px 0',
    },
});
// background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
// const getBgColor = (dark = false) => dark ? '41,88,153' : '255,255,255';
const getBgColor = (dark = false) => dark ? '1' : '0.34';
const getBorderColor = (dark = false) => `rgba(${dark ? '52,109,170,0.34' : '0,0,0,0.06'})`;
const CollapseMenu = (props) => {
    const onClickAway = () => {
        /* */
    };
    return (<ClickAwayListener mouseEvent="onMouseUp" onClickAway={props.onClickAway || onClickAway}>
            <Collapse in={props.open} style={{
        position: 'absolute',
        // background: `linear-gradient(180deg, rgba(${getBgColor(props.dark)},0) 0%,
        // rgba(${getBgColor(props.dark)},${props.dark ? 0.8 : 0.34}) 100%)`,
        background: `linear-gradient(180deg, rgba(255,255,255,0) 0%,
                     rgba(255,255,255,${getBgColor(props.dark)}) 100%)`,
        zIndex: 1,
        width: '80px',
        cursor: 'pointer',
        boxShadow: props.dark ? shadows[2] : '',
        borderRadius: Metrics.modal.borderRadius,
        right: path(['renderPosition', 'right'], props.options),
        top: path(['renderPosition', 'top'], props.options),
        ...path(['collapseStyle'], props.options),
    }}>
                {Array.isArray(props.menuList) && props.menuList.map((each, i) => {
        return (<Fragment key={i}>
                                
                                <div style={{
            height: '1px',
            margin: `${i === 0 ? '35px' : '0'} 10px 0 10px`,
            background: getBorderColor(props.dark),
        }}/>
                                <div className={props.classes.menu_item} onClick={each.onClick}>
                                    {each.label}
                                </div>
                            </Fragment>);
    })}
                
            </Collapse>
        </ClickAwayListener>);
};
export default withStyles(styles)(CollapseMenu);
