import { path } from "ramda";
import { inputType, mockOptions, mask } from "../constant";
import { validateActions } from '../utils/validator';
import { fieldsName as regiterFieldsName } from "./register";
import transform from "../utils/transform";
import localization from "../services/localization";
import { isMobile, isSafari } from "../containers/PackageInfoPanel/GetItemColsPerWidth";
import { nationalities } from "../constant/json/nationalities";
export const fieldsName = {
    avatarLink: 'avatarLink',
    [regiterFieldsName.title]: regiterFieldsName.title,
    [regiterFieldsName.nationality]: regiterFieldsName.nationality,
    [regiterFieldsName.firstName]: regiterFieldsName.firstName,
    [regiterFieldsName.lastName]: regiterFieldsName.lastName,
    [regiterFieldsName.email]: regiterFieldsName.email,
    [regiterFieldsName.countryCode]: regiterFieldsName.countryCode,
    [regiterFieldsName.tel]: regiterFieldsName.tel,
    [regiterFieldsName.social]: regiterFieldsName.social,
    [regiterFieldsName.socialId]: regiterFieldsName.socialId,
    [regiterFieldsName.isAcceptInformation]: regiterFieldsName.isAcceptInformation,
    creditCard: 'creditCard',
    nameOnCard: 'nameOnCard',
    expireCard: 'expireCard',
    reserveTel: 'reserveTel',
    reserveCountryCode: 'reserveCountryCode',
};
export default ({ 
// onPressEnter,
defaultValues, isSocial = false, language, locale, stringCreditCard, tel, code, countryJson, checkSocial, selectSocial, isMobiles, }) => {
    return {
        [fieldsName.avatarLink]: {
            // name: defaultValues.name,
            value: defaultValues[fieldsName.avatarLink],
            type: inputType.AVATAR,
            dark: true,
            editable: !isSocial,
            style: {
                default: {
                    paddingBottom: '20px',
                },
            },
        },
        [fieldsName.title]: {
            label: localization(`title`, `ProfileLabel`),
            type: inputType.RADIO,
            validate: (val) => {
                if (val === 0 || val === '') {
                    return 'error_require_title';
                }
            },
            md: isMobiles ? 8 : 12,
            style: {
                default: {
                    paddingTop: '20px',
                },
                md: {
                    paddingTop: '20px',
                    textAlign: 'start',
                },
            },
            value: defaultValues[regiterFieldsName.title] ?
                transform.userTitle(defaultValues[regiterFieldsName.title])
                :
                    undefined,
            options: language === 'EN' ? mockOptions.title : mockOptions.title_TH,
        },
        [fieldsName.firstName]: {
            label: localization(`firstname`, `ProfileLabel`),
            placeholder: localization(`firstname`, `ProfileLabel`),
            type: inputType.TEXT_INPUT,
            validate: validateActions.user_firstname,
            value: defaultValues[regiterFieldsName.firstName],
            md: isMobiles ? 12 : 6,
            style: {
                default: {
                // paddingTop: '20px',
                },
                md: {
                    // paddingTop: '20px',
                    paddingRight: '20px',
                },
            },
        },
        [fieldsName.lastName]: {
            label: localization(`lastname`, `ProfileLabel`),
            placeholder: localization(`lastname`, `ProfileLabel`),
            type: inputType.TEXT_INPUT,
            validate: validateActions.user_lastname,
            value: defaultValues[regiterFieldsName.lastName],
            md: isMobiles ? 12 : 6,
            style: {
                md: {
                    // paddingTop: '20px',
                    paddingLeft: '20px',
                },
            },
        },
        [fieldsName.nationality]: {
            label: localization(`nationality`, `BookingLabel`),
            placeholder: localization(`nationality`, `BookingLabel`),
            type: inputType.SELECT,
            // validate: validateActions.user_nationality,
            value: path([fieldsName.nationality], defaultValues),
            choices: nationalities.map((each) => ({ id: each.label, label: each.label })),
            md: isMobiles ? 12 : 6,
            filter: true,
            style: {
                default: {
                    paddingRight: '10px',
                },
                md: {
                    paddingRight: '20px',
                },
            },
            options: {
                selector: {
                    style: {
                        backgroundColor: '#fff',
                    },
                    collapseStyle: {
                        position: isMobile ? 'relative' : 'absolute',
                        width: '100%',
                    },
                },
            },
        },
        [fieldsName.email]: {
            label: localization(`email`, `ProfileLabel`),
            placeholder: localization(`email`, `ProfileLabel`),
            type: inputType.TEXT_INPUT,
            validate: validateActions.user_email,
            value: defaultValues[regiterFieldsName.email],
            editable: !isSocial,
            md: isMobiles ? 12 : 12,
            style: {
                default: {
                // paddingTop: '20px',
                },
                md: {
                // paddingTop: '20px',
                // paddingRight: '20px',
                },
            },
        },
        [fieldsName.countryCode]: {
            label: localization(`dialingCode`, `ProfileLabel`),
            placeholder: '',
            type: inputType.SELECT,
            choices: countryJson,
            show: 'id',
            filter: true,
            // validate: validateActions.user_tel,
            value: !!defaultValues[regiterFieldsName.countryCode] ? defaultValues[regiterFieldsName.countryCode] : code,
            md: isMobiles ? 4 : 2,
            style: {
                md: {
                    // paddingLeft: '20px',
                    maxWidth: '18%',
                    flexBasis: '18%',
                    marginTop: '1px',
                },
            },
            options: {
                selector: {
                    label: {
                        textAlign: 'center',
                        width: 'calc(100% - 10px)',
                    },
                    style: {
                        backgroundColor: '#fff',
                        height: isSafari() ? 44 : 41,
                        borderRadius: '2px',
                    },
                    collapseStyle: {
                        position: 'relative',
                        width: '285px',
                    },
                    labelWrapperStyle: {
                        minWidth: '55px',
                    },
                },
            },
        },
        [fieldsName.tel]: {
            label: localization(`tel`, `ProfileLabel`),
            placeholder: localization(`tel`, `ProfileLabel`),
            type: inputType.TEXT_INPUT,
            validate: validateActions.user_tel,
            value: tel,
            md: isMobiles ? 8 : 4,
            style: {
                default: {
                // paddingTop: '20px',
                },
                md: {
                    paddingRight: '20px',
                    maxWidth: '32%',
                    flexBasis: '32%',
                },
            },
        },
        [fieldsName.reserveCountryCode]: {
            label: localization(`dialingCode`, `ProfileLabel`),
            placeholder: '',
            type: inputType.SELECT,
            choices: countryJson,
            show: 'id',
            filter: true,
            // validate: validateActions.user_countryCode,
            value: !!defaultValues[fieldsName.reserveCountryCode] ?
                defaultValues[fieldsName.reserveCountryCode] : path(['id'], locale),
            md: isMobiles ? 4 : 2,
            style: {
                default: {
                    marginTop: '1px',
                },
                md: {
                    paddingLeft: '20px',
                    maxWidth: '22%',
                    flexBasis: '22%',
                    marginTop: '1px',
                },
            },
            options: {
                selector: {
                    label: {
                        textAlign: 'center',
                        width: 'calc(100% - 10px)',
                    },
                    style: {
                        backgroundColor: '#fff',
                        height: isSafari() ? 44 : 41,
                        borderRadius: '2px',
                    },
                    collapseStyle: {
                        position: 'relative',
                        width: '285px',
                    },
                    labelWrapperStyle: {
                        minWidth: '55px',
                    },
                },
            },
        },
        [fieldsName.reserveTel]: {
            label: 'Reserve Tel',
            placeholder: localization(`reserveTel`, `ProfileLabel`),
            type: inputType.TEXT_INPUT,
            md: isMobiles ? 8 : 4,
            style: {
                md: {
                    maxWidth: '28%',
                    flexBasis: '28%',
                },
            },
            value: defaultValues[fieldsName.reserveTel],
        },
        [fieldsName.social]: {
            label: localization(`social`, `AuthenLabel`),
            placeholder: 'Please select social',
            type: inputType.SELECT,
            choices: mockOptions.socialOption,
            // validate: validateActions.user_tel,
            value: defaultValues[regiterFieldsName.social],
            md: 6,
            style: {
                default: {
                    paddingRight: '20px',
                },
                md: {
                    paddingRight: '29px',
                },
            },
            options: {
                selector: {
                    style: {
                        backgroundColor: '#fff',
                        borderRadius: '2px',
                    },
                    collapseStyle: {
                        position: 'relative',
                        width: '142px',
                    },
                },
            },
            onClick: selectSocial,
        },
        [fieldsName.socialId]: {
            label: localization(`social_id`, `AuthenLabel`),
            placeholder: 'WhatsApp no. / Wechat ID / Line ID',
            type: inputType.TEXT_INPUT,
            md: 6,
            style: {
                md: {
                    paddingLeft: '20px',
                },
            },
            validate: checkSocial === false ? null : validateActions.user_socialId,
            value: defaultValues[regiterFieldsName.socialId],
        },
        [fieldsName.nameOnCard]: {
            label: localization(`name_on_card`, `BookingLabel`),
            placeholder: 'Name',
            type: inputType.TEXT_INPUT,
            // validate: validateActions.user_firstname,
            value: defaultValues[fieldsName.nameOnCard],
            md: isMobiles ? 12 : 6,
            style: {
                default: {
                // paddingTop: '20px',
                },
                md: {
                    // paddingTop: '20px',
                    paddingRight: '20px',
                },
            },
        },
        [fieldsName.creditCard]: {
            label: localization(`credit_card_number`, `BookingLabel`),
            placeholder: 'XXXX-XXXX-XXXX-XXXX',
            type: inputType.TEXT_INPUT,
            md: isMobiles ? 12 : 6,
            // validate: validateActions.payment_creditCardNumber,
            // value: path([fieldsName.firstName], defaultValues),
            transform: transform.creditCardNumber,
            mask: mask.creditCardNumber,
            style: {
                default: {
                    paddingRight: '10px',
                },
                md: {
                    paddingLeft: '20px',
                    paddingRight: '10px',
                },
            },
            value: stringCreditCard,
        },
        [fieldsName.expireCard]: {
            label: localization(`expire`, `BookingLabel`),
            placeholder: 'MM/YY',
            // require: true,
            type: inputType.TEXT_INPUT,
            // validate: validateActions.payment_creditCardExpire,
            value: path([fieldsName.expireCard], defaultValues),
            mask: mask.creditCardExpire,
            md: isMobiles ? 6 : 3,
            style: {
                default: {
                    paddingRight: '10px',
                },
                md: {
                // paddingLeft: '10px',
                // paddingRight: '10px',
                // paddingTop: '10px',
                // paddingLeft: '20px',
                // maxWidth: '37.5%',
                // flexBasis: '37.5%',
                },
            },
        },
    };
};
