export default {
    FAQTopics: 'คำถามที่พบบ่อย',
    FAQ00001: {
        questions: `ฉันสามารถเปลี่ยนแปลงการจองได้หรือไม่ ?`,
        ask: `เกาะพีพีทัวร์ไม่มีการเรียกเก็บค่าธรรมเนียมในการแก้ไข หรือเปลี่ยนแปลงการจอง
        ในกรณีมีการแก้ไขการจองก่อนวันเดินทาง 24 ช่ัวโมง(1วัน)
        กรณีเปลี่ยนแปลงการจองน้อยกว่า 24 ชั่วโมงก่อนเดินทางนโยบายการแก้ไข
        จะขึ้นอยู่กับข้อกำหนดของแต่ละซัพพลายเออร์`,
    },
    FAQ00002: {
        questions: `ความปลอดภัยในการชำระเงิน`,
        ask: `ระบบการชำระเงินออนไลน์ของเกาะพีพีทัวร์มีการรักษาความปลอดภัยระดับสูง
        มีการป้องกันการทำธุรกรรมทางการเงินออนไลน์ที่เชื่อถือได้
        เพื่อรักษาความปลอดภัยของคุณกรุณาจองเละชำระเงินผ่านระบบเกาะพีพีทัวร์เท่านั้น`,
    },
};
