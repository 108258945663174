import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { path, append, remove, equals } from "ramda";
import { withStyles } from '@material-ui/styles';
import ButtonBase from "@material-ui/core/ButtonBase";
import RootRef from '@material-ui/core/RootRef';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { shadows } from '@material-ui/system';
import Text from "../text";
import { Svg, Colors, Metrics } from "../../themes";
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";
import Filter from "./Filter";
const styles = (theme) => ({
    button: {
        textTransform: 'none',
        width: '100%',
        height: '41px',
        border: (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // '&:focus': {
        //     borderColor: '#80bdff',
        //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        // },
        paddingLeft: 12,
        paddingRight: 12,
    },
    optionRoot: {
        width: '100%',
        display: 'block',
        textAlign: 'left',
    },
});
const Option = styled.div `
    height: ${(props) => props.optionHeight ? props.optionHeight : Metrics.input.select.height}px;
    color: ${(props) => props.isBlank ? '#333' : '#333'};
    font-size: 16px;
    line-height: ${(props) => props.optionHeight ? props.optionHeight : Metrics.input.select.height}px;
    padding: 0 25px;
    border-bottom: ${(props) => props.isLast ? '' : '1px solid #979797'};
    background-color: ${(props) => props.isSelected ? props.selectedBGColor || 'rgba(0,0,0,0.12)' : ''};
    transition: background-color 250ms, color 250ms;
    ${(props) => props.disabledHover ? '' : `
    &:hover {
        background-color: rgba(0,0,0,0.12);
    }
    `}
`;
const ErrorOption = styled.div `
    height: ${(props) => props.optionHeight ? props.optionHeight : Metrics.input.select.height}px;
    color: #888;
    font-size: 16px;
    padding: 0 25px;
    cursor: default;
    line-height: ${(props) => props.optionHeight ? props.optionHeight : Metrics.input.select.height}px;
`;
// background: ${(props) => props.isBlank ? Colors.gradient : ''};
// background-color: ${Colors.theme};
// color: #fff;
class Select extends Component {
    constructor(props) {
        super(props);
        this.inputWidth = '100%';
        this.timeoutId = null;
        this.stillOpen = false;
        this.button = React.createRef();
        this.isUnmount = false;
        let selected = props.multiple ? [] : 0;
        if (!!props.value) {
            selected = props.value;
        }
        // if (props.multiple) {
        //     selected = props.value;
        // } else {
        //     if (!!props.value) {
        //         selected = props.value;
        //     }
        //     // if (!!props.value && Array.isArray(props.choices)) {
        //     //     selected = props.choices.findIndex((each) => each.id === props.value);
        //     //     selected = selected > -1 ? selected : 0;
        //     // }
        // }
        const { getRef } = props;
        if (typeof getRef === 'function') {
            getRef(this);
        }
        this.state = {
            open: false,
            selected,
            filterText: '',
        };
        this.openSelect = this.openSelect.bind(this);
        this.closeSelect = this.closeSelect.bind(this);
        this.toggleSelect = this.toggleSelect.bind(this);
        this.onClickOption = this.onClickOption.bind(this);
        this.getIsSelected = this.getIsSelected.bind(this);
    }
    componentDidMount() {
        const buttonNode = ReactDOM.findDOMNode(this.button.current);
        if (!!buttonNode) {
            const { width } = buttonNode.getBoundingClientRect();
            if (width > 0) {
                this.inputWidth = width;
                this.forceUpdate();
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(this.props.value, nextProps.value)) {
            this.setState({
                selected: nextProps.value,
            });
        }
    }
    componentWillUnmount() {
        this.isUnmount = true;
        if (!!this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
    closeSelect(state, cb) {
        this.stillOpen = false;
        this.timeoutId = setTimeout(() => {
            if (!this.stillOpen && !this.isUnmount) {
                if (this.state.open === true) {
                    this.setState({ open: false, ...state }, () => {
                        if (typeof cb === 'function') {
                            cb(false);
                        }
                    });
                }
            }
            this.timeoutId = null;
        }, 100);
    }
    openSelect(state, cb) {
        this.stillOpen = true;
        if (this.state.open === false) {
            this.setState({ open: true, ...state }, () => {
                if (typeof cb === 'function') {
                    cb(true);
                }
            });
        }
    }
    toggleSelect(cb) {
        if (this.state.open === false) {
            this.openSelect({}, cb);
        }
        else {
            this.closeSelect({}, cb);
        }
    }
    getSelectorLabel() {
        const { choices, multiple, placeholder = 'Default Label', show } = this.props;
        if (Array.isArray(choices)) {
            if (multiple) {
                if (this.state.selected.length === 0) {
                    return path(['label'], choices.find((each) => !!each.default));
                }
                return choices.map((each) => {
                    if (this.state.selected.some((eachSelect) => eachSelect === each.id)) {
                        return each.label;
                    }
                    return '';
                }).filter((each) => !!each).join(', ');
            }
            const choice = choices.find((each) => each.id === this.state.selected);
            let label = path(['label'], choice);
            const id = path(['id'], choice);
            label = !!label && id !== '' ? label : placeholder;
            return !!show ? id : label;
        }
        else {
            return placeholder;
        }
    }
    getIsSelected(each) {
        const { selected } = this.state;
        if (this.props.multiple === true) {
            return selected.some((eachSelect) => {
                return eachSelect === each.id;
            });
        }
        else {
            return selected === each.id;
        }
    }
    isSelectedDefault() {
        if (this.props.multiple) {
            return this.state.selected.length === 0;
        }
        else {
            const choice = this.props.choices.find((each) => each.id === this.state.selected);
            return path(['default'], choice);
        }
    }
    getChoiceWidth() {
        let width = path(['choicesPosition', 'width'], this.props.options);
        width = typeof width === 'number' || (typeof width === 'string' && width.length > 0) ?
            width : this.inputWidth;
        return width;
    }
    onClickOption(option) {
        const { multiple, onChange, validate, toggleCB, filter, onClick } = this.props;
        if (multiple === true) {
            if (option.default !== true) {
                const index = this.state.selected.findIndex((eachSelected) => {
                    return eachSelected === option.id;
                });
                let newSelected;
                if (index === -1) {
                    newSelected = append(option.id, this.state.selected);
                }
                else {
                    newSelected = remove(index, 1, this.state.selected);
                }
                this.setState({ selected: newSelected }, () => {
                    if (typeof onChange === 'function') {
                        onChange(newSelected);
                    }
                });
            }
        }
        else {
            if (typeof validate === 'function') {
                validate(option.id);
            }
            if (typeof onClick === 'function') {
                onClick();
            }
            this.closeSelect({ selected: option.id }, () => {
                if (typeof onChange === 'function') {
                    onChange(option.id);
                }
                if (typeof toggleCB === 'function') {
                    toggleCB(false);
                }
            });
        }
        // remove filter when select an option
        if (filter) {
            this.setState({ filterText: '' });
        }
    }
    renderChoices() {
        const { choices, options, classes, filter, label, } = this.props;
        const { filterText } = this.state;
        const height = path(['selector', 'optionHeight'], options) || Metrics.input.select.height;
        const maxHeight = filter ? height * 5 + 39 : height * 5;
        const optionHeight = path(['selector', 'optionHeight'], options);
        let choicesResult = <ErrorOption optionHeight={optionHeight}>{`No options provied`}</ErrorOption>;
        if (Array.isArray(choices)) {
            choicesResult = choices.map((each, i) => {
                if (filter && !(new RegExp(filterText, 'i').test(each.label))) {
                    return null;
                }
                return (<ButtonBase key={i} onClick={() => this.onClickOption(each)} classes={{
                    root: classes.optionRoot,
                }}>
                        <Option isBlank={!!each.default} isLast={i === choices.length - 1} isSelected={this.getIsSelected(each)} selectedBGColor={path(['selector', 'selectedBGColor'], options)} style={path(['selector', 'choiceStyle'], options)} optionHeight={optionHeight} disabledHover={path(['selector', 'disabledHover'], options)}>
                            <Text.Etext text={each.label}/>
                        </Option>
                    </ButtonBase>);
            }).filter((each) => !!each);
            if (filter && filterText !== '' && choicesResult.length === 0) {
                choicesResult = <ErrorOption optionHeight={optionHeight}>{`No result`}</ErrorOption>;
            }
        }
        return (<div style={{
            maxHeight: Array.isArray(choices) ?
                `${maxHeight}px`
                :
                    '200px',
            overflowY: 'auto',
        }}>
                {filter && (<Filter label={label} onChange={(value) => {
            this.setState({ filterText: value });
        }} value={filterText}/>)}
                {choicesResult}
            </div>);
    }
    render() {
        const { label, error, errorId, classes, options, labelStyle, disabled = false, placeholder, toggleCB, require, } = this.props;
        const selectorLabel = this.getSelectorLabel();
        const remote = path(['remote'], options);
        const labelWrapperStyle = path(['selector', 'labelWrapperStyle'], options) || {};
        return (<Fragment>
                <FieldLabel label={label} style={labelStyle} error={error} require={require}/>
                <ClickAwayListener mouseEvent="onMouseUp" onClickAway={() => this.closeSelect({}, toggleCB)}>
                    <div style={path(['selector', 'wrapperStyle'], options)}>
                        <RootRef rootRef={this.button}>
                            <ButtonBase disabled={disabled} classes={{
            root: classes.button,
        }} onClick={() => {
            this.setState({ open: !this.state.open });
        }} style={{
            ...path(['selector', 'style'], options),
            display: remote ? 'none' : '', height: 41
        }}>
                                <div style={{
            display: 'flex',
            width: '100%',
            minWidth: '100px',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: (selectorLabel === placeholder || this.isSelectedDefault()
                ?
                    Colors.input.label
                :
                    ''),
            ...labelWrapperStyle,
        }}>
                                    <div style={{
            width: 'calc(100% - 18px)',
            textAlign: 'left',
            ...path(['selector', 'label'], options),
        }}>
                                        <Text.Etext style={{ fontSize: path(['selector', 'label', 'fontSize'], options) || 16 }} text={selectorLabel}/>
                                    </div>
                                    {path(['dropdownIcon'], options)
            ||
                <Svg.dropdown.grey color={path(['selector', 'dropdownColor'], options) || ''}/>}
                                </div>
                            </ButtonBase>
                        </RootRef>
                        <Collapse in={this.state.open} style={{
            // position: this.inputWidth === '100%' ? 'relative' : 'absolute',
            position: 'absolute',
            backgroundColor: '#fff',
            zIndex: 1,
            width: this.getChoiceWidth(),
            cursor: 'pointer',
            boxShadow: shadows[2],
            left: path(['choicesPosition', 'left'], options),
            top: path(['choicesPosition', 'top'], options),
            ...path(['selector', 'collapseStyle'], options),
        }}>
                            {!!options && typeof options.renderChoices === 'function' ?
            options.renderChoices({
                onClickOption: this.onClickOption,
                getIsSelected: this.getIsSelected,
            })
            :
                this.renderChoices()}
                        </Collapse>
                    </div>
                </ClickAwayListener>
                {!remote && (<FieldError display={path(['displayError'], options)} error={error} errorId={errorId}/>)}
            </Fragment>);
    }
}
const SelectWithStyle = withStyles(styles)(Select);
export default SelectWithStyle;
