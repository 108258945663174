import React, { Component } from 'react';
import { path, equals } from "ramda";
import Grid from "@material-ui/core/Grid";
import HeaderForm from "./HeaderForm";
import Divider from "../Divider";
import { inputType } from "../../constant";
import ChildrenForm from "./ChildrenForm";
class Form extends Component {
    constructor() {
        super(...arguments);
        this.form = {};
        this.skipKey = ['divier', 'header'];
    }
    isChanged() {
        const { form } = this.props;
        return Object.keys(form).some((key) => {
            if (this.isSkip(key) || form[key].skip) {
                return false;
            }
            return !equals(form[key].value
                ||
                    (form[key].checkType === inputType.CHECKBOX ? [] : ''), this.form[key].state.value);
        });
    }
    reRenderForm() {
        this.forceUpdate();
    }
    submit() {
        let success = true;
        const data = {};
        Object.keys(this.form).forEach((key) => {
            if (!this.isSkip(key) && !this.props.form[key].skip) {
                const validateResult = this.form[key].validate();
                success = success && validateResult;
                data[key] = this.form[key].state.value;
            }
        });
        return {
            success,
            data,
        };
    }
    render() {
        const { form, style, filter } = this.props;
        return (<Grid container spacing={0} style={style}>
                {!!form && Object.keys(form).length > 0 &&
            Object.keys(form).map((key, index) => {
                if (form[key].skip) {
                    return null;
                }
                if (key.indexOf('divider') > -1) {
                    return <Divider style={{ width: '100%' }} key={index}/>;
                }
                if (key.indexOf('header') > -1) {
                    return <HeaderForm {...form[key]} key={index}/>;
                }
                if (key.indexOf('header1') > -1) {
                    return <HeaderForm {...form[key]} key={index}/>;
                }
                if (key.indexOf('header2') > -1) {
                    return <HeaderForm {...form[key]} key={index}/>;
                }
                return (<ChildrenForm reRenderForm={this.reRenderForm.bind(this)} form={{ ...form[key], fieldName: key }} require_value={path(['form', form[key].require_value, 'state', 'value'], this)
                    ||
                        path([form[key].require_value, 'value'], form)
                    ||
                        ''} filter={filter} key={index} formRef={(ref) => this.form[key] = ref}/>);
            })}
            </Grid>);
    }
    isSkip(key) {
        return this.skipKey.some((each) => each.indexOf(key) > -1);
    }
}
export { Form };
