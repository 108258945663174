import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { path } from "ramda";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import FAQPanel from './FAQPanel';
import SelectBox from './SelectBox';
import ForeignPanel from './ForeignPanel';
import DatePicker from './CustomDatePicker';
import PaymentTotalPanel from './PaymentTotalPanel';
import config from '../../config';
import Dialog from "../../components/dialog";
import styled, { css } from 'styled-components';
import { Images, Fonts, Svg, Colors } from '../../themes';
import { isMobile, isSafari } from '../PackageInfoPanel/GetItemColsPerWidth';
import { ConveyanceType } from '../../containers/Search/FilterTypeInfo';
import BookingActions, { BOOKING_KEY } from "../../redux/BookingRedux";
import { NumberFormat } from '../../components/common/SetNumberFormat';
import { GetSymbol } from '../../components/common/GetSymbol';
import { InPeriodTime, getDayWithFilterDate } from '../../components/common/SetDate';
import TraceFacebookPixel, { TrackType } from '../../components/TraceInfo/FacebookPixel';
import { pathName, reduxKeys, packageType, typeOfTransfer } from '../../constant';
// const { chooseCalendar, iconCart } = Images.package;
// const { cart,chooseCalendar } = Images.booking;
// import Select from 'material-ui/Select';
// import { MenuItem } from 'material-ui/Menu';
// import Input, { InputLabel } from 'material-ui/Input';
// import { FormControl, FormHelperText } from 'material-ui/Form';
// import { withStyles } from '@material-ui/core/styles';
// import { GetSymbol, AgeCondition } from '../../components/common/GetSymbol';
import SpecialInfoPanel from './SpecialInfoPanel';
import localization, { isLanguage } from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
let pageLabel;
const isDate = Date();
const { currency: defaultCurrency } = config.default;
const { add, sub, addDisable, subDisable, IconChat } = Images.package;
export var FormType;
(function (FormType) {
    FormType["Date"] = "Date";
    FormType["Adult"] = "Adult";
    FormType["Kid"] = "Kid";
    FormType["Infant"] = "Infant";
    FormType["Foreign"] = "Foreign";
    FormType["ForeignAdult"] = "ForeignAdult";
    FormType["Passengers"] = "Passengers";
    FormType["ForeignKid"] = "ForeignKid";
    FormType["ForeignInfant"] = "ForeignInfant";
    FormType["Transportation"] = "Transportation";
    FormType["Quantity"] = "Quantity";
    FormType["CarSeat"] = "Seat";
    FormType["Guide"] = "Guide";
    FormType["BoatQuantity"] = "BoatQuantity";
    FormType["BoatSeat"] = "BoatSeat";
})(FormType || (FormType = {}));
const isActive = false;
const packageWording = [
    { topic: FormType.Date, currency: defaultCurrency },
    { topic: FormType.Adult, currency: defaultCurrency },
    { topic: FormType.Passengers, currency: defaultCurrency },
    { topic: FormType.Kid, currency: defaultCurrency },
    { topic: FormType.Infant, currency: defaultCurrency },
];
// const _foreign = { generation: [FormType.Adult, FormType.Kid, FormType.Infant], currency: defaultCurrency }
let BookingList = [];
let Amount = 0;
let salf;
const initState = {
    Adult: 0,
    Kid: 0,
    Infant: 0,
    Transportation: 0,
    Foreign: 0,
    ForeignAdult: 0,
    ForeignKid: 0,
    ForeignInfant: 0,
    Date: '',
    Passengers: 0,
    disabledBooking: true,
    isForeign: false,
};
class SummaryPanel extends Component {
    constructor(props) {
        super(props);
        this.state = { ...initState };
        this.infoModal = null;
        this.SetFormatData = (summary) => {
            const isLoggedIn = !!path([reduxKeys.authen, 'token'], this.props);
            if (isLoggedIn) {
                this.getBookingCheck(summary);
            }
            else {
                this.infoModal.ask({
                    title: localization(`login_to_get`, `AuthenLabel`),
                    actions: [
                        {
                            label: localization(`login_book`, `AuthenLabel`),
                            action: () => { Dialog.authen().openModal(); },
                        },
                        {
                            label: localization(`continue_guest`, `AuthenLabel`),
                            action: () => { this.getBookingCheck(summary); },
                        },
                    ],
                });
            }
        };
        salf = this;
        const { SelectedInfo, SelectedLists, FilterDate } = this.props;
        BookingList = SelectedLists;
        switch (true) {
            case Boolean(SelectedInfo):
                {
                    const { bookingInfo } = SelectedInfo;
                    this.state.Adult = bookingInfo.Adult || 0;
                    this.state.Kid = bookingInfo.Kid || 0;
                    this.state.Infant = bookingInfo.Infant || 0;
                    this.state.Passengers = bookingInfo.Passengers || 0;
                    this.state.Date = bookingInfo.Date || null;
                    this.state.disabledBooking = false;
                }
                break;
            case Boolean(FilterDate):
                {
                    this.state.Date = FilterDate || null;
                    const { packageInfo } = BookingList[0];
                    const { periodTime, sellListInfo } = packageInfo;
                    const { soldOutList } = (sellListInfo || { soldOutList: [] });
                    pageLabel = localization(wordingKeys.packageLabel);
                    if (soldOutList.includes(FilterDate)) {
                        this.state.disabledBooking = true;
                        if (!!Dialog.error()) {
                            Dialog.error({
                                key: BOOKING_KEY, title: pageLabel[wordingKeys.WarningMSG],
                                content: pageLabel[wordingKeys.FullServiceMSG],
                            });
                        }
                    }
                    else {
                        const periodInfo = periodTime.find((period) => InPeriodTime(period.startDate, period.endDate, FilterDate || isDate));
                        if (Boolean(periodInfo)) {
                            const { serviceDate } = periodInfo;
                            const isService = serviceDate.includes(getDayWithFilterDate(FilterDate));
                            if (isService) {
                                this.state.disabledBooking = false;
                            }
                            else {
                                this.state.disabledBooking = true;
                                if (!!Dialog.error()) {
                                    Dialog.error({
                                        key: BOOKING_KEY,
                                        title: pageLabel[wordingKeys.WarningMSG],
                                        content: pageLabel[wordingKeys.NoServiceMSG],
                                    });
                                }
                            }
                        }
                        else {
                            this.state.disabledBooking = true;
                            if (!!Dialog.error()) {
                                Dialog.error({
                                    key: BOOKING_KEY,
                                    title: pageLabel[wordingKeys.WarningMSG],
                                    content: pageLabel[wordingKeys.NoServiceMSG],
                                });
                            }
                        }
                    }
                }
                break;
        }
    }
    render() {
        pageLabel = localization(wordingKeys.packageLabel);
        BookingList = this.props.SelectedLists;
        const _isMobile = isMobile();
        const _language = isLanguage();
        const { isPromotion, packageInfo } = BookingList[0];
        return (<React.Fragment>
                <SumamryItemsContainer isMobile={_isMobile}>
                    {(isPromotion) && <SpecialInfoPanel packageInfo={packageInfo} isMobile={_isMobile} language={_language}/>}
                    {this.SetPanelAll(BookingList, _language, _isMobile)}
                </SumamryItemsContainer>
                <Dialog.Info ref={(ref) => this.infoModal = ref}/>
            </React.Fragment>);
    }
    SetPanelAll(bookingList, language, isMobile) {
        const list = [];
        let summary = 0;
        let vnsc;
        let currency;
        let isPeriodPromotion = false;
        const { Adult, Kid, Infant, isForeign, Date: _Date } = this.state;
        Amount = (Adult + Kid + Infant);
        // console.log('Date : ', _Date);
        for (const booking of bookingList) {
            // console.log('SetPanelAll : ', booking);
            const { isPromotion, packageInfo, foreignPrice } = booking;
            const { promotionInfo: InpromotionInfo, price: Inprice, periodTime } = packageInfo;
            // console.log('packageInfo : ', periodTime, ' : ', isPeriodPromotion);
            const periodInfo = periodTime.find((period) => InPeriodTime(period.startDate, period.endDate, _Date || isDate));
            // console.log('periodInfo : ', periodInfo);
            let price;
            if (Boolean(periodInfo)) {
                const { price: periodPrice, promotionInfo: periodPromotion } = periodInfo;
                const { travelDate } = periodPromotion;
                // console.log('periodPromotion : ', periodPromotion);
                if (Boolean(travelDate)) {
                    isPeriodPromotion = InPeriodTime(travelDate.startDate, travelDate.endDate, _Date || isDate);
                }
                price = ((isPromotion && isPeriodPromotion) ?
                    (periodPromotion || InpromotionInfo) : (periodPrice || Inprice));
            }
            else {
                price = ((isPromotion) ? InpromotionInfo : Inprice);
            }
            // #### check date promotion
            const { adult: priceAdult, infant: priceInfant, kid: priceKid } = price;
            summary += ((Adult * priceAdult) + (Kid * priceKid) + (Infant * priceInfant));
            list.push(this.PackageSelectedPanel(booking, price, language));
            if (isForeign) {
                const { adult, kid, infant } = foreignPrice;
                summary += ((Adult * adult) + (Kid * kid) + (Infant * infant));
                list.push(<ForeignPanel key={'ForeignContainer'} packageInfo={booking} amoutSelect={{ Adult, Kid, Infant }}/>);
            }
            if (vnsc === undefined) {
                vnsc = packageInfo.vnsc;
                currency = price.currency;
            }
        }
        // console.log('SetPanelAll Summary: ', summary, ' this.state ', ForeignKid, ' isForeign : ', isForeign);
        list.push(<PaymentTotalPanel key={'SumamryContainer'} summary={summary} vnsc={vnsc} currency={currency}/>);
        (bookingList.length > 0) && list.push(this.CheckOutPanel(summary, isMobile));
        if (!isForeign) {
            this.state.ForeignAdult = 0;
            this.state.ForeignKid = 0;
            this.state.ForeignInfant = 0;
        }
        list.push(<FAQPanel key={'FAQContainer'}/>);
        return list;
    }
    PackageSelectedPanel(PackageInfo, isPrice, language) {
        const { packageId, packageInfo, optionLists, type } = PackageInfo;
        const { transferType } = packageInfo;
        const isOneDayTrips = (type === packageType.OneDayTrip);
        switch (type) {
            case packageType.OneDayTrip:
                {
                    return (<ContianerPanel key={packageId}>
                            <HeaderPanel>
                                <PackageNameCotianer>
                                    <PackageName>{pageLabel[wordingKeys.BookedTopics]}</PackageName>
                                </PackageNameCotianer>
                                {(isActive) && this.RemoveContainer(PackageInfo)}
                            </HeaderPanel>
                            {this.SelectedItemsContainer(isPrice, isOneDayTrips, transferType, language)}
                        </ContianerPanel>);
                }
            case packageType.Transfer:
                {
                    const { conveyanceType } = transferType;
                    switch (conveyanceType) {
                        case ConveyanceType.Ferries:
                            {
                                return (<ContianerPanel key={packageId}>
                                        <HeaderPanel>
                                            <PackageNameCotianer>
                                                <PackageName>{pageLabel[wordingKeys.BookedTopics]}</PackageName>
                                            </PackageNameCotianer>
                                            {(isActive) && this.RemoveContainer(PackageInfo)}
                                        </HeaderPanel>
                                        {this.SelectedItemsContainer(isPrice, isOneDayTrips, transferType, language)}
                                    </ContianerPanel>);
                            }
                        case ConveyanceType.Van:
                        case ConveyanceType.Hundai:
                        case ConveyanceType.Camry:
                        default:
                            {
                                return (<ContianerPanel key={packageId}>
                                        <HeaderPanel>
                                            <PackageNameCotianer>
                                                <PackageName>{pageLabel[wordingKeys.BookedTransfer]}</PackageName>
                                            </PackageNameCotianer>
                                            {(isActive) && this.RemoveContainer(PackageInfo)}
                                        </HeaderPanel>
                                        {this.SelectedTransferItemsContainer(isPrice, transferType, language)}
                                    </ContianerPanel>);
                            }
                    }
                }
        }
        // return (
        //     <ContianerPanel key={packageId} >
        //         <HeaderPanel>
        //             <PackageNameCotianer>
        //                 <PackageName>{isOneDayTrips ? `${pageLabel[wordingKeys.BookedTopics]}`
        // : pageLabel[wordingKeys.BookedTransfer]}</PackageName>
        //             </PackageNameCotianer>
        //             {(isActive) && this.RemoveContainer(PackageInfo)}
        //         </HeaderPanel>
        //         {this.SelectedItemsContainer(isPrice, optionLists, isOneDayTrips, transferType, language)}
        //     </ContianerPanel>);
    }
    SelectedItemsContainer(price, type, transferTypes, language) {
        // console.log('SelectedItemsContainer : ', packageWording, ' : ', optionLists);
        const { transferType, passengers, conveyanceType } = transferTypes;
        const isJoinType = (transferType === typeOfTransfer.Join);
        const isOneDayTrips = (type || isJoinType);
        const itemsList = packageWording;
        const isBoatRental = (conveyanceType === ConveyanceType.Ferries);
        const _passengersWording = ` ${pageLabel[(isBoatRental ? wordingKeys.LBQuantity : wordingKeys.LBPassengers)]}

        ${((passengers || 1) * ((Amount > 0) ? Amount : 1))} ${pageLabel[wordingKeys.PassengersUnit]}`;
        //  console.log('conveyanceType  : ', conveyanceType, ' : ', isJoinType, ' : ', transferTypes);
        return itemsList.map((wording, index) => {
            const { topic } = wording;
            const isDateType = (topic === FormType.Date);
            if (((topic !== FormType.Foreign) && (topic !== FormType.Passengers)) || (this.state.isForeign)) {
                const isQuantity = (isOneDayTrips) ? false : (!isDateType && (topic === FormType.Adult));
                const isDisable = (isOneDayTrips ? false : !isQuantity);
                // console.log('conveyanceType  : isBoatRental :', isBoatRental, ' : ', isJoinType, ' : ', transferTypes);
                return (<ItemsContainer key={index}>
                        <PriceTopicsContianer isDateType={isDateType} isTransfer={((!isOneDayTrips && !isQuantity))}>
                            <PriceTopicsLable isDateType={isDateType} isDisable={isDisable}>
                                {isOneDayTrips ? pageLabel[topic] : pageLabel[MapProductName(isBoatRental ? `${topic}_${conveyanceType}` : topic)]}
                            </PriceTopicsLable>
                            {(isOneDayTrips) && <PriceInfoLable>{pageLabel[`Age${topic}`]}</PriceInfoLable>}
                            {(isQuantity) && <div><PriceInfoLable isTransfer={isQuantity}>{_passengersWording}</PriceInfoLable></div>}
                        </PriceTopicsContianer>
                        <FormContianer isDateType={isDateType}>
                            {GetForm(topic, Amount, isOneDayTrips, language)}
                        </FormContianer>
                        <PriceContianer isDateType={isDateType}>
                            {(price[topic.toLocaleLowerCase()] !== undefined) &&
                    <PriceItemsLable isDisable={isDisable}>
                                    {this.GetSummaryWithType(price, topic)}
                                </PriceItemsLable>}
                        </PriceContianer>
                    </ItemsContainer>);
            }
        });
    }
    SelectedTransferItemsContainer(price, transferTypes, language) {
        // console.log('SelectedTransferItemsContainer : ', packageWording, ' : ', optionLists);
        // const isJoinType = (transferType === typeOfTransfer.Join);
        const itemsList = packageWording;
        // console.log('conveyanceType  : ', transferTypes);
        // isOneDayTrips ? packageWording : transferWording;
        return itemsList.map((wording, index) => {
            const { topic } = wording;
            switch (topic) {
                case FormType.Date:
                    {
                        return (<ItemsContainer key={index}>
                                <PriceTopicsContianer isDateType>
                                    <PriceTopicsLable isDateType>
                                        {pageLabel[MapProductName(topic)]}
                                    </PriceTopicsLable>
                                </PriceTopicsContianer>
                                <FormContianer isDateType>
                                    {GetForm(topic, Amount, false, language)}
                                </FormContianer>
                                <PriceContianer isDateType>
                                    {(price[topic.toLocaleLowerCase()] !== undefined) &&
                            <PriceItemsLable>
                                            {this.GetSummaryWithType(price, topic)}
                                        </PriceItemsLable>}
                                </PriceContianer>
                            </ItemsContainer>);
                    }
                case FormType.Adult:
                    {
                        const { passengers } = transferTypes;
                        const maxPassenger = ((passengers || 1) * ((Amount > 0) ? Amount : 1));
                        const _passengersWording = ` ${pageLabel[wordingKeys.LBQuantity]} ${maxPassenger} ${pageLabel[wordingKeys.PassengersUnit]}`;
                        return (<ItemsContainer key={index}>
                                <PriceTopicsContianer>
                                    <PriceTopicsLable>
                                        {pageLabel[MapProductName(topic)]}
                                    </PriceTopicsLable>
                                    <div><PriceInfoLable isTransfer>{_passengersWording}</PriceInfoLable></div>
                                </PriceTopicsContianer>
                                <FormContianer>
                                    {GetForm(topic, Amount, false, language, passengers)}
                                </FormContianer>
                                <PriceContianer>
                                    {(price[topic.toLocaleLowerCase()] !== undefined) &&
                            <PriceItemsLable>
                                            {this.GetSummaryWithType(price, topic)}
                                        </PriceItemsLable>}
                                </PriceContianer>
                            </ItemsContainer>);
                    }
                case FormType.Kid:
                case FormType.Infant:
                    {
                        return (<ItemsContainer key={index}>
                                <PriceTopicsContianer isTransfer>
                                    <PriceTopicsLable isDisable>
                                        {pageLabel[MapProductName(topic)]}
                                    </PriceTopicsLable>
                                    
                                </PriceTopicsContianer>
                                <FormContianer>
                                    {GetForm(topic, Amount, false, language)}
                                </FormContianer>
                                <PriceContianer>
                                    {(price[topic.toLocaleLowerCase()] !== undefined) &&
                            <PriceItemsLable isDisable>
                                            {this.GetSummaryWithType(price, topic)}
                                        </PriceItemsLable>}
                                </PriceContianer>
                            </ItemsContainer>);
                    }
                case FormType.Passengers:
                    {
                        const { passengers } = transferTypes;
                        const maxPassenger = ((passengers || 1) * ((Amount > 0) ? Amount : 1));
                        return (<ItemsContainer key={index}>
                                <PriceTopicsContianer isTransfer>
                                    <PriceTopicsLable>
                                        {pageLabel[wordingKeys.LBPassengers]}
                                    </PriceTopicsLable>
                                    
                                </PriceTopicsContianer>
                                <FormContianer>
                                    {GetForm(topic, Amount, false, language, maxPassenger)}
                                </FormContianer>
                                <PriceContianer>
                                    {(price[topic.toLocaleLowerCase()] !== undefined) &&
                            <PriceItemsLable isDisable>
                                            {this.GetSummaryWithType(price, topic)}
                                        </PriceItemsLable>}
                                </PriceContianer>
                            </ItemsContainer>);
                    }
            }
        });
    }
    GetSummaryWithType(price, topic) {
        const _amount = this.state[topic] || 0;
        const _price = price[topic.toLocaleLowerCase()] || 0;
        return `${GetSymbol(price.currency)} ${NumberFormat((_amount > 1) ? (_price * _amount) : _price)}`;
    }
    goNextStep(summary) {
        const { Adult, Kid, Infant, ForeignAdult, ForeignKid, ForeignInfant, Foreign, Passengers, Date: NewDate } = this.state;
        const checkTypeJoin = path(['SelectedLists', '0', 'packageInfo', 'transferType', 'transferType'], this.props) === 'join';
        let promotionCode;
        const affiliate = path(['location', 'hash'], this.props);
        const packageInfo = path(['SelectedLists', '0'], this.props);
        const condeInfo = path(['package', 'promotionCodes'], this.props)
            || path(['location', 'state', 'SelectedInfo', 'condeInfo'], this.props);
        const { packageId, type } = packageInfo;
        if (Boolean(condeInfo)) {
            promotionCode = condeInfo.promotionCode;
        }
        const SelectedInfo = {
            packageId,
            options: [],
            bookingInfo: { Adult, Kid, Infant, Date: NewDate, Foreign, Passengers },
            foreignInfo: { Adult: ForeignAdult, Kid: ForeignKid, Infant: ForeignInfant },
            timeSlot: '',
            agentId: '',
            sellerId: '',
            affiliateId: Boolean(affiliate) ? affiliate.slice(1) : '',
            summary,
            promotionCode: promotionCode || '',
            condeInfo,
        };
        // console.log('goNextStep : ', packageInfo);
        console.log('goNextStep : ', SelectedInfo);
        const locale = path(['location', 'state', 'locale'], this.props);
        const { name, price } = packageInfo.packageInfo;
        const newInfo = {
            packageId, type, name,
            amount: Amount, summary, currency: price.currency,
        };
        TraceFacebookPixel({ trackType: TrackType.AddToCart, data: newInfo });
        TraceFacebookPixel({ trackType: TrackType.AddPaymentInfo, data: newInfo });
        if (checkTypeJoin) {
            if (path(['checkBookingTransfer'], this.props.booking) !== undefined) {
                this.props.history.push({
                    pathname: pathName.CHOOSE_TIMESLOT,
                    state: { packageInfo: [packageInfo], SelectedInfo, summary, BookingList, locale },
                    search: packageId,
                });
            }
        }
        else {
            if (path(['checkBooking'], this.props.booking) !== undefined) {
                this.props.history.push({
                    pathname: pathName.BOOKING_PREVIEW,
                    state: { BookingList, SelectedInfo, locale },
                });
            }
        }
    }
    getBookingCheck(summary) {
        BookingList = this.props.SelectedLists;
        const { Adult, Kid } = this.state;
        const sumCount = (Adult + Kid);
        const SelectedInfo = path(['SelectedLists', '0'], this.props);
        const checkTypeJoin = path(['packageInfo', 'transferType', 'transferType'], SelectedInfo) === 'join';
        const payloads = {
            packageId: path(['0', 'packageId'], BookingList),
            date: this.state.Date,
            count: sumCount,
            isPromotion: SelectedInfo.isPromotion,
        };
        if (checkTypeJoin) {
            this.props.dispatch(BookingActions.bookingTransferCheckRequest(payloads, () => { this.goNextStep(summary); }));
        }
        else {
            this.props.dispatch(BookingActions.bookingCheckRequest(payloads, () => { this.goNextStep(summary); }));
        }
    }
    CheckOutPanel(summary, isMobile) {
        // console.log('CheckOutPanel : ', summary);
        const { disabledBooking } = this.state;
        const isDisabledButton = (disabledBooking || ((Amount < 1) || (summary < 1)));
        const { bottom_diable_checkout, bottom_checkout, bottom_chat } = style;
        const BookButtom = (<Button disabled={isDisabledButton} onClick={() => this.SetFormatData(summary)} style={(isDisabledButton) ? bottom_diable_checkout : bottom_checkout}>
                {pageLabel[wordingKeys.BookButton]}
                <Svg.Landing.Cart />
            </Button>);
        const ChatButtom = (<Button disabled={!isDisabledButton} onClick={() => console.log('ChatButtom')} style={(!isDisabledButton) ? bottom_diable_checkout : bottom_chat}>
                {pageLabel[wordingKeys.ChatWithUsButton]}
                <CartImg src={IconChat} alt='chat'/>
            </Button>);
        return (isMobile) ?
            (<React.Fragment key={'CheckOutPanel'}>
                <FixBottomContianer key={'CheckOutButtom'}>{BookButtom}</FixBottomContianer>
            </React.Fragment>) :
            (<React.Fragment key={'CheckOutPanel'}>
                <CheckOutPanel key={'CheckOutButtom'}>{BookButtom}</CheckOutPanel>
                <br />
                {(false) && <CheckOutPanel key={'ChatPanelButtom'}>{ChatButtom}</CheckOutPanel>}
            </React.Fragment>);
    }
    SetDatePicker(language) {
        // console.log('SetDatePicker : ', this.state.Date);
        // if (!Boolean(this.state.Date)) {
        //     const result = new Date();
        //     result.setDate(result.getDate() + 1);
        //     this.state.Date = result.toISOString();
        // }
        const { [reduxKeys.settings]: { currency } } = this.props;
        const props = Object.assign({ value: this.state.Date, onChange: (val) => { this.CheckDate(val); } });
        const SelectedInfo = path(['SelectedLists', '0', 'packageInfo'], this.props);
        const checkPeriodTime = path(['periodTime'], SelectedInfo);
        const CheckTransferType = path(['transferType', 'conveyanceType'], SelectedInfo);
        const checkSoldOut = CheckTransferType === ConveyanceType.Ferries ? [] : path(['sellListInfo'], SelectedInfo);
        const isPromotion = path(['SelectedLists', '0', 'isPromotion'], this.props);
        return (<div style={{ display: 'flex' }}>
                <DatePicker {...props} checkPeriodTime={checkPeriodTime} language={language} checkSoldOut={checkSoldOut} isPromotion={isPromotion} currency={currency}/>
                
            </div>);
    }
    RemoveContainer(packageInfo) {
        return (<div onClick={this.props.RemovePackage(packageInfo)} style={{ flex: '1', textAlign: 'center', margin: '5px 10px' }}>
                <Svg.xWhite />
            </div>);
    }
    CheckDate(newDate) {
        const { Date: checkDate, disabledBooking } = this.state;
        const isNewDate = Date.parse(newDate);
        const oldDate = Date.parse(checkDate);
        const isCheck = (isNewDate > Date.parse(isDate) && isNewDate !== oldDate);
        console.log('Onchange : ', isCheck, ' : ', checkDate, ' : ', disabledBooking);
        switch (true) {
            case (isCheck):
                {
                    this.setState({ disabledBooking: false, Date: newDate });
                }
                break;
            case (!disabledBooking):
                {
                    this.setState({ disabledBooking: true });
                }
                break;
        }
        // if (isCheck) {
        //     if (this.state.disabledBooking) {
        //         this.setState({ disabledBooking: false, Date: newDate });
        //     }
        //     else {
        //         this.state.Date = newDate;
        //     }
        // }
        // else if (!disabledBooking) {
        //     this.setState({ disabledBooking: true });
        // }
        console.log('Onchange : ', newDate);
    }
}
export function UpdateSummaryPanel(IsActive) {
    const { Adult, Kid, Infant, Foreign } = salf.state;
    salf.setState({ isForeign: IsActive, Foreign, ForeignAdult: Adult, ForeignKid: Kid, ForeignInfant: Infant });
}
function MapProductName(type) {
    switch (type) {
        case FormType.Adult: return FormType.Quantity;
        case FormType.Kid: return FormType.CarSeat;
        case FormType.Infant: return FormType.Guide;
        case `${FormType.Adult}_${ConveyanceType.Ferries}`: return FormType.BoatQuantity;
        case `${FormType.Kid}_${ConveyanceType.Ferries}`: return FormType.BoatSeat;
        case `${FormType.Infant}_${ConveyanceType.Ferries}`: return FormType.Guide;
        case `${FormType.Date}_${ConveyanceType.Ferries}`: return FormType.Date;
        default: return type;
    }
}
function handleChange(amount, type, addAmout, maxPassenger = 0) {
    // console.log('amount : ', amount, ' : type : ', type, ' addAmout : ', addAmout);
    switch (true) {
        case (addAmout):
            {
                if ((type === FormType.Passengers) && (salf.state[type] < maxPassenger)) {
                    salf.setState({ [type]: (salf.state[type] + 1) });
                }
                else {
                    salf.setState({ [type]: (salf.state[type] + 1) }, () => { });
                }
            }
            break;
        case (amount >= 0):
            {
                const { Adult, Kid, Infant, Passengers } = salf.state;
                switch (type) {
                    case FormType.Adult:
                        {
                            const amount = (salf.state[type] - 1);
                            const newAmount = (maxPassenger * amount);
                            const newMaxPassenger = (Passengers > newAmount) ?
                                (Passengers - (Passengers - newAmount)) : Passengers;
                            if (Adult > 0) {
                                salf.setState({ [type]: amount, [FormType.Passengers]: newMaxPassenger });
                            }
                        }
                        break;
                    case FormType.Kid:
                        {
                            if (Kid > 0) {
                                salf.setState({ [type]: (salf.state[type] - 1) });
                            }
                        }
                        break;
                    case FormType.Infant:
                        {
                            if (Infant > 0) {
                                salf.setState({ [type]: (salf.state[type] - 1) });
                            }
                        }
                        break;
                    case FormType.Passengers:
                        {
                            if (Passengers > 0) {
                                salf.setState({ [type]: (salf.state[type] - 1) });
                            }
                        }
                        break;
                }
            }
            break;
    }
    // this.setState({ [event.target.name]: event.target.value });
    // this.props.UpdateInfo.setState({ [this.props.Setting.type]: event.target.value });
}
function ChooseButtomPanel(type, amount, isDateType, maxPassenger) {
    // console.log('type : ', type);
    const _amount = salf.state[type];
    const _isStopAdd = ((type === FormType.Passengers) && (_amount === maxPassenger));
    const _isDisable = (!isDateType && (type === FormType.Kid || type === FormType.Infant));
    return (<div style={{ display: 'flex', margin: '0px 5px' }}>
            <div style={{ flex: 1 }}>
                <IconButton disabled={_isDisable} onClick={() => { handleChange(amount, type, false, maxPassenger); }}>
                    <img src={_isDisable ? subDisable : sub} alt='sub'/>
                </IconButton>
            </div>
            <div style={{ flex: 1, padding: '0px 5px', marginTop: '10px', textAlign: 'center' }}>
                <PriceLable isDisable={_isDisable}>{_amount}</PriceLable>
            </div>
            <div style={{ flex: 1 }}>
                <IconButton disabled={(_isDisable || _isStopAdd)} onClick={() => { handleChange(amount, type, true, maxPassenger); }}>
                    <img src={(_isDisable || _isStopAdd) ? addDisable : add} alt='add'/>
                </IconButton>
            </div>
        </div>);
}
export function GetForm(type, amount, isDateType, language, maxPassenger = 0) {
    // console.log('GetForm : ', type, ' : ', salf.state[type]);
    switch (type) {
        case FormType.Date:
            {
                return salf.SetDatePicker(language);
            }
        case FormType.Adult:
        case FormType.Kid:
        case FormType.Infant:
        case FormType.Quantity:
        case FormType.Passengers:
            {
                return ChooseButtomPanel(type, amount, isDateType, maxPassenger);
            }
        case FormType.Transportation:
            {
                return <SelectBox key={`price_${type}`} Setting={{ type, amout: salf.state[type] }} UpdateInfo={salf}/>;
            }
        case FormType.Foreign:
            {
                return <SelectBox key={`price_${type}`} Setting={{ type, amout: salf.state[type], total: amount }} UpdateInfo={salf}/>;
            }
        case FormType.ForeignAdult:
            {
                salf.state[type] = salf.state[FormType.Adult];
                return <SelectBox key={`price_${type}`} Setting={{ type, amout: salf.state[type], total: salf.state.Adult }} UpdateInfo={salf}/>;
            }
        case FormType.ForeignKid:
            {
                salf.state[type] = salf.state[FormType.Kid];
                return <SelectBox key={`price_${type}`} Setting={{ type, amout: salf.state[type], total: salf.state.Kid }} UpdateInfo={salf}/>;
            }
        case FormType.ForeignInfant:
            {
                salf.state[type] = salf.state[FormType.Infant];
                return <SelectBox key={`price_${type}`} Setting={{ type, amout: salf.state[type], total: salf.state.Infant }} UpdateInfo={salf}/>;
            }
    }
}
// =========================================================================================================
// export default withRouter(SummaryPanel);
const mapStateToProps = (state) => ({ ...state });
export default connect(mapStateToProps)(withRouter(SummaryPanel));
const { avenir, avenirBlack, avenirHeavy } = Fonts.family;
const _safari = isSafari();
// ===========================================================================================================
const SumamryItemsContainer = styled.div `
padding: 0px;
${(props) => props.isMobile && css `padding: 0px 10px;`}`;
const ContianerPanel = styled.div `
width: 100%;
min-height: 138px;
background-color: rgb(255, 255, 255);
box-shadow: rgba(126, 126, 126, 0.5) 0px 2px 10px 0px;
margin-bottom: 20px;`;
const HeaderPanel = styled.div `
width: 100%;
display: flex;
background-color: ${Colors.panel.header};
box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 6px 0px;
padding: 10px 0px;`;
const PackageNameCotianer = styled.div `
flex: 7 1 0%;
margin: 5px 10px;
text-align: center;`;
const PackageName = styled.label `
color: rgb(255, 255, 255);
font-family: ${avenirBlack};
font-size: 17px;
font-weight: 900;
letter-spacing: ${_safari ? -1 : 1.57}px;
line-height: 16px;
text-align: center;`;
const ItemsContainer = styled.div `
width: 100%;
height: 50px;
display: flex;
text-align: left;
border-bottom: 1px solid rgb(223, 223, 223);`;
const PriceLable = styled.label `
width: 100%;
color: #5B5B5B;
font-family: ${avenirBlack};
font-size: 24px;
font-weight: 900;
letter-spacing: 1.2px;
text-align: center;
${(props) => props.isDisable && css ` color: #dddfe2;`}`;
const PriceTopicsContianer = styled.div `
flex: 1.5 1 0%;
margin: 5px 0px 5px 10px;
${(props) => props.isTransfer && css `padding:9px 0px;`}
${(props) => props.isDateType && css `flex:2.1;padding:9px 0px;`}`;
const FormContianer = styled.div `
flex: 1.8 1 0%;
padding-top: 0px;
${(props) => props.isDateType && css ` flex: 5.1 1 0%;padding-top: 4px;`}`;
const PriceTopicsLable = styled.label `
width: 47px;
color: ${Colors.theme};
font-family: ${avenirHeavy};
font-size: 16px;
font-weight: 900;
letter-spacing: ${_safari ? -1 : 0.8}px;
${(props) => props.isDisable && css ` color: #dddfe2;`}
${(props) => props.isDateType && css ` color: #5B5B5B;`}`;
const PriceContianer = styled.div `
flex: 1.4 1 0%;
margin-right: 0px;
padding:14px 0px;
${(props) => props.isDateType && css `flex:0.3;`}`;
const PriceItemsLable = styled.label `
height: 17px;
width: 100%;
color: #4A4A4A;
font-family:${avenirBlack};
font-size: 12px;
font-weight: 900;
letter-spacing: 0.69px;
line-height: 16px;
${(props) => props.isDisable && css ` color: #dddfe2;`}`;
const CheckOutPanel = styled.div `
padding: 0px;
${(props) => props.isMobile && css `padding: 0px 10px;`}`;
const FixBottomContianer = styled.div `
position: fixed;
z-index: 99;
left: 0;
bottom: 0;
width: 100%;`;
const PriceInfoLable = styled.label `
width: 100%;
color: rgb(76, 74, 74);
font-size: 12.5px;
display: inline-grid;
font-family:${avenir};
${(props) => props.isTransfer && css `font-size:12px;`}`;
const CartImg = styled.img `margin: 0px 10px;`;
// =================================================================================================================
const style = {
    summary_container: {
        width: '100%',
        minHeight: '138px',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
        marginBottom: '20px',
    },
    label_topic: {
        width: '100%',
        color: Colors.theme,
        fontSize: '18px',
        display: 'inline-grid',
    },
    label_price: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '18px',
        lineHeight: '31px',
        display: 'inline-grid',
        marginTop: '5px',
    },
    label_age: {
        width: '100%',
        color: '#4c4a4a',
        fontSize: '12.5px',
        display: 'inline-grid',
        fontFamily: avenir,
    },
    label_packageName: {
        color: "#FFFFFF",
        fontFamily: avenir,
        fontSize: "17px",
        fontWeight: "900",
        letterSpacing: "1.57px",
        lineHeight: "16px",
        textAlign: "center",
    },
    bottom_checkout: {
        width: '100%',
        color: 'white',
        fontSize: '17px',
        fontFamily: avenirBlack,
        fontWeight: "900",
        letterSpacing: `${_safari ? -0.8 : 1.57}px`,
        backgroundColor: Colors.theme,
        boxShadow: 'rgba(126,126,126,0.5) 0px 2px 10px 0px',
        textAlign: 'center',
        minWidth: '170px',
        borderRadius: '0px',
        padding: '10px 0px',
    },
    bottom_chat: {
        width: '100%',
        color: 'white',
        fontSize: '17px',
        fontFamily: avenirBlack,
        fontWeight: "900",
        letterSpacing: `${_safari ? -0.8 : 1.57}px`,
        backgroundColor: '#3F87FF',
        boxShadow: 'rgba(126,126,126,0.5) 0px 2px 10px 0px',
        textAlign: 'center',
        minWidth: '170px',
        borderRadius: '0px',
        padding: '10px 0px',
    },
    bottom_diable_checkout: {
        width: '100%',
        color: 'white',
        fontSize: '17px',
        fontFamily: avenirBlack,
        fontWeight: "900",
        letterSpacing: `${_safari ? -0.8 : 1.57}px`,
        backgroundColor: 'rgb(223, 223, 223)',
        boxShadow: 'rgba(126,126,126,0.5) 0px 2px 10px 0px',
        textAlign: 'center',
        minWidth: '170px',
        borderRadius: '0px',
    },
};
