import { osVersion, osName, fullBrowserVersion, browserVersion, browserName, mobileVendor, mobileModel, engineName, engineVersion, getUA, } from "react-device-detect";
// import { isMobile } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
export const deviceInfo = () => {
    return {
        osVersion,
        osName,
        fullBrowserVersion,
        browserVersion,
        browserName,
        mobileVendor,
        mobileModel,
        engineName,
        engineVersion,
        getUA,
    };
};
