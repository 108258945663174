import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { compose } from "recompose";
import { pathName } from "../constant";
import { isMobile, isSafari } from '../containers/PackageInfoPanel/GetItemColsPerWidth';
import styled, { css } from 'styled-components';
import { Fonts, Images, Colors } from "../themes";
import localization from "../services/localization";
import { wordingKeys } from "../constant/enum/packageLabelKeys";
import { isPlatForm } from '../config';
import { PLATFORM_NAME } from '../config/platform';
// import { pathName, reduxKeys, packageType,PANEL_TYPE } from "../constant";
const { bookingPreview, chooseYour, paymentChannel, search, confirmPayment, wishlist, contactUs } = Images.package;
const { CHOOSE_PACKAGE, SEARCH_PRODUCT, BOOKING_PREVIEW, PAYMENT_CHANNEL, CONFIRM_PAYMMENT, SEEMORE_TRANFER, FERRIES_TRANFER, WISH_LIST, CONTACT_US } = pathName;
class SubTopicsPanel extends Component {
    render() {
        const { _pageName = '', withOutBoder } = this.props; // isLanguage
        const _isMobile = isMobile();
        const _dimension = this.getImageDimension(_pageName);
        const _isSmall = (window.innerWidth < 1020);
        const isMinSize = (window.innerWidth < 374);
        const _wording = localization(wordingKeys.templateLabel); // topics[isLanguage];
        const isPhiPhi = (isPlatForm === PLATFORM_NAME.PHIPHITOURS);
        // console.log('SubTopicsPanel : ', _pageName)
        // console.log(_wording)
        return (_isMobile) ?
            (<PanelContianerMobile withOutBoder={withOutBoder}>
                    <ImageContianerMobile isMinSize={isMinSize}>
                        <img src={this.getImageWithType(_pageName)} width={_dimension.width} height={_dimension.height} alt={_pageName}/>
                    </ImageContianerMobile>
                    <PangeNameMobile isMinSize={isMinSize}>{_wording[_pageName] || ''}</PangeNameMobile>
                    <WordingContainerMobile isMinSize={isMinSize}>
                        {_wording[wordingKeys.sentence1]}
                        <WordingSpanMobile>{_wording[wordingKeys.sentence2]}</WordingSpanMobile>
                        {_wording[wordingKeys.sentence3]}
                    </WordingContainerMobile>
                </PanelContianerMobile>) :
            (<PanelConatianer>
                    <WordingSetContainerMobile>
                        <PangeName isSmall={_isSmall}>{_wording[_pageName] || ''}</PangeName>
                        <WordingContainer isSmall={_isSmall}>
                            {_wording[wordingKeys.sentence1]}
                            <WordingSpan>{_wording[wordingKeys.sentence2]}</WordingSpan>
                            {_wording[wordingKeys.sentence3]}
                        </WordingContainer>
                    </WordingSetContainerMobile>
                    <ImageContianer>
                        <ImagePositionContianer>
                            <img src={this.getImageWithType(_pageName)} height={!!isPhiPhi ? 'auto' : 200} alt={_pageName}/>
                        </ImagePositionContianer>
                    </ImageContianer>
                    <div style={{ flex: 1.2 }}/>
                </PanelConatianer>);
    }
    getImageWithType(pageName) {
        switch (pageName) {
            case SEEMORE_TRANFER:
            case FERRIES_TRANFER:
            case SEARCH_PRODUCT: return search;
            case CHOOSE_PACKAGE: return chooseYour;
            case BOOKING_PREVIEW: return bookingPreview;
            case PAYMENT_CHANNEL: return paymentChannel;
            case CONFIRM_PAYMMENT: return confirmPayment;
            case WISH_LIST: return wishlist;
            case CONTACT_US: return contactUs;
            default: return bookingPreview;
        }
    }
    getImageDimension(pageName) {
        switch (pageName) {
            case FERRIES_TRANFER:
            case SEEMORE_TRANFER: return { width: 90, height: 68 };
            case SEARCH_PRODUCT: return { width: 90, height: 68 };
            case CHOOSE_PACKAGE: return { width: 86, height: 68 };
            case BOOKING_PREVIEW: return { width: 97, height: 68 };
            case PAYMENT_CHANNEL: return { width: 80, height: 68 };
            case CONFIRM_PAYMMENT: return { width: 48, height: 65 };
            case WISH_LIST: return { width: 79, height: 68 };
            case CONTACT_US: return { width: 90, height: 68 };
            default: return { width: 90, height: 68 };
        }
    }
}
const mapStateToProps = (state) => ({ isLanguage: state.settings.language });
export default compose(withRouter, connect(mapStateToProps))(SubTopicsPanel);
const { avenir, avenirBlack } = Fonts.family;
const _safari = isSafari();
const PanelConatianer = styled.div `
padding: 15px 15px 0px;
display: flex;
margin-top: 45px;`;
const PanelContianerMobile = styled.div `
padding: 20px 10px 0px 10px;
${(props) => props.withOutBoder && css `padding: 20px 0px 0px 0px;`}`;
const ImageContianerMobile = styled.div `
position: absolute;
margin: -8px 0px 0px 15rem;
${(props) => props.isMinSize && css `margin: -8px 0px 0px 13.5rem;`}`;
const ImageContianer = styled.div `
flex: 2 1 0%;
height: 200px;`;
const ImagePositionContianer = styled.div `
position: absolute;
margin-left: 30px;`;
const PangeName = styled.div `
height: 71px;
color: rgb(53, 53, 53);
font-family: ${avenirBlack};
font-size: 52px;
font-weight: 900;
line-height: 71px;
letter-spacing: ${_safari ? -1 : 0}px;
${(props) => props.isSmall && css `font-size: 40px;line-height: unset;`}`;
const WordingContainer = styled.div `
height: 45px;
color: ${Colors.theme};
font-family: ${avenirBlack};
font-size: 2.0625em;
font-weight: 900;
line-height: 45px;
letter-spacing: ${_safari ? -1 : 0}px;
${(props) => props.isSmall && css `font-size: 24px;line-height: unset;`}`;
const WordingSpan = styled.span `
height: 30px;
color: ${Colors.theme};
font-family: ${avenir};
font-size: 22px;
font-weight: 300;
line-height: 30px;`;
const PangeNameMobile = styled.div `
height: 25px;
color: rgb(53, 53, 53);
font-family: ${avenirBlack};
font-size: 24px;
font-weight: 900;
${(props) => props.isMinSize && css `font-size: 20px;line-height: unset;`}`;
const WordingSetContainerMobile = styled.div `
flex: 5 1 0%;
margin-top: 40px;`;
const WordingContainerMobile = styled.div `
height: 35px;
color: ${Colors.theme};
font-family: ${avenirBlack};
font-size: 14px;
font-weight: 900;
letter-spacing: -0.5px;
${(props) => props.isMinSize && css `font-size: 11px;line-height: unset;`}`;
const WordingSpanMobile = styled.span `
height: 30px;
color: ${Colors.theme};
font-family: ${avenir};
font-size: 0.8rem;
font-weight: 300;
letter-spacing: -0.5px;
line-height: 30px;`;
