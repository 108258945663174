import React from 'react';
import { path, omit } from "ramda";
import Avenir from "./Avenir";
const headerStyle = {
    textAlign: 'center', fontSize: 24, fontWeight: 900,
};
const titleStyle = {
    fontSize: 21,
};
const Content = ({ props: eachProps, text }, i) => {
    let style = path(['style'], eachProps) || {};
    if (path(['variant'], eachProps) === 'headline') {
        style = {
            ...style,
            ...headerStyle,
        };
    }
    else if (path(['variant'], eachProps) === 'title') {
        style = {
            ...style,
            ...titleStyle,
        };
    }
    return (<Avenir block key={i} style={{ marginBottom: 15, ...style }} {...omit(['style', 'variant'], eachProps)}>
            {text}
        </Avenir>);
};
export default Content;
