import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import Add from "@material-ui/icons/Add";
import { Colors } from "../../themes";
const styles = ({
    wrapper: {
        margin: '3px',
        border: `1px solid ${Colors.border}`,
        width: '100px',
        height: '100px',
        lineHeight: '130px',
        textAlign: 'center',
        backgroundColor: '#d8d8d8',
        cursor: 'pointer',
    },
});
const AddImage = ({ onClick, classes }) => {
    return (<div onClick={onClick} className={classes.wrapper}>
            <Add style={{ width: '50px', height: '50px', color: '#fff' }}/>
        </div>);
};
export default withStyles(styles)(AddImage);
