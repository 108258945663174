import * as React from "react";
import { Images, Colors } from "../../themes";
import Img from "../../components/img";
export const Tranfer = [
    {
        text: <div style={{ fontSize: 46, fontWeight: 900, color: Colors.theme, paddingTop: 63 }}>
            {`Airport Transfers`}</div>,
    },
    {
        text: <div style={{
            minWidth: 1925, cursor: 'pointer', display: 'flex',
            justifyContent: 'center', paddingTop: 50, position: 'relative',
        }}>
            <div style={{
            fontSize: 52, marginTop: 100, fontWeight: 900,
            color: '#FFFFFF', position: 'absolute', left: 150,
        }}>{'krabi'}</div>
            <Img.Sharpen src={Images.tranfer.krabi}/>
            <div style={{
            fontSize: 52, marginTop: 100, color: '#FFFFFF', fontWeight: 900,
            position: 'absolute', left: 600,
        }}>{'phuket'}</div>
            <Img.Sharpen src={Images.tranfer.phuket}/>
            <div style={{
            fontSize: 52, paddingTop: 100, color: '#FFFFFF', fontWeight: 900,
            position: 'absolute', right: 600,
        }}>{'chiangmai'}</div>
            <Img.Sharpen src={Images.tranfer.chiangmai} style={{ width: 481 }}/>
            <div style={{
            fontSize: 52, paddingTop: 100, color: '#FFFFFF', fontWeight: 900,
            position: 'absolute', right: 150,
        }}>{'pattaya'}</div>
            <Img.Sharpen src={Images.tranfer.pattaya}/>
        </div>,
    },
    {
        text: <div style={{ fontSize: 46, fontWeight: 900, color: Colors.theme, paddingTop: 63 }}>
            {`A car for every budget and occasion`}
        </div>,
    },
    {
        text: <div style={{ fontSize: 26, paddingTop: 10, color: Colors.theme }}>
            {`From low cost to luxury, we have something for any of your travel needs`}
        </div>,
    },
    {
        text: <div style={{
            cursor: 'pointer', display: 'flex',
            justifyContent: 'center', paddingTop: 50,
        }}>
            <Img.Sharpen src={Images.tranfer.car1}/>
            <Img.Sharpen src={Images.tranfer.car2} style={{ paddingRight: 30, paddingLeft: 30 }}/>
            <Img.Sharpen src={Images.tranfer.car3}/>
        </div>,
    },
];
export const car = [
    {
        text: 'Camry 2018 2-4 passengers',
    },
    {
        text: 'Toyota Van 10-15 passengers',
    },
    {
        text: 'Scania Bus  30-50 passengers',
    },
];
