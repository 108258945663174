export const pathName = {
    INDEX: `/`,
    LOGIN: `/login`,
    REGISTER: `/register`,
    PROFILE: `/profile`,
    PREVIEW: `/preview`,
    SEARCH_PRODUCT: `/search`,
    SEARCH_PRODUCTPARMS: `/search/:id`,
    SEARCH_PRODUCTWIHDPARAM: `/search/`,
    SEARCH_KOSAMUI: `/search/koSamui`,
    SEARCH_BANGKOK: `/search/bangkok`,
    SEARCH_KRABI: `/search/krabi`,
    SEARCH_CHIANGMAI: `/search/chiangMai`,
    SEARCH_PHUKET: `/search/phuket`,
    CONFIRM_PAYMMENT: `/confirmPayment`,
    PACKAGE_DETAILS: `/packgeDetails`,
    CHOOSE_PACKAGE: `/choosePackage`,
    CHOOSE_TIMESLOT: `/chooseTimeSlot`,
    BOOKING_PREVIEW: `/bookingPreview`,
    PAYMENT_DETAIL: `/paymentDetail`,
    PAYMENT_CHANNEL: `/paymentChannel`,
    BOOKING_SUCCESS: `/bookingSuccess`,
    WISH_LIST: `/wishList`,
    TERMS: `/terms`,
    COOKIE: `/cookie`,
    ABOUT_US: '/aboutUs',
    HOW_TO_BOOK: '/howToBook',
    SEEMORE_TRANFER: '/seeMoreTransfer',
    FERRIES_TRANFER: '/seeMoreFerriesTransfer',
    PRIVACY_POLICY: `/privacyPolicy`,
    CONTACT_US: `/contactUs`,
    COMPLAINT_POLICY: `/complaintPolicy`,
};
