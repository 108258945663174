import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { UserTypes } from "./UserRedux";
/* ------------- Types and Action Creators ------------- */
export const AUTHEN_KEY = 'AUTHEN';
const { Types, Creators } = createActions({
    loginRequest: ['payload', 'nextAction'],
    loginSuccess: ['payload'],
    loginFailure: null,
    logoutRequest: ['nextAction'],
    logoutSuccess: null,
    logoutFailure: null,
}, { prefix: AUTHEN_KEY + '_' });
export const AuthenTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    error: null,
    loading: false,
    token: null,
});
/* ------------- Reducers ------------- */
// request the data from an api
export const loginRequest = (state) => Object.assign({}, state, { loading: true });
export const loginSuccess = (state, { payload: { token } }) => (Object.assign({}, state, { token, loading: false }));
export const loginFailure = (state) => Object.assign({}, state, { token: null, loading: false });
export const logoutRequest = (state) => Object.assign({}, state, { loading: true });
export const logoutSuccess = (state) => Object.assign({}, state, { loading: false });
export const logoutFailure = (state) => Object.assign({}, state, { loading: false });
export const endLoading = (state) => Object.assign({}, state, { loading: false });
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.LOGIN_REQUEST]: loginRequest,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: loginFailure,
    [Types.LOGOUT_REQUEST]: logoutRequest,
    [Types.LOGOUT_SUCCESS]: logoutSuccess,
    [Types.LOGOUT_FAILURE]: logoutFailure,
    [UserTypes.SET_SOCIAL_CACHE]: endLoading,
});
