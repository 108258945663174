import React, { Component, Fragment } from 'react';
import { DatePicker as MDatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/styles';
import { equals } from "ramda";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";
import { format, reduxKeys } from "../../constant";
import { Colors, Metrics } from '../../themes';
const styles = (theme) => ({
    root: {
        width: '100%',
    },
    textFieldRoot: {
        "padding": 0,
        'label + &': { marginTop: theme.spacing.unit * 2.2 },
    },
    textFieldInput: {
        borderRadius: Metrics.modal.borderRadius,
        border: (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
        background: theme.palette.common.white,
        width: '100%',
        fontSize: 16,
        padding: '10px 12px',
        cursor: 'pointer',
    },
});
class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: props.value || new Date(),
        };
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.selectedDate)) {
            this.setState({
                selectedDate: nextProps.value || new Date(),
            });
        }
    }
    render() {
        const { label, error, errorId, classes, onChange, inputProps, getRef, require, settings: { language }, } = this.props;
        moment.locale(language);
        return (<Fragment>
                <FieldLabel label={label} error={error} requir={require}/>
                <MDatePicker ref={(node) => {
            if (typeof getRef === 'function') {
                getRef(node);
            }
        }} className={classes.root} InputProps={{
            disableUnderline: true,
            classes: {
                root: classes.textFieldRoot,
                input: classes.textFieldInput,
            },
        }} 
        // emptyLabel={null}
        // openToYearSelection={false}
        value={this.state.selectedDate} onChange={(val) => {
            this.setState({
                // selectedDate: val,
                selectedDate: val.toISOString(),
            }, () => {
                // onChange(val.valueOf());
                onChange(val.toISOString());
                if (typeof this.props.validate === 'function') {
                    this.props.validate(val.toISOString());
                }
            });
        }} format={format.DATE} {...inputProps}/>
                <FieldError error={error} errorId={errorId}/>
            </Fragment>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.settings]: state[reduxKeys.settings],
});
export default compose(withStyles(styles), connect(mapStateToProps))(DatePicker);
