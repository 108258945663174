export default {
    money: {
        numericality: {
            greaterThan: 0,
            notGreaterThan: '^error_money_notGreaterThan',
            notValid: '^error_money_notValid',
        },
    },
    creditCardNumber: {
        presence: {
            allowEmpty: false,
            message: '^error_require_credit_card_number',
        },
        format: {
            /*
                35 JCB
                4 Visa
                5[1-5] MasterCard, Mastercard
            */
            pattern: /^(35|4|5[1-5]).*$/,
            message: '^error_wrong_credit_card_number',
        },
    },
    creditCardExpire: {
        presence: {
            allowEmpty: false,
            message: '^error_require_credit_card_expire',
        },
        format: {
            pattern: /^\d{2}\/\d{2}/,
            message: '^error_wrong_credit_card_expire',
        },
    },
};
