import ReactDOM from "react-dom";
import { path } from "ramda";
// scroll after close dialog need some delay
export const scrollToRef = (ref, delay = false, extra = 0) => {
    const scrollToY = path(['offsetTop'], ReactDOM.findDOMNode(ref)) || 0;
    if (delay) {
        setTimeout(() => {
            window.scrollTo(0, scrollToY + extra);
        }, 200);
    }
    else {
        window.scrollTo(0, scrollToY + extra);
    }
};
