import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import styled, { css } from 'styled-components';
import { Fonts, Svg, Colors } from "../../themes";
import { isSafari } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
import localization from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
let pageLabel;
class TourProgramPanel extends Component {
    constructor() {
        super(...arguments);
        this.state = { ScheduleActive: true };
    }
    render() {
        pageLabel = localization(wordingKeys.packageLabel);
        const { ScheduleInfo, DesFull, Highlight, expanded, handleChange, isTransfer, isRental, isMobile, classes } = this.props;
        return (<TourProgramContianer>
                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <ExpansionPanelSummary classes={{ expanded: classes.expanded }} expandIcon={<ExpandMoreIcon style={style.ExpandMoreIconStyle}/>} style={style.ExpansionPanelSummaryStyle}>
                        <TopicsIconContianer>
                            <Svg.Landing.Destination color={Colors.icon}/>
                        </TopicsIconContianer>
                        <CallpseTopicLabel onChange={handleChange('panel1')}>
                            {pageLabel[wordingKeys.TourProgramTopics]}</CallpseTopicLabel>
                        <CallpseIconContianer isMobile={isMobile}/>
                    </ExpansionPanelSummary>
                    {(!!Highlight) &&
            <ExpansionPanelDetails style={style.ExpansionPanelPadding}>
                            <PanelDetailsContianer>
                                <HighlightContianer>
                                    <HighlightLabel>{pageLabel[wordingKeys.Highlight]}</HighlightLabel>
                                </HighlightContianer>
                                {Highlight.map((highlight, index) => (<HeightItemsContianer key={`highlight_${index}`}>
                                        <ServiceLabel isMobile={isMobile}>{highlight}</ServiceLabel>
                                    </HeightItemsContianer>))}
                            </PanelDetailsContianer>
                        </ExpansionPanelDetails>}
                    <ExpansionPanelDetails style={style.ExpansionPanelPadding}>
                        <PanelDetailsContianer>
                            {DesFull.map((description, index) => (<div key={`DesFull_${index}`}>
                                    <ServiceLabel isMobile={isMobile}>{description}</ServiceLabel>
                                </div>))}
                        </PanelDetailsContianer>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails style={style.ExpansionPanelPadding}>
                        {(!isTransfer || isRental) &&
            <ScheduleSegment>
                                <ItemContianer>
                                    {ScheduleInfo.map((schedule, index) => this.ScheduleItem(schedule, index, isMobile))}
                                </ItemContianer>
                            </ScheduleSegment>}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </TourProgramContianer>);
    }
    ScheduleItem(schedule, id, isMobile) {
        const { time, topic, detail = [] } = schedule;
        const _isTopic = Boolean(topic);
        const _isDetail = (detail.length > 0);
        // console.log('schedule : ', schedule, ' : ', (false && false));
        if ((_isTopic === false) && (_isDetail === false))
            return null;
        return (<ScheduleItemContianer key={id}>
                {Boolean(time) && (<div><ScheduleItemsTimeLabel isMobile={isMobile}>{time}</ScheduleItemsTimeLabel></div>)}
                {(_isTopic) && (<div><ScheduleItemsTopicLabel isMobile={isMobile}>{topic}</ScheduleItemsTopicLabel></div>)}
                {(_isDetail) && (<div>
                        {detail.map((details, index) => (Boolean(details) &&
            <ScheduleItemsDetialLabel key={`detail_${index}`} isMobile={isMobile}>
                                    {details}
                                </ScheduleItemsDetialLabel>))}
                    </div>)}
            </ScheduleItemContianer>);
    }
}
const styles = () => ({ expanded: { '&:first-child': { minHeight: 20 } } });
export default withStyles(styles)(withRouter(TourProgramPanel));
const { avenir } = Fonts.family;
const ItemContianer = styled.div `
                width: 100%;
                display: inline-block;
                margin-top: 0px;`;
const TourProgramContianer = styled.div `
                margin-top:10px`;
const ScheduleItemContianer = styled.div `
                width: 100%;
                max-width: 954px;
                height: 100%;
                padding: 10px 0px 10px 5px;
                text-align: left;
                margin-top: 0px;
                border-bottom: 1px solid rgb(223, 223, 223)`; // schedule_container
const ScheduleSegment = styled.div `
                padding: 0px 0px 30px 5px;
                width: 100%;
                height: 100%;
                text-align: left;`;
const CallpseTopicLabel = styled.label `
                width: 100%;
                color: ${Colors.textLabel};
                font-family: ${avenir};
                font-size: 17px;
                font-weight: 900;
                cursor: pointer;
                letter-spacing: ${isSafari() ? -1 : 0}px;
                line-height: 23px;`;
const TopicsIconContianer = styled.div `
                margin: 2px 10px 0px 0px;`;
const ScheduleItemsTimeLabel = styled.label `
                width: 100%;
                max-width: 954px;
                color: rgb(74,74,74);
                display: inline-grid;
                margin-bottom: 0px;
                font-family: ${avenir};
                font-size: 17px;
                font-weight: 900;
                line-height: 23px;
${(props) => props.isMobile && css `font-size:16px;`}`; // schedule_time
const ScheduleItemsTopicLabel = styled.label `
                width: 100%;
                color: rgb(74,74,74);
                display: inline-grid;
                margin-bottom: 0px;
                font-family: ${avenir};
                font-size: 17px;
                font-weight: 900;
                line-height: 23px;
${(props) => props.isMobile && css `font-size:16px;`}`; // schedule_topic
const ScheduleItemsDetialLabel = styled.label `
                height: 33px;
                width: 100%;
                color: #5B5B5B;
                font-family:  ${avenir};
                font-size: 15px;
                font-weight: 500;
                line-height: 16px;
${(props) => props.isMobile && css `font-size:14px;`}`; // schedule_detial
const PanelDetailsContianer = styled.div `
                display: grid;`;
const HighlightContianer = styled.div `
                margin-bottom: 10px;`;
const HighlightLabel = styled.label `
                color: rgb(74, 74, 74);
                font-family: ${avenir};
                font-size: 17px;
                font-weight: 900;
                line-height: 23px;
${(props) => props.isMobile && css `font-size:16px;`}`;
const ServiceLabel = styled.label `
                height: 33px;
                width: 100%;
                color: #5B5B5B;
                font-family: ${avenir};
                font-size: 15px;
                font-weight: 500;
                line-height: 16px;
${(props) => props.isMobile && css `font-size:14px;`}`;
const HeightItemsContianer = styled.div `
                padding-left: 20px;
                margin: 0px;`;
const CallpseIconContianer = styled.div `
                background: ${Colors.textLabel};
                width: 65px;
                height: 49px;
                position: absolute;
                right: 0;
                margin-top: -12px;
                box-shadow:0px 1px 3px 0px rgba(0, 0, 0, 0.2);`;
const style = {
    ExpansionPanelSummaryStyle: {
        backgroundColor: 'white', cursor: 'pointer',
        height: 48, borderBottom: 'solid 0.3px #353535bd', boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    },
    ExpandMoreIconStyle: { color: 'white' },
    ExpansionPanelPadding: { padding: '8px 24px 5px' },
    root: { height: 48 },
};
// MuiExpansionPanelSummary-expanded-94
