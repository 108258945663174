import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import { omit, path } from "ramda";
import { compose } from "recompose";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import CloseIcon from '@material-ui/icons/Close';
import CustomDialog from './CustomDialog';
import { Metrics, Colors } from "../../themes";
// const getWidth = (props) => props.listenWidth && isWidthUp('md', props.width) ?
//     Metrics.modal.authenDialogWidth : path(['style', 'width'], props) || "";
const styles = {
    paper: {
        height: (props) => !!props.stylePaper ? path(['stylePaper', 'height'], props) : 'unset',
        maxWidth: (props) => !!props.isCalendar === true ? '100%' : props.listenWidth && isWidthUp('md', props.width) ?
            Metrics.modal.authenDialogWidth : path(['style', 'width'], props) || "",
        overflow: "auto",
        width: (props) => !!props.isCalendar === true ? 'unset' : props.listenWidth && isWidthUp('md', props.width) ?
            Metrics.modal.authenDialogWidth : path(['style', 'width'], props) || "",
        // borderRadius: 0,
        backgroundColor: (props) => path(['stylePaper', 'backgroundColor'], props),
        boxShadow: (props) => path(['stylePaper', 'boxShadow'], props),
        minHeight: (props) => path(['stylePaper', 'minHeight'], props),
        minWidth: (props) => path(['stylePaper', 'minWidth'], props),
        borderRadius: (props) => path(['stylePaper', 'borderRadius'], props),
        margin: (props) => !!props.styleMargin && path(['styleMargin', 'margin'], props),
    },
    root: {
        zIndex: -1,
        top: 0,
        minHeight: 800,
        position: 'fixed',
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0.5)',
    },
};
const ModalWithStyles = compose(withWidth(), withStyles(styles))((props) => {
    const isMobile = isWidthDown('sm', props.width);
    return (<Dialog fullScreen={props.fullScreen} open={props.open} onClose={props.onClose} classes={{
        paper: path(['classes', 'paper'], props),
    }} style={{
        ...props.style,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        top: 0,
    }} BackdropProps={{
        classes: {
            root: path(['classes', 'root'], props),
        },
    }} disableBackdropClick={props.disableBackdropClick || false} disableEscapeKeyDown={props.disableEscapeKeyDown || false}>
            {props.closeMark ? props.renderCloseMark() : null}
            {!props.withOutFullDialog && isMobile && (<AppBar position="static" style={{ backgroundColor: Colors.theme, ...props.styleMobile }}>
                        <Toolbar style={{ justifyContent: !!props.styleMobile ? 'space-between' : 'flex-end' }}>
                            {!!props.styleMobile ?
        <span style={{ color: '#fff', fontSize: 18 }}>
                                    {props.labelMobile}</span>
        : null}
                            <IconButton color="inherit" onClick={props.onClose} aria-label="Close" style={{ justifyContent: 'flex-end' }}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>)}
            <div style={{
        // height: isMobile ?
        //     `calc(100% - ${Metrics.header.mobileHeight(props.width)}px)`
        //     :
        //     '100%',
        // width: '100%',
        display: 'flex',
        ...props.style,
    }}>
                {props.children}
            </div>
        </Dialog>);
});
const WithFullWidth = withMobileDialog()(ModalWithStyles);
class Modal extends CustomDialog {
    render() {
        const { open } = this.state;
        const { withOutFullDialog } = this.props;
        if (withOutFullDialog) {
            return (<ModalWithStyles open={open} onClose={this.onClose} renderCloseMark={this.renderCloseMark.bind(this)} {...omit(['open'], this.props)}/>);
        }
        return <WithFullWidth open={open} onClose={this.onClose} renderCloseMark={this.renderCloseMark.bind(this)} {...omit(['open'], this.props)}/>;
    }
}
export default Modal;
