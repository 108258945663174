import { path } from 'ramda';
import user from './user';
import payment from './payment';
import additional from './additional';
import * as validate from "validate.js";
const validateList = {
    user,
    payment,
    additional,
};
// const regisActions = (actionsList) => {
//     let result = {};
//     if (Object.keys(actionsList).length > 0) {
//         Object.keys(actionsList).map((key) => {
//             // return Object.keys(actionsList[key]).map((each) => {
//             Object.keys(actionsList[key]).map((each) => {
//                 // return `${key}_${each}`;
//                 result = {
//                     ...result,
//                     [`${key}_${each}`]: `${key}_${each}`,
//                 };
//                 // return `${key}_${each}`;
//             });
//         });
//     }
//     return result;
// };
// export const validateActions = regisActions({
//     user,
// });
export const validateActions = {
    user_email: 'user_email',
    user_password: 'user_password',
    user_confirmPassword: 'user_confirmPassword',
    user_firstname: 'user_firstname',
    user_lastname: 'user_lastname',
    user_tel: 'user_tel',
    user_countryCode: 'user_countryCode',
    user_gender: 'user_gender',
    user_nationality: 'user_nationality',
    user_nationalityId: 'user_nationalityId',
    user_date: 'user_date',
    user_socialId: 'user_socialId',
    payment_money: 'payment_money',
    payment_creditCardNumber: 'payment_creditCardNumber',
    payment_creditCardExpire: 'payment_creditCardExpire',
    additional_pickupNote: 'additional_pickupNote',
    additional_dropoffNote: 'additional_dropoffNote',
    additional_arrivalAirline: 'additional_arrivalAirline',
    additional_arrivalFlightNo: 'additional_arrivalFlightNo',
    additional_roomNo: 'additional_roomNo',
};
export default (value, action, transform) => {
    if (!path([action], validateActions)) {
        throw new Error('err, not found action');
    }
    const actionPart = action.split("_");
    const constraint = path(actionPart, validateList);
    // confirm password case
    if (typeof value !== 'string') {
        return path([actionPart[1], 0], validate(value, { [actionPart[1]]: constraint }));
    }
    // normal case
    value = typeof transform === 'function' ? transform(value) : value;
    return path([actionPart[1], 0], validate({
        [actionPart[1]]: value,
    }, { [actionPart[1]]: constraint }));
};
