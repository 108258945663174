import * as React from 'react';
import { withRouter } from 'react-router';
class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            setTimeout(() => { window.scrollTo(0, 0); }, 0);
            // google analytics
            window.ga('set', 'page', this.props.location.pathname);
            window.ga('send', 'pageview');
        }
    }
    render() {
        return this.props.children;
    }
}
export default withRouter(ScrollToTop);
