import apisauce from 'apisauce';
import { path } from "ramda";
import config from '../config';
import Store from "../redux";
import { apiResponse } from "../constant";
const withToken = (headers = {}) => {
    const token = path(['authen', 'token'], Store.getState());
    if (!!token) {
        return {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }
    else {
        return { ...headers };
    }
};
const mockApi = (success = true, result = {}) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                data: {
                    success,
                    result,
                },
            });
        }, 500);
    });
};
const create = (baseURL = `${config.api.host}`) => {
    const api = apisauce.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        },
        timeout: 60000,
    });
    // auth api
    const login = (payload) => {
        if (config.mockApiResponse) {
            return mockApi(true, { token: 'test' });
        }
        return api.post(`${config.api.login}`, payload, { headers: withToken() });
    };
    const logout = () => {
        if (config.mockApiResponse) {
            return mockApi(true, { token: 'test' });
        }
        return api.post(`${config.api.logout}`, {}, { headers: withToken() });
    };
    // user api
    const getUser = () => {
        if (config.mockApiResponse) {
            return mockApi(true, apiResponse.userData);
        }
        return api.get(`${config.api.getUser}`, {}, { headers: withToken() });
    };
    const register = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.register}`, payload);
    };
    const forgotPassword = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.forgotPassword}`, payload);
    };
    const updateUser = (payload) => {
        if (config.mockApiResponse) {
            return mockApi(true, apiResponse.updateUser);
        }
        return api.post(`${config.api.updateUser}`, payload, { headers: withToken() });
    };
    const changePassword = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.changePassword}`, payload, { headers: withToken() });
    };
    // landing api
    const getActivities = (payload) => {
        if (config.mockApiResponse) {
            return mockApi(true, apiResponse.activityList);
        }
        return api.get(`${config.api.getActivities}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getAppointments = (payload) => {
        if (config.mockApiResponse) {
            return mockApi(true, apiResponse.activityList);
        }
        return api.get(`${config.api.getAppointments}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    // booking
    const booking = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.booking}`, payload, { headers: withToken() });
    };
    const bookingGuest = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.bookingGuest}`, payload);
    };
    const confirmPayment = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.confirmPayment}`, payload, { headers: withToken() });
    };
    const confirmPaymentWithOutToken = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.confirmPaymentWithOutToken}`, payload);
    };
    const bookingList = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.bookingList}`, payload, { headers: withToken() });
    };
    const bookingInfo = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.bookingInfo}`, payload);
    };
    const bookingConfirm = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.bookingConfirm}`, payload);
    };
    const getCustomerRequest = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getCustomerRequest}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const bookingCheck = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.bookingCheck}`, payload);
    };
    const bookingTransferCheck = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.bookingTransferCheck}`, payload);
    };
    const refund = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.refund}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const refundBooking = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.refundBooking}`, payload, { headers: withToken() });
    };
    // other api
    const uploads = (payload, onUploadProgress) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.deleteHeader('Content-Type').post(`${config.api.uploads}`, payload, {
            headers: withToken(),
            onUploadProgress,
            timeout: 1000 * 60,
        });
    };
    const uploadsWithOutToken = (payload, onUploadProgress) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.deleteHeader('Content-Type').post(`${config.api.uploadsWithOutToken}`, payload, {
            onUploadProgress,
            timeout: 1000 * 60,
        });
    };
    const getErrorCode = () => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getErrorCode}`);
    };
    const getBanks = () => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getBanks}`);
    };
    const getPaymentTimes = () => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getPaymentTimes}`, {}, {
            baseURL: config.api.loopbackHost,
        });
    };
    const createAffiliateId = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.createAffiliateId}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getAffiliate = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getAffiliates}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getLocationServices = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getLocationServices}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getDestinations = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getDestinations}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getCurrency = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getCurrency}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getAnnouncements = () => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.announcements}`, {}, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getAffiliateStatistics = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.affiliateStat}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    // =================================================================================================================
    const getPackageList = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.packageList}`, payload);
    };
    const createReview = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.createReview}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getReview = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.packageReview}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getPromotionCodes = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getPromotionCodes}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const usePromotionCodes = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.usePromotionCodes}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const getWishList = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getWishList}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const postWishList = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.postWishList}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const linkStat = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.linkStat}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const requestReviews = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.requestReviews}`, payload, {
            baseURL: config.api.loopbackHost,
        });
    };
    const requestReviewUpdate = (payload, bookingId) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.post(`${config.api.requestReviewUpdate}`, payload, {
            baseURL: config.api.loopbackHost,
            params: { where: { bookingId } },
        });
    };
    const getPackageCategory = (payload) => {
        if (config.mockApiResponse) {
            return mockApi();
        }
        return api.get(`${config.api.getPackageCategory}`, payload);
    };
    // =================================================================================================================
    return {
        login,
        logout,
        getUser,
        register,
        forgotPassword,
        updateUser,
        bookingList,
        changePassword,
        getWishList,
        postWishList,
        getActivities,
        getAppointments,
        booking,
        bookingGuest,
        bookingInfo,
        bookingConfirm,
        confirmPayment,
        confirmPaymentWithOutToken,
        getCustomerRequest,
        bookingCheck,
        bookingTransferCheck,
        refund,
        refundBooking,
        uploads,
        uploadsWithOutToken,
        getErrorCode,
        getBanks,
        getPaymentTimes,
        createAffiliateId,
        getAffiliate,
        getLocationServices,
        getDestinations,
        getCurrency,
        getAnnouncements,
        getAffiliateStatistics,
        getPackageList,
        createReview,
        getReview,
        getPromotionCodes,
        usePromotionCodes,
        linkStat,
        requestReviews,
        requestReviewUpdate,
        getPackageCategory,
    };
};
export default {
    create,
};
