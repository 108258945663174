import React, { Component } from 'react';
import { connect } from "react-redux";
import Text from "../text";
import GrayBox from "../GrayBox";
import Link from "../Link";
import { Form } from '../form';
import formtypes, { fieldsName } from '../../formtypes';
import { CheckBoxWithStyle } from "../form/CheckBox";
import { Styles, Images, Colors } from "../../themes";
import { reduxKeys } from "../../constant";
import localization from "../../services/localization";
import { path, toString } from "ramda";
const Conditions = (props) => (<div style={{
    fontSize: 16,
    marginTop: props.isMobile ? 0 : 35,
}}>
        <Text.Avenir family="heavy">
            {localization(`conditions`, `BookingLabel`)}
        </Text.Avenir>
        <ul style={{
    marginTop: 6,
    marginBottom: 0,
    paddingLeft: 20,
    color: Colors.grayScale["9e"],
}}>
            <li>{localization(`when_paying`, `BookingLabel`)}</li>
            <li>{localization(`if_confirmation`, `BookingLabel`)}</li>
            <li>{localization(`if_you`, `BookingLabel`)}</li>
            
        </ul>
    </div>);
class CreditCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            useUserData: true,
        };
    }
    render() {
        const { isMobile, formRef, [reduxKeys.authen]: { token }, user } = this.props;
        const defaultValues = {};
        if (this.state.useUserData) {
            Object.keys(fieldsName.profileFieldsName).forEach((each) => {
                defaultValues[each] = path(['data', each], user);
            });
        }
        const creditCard = defaultValues[fieldsName.profileFieldsName.creditCard];
        const stringCreditCard = toString(creditCard);
        return (<div style={{
            color: Colors.grayScale["71"],
            ...Styles.unselected,
        }}>
                
                <Text.Avenir size={16} family="heavy" block>
                    {localization(`full_payment`, `BookingLabel`)}
                </Text.Avenir>
                <Text.Avenir size={16} style={{ marginTop: isMobile ? 3 : 10 }} block>
                    {localization(`our_reservation`, `BookingLabel`)}
                </Text.Avenir>
                {token && (<CheckBoxWithStyle label={localization(`use_my_account`, `BookingLabel`)} rootStyle={{
            marginLeft: '-14px',
            marginBottom: '-14px',
        }} value={this.state.useUserData} onChange={(val) => {
            this.setState({ useUserData: val });
        }}/>)}
                <Form form={formtypes.creditcard({ isMobile, defaultValues, stringCreditCard })} ref={(ref) => {
            if (typeof formRef === 'function') {
                formRef(ref);
            }
        }} style={{
            marginTop: isMobile ? 20 : 40,
        }}/>

                <Conditions isMobile={isMobile}/>
                <GrayBox>
                    <Text.Avenir size={16} color={Colors.grayScale["71"]} family="heavy">
                        {`${localization(`you_can_pay`, `BookingLabel`)}: `}
                    </Text.Avenir>
                    <img alt='jcb' src={Images.booking.credit.jcb} style={{ marginLeft: isMobile ? 0 : 20 }}/>
                    <img alt='mastercard' src={Images.booking.credit.mastercard} style={{ marginLeft: isMobile ? 5 : 20 }}/>
                    <img alt='visa' src={Images.booking.credit.visa} style={{ marginLeft: isMobile ? 5 : 20 }}/>
                </GrayBox>

                <GrayBox column>
                    <Text.Avenir size={16} color={Colors.grayScale["71"]} family="heavy">
                        {localization(`data_security`, `BookingLabel`)}
                    </Text.Avenir>
                    <div style={{
            fontSize: '16px',
            color: '#9b9b9b',
            marginTop: 8,
        }}>
                        {localization(`your_info`, `BookingLabel`)}
                        <span style={{ color: Colors.grayScale["82"] }}>
                            {localization(`encrypted`, `BookingLabel`)}
                        </span>
                        {localization(`with_protocal`, `BookingLabel`)}
                    </div>
                    <Text.Avenir size={16} color={Colors.grayScale["9b"]} family="heavy">
                        {localization(`platforms`, `BookingLabel`)}
                        <span style={{ color: Colors.grayScale["82"] }}>
                            {localization(`respect`, `BookingLabel`)}
                        </span>
                        {localization(`we_do_not`, `BookingLabel`)}
                    </Text.Avenir>
                    <Link.TermAndPrivacy />
                </GrayBox>

                
            </div>);
    }
}
const mapStateToProps = (state) => ({
    isMobile: state[reduxKeys.screen].isMobile,
    [reduxKeys.settings]: state[reduxKeys.settings],
    [reduxKeys.authen]: state[reduxKeys.authen],
    [reduxKeys.user]: state[reduxKeys.user],
});
export default connect(mapStateToProps)(CreditCard);
