import { path } from "ramda";
import api from "../services/apiSauce";
import errorGenerator from "../services/errorGenerator";
import Dialog from "../components/dialog";
const API = api.create();
const uploadKey = 'upload';
export default (uploadList) => {
    return new Promise((resolve) => {
        Dialog.loading({
            key: uploadKey,
            text: `Uploading...(${'0'}%)`,
            variant: 'buffer',
            value: 0,
            valueBuffer: 0,
        });
        const payload = new FormData();
        uploadList.forEach((each) => {
            payload.append(each.key, each.file);
        });
        (async () => {
            try {
                const res = await API.uploads(payload, (progress) => {
                    const value = parseInt(((progress.loaded / progress.total) * 100) + '', 10);
                    Dialog.loading().update({
                        key: uploadKey,
                        text: `Uploading...(${value}%)`,
                        variant: 'buffer',
                        value,
                        valueBuffer: value,
                    });
                });
                Dialog.loading().remove(uploadKey);
                const result = path(['data', 'result', 'upload'], res);
                resolve(result);
            }
            catch (error) {
                Dialog.loading().remove(uploadKey);
                Dialog.error({
                    key: uploadKey,
                    ...errorGenerator(error),
                });
            }
        })().catch(e => console.log("Caught: " + e));
    });
};
// export default (uploadList) => {
//     return new Promise((resolve, reject) => {
//         let result: any;
//         let finished = 0;
//         const total = uploadList.length;
// Store.dispatch(LoadingActions.addLoading({
//     loadingDialog: {
//         key: uploadKey,
//         text: `Uploading...(${finished}/${total})`,
//         mode: 'buffer',
//         value: finished / total * 100,
//         valueBuffer: (finished + 1) / total * 100,
//     },
// }));
//         const recursiveUpload = async (reUploadList) => {
//             if (reUploadList.length > 0) {
//                 const payload = new FormData();
//                 payload.append(reUploadList[0].key, reUploadList[0].file);
//                 const res = await API.uploads(payload);
//                 let uploadPath = path(['data', 'result', 'upload', 'avatar', 'original'], res);
//                 uploadPath = !!uploadPath ? `${config.host}${uploadPath}` : uploadPath;
//                 result = append(uploadPath, result);
//                 finished += 1;
//                 Store.dispatch(LoadingActions.updateLoading({
//                     loadingDialog: {
//                         key: uploadKey,
//                         text: `Uploading...(${finished}/${total})`,
//                         mode: 'buffer',
//                         value: finished / total * 100,
//                         valueBuffer: (finished + 1) / total * 100,
//                     },
//                 }));
//                 recursiveUpload(remove(0, 1, reUploadList));
//             } else {
//                 Store.dispatch(LoadingActions.removeLoading({
//                     loadingDialog: {
//                         key: uploadKey,
//                     },
//                 }));
//                 resolve(result);
//             }
//         };
//         recursiveUpload(uploadList);
//         // uploadList.forEach(async (each) => {
//         //     console.warn(each.key);
//         //     const payload = new FormData();
//         //     payload.append(each.key, each.file);
//         //     const res = await API.uploads(payload);
//         //     console.warn(res);
//         // });
//         const interval = setInterval(() => {
//             if (finished >= total) {
//                 clearInterval(interval);
//                 return;
//             }
//             finished += 1;
//             Store.dispatch(LoadingActions.updateLoading({
//                 loadingDialog: {
//                     key: 'upload',
//                     text: `Uploading...(${finished}/${total})`,
//                     mode: 'buffer',
//                     value: finished / total * 100,
//                     valueBuffer: (finished + 1) / total * 100,
//                 },
//             }));
//         }, 500);
//     });
// };
