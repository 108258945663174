import * as React from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import th from 'react-intl/locale-data/th';
import zh from 'react-intl/locale-data/zh';
import { connect } from 'react-redux';
import { Redirect, Route, Router } from "react-router-dom";
import { compose } from "recompose";
import { omit } from "ramda";
import withWidth from '@material-ui/core/withWidth';
import LoadingWrapper from "../containers/LoadingWrapper";
import InfoWrapper from "../containers/InfoWrapper";
import ErrorWrapper from "../containers/ErrorWrapper";
import ScrollToTop from "../containers/ScrollToTop";
import AuthenDialogWrapper from "../containers/AuthenDialogWrapper";
import Fallback from '../components/Fallback';
import ScreenActions from "../redux/ScreenRedux";
import PackageActions from "../redux/PackageRedux";
import { history } from '../services';
import localeData from '../assets/lang';
import { pathName, reduxKeys } from "../constant";
import ChoosePackage from "./ChoosePackage";
import PaymentDetail from "./PaymentDetail";
import BookingSuccess from './BookingSuccess';
// import { ROLE } from '../constant';
const Landing = React.lazy(() => import("./Landing"));
const Terms = React.lazy(() => import("./Terms"));
const Cookie = React.lazy(() => import("./Cookie"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy"));
const ComplaintPolicy = React.lazy(() => import("./ComplaintPolicy"));
const SearchAllProduct = React.lazy(() => import("./SearchAllProduct"));
const PackageDetails = React.lazy(() => import("../containers/PackageDetails/PackageDetails"));
// const ChoosePackage = React.lazy(() => import("./ChoosePackage/ChoosePackage"));
const ChooseTimeSlot = React.lazy(() => import("../containers/ChooseTimeSlot/ChooseTimeSlot"));
const PaymentChannel = React.lazy(() => import("./PaymentChannel"));
// const BookingSuccess = React.lazy(() => import("./BookingSuccess"));
const WishList = React.lazy(() => import("./WishList"));
const AboutUs = React.lazy(() => import("./AboutUs"));
const HowToBook = React.lazy(() => import("./HowToBook"));
const seeMoreTranfer = React.lazy(() => import("./seeMoreTranfer"));
const ContactUs = React.lazy(() => import("./ContactUs"));
const BookingPreview = React.lazy(() => import("./BookingPreview"));
// const PaymentDetail = React.lazy(() => import("./ChoosePackage/PaymentDetail"));
const Profile = React.lazy(() => import("./Profile"));
const ConfirmPayment = React.lazy(() => import("./ConfirmPayment"));
const Preview = React.lazy(() => import("../containers/DestinationPreview/Preview"));
addLocaleData([...en, ...th, ...zh]);
let initial = true;
class AppRouter extends React.Component {
    constructor(props) {
        super(props);
        const { [reduxKeys.screen]: { width }, dispatch, } = this.props;
        if (width !== this.props.width) {
            dispatch(ScreenActions.setWidth(width));
        }
    }
    componentWillReceiveProps(nextProps) {
        const { [reduxKeys.settings]: { language, currency } } = this.props;
        if (nextProps.width !== this.props.width) {
            this.props.dispatch(ScreenActions.setWidth(nextProps.width));
        }
        if (initial) {
            initial = false;
            this.props.dispatch(PackageActions.packageListRequest({ language, currencyTo: currency }));
        }
    }
    render() {
        const { [reduxKeys.settings]: { language }, [reduxKeys.authen]: { token } } = this.props;
        // const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
        const messages = localeData[language] || localeData.EN;
        return (<IntlProvider locale={language} messages={messages}>
                <Router history={history.browserHistory}>
                    <ScrollToTop>
                        <WaitingRoute exact path={pathName.INDEX} component={Landing}/>
                        <WaitingRoute path={pathName.LOGIN} component={Landing}/>
                        <WaitingRoute path={pathName.REGISTER} component={Landing}/>
                        <WaitingRoute path={pathName.TERMS} component={Terms}/>
                        <WaitingRoute path={pathName.COOKIE} component={Cookie}/>
                        <WaitingRoute path={pathName.PRIVACY_POLICY} component={PrivacyPolicy}/>
                        <WaitingRoute path={pathName.COMPLAINT_POLICY} component={ComplaintPolicy}/>
                        
                        <WaitingRoute path={pathName.PREVIEW} component={Preview}/>
                        <WaitingRoute path={pathName.SEARCH_PRODUCT} component={SearchAllProduct}/>
                        
                        
                        
                        <WaitingRoute path={pathName.PACKAGE_DETAILS} component={PackageDetails}/>
                        <Route path={pathName.CHOOSE_PACKAGE} component={ChoosePackage}/>
                        <WaitingRoute path={pathName.CHOOSE_TIMESLOT} component={ChooseTimeSlot}/>
                        <WaitingRoute path={pathName.PAYMENT_CHANNEL} component={PaymentChannel}/>
                        <Route path={pathName.BOOKING_SUCCESS} component={BookingSuccess}/>
                        
                        <WaitingRoute path={pathName.WISH_LIST} component={WishList}/>
                        <WaitingRoute path={pathName.ABOUT_US} component={AboutUs}/>
                        <WaitingRoute path={pathName.HOW_TO_BOOK} component={HowToBook}/>
                        <WaitingRoute path={pathName.SEEMORE_TRANFER} component={seeMoreTranfer}/>
                        <WaitingRoute path={pathName.CONTACT_US} component={ContactUs}/>
                        <WaitingRoute path={pathName.BOOKING_PREVIEW} component={BookingPreview}/>
                        <Route path={pathName.PAYMENT_DETAIL} component={PaymentDetail}/>
                        <InfoWrapper />
                        <ErrorWrapper />
                        <LoadingWrapper />
                        <AuthenDialogWrapper />

                        
                        <AuthenRoute path={pathName.CONFIRM_PAYMMENT} isLoggedIn={!token || !!token} render={() => (
        // !error ? (
        <React.Suspense fallback={<Fallback />}>
                                    <ConfirmPayment />
                                </React.Suspense>
        // )
        // :
        // (
        //     <Redirect to={pathName.PROFILE} />
        // )
        )}/>
                        <AuthenRoute path={pathName.PROFILE} isLoggedIn={!!token} render={() => (<React.Suspense fallback={<Fallback />}>
                                    <Profile />
                                </React.Suspense>)}/>
                        

                        
                        <Route render={({ location }) => {
            if (Object.keys(pathName).find((key) => pathName[key] === location.pathname)) {
                return null;
            }
            return <Redirect to={pathName.INDEX}/>;
        }}/>
                    </ScrollToTop>
                </Router>
            </IntlProvider>);
    }
}
const AuthenRoute = (authProps) => {
    const { component: Component, render, isLoggedIn } = authProps;
    const rest = omit(['component', 'render', 'isLoggedIn'], authProps);
    // if (rest.path === window.location.pathname) {
    //     if (window.location.pathname !== pathName.HOME && window.location.pathname !== pathName.INDEX) {
    //         return <Redirect to={pathName.HOME} />;
    //     }
    // }
    return (<Route {...rest} render={(props) => (isLoggedIn ? (!!render ? render() : <Component {...props}/>) : (<Redirect to={pathName.INDEX}/>))}/>);
};
const WaitingRoute = ({ component, ...rest }) => (<Route {...rest} component={WaitingComponent(component)}/>);
const WaitingComponent = (Component) => {
    return (props) => (<React.Suspense fallback={<Fallback />}>
            <Component {...props}/>
        </React.Suspense>);
};
// const AuthenRoute = ({ component: Component, render, isLoggedIn, privilege, role_name, ...rest }) => {
//     if (rest.path === window.location.pathname && !!privilege && privilege.indexOf(role_name) === -1) {
//         if (window.location.pathname !== pathName.HOME && window.location.pathname !== pathName.INDEX) {
//             return <Redirect to={pathName.HOME} />;
//         }
//     }
//     return (
//         <Route {...rest} render={(props) => (
//             isLoggedIn ? (
//                 !!render ? render() : <Component {...props} />
//             ) : (
//                     <Redirect to={pathName.INDEX} />
//                 )
//         )} />
//     );
// };
const mapStateToProps = (state) => {
    return {
        [reduxKeys.authen]: state[reduxKeys.authen],
        [reduxKeys.settings]: state[reduxKeys.settings],
        [reduxKeys.screen]: state[reduxKeys.screen],
        [reduxKeys.booking]: state[reduxKeys.booking],
        [reduxKeys.package]: state[reduxKeys.package],
    };
};
export default compose(withWidth(), connect(mapStateToProps))(AppRouter);
