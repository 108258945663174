import React, { Component, Fragment } from 'react';
import Rating from 'react-rating';
import { withRouter } from 'react-router';
import LazyLoad from 'react-lazyload';
import config from '../../config';
import WishListIconPanel from './WishListIconPanel';
import { isMobile, isSafari } from './GetItemColsPerWidth';
import { SetFakeReviewWithPackageId } from './PackageFakeBooked';
import { GetSymbol, Cuurency } from '../../components/common/GetSymbol';
import { ConveyanceType } from '../../containers/Search/FilterTypeInfo';
import { NumberFormat, SortLargeNumber } from '../../components/common/SetNumberFormat';
import TraceFacebookPixel, { TrackType } from '../../components/TraceInfo/FacebookPixel';
import { pathName, packageType, promotionType, language as constLaguage } from "../../constant";
// import { Images, Metrics, Colors } from '../../themes';
import './Card.css';
import styled, { css } from 'styled-components';
import { Images, Svg, Fonts, Colors } from '../../themes';
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
import localization, { isLanguage } from "../../services/localization";
let packageLabel;
const { isPhiPhi, host } = config;
const ActiveBags = false;
const hostName = host;
const { duration, passengers, bags, sale } = Images.package;
// const { ratingActive, ratingDefault, duration, bestSeller, passengers, bags, sale } = Images.package;
class NewPackagesCard extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
        this.onChoosePackage = this.onChoosePackage.bind(this);
    }
    componentDidMount() {
        document.documentElement.style.setProperty('--main-color', Colors.theme);
    }
    render() {
        // console.log('NewPackagesCard : ', this.props);
        packageLabel = localization(wordingKeys.packageLabel);
        const { packageInfo, cardSize } = this.props;
        const { packageId, type } = packageInfo;
        const { name } = packageInfo.packageInfo;
        const newSetInfo = { packageId, type, name };
        return (<LazyLoad height={100} offset={[-100, 100]} key={packageId}>
                <CradContainer isMobile={isMobile()} isSmall={cardSize}>
                    {this.imagePanel(packageInfo, newSetInfo)}
                    {this.packageNamePanel(name)}
                    {this.infoPanel(packageInfo)}
                    {this.ratingPanel(packageInfo)}
                </CradContainer>
            </LazyLoad>);
    }
    onChoosePackage() {
        const { packageInfo, history, isRecomand } = this.props;
        const { packageId, type } = packageInfo;
        const { name } = packageInfo.packageInfo;
        const _newSetInfo = { packageId, type, name };
        const locale = isLanguage();
        const hash = history.location.hash || '';
        console.log('onChoosePackage : ', hash);
        TraceFacebookPixel({ trackType: TrackType.ViewContent, data: _newSetInfo });
        history.push({ pathname: pathName.CHOOSE_PACKAGE, state: { packageInfo: [packageInfo], locale }, search: `${packageId}&language=${isLanguage()}`, hash });
        (isRecomand) && window.scrollTo(0, 0);
    }
    imagePanel(_package, _newSetInfo) {
        const { image, promotionInfo, price, name } = _package.packageInfo;
        const { isPromotion } = _package;
        const { loading } = this.state;
        return (<ImageProductContianer className="item">
                <ImageContianer>
                    <Fragment>
                        <LazyLoad height={200} once>
                            <ImagePackage alt={name || ''} src={`${hostName}${image.thumbnail}`} onLoad={() => (loading) && this.setState({ loading: !loading })} onClick={this.onChoosePackage}/>
                        </LazyLoad>
                        {(loading) && <LoadingContianer className={"imageLoding"}/>}
                    </Fragment>
                    {(isPromotion && Boolean(promotionInfo)) && this.promotionContainer(promotionInfo, price)}
                    <WishListIconPanel packageInfo={_newSetInfo}/>
                </ImageContianer>
            </ImageProductContianer>);
    }
    packageNamePanel(name) {
        return (<PackageNameContianer onClick={this.onChoosePackage}>
                <PackageName>{name}</PackageName>
            </PackageNameContianer>);
    }
    infoPanel(_package) {
        const { packageInfo, isPromotion, type } = _package;
        const { price, promotionInfo } = packageInfo;
        const isPrice = (isPromotion) ? (promotionInfo || price) : price;
        const { adult, currency } = isPrice;
        const isTranfers = (type === packageType.Transfer);
        const isCuurencyTH = (currency === Cuurency.THB);
        return (<Fragment>
                <PackageDetialContianer isTranfers={isTranfers} onClick={this.onChoosePackage}>
                    {(isTranfers) ? this.TransferInfoPanel(_package) : this.OneDayTripsInfoPanel(_package)}
                    {(isPromotion) ?
            <PromotionPriceContiner>
                            <RealPriceLabel> {`${GetSymbol(currency)} ${NumberFormat(isCuurencyTH ? parseInt(adult) : adult)}`}</RealPriceLabel>
                            {this.promotionInfo(price, promotionInfo)}
                        </PromotionPriceContiner> :
            <RealPriceContianer isTranfers={isTranfers}>
                            <RealPriceLabel> {`${GetSymbol(currency)} ${NumberFormat(adult)}`}</RealPriceLabel>
                        </RealPriceContianer>}
                </PackageDetialContianer>
            </Fragment>);
    }
    OneDayTripsInfoPanel(_package) {
        const { scheduleTime } = _package.packageInfo;
        return (<div>
                <DetialItemsContianer>
                    <img src={duration} className="icon" alt='duration' style={_margin}/>
                    <DetialLabel>{this.getDurationTime(scheduleTime)}</DetialLabel>
                </DetialItemsContianer>
            </div>);
    }
    TransferInfoPanel(_package) {
        const { scheduleTime, transferType, language } = _package.packageInfo;
        const { bags: infoBags, conveyanceType: infoConveyance, passengers: infoPassengers } = transferType;
        const _isNotFerres = (infoConveyance !== ConveyanceType.Ferries);
        const _isTHLanguage = (language === constLaguage.TH);
        const _passengersWording = (_isTHLanguage) ?
            ` ${packageLabel[wordingKeys.Passengers]} ${infoPassengers || 0} ${packageLabel[wordingKeys.PassengersUnit]}`
            : `${infoPassengers || 0} ${packageLabel[wordingKeys.Passengers]}`;
        return (<div>
                <DetialItemsContianer isTranfers={_isNotFerres}>
                    <img src={duration} className="icon" alt='duration' style={_margin}/>
                    <DetialLabel>{this.getDurationTime(scheduleTime)}</DetialLabel>
                </DetialItemsContianer>
                {(_isNotFerres) &&
            <Fragment>
                        <DetialItemsContianer isTranfers>
                            <img src={passengers} className="icon" alt='passengers' style={{ marginRight: 4 }}/>
                            <DetialLabel>{_passengersWording}</DetialLabel>
                        </DetialItemsContianer>
                        {(ActiveBags) &&
                <DetialItemsContianer isTranfers>
                                <img src={bags} className="icon" alt='bags' style={_margin}/>
                                <DetialLabel>
                                    {`${infoBags || 0} ${packageLabel[wordingKeys.StandardBags]}`}</DetialLabel>
                            </DetialItemsContianer>}
                    </Fragment>}
            </div>);
    }
    ratingPanel(_package) {
        const { rating, packageInfo, packageId, isBestSeller } = _package;
        const { booked, name } = packageInfo;
        const fakeReviews = SetFakeReviewWithPackageId(packageId, rating.reviews);
        return (<RatingContianer onClick={this.onChoosePackage}>
                <div key={name}>
                    {(isBestSeller) && <Svg.Landing.Bestseller style={bestSellerIcon} isSafari={_isSafari}/>}
                </div>
                <RatingContianerRight>
                    <Rating emptySymbol={<Svg.Landing.Star style={ratingIcon} color={'#9d9d9d'}/>} fullSymbol={<Svg.Landing.Star style={ratingIcon}/>} initialRating={rating.averagePoint} readonly fractions={2}/>
                    <div style={{ marginTop: '-7px' }}>
                        <RatingLable>
                            {`${fakeReviews || 0} ${packageLabel[wordingKeys.Reviews]} | ${SortLargeNumber(booked.booked, 2, packageId)} ${packageLabel[wordingKeys.Booked]}`}
                        </RatingLable>
                    </div>
                </RatingContianerRight>
            </RatingContianer>);
    }
    getDurationTime(scheduleTime) {
        const { start, unit } = scheduleTime;
        // Boolean(end) ? `${packageLabel[wordingKeys.Duration]} ${start} - ${end}`
        // : `${packageLabel[wordingKeys.Duration]} ${start || '-'} ${packageLabel[unit] || ''}`;
        return `${packageLabel[wordingKeys.Duration]} ${start || '-'} ${packageLabel[unit] || ''}`;
    }
    promotionInfo(_price, _promotionInfo) {
        const { type, adult, currency } = _promotionInfo;
        const _discount = (type === promotionType.Percent) ?
            ` -${_promotionInfo['percent-adult']} %` : `-${NumberFormat(_price.adult - adult)}`;
        return (<PromotionInfoCotianer>
                <PriceCossLabel>
                    <CossPrice>{`${GetSymbol(currency)} ${NumberFormat(_price.adult)} `}</CossPrice>
                </PriceCossLabel>
                <PriceLabel>
                    {_discount}
                </PriceLabel>
            </PromotionInfoCotianer>);
    }
    promotionContainer(_promotion, _price) {
        const { type, adult, currency } = _promotion;
        const _isPercent = (type === promotionType.Percent);
        const _discount = (_isPercent) ?
            ` ${Number.parseInt(_promotion['percent-adult'])}%`
            : ((currency === Cuurency.THB) ? `${(_price.adult - adult).toFixed(0)} ${GetSymbol(currency)}`
                : `${NumberFormat(_price.adult - adult)} ${GetSymbol(currency)}`);
        const _is2Digit = (_isPercent && (_promotion['percent-adult'] > 9));
        return (isPhiPhi) ?
            (<Fragment>
                <BubbleContianer isPhiPhi>
                    <BubbleFlex>
                        <BubbleLeft />
                        <BubbleRight>
                            <Svg.packageCard.Sale />
                            <PromotionContianer className="promotinPrice" is2Digit={_is2Digit} isPhiPhi>
                                <DiscountLabel isPercent={_isPercent}>{_discount}</DiscountLabel>
                                <PromotionOFF>{`OFF`}</PromotionOFF>
                            </PromotionContianer>
                        </BubbleRight>
                    </BubbleFlex>
                </BubbleContianer>
            </Fragment>) :
            (<Fragment>
                <BubbleContianer>
                    <BubbleFlex>
                        <BubbleLeft />
                        <BubbleRight>
                            <ImgBubble src={sale} alt='sale'/>
                            <PromotionContianer className="promotinPrice" is2Digit={_is2Digit}>
                                <DiscountLabel isPercent={_isPercent}>{_discount}</DiscountLabel>
                            </PromotionContianer>
                        </BubbleRight>
                    </BubbleFlex>
                </BubbleContianer>
            </Fragment>);
    }
}
export default withRouter(NewPackagesCard);
const ratingIcon = { marginLeft: '2px' };
const { avenir, avenirHeavy, avenirBlack } = Fonts.family;
const _isSafari = isSafari();
const _margin = { marginRight: '0.3125rem' };
const bestSellerIcon = {
    width: '98.94px',
    height: '26px',
    marginLeft: '13px',
    cursor: 'pointer',
    fontFamily: avenir,
};
const CradContainer = styled.div `
height: 399px;
width: 269px;
min-width: 260px;
background-color: rgb(255,255,255);
box-shadow: rgba(0,0,0,0.18) 3px 2px 10px 0px;
margin: 15px 11.5px;
cursor: pointer;
${(props) => props.isSmall && css `width: 260px`}
${(props) => props.isMobile && css `margin: 10px 11.5px;`}`;
const BubbleContianer = styled.div `
width: 100%;
text-align: right;
position: absolute;
top: 1rem;
padding-right: 13px;
padding-left: 13px;
${(props) => props.isPhiPhi && css `top:0px;padding-right:0px;`}`;
// const ImgBestSeller = styled.img`
// width: 98.94px;
// height: 26px;
// margin-left: 13px;
// cursor: pointer;
// `;
// const ImgRating = styled.img`
// margin-left: 2px;`;
const ImgBubble = styled.img `
width: 66.79px;
height: 74.75px;`;
const BubbleFlex = styled.div `
display: flex;
width: 100%;`;
const BubbleLeft = styled.div `
flex: 1 1 0%;`;
const BubbleRight = styled.div `
flex: 0.4 1 0%;`;
const ImageProductContianer = styled.div `
height: 262px;`;
const ImageContianer = styled.div `
height:262px;
&:hover .promotinPrice {-moz-transform: scale(1.12);-webkit-transform: scale(1.12);transform: scale(1.12);`;
const PromotionContianer = styled.div `
margin-top: -65px;
margin-left: 1px;
width: 100%;
height: 50px;
text-align: center;
position: sticky;
${(props) => props.isPhiPhi && css `margin-top:-75px; margin-left:15px; height:50px;`}
${(props) => props.is2Digit && css ``}`;
const PackageNameContianer = styled.div `
display: flex;
padding: 0px 13px;
height: 36px;
margin: 13px 0px 0px 0px;`;
const PackageName = styled.p `
margin: 0px;
height: 36px;
width: 100%;
color: rgb(53,53,53);
font-family: ${avenirHeavy};
font-size: 1.12rem;
font-weight: bold;
line-height: 20px;
letter-spacing: ${_isSafari ? -1 : 0}px;
cursor: pointer;`; // line-height: 18px;
const PackageDetialContianer = styled.div `
display: flex;
justify-content: space-between;
padding: 0px 13px;
height: 50px;
margin: 0px;
${(props) => props.isTranfers && css `height: 50px;margin:0px`}`;
const DetialItemsContianer = styled.div `
margin-top:10px;
min-width:100px;
${(props) => props.isTranfers && css `height: 17px;margin-top:2px`}`; // height: 12px
const DetialLabel = styled.label `
height: 16px;
width: 100%;
color: #9B9B9B;
font-family: ${avenir};
font-size: 10px;
font-weight: bold;
letter-spacing: ${_isSafari ? -1 : -0.08}px;`;
const DiscountLabel = styled.label `
font-size: 1.063rem;
font-weight: 900;
height: 28px;
width: 126px;
color: rgb(255, 255, 255);
font-family: ${avenir};
line-height: 28px;
${(props) => props.isPercent && css `font-family: ${avenirBlack};font-size: 1.5rem;`}`;
const PromotionPriceContiner = styled.div `
text-align: right;
margin-top: 4px;`;
const RealPriceContianer = styled.div `
flex: 1 1 0%;
text-align: right;
margin-top: 10px;
${(props) => props.isTranfers && css `margin-top: 20px;`}`;
const RealPriceLabel = styled.label `
width: 100%;
color: #B70302;
font-family: ${avenirHeavy};
font-size: 18px;
letter-spacing: 0.4px;
text-align: right;}`;
const PriceLabel = styled.label `
width: 100%;
color: rgb(53, 53, 53);
font-family:${avenir};
font-size: 11px;`;
const PriceCossLabel = styled.label `;
width: 100%;
color: #9B9B9B;
font-family: ${avenirHeavy};
font-size: 13px;`;
const CossPrice = styled.del `
color: rgb(155, 155, 155);
padding: 0px 3px;`;
const RatingContianer = styled.div `
display: flex;
justify-content: space-between;
margin: -2px 0px 0px 0px;`;
const RatingContianerRight = styled.div `
text-align: right;
padding: 0px 13px 0px 0px;
margin-top: -6px;
`;
const RatingLable = styled.label `
height: 14px;
width: 100%;
color: #88848B;
font-family: ${avenir};
font-size: 10px;
font-weight: 900;
letter-spacing: -0.2px;
line-height: 14px;
text-align: center;`;
const ImagePackage = styled.img `
width: 100%;
height: 262px;
background-color: rgba(155, 155, 155, 0.35);`;
const LoadingContianer = styled.div `
left: 35%;
bottom: 35%;
position: absolute;`;
const PromotionInfoCotianer = styled.div `
margin-top: -3px;`;
const PromotionOFF = styled.div `
font-size: 12px;
font-weight: 900;
color: rgb(255,255,255);
font-family: ${avenir};
padding-left: 23px;`;
// ============================================================================================================
// const _style = {
//     card_container: { height: 420, width: '16.88rem', minWidth: '16.4rem', backgroundColor: '#FFFFFF',
//  boxShadow: '3px 2px 10px 0 rgba(0,0,0,0.18)', margin: '20px 10px', cursor: 'pointer' },
//     label_topic: { height: '50px', width: '332px', color: '#353535', fontFamily: 'Arial',
//  fontSize: '1em', fontWeight: 'bold', lineHeight: '25px', cursor: 'pointer' },
//     label_rating: { "height": "19px", "width": "96.24px", "color": "#88848B", "fontFamily": "Avenir",
//  "fontSize": "0.875em", "fontWeight": "900", "letterSpacing": "-0.11px", "lineHeight": "19px" },
//     label_price: { "height": "17px", "width": "83.76px", "color": "#353535",
//  "fontFamily": "Arial", "fontSize": "0.8125em", "lineHeight": "15px" },
//     label_bestSeller: { "height": "18px", "width": "99.17px", "color": "#FFFFFF", "fontFamily": "Roboto",
//  "fontSize": "15px", "fontWeight": "bold", "letterSpacing": "-0.12px", "lineHeight": "18px", cursor: 'pointer' },
//     bestSeller_container: { "height": "34.11px", "width": "137.91px", "borderRadius": "2px",
//  "backgroundColor": "#7EC440", "textAlign": "center", 'padding': '0.375em',
//  'marginLeft': '0.9375em', cursor: 'pointer' },
//     card_detail: { display: 'flex', padding: '5px 13px', height: '3.75em', margin: '5px 0px' },
//     promotion_lable: { height: '28px', width: '126px', color: '#FFFFFF',
//  fontFamily: 'Arial', fontSize: '1.25em', lineHeight: '28px', },
// };
// ===============================================================================================================
