import React, { Component } from 'react';
import { withRouter } from 'react-router';
// import { Images } from '../../themes';
// import { pathName } from '../../constant';
// import Button from 'material-ui/Button';
// import DatePicker from '../../components/form/DatePicker';
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { FormType } from './SummaryPanel';
const _amoutList = Array.apply(null, { length: 21 }).map(Number.call, Number);
const _TransportationList = ['Car', 'Bus'];
class SelectBox extends Component {
    constructor() {
        super(...arguments);
        this.state = this.props.Setting;
        this.handleChange = (event) => {
            console.log('target name :', event.target.name, ' : ', event.target.value);
            this.setState({ [event.target.name]: event.target.value });
            this.props.UpdateInfo.setState({ [this.props.Setting.type]: event.target.value });
        };
    }
    render() {
        let _selected;
        const { Setting, disabled } = this.props;
        // console.log('SelectedBox : ', Setting);
        switch (Setting.type) {
            case FormType.Foreign:
            case FormType.ForeignAdult:
            case FormType.ForeignKid:
            case FormType.ForeignInfant:
                this.state.amout = Setting.total;
                _selected = Array.apply(null, { length: (Setting.total + 1) }).map(Number.call, Number);
                break;
            default:
                _selected = _amoutList;
                break;
        }
        // console.log('SelectedBox : ', _selected.length);
        // this.state = this.props.Setting;
        return (<FormControl style={{ width: 70, marginRight: '10px' }}>
                <Select disabled={disabled} //(_selected.length < 2)
         value={this.state.amout} onChange={this.handleChange} input={<Input name='amout' id='amout-simple'/>}>
                    {(Setting.type === FormType.Transportation) ?
            _TransportationList.map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>) :
            _selected.map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                </Select>
            </FormControl>);
    }
}
// const mapStateToProps = (state) => {
//     return { ...state, };
// };
export default withRouter(SelectBox);
