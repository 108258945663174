// ===================================================================================================================================
// Main Config App.
// ===================================================================================================================================
import debugSetting from "./debugSetting";
import { PLATFORM_NAME, getPlatFormPath } from "./platform";
// import reduxPersist from "./reduxPersist";
// import * as platformConfig from "./platform/TravisGo";
export const isPlatForm = PLATFORM_NAME.TRAVISGO;
export const isPhiPhi = isPlatForm === `${PLATFORM_NAME.PHIPHITOURS}`;
console.log(`=== Main Config App [ ${isPlatForm} ] ===`);
const isPath = getPlatFormPath(isPlatForm);
const platformConfig = require(`${isPath}`);
// ===================================================================================================================================
const { buildVer, isDev, isDevelop, isProduction, isFirebase, host, loopbackHost, api, mockApiResponse, directionURL, businessURL, dbdCertificationURL, social, trace, omiseKey, defaultSetting, license, is3DSecureActive, partner, footer } = platformConfig;
// ===================================================================================================================================
switch (true) {
    case isProduction:
        {
            console.warn(` === Build ${isPlatForm} Ver.(${buildVer}) === `);
        }
        break;
    case isDevelop:
        {
            console.warn(` === Build ${isPlatForm} Branch [ ${process.env.NODE_ENV} ] Ver.(${buildVer}) === `);
        }
        break;
}
// ===================================================================================================================================
// console.log(`=== Main Config App ===`, api);
export default {
    isPlatForm,
    isPhiPhi,
    api,
    default: defaultSetting,
    host,
    loopbackHost,
    directionURL,
    dbdCertificationURL,
    businessURL,
    social,
    mockApiResponse,
    omiseKey,
    debugSetting,
    // reduxPersist,
    footer,
    isDev,
    isDevelop,
    isFirebase,
    isProduction,
    trace,
    license,
    is3DSecureActive,
    partner,
};
// ==================================================================================================================================
