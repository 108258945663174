import { FakeBookedInfoList } from '../../constant/mockupData/FakeBookedInfoList';
export function SetFakeReviewWithPackageId(packageId, reviews = 0) {
    const itemInfo = FakeBookedInfoList.find((item) => item.packageId === packageId);
    if (Boolean(itemInfo)) {
        return (reviews + (itemInfo.point || 0));
    }
    else {
        return reviews;
    }
}
