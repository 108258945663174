export default {
    FAQTopics: 'Frequently Asked Questions',
    FAQ00001: {
        questions: `Can i make changes to my booking after i book ?`,
        ask: `Phi Phi Tour does not charge a fee to amend or change the dates of an existing booking,
        as long as the request is received by our Customer Care team,
        not less than 24 hours ahead of your confirmed travel date.`,
    },
    FAQ00002: {
        questions: `Is my payment secure ?`,
        ask: `Yes, our online payment system is secure! In fact,
        it encrypts your payment information to protect you against fraud and
        unauthorized transactions by Phi Phi Tour user credit card transactions.
        To maintain your your security, do not send payments directly to the private
        tour guide outside of our secure site.`,
    },
};
