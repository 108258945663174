// import { path } from "ramda";
import { mask } from "../constant";
import { validateActions } from '../utils/validator';
import transform from '../utils/transform';
import { inputType } from "../constant";
// import { fieldsName as regiterFieldsName } from "./register";
import localization from "../services/localization";
import { Images } from "../themes";
import { fieldsName as profileFieldsName } from "./profile";
export const fieldsName = {
    name: 'name',
    number: 'number',
    expire: 'expire',
    security_code: 'security_code',
};
export default ({ isMobile, defaultValues, stringCreditCard }) => ({
    [fieldsName.name]: {
        label: localization(`name_on_card`, `BookingLabel`),
        placeholder: 'Name',
        require: true,
        type: inputType.TEXT_INPUT,
        validate: (val) => {
            if (val.trim() === '') {
                return 'error_require_credit_card_name';
            }
        },
        md: isMobile ? 12 : 3,
        style: {
            md: {
                // paddingRight: '20px',
                paddingRight: '10px',
            },
        },
        value: defaultValues[profileFieldsName.nameOnCard],
    },
    [fieldsName.number]: {
        label: localization(`credit_card_number`, `BookingLabel`),
        placeholder: 'XXXX-XXXX-XXXX-XXXX',
        require: true,
        type: inputType.TEXT_INPUT,
        validate: validateActions.payment_creditCardNumber,
        value: stringCreditCard,
        transform: transform.creditCardNumber,
        mask: mask.creditCardNumber,
        md: isMobile ? 12 : 3,
        style: {
            default: {
                paddingRight: '10px',
            },
            md: {
                paddingLeft: '10px',
                paddingRight: '10px',
            },
        },
    },
    [fieldsName.expire]: {
        label: localization(`expire`, `BookingLabel`),
        placeholder: 'MM/YY',
        require: true,
        type: inputType.TEXT_INPUT,
        validate: validateActions.payment_creditCardExpire,
        value: defaultValues[profileFieldsName.expireCard],
        mask: mask.creditCardExpire,
        md: isMobile ? 12 : 3,
        style: {
            default: {
                paddingRight: '10px',
            },
            md: {
                paddingLeft: '10px',
                paddingRight: '10px',
            },
        },
    },
    [fieldsName.security_code]: {
        label: localization(`security_code`, `BookingLabel`),
        require: true,
        placeholder: 'CVV',
        inputProps: {
            type: 'password',
            maxLength: 3,
        },
        type: inputType.TEXT_INPUT,
        validate: (val) => {
            if (val.trim() === '') {
                return 'error_require_credit_card_code';
            }
        },
        icon: Images.booking.cvv,
        // value: path([fieldsName.email], defaultValues),
        md: isMobile ? 12 : 3,
        style: {
            default: {
            // paddingLeft: '10px',
            },
            md: {
                paddingLeft: '10px',
            },
        },
    },
});
