import React from 'react';
import { connect } from "react-redux";
import { AUTHEN_DIALOG_MODE } from "../../containers/AuthenDialogWrapper";
import AuthenActions from "../../redux/AuthenRedux";
import TraceFacebookPixel, { TrackType } from '../TraceInfo/FacebookPixel';
import Space from '../Space';
import Button from '../button';
import HeaderText from "./HeaderText";
import Tab from "./Tab";
import Divider from "./Divider";
import SocialButtons from "./SocialButtons";
import Text from "../text";
import Dialog from "../dialog";
import { Form } from '../form';
import formtypes from '../../formtypes';
import { Colors } from '../../themes';
import localization from "../../services/localization";
import { reduxKeys } from "../../constant";
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.ref = {};
        props.hocRef(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    isChanged() {
        return this.ref.isChanged();
    }
    onSubmit() {
        const { success, data } = this.ref.submit();
        if (success) {
            this.props.dispatch(AuthenActions.loginRequest(data, this.props.handleLoginSuccess), localStorage.setItem('status', JSON.stringify(true)), localStorage.setItem('loginFace', "false"), localStorage.setItem('loginGoogle', "false"));
            TraceFacebookPixel({ trackType: TrackType.Lead });
        }
    }
    render() {
        const { isMobile } = this.props;
        const isMobileMini = window.innerWidth < 390;
        const localStyle = {
            fontSize: isMobileMini ? 16 : 22,
            buttonSize: isMobileMini ? 100 : 140,
        };
        return (<div style={{
            height: '100%',
            paddingRight: isMobile ? 15 : 25,
        }}>
                <HeaderText style={{
            display: 'flex', justifyContent: 'space-between',
            fontSize: isMobileMini ? 20 : 29,
        }}>
                    {localization(`member_login`, `AuthenLabel`)}
                    <Dialog.CloseMark onClick={this.props.onHide}/>
                </HeaderText>
                <div style={{ marginTop: isMobileMini ? 10 : 30 }}>
                    <Tab activeTab={AUTHEN_DIALOG_MODE.LOGIN} onClickRegister={() => { this.props.changeMode(AUTHEN_DIALOG_MODE.REGISTER); }}/>
                </div>
                <Space height={isMobileMini ? 10 : 20}/>

                <SocialButtons handleLoginSuccess={this.props.handleLoginSuccess}/>

                <Space height={isMobileMini ? 15 : 20}/>
                <Divider style={{ opacity: 0.4 }}/>
                <Space height={isMobileMini ? 10 : 20}/>

                <Form form={formtypes.login({ onPressEnter: this.onSubmit, isMobile })} ref={(ref) => this.ref = ref}/>
                
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text.Avenir pointer onClick={() => { this.props.changeMode(AUTHEN_DIALOG_MODE.FORGOT_PASSWORD); }} style={{ color: Colors.theme, fontSize: localStyle.fontSize, letterSpacing: '-0.5px' }}>
                        {localization(`forgot_password`, `AuthenLabel`)}
                    </Text.Avenir>
                    <Button.Theme label={localization(`login`, `AuthenLabel`)} width={localStyle.buttonSize} onClick={this.onSubmit} letterSpacing={-1}/>
                </div>
            </div>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.user]: state[reduxKeys.user],
    [reduxKeys.settings]: state[reduxKeys.settings],
    isMobile: state[reduxKeys.screen].isMobile,
});
export default connect(mapStateToProps)(Login);
// export default Login;
