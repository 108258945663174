export const nationalities = [
    { label: "Afghan" },
    { label: "Albanian" },
    { label: "Algerian" },
    { label: "American" },
    { label: "Andorran" },
    { label: "Angolan" },
    { label: "Antiguans" },
    { label: "Argentinean" },
    { label: "Armenian" },
    { label: "Australian" },
    { label: "Austrian" },
    { label: "Azerbaijani" },
    { label: "Bahamian" },
    { label: "Bahraini" },
    { label: "Bangladeshi" },
    { label: "Barbadian" },
    { label: "Barbudans" },
    { label: "Batswana" },
    { label: "Belarusian" },
    { label: "Belgian" },
    { label: "Belizean" },
    { label: "Beninese" },
    { label: "Bhutanese" },
    { label: "Bolivian" },
    { label: "Bosnian" },
    { label: "Brazilian" },
    { label: "British" },
    { label: "Bruneian" },
    { label: "Bulgarian" },
    { label: "Burkinabe" },
    { label: "Burmese" },
    { label: "Burundian" },
    { label: "Cambodian" },
    { label: "Cameroonian" },
    { label: "Canadian" },
    { label: "Cape Verdean" },
    { label: "Central African" },
    { label: "Chadian" },
    { label: "Chilean" },
    { label: "Chinese" },
    { label: "Colombian" },
    { label: "Comoran" },
    { label: "Congolese" },
    { label: "Costa Rican" },
    { label: "Croatian" },
    { label: "Cuban" },
    { label: "Cypriot" },
    { label: "Czech" },
    { label: "Danish" },
    { label: "Djibouti" },
    { label: "Dominican" },
    { label: "Dutch" },
    { label: "East Timorese" },
    { label: "Ecuadorean" },
    { label: "Egyptian" },
    { label: "Emirian" },
    { label: "Equatorial Guinean" },
    { label: "Eritrean" },
    { label: "Estonian" },
    { label: "Ethiopian" },
    { label: "Fijian" },
    { label: "Filipino" },
    { label: "Finnish" },
    { label: "French" },
    { label: "Gabonese" },
    { label: "Gambian" },
    { label: "Georgian" },
    { label: "German" },
    { label: "Ghanaian" },
    { label: "Greek" },
    { label: "Grenadian" },
    { label: "Guatemalan" },
    { label: "Guinea-Bissauan" },
    { label: "Guinean" },
    { label: "Guyanese" },
    { label: "Haitian" },
    { label: "Herzegovinian" },
    { label: "Honduran" },
    { label: "Hungarian" },
    { label: "I-Kiribati" },
    { label: "Icelander" },
    { label: "Indian" },
    { label: "Indonesian" },
    { label: "Iranian" },
    { label: "Iraqi" },
    { label: "Irish" },
    { label: "Israeli" },
    { label: "Italian" },
    { label: "Ivorian" },
    { label: "Jamaican" },
    { label: "Japanese" },
    { label: "Jordanian" },
    { label: "Kazakhstani" },
    { label: "Kenyan" },
    { label: "Kittian and Nevisian" },
    { label: "Kuwaiti" },
    { label: "Kyrgyz" },
    { label: "Laotian" },
    { label: "Latvian" },
    { label: "Lebanese" },
    { label: "Liberian" },
    { label: "Libyan" },
    { label: "Liechtensteiner" },
    { label: "Lithuanian" },
    { label: "Luxembourger" },
    { label: "Macedonian" },
    { label: "Malagasy" },
    { label: "Malawian" },
    { label: "Malaysian" },
    { label: "Maldivan" },
    { label: "Malian" },
    { label: "Maltese" },
    { label: "Marshallese" },
    { label: "Mauritanian" },
    { label: "Mauritian" },
    { label: "Mexican" },
    { label: "Micronesian" },
    { label: "Moldovan" },
    { label: "Monacan" },
    { label: "Mongolian" },
    { label: "Moroccan" },
    { label: "Mosotho" },
    { label: "Motswana" },
    { label: "Mozambican" },
    { label: "Namibian" },
    { label: "Nauruan" },
    { label: "Nepalese" },
    { label: "New Zealander" },
    { label: "Nicaraguan" },
    { label: "Nigerian" },
    { label: "Nigerien" },
    { label: "North Korean" },
    { label: "Northern Irish" },
    { label: "Norwegian" },
    { label: "Omani" },
    { label: "Pakistani" },
    { label: "Palauan" },
    { label: "Panamanian" },
    { label: "Papua New Guinean" },
    { label: "Paraguayan" },
    { label: "Peruvian" },
    { label: "Polish" },
    { label: "Portuguese" },
    { label: "Qatari" },
    { label: "Romanian" },
    { label: "Russian" },
    { label: "Rwandan" },
    { label: "Saint Lucian" },
    { label: "Salvadoran" },
    { label: "Samoan" },
    { label: "San Marinese" },
    { label: "Sao Tomean" },
    { label: "Saudi" },
    { label: "Scottish" },
    { label: "Senegalese" },
    { label: "Serbian" },
    { label: "Seychellois" },
    { label: "Sierra Leonean" },
    { label: "Singaporean" },
    { label: "Slovakian" },
    { label: "Slovenian" },
    { label: "Solomon Islander" },
    { label: "Somali" },
    { label: "South African" },
    { label: "South Korean" },
    { label: "Spanish" },
    { label: "Sri Lankan" },
    { label: "Sudanese" },
    { label: "Surinamer" },
    { label: "Swazi" },
    { label: "Swedish" },
    { label: "Swiss" },
    { label: "Syrian" },
    { label: "Taiwanese" },
    { label: "Tajik" },
    { label: "Tanzanian" },
    { label: "Thai" },
    { label: "Togolese" },
    { label: "Tongan" },
    { label: "Trinidadian or Tobagonian" },
    { label: "Tunisian" },
    { label: "Turkish" },
    { label: "Tuvaluan" },
    { label: "Ugandan" },
    { label: "Ukrainian" },
    { label: "Uruguayan" },
    { label: "Uzbekistani" },
    { label: "Venezuelan" },
    { label: "Vietnamese" },
    { label: "Welsh" },
    { label: "Yemenite" },
    { label: "Zambian" },
    { label: "Zimbabwean" },
];
