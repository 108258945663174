import React, { Component, } from 'react';
import { withRouter } from 'react-router';
// import { Fonts } from '../../themes';
//import { pathName } from "../../constant";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { isMobile, isTablet } from '../PackageInfoPanel/GetItemColsPerWidth';
import styled, { css } from 'styled-components';
// const isDisplayIndex = false;
// let _index = 0;
let _ImageList = [];
class DayTripSlider extends Component {
    render() {
        const { ImageList, productName, Host } = this.props;
        const _isMobile = isMobile();
        if (ImageList[0].original.indexOf(Host) === -1) {
            _ImageList = ImageList.map(_item => {
                _item.thumbnail = Host.concat(_item.thumbnail);
                _item.thumbnailAlt = productName;
                _item.originalAlt = productName;
                _item.original = isTablet() ? Host.concat(_item.original) : (_isMobile ? _item.thumbnail : Host.concat(_item.original));
                _item.sizes = (!_isMobile) && '(height: 450px)';
                return _item;
            });
        }
        else {
            _ImageList = ImageList.map(_item => {
                _item.thumbnail = _item.thumbnail;
                _item.thumbnailAlt = productName;
                _item.originalAlt = productName;
                _item.original = isTablet() ? _item.original : (_isMobile ? _item.thumbnail : _item.original);
                _item.sizes = (!_isMobile) && '(height: 450px)';
                return _item;
            });
        }
        //console.log('history  map : ', ImageList);
        return this.attactionItem(_isMobile);
    }
    attactionItem(_isMobile) {
        return (<GalleryContianer isMobile={_isMobile} isSmallSize={(window.innerWidth < 325)}>
                <ImageGallery items={_ImageList} lazyLoad={true} autoPlay={true} showFileMeta={true} server={true} stopPropagation={false} showBullets={true} showThumbnails={true} showPlayButton={false} showFullscreenButton={false} showNav={false} 
        // onSlide={(index) => { _index = index; }}
        // onImageLoad={() => { console.log('this onImageLoad : '); }}
        // onClick={() => { (isDisplayIndex) && console.log('this MouseOver : '); }}
        //slideDuration={450}
        slideInterval={4000}/>
            </GalleryContianer>);
    }
}
export default withRouter(DayTripSlider);
// const { avenir } = Fonts.family;
const GalleryContianer = styled.div `    
height: 100%;
width: 100%;
box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 20px 0px;
cursor: pointer;
max-width: 681px;
${(props) => props.isMobile && css `max-width:${window.innerWidth - 20}px;`}
${(props) => props.isSmallSize && css `max-width:unset;margin-left: 14px;`}`;
// const _style = {
//     card_container: {
//         height: '100%',
//         width: '100%',
//         boxShadow: '0 0 20px 0 rgba(0,0,0,0.18)',
//         cursor: 'pointer',
//         marginBottm: '85px'
//     },
// }
// const images = [
//     {
//         original: 'https://api.travisgo.animation-genius.com/api/uploads/system/images/SjQ57XInUmW6Hn48.jpg',
//         thumbnail: 'https://api.travisgo.animation-genius.com/api/uploads/system/images/SjQ57XInUmW6Hn48.jpg',
//     },
// ]
