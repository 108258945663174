import * as React from 'react';
import { omit, path } from "ramda";
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import CustomDialog from './CustomDialog';
import Text from "../text";
import { Metrics, 
// Fonts,
ZIndex, Colors, } from "../../themes";
const styles = {
    paper: {
        // height: (props) => props.style.height || "100%",
        // maxWidth: (props) => props.style.width || "",
        // overflow: "initial",
        // width: (props) => props.style.width || "100%",
        minWidth: '350px',
        borderRadius: Metrics.modal.borderRadius,
    },
    colorPrimary: {
        backgroundColor: '#e9b9b4',
    },
    dashedColorPrimary: {
        backgroundColor: '#f0d0cd',
    },
    barColorPrimary: {
        backgroundColor: Colors.theme,
    },
};
const LoadingWithStyles = compose(withStyles(styles), withMobileDialog())((props) => {
    return (<Dialog fullScreen={props.fullScreen} open={props.open} 
    // onClose={props.onClose}
    classes={{
        paper: path(['classes', 'paper'], props),
    }} style={{
        zIndex: ZIndex.dialog.loading,
    }}>
            <DialogTitle>
                <Text.Etext text={props.loadingText}/>
            </DialogTitle>
            <DialogContent>
                {props.circle ?
        <div style={{
            display: 'flex',
            justifyContent: 'center',
        }}>
                            <CircularProgress style={{ color: Colors.theme }}/>
                        </div>
        :
            <LinearProgress 
            // style={{}}
            color="primary" variant={props.variant || 'indeterminate'} value={props.value} valueBuffer={props.valueBuffer} classes={{
                colorPrimary: props.classes.colorPrimary,
                dashedColorPrimary: props.classes.dashedColorPrimary,
                barColorPrimary: props.classes.barColorPrimary,
            }}/>}
            </DialogContent>
        </Dialog>);
});
class Loading extends CustomDialog {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            loadingText: 'Loading',
        };
    }
    loading(text = 'Loading') {
        this.setState({ loadingText: text }, () => {
            if (this.state.open !== true) {
                this.show();
            }
        });
    }
    render() {
        const { open, loadingText } = this.state;
        return (<LoadingWithStyles open={open} loadingText={typeof this.props.open === 'boolean' ? this.props.text : loadingText} 
        // onClose={this.onClose}
        // renderCloseMark={this.renderCloseMark.bind(this)}
        {...omit(['open'], this.props)}/>);
    }
}
Loading.defaultProps = {
    dismiss: false,
    circle: false,
    fullScreen: false,
};
export default Loading;
