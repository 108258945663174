import AdditionalInfo from "./AdditionalInfo";
import CounterAndAtm from "./CounterAndAtm";
import CreditCard from "./CreditCard";
import GuestInfo from "./GuestInfo";
import * as PaymentChannel from "./PaymentChannel";
import PaymentInstruction from "./PaymentInstruction";
import travelerInfo from "./travelerInfo";
export default {
    AdditionalInfo,
    CounterAndAtm,
    CreditCard,
    GuestInfo,
    PaymentChannel,
    PaymentInstruction,
    travelerInfo,
};
