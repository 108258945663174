import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { compose } from "recompose";
import MetaTags from 'react-meta-tags';
// import {Helmet} from "react-helmet";
import Rating from 'react-rating';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import DayTripCardFull from '../containers/ChoosePackage/DayTripCardFull';
import StepperPanel from '../components/StepperPanel';
import SubTopicsPanel from '../components/SubTopicsPanel';
import ContentWrapper from '../components/ContentWrapper';
import FacebookMessenger from '../components/Social/FacebookMessenger';
import PackageGridListPanel from '../containers/PackageInfoPanel/PackageGridListPanel';
import TraceFacebookPixel, { TrackType } from '../components/TraceInfo/FacebookPixel';
import { SetFakeReviewWithPackageId } from '../containers/PackageInfoPanel/PackageFakeBooked';
import { pathName, reduxKeys } from "../constant";
import SummaryPanel, { UpdateSummaryPanel } from '../containers/ChoosePackage/SummaryPanel';
import { isMobile, isSafari } from '../containers/PackageInfoPanel/GetItemColsPerWidth';
import SettingActions from "../redux/SettingsRedux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
// import faGoogle from '@fortawesome/fontawesome-free-brands/faGoogle';
import { faTwitter, faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton, GooglePlusShareButton, TwitterShareButton } from 'react-share';
import config from '../config';
import styled, { css } from 'styled-components';
import { Metrics, Fonts, Svg, Colors } from "../themes";
import localization from "../services/localization";
import { wordingKeys } from "../constant/enum/packageLabelKeys";
// import { from } from 'seamless-immutable';
// import { Images, Metrics, Fonts, Svg } from "../../themes";
// import { packageList } from '../PackageInfoPanel/PackageCardForm';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
// import {GetItemColsPerWidth,isMobile,} from '../PackageInfoPanel/GetItemColsPerWidth';
let pageLabel;
let selectedItemLists = [];
const { host, directionURL } = config;
// const { ratingActive, ratingDefault, iconCart, iconFacebook, iconGooglePlus, iconTwitter } = Images.package;
// =================================================================================================================
// =================================================================================================================
class ChoosePackage extends Component {
    constructor(props) {
        super(props);
        this.state = { open: true, ismobile: isMobile(), bookStep: false, isWaitPackage: undefined };
        // ==============================================================================================
        this.updateDimensions = () => {
            const ismobile = isMobile();
            // console.log('updateDimensions : ', _isMobile);
            if (this.state.ismobile !== ismobile) {
                this.setState({ ismobile });
            }
        };
        this.handleToggle = (item) => () => {
            item.selected = !item.selected;
            // console.log('handleToggle : ', item);
            UpdateSummaryPanel(item.selected);
        };
        this.SelectedPakage = (packageInfo) => {
            if (selectedItemLists.length === 0 || (selectedItemLists.indexOf(packageInfo) === -1)) {
                selectedItemLists.push(packageInfo);
                this.forceUpdate();
            }
        };
        this.RemovePakage = (packageInfo) => () => {
            if (selectedItemLists.length !== 0 && (selectedItemLists.indexOf(packageInfo) !== -1)) {
                selectedItemLists = selectedItemLists.filter((item) => { if (item !== packageInfo) {
                    return item;
                } });
                // console.log('RemovePakege : ', selectedItemLists);
                this.forceUpdate();
            }
        };
        const { location, history, settings } = this.props;
        const { state: localState } = location;
        TraceFacebookPixel({ trackType: TrackType.PageView });
        // Check old language key ======================================================================================
        // Use buile production under 2.
        if (settings.language === 'en' || settings.language === 'th') {
            this.props.dispatch(SettingActions.changeLanguage(settings.language.toUpperCase()));
        }
        // =============================================================================================================
        console.log('ChoosePackage : ', location);
        const packageId = location.search;
        if (localState === undefined && packageId === '') {
            history.push(pathName.INDEX);
        }
        else {
            if (localState !== undefined) {
                const { SelectedInfo } = localState;
                if (Boolean(SelectedInfo)) {
                    console.log('SelectedInfo : ', SelectedInfo);
                    this.state.bookStep = true;
                }
            }
        }
    }
    componentWillUnmount() {
        // selectedItemLists = selectedItemLists.map((item) => {
        //     return item.packageInfo.optionLists.map((option) => option.selected = option.include);
        // });
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }
    render() {
        pageLabel = localization(wordingKeys.packageLabel);
        const { location, history, other, settings, } = this.props;
        const { state: localState } = location;
        const isLocationState = Boolean(localState);
        let packageId = location.search;
        // console.log('ChoosePackage packageId : ', packageId);
        if (Boolean(packageId)) {
            packageId = packageId.slice(1);
            const newURL = packageId.split('&');
            packageId = Boolean(newURL) ? newURL[0] : packageId;
            if (packageId.match(/(PKD-20)/i)) {
                const _packageList = this.props[reduxKeys.package].packageList;
                if (Array.isArray(_packageList)) {
                    const packageItem = _packageList.find((_package) => _package.packageId === packageId);
                    const { isWaitPackage } = this.state;
                    // console.log('ChoosePackage packageItem : ', packageItem);
                    // if (!!packageItem) {
                    //     selectedItemLists = [packageItem];
                    // }
                    // else if (isWaitPackage === undefined) {
                    //     setTimeout(() => { this.setState({ isWaitPackage: true }) }, 2400);//Fix
                    // }
                    // else {
                    //     history.push(pathName.INDEX);
                    // }
                    switch (true) {
                        case (!!packageItem):
                            {
                                selectedItemLists = [packageItem];
                            }
                            break;
                        case (isWaitPackage === undefined):
                            {
                                setTimeout(() => { this.setState({ isWaitPackage: true }); }, 2400);
                            }
                            break;
                        default: history.push(pathName.INDEX);
                    }
                }
            }
            else {
                history.push(pathName.INDEX);
            }
        }
        else {
            if (Boolean(packageId) || (isLocationState)) {
                const { packageInfo } = localState;
                // console.log('ChoosePackage optionLists : ', packageInfo[0].packageInfo.optionLists);
                selectedItemLists = packageInfo.map((item) => {
                    item.optinActive = true;
                    if (item.packageInfo.optionLists === undefined) {
                        item.packageInfo.optionLists = [];
                    }
                    return item;
                });
            }
            else {
                history.push(pathName.INDEX);
                return null;
            }
        }
        // const SelectedInfo = (isLocationState) ? localState.SelectedInfo : undefined;
        // const FilterDate = (isLocationState) ? localState.FilterDate : undefined;
        // console.log('SelectItems : ', localState);
        const { SelectedInfo, FilterDate } = localState || { SelectedInfo: undefined, FilterDate: undefined };
        const { ismobile, bookStep } = this.state;
        const _bookStepPanel = (ismobile && bookStep);
        // console.log('locationServiceId : ', selectedItemLists);
        const { locationServices } = other;
        const isPackageInfo = selectedItemLists[0]; //fix
        if (isPackageInfo === undefined)
            return null;
        const { locationServiceId = '' } = isPackageInfo; //fix
        // console.log('locationServiceId : ', locationServiceId);
        const { thumbnail } = (!!selectedItemLists) ? isPackageInfo.packageInfo.image : '';
        const Language = settings.language;
        return (_bookStepPanel) ?
            (<ContentWrapper hideFooter={ismobile}>
                    <SetMetaTag Image={`${host}${thumbnail}`}/>
                    <div>
                        <SubTopicsPanel _pageName={pathName.CHOOSE_PACKAGE}/>
                        <StepperPanel isStap={0} BookingList={selectedItemLists} productType={isPackageInfo.type} Language={Language} isMobile={ismobile}/>
                        <SummaryPanel SelectedLists={selectedItemLists} RemovePackage={this.RemovePakage} SelectedInfo={SelectedInfo} FilterDate={FilterDate}/>
                    </div>
                    <FacebookMessenger Language={Language}/>
                </ContentWrapper>) :
            (<ContentWrapper hideFooter={ismobile}>
                <SetMetaTag Image={`${host}${thumbnail}`}/>
                <ContentContianer>
                    <div style={{ width: Metrics.maxInnerWidth }}>
                        <SubTopicsPanel _pageName={pathName.CHOOSE_PACKAGE}/>
                        <StepperPanel isStap={0} BookingList={selectedItemLists} productType={isPackageInfo.type} Language={Language} isMobile={ismobile}/>
                        <PackageNamePanel isMobile={ismobile} Info={isPackageInfo}/>
                        <ShareProductPanel isMobile={ismobile} packageInfo={isPackageInfo} locationServices={locationServices} Language={Language}/>
                        {this.DispalySelectedPanel(SelectedInfo, FilterDate)}
                        <EndLine isMobile={ismobile}/>
                    </div>
                </ContentContianer>
                <RecommendPanelContianer isMobile={ismobile}>
                    <PackageGridListPanel PackageList={this.props[reduxKeys.package].packageList} PanelType={"Recommend" /* RECOMMEND */} Language={Language} LocationService={locationServiceId}/>
                </RecommendPanelContianer>
                {(ismobile) && this.FixBottomPanel(bookStep)}
                <FacebookMessenger Language={Language}/>
            </ContentWrapper>);
    }
    // ==============================================================================================
    FixBottomPanel(bookStep) {
        return (<FixBottomContianer>
                <Button style={style.FixBottomStyle} onClick={() => {
            this.setState({ bookStep: !bookStep }, () => window.scrollTo(0, 0));
        }}>
                    {pageLabel[wordingKeys.BookButton]}
                    
                    <Svg.Landing.Cart />
                </Button>
            </FixBottomContianer>);
    }
    DispalySelectedPanel(SelectedInfo, FilterDate) {
        const { ismobile } = this.state;
        return (<PackageInfoContianer isMobile={ismobile}>
                <RightInfoContianer isMobile={ismobile} isSmallSize={(window.innerWidth < 325)}>
                    <DayTripCardFull PackageLists={selectedItemLists} isMobile={ismobile} SelectedPakage={(item) => this.SelectedPakage(item)} handleToggle={this.handleToggle}/>
                </RightInfoContianer>
                <LeftInfoContianer>
                    {(!ismobile) && <SummaryPanel SelectedLists={selectedItemLists} RemovePackage={this.RemovePakage} SelectedInfo={SelectedInfo} FilterDate={FilterDate}/>}
                </LeftInfoContianer>
            </PackageInfoContianer>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.package]: state[reduxKeys.package],
    [reduxKeys.other]: state[reduxKeys.other],
    [reduxKeys.settings]: state[reduxKeys.settings],
});
export default compose(withRouter, connect(mapStateToProps))(ChoosePackage);
// ==================================================================================================
function PackageNamePanel(props) {
    const { Info, isMobile } = props;
    const { packageInfo, rating, packageId } = Info;
    const { name, desShort } = packageInfo;
    const fakeReviews = SetFakeReviewWithPackageId(packageId, rating.reviews);
    // console.log('PackageNamePanel : ', Info);
    // <img alt='rating' style={imgStyle} src={ratingDefault} />
    // <img alt='rating' style={imgStyle} src={ratingActive} />
    return (<PackageNameContiner isMobile={isMobile}>
            <PackageNameLabel isMobile={isMobile}>{name}</PackageNameLabel>
            <div>
                <PackageDesShort>{desShort}</PackageDesShort>
            </div>
            <div style={{ marginTop: 10 }}>
                <Rating emptySymbol={<Svg.Landing.Star color={'#9d9d9d'}/>} fullSymbol={<Svg.Landing.Star />} initialRating={rating.averagePoint} readonly fractions={2}/>
                <span style={{ marginLeft: '5px' }}>
                    <ReviewsLable>
                        {`( ${fakeReviews || 0} ${pageLabel[wordingKeys.Reviews]} )`}
                    </ReviewsLable>
                </span>
            </div>
        </PackageNameContiner>);
}
function ShareProductPanel(props) {
    const { isMobile, packageInfo, locationServices, Language } = props;
    const { packageId, locationServiceId } = packageInfo;
    const _newPackageInfo = { ...packageInfo.packageInfo, packageId, locationServiceId };
    return (isMobile) ?
        (<ShareProductContianer>
            <div style={{ flex: 1 }}>
                <ShareItems isMobile packageInfo={_newPackageInfo} locationServices={locationServices} Language={Language}/>
            </div>
        </ShareProductContianer>) :
        (<ShareProductContianer>
            <RightInfoContianer isMobile={isMobile} isNonPadding>
                <ShareItems packageInfo={_newPackageInfo} locationServices={locationServices} Language={Language}/>
            </RightInfoContianer>
            <LeftInfoContianer isNonPadding/>
        </ShareProductContianer>);
}
function ShareItems(props) {
    const { packageInfo, isMobile, locationServices, Language } = props;
    const { packageId, locationServiceId, image, name } = packageInfo;
    const location = locationServices.find((info) => info.locationServiceId === locationServiceId);
    // console.log('ShareItems : ', location)
    const Url = `${directionURL}/choosePackage?${packageId}`;
    const Quout = name;
    const Hashtag = Boolean(location) && `#${location.name_EN} #${location[Language].name}`;
    const { FontFacebookStyle, FontTwitterStyle, FontGoogleStyle, IconStyle, IconStyleContianer } = style;
    return (<ShareItemsContianer isMobile={isMobile}>
            <ItemsSharePanelContianer>
                <ShareContianerL>
                    {pageLabel[wordingKeys.ShareTrip]}
                </ShareContianerL>
                <ShareContianerR>
                    <IconButton style={IconStyleContianer}>
                        <TwitterShareButton url={Url} style={IconStyle}>
                            
                            <FontAwesomeIcon icon={faTwitter} style={FontTwitterStyle}/>
                        </TwitterShareButton>
                    </IconButton>
                    <IconButton style={IconStyleContianer}>
                        <FacebookShareButton url={Url} quote={Quout} hashtag={Hashtag} style={IconStyle}>
                            
                            <FontAwesomeIcon icon={faFacebookF} style={FontFacebookStyle}/>
                            <SetMetaTag Image={`${host}${image.thumbnail}`} Name={name}/>
                        </FacebookShareButton>
                    </IconButton>
                    <IconButton style={IconStyleContianer}>
                        <GooglePlusShareButton url={Url} style={IconStyle}>
                            
                            <FontAwesomeIcon icon={faGoogle} style={FontGoogleStyle}/>
                        </GooglePlusShareButton>
                    </IconButton>
                </ShareContianerR>
            </ItemsSharePanelContianer>
        </ShareItemsContianer>);
}
function SetMetaTag(props) {
    const { Image, Name } = props;
    return (<MetaTags id='OgImage'>
            <title>{Boolean(Name) ? `${Name} - ${pageLabel[wordingKeys.AppTitle]}`
        : pageLabel[wordingKeys.AppTitle]}</title>
            <meta name="description" content={Name}/>
            <meta property="og:title" content={Name}/>
            <meta property='og:image' id='OgImage' content={Image}/>
            <meta property="og:image:height" content="600"/>
            <meta property="og:image:width" content="315"/>
            <meta property='ia:markup_url' id='MarkUpImage' content={Image}/>
        </MetaTags>);
}
const { avenir } = Fonts.family;
const _safari = isSafari();
const ContentContianer = styled.div `
            display: flex;
            height: 100%;
            min-height: 1000px;
            justify-content: center;
${(props) => props.isMobile && css `padding:0px 10px;`}`;
const PackageInfoContianer = styled.div `
            width: 100%;
            display: flex;
            margin-bottom: 20px;
${(props) => props.isMobile && css `display:table;`}`;
const PackageNameContiner = styled.div `
            margin:10px;
            padding:0px;
${(props) => props.isMobile && css `margin:'0px 10px';`}`; // padding:0px 10px;
const RightInfoContianer = styled.div `
            flex: 1 1 0%;
            padding: 10px;
            max-width: 681px;
${(props) => props.isMobile && css `max-width:${Metrics.maxInnerWidth};`}
${(props) => props.isSmallSize && css `padding: 10px 0px;max-width:335px;`}
${(props) => props.isNonPadding && css `padding: 0px 10px;`}`;
const LeftInfoContianer = styled.div `
            flex: 1 1 0%;
            max-width: 347px;
            min-width: 347px;
            padding: 10px;
${(props) => props.isNonPadding && css `padding: 0px 10px;`}`;
const EndLine = styled.div `
            box-sizing:border-box;
            width: 100%;
            margin-bottom:50px;
            border: 1px solid #979797;
            opacity: 0.27;
${(props) => props.isMobile && css `width:90%;margin: 0px 20px 20px 20px;`}`;
const PackageNameLabel = styled.p `
            margin: 0px;
            color: rgb(74, 74, 74);
            font-family: ${avenir};
            font-size: 29px;
            font-weight: 900;
            line-height: 40px;
            letter-spacing: ${_safari ? -1 : 0}px;
${(props) => props.isMobile && css `font-size:18px;line-height:unset`}`;
const PackageDesShort = styled.label `
            color: rgb(130, 130, 130);
            font-family: ${avenir};
            font-size: 14px;
            line-height: 19px;
            letter-spacing: ${_safari ? -1 : 0}px;`;
const FixBottomContianer = styled.div `
            position: fixed;
            z-index: 99;
            left: 0;
            bottom: 0;
            width: 100%;`;
const ShareProductContianer = styled.div `
            display: flex;
            width: 100%;
            margin-top: -20px;
            margin-bottom: -10px;`;
const ShareContianerL = styled.div `
            flex:1;
            height: 30px;
            width: 99px;
            background-color: ${Colors.theme};
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.5);
            color: #FFFFFF;
            font-family: ${avenir};
            font-size: 12px;
            font-weight: 900;
            line-height: 16px;
            text-align: center;
            padding: 7px 0px;`;
const ShareContianerR = styled.div `
            flex:1;
            height: 30px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 6px 0 rgba(0,0,0,0.5);
            text-align: center;`;
const ItemsSharePanelContianer = styled.div `
            display: flex;
            width: 200px;`;
const ShareItemsContianer = styled.div `
            float: right;
${(props) => props.isMobile && css `padding-right: 10px;`}`;
const ReviewsLable = styled.label `
            height: 16px;
            width: 135.61px;
            color: #88848B;
            font-family: ${avenir};
            font-size: 0.6875em;
            font-weight: bold;
            letter-spacing: -0.09px;
            line-height: 16px;`;
const RecommendPanelContianer = styled.div `
            margin-bottom: 0px;
${(props) => props.isMobile && css ` margin-bottom: 45px;`}`;
// =======================================================================================
const style = {
    FixBottomStyle: {
        width: "100%",
        color: "white",
        fontSize: "17px",
        fontFamily: avenir,
        fontWeight: "900",
        letterSpacing: `${_safari ? -1 : 1.57}`,
        backgroundColor: `${Colors.theme}`,
        boxShadow: "box-shadow: rgba(126,126,126,0.5) 0px 2px 10px 0px",
        textAlign: "center",
        minWidth: "170px",
        borderRadius: "0px",
        padding: "10px 0px",
    },
    FontFacebookStyle: { fontSize: 12, color: '#5366a8' },
    FontTwitterStyle: { fontSize: 12, color: '#1faee1' },
    FontGoogleStyle: { fontSize: 12, color: '#c04a56' },
    IconStyleContianer: { marginTop: 2, padding: 5, width: 25, height: 25 },
    IconStyle: { marginTop: -10, width: 25, outline: 'unset' },
};
// ===========================================================================================
