import { inputType } from "../constant";
export const fieldsName = {
    bookingId: 'bookingId',
    type: 'type',
    status: 'status',
    travelDate: 'travelDate',
};
export default ({ defaultValues = {} }) => ({
    [fieldsName.bookingId]: {
        label: 'Find Booking',
        placeholder: 'Booking ID , Name',
        type: inputType.TEXT_INPUT,
        value: defaultValues[fieldsName.bookingId],
    },
});
