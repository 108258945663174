import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Text from "../text";
import { Colors } from "../../themes";
const styles = () => ({
    root: {
        width: '100%',
        height: 50,
    },
    icon: {
        transition: 'transform 200ms',
        color: '#fff',
    },
});
const PanelHeader = ({ collapsible = false, isOpen, onClick, children, classes, style = {}, textStyle = {}, isMobile, iconStyle = {}, }) => {
    return (<ButtonBase onClick={onClick} disabled={!collapsible} className={classes.root} style={{
        justifyContent: collapsible ? 'space-between' : 'flex-start',
        backgroundColor: collapsible ? isOpen ? Colors.panel.headerCollapActive : Colors.panel.headerCollapDefault : Colors.theme,
        paddingLeft: isMobile ? 15 : 40,
        paddingRight: 15,
        ...style,
    }}>
            <Text.Avenir size={isMobile ? 18 : 24} color="#fff" family="heavy" style={{ ...textStyle }}>
                {children}
            </Text.Avenir>
            {collapsible && (<ExpandMoreIcon className={classes.icon} style={{
        transform: isOpen ? 'rotate(180deg)' : '',
        ...iconStyle,
    }}/>)}
        </ButtonBase>);
};
export default withStyles(styles)(PanelHeader);
