export const reduxKeys = {
    authen: 'authen',
    error: 'error',
    user: 'user',
    settings: 'settings',
    booking: 'booking',
    package: 'package',
    other: 'other',
    screen: 'screen',
};
