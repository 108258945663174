import { combineReducers } from 'redux';
import { resettableReducer } from "reduxsauce";
// import { persistCombineReducers } from 'redux-persist';
import rootSaga from '../sagas';
import { CLEAR_STORE } from '../sagas/ManageStoreSaga';
import configureStore from './createStore';
// import reduxPersist from "../config/reduxPersist";
import { reduxKeys } from "../constant";
import { reducer as AuthenReducer } from "./AuthenRedux";
import { reducer as ErrorReducer } from "./ErrorRedux";
import { reducer as UserReducer } from "./UserRedux";
import { reducer as SettingsReducer } from "./SettingsRedux";
import { reducer as BookingReducer } from "./BookingRedux";
import { reducer as PackageReducer } from "./PackageRedux";
import { reducer as OtherReducer } from "./OtherRedux";
import { reducer as ScreenReducer } from "./ScreenRedux";
const resettable = resettableReducer(CLEAR_STORE);
const rootReducer = combineReducers({
    [reduxKeys.authen]: resettable(AuthenReducer),
    [reduxKeys.error]: ErrorReducer,
    [reduxKeys.user]: resettable(UserReducer),
    [reduxKeys.settings]: SettingsReducer,
    [reduxKeys.booking]: resettable(BookingReducer),
    [reduxKeys.package]: PackageReducer,
    [reduxKeys.other]: OtherReducer,
    [reduxKeys.screen]: ScreenReducer,
});
const { store, persistor: Persistor } = configureStore(rootReducer, rootSaga);
export const persistor = Persistor;
export default store;
