import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* ------------- Types and Action Creators ------------- */
export const PACKAGE_KEY = 'PACKAGE';
const { Types, Creators } = createActions({
    packageListRequest: ['payload'],
    packageListSuccess: ['packageList'],
    packageListFailure: ['packageList'],
    createReviewRequest: ['payload', 'nextAction'],
    createReviewSuccess: ['payload'],
    createReviewFailure: null,
    getReviewRequest: ['payload', 'nextAction'],
    getReviewSuccess: ['reviews'],
    getReviewFailure: [],
    getPromotionCodesRequest: ['payload'],
    getPromotionCodesSuccess: ['promotionCodes'],
    getPromotionCodesFailure: [],
    usePromotionCodesRequest: ['payload'],
    usePromotionCodesSuccess: ['payload'],
    usePromotionCodesFailure: null,
    clearPromotionCodes: null,
    getWishListRequest: ['payload'],
    getWishListSuccess: ['wishLists'],
    getWishListFailure: null,
    postWishListRequest: ['payload', 'nextAction'],
    postWishListSuccess: ['payload'],
    postWishListFailure: null,
    linkStatRequest: ['payload', 'nextAction'],
    linkStatSuccess: ['stat'],
    linkStatFailure: null,
    requestReviewsRequest: ['payload', 'nextAction'],
    requestReviewsSuccess: ['requestReviews'],
    requestReviewsFailure: null,
    requestReviewUpdateRequest: ['payload', 'bookingId', 'nextAction'],
    requestReviewUpdateSuccess: ['payload'],
    requestReviewUpdateFailure: null,
    getPackageCategoryRequest: ['payload', 'nextAction'],
    getPackageCategorySuccess: ['packageCategory'],
    getPackageCategoryFailure: null,
}, { prefix: `${PACKAGE_KEY}_` });
export const PackageTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    packageList: [],
    reviews: [],
    promotionCodes: null,
    wishLists: null,
    stat: [],
    requestReviews: [],
    packageCategory: [],
});
/* ------------- Reducers ------------- */
// request the data from an api
export const packageListRequest = (state) => Object.assign({}, state, { loading: true });
export const packageListSuccess = (state, { packageList }) => (Object.assign({}, state, { packageList, loading: false }));
export const packageListFailure = (state, { error, packageList }) => Object.assign({}, state, { packageList, error, loading: false });
export const createReviewRequest = (state) => Object.assign({}, state, { loading: true });
export const createReviewSuccess = (state, { data }) => (Object.assign({}, state, {
    data: { ...state.data, ...data },
    loading: false,
}));
export const createReviewFailure = (state, { error }) => Object.assign({}, state, { error, loading: false });
export const getReviewRequest = (state) => Object.assign({}, state, { loading: true });
export const getReviewSuccess = (state, { reviews }) => Object.assign({}, state, { reviews, loading: false });
export const getReviewFailure = (state, { error }) => Object.assign({}, state, { error, loading: false });
export const getPromotionCodesRequest = (state) => Object.assign({}, state, { loading: true });
export const getPromotionCodesSuccess = (state, { promotionCodes }) => Object.assign({}, state, { promotionCodes, loading: false });
export const getPromotionCodesFailure = (state) => Object.assign({}, state, { loading: false });
export const usePromotionCodesRequest = (state) => Object.assign({}, state, { loading: true });
export const usePromotionCodesSuccess = (state, { payload }) => Object.assign({}, state, { payload, loading: false });
export const usePromotionCodesFailure = (state) => Object.assign({}, state, { loading: false });
export const clearPromotionCodes = (state) => Object.assign({}, state, { promotionCodes: null });
export const getWishListRequest = (state) => Object.assign({}, state, { loading: true });
export const getWishListSuccess = (state, { wishLists }) => Object.assign({}, state, { wishLists, loading: false });
export const getWishListFailure = (state) => Object.assign({}, state, { loading: false });
export const postWishListRequest = (state) => Object.assign({}, state, { loading: true });
export const postWishListSuccess = (state, { payload: { data } }) => (Object.assign({}, state, {
    data: { ...state.data, ...data },
    loading: false,
}));
export const postWishListFailure = (state) => Object.assign({}, state, { loading: false });
export const linkStatRequest = (state) => Object.assign({}, state, { loading: true });
export const linkStatSuccess = (state, { stat }) => Object.assign({}, state, { stat, loading: false });
export const linkStatFailure = (state) => Object.assign({}, state, { loading: false });
export const requestReviewsRequest = (state) => Object.assign({}, state, { loading: true });
export const requestReviewsSuccess = (state, { requestReviews }) => Object.assign({}, state, { requestReviews, loading: false });
export const requestReviewsFailure = (state) => Object.assign({}, state, { loading: false });
export const requestReviewUpdateRequest = (state) => Object.assign({}, state, { loading: true });
export const requestReviewUpdateSuccess = (state, { payload }, bookingId) => Object.assign({}, state, { payload, loading: false }, bookingId);
export const requestReviewUpdateFailure = (state) => Object.assign({}, state, { loading: false });
export const getPackageCategoryRequest = (state) => Object.assign({}, state, { loading: true });
export const getPackageCategorySuccess = (state, { packageCategory }) => Object.assign({}, state, { packageCategory, loading: false });
export const getPackageCategoryFailure = (state) => Object.assign({}, state, { loading: false });
/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.PACKAGE_LIST_REQUEST]: packageListRequest,
    [Types.PACKAGE_LIST_SUCCESS]: packageListSuccess,
    [Types.PACKAGE_LIST_FAILURE]: packageListFailure,
    [Types.CREATE_REVIEW_REQUEST]: createReviewRequest,
    [Types.CREATE_REVIEW_SUCCESS]: createReviewSuccess,
    [Types.CREATE_REVIEW_FAILURE]: createReviewFailure,
    [Types.GET_REVIEW_REQUEST]: getReviewRequest,
    [Types.GET_REVIEW_SUCCESS]: getReviewSuccess,
    [Types.GET_REVIEW_FAILURE]: getReviewFailure,
    [Types.GET_PROMOTION_CODES_REQUEST]: getPromotionCodesRequest,
    [Types.GET_PROMOTION_CODES_SUCCESS]: getPromotionCodesSuccess,
    [Types.GET_PROMOTION_CODES_FAILURE]: getPromotionCodesFailure,
    [Types.USE_PROMOTION_CODES_REQUEST]: usePromotionCodesRequest,
    [Types.USE_PROMOTION_CODES_SUCCESS]: usePromotionCodesSuccess,
    [Types.USE_PROMOTION_CODES_FAILURE]: usePromotionCodesFailure,
    [Types.CLEAR_PROMOTION_CODES]: clearPromotionCodes,
    [Types.GET_WISH_LIST_REQUEST]: getWishListRequest,
    [Types.GET_WISH_LIST_SUCCESS]: getWishListSuccess,
    [Types.GET_WISH_LIST_FAILURE]: getWishListFailure,
    [Types.POST_WISH_LIST_REQUEST]: postWishListRequest,
    [Types.POST_WISH_LIST_SUCCESS]: postWishListSuccess,
    [Types.POST_WISH_LIST_FAILURE]: postWishListFailure,
    [Types.LINK_STAT_REQUEST]: linkStatRequest,
    [Types.LINK_STAT_SUCCESS]: linkStatSuccess,
    [Types.LINK_STAT_FAILURE]: linkStatFailure,
    [Types.REQUEST_REVIEWS_REQUEST]: requestReviewsRequest,
    [Types.REQUEST_REVIEWS_SUCCESS]: requestReviewsSuccess,
    [Types.REQUEST_REVIEWS_FAILURE]: requestReviewsFailure,
    [Types.REQUEST_REVIEW_UPDATE_REQUEST]: requestReviewUpdateRequest,
    [Types.REQUEST_REVIEW_UPDATE_SUCCESS]: requestReviewUpdateSuccess,
    [Types.REQUEST_REVIEW_UPDATE_FAILURE]: requestReviewUpdateFailure,
    [Types.GET_PACKAGE_CATEGORY_REQUEST]: getPackageCategoryRequest,
    [Types.GET_PACKAGE_CATEGORY_SUCCESS]: getPackageCategorySuccess,
    [Types.GET_PACKAGE_CATEGORY_FAILURE]: getPackageCategoryFailure,
});
