import { inputType } from "../constant";
import { validateActions } from '../utils/validator';
import { fieldsName as regiterFieldsName } from "./register";
import localization from "../services/localization";
export const fieldsName = {
    old_password: 'old_password',
    [regiterFieldsName.password]: regiterFieldsName.password,
    [regiterFieldsName.confirm_password]: regiterFieldsName.confirm_password,
};
export default ({ onPressEnter }) => ({
    [fieldsName.old_password]: {
        label: localization(`old_password`, `ProfileLabel`),
        inputProps: {
            type: 'password',
        },
        placeholder: localization(`old_password`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_password,
        style: {
            md: {
                paddingRight: '50%',
            },
        },
    },
    [fieldsName.password]: {
        label: localization(`new_password`, `ProfileLabel`),
        inputProps: {
            type: 'password',
        },
        placeholder: localization(`new_password`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_password,
        style: {
            md: {
                paddingRight: '50%',
            },
        },
    },
    [fieldsName.confirm_password]: {
        label: localization(`confirm_new_password`, `ProfileLabel`),
        inputProps: {
            type: 'password',
        },
        placeholder: localization(`confirm_new_password`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_confirmPassword,
        require_value: 'password',
        style: {
            md: {
                paddingRight: '50%',
            },
        },
        onPressEnter,
    },
});
