import store from "../redux";
// import OtherActions from "../redux/OtherRedux";
import PackageActions from "../redux/PackageRedux";
import { reduxKeys } from "../constant";
export default () => {
    const { [reduxKeys.settings]: { language, currency } } = store.getState();
    // store.dispatch(OtherActions.getLocationServicesRequest({ filter: { where: { isService: true } } }));
    // store.dispatch(OtherActions.getDestinationsRequest({ filter: { where: { actived: true } } }));
    // store.dispatch(OtherActions.getActivitiesRequest({ filter: { where: { actived: true } } }));
    // store.dispatch(OtherActions.getAppointmentsRequest({ filter: { where: { actived: true } } }));
    store.dispatch(PackageActions.packageListRequest({ language, currencyTo: currency }));
};
