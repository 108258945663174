import * as React from 'react';
import { omit } from "ramda";
import { connect } from "react-redux";
import FlexView from 'react-flexview/lib';
import { Colors } from "../themes";
import { reduxKeys } from "../constant";
const GrayBox = (props) => (<FlexView vAlignContent='center' style={{
    backgroundColor: Colors.grayScale.eb,
    padding: `13px ${props.isMobile ? 15 : 20}px`,
    fontSize: 16,
    color: Colors.grayScale["9e"],
    marginTop: 17,
    ...props.style,
}} {...omit(['isMobile', 'dispatch', 'style'], props)}/>);
const mapStateToProps = (state) => ({
    isMobile: state[reduxKeys.screen].isMobile,
});
export default connect(mapStateToProps)(GrayBox);
