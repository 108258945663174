import React from 'react';
import { connect } from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import { reduxKeys } from "../../constant";
const PanelContent = ({ collapsible = false, isOpen, children, style = {}, collapseStyle = {}, isMobile }) => {
    const content = (<div style={{ padding: `${isMobile ? 15 : 30}px ${isMobile ? 15 : 40}px`, ...style }}>
            {children}
        </div>);
    if (collapsible) {
        return (<Collapse in={isOpen} style={{ ...collapseStyle, }}>
                {content}
            </Collapse>);
    }
    return content;
};
const mapStateToProps = (state) => ({
    isMobile: state[reduxKeys.screen].isMobile,
});
export default connect(mapStateToProps)(PanelContent);
