// import Icon from 'react-native-vector-icons/MaterialIcons';
// import { TYPE } from '../components/form/Form';
// import validate from '../util/validate';
import { inputType, mockOptions, countryCode } from "../constant";
import { validateActions } from '../utils/validator';
import validator from '../utils/validator';
import localization from "../services/localization";
import { isSafari } from "../containers/PackageInfoPanel/GetItemColsPerWidth";
import { nationalities } from "../constant/json/nationalities";
export const fieldsName = {
    email: 'email',
    password: 'password',
    confirm_password: 'confirm_password',
    title: 'title',
    nationality: 'nationality',
    firstName: 'firstName',
    lastName: 'lastName',
    countryCode: 'countryCode',
    tel: 'tel',
    social: 'social',
    socialId: 'socialId',
    isAcceptInformation: 'isAcceptInformation',
};
export default ({ onPressEnter, defaultValues = {}, isSocial = false, isMobile, dialingCode, checkSocial, selectSocial }) => ({
    [fieldsName.email]: {
        label: localization(`email`, `ProfileLabel`),
        inputProps: {
            type: 'email',
        },
        placeholder: localization(`email`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_email,
        value: defaultValues[fieldsName.email],
        disabled: !validator(defaultValues[fieldsName.email], validateActions.user_email),
    },
    [fieldsName.password]: {
        label: localization(`password`, `AuthenLabel`),
        inputProps: {
            type: 'password',
        },
        placeholder: localization(`password`, `AuthenLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_password,
        value: defaultValues[fieldsName.password],
        skip: isSocial,
    },
    [fieldsName.confirm_password]: {
        label: localization(`confirm_passord`, `AuthenLabel`),
        inputProps: {
            type: 'password',
        },
        placeholder: localization(`confirm_passord`, `AuthenLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_confirmPassword,
        value: defaultValues[fieldsName.confirm_password],
        require_value: 'password',
        skip: isSocial,
    },
    [fieldsName.title]: {
        label: localization(`title`, `ProfileLabel`),
        type: inputType.RADIO,
        validate: (val) => {
            if (val === 0 || val === '') {
                return 'error_require_title';
            }
        },
        // md: 6,
        // style: {
        //     md: {
        //         // paddingRight: '20px',
        //     },
        // },
        // value: '1',
        options: mockOptions.title,
    },
    [fieldsName.firstName]: {
        label: localization(`firstname_register`, `AuthenLabel`),
        placeholder: localization(`firstname_register`, `AuthenLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_firstname,
        value: defaultValues[fieldsName.firstName],
    },
    [fieldsName.lastName]: {
        label: localization(`lastname_register`, `AuthenLabel`),
        placeholder: localization(`lastname_register`, `AuthenLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_lastname,
        value: defaultValues[fieldsName.lastName],
    },
    [fieldsName.nationality]: {
        label: localization(`nationality`, `BookingLabel`),
        placeholder: localization(`nationality`, `BookingLabel`),
        type: inputType.SELECT,
        choices: nationalities.map((each) => ({ id: each.label, label: each.label })),
        // show: 'id',
        filter: true,
        validate: validateActions.user_nationality,
        value: dialingCode,
        md: 12,
        style: {
            default: {
                marginTop: '1px',
                paddingRight: '10px',
            },
            md: {
                paddingRight: '10px',
                marginTop: '1px',
            },
        },
        options: {
            selector: {
                label: {
                    textAlign: 'start',
                },
                style: {
                    backgroundColor: '#fff',
                    height: isSafari() ? 44 : 41,
                    borderRadius: '2px',
                },
                collapseStyle: {
                    position: 'relative',
                    width: '240px',
                },
                labelWrapperStyle: {
                    minWidth: '70px',
                },
            },
        },
    },
    [fieldsName.countryCode]: {
        label: localization(`dialingCode`, `ProfileLabel`),
        placeholder: '',
        type: inputType.SELECT,
        choices: countryCode,
        show: 'id',
        filter: true,
        validate: validateActions.user_countryCode,
        value: dialingCode,
        md: isMobile ? 4 : 2,
        style: {
            default: {
                marginTop: '1px',
            },
            md: {
                // paddingLeft: '20px',
                marginTop: '1px',
                maxWidth: '23%',
                flexBasis: '23%',
            },
        },
        options: {
            selector: {
                label: {
                    textAlign: 'center',
                },
                style: {
                    backgroundColor: '#fff',
                    height: isSafari() ? 44 : 41,
                    borderRadius: '2px',
                },
                collapseStyle: {
                    position: 'relative',
                    width: '285px',
                },
                labelWrapperStyle: {
                    minWidth: '70px',
                },
            },
        },
    },
    [fieldsName.tel]: {
        // label: localization(`tel`, `ProfileLabel`),
        placeholder: localization(`tel`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        md: isMobile ? 8 : 10,
        validate: validateActions.user_tel,
        value: defaultValues[fieldsName.tel],
        style: {
            md: {
                // paddingLeft: '20px',
                maxWidth: '76%',
                flexBasis: '76%',
            },
        },
    },
    [fieldsName.social]: {
        label: localization(`social`, `AuthenLabel`),
        placeholder: 'Please select social',
        type: inputType.SELECT,
        choices: mockOptions.socialOption,
        // validate: validateActions.user_tel,
        value: defaultValues[fieldsName.social],
        md: isMobile ? 8 : 6,
        style: {
            md: {
                paddingRight: '20px',
            },
        },
        options: {
            selector: {
                style: {
                    backgroundColor: '#fff',
                },
                collapseStyle: {
                    position: 'relative',
                },
            },
        },
        onClick: selectSocial,
    },
    [fieldsName.socialId]: {
        label: localization(`social_id`, `AuthenLabel`),
        placeholder: 'WhatsApp no. / Wechat ID / Line ID',
        type: inputType.TEXT_INPUT,
        validate: checkSocial === false ? null : validateActions.user_socialId,
        value: defaultValues[fieldsName.socialId],
        onPressEnter,
    },
    [fieldsName.isAcceptInformation]: {
        value: true,
        label: 'Interesting in special offer and discount coupon.',
        type: inputType.CHECKBOX,
        labelStyle: {
            color: '#7d7a7a',
            fontSize: '10px',
            marginLeft: '5px',
        },
        buttonRootStyle: {
            height: '24px',
            width: '24px',
        },
    },
});
