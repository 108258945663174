import { not, contains } from 'ramda';
import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';
import config from '../config';
import reduxPersist from '../config/reduxPersist';
// creates the store
export default (rootReducer, rootSaga) => {
    /* ------------- Redux Configuration ------------- */
    const middleware = [];
    const enhancers = [];
    const sagaMiddleware = createSagaMiddleware();
    middleware.push(thunk);
    middleware.push(sagaMiddleware);
    if (process.env.NODE_ENV === `development`) {
        const SAGA_LOGGER_BLACKLIST = ['EFFECT_TRIGGERED', 'EFFECT_RESOLVED', 'EFFECT_REJECTED'];
        // the logger master switch
        const USE_LOGGER = config.debugSetting.reduxLogger;
        // silence these saga-based messages
        // create the logger
        const logger = createLogger({
            predicate: (getState, { type }) => USE_LOGGER && not(contains(type, SAGA_LOGGER_BLACKLIST)),
        });
        middleware.push(logger);
    }
    else {
        console.log = () => null;
    }
    enhancers.push(applyMiddleware(...middleware));
    const persistedReducer = persistReducer(reduxPersist.storeConfig, rootReducer);
    const store = createStore(persistedReducer, compose(...enhancers));
    const persistor = persistStore(store);
    const { reducerVersion } = reduxPersist;
    localForage.getItem('reducerVersion').then((localVersion) => {
        if (localVersion !== reducerVersion) {
            persistor.purge().then(() => {
                localForage.setItem('reducerVersion', reducerVersion);
            });
        }
    }).catch(() => {
        localForage.setItem('reducerVersion', reducerVersion);
    });
    // kick off root saga
    sagaMiddleware.run(rootSaga);
    return { store, persistor };
};
