import React from 'react';
import Paper from "@material-ui/core/Paper";
import PanelHeader from "./PanelHeader";
import PanelContent from "./PanelContent";
const Panel = ({ children, style, isOpen, collapsible, isMobile }) => {
    const passProps = (element, key = '') => React.cloneElement(element, { isOpen, collapsible, key, isMobile });
    return (<Paper square style={style}>
            {Array.isArray(children) ?
        children.map((each, i) => passProps(each, i))
        :
            passProps(children)}
        </Paper>);
};
export default Panel;
export { PanelHeader, PanelContent, };
