import * as React from 'react';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import startUpService from "./services/startUpService";
import AppRouter from './pages/AppRouter';
import store, { persistor } from './redux';
import { Fonts, Colors } from "./themes";
const theme = createMuiTheme({
    typography: {
        fontFamily: [Fonts.family.avenir, Fonts.family.roboto].join(','),
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: { backgroundColor: Colors.theme },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
            // backgroundColor: lightBlue.A200,
            // color: 'white',
            },
        },
        MuiPickersDay: {
            day: { color: Colors.theme },
            isSelected: {
                'backgroundColor': Colors.theme,
                '&:hover': { backgroundColor: Colors.theme },
            },
            current: { color: Colors.theme },
        },
        MuiPickersModal: {
            dialogAction: { color: Colors.theme },
        },
        MuiIconButton: {
            edgeEnd: { marginRight: '-15px' }
        }
        // MuiPickersClock: {
        //     pin: {
        //         backgroundColor: Colors.theme,
        //     },
        // },
    },
});
const App = () => {
    return (<React.Fragment>
            <CssBaseline />
            <Provider store={store}>
                <PersistGate persistor={persistor} onBeforeLift={startUpService}>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <AppRouter />
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </React.Fragment>);
};
export default App;
