import * as React from 'react';
import { withStyles } from '@material-ui/styles';
import FlexView from 'react-flexview/lib';
import { path } from "ramda";
import Close from "@material-ui/icons/Close";
import { shadows } from '@material-ui/system';
import { Colors } from "../../themes";
const styles = ({
    wrapper: {
        border: `1px solid ${Colors.border}`,
        width: '100px',
        height: '100px',
        lineHeight: '130px',
        textAlign: 'center',
        backgroundColor: '#d8d8d8',
        // cursor: 'pointer',
        margin: '3px',
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: 3,
        right: 3,
        cursor: 'pointer',
        boxShadow: shadows[3],
        borderRadius: '50%',
        color: '#bbb',
        backgroundColor: '#fff',
    },
});
const PreviewImage = ({ onClick, src, alt = 'preview', classes, removable = true }) => {
    return (<FlexView vAlignContent='center' hAlignContent='center' 
    // onClick={onClick}
    className={classes.wrapper}>
            <img alt={alt} src={src} style={{
        width: '100%',
    }} onLoad={(e) => {
        const imgWidth = path(['target', 'naturalWidth'], e) || 0;
        const imgHeight = path(['target', 'naturalHeight'], e) || 0;
        const imgStyle = path(['target', 'style'], e);
        if (imgWidth > imgHeight) {
            imgStyle.width = '100%';
            imgStyle.height = '';
        }
        else {
            imgStyle.width = '';
            imgStyle.height = '100%';
        }
    }}/>
            {removable && (<Close onClick={onClick} className={classes.icon}/>)}
        </FlexView>);
};
export default withStyles(styles)(PreviewImage);
