import * as React from 'react';
import { withRouter } from 'react-router';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Fonts, Images } from "../../themes";
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuttleVan, faUserInjured, faCalendarAlt, faWalking, faBan, faHandPointRight, faUserFriends } from '@fortawesome/free-solid-svg-icons';
// import FontIcon from '../../components/FontIcon';
// import { } from '@fortawesome/free-brands-svg-icons';
import localization from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
import { isMobile, isTablet } from '../PackageInfoPanel/GetItemColsPerWidth';
let pageLabel;
const { reverse, ticket, lang, group, protect, car, insant, maximum, noRefund, traveler, calendra, van } = Images.package;
const iconData = [
    { iconId: '0', image: reverse, iconName: 'isReverse', icon: 'dollar-sign', isHighlight: true },
    { iconId: '1', image: ticket, iconName: 'isTicket', icon: 'ticket-alt', isHighlight: false },
    { iconId: '2', image: lang, iconName: 'isLang', icon: 'globe', isHighlight: false },
    { iconId: '3', image: group, iconName: 'isGroup', icon: 'users', isHighlight: false },
    { iconId: '4', image: protect, iconName: 'isProtect', icon: faUserInjured, isHighlight: false },
    { iconId: '5', image: car, iconName: 'isCar', icon: 'car', isHighlight: false },
    { iconId: '6', image: insant, iconName: 'isInsant', icon: faHandPointRight, isHighlight: false },
    { iconId: '7', image: maximum, iconName: 'isMaximum', icon: faUserFriends, isHighlight: false },
    { iconId: '8', image: noRefund, iconName: 'isNoRefund', icon: faBan, isHighlight: false },
    { iconId: '9', image: traveler, iconName: 'isTraveler', icon: faWalking, isHighlight: false },
    { iconId: '10', image: calendra, iconName: 'isCalendra', icon: faCalendarAlt, isHighlight: false },
    { iconId: '11', image: van, iconName: 'isVan', icon: faShuttleVan, isHighlight: false },
    { iconId: '12', image: 'check', iconName: 'isCraditFee', icon: 'check', isHighlight: true },
];
class ServiceIconPanel extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { InclusionActive: true };
    }
    render() {
        pageLabel = localization(wordingKeys.packageLabel);
        const { Icon } = this.props;
        const _isMobile = isMobile();
        return (<div>
                {(!!Icon) &&
            <ExpansionPanel defaultExpanded>
                        <ExpansionPanelDetails style={_isMobile ? style.PanelDetailsMobile : style.PanelDetails}>
                            <GridList cellHeight={25} cols={isTablet() ? 4 : ((_isMobile) ? 2 : 4)} style={style.GridListStyle}>
                                {MapIcons(iconData, Icon).map((result, i) => {
                const { isActive = false, iconName, image, icon, isHighlight } = result;
                return ((_isMobile) ?
                    <GridListTile key={i}>
                                                    <IconsContianer>
                                                        {(isHighlight) ?
                        <FontAwesomeIcon icon={icon} style={style.IconHighlightMobile}/> :
                        (Boolean(icon)) ?
                            <FontAwesomeIcon icon={icon} style={isActive ? style.IconAcitveMobile : style.IconDefaultMobile}/> :
                            <ImgIcons src={image} isActive={isActive} isMobile alt={iconName}/>}
                                                        <InclusionLable isMobile isInclude={isActive} isHighlight={isHighlight}>
                                                            {pageLabel[iconName]}
                                                        </InclusionLable>
                                                    </IconsContianer>
                                                </GridListTile> :
                    <GridListTile key={i}>
                                                    <IconsContianer>
                                                        {(isHighlight) ?
                        <FontAwesomeIcon icon={icon} style={style.IconHighlight}/> :
                        (Boolean(icon)) ?
                            <FontAwesomeIcon icon={icon} style={isActive ? style.IconAcitve : style.IconDefault}/> :
                            <ImgIcons src={image} isActive={isActive} alt={iconName}/>}
                                                        <InclusionLable isInclude={isActive} isHighlight={isHighlight}>
                                                            {pageLabel[iconName]}
                                                        </InclusionLable>
                                                    </IconsContianer>
                                                </GridListTile>);
            })}
                            </GridList>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>}
            </div>);
    }
}
function MapIcons(IconList, StatusList) {
    const Highligh = [];
    const ActiveList = [];
    const DefaultList = [];
    for (const item of IconList) {
        item.isActive = StatusList[0][item.iconName];
        switch (true) {
            case item.isHighlight:
                {
                    Highligh.push(item);
                }
                break;
            case item.isActive:
                {
                    ActiveList.push(item);
                }
                break;
            default: DefaultList.push(item);
        }
    }
    return Highligh.concat(ActiveList.concat(DefaultList));
}
export default withRouter(ServiceIconPanel);
const { avenir } = Fonts.family;
const IconsContianer = styled.div `
display: flex;`;
const InclusionLable = styled.label `
color: #4A4A4A;
font-size: 12px;
display: inline-grid;
margin-top: 9px;
align-item: center;
font-family: ${avenir};
${(props) => props.isMobile && css `font-size: 12.5px; letter-spacing: -0.94px;`}
${(props) => (props.isInclude === false) && css `color: #dddfe2;`}
${(props) => (props.isHighlight === true) && css `color: rgb(8, 160, 8);`}`;
const ImgIcons = styled.img `
margin-right: 5px;
width: 30px;
height: 25px;
object-fit: none;
padding-top: 6px;
opacity: 0.32;
${(props) => props.isActive && css `opacity: 1;`}
${(props) => (props.isMobile === false) && css `width: 20px;`}`;
const style = {
    PanelDetails: { display: 'flex', padding: '8px 0px 24px 24px' },
    PanelDetailsMobile: { display: 'flex', padding: '12px 0px 24px 10px' },
    GridListStyle: { width: '100%' },
    IconHighlightMobile: { fontSize: 14, color: '#08a008', margin: '9px 12px 0px 11px' },
    IconAcitveMobile: { fontSize: 14, color: '#4A4A4A', margin: '9px 12px 0px 11px' },
    IconDefaultMobile: { fontSize: 14, color: '#dddfe2', margin: '9px 12px 0px 11px' },
    IconHighlight: { fontSize: 14, color: '#08a008', margin: '9px 8px 0px 4px' },
    IconAcitve: { fontSize: 14, color: '#4A4A4A', margin: '9px 8px 0px 4px' },
    IconDefault: { fontSize: 14, color: '#dddfe2', margin: '9px 8px 0px 4px' }
};
