export default {
    login: 'Login',
    register: 'Register',
    forgot_password: 'Forgot password ?',
    password: 'Password',
    member_login: 'Member Login',
    continue_with_facebook: 'Continue with Facebook',
    sign_in_with_google: 'Sign in with Google',
    password_recovery: 'Password Recovery',
    back_to_login: 'Back to Login',
    reset_password: 'Reset Password',
    recovery_method: 'Recovery method will sent to your email',
    confirm_passord: 'Confirm password',
    login_to_get: 'Login to get member privilege or Continue as a guest',
    login_book: 'LOGIN',
    continue_guest: 'CONTINUE',
    firstname_register: 'First Name',
    lastname_register: 'Last Name',
    social: 'Social',
    social_id: 'Social Id',
};
