import React from 'react';
import ButtonBase from "@material-ui/core/ButtonBase";
import Text from "../text";
import Img from "../img";
import config from "../../config";
import { Images } from "../../themes";
import CustomLink from "../CustomLink";
import localization from "../../services/localization";
import TraceFacebookPixel, { TrackType } from '../TraceInfo/FacebookPixel';
const { line, facebook, instagram, googlePlus, youtube } = Images.landing.footer.social;
const { line: LinkLine, fb: LinkFaceBook, ig: LinkIG, youtube: LinkYoutube } = config.footer.link;
const socials = [
    {
        link: LinkLine,
        img: line,
    },
    {
        link: LinkFaceBook,
        img: facebook,
    },
    {
        link: LinkIG,
        img: instagram,
    },
    {
        link: '',
        img: googlePlus,
    },
    {
        link: LinkYoutube,
        img: youtube,
    },
];
const Social = ({ isMobile }) => {
    return (<div style={{ marginTop: isMobile ? 0 : 9 }}>
            <Text.Avenir size={16} color="#fff" block family="heavy" style={{
        marginBottom: 5.5,
        display: isMobile ? 'flex' : 'null',
        justifyContent: 'center',
    }}>
                {`${localization(`follow_us`, `LandingLabel`)} :`}
            </Text.Avenir>
            {socials.map((each, i) => (<ButtonBase key={i} onClick={() => TraceFacebookPixel({ trackType: TrackType.Contact })} style={{ borderRadius: '50%', marginRight: isMobile ? 9 : 7 }}>
                        <CustomLink href={each.link} target="_blank" rel="noopener">
                            <Img.Sharpen size={isMobile ? 47 : 33} alt={`social-${i}`} src={each.img}/>
                        </CustomLink>
                    </ButtonBase>))}
        </div>);
};
export default Social;
