import React, { Component } from 'react';
import { path } from "ramda";
import CreditCard from "./CreditCard";
import InternetBanking from "./InternetBanking";
import CounterAndAtm from "./CounterAndAtm";
import Panel, { PanelHeader, PanelContent } from "../Panel";
import Dialog from "../dialog";
// import { Metrics, Styles, Fonts } from "../../themes";
import { paymentMethod } from "../../constant";
import omiseServices from "../../services/omiseServices";
import localization from "../../services/localization";
import Alipay from './Alipay';
import config from '../../config';
import BillPayment from './BillPayment';
export const paymentChannel = (props) => {
    const channels = [
        {
            header: localization(`credit_card`, `BookingLabel`),
            content: <CreditCard formRef={path(['creditCardForm'], props)}/>,
            disabled: false,
            method: paymentMethod.Omise,
            subMethod: "card",
            show: true,
        },
        {
            header: 'Internet Banking',
            subHeader: '*Currency THB Only',
            content: <InternetBanking type={path(['type'], props)} handleChange={path(['handleChange'], props)}/>,
            disabled: true,
            method: paymentMethod.Omise,
            subMethod: paymentMethod.iBanking,
            show: !!config.isDevelop,
        },
        {
            header: localization(`counter_payment`, `BookingLabel`),
            content: <CounterAndAtm />,
            disabled: true,
            method: paymentMethod.Alienation,
            show: !!config.isDev,
        },
        {
            header: 'Alipay',
            subHeader: '*Currency THB Only',
            content: <Alipay />,
            disabled: true,
            method: paymentMethod.Omise,
            subMethod: paymentMethod.Alipay,
            show: !!config.isDevelop,
        },
        {
            header: 'Bill Payment',
            subHeader: '*Currency THB Only',
            content: <BillPayment />,
            disabled: true,
            method: paymentMethod.Omise,
            subMethod: paymentMethod.billPayment,
            show: !!config.isDevelop && path(['checkBill'], props),
        },
    ];
    // if (props.isLoggedIn) {
    // channels.push({
    //     header: localization('counter_payment'),
    //     content: <CounterAndAtm />,
    //     disabled: true,
    //     method: paymentMethod.Alienation,
    // });
    // }
    return channels;
};
class PaymentChannel extends Component {
    constructor(props) {
        super(props);
        this.checkCurrency = null;
        if (typeof props.paymentRef === 'function') {
            props.paymentRef(this);
        }
        const expanded = paymentChannel().findIndex((each) => !each.disabled);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            expanded,
            type: null,
            disable: false,
        };
    }
    handleExpand(expanded) {
        const { currency } = this.props;
        let nextExpanded = expanded;
        if (this.state.expanded === expanded) {
            nextExpanded += currency === 'THB' ? 1 : 2;
            nextExpanded = !!paymentChannel({ isLoggedIn: this.props.isLoggedIn })[nextExpanded] ? nextExpanded : 0;
        }
        this.setState({ expanded: nextExpanded }, () => {
            if (currency !== 'THB') {
                if (expanded === 1 || expanded === 3 || expanded === 4) {
                    this.checkCurrency.ask({
                        title: localization(`currency_support`, `BookingLabel`),
                        actions: [
                            {
                                label: localization(`ok`, `DialogLabel`),
                            },
                        ],
                    });
                    switch (expanded) {
                        case 1:
                            return (this.setState({ expanded: 0 }));
                        case 3:
                        case 4:
                            return (this.setState({ expanded: 2 }));
                    }
                }
            }
        });
    }
    handleChange(selected) {
        this.setState({ type: selected });
        this.forceUpdate();
    }
    async submit() {
        const { expanded } = this.state;
        const { summary } = this.props;
        const paymentChannelRef = paymentChannel({ isLoggedIn: this.props.isLoggedIn });
        const amount = (parseInt(`${(summary * 100).toFixed(2)}`));
        // console.log('amount : ', amount);
        if (paymentChannelRef[expanded].method === paymentMethod.Omise) {
            switch (paymentChannelRef[expanded].subMethod) {
                case paymentMethod.Card: {
                    const { success, data } = this.creditCardForm.submit();
                    if (success) {
                        const payload = {
                            name: data.name,
                            number: data.number,
                            expiration_month: data.expire.split('/')[0],
                            expiration_year: data.expire.split('/')[1],
                            security_code: data.security_code,
                        };
                        return await omiseServices.createOmiseTokenRequest(payload);
                    }
                }
                case paymentMethod.iBanking: {
                    const payload = { amount, currency: "THB" };
                    const type = this.state.type;
                    return await omiseServices.createOmiseSourceRequest(type, payload);
                }
                case paymentMethod.Alipay: {
                    const payload = { amount, currency: "THB" };
                    const type = 'alipay';
                    return await omiseServices.createOmiseSourceRequest(type, payload);
                }
                case paymentMethod.billPayment: {
                    const payload = { amount, currency: "THB" };
                    const type = 'bill_payment_tesco_lotus';
                    return await omiseServices.createOmiseSourceRequest(type, payload);
                }
            }
        }
        else if (paymentChannelRef[expanded].method === paymentMethod.Alienation) {
            return { success: true };
        }
        return { success: false };
    }
    render() {
        const { expanded } = this.state;
        const { isMobile, currency, bookingInfo } = this.props;
        const toDay = new Date();
        const travelDate = new Date(bookingInfo.date);
        const bookingDate = new Date(toDay.getTime() + 86400000);
        const checkBill = Date.parse(`${bookingDate}`) < Date.parse(`${travelDate}`);
        return (<React.Fragment>
                <Dialog.Info ref={(ref) => this.checkCurrency = ref}/>
                <Panel isMobile={isMobile}>
                    <PanelHeader>
                        {localization(`select_payment_channel`, `BookingLabel`)}
                    </PanelHeader>
                </Panel>
                {paymentChannel({
            creditCardForm: (ref) => this.creditCardForm = ref,
            isLoggedIn: this.props.isLoggedIn,
            type: this.state.type,
            handleChange: this.handleChange,
            currency,
            checkBill,
        }).map((each, i) => {
            if (each.show === false) {
                return null;
            }
            return (<Panel collapsible isOpen={expanded === i} key={i} isMobile={isMobile}>
                                <PanelHeader onClick={() => {
                this.handleExpand(i);
            }} style={{ height: 40, borderBottom: '1px solid #ccc' }} textStyle={{ fontSize: isMobile ? 16 : 17, display: 'flex' }}>
                                    {each.header}
                                    {currency !== 'THB' ?
                <div style={{
                    fontSize: isMobile ? 14 : 15, marginLeft: 20,
                    marginTop: 2, color: '#e6d910',
                }}>
                                            {each.subHeader}
                                        </div> : null}
                                </PanelHeader>
                                <PanelContent>
                                    {each.content}
                                </PanelContent>
                            </Panel>);
        })}
            </React.Fragment>);
    }
}
export default PaymentChannel;
