export default {
    confirm_payment: 'ยืนยันการชำระเงิน',
    booking_no: 'หมายเลขการจอง',
    booking_status: 'สถานะการจอง',
    payment_to_bank: 'ธนาคารที่ชำระเงิน',
    amount_of_money: 'จำนวนเงิน',
    time: 'เวลา',
    note: 'หมายเหตุ',
    // bookingSuccess
    open_ticket: 'ดูเอกสารการจอง',
    manage_my_booking: 'จัดการแก้ไข/แก้ไข การจอง',
    your_booking_success: 'การจองเสร็จสมบูรณ์ !',
    your_booking_incomplete: 'การจองไม่สมบูรณ์ !',
    your_booking_inprocess: 'การจองอยู่ระหว่างดำเนินการ !',
    invalid_security_code: 'รหัสความปลอดภัยไม่ถูกต้อง',
    go_landing_page: 'กลับสู่หน้าหลัก',
    // bookingPreview
    booking_preview: 'ตรวจสอบการจอง',
    travel_time: 'เวลาเดินทาง',
    number_of_adults: 'จำนวนเดินทาง (ผู้ใหญ่)',
    number_of_kid: 'จำนวนเดินทาง (เด็ก)',
    number_of_infant: 'จำนวนเดินทาง (ทารก)',
    change_date_detail: 'เปลี่ยนแปลงวันเดินทางหรือรายละเอียดอื่นๆ',
    change_special: 'เปลี่ยนแปลงจำนวนผู้เดินทาง',
    confirm: 'ยืนยัน',
    quantity_of: 'จำนวน',
    passengers: 'จํานวนผู้โดยสารสูงสุด',
    transport_type: 'ประเภทของรถ',
    special_request: 'ความต้องการพิเศษ',
    person: 'คน',
    time_slot: 'ช่วงเวลา',
    vehicle: 'คัน',
    total_price: 'ราคารวม',
    thb: 'บาท',
    // paymentDetail
    customer_information: 'ข้อมูลลูกค้า',
    additional_information: 'ข้อมูลเพิ่มเติม',
    select_payment_channel: 'เลือกช่องทางการชำระเงิน',
    credit_card: 'บัตรเครดิต / บัตรเดบิต',
    counter_payment: 'ชำระเงินผ่านธนาคาร',
    use_my_account: 'ใช้ข้อมูลของบัญชีในการกรอกข้อมูล',
    are_your_booking: `รายละเอียดข้อมูลท่านถูกต้องหรือไม่ ?`,
    our_eticket: `เอกสารยืนยันการจองจะส่งไปที่`,
    its_appointment: `สำหรับการนัดหมายเจ้าหน้าที่จะโทรยืนยันรายละเอียดตามเบอร์`,
    please_confirm: `กรุณาตรวจสอบข้อมูลของท่านให้ถูกต้องเพื่อการจองที่สมบูรณ์`,
    change: `แก้ไข`,
    correct: `ยืนยัน`,
    firstname_info: 'ชื่อ (ตามเอกสารการเดินทาง)',
    lastname_info: 'นามสกุล (ตามเอกสารการเดินทาง)',
    name_placeholder: 'ตามที่ปรากฏในหนังสือเดินทาง',
    currency_support: 'ในขณะนี้สกุลเงินที่รองรับคือบาทเท่านั้น',
    home_page: 'หน้าหลัก',
    payment_instructions: 'ส่งคำแนะนำการชำระเงินไปยังอีเมลของคุณแล้ว',
    payment_before: 'ชำระเงินก่อน',
    transfer_to: 'กรุณาโอนไปที่',
    complete_payment: 'ชำระเงินให้เสร็จสิ้นภายใน',
    payment_to: 'ชำระเงินกับเทสโก้โลตัส',
    booking_detail: 'รายละเอียดการจอง',
    completed_payment: 'เสร็จสิ้นการชำระเงิน',
    bill_payment: 'Bill Payment',
    condition_bill: 'เทสโก้โลตัสมีการคิดค่าธรรมเนียมการชำระเงินรายการละ 10 บาท',
    for_payment: 'สำหรับการชำระเงินผ่านตัวแทนในประเทศไทยด้วยสกุล เงินไทยบาทเท่านั้น',
    processing_fee: 'ค่าธรรมเนียมช่องทางจะถูกเรียกเก็บต่อรายการ',
    email_confirm: 'ท่านจะได้รับ Email และ SMS ยืนยันการจองเมื่อทำการชำระเงินสำเร็จ',
    to_avoid: 'เพื่อหลีกเลี่ยงรายการหมดเวลา',
    must_register: `ท่านต้องสมัครบริการ Internet Banking ผ่านช่องทางของธนาคารที่ท่านเลือกก่อน`,
    fee: 'ค่าธรรมเนียมการดำเนินการ',
    select_bank: 'เลือกธนาคารที่ต้องการ',
    hours: 'ชั่วโมง',
    minutes: 'นาที',
    step: 'ขั้นตอน',
    step1_ibanking: 'เลือกธนาคารที่ต้องการและคลิก "จองตอนนี้" เพื่อยืนยันการจองของคุณ',
    step2_ibanking: 'กรอกรหัสผ่านและชำระเงินที่หน้าเว็บไซต์ธนาคาร และกดปุ่ม Back to Merchant เพื่อกลับมาที่หน้ายืนยันการจองของทราวิสโก้',
    step3_ibanking: 'รับหมายเลขยืนยันการจองผ่านอีเมล และ SMS',
    // additional
    pick_up_detail: 'รายละเอียดสถานที่รับ',
    drop_off_detail: 'รายละเอียดสถานที่ส่ง',
    pick_up: 'สถานที่รับ',
    drop_off: 'สถานที่ส่ง',
    pick_up_note: 'ที่อยู่ที่คุณต้องการให้เราไปรับ',
    where_you_want_pick_up: 'ที่อยู่ที่คุณต้องการให้เราไปรับ แบบละเอียด',
    where_you_want_drop_off: 'ที่อยู่ที่คุณต้องการให้เราไปส่ง แบบละเอียด',
    arrival_airline: 'ชื่อสายการบิน',
    arrival_flight_no: 'หมายเลขเที่ยวบิน',
    arrival_time: 'เวลาถึง',
    departure_airline: 'ชื่อสายการบิน',
    departure_flight_no: 'หมายเลขเที่ยวบิน',
    departure_time: 'เวลาออกเดินทาง',
    other: 'อื่นๆ',
    book: 'จองตอนนี้',
    next: 'ถัดไป',
    placeholder_location: 'เลือกโซนโรงแรมของคุณ',
    placeholder_note: 'ระบุชื่อโรงแรม และที่อยู่',
    placeholder_request: 'เลือกความต้องการพิเศษ',
    please_explain: 'ความต้องการเพิ่มเติมของคุณ เช่น โรคประจำตัว อาหารที่แพ้',
    more_detail: 'รายละเอียดเพิ่มเติม',
    please_input_time: 'กรุณาเลือกเวลา',
    roomNo: 'หมายเลขห้อง',
    nationality: 'สัญชาติ',
    // credit/debit
    name_on_card: 'ชื่อหน้าบัตร',
    credit_card_number: 'หมายเลขบัตร (16 หลัก)',
    expire: 'วันหมดอายุ',
    security_code: 'รหัสความปลอดภัย (3 หลัก)',
    conditions: 'เงื่อนไข',
    you_can_pay: 'สามารถชำระเงินด้วย',
    data_security: 'ความปลอดภัยของข้อมูลส่วนตัว',
    full_payment: 'การชำระเงินจำนวนเต็มด้วยบัตรเครดิตจะมีค่าธรรมเนียมขึ้นอยู่กับการจอง',
    our_reservation: 'การจองของเรามีความปลอดภัย',
    by_choosing_to_book: 'การคลิก "จองตอนนี้” ถือได้ว่าท่านยืนยันว่าเข้าใจและยอมรับใน ข้อกำหนดและเงื่อนไขของทราวิสโก้ และ นโยบายความเป็นส่วนตัว',
    when_paying: 'เมื่อคุณชำระเงินด้วยบัตรเครดิต บัตรของคุณจะถูกเรียกเก็บเงินทันทีที่ได้รับการยืนยันการจอง',
    if_confirmation: `กรณีการจองของคุณยังไม่ได้รับการยืนยัน ระบบจะยังไม่เรียกเก็บเงินจากบัตร`,
    if_you: 'กรณีมีการแก้ไข เปลี่ยนแปลงการจอง อาจจะมีการเรียกเก็บค่าธรรมเนียมเพิ่มเติม',
    your_info: `ข้อมูลของคุณปลอดภัยภายในระบบทราวิสโก้ ข้อมูลทั้งหมดจะ`,
    encrypted: 'ถูกเข้ารหัสอย่างปลอดภัย',
    with_protocal: 'ด้วยโปรโตคอล SSL',
    travisgo: 'ทราวิสโก้',
    respect: 'เคารพความเป็นส่วนตัวของคุณ',
    we_do_not: 'เราไม่ได้เปิดเผยข้อมูลส่วนบุคคลของลูกค้า',
    travisgo_terms: `ข้อกำหนดและเงื่อนไขของทราวิสโก้ `,
    and: ' และ ',
    privacy_statement: 'นโยบายความเป็นส่วนตัว',
    detail: 'รายละเอียด ',
};
