import * as React from 'react';
import { Styles } from "../themes";
const Divider = ({ text = '', style = {} }) => {
    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        height: '10px',
        justifyContent: 'center',
        position: 'relative',
        ...style,
    }}>
            <div style={{
        backgroundColor: 'rgba(151,151,151,0.64)',
        bottom: 0,
        height: '1px',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    }}/>
            <div style={{
        backgroundColor: '#fff',
        color: '#7d7a7a',
        fontSize: '11px',
        padding: !!text ? '5px' : '',
        zIndex: 1,
        ...Styles.unselected,
    }}>
                {text}
            </div>
        </div>);
};
export default Divider;
