import * as React from 'react';
import { withRouter } from 'react-router';
import { compose } from "recompose";
import { connect } from "react-redux";
import { path } from "ramda";
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { reduxKeys } from "../../constant";
import { Images, Fonts, Colors, Svg } from '../../themes';
import { DisplayDateFormat } from '../../components/common/SetDate';
import config from '../../config';
import styled from 'styled-components';
import Text from '../../components/text';
import Avatar, { ConfigProvider } from 'react-avatar';
import { reverse, dropLast } from 'ramda';
import { withStyles } from '@material-ui/styles';
import { shadows } from '@material-ui/system';
import LinearProgress from '@material-ui/core/LinearProgress';
import localization from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
import { isMobile } from '../PackageInfoPanel/GetItemColsPerWidth';
import { FakeBookedInfoList } from '../../constant/mockupData/FakeBookedInfoList';
let pageLabel;
const { up, down } = Images.package;
const style = {
    root: {
        flexGrow: 1,
        height: 6,
        borderRadius: 50,
    },
    colorPrimary: {
        backgroundColor: '#ccc',
    },
    barColorPrimary: {
        backgroundColor: Colors.theme,
    },
};
function ReviewListPanel(props) {
    // console.log(`ReviewListPanel : `, props);
    pageLabel = localization(wordingKeys.packageLabel);
    const isMobiles = isMobile();
    const { limit, onChangeLoadMore } = props;
    const faker = FakeBookedInfoList.filter((each) => each.packageId === props.packageList.packageId) || [];
    const fakerReview = path(['0', 'reviews'], faker) || 0;
    const fakserPoint = path(['0', 'point'], faker) || 0;
    const reviewList = props.package.reviews;
    const sumPoint = fakserPoint + reviewList.length;
    const sortReview = reviewList.slice(0, limit);
    const summary = [0, 0, 0, 0, 0, 0];
    props.package.reviews.forEach((each) => summary[each.point]++);
    return (<PanelContainer>
            <TopicsContainer>
                {`${pageLabel[wordingKeys.ReviewsTopics]} ( ${sumPoint || reviewList.length}
                    ${pageLabel[wordingKeys.ReviewPackageTopics]} )`}
            </TopicsContainer>
            <div style={{
        padding: 20, borderBottom: '1px solid rgb(223, 223, 223)',
    }}>
                {dropLast(1, reverse(summary)).map((each, index) => {
        const result = reverse(Object.values(fakerReview))[index] + each || 0;
        return (<div key={index} style={{ display: 'block' }}>
                            <div style={{ float: 'left', width: isMobiles ? '23%' : '13%' }}>
                                <Rating key={index} emptySymbol={<Svg.Landing.Star color={'#9d9d9d'}/>} fullSymbol={<Svg.Landing.Star />} initialRating={5 - index} readonly/>
                            </div>
                            <div style={{ display: 'inline-block', width: isMobiles ? '65%' : '74%' }}>
                                <LinearProgress value={((result || each) / (sumPoint || reviewList.length)) * 100 || 0} variant="determinate" classes={{
            root: props.classes.root,
            colorPrimary: props.classes.colorPrimary,
            barColorPrimary: props.classes.barColorPrimary,
        }}/>
                            </div>
                            <div style={{ float: 'right', width: '10%', color: '#5b5b5b' }}>
                                {result || each}
                            </div>
                        </div>);
    })}
            </div>
            {sortReview.map((review, index) => (<ReviewItem key={index} review={review} index={index} onChangeLightBox={props.onChangeLightBox} currentImage={props.currentImage} gotoPrevious={props.gotoPrevious} gotoNext={props.gotoNext}/>))}
            {((reviewList.length > limit) && (limit !== 5)) ?
        <div style={{ display: 'flex', justifyContent: 'center', background: '#ebedf0' }}>
                    <Text.Avenir style={TextAvatarStyle} onClick={() => onChangeLoadMore(limit + 5)}>
                        <img src={down} style={iconLoad} alt=''/>
                        {pageLabel[wordingKeys.LoadMore]}</Text.Avenir>
                    {' | '}
                    <Text.Avenir style={TextAvatarStyle} onClick={() => onChangeLoadMore(5)}>
                        <img src={up} style={iconLoad} alt=''/>
                        {pageLabel[wordingKeys.HideReview]}</Text.Avenir>
                </div>
        :
            (reviewList.length > limit) ?
                <Text.Avenir style={TextAvatarStyle} onClick={() => onChangeLoadMore(limit + 5)}>
                        <img src={down} style={iconLoad} alt=''/>
                        {pageLabel[wordingKeys.LoadMore]}</Text.Avenir>
                :
                    ((reviewList.length < limit || reviewList.length === limit)
                        && (reviewList.length !== 0) && reviewList.length > 5) ?
                        <Text.Avenir style={TextAvatarStyle} onClick={() => onChangeLoadMore(5)}>
                            <img src={up} style={iconLoad} alt=''/>
                            {pageLabel[wordingKeys.HideReview]}</Text.Avenir> : null}
        </PanelContainer>);
}
function ReviewItem(props) {
    const isMobiles = isMobile();
    const { review, index } = props;
    const { customerName, customerAvatarLink, point, imageName, packageReviewId } = review;
    return (<ReviewItemContainer key={index}>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 0.1, width: 40, margin: '0px 10px 5px 0px' }}>
                    {Boolean(customerAvatarLink) ?
        <Avatar src={`${config.host}${customerAvatarLink}`} style={styles.avatar} size="37px" round={true}/>
        :
            <ConfigProvider colors={Colors.colorsAvatar}>
                            <div style={{ fontSize: '40%' }}>
                                <Avatar round={true} style={styles.avatar} textSizeRatio={1.5} maxInitials={1} value={imageName} size="37px"/>
                            </div>
                        </ConfigProvider>}
                </div>
                <div style={{ flex: 5 }}>
                    <CustomerNameLable>{customerName}</CustomerNameLable>
                    <div>
                        <Rating emptySymbol={<Svg.Landing.Star color={'#9d9d9d'}/>} fullSymbol={<Svg.Landing.Star />} initialRating={point} readonly/>
                    </div>
                </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                    <DateLable>{DisplayDateFormat(review.createDate)}</DateLable>
                </div>
            </div>
            <div style={{ paddingTop: 6 }}>
                <ReviewWording>{review.reviewDetails}</ReviewWording>
            </div>
            <div key={index} style={{ paddingTop: 6, display: 'flex', flexWrap: 'wrap' }}>
                {!!Array.isArray(review.images) ?
        review.images.map((img, i) => {
            return (<div key={i}>
                                <a href={`${config.host}${img}`} key={i} onClick={(e) => props.onChangeLightBox(i, packageReviewId, true, e)}>
                                    <img src={`${config.host}${img.thumbnail}`} style={{ paddingRight: 10, width: isMobiles ? 80 : 100 }}/>
                                </a>
                            </div>);
        })
        : null}

            </div>
        </ReviewItemContainer>);
}
ReviewListPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStatetoProps = (state) => ({ [reduxKeys.package]: state[reduxKeys.package] });
export default compose(connect(mapStatetoProps), withRouter, withStyles(style))(ReviewListPanel);
const TextAvatarStyle = {
    display: 'flex', background: '#ebedf0',
    padding: '0 20px', justifyContent: 'center',
    cursor: 'pointer', color: '#4A4A4A', fontSize: '10px', lineHeight: '25px',
};
const iconLoad = { objectFit: 'none', marginRight: 5 };
const PanelContainer = styled.div `
width: 100%;
height:100%;
margin-top:15px;
background-color: #FFFFFF;
box-shadow: 0 0 10px 0 rgba(126,126,126,0.5);}`;
const TopicsContainer = styled.div `
width: 100%;
display: flex;
background-color: #FFFFFF;
box-shadow: rgba(0, 0, 0, 0.18) 0px 0px 4px 0px;
padding: 7px 24px;
font-family: ${Fonts.family.avenir};
font-size: 14px;
color: #4A4A4A;
line-height: 19px;`;
const ReviewItemContainer = styled.div `
width: 100%;
display: grid;
padding: 20px;
border-bottom: 1px solid rgb(223, 223, 223);`;
const DateLable = styled.label `
height: 16px;
width: 70px;
color: #828282;
font-family: ${Fonts.family.avenir};
float: right;
font-size: 12px;
font-weight: 500;
line-height: 16px;}`;
const CustomerNameLable = styled.label `
height: 22px;
width: 99px;
color: #5B5B5B;
font-family: ${Fonts.family.avenirHeavy};
font-size: 16px;
line-height: 22px;`;
const ReviewWording = styled.label `
height: 33px;
width: 635px;
color: #5B5B5B;
font-family: ${Fonts.family.avenir};
font-size: 12px;
font-weight: 500;
line-height: 16px;`;
const styles = {
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '50%',
        boxShadow: shadows[2],
        position: 'relative',
        background: 'transparent',
        fontFamily: `${Fonts.family.avenir}`,
    },
};
// const styles = (theme) => ({
//     root: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'space-around',
//         overflow: 'hidden',
//         background: theme.palette.background.paper,
//     },
//     gridList: {
//         width: '100%',
//         height: 500,
//         overflow: 'hidden',
//     },
//     subheader: {
//         width: '100%',
//     },
// });
