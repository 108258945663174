import React, { Component, Fragment } from 'react';
import Rating from 'react-rating';
// import moment from 'moment';
import LazyLoad from 'react-lazyload';
import { withRouter } from 'react-router';
import { pathName, packageType, promotionType, language as constLaguage } from "../../constant";
import config from '../../config';
import { isSafari } from './GetItemColsPerWidth';
import WishListIconPanel from './WishListIconPanel';
import { GetSymbol, Cuurency } from '../../components/common/GetSymbol';
import { ConveyanceType } from '../../containers/Search/FilterTypeInfo';
import { NumberFormat, SortLargeNumber } from '../../components/common/SetNumberFormat';
import TraceFacebookPixel, { TrackType } from '../../components/TraceInfo/FacebookPixel';
import { SetFakeReviewWithPackageId } from './PackageFakeBooked';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUserFriends } from '@fortawesome/free-solid-svg-icons';
// import { } from '@fortawesome/free-brands-svg-icons';
import './Card.css';
import { Images, Fonts, Svg, Colors } from '../../themes';
import styled, { css } from 'styled-components';
// import { Images, Metrics, Colors } from '../../themes';
// import { GetItemColsPerWidth, isMobile } from './GetItemColsPerWidth';
import localization, { isLanguage } from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
let packageLabel;
const { isPhiPhi, host } = config;
const ActiveBags = false;
const hostName = host;
const { bags, sale } = Images.package;
// const { ratingActive, ratingDefault, duration, bestSeller, passengers, bags, sale } = Images.package;
class NewPackagesCardMobile extends Component {
    constructor(props) {
        super(props);
        this.onChoosePackage = this.onChoosePackage.bind(this);
    }
    componentDidMount() {
        document.documentElement.style.setProperty('--main-color', Colors.theme);
    }
    render() {
        // console.log('NewPackagesCardMobile : ', this.props.packageInfo);
        packageLabel = localization(wordingKeys.packageLabel);
        const { packageInfo, cardSize } = this.props;
        const { packageId, type, isBestSeller } = packageInfo;
        const { name } = packageInfo.packageInfo;
        const newSetInfo = { packageId, type, name };
        return (<CradContainer isMobile isSmall={cardSize}>
                {this.imagePanel(packageInfo, newSetInfo)}
                {this.packageNamePanel(name)}
                {this.infoPanel(packageInfo)}
                {this.ratingPanel(packageInfo, isBestSeller)}
            </CradContainer>);
    }
    onChoosePackage() {
        const { packageInfo, history, isRecomand } = this.props;
        const { packageId, type } = packageInfo;
        const { name } = packageInfo.packageInfo;
        const _newSetInfo = { packageId, type, name };
        const locale = isLanguage();
        const hash = history.location.hash || '';
        // TraceFacebookPixel({ trackType: TrackType.AddToCart, data: _newSetInfo })
        TraceFacebookPixel({ trackType: TrackType.ViewContent, data: _newSetInfo });
        history.push({ pathname: pathName.CHOOSE_PACKAGE, state: { packageInfo: [packageInfo], locale }, search: `${packageId}&language=${isLanguage()}`, hash });
        (isRecomand) && window.scrollTo(0, 0);
    }
    imagePanel(_package, _newSetInfo) {
        const { name, image, promotionInfo, price } = _package.packageInfo;
        const { thumbnailMobile, thumbnail } = image;
        const { isPromotion } = _package;
        return (<div className="item" style={{ height: 181 }}>
                <ImageContianer>
                    <LazyLoad height={100}>
                        <img src={`${hostName}${thumbnailMobile || thumbnail}`} width='100%' alt={name} height={181} onClick={this.onChoosePackage}/>
                    </LazyLoad>
                    {(isPromotion && Boolean(promotionInfo)) && this.promotionContainer(promotionInfo, price)}
                    <WishListIconPanel packageInfo={_newSetInfo}/>
                </ImageContianer>
            </div>);
    }
    packageNamePanel(name) {
        return (<PackageNameContianer onClick={this.onChoosePackage}>
                <PackageName>{name}</PackageName>
            </PackageNameContianer>);
    }
    infoPanel(_package) {
        const { type } = _package;
        const isTranfers = (type === packageType.Transfer);
        return (<Fragment>
                <PackageDetialContianer isTranfers={isTranfers} onClick={this.onChoosePackage}>
                    {(isTranfers) ?
            this.TransferInfoPanel(_package) :
            this.OneDayTripsInfoPanel(_package)}
                </PackageDetialContianer>
            </Fragment>);
    }
    OneDayTripsInfoPanel(_package) {
        const { scheduleTime } = _package.packageInfo;
        return (<div style={{ flex: 1 }}>
                <BookedInfoPanel _package={_package}/>
                <DetialItemsContianer>
                    
                    <FontAwesomeIcon icon={faClock} style={durationFont}/>
                    <DetialLabel>{this.getDurationTime(scheduleTime)}</DetialLabel>
                </DetialItemsContianer>
            </div>);
    }
    TransferInfoPanel(_package) {
        const { scheduleTime, transferType, language } = _package.packageInfo;
        const { bags: infoBags, conveyanceType: infoConveyance, passengers: infoPassengers } = transferType;
        const _isTHLanguage = (language === constLaguage.TH);
        const _passengersWording = (_isTHLanguage) ?
            `${packageLabel[wordingKeys.Passengers]} ${infoPassengers || 0} ${packageLabel[wordingKeys.PassengersUnit]}`
            : `${infoPassengers || 0} ${packageLabel[wordingKeys.Passengers]}`;
        return (<div style={{ flex: 1 }}>
                <DetialItemsContianer isTranfers>
                    
                    <FontAwesomeIcon icon={faClock} style={durationFont}/>
                    <DetialLabel>{this.getDurationTime(scheduleTime)}</DetialLabel>
                </DetialItemsContianer>
                {(infoConveyance !== ConveyanceType.Ferries) &&
            <Fragment>
                        <DetialItemsContianer isTranfers>
                            
                            <FontAwesomeIcon icon={faUserFriends} style={passengersFont}/>
                            <DetialLabel>{_passengersWording}</DetialLabel>
                        </DetialItemsContianer>
                        {(ActiveBags) &&
                <DetialItemsContianer isTranfers>
                                <img src={bags} className="icon" alt='bags' style={durationIcon}/>
                                <DetialLabel>
                                    {`${infoBags || 0} ${packageLabel[wordingKeys.StandardBags]}`}</DetialLabel>
                            </DetialItemsContianer>}
                    </Fragment>}
            </div>);
    }
    ratingPanel(_package, isBestSeller) {
        const { price, promotionInfo } = _package.packageInfo;
        const { isPromotion, type } = _package;
        const isPrice = (isPromotion) ? promotionInfo : price;
        const isTranfers = (type === packageType.Transfer);
        return (<Fragment>
                <RatingContianer isTranfers={isTranfers} onClick={this.onChoosePackage}>
                    <div style={{ marginLeft: '13px', marginTop: '-15px' }}>
                        <BookedInfoPanel isTranfers={isTranfers} _package={_package} isEmpty={!isTranfers}/>
                        {(isBestSeller) ? <Svg.Landing.Bestseller /> : <EmptyImgBestSeller />}
                        
                    </div>
                    <div style={{ textAlign: 'right', padding: '0px 13px 0px 0px', marginTop: '-2px' }}>
                        
                    </div>
                </RatingContianer>
                <div style={{ textAlign: 'right', padding: '0px 13px 0px 0px', marginTop: '-52px' }}>
                    {this.PricePanel({ isPromotion, price, isPrice, promotionInfo, isTranfers, isBestSeller })}
                </div>
            </Fragment>);
    }
    PricePanel(info) {
        const { isPromotion, price, isPrice, promotionInfo, isTranfers } = info;
        const { currency, adult } = isPrice;
        const isCuurencyTH = (currency === Cuurency.THB);
        return (isPromotion) ?
            <PricePanelCotianer>
                <RealPriceLabel> {`${GetSymbol(currency)} ${NumberFormat(isCuurencyTH ? parseInt(adult) : adult)}`}</RealPriceLabel>
                {isPromotion && this.promotionInfo(price, promotionInfo)}
            </PricePanelCotianer> :
            <RealPriceCotianer isTranfers={isTranfers}>
                <RealPriceLabel> {`${GetSymbol(currency)} ${NumberFormat(adult)}`}</RealPriceLabel>
            </RealPriceCotianer>;
    }
    getDurationTime(scheduleTime) {
        const { start, unit } = scheduleTime;
        // Boolean(end) ? `${packageLabel[wordingKeys.Duration]} ${start} - ${end}`
        // : `${packageLabel[wordingKeys.Duration]} ${start || '-'} ${packageLabel[unit] || ''}`;
        return `${packageLabel[wordingKeys.Duration]} ${start || '-'} ${packageLabel[unit] || ''}`;
    }
    promotionInfo(_price, _promotionInfo) {
        const { type, adult, currency } = _promotionInfo;
        const _discount = (type === promotionType.Percent) ?
            ` -${_promotionInfo['percent-adult']} %` : ` ${GetSymbol(currency)} -${NumberFormat(_price.adult - adult)}`;
        return (<PromotionInfoCotianer>
                <PriceCossLabel>
                    <CossPrice>{`${GetSymbol(currency)} ${NumberFormat(_price.adult)} `}</CossPrice>
                </PriceCossLabel>
                <PriceLabel>
                    {_discount}
                </PriceLabel>
            </PromotionInfoCotianer>);
    }
    promotionContainer(_promotion, _price) {
        const { type, adult, currency } = _promotion;
        const _isPercent = (type === promotionType.Percent);
        const _discount = (_isPercent) ?
            ` ${Number.parseInt(_promotion['percent-adult'])}%`
            : ((currency === Cuurency.THB) ?
                `${(_price.adult - adult).toFixed(0)} ${GetSymbol(currency)}`
                : `${NumberFormat(_price.adult - adult)} ${GetSymbol(currency)}`);
        const _is2Digit = (_isPercent && (_promotion['percent-adult'] > 9));
        return (isPhiPhi) ?
            (<Fragment>
                <BubbleContianer isPhiPhi>
                    <BubbleFlex>
                        <BubbleLeft />
                        <BubbleRight>
                            <Svg.packageCard.Sale height={88}/>
                            <PromotionContianer className="promotinPrice" is2Digit={_is2Digit} isPhiPhi>
                                <DiscountLabel isPercent={_isPercent}>{_discount}</DiscountLabel>
                                <PromotionOFF>{`OFF`}</PromotionOFF>
                            </PromotionContianer>
                        </BubbleRight>
                    </BubbleFlex>
                </BubbleContianer>
            </Fragment>) :
            (<Fragment>
                <BubleContianer>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ flex: 1 }}></div>
                        <div style={{ flex: 0.4 }}>
                            <img src={sale} alt='sale' width='66.79px' height='74.75px'/>
                            <PromotionContianer className="promotinPrice" is2Digit={_is2Digit}>
                                <DiscountLabel isPercent={_isPercent}>{_discount}</DiscountLabel>
                            </PromotionContianer>
                        </div>
                    </div>
                </BubleContianer>
            </Fragment>);
    }
}
export default withRouter(NewPackagesCardMobile);
// ================================================================================================================
const BookedInfoPanel = (props) => {
    const { isEmpty, isTranfers, _package } = props;
    const { rating, packageInfo, packageId } = _package;
    const { booked } = (packageInfo.booked === undefined) ? 0 : packageInfo.booked;
    const isStyle = (isTranfers) ? { marginTop: 15, height: 26, width: 250 } : { marginTop: 0, height: 16, width: 250 };
    const fakeReviews = SetFakeReviewWithPackageId(packageId, rating.reviews);
    // const imgStyle = { width: 12, height: 11 };
    // <img alt='rating' style={imgStyle} src={ratingDefault} />
    // <img alt='rating' style={imgStyle} src={ratingActive} />
    return (<div style={isStyle}>
            {(!isEmpty) &&
        <Fragment>
                    <Rating emptySymbol={<Svg.Landing.Star color={'#9d9d9d'}/>} fullSymbol={<Svg.Landing.Star />} initialRating={rating.averagePoint || 0} readonly fractions={2}/>
                    <span style={{ margin: '0px 5px' }}>
                        <RatingLable>
                            {`( ${fakeReviews || 0} ) | ${SortLargeNumber(booked, 2, packageId)} ${packageLabel[wordingKeys.Booked]}`}
                        </RatingLable>
                    </span>
                </Fragment>}
        </div>);
};
const { avenir, avenirHeavy, avenirBlack } = Fonts.family;
const durationIcon = { marginRight: 5, width: 9, height: 9 };
const durationFont = { marginRight: 5, width: 9, height: 9, color: '#9b9b9b', marginBottom: 2 };
// const passengersIcon = { marginRight: 5, width: 10, height: 7.5 };
const passengersFont = { marginRight: 4, width: 11, height: 11, color: '#9b9b9b', marginBottom: 1 };
const CradContainer = styled.div `
height: 333px;
width: 308px;
min-width: 260px;
background-color: rgb(255,255,255);
box-shadow: rgba(0,0,0,0.18) 3px 2px 10px 0px;
margin: 10px 7px 10px 10px;
cursor: pointer;
${(props) => props.isSmall && css `width: 260px`}
${(props) => props.isMobile && css `margin: 10px`}`; // margin: 10px 12.5px;
const BubleContianer = styled.div `
width: 100%;
text-align: right;
position: absolute;
top: 1rem;
padding-right: 13px;
padding-left: 13px;`;
const ImageContianer = styled.div `
height:181px;
&:hover .promotinPrice {-moz-transform: scale(1.12);-webkit-transform: scale(1.12);transform: scale(1.12);`;
const PromotionContianer = styled.div `
margin-top: -65px;
margin-left: 7px;
width: 100%;
height: 50px;
text-align: center;
position: sticky;
${(props) => props.isPhiPhi && css `margin-top:-75px; margin-left:15px; height:50px;`}
${(props) => props.is2Digit && css ``}`;
const PackageNameContianer = styled.div `
display: flex;
padding: 0px 13px;
height: 44px;
margin: 6px 0px 0px 0px;`;
const PackageName = styled.p `
margin: 0px;
height: 48px;
width: 100%;
color: rgb(53,53,53);
font-family: ${avenirHeavy};
font-size: 20px;
font-weight: bold;
line-height: 22px;
letter-spacing: ${isSafari() ? -1 : 0}px;
cursor: pointer;`;
const PackageDetialContianer = styled.div `
display: flex;
padding: 5px 13px;
height: 38px;
margin: 0px;
${(props) => props.isTranfers && css `height: 38px;margin:0px`}`; // padding: 0px 13px;
const DetialItemsContianer = styled.div `
margin-top:6px
${(props) => props.isTranfers && css `height: 17px;margin-top:0px`}`; // height: 12px;
const DetialLabel = styled.label `
height: 16px;
width: 100%;
color: #9B9B9B;
font-family: ${avenir};
font-size: 12px;
font-weight: unset;
letter-spacing: -0.09px;`;
const DiscountLabel = styled.label `
font-size: 1.063rem;
font-weight: 900;
height: 28px;
width: 126px;
color: rgb(255, 255, 255);
font-family: ${avenir};
line-height: 28px;
${(props) => props.isPercent && css `font-family: ${avenirBlack};font-size: 1.5rem;`}`;
const RealPriceCotianer = styled.div `
flex: 1 1 0%;
text-align: right;
margin-top: 10px;
${(props) => props.isTranfers && css `margin-top: 20px;`}
${(props) => (props.isTranfers && !props.isBestSeller) && css ``}`;
const RealPriceLabel = styled.label `
width: 100%;
color: #B70302;
font-family: ${avenirHeavy};
font-size: 20px;
letter-spacing: 0px;
text-align: right;}`; // letter-spacing: 0.4px;
const PriceLabel = styled.label `
width: 100%;
color: rgb(53, 53, 53);
font-family:${avenir};
font-size: 15px;`;
const PriceCossLabel = styled.label `;
width: 100%;
color: #9B9B9B;
font-family: ${avenirHeavy};
font-size: 18px;}`;
const CossPrice = styled.del `
color: rgb(155, 155, 155);
padding: 0px 3px;`;
const RatingContianer = styled.div `
display: flex;
justify-content: space-between;
margin: 23px 0px 0px 0px;
${(props) => props.isTranfers && css `margin:3px 0px 0px 0px`}`; // margin: -2px 0px 0px 0px;
const RatingLable = styled.label `
height: 14px;
width: 100%;
color: #9B9B9B;
font-family: ${avenir};
font-size: 12px;
font-weight: unset;
letter-spacing: -0.08px;
line-height: 14px;
text-align: center;`;
const PromotionInfoCotianer = styled.div `
margin-top: -2px;`;
const PricePanelCotianer = styled.div `
flex: 1 1 0%;
text-align: right;
margin-top: 2px;`;
// const ImgBestSeller = styled.img`
// width: 98.94px;
// height: 26px;
// margin-top: 2px;
// cursor: pointer;
// ${(props: ICardSize) => props.isTranfers && css`margin-top:-3px;`}`;
const EmptyImgBestSeller = styled.div `height: 26px;`;
const BubbleContianer = styled.div `
width: 100%;
text-align: right;
position: absolute;
top: 1rem;
padding-right: 13px;
padding-left: 13px;
${(props) => props.isPhiPhi && css `top:0px;padding-right:0px;`}`;
const BubbleFlex = styled.div `
display: flex;
width: 100%;`;
const BubbleLeft = styled.div `
flex: 1 1 0%;`;
const BubbleRight = styled.div `
flex: 0.4 1 0%;`;
const PromotionOFF = styled.div `
font-size: 12px;
font-weight: 900;
color: rgb(255,255,255);
font-family: ${avenir};
padding-left: 23px;`;
// ====================================================================================================================
// const _style = {
//     card_container: { height: 420, width: '16.88rem', minWidth: '16.4rem', backgroundColor: '#FFFFFF',
//  boxShadow: '3px 2px 10px 0 rgba(0,0,0,0.18)', margin: '20px 10px', cursor: 'pointer' },
//     label_topic: { height: '50px', width: '332px', color: '#353535', fontFamily: 'Arial',
//  fontSize: '1em', fontWeight: 'bold', lineHeight: '25px', cursor: 'pointer' },
//     label_rating: { "height": "19px", "width": "96.24px", "color": "#88848B", "fontFamily": "Avenir",
//  "fontSize": "0.875em", "fontWeight": "900", "letterSpacing": "-0.11px", "lineHeight": "19px" },
//     label_price: { "height": "17px", "width": "83.76px", "color": "#353535", "fontFamily": "Arial",
//  "fontSize": "0.8125em", "lineHeight": "15px" },
//     label_bestSeller: { "height": "18px", "width": "99.17px", "color": "#FFFFFF",
//  "fontFamily": "Roboto", "fontSize": "15px", "fontWeight": "bold", "letterSpacing": "-0.12px",
//   "lineHeight": "18px", cursor: 'pointer' },
// bestSeller_container: { "height": "34.11px", "width": "137.91px", "borderRadius": "2px",
//  "backgroundColor": "#7EC440", "textAlign": "center", 'padding': '0.375em',
// 'marginLeft': '0.9375em', cursor: 'pointer' },
//     card_detail: { display: 'flex', padding: '5px 13px', height: '3.75em', margin: '5px 0px' },
//     promotion_lable: { height: '28px', width: '126px', color: '#FFFFFF',
//  fontFamily: 'Arial', fontSize: '1.25em', lineHeight: '28px', },
// };
// =====================================================================================================================
