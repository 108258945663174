import React, { Component } from 'react';
import { TimePicker as MTimePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/styles';
import { equals } from "ramda";
import moment from "moment";
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";
import { Colors, Metrics } from "../../themes";
import localization from '../../services/localization';
const styles = (theme) => ({
    root: {
        width: '100%',
    },
    textFieldRoot: {
        "padding": 0,
        'label + &': {
            marginTop: theme.spacing.unit * 2.2,
        },
    },
    textFieldInput: {
        cursor: 'pointer',
        // height: '12px',
        borderRadius: Metrics.modal.borderRadius,
        background: theme.palette.common.white,
        border: (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
        fontSize: 16,
        padding: '10px 12px',
        width: '100%',
    },
});
class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTime: props.value || '',
            clear: null,
        };
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.selectedTime)) {
            if (this.shouldUpdateTime(nextProps.value)) {
                const { onChange } = this.props;
                if (typeof onChange === 'function') {
                    const dateAddTime = moment(nextProps.value);
                    const selectedTime = new Date(this.state.selectedTime);
                    dateAddTime.add(selectedTime.getHours(), 'h').add(selectedTime.getMinutes(), 'm')
                        .add(selectedTime.getSeconds(), 's').add(selectedTime.getMilliseconds(), 'ms');
                    onChange(dateAddTime.toISOString());
                }
            }
            else {
                this.setState({
                    selectedTime: nextProps.value || new Date(),
                });
            }
        }
    }
    /*
        case description
        date and time picker seperate but value same pointer
        when date change the picker reset time to 00:00(17:00+0)
        this function cheking for should trig onChange time or not
        maybe change in future if other case come
    */
    shouldUpdateTime(newDate) {
        newDate = new Date(newDate);
        return newDate.getHours() === 0 && newDate.getMinutes() === 0 && newDate.getSeconds() === 0;
    }
    render() {
        const { label, error, errorId, classes, onChange, require, } = this.props;
        return (<div className="picker">
                <FieldLabel label={label} error={error} require={require}/>
                <MTimePicker ampm={false} autoOk keyboard 
        // mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
        mask={[/\d/, /\d/, ':', /\d/, /\d/]} className={classes.root} InputProps={{
            disableUnderline: true,
            classes: {
                root: classes.textFieldRoot,
                input: classes.textFieldInput,
            },
        }} value={this.state.selectedTime ? this.state.selectedTime : this.state.clear} onChange={(val) => {
            this.setState({
                selectedTime: val.toISOString(),
            }, () => {
                // onChange(val.valueOf());
                onChange(val.toISOString());
            });
        }} 
        // emptyLabel="Please select time
        placeholder={`${localization(`please_input_time`, `BookingLabel`)}`}/>
                <FieldError error={error} errorId={errorId}/>
            </div>);
    }
}
export default withStyles(styles)(TimePicker);
