import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import MaskedInput from "react-text-mask";
import { withStyles } from '@material-ui/styles';
import { path, equals } from "ramda";
import keycode from 'keycode';
// import { withStyles } from 'material-ui/styles';
// import TextField from 'material-ui/TextField';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from '@material-ui/core/InputAdornment';
import { Metrics, Fonts, Colors } from "../../themes";
const typingTimeOut = 200;
const styles = (theme) => {
    const placeholder = {
        color: 'currentColor',
        opacity: 0.42,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.ease,
        }),
    };
    return {
        root: {
            width: '100%',
            position: 'relative',
            margin: 0,
        },
        textFieldRoot: {
            "padding": 0,
            "minHeight": (props) => `calc(${props.minHeight + theme.spacing.unit * 2.2}px)` || '',
            "label + &": {
                marginTop: theme.spacing.unit * 2.2,
            },
        },
        textFieldInput: {
            // "height": '12px',
            "minHeight": (props) => `${props.minHeight - 2}px` || '',
            "borderRadius": Metrics.modal.borderRadius,
            "background": theme.palette.common.white,
            "border": (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
            "fontSize": '16px',
            "padding": '10px 12px',
            "width": (props) => props.multiline ? 'calc(100% - 26px)' : '100%',
            "height": (props) => props.multiline ? 'calc(100% - 20px)' : '',
            "transition": theme.transitions.create(['border-color', 'box-shadow']),
            // "&:focus": {
            //     borderColor: '#80bdff',
            //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            // },
            '&::-webkit-input-placeholder': placeholder,
            '&::-moz-placeholder': placeholder,
            '&:-ms-input-placeholder': placeholder,
            '&::-ms-input-placeholder': placeholder,
        },
        textFieldFormLabel: {
            fontSize: Fonts.size.input.label,
            color: Colors.input.label,
        },
        textFieldFormLabelShrink: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
        helperText: {
            marginTop: '5px',
        },
    };
};
class TextInput extends Component {
    constructor(props) {
        super(props);
        this.typingTimer = null;
        this.state = {
            value: props.value || '',
        };
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.value)) {
            this.setState({
                value: nextProps.value,
            });
        }
    }
    componentWillUnmount() {
        this.clearTimeout();
    }
    render() {
        const { classes, error, errorId = 'blank', label, placeholder, inputProps, multiline, disabled = false, mask, displayError = true, inputStyle = {}, labelStyle = {}, wrapperStyle = {}, endAdornment, onKeyUp, require, icon, } = this.props;
        const { value } = this.state;
        return (<FormControl className={path(['root'], classes)} disabled={disabled} style={wrapperStyle}>
                <InputLabel htmlFor={`id-${label}`} shrink classes={{
            root: path(['textFieldFormLabel'], classes),
            shrink: path(['textFieldFormLabelShrink'], classes),
        }} error={error} style={labelStyle}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ color: 'red' }}>{require === true ? '*' : null}</div>{label}</div>
                </InputLabel>
                {mask ? (<div className={path(['textFieldRoot'], classes)}>
                            <MaskedInput 
        // showMask
        id={`id-${label}`} 
        // guide={false}
        value={value} placeholder={placeholder} mask={mask} className={path(['textFieldInput'], classes)} onChange={this.onChangeHandler} onKeyDown={this.onKeyDown} onBlur={this.onBlur} style={inputStyle}/>
                        </div>)
            :
                <Input id={`id-${label}`} value={value} placeholder={placeholder} multiline={multiline} disableUnderline type='text' classes={{
                    root: path(['textFieldRoot'], classes),
                    input: path(['textFieldInput'], classes),
                }} onChange={this.onChangeHandler} onKeyDown={this.onKeyDown} onBlur={this.onBlur} onKeyUp={onKeyUp} inputProps={{
                    ...inputProps,
                    style: inputStyle,
                }} endAdornment={<InputAdornment position="end">
                                    {<img src={icon} style={{ position: 'absolute', top: 7, right: 20 }}/>}
                                </InputAdornment>}/>}
                {!!endAdornment && (endAdornment)}
                {displayError && (<FormHelperText className={path(['helperText'], classes)} error>
                            <FormattedMessage id={errorId}/>
                        </FormHelperText>)}
            </FormControl>);
    }
    onChangeHandler(e) {
        const { validate, onEndEditing } = this.props;
        const value = path(['target', 'value'], e);
        this.clearTimeout();
        this.setState({ value }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(value);
            }
            this.typingTimer = setTimeout(() => {
                if (typeof onEndEditing === 'function') {
                    onEndEditing();
                }
                if (typeof validate === 'function') {
                    validate();
                }
            }, typingTimeOut);
        });
    }
    onBlur() {
        const { validate, onEndEditing } = this.props;
        if (typeof onEndEditing === 'function') {
            onEndEditing();
        }
        if (typeof validate === 'function') {
            validate();
        }
    }
    onKeyDown(e) {
        if (keycode(e) === 'enter' && typeof this.props.onPressEnter === 'function') {
            this.props.onPressEnter();
        }
    }
    clearTimeout() {
        if (this.typingTimer !== null) {
            clearTimeout(this.typingTimer);
        }
    }
}
TextInput.defaultProps = {
    onChange: () => { },
    onEndEditing: () => { },
    onPressEnter: () => { },
    type: 'text',
    value: '',
};
export const InputWithStyle = withStyles(styles)(TextInput);
