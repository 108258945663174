import React from 'react';
import { connect } from "react-redux";
import { path } from "ramda";
import { AUTHEN_DIALOG_MODE } from "../../containers/AuthenDialogWrapper";
import UserActions from "../../redux/UserRedux";
import transform from "../../utils/transform";
// import moment from 'moment';
import Space from '../Space';
import Button from '../button';
import HeaderText from "./HeaderText";
import Tab from "./Tab";
import Divider from "./Divider";
import SocialButtons from "./SocialButtons";
import { CheckBoxWithStyle } from "../form/CheckBox";
import Dialog from "../dialog";
import TraceFacebookPixel, { TrackType } from '../TraceInfo/FacebookPixel';
import { Form } from '../form';
import formtypes, { fieldsName } from '../../formtypes';
import { reduxKeys, socialKeys, pathName, countryCode } from "../../constant";
import localization from "../../services/localization";
import { deviceInfo } from '../common/DeviceInfo';
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.ref = {};
        props.hocRef(this);
        this.state = {
            isAcceptTerms: false,
            checkSocial: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.selectSocial = this.selectSocial.bind(this);
    }
    isChanged() {
        return this.ref.isChanged();
    }
    onSubmit() {
        const { user, [reduxKeys.settings]: { language } } = this.props;
        const { success, data } = this.ref.submit();
        TraceFacebookPixel({ trackType: TrackType.SubmitApplication });
        if (success) {
            if (!this.state.isAcceptTerms) {
                Dialog.info({
                    key: 'register',
                    title: `${localization(`please_accept_terms_and_conditions`, `DialogLabel`)}`,
                });
            }
            else {
                data.tel = transform.tel(data.tel);
                const id = path(['socialCache', 'id'], user);
                if (!!id) {
                    const socialType = path(['socialCache', 'socialType'], user) || '';
                    data[socialKeys[socialType]] = id;
                }
                data.title = transform.userTitle(data.title);
                data.language = language;
                data.deviceInfo = deviceInfo();
                this.props.dispatch(UserActions.registerRequest(data, this.props.handleRegisterSuccess));
                localStorage.setItem('loginFace', "false");
                localStorage.setItem('loginGoogle', "false");
                TraceFacebookPixel({ trackType: TrackType.CompleteRegistration });
            }
        }
    }
    selectSocial() {
        this.setState({ checkSocial: true });
    }
    componentWillUnmount() {
        localStorage.setItem('loginFace', "false");
    }
    render() {
        const { user, isMobile, [reduxKeys.settings]: { language } } = this.props;
        const defaultValues = {};
        Object.keys(fieldsName.regiserFieldsName).forEach((each) => {
            defaultValues[each] = path(['socialCache', each], user);
        });
        const locale = countryCode.find((each) => each.code === language);
        const dialingCode = path(['id'], locale);
        const isMobileSmall = window.innerWidth < 390;
        return (<div style={{
            // overflowY: 'scroll',
            // overflowX: 'hidden',
            height: '100%',
            paddingRight: isMobile ? 15 : 25,
        }}>
                <HeaderText style={{
            display: 'flex', justifyContent: 'space-between',
            fontSize: isMobileSmall ? 20 : 29,
        }}>
                    {localization(`register`, `AuthenLabel`)}
                    <Dialog.CloseMark onClick={this.props.onHide}/>
                </HeaderText>
                <div style={{ marginTop: isMobileSmall ? 10 : 30 }}>
                    <Tab activeTab={AUTHEN_DIALOG_MODE.REGISTER} onClickLogin={() => { this.props.changeMode(AUTHEN_DIALOG_MODE.LOGIN); }}/>
                </div>
                <Space height={isMobileSmall ? 10 : 20}/>

                <SocialButtons handleLoginSuccess={this.props.handleLoginSuccess}/>

                <Space height={isMobileSmall ? 15 : 20}/>
                <Divider style={{ opacity: 0.4 }}/>
                <Space height={isMobileSmall ? 10 : 20}/>

                <Form form={formtypes.register({
            onPressEnter: this.onSubmit,
            defaultValues,
            isSocial: !!path(['socialCache', 'id'], user),
            isMobile,
            dialingCode,
            checkSocial: this.state.checkSocial,
            selectSocial: this.selectSocial,
        })} ref={(ref) => this.ref = ref}/>

                <CheckBoxWithStyle value={this.state.isAcceptTerms} rootStyle={{
            margin: '5px 0',
        }} buttonRootStyle={{
            width: '24px',
            height: '24px',
        }} labelStyle={{
            color: '#7d7a7a',
            fontSize: '10px',
            marginLeft: '5px',
        }} label={<a href={pathName.TERMS} target={'_blank'} rel="noopener">{`Accept Terms and Conditions`}</a>} onChange={(isAcceptTerms) => this.setState({ isAcceptTerms })}/>

                
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    
                    <Button.Theme label={localization(`register`, `AuthenLabel`)} width="140px" onClick={this.onSubmit} letterSpacing={-1}/>
                </div>
            </div>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.user]: state[reduxKeys.user],
    [reduxKeys.settings]: state[reduxKeys.settings],
});
export default connect(mapStateToProps)(Register);
