export const inputType = {
    CHECKBOX: 'CHECKBOX',
    TEXT_INPUT: 'TEXT_INPUT',
    SELECT: 'SELECT',
    DATE_PICKER: 'DATE_PICKER',
    TIME_PICKER: 'TIME_PICKER',
    DATE_TIME_PICKER: 'DATE_TIME_PICKER',
    AVATAR: 'AVATAR',
    RADIO: 'RADIO',
    CALENDAR: 'CALENDAR',
    CUSTOM_CALENDAR: 'CUSTOM_CALENDAR',
};
