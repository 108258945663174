import FacebookLogin from './FacebookLogin';
import GoogleLogin from './GoogleLogin';
export default {
    facebook: {
        login: FacebookLogin,
    },
    google: {
        login: GoogleLogin,
    },
};
