import templateLabel from './CN/Message/templateLabel';
import packageLabel from './CN/Message/packageLabel';
import FAQLabel from './CN/Message/FAQLabel';
import AnnouncementLable from './TH/Message/Messages';
export default {
    hello: "你好",
    go_landing_page: '',
    your_booking_incomplete: 'Your Booking Incomplete !',
    invalid_security_code: 'the security code is invalid',
    pick_up_origin: 'ORIGIN',
    drop_off_destination: 'DESTINATION',
    templateLabel,
    packageLabel,
    FAQLabel,
    AnnouncementLable,
};
