import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { compose } from "recompose";
import styled from 'styled-components';
import { path } from "ramda";
import { pathName, reduxKeys } from "../../constant";
import { logout } from "../../sagas/ManageStoreSaga";
import { Colors, Images } from "../../themes";
import CollapseMenu from "../menu/CollapseMenu";
import Avatar from '../Avatar';
// import Select from "../form/Select";
const getLinks = ({ openModal, firstName, toggleCollapse, history, avatar, dark, }) => ([
    {
        label: 'HOME',
        path: pathName.INDEX,
    },
    // {
    //     label: 'SERVICE',
    //     action: () => {/* */ },
    // },
    // {
    //     label: 'ABOUT',
    //     action: () => {/* */ },
    // },
    // {
    //     label: 'CONFIRM-PAYMENT',
    //     path: pathName.CONFIRM_PAYMMENT,
    // },
    {
        label: 'LOGIN/REGISTER',
        customRender: !!firstName ? (<Avatar size={22} dark={dark} avatar={avatar}/>) : undefined,
        // path: !!firstName ? pathName.PROFILE : undefined,
        path: undefined,
        action: () => {
            if (!!firstName) {
                toggleCollapse('user');
            }
            else {
                const homePath = [pathName.INDEX, pathName.LOGIN, pathName.REGISTER];
                if (homePath.indexOf(history.location.pathname) === -1) {
                    history.push(pathName.LOGIN);
                }
                else {
                    openModal();
                }
            }
        },
        collapseName: 'user',
        children: !!firstName ? ([
            {
                label: 'My Profile',
                onClick: () => history.push(pathName.PROFILE),
            },
            // {
            //     label: 'My Cart',
            //     onClick: () => history.push(pathName.BOOKING_PREVIEW),
            // },
            {
                label: 'Logout',
                onClick: () => logout(),
            },
        ])
            :
                undefined,
    },
]);
const Link = styled.div `
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    padding: 0 27px;
    cursor: pointer;
`;
class Desktop extends React.Component {
    constructor(props) {
        super(props);
        this.Links = getLinks({});
        const collapses = {};
        this.Links.filter((each) => !!each.collapseName).forEach((each) => {
            collapses[each.collapseName] = false;
        });
        this.state = { collapses };
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }
    toggleCollapse(name) {
        this.setState({
            collapses: {
                ...this.state.collapses,
                [name]: !this.state.collapses[name],
            },
        });
    }
    render() {
        const { openModal, history, linkStyle, user, dark, } = this.props;
        const firstName = path(['data', 'firstName'], user);
        const avatar = path(['data', 'avatarLink'], user);
        const Links = getLinks({
            history,
            openModal,
            firstName,
            toggleCollapse: this.toggleCollapse,
            avatar,
            dark,
        });
        return (<div style={{ display: 'flex' }}>
                {Links.map((each, i) => {
            return (<Fragment key={i}>
                                <Link onClick={() => {
                if (typeof each.action === 'function') {
                    each.action();
                }
                else {
                    if (!!each.path && history.location.pathname !== each.path) {
                        history.push(each.path);
                    }
                }
            }} style={{
                paddingRight: i === Links.length - 1 ? '5px' : '',
                display: 'flex',
                alignItems: 'center',
                minHeight: '25px',
                zIndex: 2,
                position: 'relative',
                color: dark ? Colors.lightBlue : '#fff',
                ...linkStyle,
            }}>
                                    {!!each.customRender ?
                each.customRender
                :
                    each.label}
                                    {each.children && (<div style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '14.5px',
            }}>
                                                <img alt='drop-down' src={dark ?
                Images.nav.dropdown.blue
                :
                    Images.nav.dropdown.white}/>
                                                
                                            </div>)}
                                    {each.children && this.renderChildren({
                link: each,
                children: each.children,
            })}
                                </Link>
                            </Fragment>);
        })}
            </div>);
    }
    // background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.34) 100%);
    renderChildren({ link, children }) {
        return (<CollapseMenu dark={this.props.dark} options={{
            renderPosition: {
                top: -6,
                right: 0,
            },
        }} onClickAway={() => {
            if (this.state.collapses[link.collapseName] === true) {
                this.toggleCollapse(link.collapseName);
            }
        }} open={this.state.collapses[link.collapseName]} menuList={children}/>);
    }
}
const mapStateToProps = (state) => {
    return {
        [reduxKeys.user]: state[reduxKeys.user],
    };
};
const composedDesktop = compose(connect(mapStateToProps), withRouter)(Desktop);
export default composedDesktop;
