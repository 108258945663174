import React from 'react';
import Divider from "../Divider";
import Text from "../text";
import { Colors } from "../../themes";
const HeaderForm = ({ text = '', style }) => {
    return (<div style={{ width: '100%', ...style }}>
            <Text.Avenir family="heavy" size={18} color={Colors.grayScale["35"]}>
                {text}
            </Text.Avenir>
            <Divider style={{ width: '100%' }}/>
        </div>);
};
export default HeaderForm;
