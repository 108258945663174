import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { equals } from "ramda";
import { Calendar } from 'primereact/calendar';
import Dialog from '../dialog';
import { Title } from '../form';
import { Colors, Metrics } from "../../themes";
import './CustomStyle.css';
import './PrimeStyle.css';
import 'primeicons/primeicons.css';
import { isMobile } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
import localization from '../../services/localization';
const styles = (theme) => ({
    root: {
        width: '100%',
    },
    textFieldRoot: {
        "padding": 0,
        'label + &': { marginTop: theme.spacing.unit * 2.2 },
    },
    textFieldInput: {
        // height: '12px',
        borderRadius: Metrics.modal.borderRadius,
        border: (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
        background: theme.palette.common.white,
        width: '100%',
        fontSize: 16,
        padding: '10px 12px',
        cursor: 'pointer',
    },
});
class CustomCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: props.value || null,
            date: null,
        };
    }
    componentDidMount() {
        document.documentElement.style.setProperty('--main-color', Colors.theme);
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.selectedDate)) {
            this.setState({
                selectedDate: this.state.date,
            });
        }
    }
    render() {
        const { onChange, getRef, inputStyle, placeholder, dateTemplate, disabledDates, numberOfMonths, minDate, maxDate, modalRef, language, } = this.props;
        const date = new Date();
        const StartDay = new Date(date.getTime() + 86400000);
        const TH = {
            firstDayOfWeek: 0,
            dayNamesMin: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
            monthNames: ["มกราคม", "กุมพาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม",
                "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
        };
        const EN = {
            firstDayOfWeek: 0,
            dayNamesMin: ["Su", "Mi", "Tu", "We", "Th", "Fr", "Sa"],
            monthNames: ["January", "February", "March", "April", "May",
                "June", "July", "August", "September", "October", "November", "December"],
        };
        const isMobiles = isMobile();
        return (<Dialog.Modal ref={modalRef} listenWidth closeMark={false} isCalendar={true} stylePaper={{ height: '400px' }} styleMobile={{ backgroundColor: Colors.theme }} labelMobile={localization(`travel_date_calendar`, `LandingLabel`)}>
                <div style={{ display: 'grid' }}>
                    {isMobiles ? null : <div style={{
            backgroundColor: Colors.theme, height: 45,
            display: 'inline-grid', alignItems: 'center', paddingLeft: 30,
        }}>
                        <Title title={localization(`travel_date_calendar`, `LandingLabel`)} style={{ color: '#fff', fontSize: 18 }}/>
                    </div>}
                    <div style={{
            overflowY: 'unset',
            height: '100%',
            justifyContent: 'center',
        }}>
                        <div className="content-section implementation inputgrid-demo">
                            <div style={{ width: '100%', height: '100%' }}>
                                <Calendar ref={(node) => {
            if (typeof getRef === 'function') {
                getRef(node);
            }
        }} style={{ width: '100%', paddingTop: 5 }} inputStyle={inputStyle} minDate={minDate} maxDate={maxDate} placeholder={placeholder} numberOfMonths={isMobiles ? 1 : numberOfMonths} value={this.state.date} locale={language === 'TH' ? TH : EN} inline={true} panelStyle={{
            width: isMobiles ? '300px' : 'unset',
            background: isMobiles ? '#fff' : '',
            padding: isMobiles ? 'unset' : '',
        }} onChange={(e) => {
            this.setState({
                date: e.value === undefined ? StartDay : e.value,
            }, () => {
                onChange(e.value === undefined ? StartDay : e.value);
                if (typeof this.props.validate === 'function') {
                    this.props.validate(e.value === undefined ? StartDay : e.value);
                }
                this.props.hideDialog();
            });
        }} showOtherMonths={false} dateTemplate={dateTemplate} disabledDates={disabledDates} dateFormat="dd/mm/yy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog.Modal>);
    }
}
export default withStyles(styles)(CustomCalendar);
