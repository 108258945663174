export default {
    info_confirm_payment: 'คุณแน่ใจหรือไม่ว่าต้องการยืนยันการชำระเงินนี้ ?',
    confirm_discard: 'คุณต้องการยกเลิกหรือไม่',
    confirm_yes: 'ใช่',
    confirm_no: 'ไม่',
    loading_user: 'กำลังโหลดข้อมูล...',
    loading_login: 'กำลังล็อคอิน',
    loading_please_wait: 'โปรดรอสักครู่...',
    question_title_register: `คุณยังไม่ได้ลงทะเบียนหรือสมัครสมาชิก`,
    question_content_regiser: `โปรดกรอกข้อมูลที่จำเป็นทั้งหมด`,
    question_content_regiser_fail: 'To ensure that our mailing list contains only those who wish to be on it, we have sent a verify link to your email address. Please log in to your email account and find the email we have just sent you. It may be in your Spam/Bulk/Junk folder. To complete the process of being added to our mailing list, just click the link in that email.',
    info_title_register_success: 'ลงทะเบียนเรียบร้อยแล้ว',
    info_content_register_success: 'โปรดยืนยันอีเมลของคุณ',
    info_title_forgot_password_success: 'กู้คืนรหัสผ่านสำเร็จแล้ว',
    info_content_forgot_password_success: 'อีเมลจะถูกส่งถึงคุณภายใน 2-3 นาที',
    info_title_update_profile_success: 'อัปเดตโปรไฟล์เรียบร้อยแล้ว',
    info_title_change_password_success: 'เปลี่ยนรหัสผ่านสำเร็จแล้ว',
    info_title_confirm_payment_success: 'ยืนยันการชำระเงินเรียบร้อยแล้ว',
    info_title_request_affiliate_id_success: 'ขอรหัส Affiliate สำเร็จแล้ว',
    info_title_create_review_success: 'รีวิวเรียบร้อยแล้ว',
    info_title_get_promotion_code_success: 'ยินดีด้วย',
    info_subtitle_get_promotion_code_success: 'คุณได้รับราคาที่ดีที่สุดกับทราวิสโก้',
    info_title_use_promotion_code_success: 'ใช้โค้ดส่วนลดสำเร็จแล้ว',
    info_title_get_promotion_code_fail: 'โค้ดโปรโมชั่นนี้ไม่ถูกต้องหรือหมดอายุไปแล้ว กรุณาเช็คข้อมูลอีกครั้ง',
    ok: 'ตกลง',
    please_accept_terms_and_conditions: 'โปรดยอมรับข้อกำหนดในการให้บริการ',
    cancel: 'ยกเลิก',
    save_credit_card: 'คุณต้องการบันทึกข้อมูลบัตรเครดิตนี้หรือไม่',
};
