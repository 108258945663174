import templateLabel from './EN/Message/templateLabel';
import packageLabel from './EN/Message/packageLabel';
import FAQLabel from './EN/Message/FAQLabel';
import AnnouncementLable from './EN/Message/Messages';
import AuthenLabel from './EN/Message/Authen';
import BookingLabel from './EN/Message/Booking';
import DialogLabel from './EN/Message/Dialog';
import LandingLabel from './EN/Message/Landing';
import ProfileLabel from './EN/Message/Profile';
export default {
    blank: ' ',
    hello: 'hello',
    // error
    error_require_email: 'Email is required.',
    error_require_password: 'Password is required.',
    error_require_confirm_password: 'Confirm password is required.',
    error_require_firstname: 'Firstname is required.',
    error_require_lastname: 'Lastname is required.',
    error_require_tel: 'Tel is required.',
    error_require_bank: 'Bank is required.',
    error_require_bank_branch: 'Branch of Bank is required.',
    error_require_date: 'Date is required.',
    error_require_time: 'Time is required.',
    error_require_bank_statement: 'Bank statement is required.',
    error_require_title: 'Title is required.',
    error_require_credit_card_name: 'Name is required.',
    error_require_credit_card_number: 'Credit card number is required.',
    error_require_credit_card_expire: 'Expire is required.',
    error_require_credit_card_code: 'Security code is required.',
    error_wrong_credit_card_number: 'Wrong credit card number pattern.',
    error_wrong_credit_card_expire: 'Wrong expire pattern.',
    error_wrong_tel: 'Wrong Tel pattern.',
    error_wrong_email: 'Wrong email pattern.',
    error_password: 'Password must be at least 8.',
    error_confirm_password: 'Confirm password must be equal to password.',
    error_money_notGreaterThan: 'Money must be greater than 0.',
    error_money_notValid: 'Money not valid.',
    error_require_request: 'Special Request is required',
    error_require_pickup_detail: 'Pick-up Deital is required',
    error_require_pick_up_location: 'Pick-up Location is required',
    error_require_pickup_note: 'Pick-up Note is required',
    error_require_dropoff_note: 'Drop-off Note is required',
    error_require_arrival_airline: 'Arrival Airline is required',
    error_require_arrival_flight_no: 'Arrvival Flight No is required',
    error_require_arrival_time: 'Arrival Time is required',
    error_require_country_code: 'Country Code is required',
    error_require_social_id: 'Social Id is required',
    // error_require_money: 'Money is required.',
    default_error: 'Something went wrong.',
    error_title_connection: 'Connection timeout.',
    error_content_connection: 'Please check your connectivity.',
    error_title_omise: 'Omise error.',
    error_wrong_firstname: 'Firstname is English only',
    error_wrong_lastname: 'Lastname is English only',
    error_require_gender: 'Gender is required',
    error_require_nationality: 'Nationality is required',
    error_require_nationality_id: 'Nationality ID is required',
    error_require_date_of_birth: 'Date of birth is required',
    error_require_refund_reasons: 'Refund Reasons is required',
    error_require_refund_other: 'Other is required',
    error_require_room_no: 'Room No. is required',
    // system
    payment_method_alienation: 'Alienation',
    payment_method_omise: 'Omise',
    empty_table: 'Empty',
    price: '฿ {price, number}',
    amount: '{amount, number}',
    cancel: 'cancel',
    // month
    jan: 'JAN',
    feb: 'FEB',
    mar: 'MAR',
    apr: 'APR',
    may: 'MAY',
    jun: 'JUN',
    jul: 'JUL',
    aug: 'AUG',
    sep: 'SEP',
    oct: 'OCT',
    nov: 'NOV',
    dec: 'DEC',
    // province
    krabi: 'KRABI',
    phuket: 'PHUKET',
    chiangmai: 'CHIANG MAI',
    pattaya: 'PATTAYA',
    // templateLabel
    templateLabel,
    packageLabel,
    FAQLabel,
    AnnouncementLable,
    DialogLabel,
    ProfileLabel,
    AuthenLabel,
    BookingLabel,
    LandingLabel,
};
