import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled, { css } from 'styled-components';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
// import DayTripCard from './DayTripCard';
// import PopularCard from './PopularCard';
// import PopularSlider from './PopularSlider';
// import { packageList } from './PackageCardForm';
// import Subheader from 'material-ui/List/ListSubheader';
// import { Metrics, Fonts } from "../../themes";
import NewPackagesCard from './NewPackagesCard';
import NewPackagesCardMobile from './NewPackagesCardMobile';
import { Fonts, Colors } from "../../themes";
import { pathName, packageType } from "../../constant"; // language
import { ConveyanceType } from '../../containers/Search/FilterTypeInfo';
import { GetItemColsPerWidth, isMobile, isFirefox, isSafari } from './GetItemColsPerWidth';
import localization from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
// ===========================================================================================================
let pageLabel;
const maximumItems = 4;
const special = {
    ["New Attractions" /* NEW_ATTRACTIONS */]: "isAttractions",
    ["Recommend Trip" /* RECOMMEND_TRIP */]: "isRecommends", ["Special Promotion" /* SPECIAL_PROMOTION */]: "isPromotion",
};
// ============================================================================================================
class PackageGridListPanel extends Component {
    constructor() {
        super(...arguments);
        this.state = { cols: GetItemColsPerWidth(), width: window.innerWidth };
        this.updateDimensions = () => {
            const _cols = GetItemColsPerWidth();
            const { cols, width } = this.state;
            const _with = window.innerWidth;
            if ((cols !== _cols) || (width !== _with)) {
                this.setState({ cols: _cols, width: _with });
            }
        };
        // =======================================================================================================
        this.headleSeeMore = (PanelType) => () => {
            let stateInfo;
            switch (PanelType) {
                case "Car Rental" /* CAR_RENTAL */:
                case "Airport Transfer" /* AIRPORT_TRNSFER */:
                    {
                        stateInfo = { searchType: packageType.Transfer };
                    }
                    break;
                case "Boat Charters" /* BOAT_CHARTERS */:
                case "Ferries Transfer" /* FERRIES_TRNSFER */:
                    {
                        stateInfo = { searchType: packageType.Transfer, conveyanceType: ConveyanceType.Ferries };
                    }
                    break;
                case "Recommend" /* RECOMMEND */:
                    {
                        stateInfo = { searchType: packageType.OneDayTrip, conveyanceType: undefined };
                        window.scrollTo(0, 0);
                    }
                    break;
                case "Special Promotion" /* SPECIAL_PROMOTION */:
                case "New Attractions" /* NEW_ATTRACTIONS */:
                case "Recommend Trip" /* RECOMMEND_TRIP */:
                default:
                    {
                        stateInfo = { searchType: packageType.OneDayTrip, special: (special[PanelType] || null) };
                    }
            }
            this.props.history.push({ pathname: pathName.SEARCH_PRODUCT, state: stateInfo, search: '', hash: '' });
        };
        // ==========================================================================================================
    }
    componentWillMount() {
        this.updateDimensions();
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }
    componentWillUnmount() {
        // window.removeEventListener("resize", this.updateDimensions);
    }
    render() {
        pageLabel = localization(wordingKeys.templateLabel);
        const { PackageList } = this.props;
        return (!!PackageList) ? this.packageListPanel() : null;
    }
    packageListPanel() {
        const { PackageList, PanelType, Language, LocationService, isLastPanel } = this.props;
        if (PackageList.length === 0) {
            return null;
        }
        let list;
        let isMiniSize = false;
        let isRecomand = false;
        switch (PanelType) {
            case "Day Tours" /* DAY_TOURS */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageB.priorityDisplay - packageA.priorityDisplay));
                    list = list.filter((item) => item.isPromotion).slice(0, maximumItems);
                }
                break;
            case "Special Promotion" /* SPECIAL_PROMOTION */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageA.priorityDisplay - packageB.priorityDisplay));
                    list = list.filter((item) => item.isPromotion).slice(0, maximumItems);
                }
                break;
            case "New Attractions" /* NEW_ATTRACTIONS */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageA.priorityDisplay - packageB.priorityDisplay));
                    list = list.filter((item) => item.isAttractions && item.priorityDisplay > 0).slice(0, maximumItems);
                }
                break;
            case "Recommend Trip" /* RECOMMEND_TRIP */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageA.priorityDisplay - packageB.priorityDisplay));
                    list = list.filter((item) => item.isRecommends && item.priorityDisplay > 1).slice(0, maximumItems);
                }
                break;
            case "Airport Transfer" /* AIRPORT_TRNSFER */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageA.priorityDisplay - packageB.priorityDisplay));
                    list = list.filter((item) => (item.type === packageType.Transfer && item.packageInfo.transferType.conveyanceType !== ConveyanceType.Ferries)).slice(0, maximumItems);
                }
                break;
            case "Car Rental" /* CAR_RENTAL */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageA.priorityDisplay - packageB.priorityDisplay));
                    list = list.filter((item) => (item.type === packageType.Transfer && item.packageInfo.transferType.conveyanceType !== ConveyanceType.Ferries)).slice(0, maximumItems);
                }
                break;
            case "Boat Charters" /* BOAT_CHARTERS */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageA.priorityDisplay - packageB.priorityDisplay));
                    list = list.filter((item) => (item.type === packageType.Transfer && item.packageInfo.transferType.conveyanceType === ConveyanceType.Ferries)).slice(0, maximumItems);
                }
                break;
            case "Ferries Transfer" /* FERRIES_TRNSFER */:
                {
                    list = PackageList.sort((packageA, packageB) => (packageA.priorityDisplay - packageB.priorityDisplay));
                    list = list.filter((item) => (item.type === packageType.Transfer && item.packageInfo.transferType.conveyanceType === ConveyanceType.Ferries)).slice(0, maximumItems);
                }
                break;
            case "Recommend" /* RECOMMEND */:
                {
                    list = PackageList.filter((item) => item.locationServiceId === LocationService).slice(0, maximumItems * 2);
                    if (list.length < maximumItems) {
                        list = list.concat(PackageList.filter((item) => item.isRecommends).slice(0, maximumItems));
                    }
                    isRecomand = true;
                }
                break;
            case "WishList" /* WISHLIST */:
                {
                    isMiniSize = true;
                    list = PackageList;
                }
                break;
            default:
                {
                    list = PackageList.filter((item, index) => { if (index > 1 && index < 4) {
                        return item;
                    } });
                }
                break;
        }
        list = list.sort((packageA, packageB) => (packageA.indexSequence - packageB.indexSequence));
        if (list.length === 0) {
            return null;
        }
        const ismobile = isMobile();
        const { cols } = this.state;
        const _isUnderDefault = (window.innerWidth < 1200);
        const _flex = { flex: ismobile ? 1 : ((window.innerWidth > 1300) ? 1 : 0.1) };
        const _gridStyle = ((ismobile ? style.gridMobileList
            : (window.innerWidth > maxContentWidth) ? style.gridListMax : style.gridList));
        // console.log('packageListPanel : ', this.props)
        return (<PanelContianer isMobile={ismobile} isLastPanel={isLastPanel}>
                <div style={_flex}/>
                <CenterContent isMobile={ismobile} isUnderDefault={_isUnderDefault}>
                    <TopicsPanel PanelType={PanelType} Language={Language} isMobile={ismobile} headleSeeMore={this.headleSeeMore}/>
                    <GridList cols={cols} style={_gridStyle} spacing={1}>
                        {list.map((packageInfo) => (<GridListTile style={style.gridTileStyle} key={packageInfo.packageId}>
                                    {(ismobile) ?
            <NewPackagesCardMobile packageInfo={packageInfo} cardSize={isMiniSize} isRecomand={isRecomand}/> :
            <NewPackagesCard packageInfo={packageInfo} cardSize={isMiniSize} isRecomand={isRecomand}/>}
                                </GridListTile>))}
                    </GridList>
                </CenterContent>
                <div style={_flex}/>
            </PanelContianer>);
    }
}
export default withRouter(PackageGridListPanel);
const TopicsPanel = (props) => {
    const { PanelType, isMobile, headleSeeMore } = props;
    return (<TopicsContianer>
            <TopicsLeftContianer>
                <TopicsLable isMobile={isMobile}>
                    {pageLabel[PanelType] || ''}
                </TopicsLable>
            </TopicsLeftContianer>
            <TopicsRightContianer isMobile={isMobile}>
                <SeeMore isMobile={isMobile} onClick={headleSeeMore(PanelType)}>
                    {`${pageLabel[wordingKeys.seeMoreLable]} >`}
                </SeeMore>
            </TopicsRightContianer>
        </TopicsContianer>);
};
const { avenirBlack } = Fonts.family;
const maxContentWidth = 1175;
const _isSafari = isSafari();
const _isFirefox = isFirefox();
const PanelContianer = styled.div `
display: flex;
margin-bottom: 45px;
${(props) => props.isMobile && css `margin-bottom: 27px;`}
${(props) => (props.isMobile && props.isLastPanel) && css `margin-bottom: 0px;`}`;
// const SpaceContiner = styled.div`
// `;
const TopicsContianer = styled.div `
display:flex;
padding: 0px 0px 0px 10px;
width: 100%;
text-align: letf;`;
const TopicsLeftContianer = styled.div `
flex: 2 1 0%;
`;
const TopicsRightContianer = styled.div `
flex: 1 1 0%;
max-width: 300px;
margin-right: ${((window.innerWidth < 1800) ? 0 : 5)}px;
${(props) => props.isMobile && css `margin-right: 0px;`}`;
const TopicsLable = styled.label `
height: 66px;
width: 100%;
color: ${Colors.theme};
font-family: ${avenirBlack};
font-size: 3rem;
font-weight: 900;
line-height: 66px;
letter-spacing: ${_isSafari ? -0.8 : 0}px;
${(props) => props.isMobile && css `font-size:24px;line-height:12px;`}`;
const SeeMore = styled.div `
height: 29px;
color: ${Colors.theme};
font-family: ${avenirBlack};
font-size: 21px;
font-weight: 900;
line-height: 29px;
float: right;
margin-right: 10px;
margin-top:37px;
cursor: pointer;
letter-spacing: ${_isSafari ? -0.8 : 0}px;
${(props) => props.isMobile && css `font-size:12px;margin-right: 10px;margin-top:-5px;`}`; // width: 116px;
const CenterContent = styled.div `
flex: 5;
height: 100%;
width: 100%;
max-width:${maxContentWidth}px;
marginTop: 1.25rem;
marginBottom: 1.25rem;
${(props) => props.isUnderDefault && css `width:100%;max-width:${maxContentWidth}px;`}
${(props) => props.isMobile && css `width:100%;max-width:unset;`}}`;
// ============================================================================================================
const style = {
    gridList: {
        // width: '100%',
        height: '100%',
        display: _isFirefox ? '-moz-box' : '-webkit-box',
        flexWrap: 'nowrap',
    },
    gridListMax: {
        height: '100%',
        display: _isFirefox ? '-moz-box' : '-webkit-box',
        maxWidth: maxContentWidth,
        minWidth: maxContentWidth,
        flexWrap: 'nowrap',
    },
    gridTabletList: {
        width: '100%',
        height: '100%',
        minWidth: 600,
        maxWidth: maxContentWidth,
        flexWrap: 'nowrap',
    },
    gridMobileList: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        display: '-webkit-box',
        flexWrap: 'nowrap',
    },
    gridTileStyle: {
        width: 'unset',
        height: '100%',
    },
};
