export const Styles = {
    absoluteCenter: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        position: 'absolute',
    },
    unselected: {
        userSelect: 'none',
    },
    sharpenImage: `
    image-rendering: auto;
        // image-rendering: -moz-crisp-edges;         /* Firefox */
        // image-rendering: -o-crisp-edges;         /* Opera */
        // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        // image-rendering: crisp-edges;
        // -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    `,
};
