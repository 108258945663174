import React from 'react';
import { Svg, Colors } from "../../themes";
const CloseMark = (props) => {
    return (<div onClick={props.onClick} style={{
        // position: "absolute",
        // right: 30,
        // top: 30,
        cursor: "pointer",
        ...props.style,
    }}>
            <Svg.xWhite color={Colors.theme}/>
        </div>);
};
export default CloseMark;
