import * as React from 'react';
import { AUTHEN_DIALOG_MODE } from "../../containers/AuthenDialogWrapper";
import UserActions from "../../redux/UserRedux";
import Space from '../Space';
import Button from '../button';
import Text from '../text';
import HeaderText from "./HeaderText";
import { Form } from '../form';
import Dialog from "../dialog";
import formtypes from '../../formtypes';
import { Colors } from '../../themes';
import localization from "../../services/localization";
import store from '../../redux';
import { reduxKeys } from '../../constant';
class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.ref = {};
        props.hocRef(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    isChanged() {
        return this.ref.isChanged();
    }
    onSubmit() {
        const { success, data } = this.ref.submit();
        const { [reduxKeys.settings]: { language } } = store.getState();
        if (success) {
            const { email } = data;
            this.props.dispatch(UserActions.forgotPasswordRequest({ user: { email }, language }, this.props.handleForgotSuccess));
        }
    }
    render() {
        const { isMobile } = this.props;
        // 414 is iPhone7+
        const isTinyMobile = window.innerWidth < 414;
        return (<React.Fragment>
                <HeaderText style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: isTinyMobile ? 25 : '',
        }}>
                    {localization(`password_recovery`, `AuthenLabel`)}
                    <Dialog.CloseMark onClick={this.props.onHide}/>
                </HeaderText>
                <Space />

                <Form form={formtypes.forgotPassword({ onPressEnter: this.onSubmit })} ref={(ref) => this.ref = ref}/>

                <Text.Avenir color={Colors.grayScale['86']} size={isTinyMobile ? 14 : isMobile ? 19 : 20}>
                    {localization(`recovery_method`, `AuthenLabel`)}
                </Text.Avenir>
                <Space />

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text.Avenir pointer onClick={() => { this.props.changeMode(AUTHEN_DIALOG_MODE.LOGIN); }} style={{ fontWeight: 500, color: Colors.theme, fontSize: isTinyMobile ? 15 : 20 }}>
                        {localization(`back_to_login`, `AuthenLabel`)}
                    </Text.Avenir>
                    <Button.Theme label={localization(`reset_password`, `AuthenLabel`)} 
        // width={isTinyMobile ? 150 : isMobile ? 190 : 230}
        width={'50%'} onClick={this.onSubmit} letterSpacing={0}/>
                </div>
            </React.Fragment>);
    }
}
export default ForgotPassword;
