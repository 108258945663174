export default {
    pickupNote: {
        presence: {
            allowEmpty: false,
            message: '^error_require_pickup_note',
        },
    },
    dropoffNote: {
        presence: {
            allowEmpty: false,
            message: '^error_require_dropoff_note',
        },
    },
    arrivalAirline: {
        presence: {
            allowEmpty: false,
            message: '^error_require_arrival_airline',
        },
    },
    arrivalFlightNo: {
        presence: {
            allowEmpty: false,
            message: '^error_require_arrival_flight_no',
        },
    },
    roomNo: {
        presence: {
            allowEmpty: false,
            message: '^error_require_room_no',
        },
    },
};
