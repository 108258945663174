import { path } from "ramda";
import { validateActions } from '../utils/validator';
import transform from "../utils/transform";
import { inputType, mockOptions } from "../constant";
import localization from "../services/localization";
import { nationalities } from "../constant/json/nationalities";
export const fieldsName = {
    title: 'title',
    firstName: 'firstName',
    lastName: 'lastName',
    gender: 'gender',
    nationality: 'nationality',
    nationalityId: 'nationalityId',
    birthDate: 'birthDate',
};
export default ({ onPressEnter, defaultValues, isMobile, firstForm }) => ({
    [fieldsName.title]: {
        label: localization(`title`, `ProfileLabel`),
        type: inputType.RADIO,
        require: true,
        validate: (val) => {
            if (val === 0 || val === '') {
                return 'error_require_title';
            }
        },
        md: isMobile ? 12 : 3,
        style: {
            md: {
            // paddingRight: '20px',
            },
        },
        value: firstForm ? defaultValues[fieldsName.title] ?
            transform.userTitle(defaultValues[fieldsName.title])
            :
                undefined : undefined,
        options: mockOptions.title,
    },
    [fieldsName.firstName]: {
        label: localization(`firstname_info`, `BookingLabel`),
        placeholder: localization(`name_placeholder`, `BookingLabel`),
        require: true,
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_firstname,
        value: firstForm ? path([fieldsName.firstName], defaultValues) : null,
        md: isMobile ? 12 : 4,
        style: {
            default: {
            // paddingTop: '10px',
            },
            md: {
                // paddingTop: '10px',
                paddingRight: '20px',
                maxWidth: '37.5%',
                flexBasis: '37.5%',
            },
        },
    },
    [fieldsName.lastName]: {
        label: localization(`lastname_info`, `BookingLabel`),
        placeholder: localization(`name_placeholder`, `BookingLabel`),
        require: true,
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_lastname,
        value: firstForm ? path([fieldsName.lastName], defaultValues) : null,
        onPressEnter,
        md: isMobile ? 12 : 4,
        style: {
            default: {
            // paddingTop: '20px',
            },
            md: {
                // paddingTop: '10px',
                paddingLeft: '20px',
                maxWidth: '37.5%',
                flexBasis: '37.5%',
            },
        },
    },
    [fieldsName.gender]: {
        label: localization(`gender`, `BookingLabel`),
        placeholder: localization(`gender`, `BookingLabel`),
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_gender,
        require: true,
        value: path([fieldsName.gender], defaultValues),
        md: isMobile ? 12 : 3,
        style: {
            md: {
                paddingRight: '40px',
            },
        },
    },
    [fieldsName.nationality]: {
        label: localization(`nationality`, `BookingLabel`),
        placeholder: localization(`nationality`, `BookingLabel`),
        type: inputType.SELECT,
        validate: validateActions.user_nationality,
        value: path([fieldsName.nationality], defaultValues),
        choices: nationalities.map((each) => ({ id: each.label, label: each.label })),
        filter: true,
        md: isMobile ? 12 : 4,
        style: {
            default: {
                paddingRight: '10px',
            },
            md: {
                paddingRight: '30px',
                maxWidth: '37.5%',
                flexBasis: '37.5%',
            },
        },
        options: {
            selector: {
                style: {
                    backgroundColor: '#fff',
                },
                collapseStyle: {
                    position: isMobile ? 'relative' : 'absolute',
                    width: isMobile ? '100%' : '370px',
                },
            },
        },
    },
    [fieldsName.nationalityId]: {
        label: localization(`passport`, `BookingLabel`),
        placeholder: localization(`passport`, `BookingLabel`),
        require: true,
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_nationalityId,
        value: path([fieldsName.nationalityId], defaultValues),
        choices: mockOptions.socialOption,
        md: isMobile ? 12 : 4,
        style: {
            md: {
                // paddingTop: '10px',
                paddingLeft: '20px',
                maxWidth: '37.5%',
                flexBasis: '37.5%',
            },
        },
    },
    [fieldsName.birthDate]: {
        label: localization(`dateofbirth`, `BookingLabel`),
        type: inputType.CALENDAR,
        require: true,
        validate: (val) => {
            if (val === "" || val === null) {
                return 'error_require_date_of_birth';
            }
        },
        dateofbirth: true,
        md: isMobile ? 12 : 4,
        style: {
            md: {
                paddingRight: '40px',
            },
        },
    },
});
