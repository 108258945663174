import * as React from 'react';
import Facebook from 'react-facebook-login';
// import { Redirect, Route, Router } from "react-router-dom";
import config from '../../../config';
import { Svg, Fonts } from "../../../themes";
import RaisedButton from '../RaisedButton';
import { userServices } from "../../../services";
import { socialType } from "../../../constant";
import localization from "../../../services/localization";
import { isMobile } from '../../../containers/PackageInfoPanel/GetItemColsPerWidth';
// import validator, { validateActions } from '../../../utils/validator';
const { directionURL, social } = config;
const FacebookLogin = (props) => {
    const isMobiles = isMobile();
    const { appId, permission } = social.facebook;
    // const { [reduxKeys.user]: { socialCache } } = store.getState();
    // (_isMobile) && Store.dispatch(AuthenActions.logoutRequest());
    // _isMobile && (socialCache !== null) && Store.dispatch(AuthenActions.logoutRequest());
    return (<Facebook appId={appId} fields={permission} autoLoad={true} isMobile={isMobiles} disableMobileRedirect={true} redirectUri={directionURL} buttonStyle={{
        background: 'transparent',
        border: 'none',
        padding: 0,
        width: '100%',
    }} textButton="" tag="div" icon={(<RaisedButton buttonStyle={{ backgroundColor: '#4267b2', padding: 0, width: '100%' }} label={props.label || localization(`continue_with_facebook`, `AuthenLabel`)} labelStyle={{
        fontFamily: Fonts.family.avenir,
        letterSpacing: '.25px',
        margin: '0 24px 0 12px',
        overflow: 'hidden',
        textOverflow: 'clip',
        whiteSpace: 'nowrap',
    }} onClick={() => localStorage.setItem('loginFace', "true")} icon={(<div style={{
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
                            <Svg.facebook style={{ width: '24px', height: '24px' }}/>
                        </div>)}/>)} callback={(res) => {
        userServices.onSocialCallback(res, socialType.FACEBOOK, props.handleLoginSuccess);
    }}/>);
};
export default FacebookLogin;
// ============================================================================================
// return (
//     <Facebook
//         appId={config.social.facebook.appId}
//         fields={config.social.facebook.permission}
//         cssClass="FacebookButton"
//         tag="div"
//         textButton={(
//             <div
//                 style={{
//                     alignItems: 'center',
//                     border: 'none',
//                     display: 'flex',
//                     fontFamily: 'Helvetica, Arial, sans-serif',
//                     letterSpacing: '.25px',
//                     margin: '0 24px 0 12px',
//                     overflow: 'hidden',
//                     textOverflow: 'clip',
//                     whiteSpace: 'nowrap',
//                 }}>
//                 Continue with Facebook
//             </div>
//         )}
//         icon={(
//             <div style={{
//                 alignItems: 'center',
//                 display: 'flex',
//                 height: '40px',
//                 justifyContent: 'center',
//                 width: '40px',
//             }}>
//                 <Svg.facebook style={{
//                     height: '24px',
//                     width: '24px',
//                 }} />
//             </div>
//         )}
//         // callback={(res) => { onSocialLogin('facebook', res, props.handleSocialAction) }}
//         callback={(res) => console.warn('res', res)}
//     />
// );
// ===========================================================================================
// ===========================================================================================
// const FacebookLogin = (props) => {
//     return (
//         <div
//             className="fb-login-button"
//             data-max-rows="1"
//             data-size="large"
//             data-button-type="continue_with"
//             data-show-faces="false"
//             data-auto-logout-link="false"
//             data-use-continue-as="false">
//         </div>
//     );
// };
// const FacebookLogin = (props) => {
//     return (
//         <div onClick={props.onClick}
//             style={{
//                 backgroundColor: '#4267b2',
//                 borderRadius: '4px',
//                 color: '#fff',
//                 cursor: 'pointer',
//                 display: 'inline-flex',
//                 maxWidth: '400px',
//             }}>
//             <div
//                 style={{
//                     alignItems: 'center',
//                     display: 'flex',
//                     height: '40px',
//                     justifyContent: 'center',
//                     width: '40px',
//                 }}>
//                 <Svg.facebook style={{
//                     height: '24px',
//                     width: '24px',
//                 }} />
//             </div>
//             <div
//                 style={{
//                     alignItems: 'center',
//                     border: 'none',
//                     display: 'flex',
//                     fontFamily: 'Helvetica, Arial, sans-serif',
//                     letterSpacing: '.25px',
//                     margin: '0 24px 0 12px',
//                     overflow: 'hidden',
//                     textOverflow: 'clip',
//                     whiteSpace: 'nowrap',
//                 }}>
//                 Continue with Facebook
//             </div>
//         </div>
//     );
// };
// ======================================================================================
