import React from "react";
// import { Colors } from "../../themes";
const Sale = ({ viewBox = "0 0 88 70" }) => (<svg width="88px" height="70px" viewBox={`${viewBox}`} version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Landing-Page-Login" transform="translate(-569.000000, -1019.000000)" fill="#EA2326">
                <g id="Group-7" transform="translate(569.000000, 1019.000000)">
                    <polygon id="label_sale" points="4.19220214e-13 -1.20792265e-13 88 -1.20792265e-13 88 70"></polygon>
                </g>
            </g>
        </g>
    </svg>);
export default {
    Sale,
};
