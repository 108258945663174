export default {
    confirm_payment: 'Confirm Payment',
    booking_no: 'Booking No.',
    booking_status: 'Your Booking status',
    payment_to_bank: 'Payment to bank account',
    amount_of_money: 'Amount of money',
    time: 'Time',
    note: 'Note',
    // bookingSuccess
    open_ticket: 'Open Ticket as PDF',
    manage_my_booking: 'Manage My Booking',
    your_booking_success: 'Your Booking Success !',
    your_booking_incomplete: 'Your Booking Incomplete !',
    your_booking_inprocess: 'Your Booking In-process !',
    go_landing_page: 'Back to the main page',
    invalid_security_code: 'the security code is invalid',
    // bookingPreview
    booking_preview: 'Booking Preview',
    travel_time: 'Time',
    number_of_adults: 'Number of Adults',
    number_of_kid: 'Number of Kid',
    number_of_infant: 'Number of Infant',
    change_date_detail: 'Change dates & details',
    change_special: 'Change Special Request',
    confirm: 'CONFIRM',
    quantity_of: 'Quantity of',
    passengers: 'Max Passengers',
    transport_type: 'Transport Type',
    special_request: 'Special Request',
    person: 'Person',
    time_slot: 'Time Slot',
    vehicle: 'Vehicle',
    total_price: 'Total Price',
    thb: 'THB',
    // paymentDetail
    customer_information: 'Customer Information',
    additional_information: 'Additional Information',
    select_payment_channel: 'Select Payment Channel',
    credit_card: 'Credit Card / Debit Card',
    counter_payment: 'Counter Payment & ATM',
    use_my_account: 'Use my account information',
    are_your_booking: `Are your booking details correct?`,
    our_eticket: `Our E-Ticket will be sent to`,
    its_appointment: `its appointment will be call to`,
    please_confirm: `Please confirm if your contact details are correct.`,
    change: `Change`,
    correct: `Correct`,
    firstname_info: 'First Name (as on Travel Document)',
    lastname_info: 'Family Name (as on Travel Document)',
    name_placeholder: 'As shown on passport',
    currency_support: 'Current currency support: THB',
    home_page: 'Home Page',
    payment_instructions: 'Payment instructions have been sent to your email.',
    payment_before: 'Make a Payment Before',
    transfer_to: 'Please Transfer to',
    complete_payment: 'Complete your payment within',
    payment_to: 'Payment to Tesco Lotus',
    booking_detail: 'Booking Detail',
    completed_payment: 'Completed Your Payment',
    bill_payment: 'Bill Payment',
    condition_bill: 'Tesco Lotus charges a small transaction fee of THB 10 from the payee at payment point.',
    for_payment: 'For payment in Thailand with THB currency only.',
    processing_fee: 'Processing fee will charge per transaction.',
    email_confirm: 'Email and SMS Confirmation will be send after payment completed.',
    to_avoid: 'to avoid time-out error.',
    must_register: `Must register for Internet Banking Account via your Bank's provider channel BEFORE make a booking.`,
    fee: 'Processing Fee',
    select_bank: 'Select preferred bank',
    hours: 'hours',
    minutes: 'minutes',
    step: 'Step',
    step1_ibanking: 'Select preferred bank and click "BOOK" to confirm your booking.',
    step2_ibanking: 'Login to your account on bank to make payment. Click "Bank to Merchant" upon payment completed.',
    step3_ibanking: 'Get your booking confirmation via email and SMS',
    gender: 'Gender',
    passport: 'Passport No. / Nationality ID',
    dateofbirth: 'Date of birth',
    // additional
    pick_up_detail: 'Pick-Up Detail',
    drop_off_detail: 'Drop-Off Detail',
    pick_up: 'Pick-Up Location',
    drop_off: 'Drop-Off Location',
    pick_up_note: 'Pick-Up Note',
    where_you_want_pick_up: 'Where you want we to get pick you up',
    where_you_want_drop_off: 'Where you want we to get drop you up',
    arrival_airline: 'Arrival Airline',
    arrival_flight_no: 'Arrival Flight No.',
    arrival_time: 'Arrival Time',
    departure_airline: 'Departure Airline',
    departure_flight_no: 'Departure Flight No.',
    departure_time: 'Departure Time',
    other: 'Other',
    book: 'BOOK',
    next: 'NEXT',
    placeholder_location: 'Select your hotel zone',
    placeholder_note: 'Enter hotel name and address',
    placeholder_request: 'Select special request',
    please_explain: 'Please explain your request: arraival time, food allergy...',
    more_detail: 'More Detail',
    please_input_time: 'Please input time',
    roomNo: 'Room No.',
    nationality: 'Nationality',
    // credit/debit
    name_on_card: 'Name on card',
    credit_card_number: 'Credit Card number',
    expire: 'Expire',
    security_code: 'Security code',
    conditions: 'Conditions',
    you_can_pay: 'You can pay with',
    data_security: 'Data Security',
    full_payment: 'Full payment will be charged on your credit card upon booking.',
    our_reservation: 'Our reservation system is secure.',
    by_choosing_to_book: 'By choosing to book, I acknowledge having read and agreed to terms and conditions and privacy policy.',
    when_paying: 'When paying by credit card, your card is charged the total amount as soon as we receive confirmation of your booking.',
    if_confirmation: `If confirmation isn't received instantly, an authorization is held against your card until it arrives.`,
    if_you: 'If you change or cancel your booking request, charges may apply.',
    your_info: `Your info's safe with us. All data is`,
    encrypted: ' encrypted and transmitted securely',
    with_protocal: ' with an SSL protocal.',
    platforms: 'Phi Phi Tours ',
    respect: 'respect your privacy.',
    we_do_not: ' We do not sell your personal information to anyone.',
    platforms_terms: `Phi Phi Tours Terms and Conditions`,
    and: ' and ',
    privacy_statement: 'Privacy Policy.',
    detail: 'Detail ',
    // ibank
    read_before_pay: 'Read Before You Pay',
    ibank_detail: 'You can make secure and convenient payments via Internet Banking, Mobile Banking or ATM.',
    ibank1: 'The transfer amount must be an exact amount stated below otherwise the booking will not be processed.',
    ibank2: 'International funds transfer from overseas bank accounts for any booking will not be facilitated and processed. Be advised that an international transfer fee will be deducted from the refund.',
    ibank3: 'The originating bank and beneficiary bank must be the same in order to quicken the payment confirmation process.',
    ibank4: 'Transfer can be done via Mobile Banking, Internet Banking, ATM or bank counter.',
};
