import * as React from 'react';
import styled from 'styled-components';
import { Images } from '../themes';
import { isMobile } from '../containers/PackageInfoPanel/GetItemColsPerWidth';
const ImgWithStyle = styled.img `
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
`;
const Logo = ({ style, color = 'white', onClick, width = '', height = '', isPartner }) => {
    return (<ImgWithStyle alt='phiphitours' style={{
        cursor: !!onClick ? 'pointer' : '',
        width,
        height,
        ...style,
    }} onClick={onClick} src={isPartner ? Images.logo.Logo_partner : color === 'white' ? isMobile() ? Images.logo.Logo_footer : Images.logo.LogoMobile : Images.logo.Logo}/>);
};
export default Logo;
