import { inputType } from "../constant";
import { validateActions } from '../utils/validator';
import { fieldsName as regiterFieldsName } from "./register";
export default ({ onPressEnter }) => ({
    [regiterFieldsName.email]: {
        label: 'E-mail',
        inputProps: {
            type: 'email',
        },
        placeholder: 'Email',
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_email,
        value: '',
        onPressEnter,
    },
});
