import { path } from 'ramda';
import { put, call } from 'redux-saga/effects';
import ErrorActions, { ERROR_KEY } from "../redux/ErrorRedux";
import Dialog from "../components/dialog";
import errorGenerator from "../services/errorGenerator";
function* getErrorRequest(api) {
    try {
        const res = yield call(api.getErrorCode);
        if (path(['status'], res) === 200) {
            yield put(ErrorActions.getErrorCodeSuccess(path(['data', 'result', 'data'], res)));
        }
        else {
            Dialog.error({ key: ERROR_KEY, ...errorGenerator(res) });
            yield put(ErrorActions.getErrorCodeFailure('get error codes fail'));
        }
    }
    catch (e) {
        yield put(ErrorActions.getErrorCodeFailure(e));
    }
}
export { getErrorRequest };
