import * as React from 'react';
import RaisedButton from "./RaisedButton";
import { Colors, Fonts } from "../../themes";
import { isSafari } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
const safari = isSafari();
const { avenir, avenirBlack, avenirHeavy } = Fonts.family;
const ThemeLight = ({ onClick, background = Colors.themeLight, label, letterSpacing = safari ? '-1px' : '2.85px', lineHeight = '19px', fontSize = '16px', height = '100%', width = '100%', fontFamily = avenir, buttonStyle = {}, }) => {
    fontFamily = fontFamily === 'black' ? avenirBlack : fontFamily === 'heavy' ? avenirHeavy : avenir;
    return (<RaisedButton onClick={onClick} buttonStyle={{
        background,
        borderRadius: '0px',
        padding: '10px 40px',
        height,
        width,
        boxShadow: 'none',
        ...buttonStyle,
    }} label={label || 'Default'} labelStyle={{
        fontSize,
        fontFamily,
        fontWeight: 'bold',
        letterSpacing,
        lineHeight,
        color: '#fff',
    }}/>);
};
export default ThemeLight;
