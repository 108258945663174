export const FakeBookedInfoList = [
    {
        packageId: "PKD-20181018-000001",
        isBestSeller: true,
        booked: 8703,
        point: 261,
        reviews: {
            0: 0,
            1: 5,
            2: 8,
            3: 56,
            4: 94,
            5: 98,
        },
    },
    {
        packageId: "PKD-20181018-000002",
        isBestSeller: true,
        booked: 9864,
        point: 295,
        reviews: {
            0: 0,
            1: 6,
            2: 9,
            3: 44,
            4: 98,
            5: 138,
        },
    },
    {
        packageId: "PKD-20181018-000003",
        isBestSeller: true,
        booked: 7597,
        point: 228,
        reviews: {
            0: 0,
            1: 3,
            2: 43,
            3: 14,
            4: 70,
            5: 137,
        },
    },
    {
        packageId: "PKD-20181018-000004",
        isBestSeller: false,
        booked: 4600,
        point: 138,
        reviews: {
            0: 0,
            1: 3,
            2: 4,
            3: 24,
            4: 65,
            5: 42,
        },
    },
    {
        packageId: "PKD-20181018-000005",
        isBestSeller: true,
        booked: 5780,
        point: 172,
        reviews: {
            0: 0,
            1: 3,
            2: 5,
            3: 26,
            4: 85,
            5: 53,
        },
    },
    {
        packageId: "PKD-20181018-000006",
        isBestSeller: false,
        booked: 2854,
        point: 86,
        reviews: {
            0: 0,
            1: 2,
            2: 3,
            3: 13,
            4: 51,
            5: 17,
        },
    },
    {
        packageId: "PKD-20181027-000001",
        isBestSeller: false,
        booked: 2458,
        point: 73,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 11,
            4: 44,
            5: 15,
        },
    },
    {
        packageId: "PKD-20181027-000002",
        isBestSeller: true,
        booked: 5982,
        point: 180,
        reviews: {
            0: 0,
            1: 4,
            2: 5,
            3: 27,
            4: 108,
            5: 36,
        },
    },
    {
        packageId: "PKD-20181027-000003",
        isBestSeller: false,
        booked: 1298,
        point: 39,
        reviews: {
            0: 0,
            1: 1,
            2: 1,
            3: 6,
            4: 23,
            5: 8,
        },
    },
    {
        packageId: "PKD-20181027-000004",
        isBestSeller: false,
        booked: 1993,
        point: 60,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 9,
            4: 36,
            5: 12,
        },
    },
    {
        packageId: "PKD-20181027-000005",
        isBestSeller: false,
        booked: 2348,
        point: 70,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 11,
            4: 42,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181027-000006",
        isBestSeller: false,
        booked: 2145,
        point: 64,
        reviews: {
            0: 0,
            1: 2,
            2: 5,
            3: 6,
            4: 45,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181027-000007",
        isBestSeller: false,
        booked: 1908,
        point: 58,
        reviews: {
            0: 0,
            1: 2,
            2: 4,
            3: 6,
            4: 40,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181027-000008",
        isBestSeller: false,
        booked: 1870,
        point: 57,
        reviews: {
            0: 0,
            1: 2,
            2: 4,
            3: 6,
            4: 39,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181027-000009",
        isBestSeller: false,
        booked: 1567,
        point: 47,
        reviews: {
            0: 0,
            1: 1,
            2: 3,
            3: 5,
            4: 33,
            5: 5,
        },
    },
    {
        packageId: "PKD-20181027-000010",
        isBestSeller: false,
        booked: 1345,
        point: 40,
        reviews: {
            0: 0,
            1: 1,
            2: 3,
            3: 4,
            4: 28,
            5: 4,
        },
    },
    {
        packageId: "PKD-20181027-000012",
        isBestSeller: false,
        booked: 1456,
        point: 43,
        reviews: {
            0: 0,
            1: 1,
            2: 3,
            3: 4,
            4: 31,
            5: 4,
        },
    },
    {
        packageId: "PKD-20181027-000013",
        isBestSeller: true,
        booked: 4870,
        point: 146,
        reviews: {
            0: 0,
            1: 4,
            2: 10,
            3: 15,
            4: 102,
            5: 15,
        },
    },
    {
        packageId: "PKD-20181027-000014",
        isBestSeller: false,
        booked: 1568,
        point: 47,
        reviews: {
            0: 0,
            1: 1,
            2: 3,
            3: 5,
            4: 33,
            5: 5,
        },
    },
    {
        packageId: "PKD-20181029-000001",
        isBestSeller: false,
        booked: 1890,
        point: 58,
        reviews: {
            0: 0,
            1: 2,
            2: 4,
            3: 6,
            4: 40,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181029-000002",
        isBestSeller: true,
        booked: 6830,
        point: 203,
        reviews: {
            0: 0,
            1: 6,
            2: 14,
            3: 20,
            4: 85,
            5: 78,
        },
    },
    {
        packageId: "PKD-20181029-000003",
        isBestSeller: false,
        booked: 2213,
        point: 67,
        reviews: {
            0: 0,
            1: 2,
            2: 5,
            3: 7,
            4: 46,
            5: 7,
        },
    },
    {
        packageId: "PKD-20181029-000004",
        isBestSeller: true,
        booked: 7469,
        point: 224,
        reviews: {
            0: 0,
            1: 7,
            2: 16,
            3: 22,
            4: 157,
            5: 22,
        },
    },
    {
        packageId: "PKD-20181029-000007",
        isBestSeller: false,
        booked: 2145,
        point: 65,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 10,
            4: 42,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181029-000008",
        isBestSeller: true,
        booked: 5792,
        point: 173,
        reviews: {
            0: 0,
            1: 3,
            2: 5,
            3: 26,
            4: 83,
            5: 56,
        },
    },
    {
        packageId: "PKD-20181029-000009",
        isBestSeller: false,
        booked: 1345,
        point: 40,
        reviews: {
            0: 0,
            1: 1,
            2: 1,
            3: 6,
            4: 26,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181029-000010",
        isBestSeller: false,
        booked: 1456,
        point: 44,
        reviews: {
            0: 0,
            1: 0,
            2: 0,
            3: 2,
            4: 4,
            5: 37,
        },
    },
    {
        packageId: "PKD-20181029-000011",
        isBestSeller: false,
        booked: 1376,
        point: 41,
        reviews: {
            0: 0,
            1: 1,
            2: 1,
            3: 7,
            4: 27,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181030-000003",
        isBestSeller: false,
        booked: 1357,
        point: 40,
        reviews: {
            0: 0,
            1: 1,
            2: 1,
            3: 6,
            4: 26,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181030-000004",
        isBestSeller: false,
        booked: 1872,
        point: 56,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 8,
            4: 37,
            5: 8,
        },
    },
    {
        packageId: "PKD-20181030-000006",
        isBestSeller: false,
        booked: 1354,
        point: 40,
        reviews: {
            0: 0,
            1: 1,
            2: 1,
            3: 6,
            4: 26,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181030-000007",
        isBestSeller: false,
        booked: 1483,
        point: 45,
        reviews: {
            0: 0,
            1: 1,
            2: 1,
            3: 7,
            4: 29,
            5: 7,
        },
    },
    {
        packageId: "PKD-20181018-000007",
        isBestSeller: false,
        booked: 4589,
        point: 138,
        reviews: {
            0: 0,
            1: 3,
            2: 4,
            3: 21,
            4: 89,
            5: 21,
        },
    },
    {
        packageId: "PKD-20181018-000008",
        isBestSeller: false,
        booked: 3376,
        point: 101,
        reviews: {
            0: 0,
            1: 2,
            2: 3,
            3: 15,
            4: 66,
            5: 15,
        },
    },
    {
        packageId: "PKD-20181018-000009",
        isBestSeller: true,
        booked: 6838,
        point: 205,
        reviews: {
            0: 0,
            1: 4,
            2: 6,
            3: 31,
            4: 133,
            5: 31,
        },
    },
    {
        packageId: "PKD-20181018-000010",
        isBestSeller: false,
        booked: 4982,
        point: 148,
        reviews: {
            0: 0,
            1: 3,
            2: 4,
            3: 22,
            4: 97,
            5: 22,
        },
    },
    {
        packageId: "PKD-20181018-000011",
        isBestSeller: true,
        booked: 5625,
        point: 168,
        reviews: {
            0: 0,
            1: 3,
            2: 5,
            3: 25,
            4: 110,
            5: 25,
        },
    },
    {
        packageId: "PKD-20181018-000012",
        isBestSeller: false,
        booked: 3789,
        point: 113,
        reviews: {
            0: 0,
            1: 2,
            2: 3,
            3: 17,
            4: 74,
            5: 17,
        },
    },
    {
        packageId: "PKD-20181018-000013",
        isBestSeller: false,
        booked: 4320,
        point: 129,
        reviews: {
            0: 0,
            1: 3,
            2: 4,
            3: 19,
            4: 84,
            5: 19,
        },
    },
    {
        packageId: "PKD-20181018-000014",
        isBestSeller: false,
        booked: 2156,
        point: 65,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 10,
            4: 42,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181018-000015",
        isBestSeller: false,
        booked: 1789,
        point: 54,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 8,
            4: 35,
            5: 8,
        },
    },
    {
        packageId: "PKD-20181018-000016",
        isBestSeller: false,
        booked: 2265,
        point: 67,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 10,
            4: 44,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181029-000019",
        isBestSeller: false,
        booked: 1543,
        point: 46,
        reviews: {
            0: 0,
            1: 1,
            2: 1,
            3: 7,
            4: 30,
            5: 7,
        },
    },
    {
        packageId: "PKD-20181029-000020",
        isBestSeller: false,
        booked: 1193,
        point: 36,
        reviews: {
            0: 0,
            1: 2,
            2: 5,
            3: 7,
            4: 18,
            5: 4,
        },
    },
    {
        packageId: "PKD-20181029-000021",
        isBestSeller: false,
        booked: 1764,
        point: 53,
        reviews: {
            0: 0,
            1: 3,
            2: 8,
            3: 11,
            4: 26,
            5: 5,
        },
    },
    {
        packageId: "PKD-20181029-000022",
        isBestSeller: false,
        booked: 1543,
        point: 46,
        reviews: {
            0: 0,
            1: 2,
            2: 7,
            3: 9,
            4: 23,
            5: 5,
        },
    },
    {
        packageId: "PKD-20181029-000023",
        isBestSeller: false,
        booked: 1672,
        point: 51,
        reviews: {
            0: 0,
            1: 3,
            2: 8,
            3: 10,
            4: 25,
            5: 5,
        },
    },
    {
        packageId: "PKD-20181029-000024",
        isBestSeller: false,
        booked: 1753,
        point: 53,
        reviews: {
            0: 0,
            1: 3,
            2: 8,
            3: 11,
            4: 26,
            5: 5,
        },
    },
    {
        packageId: "PKD-20181030-000001",
        isBestSeller: true,
        booked: 6591,
        point: 199,
        reviews: {
            0: 0,
            1: 10,
            2: 30,
            3: 40,
            4: 99,
            5: 20,
        },
    },
    {
        packageId: "PKD-20181030-000002",
        isBestSeller: true,
        booked: 4832,
        point: 144,
        reviews: {
            0: 0,
            1: 7,
            2: 22,
            3: 29,
            4: 72,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181030-000005",
        isBestSeller: false,
        booked: 1132,
        point: 34,
        reviews: {
            0: 0,
            1: 2,
            2: 5,
            3: 7,
            4: 17,
            5: 3,
        },
    },
    {
        packageId: "PKD-20181029-000005",
        isBestSeller: false,
        booked: 2356,
        point: 78,
        reviews: {
            0: 0,
            1: 4,
            2: 11,
            3: 14,
            4: 35,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181029-000006",
        isBestSeller: false,
        booked: 2789,
        point: 84,
        reviews: {
            0: 0,
            1: 4,
            2: 13,
            3: 17,
            4: 42,
            5: 8,
        },
    },
    {
        packageId: "PKD-20181029-000012",
        isBestSeller: true,
        booked: 4321,
        point: 129,
        reviews: {
            0: 0,
            1: 6,
            2: 19,
            3: 26,
            4: 65,
            5: 13,
        },
    },
    {
        packageId: "PKD-20181029-000013",
        isBestSeller: true,
        booked: 4789,
        point: 144,
        reviews: {
            0: 0,
            1: 7,
            2: 22,
            3: 29,
            4: 72,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181029-000014",
        isBestSeller: false,
        booked: 2210,
        point: 66,
        reviews: {
            0: 0,
            1: 3,
            2: 10,
            3: 13,
            4: 33,
            5: 7,
        },
    },
    {
        packageId: "PKD-20181029-000015",
        isBestSeller: false,
        booked: 1876,
        point: 56,
        reviews: {
            0: 0,
            1: 3,
            2: 8,
            3: 11,
            4: 28,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181112-000001",
        isBestSeller: false,
        booked: 2756,
        point: 220,
        reviews: {
            0: 0,
            1: 11,
            2: 33,
            3: 27,
            4: 76,
            5: 73,
        },
    },
    {
        packageId: "PKD-20181112-000002",
        isBestSeller: false,
        booked: 1978,
        point: 162,
        reviews: {
            0: 0,
            1: 8,
            2: 15,
            3: 45,
            4: 38,
            5: 56,
        },
    },
    {
        packageId: "PKD-20181123-000001",
        isBestSeller: false,
        booked: 1783,
        point: 89,
        reviews: {
            0: 0,
            1: 2,
            2: 5,
            3: 24,
            4: 40,
            5: 18,
        },
    },
    {
        packageId: "PKD-20181123-000002",
        isBestSeller: false,
        booked: 2398,
        point: 120,
        reviews: {
            0: 0,
            1: 1,
            2: 15,
            3: 25,
            4: 50,
            5: 29,
        },
    },
    {
        packageId: "PKD-20181111-000001",
        isBestSeller: true,
        booked: 6432,
        point: 322,
        reviews: {
            0: 0,
            1: 4,
            2: 20,
            3: 77,
            4: 87,
            5: 134,
        },
    },
    {
        packageId: "PKD-20181111-000002",
        isBestSeller: false,
        booked: 1563,
        point: 78,
        reviews: {
            0: 0,
            1: 2,
            2: 9,
            3: 13,
            4: 31,
            5: 23,
        },
    },
    {
        packageId: "PKD-20181111-000003",
        isBestSeller: true,
        booked: 4321,
        point: 216,
        reviews: {
            0: 0,
            1: 4,
            2: 8,
            3: 81,
            4: 84,
            5: 39,
        },
    },
    {
        packageId: "PKD-20181111-000004",
        isBestSeller: false,
        booked: 3212,
        point: 161,
        reviews: {
            0: 0,
            1: 4,
            2: 3,
            3: 57,
            4: 54,
            5: 43,
        },
    },
    {
        packageId: "PKD-20181116-000001",
        isBestSeller: false,
        booked: 3419,
        point: 171,
        reviews: {
            0: 0,
            1: 4,
            2: 12,
            3: 58,
            4: 54,
            5: 43,
        },
    },
    {
        packageId: "PKD-20181116-000002",
        isBestSeller: false,
        booked: 4762,
        point: 238,
        reviews: {
            0: 0,
            1: 5,
            2: 30,
            3: 93,
            4: 78,
            5: 32,
        },
    },
    {
        packageId: "PKD-20181116-000003",
        isBestSeller: false,
        booked: 3452,
        point: 173,
        reviews: {
            0: 0,
            1: 6,
            2: 12,
            3: 68,
            4: 59,
            5: 28,
        },
    },
    {
        packageId: "PKD-20181116-000004",
        isBestSeller: true,
        booked: 5742,
        point: 287,
        reviews: {
            0: 0,
            1: 12,
            2: 23,
            3: 120,
            4: 98,
            5: 34,
        },
    },
    {
        packageId: "PKD-20181116-000005",
        isBestSeller: false,
        booked: 3452,
        point: 173,
        reviews: {
            0: 0,
            1: 1,
            2: 3,
            3: 89,
            4: 57,
            5: 23,
        },
    },
    {
        packageId: "PKD-20181116-000006",
        isBestSeller: false,
        booked: 2648,
        point: 132,
        reviews: {
            0: 0,
            1: 2,
            2: 5,
            3: 34,
            4: 48,
            5: 43,
        },
    },
    {
        packageId: "PKD-20181116-000007",
        isBestSeller: true,
        booked: 5219,
        point: 261,
        reviews: {
            0: 0,
            1: 9,
            2: 32,
            3: 114,
            4: 76,
            5: 30,
        },
    },
    {
        packageId: "PKD-20181116-000008",
        isBestSeller: false,
        booked: 2561,
        point: 128,
        reviews: {
            0: 0,
            1: 2,
            2: 4,
            3: 36,
            4: 54,
            5: 32,
        },
    },
    {
        packageId: "PKD-20181116-000009",
        isBestSeller: false,
        booked: 2432,
        point: 122,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 36,
            4: 48,
            5: 35,
        },
    },
    {
        packageId: "PKD-20181116-000010",
        isBestSeller: false,
        booked: 4521,
        point: 226,
        reviews: {
            0: 0,
            1: 8,
            2: 21,
            3: 88,
            4: 78,
            5: 31,
        },
    },
    {
        packageId: "PKD-20181116-000011",
        isBestSeller: true,
        booked: 6547,
        point: 327,
        reviews: {
            0: 0,
            1: 7,
            2: 13,
            3: 138,
            4: 102,
            5: 67,
        },
    },
    {
        packageId: "PKD-20181116-000012",
        isBestSeller: false,
        booked: 1452,
        point: 73,
        reviews: {
            0: 0,
            1: 0,
            2: 2,
            3: 37,
            4: 20,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181116-000013",
        isBestSeller: false,
        booked: 1342,
        point: 67,
        reviews: {
            0: 0,
            1: 1,
            2: 3,
            3: 17,
            4: 34,
            5: 12,
        },
    },
    {
        packageId: "PKD-20181124-000001",
        isBestSeller: false,
        booked: 2310,
        point: 116,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 36,
            4: 52,
            5: 25,
        },
    },
    {
        packageId: "PKD-20181130-000001",
        isBestSeller: false,
        booked: 890,
        point: 45,
        reviews: {
            0: 0,
            1: 1,
            2: 4,
            3: 13,
            4: 18,
            5: 9,
        },
    },
    {
        packageId: "PKD-20181130-000003",
        isBestSeller: true,
        booked: 2310,
        point: 116,
        reviews: {
            0: 0,
            1: 2,
            2: 9,
            3: 35,
            4: 46,
            5: 23,
        },
    },
    {
        packageId: "PKD-20181130-000004",
        isBestSeller: false,
        booked: 769,
        point: 38,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 12,
            4: 15,
            5: 8,
        },
    },
    {
        packageId: "PKD-20181201-000001",
        isBestSeller: false,
        booked: 478,
        point: 24,
        reviews: {
            0: 0,
            1: 0,
            2: 1,
            3: 8,
            4: 10,
            5: 5,
        },
    },
    {
        packageId: "PKD-20181201-000002",
        isBestSeller: false,
        booked: 987,
        point: 49,
        reviews: {
            0: 0,
            1: 0,
            2: 4,
            3: 15,
            4: 20,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181201-000003",
        isBestSeller: false,
        booked: 1654,
        point: 83,
        reviews: {
            0: 0,
            1: 2,
            2: 7,
            3: 25,
            4: 33,
            5: 17,
        },
    },
    {
        packageId: "PKD-20181201-000004",
        isBestSeller: false,
        booked: 1487,
        point: 74,
        reviews: {
            0: 0,
            1: 2,
            2: 6,
            3: 22,
            4: 30,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181201-000005",
        isBestSeller: false,
        booked: 2198,
        point: 110,
        reviews: {
            0: 0,
            1: 2,
            2: 9,
            3: 33,
            4: 44,
            5: 22,
        },
    },
    {
        packageId: "PKD-20181201-000006",
        isBestSeller: false,
        booked: 987,
        point: 49,
        reviews: {
            0: 0,
            1: 0,
            2: 0,
            3: 15,
            4: 20,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181201-000007",
        isBestSeller: false,
        booked: 654,
        point: 33,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 10,
            4: 13,
            5: 7,
        },
    },
    {
        packageId: "PKD-20181201-000008",
        isBestSeller: false,
        booked: 754,
        point: 38,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 11,
            4: 15,
            5: 8,
        },
    },
    {
        packageId: "PKD-20181201-000010",
        isBestSeller: false,
        booked: 432,
        point: 22,
        reviews: {
            0: 0,
            1: 0,
            2: 0,
            3: 6,
            4: 10,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181201-000011",
        isBestSeller: false,
        booked: 890,
        point: 45,
        reviews: {
            0: 0,
            1: 0,
            2: 4,
            3: 13,
            4: 18,
            5: 9,
        },
    },
    {
        packageId: "PKD-20181201-000012",
        isBestSeller: false,
        booked: 674,
        point: 34,
        reviews: {
            0: 0,
            1: 0,
            2: 0,
            3: 10,
            4: 14,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181201-000013",
        isBestSeller: false,
        booked: 2467,
        point: 123,
        reviews: {
            0: 0,
            1: 2,
            2: 10,
            3: 37,
            4: 49,
            5: 25,
        },
    },
    {
        packageId: "PKD-20181201-000014",
        isBestSeller: false,
        booked: 1425,
        point: 71,
        reviews: {
            0: 0,
            1: 5,
            2: 12,
            3: 21,
            4: 19,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181201-000015",
        isBestSeller: true,
        booked: 2986,
        point: 149,
        reviews: {
            0: 0,
            1: 2,
            2: 11,
            3: 47,
            4: 59,
            5: 30,
        },
    },
    {
        packageId: "PKD-20181201-000016",
        isBestSeller: false,
        booked: 1540,
        point: 77,
        reviews: {
            0: 0,
            1: 1,
            2: 6,
            3: 24,
            4: 31,
            5: 15,
        },
    },
    {
        packageId: "PKD-20181201-000017",
        isBestSeller: false,
        booked: 1367,
        point: 68,
        reviews: {
            0: 0,
            1: 4,
            2: 5,
            3: 21,
            4: 25,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181201-000018",
        isBestSeller: false,
        booked: 1468,
        point: 73,
        reviews: {
            0: 0,
            1: 1,
            2: 6,
            3: 22,
            4: 29,
            5: 15,
        },
    },
    {
        packageId: "PKD-20181201-000019",
        isBestSeller: false,
        booked: 889,
        point: 44,
        reviews: {
            0: 0,
            1: 0,
            2: 4,
            3: 13,
            4: 18,
            5: 9,
        },
    },
    {
        packageId: "PKD-20181201-000020",
        isBestSeller: false,
        booked: 1672,
        point: 84,
        reviews: {
            0: 0,
            1: 2,
            2: 7,
            3: 25,
            4: 33,
            5: 17,
        },
    },
    {
        packageId: "PKD-20181201-000021",
        isBestSeller: true,
        booked: 2387,
        point: 119,
        reviews: {
            0: 0,
            1: 2,
            2: 10,
            3: 36,
            4: 48,
            5: 24,
        },
    },
    {
        packageId: "PKD-20181201-000022",
        isBestSeller: true,
        booked: 2467,
        point: 123,
        reviews: {
            0: 0,
            1: 2,
            2: 10,
            3: 37,
            4: 49,
            5: 25,
        },
    },
    {
        packageId: "PKD-20181201-000023",
        isBestSeller: false,
        booked: 987,
        point: 49,
        reviews: {
            0: 0,
            1: 0,
            2: 4,
            3: 15,
            4: 20,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181201-000024",
        isBestSeller: false,
        booked: 1356,
        point: 68,
        reviews: {
            0: 0,
            1: 4,
            2: 8,
            3: 17,
            4: 25,
            5: 14,
        },
    },
    {
        packageId: "PKD-20181201-000025",
        isBestSeller: true,
        booked: 3012,
        point: 151,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 45,
            4: 70,
            5: 33,
        },
    },
    {
        packageId: "PKD-20181201-000026",
        isBestSeller: false,
        booked: 432,
        point: 22,
        reviews: {
            0: 0,
            1: 0,
            2: 2,
            3: 6,
            4: 9,
            5: 4,
        },
    },
    {
        packageId: "PKD-20181201-000027",
        isBestSeller: false,
        booked: 987,
        point: 49,
        reviews: {
            0: 0,
            1: 0,
            2: 4,
            3: 15,
            4: 20,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181201-000028",
        isBestSeller: false,
        booked: 1456,
        point: 73,
        reviews: {
            0: 0,
            1: 4,
            2: 8,
            3: 22,
            4: 24,
            5: 15,
        },
    },
    {
        packageId: "PKD-20181201-000029",
        isBestSeller: false,
        booked: 563,
        point: 28,
        reviews: {
            0: 0,
            1: 0,
            2: 2,
            3: 8,
            4: 11,
            5: 6,
        },
    },
    {
        packageId: "PKD-20181201-000030",
        isBestSeller: false,
        booked: 780,
        point: 39,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 12,
            4: 16,
            5: 8,
        },
    },
    {
        packageId: "PKD-20181201-000031",
        isBestSeller: false,
        booked: 965,
        point: 48,
        reviews: {
            0: 0,
            1: 0,
            2: 1,
            3: 13,
            4: 24,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181201-000032",
        isBestSeller: true,
        booked: 1987,
        point: 99,
        reviews: {
            0: 0,
            1: 0,
            2: 2,
            3: 28,
            4: 49,
            5: 20,
        },
    },
    {
        packageId: "PKD-20181201-000034",
        isBestSeller: false,
        booked: 1653,
        point: 83,
        reviews: {
            0: 0,
            1: 1,
            2: 2,
            3: 27,
            4: 36,
            5: 17,
        },
    },
    {
        packageId: "PKD-20181201-000035",
        isBestSeller: false,
        booked: 992,
        point: 50,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 16,
            4: 20,
            5: 11,
        },
    },
    {
        packageId: "PKD-20181201-000036",
        isBestSeller: false,
        booked: 854,
        point: 43,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 13,
            4: 17,
            5: 10,
        },
    },
    {
        packageId: "PKD-20181201-000037",
        isBestSeller: false,
        booked: 760,
        point: 38,
        reviews: {
            0: 0,
            1: 0,
            2: 3,
            3: 12,
            4: 15,
            5: 8,
        },
    },
];
