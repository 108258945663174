import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { path } from "ramda";
// import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from "../Logo";
import Dialog from "../dialog";
import Avatar, { getRandomColor } from 'react-avatar';
import { reduxKeys, pathName } from "../../constant";
import { logout } from "../../sagas/ManageStoreSaga";
import { Fonts, Colors, Images, Svg } from "../../themes";
import SettingActions, { SETTING_KEY } from "../../redux/SettingsRedux";
import { AUTHEN_DIALOG_MODE } from "../../containers/AuthenDialogWrapper";
import config, { isPlatForm } from '../../config';
import { optionsServices } from "../../services";
import localization from '../../services/localization';
import reloadService from '../../services/reloadService';
import { PLATFORM_NAME } from '../../config/platform';
const styles = {
    root: { width: '100%' },
    // flex: { flex: 1,},
    menuButton: {
        marginLeft: -12,
    },
    toolBar: {
        color: '#fff',
        justifyContent: 'space-between',
    },
    list: {
        width: '100vw',
        fontFamily: Fonts.family.avenir,
    },
    paper: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: '#fff',
        overflow: 'unset',
    },
    text: {
        color: '#fff',
        fontSize: 20,
    },
    subText: {
        color: '#fff',
        fontSize: 16,
    },
};
const sidebar = (props) => [
    {
        label: 'Home', path: pathName.INDEX,
        icon: 'fas fa-home', render: true,
    },
    {
        label: 'About Us', path: pathName.ABOUT_US,
        icon: 'fas fa-users', render: true,
    },
    {
        label: 'How To Book', path: pathName.HOW_TO_BOOK,
        icon: 'far fa-calendar-alt', render: true,
    },
    {
        label: 'Profile', path: pathName.PROFILE,
        icon: 'fas fa-user-circle', render: props.isLoggedin,
    },
    {
        label: 'Wish List', path: pathName.WISH_LIST,
        icon: 'fas fa-heart', render: props.isLoggedin,
    },
    {
        label: 'Bussiness Partners', path: undefined,
        icon: 'fas fa-user-tie', render: true,
    },
    {
        label: 'Currency', path: undefined,
        icon: 'fas fa-dollar-sign', render: true,
    },
    {
        label: 'Language', path: undefined,
        icon: 'fas fa-globe', render: true,
    },
    {
        label: 'Contact Us', path: pathName.CONTACT_US,
        icon: 'fas fa-phone', render: true,
    },
    {
        label: 'Logout', icon: 'fas fa-sign-out-alt',
        onClick: () => logout(), render: props.isLoggedin,
    },
];
const Lang = [
    { id: 0, label: 'EN', value: 'EN' },
    { id: 1, label: 'TH', value: 'TH' },
];
class Mobile extends React.Component {
    constructor(props) {
        super(props);
        this.currencyOptions = null;
        this.handleClickLanguage = () => {
            this.setState({ openLanguage: !this.state.openLanguage, openCurrency: false });
        };
        this.handleClickCurrency = () => {
            this.setState({ openCurrency: !this.state.openCurrency, openLanguage: false });
        };
        this.state = { openSidebar: false, openCurrency: false, openLanguage: false };
        this.currencyOptions = optionsServices.getCurrencyOptions(false);
    }
    async loadService() {
        Dialog.loading({ key: SETTING_KEY, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
        await reloadService();
        await window.setTimeout(() => { Dialog.loading().remove(SETTING_KEY); }, 1000);
    }
    refreshPage(language) {
        const searchLang = this.props.location.search.split('=');
        if (this.props.location.pathname === '/') {
            this.props.history.push('/refresh/');
        }
        else if (this.props.location.pathname === '/confirmPayment') {
            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: language === undefined ? this.props.location.search :
                    this.props.location.search.indexOf('&') > 1 ?
                        `${searchLang[0]}=${searchLang[1]}=${searchLang[2]}=${language}` :
                        `${this.props.location.search}&language=${language}`,
            });
        }
        else {
            this.props.history.replace({
                pathname: this.props.location.pathname,
                search: language === undefined ? this.props.location.search :
                    `${this.props.location.search === "" ? 'language' :
                        this.props.location.search.indexOf('&') > 0 ? searchLang[0] :
                            searchLang[0] === "?language" ? searchLang[0] : searchLang[0] + '&language'}=${language}`,
                state: this.props.location.state,
            });
        }
        this.setState({ openSidebar: false });
    }
    render() {
        const { classes, user, history, authen, isMobile, dispatch, location } = this.props;
        const { openSidebar, openCurrency, openLanguage } = this.state;
        const avatar = path(['data', 'avatarLink'], user);
        const isLoggedin = !!path(['token'], authen);
        const language = path(['settings', 'language'], this.props);
        const currency = path(['settings', 'currency'], this.props);
        const checkPlatForm = isPlatForm === PLATFORM_NAME.PHIPHITOURS;
        const hash = window.location.hash;
        const isPartner = hash === config.partner.id;
        if (!isMobile) {
            return null;
        }
        return (<div className={classes.root}>
                <AppBar position='fixed' style={{ backgroundColor: Colors.theme }}>
                    <Toolbar className={classes.toolBar}>
                        {(!openSidebar)
            ?
                (<IconButton className={classes.menuButton} color='inherit' aria-label="Menu" onClick={() => { this.setState({ openSidebar: true }); }}>
                                        <MenuIcon style={{ fontSize: 28 }}/>
                                    </IconButton>)
            :
                <div style={{ width: 48, height: 48, marginRight: 8 }}/>}
                        <Logo width={checkPlatForm ? 90 : 99}/>
                        {isPartner &&
            <Logo width={checkPlatForm ? 90 : 99} isPartner={isPartner}/>}
                        {(isLoggedin) ?
            <Link to={pathName.PROFILE} style={{ marginLeft: 16 }}>
                                    {avatar ?
                <Avatar size={"32px"} src={path(['data', 'avatarLink'], user)} round={true}/>
                :
                    <Avatar name={path(['data', 'imageName'], user)} size={"32px"} textSizeRatio={1.5} color={getRandomColor(path(['data', 'imageName'], user), Colors.colorsAvatar)} round={true}/>}
                                </Link>
            :
                <Svg.Landing.Login style={{ cursor: 'pointer' }} onClick={() => {
                    Dialog.authen(AUTHEN_DIALOG_MODE.LOGIN);
                }}/>}
                    </Toolbar>
                </AppBar>
                <Drawer open={openSidebar} onClose={() => { this.setState({ openSidebar: false }); }} classes={{ paper: classes.paper }}>
                    <div className={classes.list}>
                        <IconButton 
        // className={classes.menuButton}
        style={{
            paddingLeft: 15, marginTop: 5, width: window.innerWidth,
            justifyContent: 'start',
        }} color='inherit' aria-label="Menu" onClick={() => { this.setState({ openSidebar: false }); }}>
                            <MenuIcon style={{ fontSize: 28 }}/>
                        </IconButton>
                        <List component="nav" disablePadding>
                            {sidebar({ isLoggedin, dispatch, language, location, history }).map((each, i) => {
            if (!each.render) {
                return null;
            }
            return (<Fragment key={i}>
                                            <ListItem button onClick={() => {
                switch (true) {
                    case (typeof each.onClick === 'function'): {
                        each.onClick();
                        break;
                    }
                    case (!!each.path && history.location.pathname !== each.path): {
                        history.push(each.path);
                        break;
                    }
                    case (!!each.path && history.location.pathname === each.path): {
                        this.setState({ openSidebar: false });
                        break;
                    }
                    case (each.label === 'Currency'): {
                        this.handleClickCurrency();
                        break;
                    }
                    case (each.label === 'Language'): {
                        this.handleClickLanguage();
                        break;
                    }
                    case (each.label === 'Bussiness Partners'): {
                        window.location.href = config.businessURL;
                        break;
                    }
                }
            }}>
                                                <div style={{ width: 20, justifyContent: 'center', display: 'flex' }}>
                                                    <i className={each.icon}></i>
                                                </div>
                                                <ListItemText classes={{ primary: classes.text }} primary={each.label}/>
                                                {(each.label === 'Currency') ?
                openCurrency ? <ExpandLess /> : <ExpandMore /> : null}
                                                {(each.label === 'Language') ?
                openLanguage ? <ExpandLess /> : <ExpandMore /> : null}
                                            </ListItem>
                                            {this.checkCollapse(each.label, currency, language)}
                                        </Fragment>);
        })}
                            
                            <div style={{ height: window.innerHeight }} onClick={() => { this.setState({ openSidebar: false }); }}></div>
                        </List>
                    </div>
                </Drawer>
            </div>);
    }
    checkCollapse(label, currency, language) {
        const { openCurrency, openLanguage } = this.state;
        switch (label) {
            case 'Currency':
                return this.CollapseOpen(currency, openCurrency, this.currencyOptions, label);
                break;
            case 'Language':
                return this.CollapseOpen(language, openLanguage, Lang, label);
                break;
            default:
                break;
        }
    }
    CollapseOpen(label, open, list, type) {
        const { classes } = this.props;
        return (<Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {list.filter((fil) => fil.label !== label).map((val, index) => (<Fragment key={index}>
                                <ListItem button onClick={() => {
            if (type === 'Currency') {
                this.props.dispatch(SettingActions.changeCurrency(val.label));
                this.refreshPage();
            }
            else {
                this.props.dispatch(SettingActions.changeLanguage(val.value));
                this.refreshPage(val.value);
            }
            this.loadService();
            this.handleClickLanguage();
        }}>
                                    <div style={{
            width: 20, marginLeft: 30,
            justifyContent: 'center',
            display: 'flex',
        }}>
                                        <img src={Images.landing.arrowDown} style={{ transform: 'rotate(270deg)' }} alt=''/>
                                    </div>
                                    <ListItemText classes={{ primary: classes.subText }} primary={val.label}/>
                                </ListItem>
                            </Fragment>))}
                </List>
            </Collapse>);
    }
}
const mapStateToProps = (state) => {
    return {
        [reduxKeys.authen]: state[reduxKeys.authen],
        [reduxKeys.user]: state[reduxKeys.user],
        isMobile: state[reduxKeys.screen].isMobile,
        [reduxKeys.settings]: state[reduxKeys.settings],
    };
};
const composedMobile = compose(connect(mapStateToProps), withStyles(styles), withRouter)(Mobile);
export default composedMobile;
