import React, { Component, Fragment } from 'react';
import DatePicker from 'rc-calendar/lib/Picker';
import moment from 'moment';
import { equals } from "ramda";
import Calendar from 'rc-calendar';
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";
import Input from "@material-ui/core/Input";
import enUS from 'rc-calendar/lib/locale/en_US';
import { withStyles } from '@material-ui/styles';
import 'rc-calendar/assets/index.css';
import { Colors, Metrics } from "../../themes";
const styles = (theme) => ({
    root: {
        width: '100%',
    },
    textFieldRoot: {
        "padding": 0,
        'label + &': { marginTop: theme.spacing.unit * 2.2 },
    },
    textFieldInput: {
        // height: '12px',
        borderRadius: Metrics.modal.borderRadius,
        border: (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
        background: theme.palette.common.white,
        width: '100%',
        fontSize: 16,
        padding: '10px 12px',
        cursor: 'pointer',
    },
});
const formatDate = 'YYYY-MM-DD';
class CalendarForm extends Component {
    constructor(props) {
        super(props);
        this.onChange = (value) => {
            switch (this.state.mode) {
                case 'year':
                    value !== null ? this.setState({ mode: 'month' }) : this.setState({ mode: 'year' });
                    break;
                case 'month':
                    value !== null ? this.setState({ mode: 'date' }) : this.setState({ mode: 'year' });
                    break;
                case 'date':
                    if (value === null) {
                        this.setState({ mode: 'year' });
                    }
                    break;
            }
            this.setState({
                value,
            }, () => {
                this.props.onChange(value);
                if (typeof this.props.validate === 'function') {
                    this.props.validate(value);
                }
            });
        };
        this.handlePanelChange = (moments, modes) => {
            this.setState({ mode: modes });
        };
        this.state = {
            disabled: false,
            value: props.value,
            showTime: false,
            mode: 'year',
        };
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.value)) {
            this.setState({
                value: nextProps.value,
            });
        }
    }
    disabledDate(current) {
        const date = moment();
        date.hour(0);
        date.minute(0);
        date.second(0);
        return current.valueOf() > date.valueOf();
    }
    render() {
        const { label, error, errorId, classes, inputProps, getRef, inputStyle = {}, require, } = this.props;
        const state = this.state;
        const calendar = (<Calendar locale={enUS} mode={this.state.mode} onChange={this.onChange} onPanelChange={this.handlePanelChange} showToday={false} disabledDate={this.disabledDate} style={{ zIndex: 1000 }}/>);
        return (<Fragment>
                <FieldLabel label={label} error={error} requir={require}/>
                <DatePicker animation="slide-up" format={formatDate} calendar={calendar} value={state.value} onChange={this.onChange} ref={(node) => {
            if (typeof getRef === 'function') {
                getRef(node);
            }
        }}>
                    {({ value }) => {
            return (<Input placeholder="Please Select" style={{ width: 250 }} disabled={state.disabled} disableUnderline inputProps={{
                ...inputProps,
                style: inputStyle,
            }} classes={{
                root: classes.textFieldRoot,
                input: classes.textFieldInput,
            }} readOnly value={(value && value.format(formatDate)) || ''}/>);
        }}
                </DatePicker>
                <FieldError error={error} errorId={errorId}/>
            </Fragment>);
    }
}
export default withStyles(styles)(CalendarForm);
