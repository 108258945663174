import Modal from './Modal';
import Loading from './Loading';
import Info from './Info';
import CloseMark from "./CloseMark";
import Alert from "./Alert";
class ModalInstances {
    static getInstance() {
        if (!!ModalInstances.instance) {
            return ModalInstances.instance;
        }
        else {
            return this.createInstance();
        }
    }
    static createInstance() {
        if (ModalInstances.instance === null || ModalInstances.instance === undefined) {
            ModalInstances.instance = new ModalInstances();
            return ModalInstances.instance;
        }
        else {
            return ModalInstances.instance;
        }
    }
    // private loading: LoadingWrapper;
    // private info: any;
    // private error: any;
    setModal(name, modal) {
        this[name] = modal;
    }
    getModal(name) {
        return this[name];
    }
}
const loading = (data) => {
    const modal = ModalInstances.getInstance().getModal('loading');
    if (!!data) {
        modal.loading(data);
    }
    return modal;
};
const error = (data) => {
    const modal = ModalInstances.getInstance().getModal('error');
    if (!!data) {
        modal.error(data);
    }
    return modal;
};
const info = (data) => {
    const modal = ModalInstances.getInstance().getModal('info');
    if (!!data) {
        modal.info(data);
    }
    return modal;
};
const authen = (mode) => {
    const modal = ModalInstances.getInstance().getModal('authen');
    if (!!mode) {
        modal.openModal(mode);
    }
    return modal;
};
export default {
    Modal,
    Loading,
    Info,
    CloseMark,
    Alert,
    ModalInstances,
    loading,
    error,
    info,
    authen,
};
