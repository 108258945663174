import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import config from '../../config';
import { language as ConLanguage } from "../../constant";
let isLanguage;
let loggedInGreeting;
let loggedOutGreeting;
const { social } = config;
const { pageId, appId } = social.facebook;
const FacebookMessenger = (props) => {
    const { Language, isCustom } = props;
    switch (Language) {
        case ConLanguage.TH:
            {
                isLanguage = 'th_TH';
                loggedInGreeting = 'ทดสอบ loggedInGreeting';
                loggedOutGreeting = 'ทดสอบ loggedOutGreeting';
            }
            break;
        case ConLanguage.CN:
            {
                isLanguage = 'zh_CN';
                loggedInGreeting = 'test loggedInGreeting';
                loggedOutGreeting = 'test loggedOutGreeting';
            }
            break;
        case ConLanguage.EN:
        default: {
            isLanguage = 'en_US';
            loggedInGreeting = 'test loggedInGreeting';
            loggedOutGreeting = 'test loggedOutGreeting';
        }
    }
    return (isCustom) ?
        <MessengerCustomerChat pageId={pageId} appId={appId} 
        // shouldShowDialog={true}
        loggedInGreeting={loggedInGreeting} loggedOutGreeting={loggedOutGreeting} 
        // minimized={true}
        themeColor={'#c01802'} greetingDialogDelay={1} language={isLanguage}/> :
        <MessengerCustomerChat pageId={pageId} appId={appId} greetingDialogDelay={1} language={isLanguage}/>;
};
export default FacebookMessenger;
