export default {
    FAQTopics: '經常問的問題',
    FAQ00001: {
        questions: `我預訂後可以更改預訂嗎 ？`,
        ask: `TravisGO 修改或更改現有預訂的日期不收取費用，只要我們的客戶服務團隊收到請求，在您確認的旅行日期之前不少於三（24 hours)`,
    },
    FAQ00002: {
        questions: `我的付款安全嗎 ？`,
        ask: `是的，我們的在線支付系統是安全的！ 事實上， 
        它會對您的付款信息進行加密，以保護您免受 TravisGO 用戶信用卡交易的欺詐和未經授權的交易。
        為了保證您的安全，請不要將付款直接發送到我們安全網站之外的私人導遊。`,
    },
};
