// ===================================================================================================================================
// TravisGo
// ===================================================================================================================================
import { language } from "../../../constant";
import { currency } from "../../../constant/json/currency";
import * as mainAPI from "../../api";
// ===================================================================================================================================
const isFirebase = false;
const isDev = false; // process.env.NODE_ENV === `development`;//`development`;
const isDevelop = (isDev || isFirebase);
const isProduction = !isDevelop;
const buildVer = (isDevelop) ? '1.1.91' : '1.1.91';
// console.warn('isDevelop : ', process.env.NODE_ENV)
const host = (isDevelop) ? ` https://tvg-api.poysoft.com` : `https://tvg-api.poysoft.com`;
const loopbackHost = (isDevelop) ? ` https://tvg-api.poysoft.com/lb` : `https://tvg-api.poysoft.com/lb`;
const directionURL = (isDevelop) ? `https://travis-go.firebaseapp.com/` : `https://www.travisgo.com/`;
const businessURL = (isDevelop) ? `https://os.dev.travisgo.com/` : `http://localhost:3000/`;
const dbdCertificationURL = `https://www.trustmarkthai.com/callbackData/initialize.js?t=8444c-26-5-94012d564c55a2423f40b5775262906a7e4838e4`;
// ===================================================================================================================================
// (!isDevelop) && console.warn(` === Build Ver.(${buildVer}) === `);
// ===================================================================================================================================
const api = mainAPI.api;
api['host'] = host;
api['loopbackHost'] = loopbackHost;
// ===================================================================================================================================
const defaultSetting = { language: language.EN, currency: currency[0].value };
const social = {
    facebook: {
        appId: "2086382298259508",
        pageId: (isDevelop ? "374650436604380" : "1664658767165619"),
        permission: "name,email,picture",
    },
    google: { clientId: "953111485803-ra470233bn531m4gu7c3qfsja1rrm572.apps.googleusercontent.com" },
};
const trace = { facebookPixelId: '1727426540703026' };
const mockApiResponse = false;
const omiseKey = isDevelop ? 'pkey_test_5aq2e4lhc9wqhkhr3uw' : 'pkey_5d81s4rsls2ozdodxs4'; // 'pkey_test_5cxkrvshiy016tyszma';
const is3DSecureActive = isProduction;
const license = {
    TATLicense: '11/09229',
    DBDLicense: '',
};
const footer = {
    link: {
        license: `http://103.80.100.92:8087/mobiletourguide/info/license/tour/`,
        ig: 'https://www.instagram.com/travisgotravel/',
        fb: 'https://www.facebook.com/TravisGOthailand/',
        youtube: 'https://www.youtube.com/channel/UCEb-hovh0g3iV1HOFvAJkIw',
        line: 'https://line.me/R/ti/p/%40travisgo',
        twt: 'https://twitter.com/TravisGOTravel',
    },
};
const partner = {
    id: "#SLR-20191031-000001",
    name: 'BangkokAirways'
};
// ==================================================================================================================================
export { api, defaultSetting, host, directionURL, dbdCertificationURL, businessURL, social, mockApiResponse, omiseKey, 
// debugSetting,
// reduxPersist,
footer, isDev, isDevelop, isProduction, isFirebase, trace, buildVer, license, is3DSecureActive, partner };
