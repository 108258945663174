import React from 'react';
import { path } from "ramda";
import moment from "moment";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FlexView from 'react-flexview/lib';
import Panel, { PanelHeader, PanelContent } from "../Panel";
import Text from "../text";
import Divider from "../Divider";
import GrayBox from "../GrayBox";
import Button from "../button";
import Space from "../Space";
import { Colors } from "../../themes";
import { pathName } from '../../constant';
import { isSafari } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
import localization from '../../services/localization';
const TextWithNumber = ({ responsiveStyle: { size, numSize, textSize, textGap, marginTop, lineHeight }, num, text, }) => (<div style={{ display: 'flex', alignItems: 'center', marginTop }}>
            <div style={{ width: size, height: size }}>
                <Text.Avenir block family="heavy" size={numSize} color="#fff" style={{
    textAlign: 'center', lineHeight,
    backgroundColor: Colors.theme, width: size, height: size, borderRadius: '50%',
}}>
                    {num}
                </Text.Avenir>
            </div>
            <Text.Avenir block family="heavy" size={textSize} color={Colors.grayScale["4a"]} style={{
    marginLeft: textGap,
}}>
                {text}
            </Text.Avenir>
        </div>);
const PaymentInstruction = ({ selectedBooking, goNextStep, paymentTimes, isMobile, isLoggedIn, language }) => {
    const bookingNo = path(['bookingId'], selectedBooking);
    const bookingStatus = path(['bookingStatus'], selectedBooking);
    const packageName = path(['packageInfo', 0, 'name'], selectedBooking);
    const adult = path(['packageInfo', 0, 'bookingInfo', 'adult'], selectedBooking);
    const kid = path(['packageInfo', 0, 'bookingInfo', 'kid'], selectedBooking);
    const date = path(['date'], selectedBooking);
    const expireDate = path(['expireDate'], selectedBooking);
    const amount = path(['priceTotal', 'subTotal'], selectedBooking);
    const paymentMethod = path(['paymentInfo', 'paymentMethod'], selectedBooking);
    const panelHeader = paymentMethod === "Alienation" ?
        `Kasikornbank Payment Instructions` : 'Bill Payment Instructions';
    const omiseAmount = path(['omiseData', 'source', 'amount'], selectedBooking) || '';
    const barcode = path(['omiseData', 'source', 'references', 'barcode'], selectedBooking) || '';
    const omiseTax = path(['omiseData', 'source', 'references', 'omise_tax_id'], selectedBooking) || '';
    const omiseRef1 = path(['omiseData', 'source', 'references', 'reference_number_1'], selectedBooking) || '';
    const omiseRef2 = path(['omiseData', 'source', 'references', 'reference_number_2'], selectedBooking) || '';
    moment.locale(language);
    // const expireDiff = moment(expireDate).diff(moment(date)) / 1000;
    // const totalMins = expireDiff / 60;
    // const hours = Math.floor(totalMins / 60);
    // const mins = totalMins % 60;
    const hour = paymentMethod === "Alienation" ? path([0, 'time', 'hour'], paymentTimes) : 24 || 0;
    const min = paymentMethod === "Alienation" ? path([0, 'time', 'min'], paymentTimes) : 0 || 0;
    let expireText = `${hour > 0 ? `${hour} hour${hour > 1 ? 's' : ''}` : ''}`;
    expireText += ` ${min > 0 ? `${min} minute${min > 1 ? 's' : ''}` : ''}`;
    const bulletData = [
        ` ${packageName}`,
        ` ${moment(date).format('dddd, MMMM DD, YYYY')}`,
        ` ${adult} ${localization(`adult`, `ProfileLabel`)}, ${kid} ${localization(`kid`, `ProfileLabel`)}`,
    ];
    const bulletStyle = {
        size: isMobile ? 9 : 12,
        marginRight: isMobile ? 8 : 13,
        marginBottom: isMobile ? 3 : 1,
        width: 0,
        height: 0,
    };
    bulletStyle.width = bulletStyle.size + bulletStyle.marginRight;
    bulletStyle.height = bulletStyle.size + bulletStyle.marginBottom;
    const textWithNumStyle = {
        size: isMobile ? 26 : 35,
        numSize: isMobile ? 19 : 23,
        textSize: isMobile ? 19 : 26,
        lineHeight: isMobile ? '27px' : '34px',
        textGap: isMobile ? 8 : 15,
        marginTop: isMobile ? 25 : 35,
    };
    const isTinyMobile = window.innerWidth < 405;
    const buttonStyle = {
        padding: '10px 5px',
        letterSpacing: isSafari() ? '-1px' : '1.85px',
    };
    const status = bookingStatus === 'in-process' ? localization(`in_process`, `ProfileLabel`) : localization(bookingStatus, `ProfileLabel`);
    return (<React.Fragment>
            <Panel isMobile={isMobile}>
                <PanelHeader style={{
        backgroundColor: Colors.grayScale["35"],
        padding: isMobile ? 0 : '0 0 0 40px',
        justifyContent: isMobile ? 'center' : 'flex-start',
    }}>
                    {panelHeader}
                </PanelHeader>
                <PanelContent style={{ padding: 0 }}>
                    <Text.Avenir block size={isMobile ? 17 : 24} color={Colors.grayScale["35"]}>
                        <div style={{ padding: isMobile ? 15 : 40 }}>
                            <Text.Header>{`${localization(`booking_no`, `BookingLabel`)} : ${bookingNo}`}</Text.Header>
                            <div style={{ marginBottom: isMobile ? 18 : 27, marginTop: 5 }}>
                                {`${localization(`booking_status`, `BookingLabel`)} : ${status}`}
                            </div>
                            <Text.Header>{`${localization(`booking_detail`, `BookingLabel`)}`}</Text.Header>
                            {bulletData.map((each, i) => (<div key={i} style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                                        <div style={{ width: bulletStyle.width, height: bulletStyle.height }}>
                                            <Text.Bullet size={bulletStyle.size} style={{
        marginRight: bulletStyle.marginRight,
        marginBottom: bulletStyle.marginBottom,
    }}/>
                                        </div>
                                        <div>{each}</div>
                                    </div>))}
                        </div>
                        <Divider />
                        <div style={{ padding: isMobile ? 15 : 40 }}>
                            <Text.Header>{localization(`payment_instructions`, `BookingLabel`)}</Text.Header>

                            <TextWithNumber responsiveStyle={textWithNumStyle} num="1" text={localization(`payment_before`, `BookingLabel`)}/>
                            <GrayBox style={{
        fontSize: isMobile ? '' : 24, color: Colors.grayScale["35"],
    }}>
                                {`${moment(expireDate).calendar()}. ${localization(`complete_payment`, `BookingLabel`)} ${expireText}`}
                            </GrayBox>

                            <TextWithNumber responsiveStyle={textWithNumStyle} num="2" text={paymentMethod === "Alienation" ?
        localization(`transfer_to`, `BookingLabel`) : localization(`payment_to`, `BookingLabel`)}/>
                            {paymentMethod === "Alienation" ?
        <GrayBox column style={{
            fontSize: isTinyMobile ? 12 : isMobile ? '' : 24,
            color: Colors.grayScale["35"], padding: 0,
        }}>
                                    <Text.Avenir block family="heavy" style={{
            padding: `13px ${isMobile ? 15 : 20}px`,
            backgroundColor: Colors.grayScale["9e"],
        }} color={Colors.grayScale["4a"]} size={isMobile ? 20 : 29}>
                                        {`Kasikorn Bank`}
                                    </Text.Avenir>
                                    <div style={{
            padding: `13px ${isMobile ? 15 : 20}px`,
            display: 'flex', justifyContent: 'space-between',
        }}>
                                        <div>{`Account Number`}</div>
                                        <div>{`036-1-3359-2`}</div>
                                    </div>
                                    <Divider style={{ height: 1, margin: `0 ${isMobile ? 15 : 20}px` }}/>
                                    <div style={{
            padding: `13px ${isMobile ? 15 : 20}px`,
            display: 'flex', justifyContent: 'space-between',
        }}>
                                        <div>{`Account Holder Name`}</div>
                                        <div>{`TRAVISGO CO.,LTD`}</div>
                                    </div>
                                    <Divider style={{ height: 1, margin: `0 ${isMobile ? 15 : 20}px` }}/>
                                    <div style={{
            padding: `13px ${isMobile ? 15 : 20}px`,
            display: 'flex', justifyContent: 'space-between',
        }}>
                                        <div>{`Transfer Amount`}</div>
                                        <div>
                                            <FormattedMessage id='amount' defaultMessage={`{amount, number}`} values={{ amount }}/>
                                            {` Baht`}
                                        </div>
                                    </div>
                                </GrayBox> :
        <GrayBox column style={{
            fontSize: isTinyMobile ? 12 : isMobile ? '' : 24,
            color: Colors.grayScale["35"], padding: 0,
        }}>
                                    <div style={{ margin: '10px 0px' }}>
                                        <Text.Avenir block family="heavy" color={Colors.grayScale["4a"]} size={16} style={{ padding: `13px ${isMobile ? 15 : 20}px`, width: 200 }}>
                                            {`Barcode :`}
                                        </Text.Avenir>
                                        <div style={{ textAlign: 'center' }}>
                                            <img src={barcode} style={{
            width: 620,
            padding: `13px ${isMobile ? 15 : 20}px`,
        }}/>
                                            <Text.Avenir block family="heavy" color={Colors.grayScale["4a"]} size={16} style={{
            padding: `0px ${isMobile ? 15 : 20}px 13px`,
            width: 620, display: 'inline-block',
        }}>
                                                {`| ${omiseTax} 00 ${omiseRef1}
                                                 ${omiseRef2} ${omiseAmount}`}
                                            </Text.Avenir>
                                        </div>
                                    </div>
                                    <Divider style={{ height: 1, margin: `0 ${isMobile ? 15 : 20}px` }}/>
                                    <div style={{ display: 'flex', margin: '10px 0px' }}>
                                        <Text.Avenir block family="heavy" color={Colors.grayScale["4a"]} size={16} style={{ padding: `13px ${isMobile ? 15 : 20}px`, width: 200 }}>
                                            {`Link Download PDF :`}
                                        </Text.Avenir>
                                        <a href={path(['billPaymentLink'], selectedBooking)} target='_blank' rel="noopener" style={{
            color: 'unset',
            textDecoration: 'none',
            width: 125,
        }}>
                                            <Button.Theme label={'Bill Payment'} buttonStyle={{
            borderRadius: '5px',
            padding: '7px 2px',
            width: 125,
            background: '#ffffff',
            border: `1px solid ${Colors.theme}`,
        }} labelStyle={{
            color: Colors.theme,
            lineHeight: window.innerWidth < 767 ? '9px' : '25px',
        }} letterSpacing={0} fontSize={window.innerWidth < 767 ? 12 : 16}/>
                                        </a>
                                    </div>
                                </GrayBox>}

                            {paymentMethod === "Alienation" ?
        <React.Fragment>
                                    <TextWithNumber responsiveStyle={textWithNumStyle} num="3" text={localization(`completed_payment`, `BookingLabel`)}/>
                                    <GrayBox style={{
            fontSize: isMobile ? '' : 24, color: Colors.grayScale["35"],
        }}>
                                        {`Once your payment is confirmed,
we will send your hotel voucher to your email address.`}
                                    </GrayBox>
                                </React.Fragment> : null}

                            {!isMobile && (<div style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '80px 0 40px 0',
        height: 47,
    }}>
                                        {paymentMethod === "Alienation" ?
        <Button.Theme label={`Upload Payment Proof`} buttonStyle={{ width: 380, padding: '10px 0' }} onClick={goNextStep} fontSize={24} fontFamily="heavy" letterSpacing="0px"/> :
        <FlexView wrap style={{
            marginTop: isMobile ? '10px' : '0px',
            justifyContent: 'center',
        }}>
                                                <Link to={pathName.INDEX} style={{
            textDecoration: 'none',
            width: '283px',
            height: isMobile ? '40px' : '45px',
            margin: isMobile ? '10px 8px' : '24px 15px',
        }}>
                                                    <Button.Theme buttonStyle={buttonStyle} label={localization(`go_landing_page`, `BookingLabel`)}/>
                                                </Link>
                                                {isLoggedIn && (<Link to={pathName.PROFILE} style={{
            textDecoration: 'none',
            width: '283px',
            height: isMobile ? '40px' : '45px',
            margin: isMobile ? '10px 8px' : '24px 15px',
        }}>
                                                            <Button.Theme buttonStyle={buttonStyle} label={localization(`manage_my_booking`, `BookingLabel`)}/>
                                                        </Link>)}
                                            </FlexView>}
                                    </div>)}
                        </div>
                    </Text.Avenir>
                </PanelContent>
            </Panel>
            {isMobile && (<Space height={13}/>)}
        </React.Fragment>);
};
export default PaymentInstruction;
