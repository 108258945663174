import React, { Component } from 'react';
import Text from "../text";
import { Colors, Metrics, Fonts, Svg } from "../../themes";
import styled, { css } from 'styled-components';
import Rating from 'react-rating';
import { Input, ButtonBase } from '@material-ui/core';
import { path } from 'ramda';
import { withStyles } from '@material-ui/styles';
import PackageActions from "../../redux/PackageRedux";
import { reduxKeys } from "../../constant";
import { connect } from "react-redux";
import { compose } from "recompose";
import Avatar, { getRandomColor } from 'react-avatar';
import { shadows } from '@material-ui/system';
import localization from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
import FileUploader from "../fileUploader";
import uploadUtil from "../../utils/uploadUtil";
let pageLabel;
const styles = (theme) => {
    const placeholder = {
        color: 'currentColor',
        opacity: 0.42,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.ease,
        }),
    };
    return {
        root: {
            width: '100%',
            position: 'relative',
        },
        textFieldRoot: {
            "padding": 0,
            "minHeight": (props) => `calc(${props.minHeight + theme.spacing.unit * 2.2}px)` || '',
            "label + &": {
                marginTop: theme.spacing.unit * 2.2,
            },
            "width": '100%',
        },
        avatar: {
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '50%',
            boxShadow: shadows[2],
            position: 'relative',
            background: 'transparent',
        },
        textFieldInput: {
            // "height": '12px',
            "minHeight": (props) => `${props.minHeight - 2}px` || '',
            "borderRadius": Metrics.modal.borderRadius,
            "background": theme.palette.common.white,
            "border": (props) => `1px solid ${props.error ? theme.palette.error.main : Colors.input.border}`,
            "fontSize": '14px',
            "padding": '10px 12px',
            "width": (props) => props.multiline ? 'calc(100% - 26px)' : '100%',
            "height": (props) => props.multiline ? 'calc(100% - 20px)' : '',
            "transition": theme.transitions.create(['border-color', 'box-shadow']),
            // "&:focus": {
            //     borderColor: '#80bdff',
            //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            // },
            '&::-webkit-input-placeholder': placeholder,
            '&::-moz-placeholder': placeholder,
            '&:-ms-input-placeholder': placeholder,
            '&::-ms-input-placeholder': placeholder,
        },
    };
};
class Review extends Component {
    constructor(props) {
        super(props);
        this.defaultImages = [];
        this.handleClickOpen = () => {
            this.setState({ open: true });
        };
        this.handleClose = () => {
            this.setState({ open: false });
        };
        this.state = {
            open: false,
            disabled: false,
            files: [],
            value: {
                reviewDetails: props.reviewDetails || '',
                point: props.point || 0,
                packageId: path(['packageId'], this.props.PackageLists),
                userId: path(['data', '_id'], this.props.user),
                images: [],
            },
        };
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.clearValue = this.clearValue.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    onChangeHandler(e) {
        const { value } = this.state;
        this.setState({ value: { ...this.state.value, reviewDetails: path(['target', 'value'], e) } }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(value);
            }
        });
    }
    handleClick(val) {
        const { value } = this.state;
        this.setState({ value: { ...this.state.value, point: val } }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(value);
            }
        });
    }
    async onSubmit() {
        const { user, isDialog, PackageLists } = this.props;
        const { value } = this.state;
        const packageIds = path(['packageId'], PackageLists);
        let uploadResult;
        let imageResult = [];
        if (Array.isArray(this.state.files) && this.state.files.length > 0) {
            uploadResult = await uploadUtil(this.state.files.map((each) => ({
                key: 'reviewImages',
                file: each.file,
            })));
            imageResult = Array.isArray(uploadResult.reviewImages) ?
                uploadResult.reviewImages
                :
                    [uploadResult.reviewImages];
        }
        const images = [
            ...this.defaultImages,
            ...imageResult,
        ];
        const payload = {
            ...value,
            packageId: path(['packageId'], PackageLists),
            userId: path(['data', '_id'], user),
            images,
        };
        const bookingId = path(['bookingId'], PackageLists);
        this.props.dispatch(PackageActions.createReviewRequest(payload, () => {
            this.handleClose();
            // if (isDialog === true) {
            this.props.dispatch(PackageActions.requestReviewUpdateRequest({ isReview: true }, bookingId, (res) => {
                if (!!res) {
                    this.requestReviewsRequest();
                    if (isDialog === true) {
                        this.props.hideDialog();
                    }
                }
            }));
            // } else {
            this.props.dispatch(PackageActions.getReviewRequest({ where: { packageId: packageIds } }));
            // }
            this.clearValue();
        }));
    }
    requestReviewsRequest() {
        const { [reduxKeys.settings]: { language }, user } = this.props;
        const payload = {
            userId: path(['data', '_id'], user),
            language,
            isReview: false,
        };
        this.props.dispatch(PackageActions.requestReviewsRequest(payload));
    }
    clearValue() {
        this.setState({ value: { reviewDetails: '', point: 0, images: [] }, files: [] });
    }
    render() {
        pageLabel = localization(wordingKeys.packageLabel);
        const { classes, inputProps, inputStyle = {}, isMobile, isDialog } = this.props;
        const { value } = this.state;
        const disabledButton = value.reviewDetails.length > 0 && value.point !== 0;
        return (<PanelContainer>
                {!!isDialog ? null : <TopicsContainer>{pageLabel[wordingKeys.ReviewPackageTopics]}</TopicsContainer>}
                <ReviewItemContainer isMobile={isMobile}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 0.1, width: 40, margin: '0px 10px 5px 0px' }}>

                            {Boolean(path(['data', 'avatarLink'], this.props.user)) ?
            <Avatar src={path(['data', 'avatarLink'], this.props.user)} size="37" className={path(['avatar'], classes)} round={true}/>
            :
                <Avatar name={path(['data', 'imageName'], this.props.user)} size="37" textSizeRatio={1.5} color={getRandomColor(path(['data', 'imageName'], this.props.user), Colors.colorsAvatar)} className={path(['avatar'], classes)} round={true}/>}
                        </div>
                        <div style={{ flex: 5, display: 'flex', justifyContent: 'space-between' }}>
                            <Text.Avenir size={14} color={'#5B5B5B'} weight={900} spacing="1.4px" lineHeight="40px">
                                {path(['data', 'fullName'], this.props.user) || 'Username'}
                            </Text.Avenir>
                            <div style={{ marginTop: 10 }}>
                                <Rating emptySymbol={<Svg.Landing.Star color={'#9d9d9d'}/>} fullSymbol={<Svg.Landing.Star />} initialRating={path(['value', 'point'], this.state)} onChange={this.handleClick}/>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Input value={path(['value', 'reviewDetails'], this.state) || ''} multiline rows={4} placeholder={pageLabel[wordingKeys.ReviewHolder]} disableUnderline type='text' classes={{
            root: path(['textFieldRoot'], classes),
            input: path(['textFieldInput'], classes),
        }} onChange={this.onChangeHandler} {...inputProps} inputProps={{
            style: inputStyle,
        }}/>
                        <FileUploader defaultImages={this.defaultImages} accept={'image/*'} multiple onChange={(files) => {
            this.setState({ files }, () => {
                // this.validateFiles();
            });
        }} hideAddButton getRef={(ref) => this.fileUploader = ref}/>
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                        <ButtonBase style={{
            width: isMobile ? isDialog ? 105 : 135 : 150,
            height: '100%', backgroundColor: Colors.profile.enabledTab,
        }} onClick={() => { this.fileUploader.onClickAdd(); }}>
                            <Text.Avenir size={isMobile ? 13 : 16} color={Colors.profile.enabledText} style={{ display: 'inline-grid', justifyContent: 'start' }} weight={900} spacing="1.4px" lineHeight="40px">
                                {'Attach photo'}
                            </Text.Avenir>
                        </ButtonBase>
                        <ButtonBase onClick={this.onSubmit} disabled={!disabledButton} style={{
            backgroundColor: !disabledButton ?
                Colors.profile.disabledTab : Colors.profile.enabledTab,
            width: isMobile ? isDialog ? 105 : 135 : 150,
        }}>
                            <Text.Avenir size={isMobile ? 13 : 16} color={!disabledButton ? Colors.profile.disabledText : Colors.profile.enabledText} style={{ display: 'inline-grid', justifyContent: 'start' }} weight={900} spacing="1.4px" lineHeight="40px">
                                {pageLabel[wordingKeys.Submit]}
                            </Text.Avenir>
                        </ButtonBase>
                    </div>
                </ReviewItemContainer>
            </PanelContainer>);
    }
}
Review.defaultProps = {
    onChange: () => { },
    type: 'text',
    value: '',
};
const mapStateToProps = (state) => ({
    [reduxKeys.user]: state[reduxKeys.user],
    [reduxKeys.settings]: state[reduxKeys.settings],
});
export default compose(withStyles(styles), connect(mapStateToProps))(Review);
const { avenirBlack } = Fonts.family;
const ReviewItemContainer = styled.div `
width: 100%;
display: grid;
padding: 20px;
${(props) => props.isMobile && css `display: inline-block`}`;
const TopicsContainer = styled.div `
width: 100%;
display: flex;
background-color: rgb(53, 53, 53);
box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 6px 0px;
padding: 10px 24px;
font-family:${avenirBlack};
font-size: 18px;
color: white;
font-weight: 900;
line-height: 22px;`;
const PanelContainer = styled.div `
width: 100%;
margin-top:15px;
background-color: #FFFFFF;
box-shadow: 0 0 10px 0 rgba(126,126,126,0.5);}`;
