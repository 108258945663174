import * as React from 'react';
const Etext = ({ text, style, className, withTitle = false }) => {
    return (<div style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // cursor: 'default',
        fontFamily: 'inherit',
        ...style,
    }} className={className} title={withTitle ? text : ''}>
            {text}
        </div>);
};
export default Etext;
