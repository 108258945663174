import { path } from "ramda";
import { reduxKeys, language as constLang } from "../constant";
// import localeData from '../assets/lang';
import Store from "../redux";
import { isPlatForm } from "../config/";
import { PLATFORM_NAME } from "../config/platform";
let platforms;
export default (keys = 'blank', folder) => {
    switch (`${isPlatForm}`) {
        case PLATFORM_NAME.PHIPHITOURS:
            {
                platforms = require(`../themes/PhiPhiTours/assets/lang`);
            }
            break;
        case PLATFORM_NAME.TRAVISGO:
            {
                platforms = require(`../themes/TravisGo/assets/lang`);
            }
            break;
        default:
            {
                platforms = require(`../themes/TravisGo/assets/lang`);
            }
            break;
    }
    const localeData = platforms.default;
    if (!!folder) {
        return path([(path([reduxKeys.settings, 'language'], Store.getState()) || constLang.EN), folder, keys], localeData) || '';
    }
    else {
        return path([(path([reduxKeys.settings, 'language'], Store.getState()) || constLang.EN), keys], localeData) || '';
    }
};
export const isLanguage = () => (path([reduxKeys.settings, 'language'], Store.getState()) || constLang.EN);
/*
export default (id: keyof ILocale = 'blank', language?: string) => {

    //language = !!language ? language : '';
    //const lang: string = (Object.keys(constLang).indexOf(language) > -1) ?
    language : path([reduxKeys.settings, 'language'], Store.getState()) || '';

    //console.log('isLanguage : ', language, ' lang : ', lang, ' key : ', id);

    const message: string = path([lang, id], localeData) || '';
    return message;
};*/
