import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import { compose } from "recompose";
import { connect } from "react-redux";
import { path, isEmpty, equals } from "ramda";
import PackageActions from "../../redux/PackageRedux";
import { reduxKeys, promotionType } from "../../constant";
import { GetSymbol } from '../../components/common/GetSymbol';
import { NumberFormat } from '../../components/common/SetNumberFormat';
// import injectSheet from 'react-jss';
// import Dialog from "../../components/dialog";
import localization from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
import { isSafari } from '../PackageInfoPanel/GetItemColsPerWidth';
import { Fonts, Colors } from '../../themes';
import styled, { css } from 'styled-components';
let pageLabel;
// let totalDisCount;
// let totalPrice;
const ServiceActive = false;
class PaymentTotalPanel extends Component {
    // private promotionCode: any;
    constructor(props) {
        super(props);
        this.state = { promotionCode: '' };
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onChangeHandler(e) {
        this.setState({ promotionCode: e.target.value });
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(this.props.currency, nextProps.currency)) {
            this.props.dispatch(PackageActions.clearPromotionCodes());
        }
    }
    async onSubmit() {
        const promotionCode = this.state;
        const { settings, summary } = this.props;
        const packageId = path(['location', 'state', 'packageInfo', '0', 'packageId'], this.props);
        const { currency } = settings;
        const payloads = { promotionCode: promotionCode.promotionCode, currency, totalPrice: summary, packageId };
        this.props.dispatch(PackageActions.getPromotionCodesRequest(payloads));
    }
    componentDidMount() {
        this.props.dispatch(PackageActions.clearPromotionCodes());
    }
    render() {
        pageLabel = localization(wordingKeys.packageLabel);
        const { summary, discount, vnsc, currency, package: selectPackage } = this.props;
        const VATService = (ServiceActive) ? (Boolean(vnsc) ? (summary * vnsc.total) : 0) : 0;
        const { promotionCode } = this.state;
        const promotionCodes = path(['promotionCodes'], selectPackage)
            || path(['location', 'state', 'SelectedInfo', 'condeInfo'], this.props);
        const disableUsePromotion = isEmpty(promotionCode);
        console.log('disable', disableUsePromotion);
        let totalDisCount;
        let totalPrice;
        if (Boolean(promotionCodes)) {
            const { discountType, discountValue } = promotionCodes;
            // console.log('discountValue : ', discountValue, ' : ', discountType);
            switch (discountType) {
                case promotionType.FixPrice:
                    {
                        totalDisCount = (summary > 0) ? (((summary + VATService) - discountValue) || 0) : 0;
                        totalPrice = (summary > 0) ? (discountValue || 0) : 0;
                    }
                    break;
                case promotionType.Percent:
                    {
                        totalDisCount = (summary > 0) ? (discount || 0) + (((summary * discountValue) / 100) || 0) : 0;
                        totalPrice = ((summary + VATService) - totalDisCount);
                        // totalDisCount = path(['discountPrice'], promotionCodes);
                        // totalPrice = path(['totalPrice'], promotionCodes);
                    }
                    break;
                case promotionType.Price:
                    {
                        totalDisCount = (summary > 0) ? (discount || 0) + (discountValue || 0) : 0;
                        totalPrice = ((summary + VATService) - totalDisCount);
                        // totalDisCount = path(['discountPrice'], promotionCodes);
                        // totalPrice = path(['totalPrice'], promotionCodes);
                    }
                    break;
                default:
                    {
                    }
            }
        }
        else {
            totalDisCount = 0;
            totalPrice = (summary + VATService);
        }
        // let totalDisCount = (summary > 0) ? (discount || 0) + (discountValue || 0) : 0;
        // let totalPrice = ((summary + VATService) - totalDisCount);
        console.log(`PaymentTotalPanel : `, this.props);
        return (<PanelContianer>
                <TopContianer>
                    <HeaderLabel>{pageLabel[wordingKeys.ProCodeTopics]}</HeaderLabel>
                    <div style={{ display: 'flex', padding: '10px 0px' }}>
                        <TextInputContianer>
                            <div style={{ flex: 3 }}>
                                <TextField style={{ width: '100%', padding: '0px 10px' }} InputProps={{ disableUnderline: true }} value={path(['promotionCode'], this.state) ||
            path(['location', 'state', 'SelectedInfo', 'promotionCode'], this.props)} onChange={this.onChangeHandler}/>
                            </div>
                            <div style={{ flex: 1 }}>
                                <UseCode disabled={disableUsePromotion}>
                                    <Button onClick={this.onSubmit} disabled={disableUsePromotion} style={ButtonStyle}>
                                        {pageLabel[wordingKeys.CouponButton]}
                                    </Button>
                                </UseCode>
                            </div>
                        </TextInputContianer>
                    </div>
                </TopContianer>
                <BottomContianer>
                    <ItmesContianer>
                        <FlexLeft>
                            <DiscountLabel>{pageLabel[wordingKeys.Discount]}</DiscountLabel>
                        </FlexLeft>
                        <FlexRight>
                            <DiscountLabel>{`${GetSymbol(currency)} ${NumberFormat(totalDisCount)}`}</DiscountLabel>
                        </FlexRight>
                    </ItmesContianer>
                    {(ServiceActive) &&
            <ServiceContianer>
                            <FlexLeft>
                                <ServiceChargeLabel>{pageLabel[wordingKeys.ServiceCharge]}</ServiceChargeLabel>
                            </FlexLeft>
                            <FlexRight>
                                <ServiceChargeLabel>
                                    {`${GetSymbol(currency)} ${NumberFormat(VATService)}`}</ServiceChargeLabel>
                            </FlexRight>
                        </ServiceContianer>}
                    <ItmesContianer>
                        <FlexLeft>
                            <TotalLabel>{pageLabel[wordingKeys.Total]}</TotalLabel>
                        </FlexLeft>
                        <FlexRight>
                            <TotalLabel>{`${GetSymbol(currency)} ${NumberFormat(totalPrice)}`}</TotalLabel>
                        </FlexRight>
                    </ItmesContianer>
                </BottomContianer>
            </PanelContianer>);
    }
}
const mapStateToProps = (state) => ({
    [reduxKeys.package]: state[reduxKeys.package],
    [reduxKeys.settings]: state[reduxKeys.settings],
});
export default compose(withRouter, connect(mapStateToProps))(PaymentTotalPanel);
const { avenir, avenirBlack } = Fonts.family;
const _isSafari = isSafari();
// ===========================================================================================
const PanelContianer = styled.div `
                        height: 218px;
                        width: 100%;
                        border: 3px solid ${Colors.theme};
                        background-color: #FFFFFF;
                        box-shadow: 0 0 10px 0 rgba(126,126,126,0.5);
${(props) => props.isMobile && css `padding: 0px;`}`;
const TopContianer = styled.div `
                        height: 50%;
                        padding: 10px;`;
const BottomContianer = styled.div `
                        text-align: center;
                       `;
const HeaderLabel = styled.label `
                        height: 16px;
                        width: 202px;
                        color: ${Colors.theme};
                        font-family: ${avenirBlack};
                        font-size: 12px;
                        font-weight: 900;
                        letter-spacing: ${_isSafari ? -0.8 : 0}px;
                        line-height: 16px;`;
const TotalLabel = styled.label `
                        height: 29px;
                        width: 100%;
                        color: #5B5B5B;
                        font-family: ${avenirBlack};
                        font-size: 21px;
                        font-weight: 900;
                        letter-spacing: ${_isSafari ? -0.8 : 0}px;
                        line-height: 29px;`;
const DiscountLabel = styled.label `
                        height: 19px;
                        width: 100%;
                        color: ${Colors.theme};
                        font-family: ${avenirBlack};
                        font-size: 14px;
                        font-weight: 900;
                        letter-spacing: ${_isSafari ? -0.8 : 0}px;
                        line-height: 19px;`;
const TextInputContianer = styled.div `
                        display: flex;
                        height: 35px;
                        width: 100%;
                        background-color: #FFFFFF;
                        box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.5), 0 0 2px 0 rgba(10, 0, 0, 0.74);`;
const ServiceChargeLabel = styled.label `
                        height: 16px;
                        width: 100%;
                        color: #9D9D9D;
                        font-family: ${avenir};
                        font-size: 12px;
                        letter-spacing: ${_isSafari ? -0.8 : 0}px;
                        line-height: 16px;`;
const ItmesContianer = styled.div `
                        display: flex;
                        padding: 10px;`;
const ServiceContianer = styled.div `
                        display: flex;
                        padding: 0px 10px;`;
const FlexLeft = styled.div `
                        flex: 1;
                        text-align:left;`;
const FlexRight = styled.div `
                        flex: 1 1 0%;
                        text-align: right;`;
const UseCode = styled.div `
                        height: 35px;
                        width: 86px;
                        text-align: center;
                        background-color: ${Colors.theme};
                        float: right;
                        box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 0 10px 0 rgba(126,126,126,0.5);
                        ${(props) => props.disabled && css `background-color: rgb(223,223,223);`}`;
const ButtonStyle = {
    width: 80, height: 35, color: 'white',
    fontSize: 10, padding: 0, fontWeight: 900, textAlign: 'center',
};
// =====================================================================================================
// Old TotalPanel
// private TotalPanel() {
//     return (
//         <div style={_style.summary_container}>
//             <div style={_style.total_container}>
//                 <div style={{ flex: '1', }}>
//                     <label style={_style.label_topic}>{`TOTAL`}</label>
//                 </div>
//                 <div style={{ flex: '1', textAlign: 'right' }}>
//                     <label style={_style.label_topic}>{`฿ ${this.props.summary}`}</label>
//                 </div>
//             </div>
//             <BottomContianer>
//                 <label style={_style.label_topic}>{`SELECT SOME TRIP`}</label>
//             </BottomContianer>
//         </div>)
// }
// const _style = {
//     summary_container:
//     {
//         width: '100%',
//         minHeight: '138px',
//         backgroundColor: '#ffffff',
//         boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
//         marginBottom: '20px',
//     },
//     total_container:
//     {
//         height: '50%',
//         display: 'flex',
//         borderTop: '5px solid rgb(184, 24, 7)',
//         padding: '10px',
//     },
//     label_container:
//     {
//         textAlign: 'center',
//         borderTop: '1px solid #DFDFDF '
//     },
//     label_topic:
//     {
//         height: '31px',
//         width: '100%',
//         color: 'rgb(184, 24, 7)',
//         fontSize: '18px',
//         lineHeight: '31px',
//         display: 'inline-grid',
//         marginTop: '17px'
//     },
// }
// ======================================================================================
