import React, { Component } from 'react';
import { path, append, remove, update } from "ramda";
import Dialog from "../components/dialog";
import localization from '../services/localization';
class InfoWrapper extends Component {
    constructor(props) {
        super(props);
        this.infoList = [];
        Dialog.ModalInstances.getInstance().setModal('info', this);
    }
    info(data) {
        const key = path(['key'], data);
        if (!!key && key !== '') {
            const index = this.infoList.findIndex((each) => each.key === key);
            if (index === -1) {
                this.infoList = append(data, this.infoList);
                this.forceUpdate();
            }
        }
    }
    update(data) {
        const key = path(['key'], data);
        if (!!key && key !== '') {
            const index = this.infoList.findIndex((each) => each.key === key);
            if (index > -1) {
                this.infoList = update(index, data, this.infoList);
                this.forceUpdate();
            }
        }
    }
    remove(key) {
        if (!!key && key !== '') {
            const index = this.infoList.findIndex((each) => each.key === key);
            if (index > -1) {
                this.infoList = remove(index, 1, this.infoList);
                this.forceUpdate();
            }
        }
    }
    render() {
        const title = path([0, 'title'], this.infoList);
        const content = path([0, 'content'], this.infoList);
        const key = path([0, 'key'], this.infoList);
        const status = path([0, 'status'], this.infoList);
        return (status !== undefined ? <Dialog.Alert open={!!title} title={title} content={content} status={status} dialogStyle={{
            width: '500px',
        }} actions={!!key ? [
            {
                label: localization(`ok`, `DialogLabel`),
                action: () => this.remove(key),
            },
        ] : undefined} ref={(ref) => this.infoRef = ref}/>
            :
                <Dialog.Info open={!!title} title={title} content={content} dialogStyle={{
                    width: '500px',
                }} actions={!!key ? [
                    {
                        label: localization(`ok`, `DialogLabel`),
                        action: () => this.remove(key),
                    },
                ] : undefined} ref={(ref) => this.infoRef = ref}/>);
    }
}
export default InfoWrapper;
