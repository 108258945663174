export default {
    about_us: 'เกี่ยวกับเรา',
    how_to_book: 'วิธีการจอง',
    business_partners: 'พันธมิตรทางธุรกิจ',
    wish_list: 'สินค้าที่ฉันชอบ',
    profile: 'โปรไฟล์',
    log_in: 'ลงชื่อเข้าใช้/สมัคร',
    log_out: 'ออกจากระบบ',
    searchPanelTab1: 'เดย์ทัวร์',
    searchPanelTab2: 'รถรับส่ง',
    searchPanelTab3: 'เฟอร์รี่',
    destination: 'จุดหมาย',
    activities: 'กิจกรรม',
    pick_up_location: 'สถานที่รับ',
    drop_off_location: 'สถานที่ส่ง',
    pick_up_origin: 'สถานที่รับ',
    drop_off_destination: 'สถานที่ส่ง',
    date: 'วันที่',
    passenger: 'ผู้โดยสาร',
    search: 'ค้นหา',
    exclusive_for: 'สิทธิพิเศษสำหรับสมาชิก',
    platforms: 'ทราวิสโก้',
    member: '',
    extendsDataHeader1: 'คัดสรรสิ่งที่ดีที่สุด',
    extendsDataDes1: 'ตามความต้องการของคุณ',
    extendsDataHeader2: 'ราคาที่ดีที่สุด',
    extendsDataDes2: 'ลดสูงสุด 60%',
    extendsDataHeader3: 'ปลอดภัย และง่าย',
    extendsDataDes3: 'ให้บริการด้านการท่องเที่ยวแบบครบวงจร',
    our_partner: 'พันธมิตรของเรา',
    price_guarantee: 'ราคายุติธรรม',
    your_experience: 'ประสบการณ์ของคุณ\nคือประสบการณ์ของเรา !',
    the_best: 'ร่วมสร้างประสบการณ์ที่ดีที่สุดกับทราวิสโก้',
    from: 'เริ่มต้น',
    baht: 'บาท',
    book_now: 'จองด่วน',
    book_from: 'ช่วงเวลาจอง 18 – 27 ธันวาคม 2018',
    travel_from: 'ช่วงเวลาการเดินทาง 19 ธันวาคม – 30 พฤษภาคม 2019',
    terms_apply: 'เป็นไปตามข้อกำหนดและเงื่อนไข',
    book_easy: 'จองง่าย และ ประหยัดมากกว่า',
    day_trips_airport: 'เดย์ทัวร์ รถรับ-ส่งสนามบิน เฟอร์รี่',
    airport_transfer: 'บริการรถรับ-ส่งสนามบิน',
    a_car_for_every: 'บริการรถรับ - ส่ง ทุกรูปแบบตามงบประมาณของคุณ',
    from_low: 'ตอบสนองทุกไลฟ์สไตล์การเดินทาง',
    select: 'กรุณาเลือก',
    no_option: 'ไม่พบข้อมูล',
    please_login: 'กรุณาเข้าสู่ระบบเพื่อเพิ่มสินค้าที่คุณชอบ',
    travel_date_calendar: 'วันเดินทาง',
    site_maintenance: 'เว็บไซต์นี้อยู่ระหว่างการปรับปรุง',
    // footer
    contact: 'บริการลูกค้า',
    privacy_policy: 'นโยบายความเป็นส่วนตัว',
    term_conditions: 'ข้อตกลงและเงื่อนไข',
    complaint_policy: 'นโยบายการร้องเรียน',
    cookie_policy: 'ข้อกำหนดการใช้งาน',
    aboutus: 'เกี่ยวกับเรา',
    follow_us: 'ติดตามพวกเราได้ที่',
    license: 'ใบอนุญาติท่องเที่ยว\n\n\nเลขที่',
    way_you_can_pay: 'ช่องทางการชำระเงิน',
    support: 'Support',
    company: 'Company',
    address: '288/6 หมู่ 5 ปากเกร็ด นนทบุรี 11120',
    businessHours: 'ทุกวัน 09.00 - 18.00',
    emailContact: 'info@Travisgo.com',
    line: '@travisgo',
    whatsApp: '+66 84889 7776',
    website: 'www.travisgo.com',
};
