var Symbol;
(function (Symbol) {
    Symbol["THB"] = "\u0E3F";
    Symbol["USD"] = "$";
    Symbol["CNY"] = "\uFFE5";
})(Symbol || (Symbol = {}));
;
export var Cuurency;
(function (Cuurency) {
    Cuurency["THB"] = "THB";
    Cuurency["USD"] = "USD";
    Cuurency["CNY"] = "CNY";
})(Cuurency || (Cuurency = {}));
;
// enum Currency{THA = 'THB', ENG = 'ENG', CNA = 'CNA' }
export const GetSymbol = (_currency) => {
    // console.log('GetSymbol : ', _currency)
    switch (_currency) {
        case Cuurency.THB: return Symbol.THB;
        case Cuurency.USD: return Symbol.USD;
        case Cuurency.CNY: return Symbol.CNY;
        case '': return _currency;
        default: return Symbol.USD;
    }
};
export var AgeCondition;
(function (AgeCondition) {
    AgeCondition["Adult"] = "13+ Yrs";
    AgeCondition["Kid"] = "4 - 12 Yrs";
    AgeCondition["Infant"] = "0 - 3 Yrs";
})(AgeCondition || (AgeCondition = {}));
//export enum AgeCondition { Adult = 'Age 13+ Yrs', Kid = 'Age 4 - 12 Yrs', Infant = 'Age 0 - 3 Yrs' }
