import { path } from "ramda";
import { validateActions } from '../utils/validator';
import transform from "../utils/transform";
import { inputType, mockOptions, countryCode } from "../constant";
import { fieldsName as regiterFieldsName } from "./register";
import localization from "../services/localization";
import { isSafari } from "../containers/PackageInfoPanel/GetItemColsPerWidth";
import { nationalities } from "../constant/json/nationalities";
export const fieldsName = {
    [regiterFieldsName.title]: regiterFieldsName.title,
    [regiterFieldsName.nationality]: regiterFieldsName.nationality,
    [regiterFieldsName.firstName]: regiterFieldsName.firstName,
    [regiterFieldsName.lastName]: regiterFieldsName.lastName,
    [regiterFieldsName.email]: regiterFieldsName.email,
    [regiterFieldsName.countryCode]: regiterFieldsName.countryCode,
    [regiterFieldsName.tel]: regiterFieldsName.tel,
    [regiterFieldsName.social]: regiterFieldsName.social,
    [regiterFieldsName.socialId]: regiterFieldsName.socialId,
    request: 'request',
    pickupDetail: 'pickupDetail',
};
export default ({ onPressEnter, defaultValues, isMobile, tel, code, checkSocial, selectSocial }) => ({
    [fieldsName.title]: {
        label: localization(`title`, `ProfileLabel`),
        type: inputType.RADIO,
        require: true,
        validate: (val) => {
            if (val === 0 || val === '') {
                return 'error_require_title';
            }
        },
        md: isMobile ? 12 : 3,
        style: {
            md: {
            // paddingRight: '20px',
            },
        },
        value: defaultValues[regiterFieldsName.title] ?
            transform.userTitle(defaultValues[regiterFieldsName.title])
            :
                undefined,
        options: mockOptions.title,
    },
    [fieldsName.firstName]: {
        label: localization(`firstname_info`, `BookingLabel`),
        placeholder: localization(`name_placeholder`, `BookingLabel`),
        require: true,
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_firstname,
        value: path([fieldsName.firstName], defaultValues),
        md: isMobile ? 12 : 3,
        style: {
            default: {
            // paddingTop: '10px',
            },
            md: {
                paddingRight: '20px',
                letterSpacing: '-1px',
            },
        },
    },
    [fieldsName.lastName]: {
        label: localization(`lastname_info`, `BookingLabel`),
        placeholder: localization(`name_placeholder`, `BookingLabel`),
        require: true,
        type: inputType.TEXT_INPUT,
        validate: validateActions.user_lastname,
        value: path([fieldsName.lastName], defaultValues),
        onPressEnter,
        md: isMobile ? 12 : 3,
        style: {
            default: {
            // paddingTop: '20px',
            },
            md: {
                // paddingTop: '10px',
                paddingLeft: '20px',
                letterSpacing: '-1px',
            },
        },
    },
    [fieldsName.nationality]: {
        label: localization(`nationality`, `BookingLabel`),
        placeholder: localization(`nationality`, `BookingLabel`),
        type: inputType.SELECT,
        validate: validateActions.user_nationality,
        value: path([fieldsName.nationality], defaultValues),
        choices: nationalities.map((each) => ({ id: each.label, label: each.label })),
        filter: true,
        require: true,
        md: isMobile ? 10 : 3,
        style: {
            md: {
                paddingRight: '10px',
                paddingLeft: '20px',
            },
        },
        options: {
            selector: {
                style: {
                    backgroundColor: '#fff',
                },
                collapseStyle: {
                    position: isMobile ? 'relative' : 'absolute',
                    width: '230px',
                },
            },
        },
    },
    [fieldsName.email]: {
        label: localization(`email`, `ProfileLabel`),
        placeholder: localization(`email`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        require: true,
        validate: validateActions.user_email,
        value: path([fieldsName.email], defaultValues),
        md: isMobile ? 12 : 6,
        style: {
            md: {
                paddingRight: '20px',
            },
        },
    },
    [fieldsName.countryCode]: {
        label: localization(`dialingCode`, `ProfileLabel`),
        placeholder: '',
        type: inputType.SELECT,
        choices: countryCode,
        show: 'id',
        require: true,
        validate: validateActions.user_countryCode,
        value: !!defaultValues[regiterFieldsName.countryCode] ? defaultValues[regiterFieldsName.countryCode] : code,
        md: isMobile ? 4 : 2,
        filter: true,
        style: {
            default: {
                marginTop: '1px',
            },
            md: {
                paddingLeft: '20px',
                marginTop: '1px',
                maxWidth: '11.66667%',
                flexBasis: '11.66667%',
                letterSpacing: '-0.5px',
            },
        },
        options: {
            selector: {
                label: {
                    textAlign: 'center',
                    paddingLeft: '15px',
                },
                style: {
                    backgroundColor: '#fff',
                    height: isSafari() ? 44 : 41,
                    borderRadius: '2px',
                },
                collapseStyle: {
                    position: isMobile ? 'relative' : 'absolute',
                    width: '285px',
                },
                labelWrapperStyle: {
                    minWidth: '85px',
                },
            },
        },
    },
    [fieldsName.tel]: {
        label: localization(`tel`, `ProfileLabel`),
        placeholder: localization(`tel`, `ProfileLabel`),
        type: inputType.TEXT_INPUT,
        // require: true,
        validate: validateActions.user_tel,
        value: tel,
        md: isMobile ? 8 : 4,
        style: {
            md: {
                maxWidth: '38.33333%',
                flexBasis: '38.33333%',
            },
        },
    },
    [fieldsName.social]: {
        label: localization(`social`, `AuthenLabel`),
        placeholder: 'Please select social',
        type: inputType.SELECT,
        value: path([fieldsName.social], defaultValues),
        choices: mockOptions.socialOption,
        md: isMobile ? 10 : 6,
        style: {
            default: {
                paddingRight: '30px',
            },
            md: {
                paddingRight: '30px',
            },
        },
        options: {
            selector: {
                style: {
                    backgroundColor: '#fff',
                },
                collapseStyle: {
                    position: isMobile ? 'relative' : 'absolute',
                    width: '155px',
                },
            },
        },
        onClick: selectSocial,
    },
    [fieldsName.socialId]: {
        label: localization(`social_id`, `AuthenLabel`),
        placeholder: 'WhatsApp no. / Wechat ID / Line ID',
        type: inputType.TEXT_INPUT,
        value: path([fieldsName.socialId], defaultValues),
        validate: checkSocial === false ? null : validateActions.user_socialId,
        md: isMobile ? 12 : 6,
        style: {
            md: {
                paddingLeft: '20px',
            },
        },
    },
});
