import * as React from 'react';
import { path, append, remove, update } from "ramda";
import Dialog from "../components/dialog";
class LoadingWrapper extends React.Component {
    constructor(props) {
        super(props);
        // public loadingRef: any;
        this.loadingList = [];
        Dialog.ModalInstances.getInstance().setModal('loading', this);
    }
    loading(data) {
        const key = path(['key'], data);
        if (!!key && key !== '') {
            const index = this.loadingList.findIndex((each) => each.key === key);
            if (index === -1) {
                this.loadingList = append(data, this.loadingList);
                this.forceUpdate();
            }
        }
    }
    update(data) {
        const key = path(['key'], data);
        if (!!key && key !== '') {
            const index = this.loadingList.findIndex((each) => each.key === key);
            if (index > -1) {
                this.loadingList = update(index, data, this.loadingList);
                this.forceUpdate();
            }
        }
    }
    remove(key) {
        if (!!key && key !== '') {
            const index = this.loadingList.findIndex((each) => each.key === key);
            if (index > -1) {
                this.loadingList = remove(index, 1, this.loadingList);
                this.forceUpdate();
            }
        }
    }
    render() {
        const text = path([0, 'text'], this.loadingList);
        return <Dialog.Loading text={text} open={!!text} variant={path([0, 'variant'], this.loadingList)} value={path([0, 'value'], this.loadingList)} valueBuffer={path([0, 'valueBuffer'], this.loadingList)}/>;
    }
}
export default LoadingWrapper;
