import { path } from "ramda";
import store from "../redux";
import AuthenActions from "../redux/AuthenRedux";
import ErrorActions from "../redux/ErrorRedux";
import OtherActions from "../redux/OtherRedux";
import PackageActions from "../redux/PackageRedux";
import BookingActions from "../redux/BookingRedux";
import SettingActions from "../redux/SettingsRedux";
import { history } from "./navigationServices";
import { reduxKeys, pathName } from "../constant";
export default () => {
    let paramLanguage;
    const { [reduxKeys.settings]: { language, currency } } = store.getState();
    let webSearch = path(['location', 'search'], window);
    webSearch = !!webSearch && webSearch !== '' ? webSearch.slice(1) : null;
    let hash = path(['location', 'hash'], window);
    hash = !!hash && hash !== '' ? hash : null;
    let pageName = window.location.pathname === '/' ? 'home' : window.location.pathname.substr(1);
    console.log('startUpservice : ', window.location);
    // store.dispatch(PackageActions.packageListRequest({ language, currencyTo: currency }));
    if (!!hash && !webSearch) {
        const payload = {
            affiliateId: hash.substr(1),
            pageName,
        };
        store.dispatch(OtherActions.getAffiliateStatisticsRequest(payload));
    }
    if (!!webSearch) {
        if (webSearch.indexOf('&') > 0) {
            webSearch = webSearch.split('&');
        }
        else {
            webSearch = [webSearch];
        }
        if (!!hash) {
            const choosePackage = {
                affiliateId: hash.substr(1),
                pageName,
                packageId: webSearch[0]
            };
            const search = {
                affiliateId: hash.substr(1),
                pageName: pageName.substr(0, 6),
                remark: pageName.substr(7)
            };
            const payload = pageName === 'choosePackage' ? choosePackage : search;
            store.dispatch(OtherActions.getAffiliateStatisticsRequest(payload));
        }
        webSearch.forEach((each) => {
            const part = each.split('=');
            const key = part[0];
            const value = part[1];
            const validLang = ["TH", "EN"];
            if (key === 'language') {
                // console.log('startUpservice 2 : ', value.toLocaleUpperCase())
                paramLanguage = validLang.indexOf(value.toLocaleUpperCase()) > -1 ? value.toLocaleUpperCase() : "EN";
            }
            if (key === 'token') {
                store.dispatch(AuthenActions.loginSuccess({ token: value }));
                history.replace(pathName.INDEX);
            }
        });
    }
    store.dispatch(ErrorActions.getErrorCodeRequest());
    store.dispatch(OtherActions.getBanksRequest());
    store.dispatch(OtherActions.getLocationServicesRequest({ filter: { where: { isService: true } } }));
    store.dispatch(OtherActions.getDestinationsRequest({ filter: { where: { actived: true } } }));
    store.dispatch(OtherActions.getActivitiesRequest({ filter: { where: { actived: true } } }));
    store.dispatch(OtherActions.getAppointmentsRequest({ filter: { where: { actived: true } } }));
    store.dispatch(OtherActions.getPaymentTimesRequest());
    store.dispatch(OtherActions.getCurrencyRequest({ filter: { where: { actived: true } } }));
    store.dispatch(SettingActions.changeCurrency(currency ? currency : 'THB'));
    store.dispatch(SettingActions.changeLanguage(paramLanguage ? paramLanguage : (language ? language : 'EN')));
    store.dispatch(PackageActions.getPackageCategoryRequest({ language, currencyTo: currency }));
    store.dispatch(OtherActions.clearAnnouncements());
    store.dispatch(BookingActions.getCustomerRequestRequest({
        filter: {
            where: { visible: true },
            order: "requestId ASC",
        },
    }));
    if (!!path([reduxKeys.authen, 'token'], store.getState())) {
        store.dispatch(AuthenActions.loginRequest());
    }
};
