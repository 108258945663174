import { FakeBookedInfoList } from '../../constant/mockupData/FakeBookedInfoList';
export function NumberFormat(price) {
    if (price > 999) {
        const _price = parseFloat(`${price}`).toFixed(2);
        return (`${_price}`).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseFloat(price).toFixed(2);
}
export function NumberFormatNotFixed(price, currency) {
    if (price > 999) {
        const _price = currency === 'THB' ? parseFloat(`${price}`).toFixed(0) : parseFloat(`${price}`).toFixed(2);
        return (`${_price}`).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return currency === 'THB' ? parseFloat(price).toFixed(0) : parseFloat(price).toFixed(2);
}
export function Num2Digit(price) {
    return parseFloat(price).toFixed(2);
}
// const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
const units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
export function SortLargeNumber(num, digits, packageId) {
    const itemInfo = FakeBookedInfoList.find((item) => item.packageId === packageId);
    let _newBooked;
    if (Boolean(itemInfo)) {
        _newBooked = num + (itemInfo.booked || 0);
    }
    else {
        _newBooked = num + 1000;
    }
    let decimal;
    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);
        if (_newBooked <= -decimal || _newBooked >= decimal) {
            return +(_newBooked / decimal).toFixed(digits) + units[i];
        }
    }
    return _newBooked;
    // }
}
