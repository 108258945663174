import * as React from 'react';
import { equals } from "ramda";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import { InputWithStyle } from "./TextInput";
const iconStyle = {
    width: 20,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 12,
    margin: 'auto',
};
class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnChange = (value) => {
            const { onChange } = this.props;
            this.setState({ value }, () => {
                if (typeof onChange === 'function') {
                    onChange(value);
                }
            });
        };
        this.state = {
            value: props.value,
        };
    }
    static getDerivedStateFromProps(props, state) {
        if (!equals(props.value, state.value)) {
            return {
                value: props.value,
            };
        }
        return null;
    }
    render() {
        return (<InputWithStyle label={this.props.label} placeholder={`Search...`} value={this.state.value} labelStyle={{
            display: 'none',
        }} endAdornment={(this.state.value !== '' ?
            <Close style={iconStyle} onClick={() => this.handleOnChange('')}/>
            :
                <Search style={{ ...iconStyle, color: '#ccc' }}/>)} displayError={false} textFieldRootStyle={{
            marginTop: 0,
            width: 'calc(100% - 32px)',
        }} inputStyle={{
            border: 'none',
            marginTop: -16,
            borderBottom: '1px solid #ccc',
        }} onChange={(value) => this.handleOnChange(value)} inputProps={{
            onKeyDown: (e) => {
                // backslash bug Regexp
                if (e.keyCode === 220) {
                    e.preventDefault();
                }
                if (e.key === '+') {
                    e.preventDefault();
                }
            },
        }}/>);
    }
}
export default Filter;
