import Dialog from "../components/dialog";
import localization from "./localization";
const key = "omise" /* omise */;
const createOmiseTokenRequest = (payload) => {
    Dialog.loading({ key, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
    return new Promise((resolve) => {
        window.Omise.createToken("card", payload, (statusCode, response) => {
            Dialog.loading().remove(key);
            if (statusCode === 200) {
                resolve({ success: true, token: response.id, type: "card", payload });
                // Success: send back the TOKEN_ID to your server to create a charge.
                // The TOKEN_ID can be found in `response.id`.
            }
            else {
                resolve({ success: false });
                Dialog.error({ key, title: localization('error_title_omise'), content: response.message });
                // Error: display an error message. Note that `response.message` contains
                // a preformatted error message. Also note that `response.code` will be
                // "invalid_card" in case of validation error on the card.
                // Example Error displaying
                // alert(response.code + ": " + response.message);
            }
        });
    });
};
const createOmiseSourceRequest = (type, payload) => {
    Dialog.loading({ key, text: `${localization(`loading_please_wait`, `DialogLabel`)}` });
    return new Promise((resolve) => {
        window.Omise.createSource(type, payload, (statusCode, response) => {
            // console.log(response);
            Dialog.loading().remove(key);
            if (statusCode === 200) {
                resolve({ success: true, token: response.id, type: response.type, payload });
            }
            else {
                resolve({ success: false });
                Dialog.error({ key, title: localization('error_title_omise'), content: response.message });
            }
        });
    });
};
export default {
    createOmiseTokenRequest,
    createOmiseSourceRequest,
};
