import localForage from 'localforage';
import { reduxKeys } from "../constant";
const reduxPersist = {
    reducerVersion: '1.00',
    storeConfig: {
        key: 'primary',
        storage: localForage,
        // reducer keys that you do NOT want stored to persistence here
        blacklist: [reduxKeys.user, reduxKeys.error, reduxKeys.screen],
    },
};
export default reduxPersist;
