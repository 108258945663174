import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { compose } from "recompose";
import { path, omit } from "ramda";
import Paper from "@material-ui/core/Paper";
import Space from "../components/Space";
import Button from "../components/button";
import { CheckBoxWithStyle } from "../components/form/CheckBox";
import ContentWrapper from "../components/ContentWrapper";
import PaymentDetailComponents from "../components/booking";
import SubTopicsPanel from '../components/SubTopicsPanel';
import StepperPanel from '../components/StepperPanel';
import BookingComponent from "../components/booking";
import Dialog from "../components/dialog";
import TraceFacebookPixel, { TrackType } from '../components/TraceInfo/FacebookPixel';
import { transferFieldsName, transferType } from "../formtypes/additionGuestInfo";
import BookingActions, { BOOKING_KEY } from "../redux/BookingRedux";
import UserActions from "../redux/UserRedux";
import transform from "../utils/transform";
import { scrollToRef } from "../utils/scroll";
import { reduxKeys, pathName, paymentMethod as paymentMethodConst, packageType, bookingListKeys, countryCode, } from "../constant";
import { Colors, Metrics } from "../themes";
import localization from "../services/localization";
import { optionsServices } from "../services";
import config from '../config';
import store from '../redux';
import { deviceInfo } from '../components/common/DeviceInfo';
const transferInfoFields = {
    dropOffDetail: 'dropOffDetail',
    pickUpDetail: 'pickUpDetail',
};
const steps = {
    3.1: '3.1',
    3.2: '3.2',
};
const mobileSteps = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
};
class PaymentDetail extends Component {
    constructor(props) {
        super(props);
        this.selectedPackage = null;
        this.travelerInfo = null;
        this.infoModal = null;
        this.creditModal = null;
        this.onPressMobileButton = () => {
            const { mobileStep, step } = this.state;
            const isAllTraveller = path(['isAllTraveller'], this.selectedPackage);
            if (step === steps["3.2"]) {
                this.goNextStep();
            }
            else {
                switch (mobileStep) {
                    case mobileSteps["1"]: {
                        this.askToRecheckDetail(() => {
                            const form = this.ref.submit();
                            if (form.success) {
                                this.setState({ mobileStep: mobileStep + 1 });
                            }
                        });
                        break;
                    }
                    case mobileSteps["2"]: {
                        if (isAllTraveller === true) {
                            const packageListInfo = path(['location', 'state', 'packageList'], this.props);
                            const bookingInfo = path(['0', 'bookingInfo'], packageListInfo);
                            const { adult, kid, infant, passengers } = bookingInfo;
                            const isTransfer = path(['type'], this.selectedPackage) === "Transfer";
                            const travelerAll = isTransfer === true ? passengers : adult + kid + infant;
                            const form = this.travelerInfo.submit();
                            const checkForm = form.filter((each) => each.success === true);
                            // console.log(form.filter((each) => each.success === true));
                            // console.log(checkForm.length === travelerAll);
                            if (checkForm.length === travelerAll) {
                                this.setState({ mobileStep: mobileStep + 1 });
                            }
                        }
                        else {
                            const form = this.additionForm.submit();
                            if (form.success) {
                                this.setState({ mobileStep: mobileStep + 1 });
                            }
                        }
                        break;
                    }
                    case mobileSteps["3"]: {
                        if (isAllTraveller === true) {
                            const form = this.additionForm.submit();
                            if (form.success) {
                                this.setState({ mobileStep: mobileStep + 1 });
                            }
                        }
                        else {
                            this.checkCreditCard(this.onSubmit);
                        }
                        break;
                    }
                    case mobileSteps["4"]: {
                        this.checkCreditCard(this.onSubmit);
                        break;
                    }
                    default:
                        break;
                }
            }
        };
        this.onDesktopSubmit = () => {
            this.askToRecheckDetail(this.onSubmit);
        };
        this.getTransportType = () => {
            return path(['packageInfo', 'transferType', 'conveyanceType'], this.selectedPackage) || '';
        };
        this.getTransferIn = () => {
            return !!path(['packageInfo', 'transferType', 'transferIn'], this.selectedPackage);
        };
        this.translateTransferIn = () => {
            return this.getTransferIn() ? transferType.pickUp : transferType.dropOff;
        };
        // default is OneDayTrip
        this.getPackageType = () => {
            return path(['type'], this.selectedPackage) || packageType.OneDayTrip;
        };
        this.getCustomerRequestOptions = (withDefault = false) => {
            const { [reduxKeys.booking]: { customerRequest }, [reduxKeys.settings]: { language } } = store.getState();
            let customerRequestOptions = withDefault ? [{ id: '', label: 'None', default: true }] : [];
            if (Array.isArray(customerRequest)) {
                customerRequestOptions = customerRequestOptions.concat((language === 'EN') ? customerRequest.map(({ requestId: id, langugeENG }) => ({ id, label: langugeENG.requestName })) :
                    customerRequest.map(({ requestId: id, langugeTH }) => ({ id, label: langugeTH.requestName })));
            }
            return customerRequestOptions;
        };
        this.goNextStep = () => {
            const { [reduxKeys.booking]: { selectedBooking } } = this.props;
            this.props.history.push({
                pathname: pathName.CONFIRM_PAYMMENT,
                search: `bookingId=${selectedBooking.bookingId}&email=${selectedBooking.buyerInfo.email}`,
            });
        };
        this.state = {
            agree: false,
            agreeError: false,
            step: !!props[reduxKeys.booking].selectedBooking ? steps["3.2"] : steps["3.1"],
            mobileStep: mobileSteps["1"],
        };
        const packageInfo = path(['location', 'state', 'packageList'], this.props);
        if (!packageInfo) {
            this.props.history.push(pathName.INDEX);
            // console.warn('constructor: ', this.props)
        }
        const { packageId } = packageInfo[0];
        TraceFacebookPixel({ trackType: TrackType.PageView, data: { packageId } });
        this.onSubmit = this.onSubmit.bind(this);
    }
    static getDerivedStateFromProps(props, state) {
        if (!!props[reduxKeys.booking].selectedBooking && state.step === steps["3.1"]) {
            window.scrollTo(0, 0);
            return { step: steps["3.2"] };
        }
        return null;
    }
    async onSubmit() {
        const { agree } = this.state;
        if (agree) {
            this.setState({ agreeError: false });
            const data = this.onSubmitInfoForm();
            const additionFormData = this.additionForm.submit();
            const isAllTraveller = path(['isAllTraveller'], this.selectedPackage);
            const packageListInfo = path(['location', 'state', 'packageList'], this.props);
            const bookingInfo = path(['0', 'bookingInfo'], packageListInfo);
            const { adult, kid, infant, passengers } = bookingInfo;
            const isTransfer = path(['type'], this.selectedPackage) === "Transfer";
            const travelerAll = isTransfer === true ? passengers : adult + kid + infant;
            let travelerInfoFormData;
            let checkForm;
            if (isAllTraveller === true) {
                travelerInfoFormData = this.travelerInfo.submit();
                checkForm = travelerInfoFormData.filter((each) => each.success === true);
            }
            if (isAllTraveller === true ? checkForm.length === travelerAll : true) {
                if (additionFormData.success) {
                    // await for omise
                    const res = await this.payment.submit();
                    if (res.success) {
                        const { [reduxKeys.authen]: { token }, [reduxKeys.settings]: { language, currency } } = this.props;
                        const isLoggedIn = !!token;
                        // combind state, payment, user info here
                        const paymentMethod = PaymentDetailComponents.PaymentChannel
                            .paymentChannel({ isLoggedIn })[this.payment.state.expanded].method;
                        data.title = (Boolean(data.title)) ? transform.userTitle(data.title) : '';
                        const packageList = path(['location', 'state', 'packageList'], this.props);
                        // const bookingInfo = path(['0', 'bookingInfo'], packageList);
                        const summary = path(['location', 'state', 'summary'], this.props);
                        const stateData = path(['location', 'state'], this.props) || {};
                        const peopleId = omit(['packageList'], stateData);
                        // console.log('packageList : ', this.selectedPackage);
                        const { type, packageId, packageInfo } = this.selectedPackage;
                        const { name } = packageInfo;
                        // const { adult, kid, infant }: any = bookingInfo;
                        const newInfo = { packageId, type, name, amount: (adult + kid + infant), summary, currency };
                        TraceFacebookPixel({ trackType: TrackType.InitiateCheckout, data: newInfo });
                        const traveller = isAllTraveller === true ? travelerInfoFormData.map((each) => each.data) : [];
                        let payload = {
                            language,
                            isDev: config.isDev,
                            deviceInfo: deviceInfo(),
                            currencyTo: currency,
                            buyerInfo: {
                                ...data, ...additionFormData.data,
                            },
                            travellerInfoList: traveller,
                            packageList,
                            paymentInfo: { paymentMethod, paymentToken: res.token, subPaymentMethod: res.type },
                            ...peopleId,
                        };
                        if (this.getPackageType() === packageType.Transfer && additionFormData.success) {
                            payload.buyerInfo = { ...data };
                            const transferTypeData = path(['packageInfo', 'transferType'], this.selectedPackage) || {};
                            // const blankData: any = {};
                            // set blank data
                            // values(transferFieldsName[this.getTransferIn() ?
                            //  'dropOff' : 'pickUp']).forEach((each) => { blankData[each] = ''; });
                            payload.transferInfo = {
                                moreDetail: {
                                    otherDetail: additionFormData.data.specialRequest,
                                    commendDriver: '',
                                    tourOrActivityLanguage: '',
                                },
                                [transferInfoFields.pickUpDetail]: {
                                    ...additionFormData.data,
                                },
                                [transferInfoFields.dropOffDetail]: {
                                    ...additionFormData.data,
                                },
                                // [this.getTransferIn() ?
                                //     transferInfoFields.pickUpDetail : transferInfoFields.dropOffDetail]: {
                                //     ...additionFormData.data,
                                // },
                                // [this.getTransferIn() ?
                                //     transferInfoFields.dropOffDetail : transferInfoFields.pickUpDetail]: {
                                //     ...blankData,
                                // },
                                ...transferTypeData,
                            };
                        }
                        if (paymentMethod !== paymentMethodConst.Omise) {
                            payload = omit(['paymentToken'], payload);
                        }
                        this.props.dispatch(BookingActions.bookingRequest(payload, isLoggedIn, (selectedBooking = {}) => {
                            // console.log('tesco', selectedBooking);
                            switch (paymentMethod) {
                                case paymentMethodConst.Omise:
                                    {
                                        // Omise redirect to success page
                                        if (!config.is3DSecureActive) {
                                            console.log('payload : ', selectedBooking);
                                            const { bookingStatus } = selectedBooking;
                                            if (bookingStatus === "approved" /* APPROVED */) {
                                                this.props.history.push(pathName.BOOKING_SUCCESS, { ...selectedBooking });
                                            }
                                            const { status, metadata } = selectedBooking.omiseData;
                                            console.warn('metadata [PaymentDetail] :  ', status);
                                            switch (status) {
                                                case "successful" /* successful */:
                                                    {
                                                        this.props.history.push(pathName.BOOKING_SUCCESS, { ...selectedBooking });
                                                    }
                                                    break;
                                                case "pending" /* pending */:
                                                    {
                                                        if (selectedBooking.paymentInfo.subPaymentMethod === 'bill_payment_tesco_lotus') {
                                                            this.props.dispatch(BookingActions.selectBooking(selectedBooking));
                                                        }
                                                        else {
                                                            const { booking } = metadata;
                                                            localStorage.removeItem(bookingListKeys.bookingInfoKey);
                                                            this.props.history.push(pathName.BOOKING_SUCCESS, { ...selectedBooking, booking, paymentMethod });
                                                        }
                                                    }
                                                    break;
                                                case "failed" /* failed */:
                                                    {
                                                        const { failure_code, failure_message } = selectedBooking;
                                                        if (!!Dialog.error()) {
                                                            Dialog.error({
                                                                key: BOOKING_KEY,
                                                                title: 'Payment Error.',
                                                                content: (localization(failure_code)
                                                                    || failure_message),
                                                            });
                                                        }
                                                    }
                                                    break;
                                                default:
                                                    {
                                                    }
                                                    break;
                                            }
                                        }
                                        else {
                                            // console.warn('payload [PaymentDetail] :  ', selectedBooking);
                                            // const { status, authorize_uri,
                                            //  authorized, return_uri } = selectedBooking;
                                            const metadata = path(['omiseData', 'metadata'], selectedBooking)
                                                || path(['metadata'], selectedBooking);
                                            const status = path(['omiseData', 'status'], selectedBooking)
                                                || path(['status'], selectedBooking);
                                            // const { omiseData } = selectedBooking;
                                            // const { metadata, status } = omiseData;
                                            const { booking } = metadata;
                                            switch (status) {
                                                case "successful" /* successful */:
                                                    {
                                                        const { packageInfo, priceTotal } = booking;
                                                        const { packageId, type, bookingInfo, name } = Array.isArray(packageInfo) ? packageInfo[0] : packageInfo;
                                                        const { adult, infant, kid } = bookingInfo;
                                                        const { subTotal, currency } = priceTotal;
                                                        const newInfo = {
                                                            packageId, type, name, amount: (adult + infant + kid),
                                                            summary: subTotal, currency,
                                                        };
                                                        TraceFacebookPixel({ trackType: TrackType.Purchase, data: newInfo });
                                                        this.props.history.push(pathName.BOOKING_SUCCESS, { ...selectedBooking, booking, paymentMethod });
                                                    }
                                                    break;
                                                case "pending" /* pending */:
                                                    {
                                                        const subPaymentMethod = path(['paymentInfo', 'subPaymentMethod'], selectedBooking) || path(['paymentInfo', 'subPaymentMethod'], booking);
                                                        if (subPaymentMethod === 'bill_payment_tesco_lotus') {
                                                            this.props.dispatch(BookingActions.selectBooking(selectedBooking));
                                                        }
                                                        else {
                                                            console.warn('metadata [PaymentDetail] :  ', booking);
                                                            localStorage.removeItem(bookingListKeys.bookingInfoKey);
                                                            this.props.history.push(pathName.BOOKING_SUCCESS, { ...selectedBooking, booking, paymentMethod });
                                                        }
                                                    }
                                                    break;
                                                case "failed" /* failed */:
                                                    {
                                                        const { failure_code, failure_message } = selectedBooking;
                                                        if (!!Dialog.error()) {
                                                            Dialog.error({
                                                                key: BOOKING_KEY,
                                                                title: 'Payment Error.',
                                                                content: (localization(failure_code)
                                                                    || failure_message),
                                                            });
                                                        }
                                                    }
                                                    break;
                                                default:
                                                    {
                                                    }
                                                    break;
                                            }
                                        }
                                    }
                                    break;
                                case paymentMethodConst.Alienation:
                                    {
                                        // Alienation go to step 3.2(display data);
                                        this.props.dispatch(BookingActions.selectBooking(selectedBooking));
                                    }
                                    break;
                            }
                        }));
                    }
                    else {
                        scrollToRef(this.payment, true);
                    }
                }
                else {
                    scrollToRef(this.additionForm, true);
                }
            }
            else {
                scrollToRef(this.travelerInfo, true);
            }
        }
        else {
            this.setState({ agreeError: true });
        }
    }
    onSubmitInfoForm() {
        const { success, data } = this.ref.submit();
        if (success) {
            return data;
        }
        else {
            scrollToRef(this.guest);
            return null;
        }
    }
    async checkCreditCard(nextAction) {
        const { user } = this.props;
        const creditCard = path(['data', 'creditCard'], user);
        const paymentMethod = await this.payment.submit();
        console.log('paymentMethod', paymentMethod);
        if (paymentMethod.type === "card") {
            if (creditCard !== null) {
                if (typeof nextAction === 'function') {
                    nextAction();
                }
            }
            else {
                this.creditModal.ask({
                    title: localization(`save_credit_card`, `DialogLabel`),
                    actions: [
                        {
                            label: localization(`confirm_no`, `DialogLabel`),
                            action: () => {
                                if (typeof nextAction === 'function') {
                                    nextAction();
                                }
                            },
                        },
                        {
                            label: localization(`confirm_yes`, `DialogLabel`),
                            action: () => { this.updateCreditCard(nextAction); },
                        },
                    ],
                });
            }
        }
        else {
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
    }
    async askToRecheckDetail(nextAction) {
        const { [reduxKeys.authen]: { token }, isMobile } = this.props;
        const isLoggedIn = !!token;
        const data = this.onSubmitInfoForm();
        // ask guest to recheck email and tel
        if (!isLoggedIn) {
            if (!!data) {
                const { email, tel } = data;
                this.infoModal.ask({
                    title: localization(`are_your_booking`, `BookingLabel`),
                    customContent: (<div>
                            {`${localization(`our_eticket`, `BookingLabel`)} ${email}, ${localization(`its_appointment`, `BookingLabel`)} ${tel}`}
                            <br />
                            {`${localization(`please_confirm`, `BookingLabel`)}`}
                        </div>),
                    actions: [
                        {
                            label: localization(`change`, `BookingLabel`),
                        },
                        {
                            label: localization(`correct`, `BookingLabel`),
                            action: () => {
                                if (typeof nextAction === 'function') {
                                    nextAction();
                                }
                            },
                        },
                    ],
                });
            }
        }
        else {
            if (config.is3DSecureActive) {
                if (typeof nextAction === 'function') {
                    nextAction();
                }
            }
            else {
                if (!isMobile) {
                    this.checkCreditCard(nextAction);
                }
                else {
                    if (typeof nextAction === 'function') {
                        nextAction();
                    }
                }
            }
        }
    }
    async updateCreditCard(nextAction) {
        const paymentMethod = await this.payment.submit();
        const payload = {
            nameOnCard: paymentMethod.payload.name,
            creditCard: paymentMethod.payload.number,
            expireCard: `${paymentMethod.payload.expiration_month}/${paymentMethod.payload.expiration_year}`,
        };
        if (payload.creditCard.indexOf('-') > 0) {
            payload.creditCard = transform.creditCardNumber(payload.creditCard);
        }
        await this.props.dispatch(UserActions.updateUserRequest({ user: payload }, (res) => {
            if (!!res) {
                if (typeof nextAction === 'function') {
                    nextAction();
                }
            }
        }));
    }
    render() {
        const { isMobile, [reduxKeys.package]: { packageList }, [reduxKeys.booking]: { selectedBooking }, [reduxKeys.other]: { paymentTimes }, [reduxKeys.authen]: { token }, [reduxKeys.settings]: { language, currency }, } = this.props;
        const { step, mobileStep } = this.state;
        const isLoggedIn = !!token;
        const packageId = path(['location', 'state', 'packageList', 0, 'packageId'], this.props);
        // this.selectedPackage = {};
        const getCustomerRequestOptions = this.getCustomerRequestOptions();
        if (!!packageId && Array.isArray(packageList) && packageList.length > 0) {
            this.selectedPackage = packageList.find((p) => p.packageId === packageId);
        }
        const isPrivate = path(['packageInfo', 'transferType', 'transferType'], this.selectedPackage) === 'private';
        const appointmentId = isPrivate ?
            path(['packageInfo', 'schedule', 0, 'appointmentId'], this.selectedPackage) : "";
        const pickUpList = path(['pickUpList'], this.selectedPackage) || null;
        const dropOffList = path(['dropOffList'], this.selectedPackage) || null;
        const defaultValues = {
            [transferFieldsName[this.translateTransferIn()].location]: isPrivate ?
                appointmentId : "",
        };
        const disabledButton = this.state.agree === true;
        const isPickUp = path(['isPickUp'], this.selectedPackage);
        const isTransfer = path(['type'], this.selectedPackage) === "Transfer";
        const isDropOff = path(['isDropOff'], this.selectedPackage);
        const isAllTraveller = path(['isAllTraveller'], this.selectedPackage);
        const transferIn = path(['packageInfo', 'transferType', 'transferIn'], this.selectedPackage);
        const isFerries = path(['packageInfo', 'transferType', 'conveyanceType'], this.selectedPackage);
        const locationOptions = optionsServices.getLocationServicesOptions();
        const appointLocation = locationOptions.filter((items) => path(['location', 'state', 'packageList', 0, 'locationServiceId'], this.props) === items.id);
        const packageListInfo = path(['location', 'state', 'packageList'], this.props);
        const bookingInfo = path(['0', 'bookingInfo'], packageListInfo);
        const locales = path(['location', 'state', 'locale'], this.props);
        const locale = locales !== undefined ? countryCode.find((each) => each.code === locales) : '';
        const dialingCode = locales !== undefined ? path(['id'], locale) : '';
        const summary = path(['location', 'state', 'summary'], this.props);
        // console.log('isPickUp : ',isPickUp,' isDropOff : ',isDropOff)
        // console.log('transferIn : ', transferIn,'isPickUp : ', pickUpList, ' isDropOff : ', dropOffList)
        return (<ContentWrapper withMaxWidth hideFooter={isMobile} customFooter={isMobile ? {
            // strict: true,
            height: 44,
            element: <Button.Theme onClick={this.onPressMobileButton} disabled={isAllTraveller === true ?
                mobileStep === mobileSteps["4"] ? (!disabledButton) : false :
                mobileStep === mobileSteps["3"] ? (!disabledButton) : false} label={step === steps['3.2'] ?
                'Upload Payment Proof'
                :
                    isAllTraveller === true ? mobileStep === mobileSteps["4"] ?
                        localization(`book`, `BookingLabel`) : localization(`next`, `BookingLabel`) :
                        mobileStep === mobileSteps["3"] ? localization(`book`, `BookingLabel`) : localization(`next`, `BookingLabel`)} height={44} fontFamily="heavy" fontSize={16} letterSpacing="3.8px"/>,
        }
            :
                undefined}>
                <div style={{ width: isMobile ? 'unset' : Metrics.maxInnerWidth }}>
                    <Dialog.Info ref={(ref) => this.infoModal = ref}/>
                    <Dialog.Info ref={(ref) => this.creditModal = ref}/>
                    <SubTopicsPanel withOutBoder _pageName={pathName.PAYMENT_CHANNEL}/>
                    <StepperPanel isStap={2} productType={this.getPackageType()} Language={language} isMobile={isMobile}/>
                    {step === steps["3.1"] && (<React.Fragment>
                                <div style={{ display: !isMobile || mobileStep === mobileSteps["1"] ? '' : 'none' }}>
                                    <PaymentDetailComponents.GuestInfo ref={(ref) => this.guest = ref} formRef={(ref) => this.ref = ref} dialingCode={dialingCode}/>
                                </div>
                                <div style={{
            display: isAllTraveller === true ?
                !isMobile || mobileStep === mobileSteps["2"] ? '' : 'none' : '',
        }}>
                                    <PaymentDetailComponents.travelerInfo formRef={(ref) => this.travelerInfo = ref} bookingInfo={bookingInfo} isAllTraveller={isAllTraveller} isTransfer={isTransfer}/>
                                </div>
                                <div style={{
            display: isAllTraveller === true ?
                !isMobile || mobileStep === mobileSteps["3"] ? '' : 'none' :
                !isMobile || mobileStep === mobileSteps["2"] ? '' : 'none',
        }}>
                                    <PaymentDetailComponents.AdditionalInfo formRef={(ref) => this.additionForm = ref} selectedPackage={this.selectedPackage} translateTransferIn={this.translateTransferIn} getPackageType={this.getPackageType} getCustomerRequestOptions={getCustomerRequestOptions} isPickUp={isPickUp} isDropOff={isDropOff} appointLocation={appointLocation} pickUpList={pickUpList} dropOffList={dropOffList} transferIn={transferIn} isFerries={isFerries} 
        // isPrivate={path(
        //     ['packageInfo', 'transferType', 'transferType'],
        //     this.selectedPackage) === 'private'}
        defaultValues={defaultValues} isMobile={isMobile}/>
                                </div>
                                <div style={{
            display: isAllTraveller === true ?
                !isMobile || mobileStep === mobileSteps["4"] ? '' : 'none' :
                !isMobile || mobileStep === mobileSteps["3"] ? '' : 'none',
        }}>
                                    <PaymentDetailComponents.PaymentChannel.default paymentRef={(ref) => this.payment = ref} bookingInfo={bookingInfo} isLoggedIn={isLoggedIn} isMobile={isMobile} currency={currency} summary={summary}/>
                                    <Paper style={{
            padding: isMobile ?
                '15px 10px 15px 0' : '50px 15px', marginTop: 13,
        }} square>
                                        <CheckBoxWithStyle value={this.state.agree} label={localization(`by_choosing_to_book`, `BookingLabel`)} rootStyle={{ textAlign: 'center' }} labelStyle={{ color: this.state.agreeError ? Colors.error : 'unset' }} onChange={(val) => { this.setState({ agree: val, agreeError: !val }); }}/>

                                        {!isMobile && (<div style={{ textAlign: 'center', margin: '15px 0' }}>
                                                    <div style={{
            height: '45px', width: '315px',
            display: 'inline-block',
        }}>
                                                        <Button.Theme onClick={this.onDesktopSubmit} label={localization(`book`, `BookingLabel`)} disabled={!disabledButton}/>
                                                    </div>
                                                </div>)}
                                    </Paper>
                                </div>
                                {isMobile && (<Space height={13}/>)}
                            </React.Fragment>)}
                    {step === steps["3.2"] && (<BookingComponent.PaymentInstruction selectedBooking={selectedBooking} goNextStep={this.goNextStep} paymentTimes={paymentTimes} isMobile={isMobile} isLoggedIn={isLoggedIn} language={language}/>)}
                </div>
                {(!isMobile) && (<Space height={100}/>)}
            </ContentWrapper>);
    }
}
const mapStatetoProps = (state) => ({
    [reduxKeys.authen]: state[reduxKeys.authen],
    isMobile: state[reduxKeys.screen].isMobile,
    [reduxKeys.package]: state[reduxKeys.package],
    [reduxKeys.booking]: state[reduxKeys.booking],
    [reduxKeys.other]: state[reduxKeys.other],
    [reduxKeys.settings]: state[reduxKeys.settings],
    [reduxKeys.user]: state[reduxKeys.user],
});
export default compose(connect(mapStatetoProps), withRouter)(PaymentDetail);
