import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { compose } from "recompose";
import { path, equals } from "ramda";
import Carousel, { Modal, ModalGateway } from 'react-images';
import DayTripSlider from './DayTripSlider';
import InclutionPanel from './InclusionPanel';
import TourProgramPanel from './TourProgramPanel';
import ServiceIconPanel from './ServiceIconPanel';
import ReviewsPanel from '../Reviews/ReviewListPanel';
import BookingActions from "../../redux/BookingRedux";
import PackageActions from "../../redux/PackageRedux";
import { ConveyanceType } from '../../containers/Search/FilterTypeInfo';
import { reduxKeys, bookingListKeys, packageType, typeOfTransfer } from "../../constant";
import config from '../../config';
import { Fonts, Colors } from "../../themes";
import styled from 'styled-components';
import '../../containers/PackageInfoPanel/Card.css';
import { scrollToRef } from '../../utils/scroll';
import Dialog from "../../components/dialog";
// import Fallback from '../../components/Fallback';
import Review from '../../components/form/Review';
const FormReview = React.memo(Review);
// const Carousel = React.lazy(() => import("react-images"));
const hostName = config.host;
// import PriceDetailBar from './PriceDetailBar';
// import { Metrics, Colors, Fonts } from "../../themes";
// import { isMobile } from '../PackageInfoPanel/GetItemColsPerWidth';
// interface IDayTripCardFull { PackageLists?; SelectedPakage?; handleToggle?; dispatch?; }
const TourProgramPanelRef = React.createRef();
const reviewPanel = React.createRef();
const InclutionPanelRef = React.createRef();
class DayTripCardFull extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookingList: [], limit: 5, expanded: 'panel1', isLogin: false,
            lightboxIsOpen: false, currentImage: 0, picture: [], findReview: '',
        };
        this.handleChange = (panel) => (event, expanded) => {
            this.setState({ expanded: expanded ? panel : false }, () => this.scrollRef(expanded, panel));
        };
        this.scrollRef = (active, refName) => {
            const { [reduxKeys.screen]: { isMobile } } = this.props;
            // console.log('scrollRef : ', refName, ' : ', active, ' : ');
            if (active) {
                switch (refName) {
                    case 'panel1': {
                        return scrollToRef(TourProgramPanelRef.current, true);
                    }
                    case 'panel2': {
                        return scrollToRef(TourProgramPanelRef.current, true);
                    }
                    case 'panel3': {
                        return scrollToRef(TourProgramPanelRef.current, true, isMobile ? 70 : 30);
                    }
                    case 'panel4': {
                        return scrollToRef(TourProgramPanelRef.current, true, isMobile ? 170 : 100);
                    }
                    case 'panel5': {
                        return scrollToRef(TourProgramPanelRef.current, true, isMobile ? 150 : 50);
                    }
                }
            }
            //  scrollToComponent(this.InclutionPanelRef, { offset: 0, align: 'bottom', duration: 400 });
            // window.scrollTo(0, this.InclutionPanelRef.current.offsetTop);
            // window.scrollTo({ top: this.InclutionPanelRef.current.offsetTop - 20, behavior: "smooth" });
            // this.forceUpdate();
        };
        // this.TourProgramPanelRef = React.createRef();
        // this.InclutionPanelRef = React.createRef();
        this.getReviewList();
        // this.getBookingList({ bookingStatus: `${BOOKING_STATUS.COMPLETE}` });
        this.onChangeLightBox = this.onChangeLightBox.bind(this);
        this.onChangeLoadMore = this.onChangeLoadMore.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        const { location } = this.props;
        const { state: thisState } = location;
        const { state: nextState } = nextProps.location;
        // console.log(nextProps, this.props);
        if (nextProps.authen.token !== null || this.props.authen.token !== null) {
            this.setState({ isLogin: true });
        }
        if (!equals(nextProps.authen.token, this.props.authen.token)) {
            this.getBookingList({ bookingStatus: `${"complete" /* COMPLETE */}` });
            const webSearch = path(['location', 'hash'], window);
            if (webSearch === '#review') {
                window.setTimeout(() => { scrollToRef(reviewPanel.current, true, 30); }, 2000);
            }
        }
        switch (true) {
            case Boolean(thisState):
                {
                    if (!equals(nextState.packageInfo[0].packageId, thisState.packageInfo[0].packageId)) {
                        // this.getBookingList({ bookingStatus: `${BOOKING_STATUS.COMPLETE}` });
                        this.getReviewList(nextState.packageInfo[0].packageId);
                        this.setState({ limit: 5 });
                    }
                }
                break;
            case ((thisState === undefined && Boolean(nextState))):
                {
                    // this.getBookingList({ bookingStatus: `${BOOKING_STATUS.COMPLETE}` });
                    this.getReviewList(nextState.packageInfo[0].packageId);
                    this.setState({ limit: 5 });
                }
                break;
        }
        // this.getBookingList({ bookingStatus: `${BOOKING_STATUS.COMPLETE}` });
    }
    componentDidMount() {
        this.getBookingList({ bookingStatus: `${"complete" /* COMPLETE */}` });
        document.documentElement.style.setProperty('--main-color', Colors.theme);
    }
    getBookingList(payload = { bookingStatus: `${"complete" /* COMPLETE */}` }) {
        if (this.props.authen.token !== null) {
            this.props.dispatch(BookingActions.bookingListRequest(payload, (res) => {
                if (!!res) {
                    this.setState({
                        bookingList: res[bookingListKeys.bookingList],
                    });
                }
            }));
        }
    }
    onChangeLoadMore(val) {
        this.setState({ limit: val });
        this.forceUpdate();
    }
    getReviewList(packageId = '') {
        const { PackageLists, dispatch } = this.props;
        const packageIds = Boolean(packageId) ? packageId : path(['0', 'packageId'], PackageLists);
        dispatch(PackageActions.getReviewRequest({
            where: { packageId: packageIds },
            key: 'createDate', orderBy: 'DESC',
        }));
    }
    onChangeLightBox(i, id, open, e) {
        e.preventDefault();
        this.setState({ currentImage: i, lightboxIsOpen: open, findReview: id });
    }
    render() {
        const { PackageLists, isMobile } = this.props;
        return PackageLists.map((item) => (this.CardWithSlider(item, isMobile)));
    }
    CardWithSlider(item, isMobile) {
        // console.log('item : ', item);
        // const { picture, lightboxIsOpen, findReview } = this.state;
        const { expanded, limit, lightboxIsOpen, findReview, isLogin } = this.state;
        const { packageId, isPromotion, packageInfo, type, isPickUp } = item;
        const { name, schedule, desFull, highlight, service, serviceExclusion, promotionInfo, pickUpPolicy, cancelPolicy, pickUpZoneList, imageGallery, shouldKnow, inclusion_icon, transferType } = packageInfo;
        const _isTransfer = (type === packageType.Transfer);
        const checkReview = this.state.bookingList.filter((each) => each.packageInfo[0].packageId === packageId);
        const CheckBooking = path(['0', 'bookingStatus'], checkReview) === 'complete';
        const reviewList = this.props.package.reviews;
        const isBoatRental = (_isTransfer && (transferType.conveyanceType === ConveyanceType.Ferries) && (transferType.transferType === typeOfTransfer.Private));
        // console.log('login :', isLogin, 'CheckBooking :', CheckBooking);
        // console.log('transferType :', isBoatRental);
        const InclutionPanelInfo = {
            Service: service,
            ShouldKnow: shouldKnow,
            ServiceExclusion: serviceExclusion,
            PickUpPolicy: pickUpPolicy,
            PickUpZoneList: pickUpZoneList,
            CancelPolicy: cancelPolicy,
            isPickUp,
            isMobile,
        };
        const webSearch = path(['location', 'hash'], window);
        if (webSearch === '#review') {
            if (isLogin === false) {
                if (!!Dialog.authen()) {
                    Dialog.authen().openModal();
                }
            }
            else {
                window.setTimeout(() => { scrollToRef(reviewPanel.current, true, 30); }, 2000);
            }
        }
        const mapReview = (findReview !== '') ? reviewList.filter((each) => each.packageReviewId === findReview) : null;
        const imageList = !!Array.isArray(mapReview) ?
            mapReview[0].images.map((each) => ({ src: `${config.host}${each.original}` })) : [];
        return (<FullCardContianer key={packageId}>
                <DayTripSlider ImageList={imageGallery} Host={hostName} productName={name}/>
                {(isPromotion) && this.promotionContainer(promotionInfo)}
                
                <ServiceIconPanel Icon={inclusion_icon}/>
                <div ref={TourProgramPanelRef}>
                    <TourProgramPanel ref={TourProgramPanelRef} ScheduleInfo={schedule} DesFull={desFull} Highlight={highlight} isRental={isBoatRental} expanded={expanded} handleChange={this.handleChange} isTransfer={_isTransfer} isMobile={isMobile}/>
                </div>
                <div ref={InclutionPanelRef}>
                    <InclutionPanel InclutionPanelInfo={InclutionPanelInfo} expanded={expanded} handleChange={this.handleChange} isMobile={isMobile}/>
                </div>
                <ReviewsPanel packageList={item} onChangeLoadMore={this.onChangeLoadMore} limit={limit} onChangeLightBox={this.onChangeLightBox} currentImage={this.state.currentImage}/>
                <div ref={reviewPanel}>
                    {(isLogin && CheckBooking) &&
            <FormReview PackageLists={item} isMobile={isMobile}/>}
                </div>
                <ModalGateway>
                    {lightboxIsOpen ?
            (<Modal onClose={(e) => this.onChangeLightBox(0, '', false, e)} allowFullscreen={false}>
                            <Carousel styles={customStyles} currentIndex={this.state.currentImage} views={imageList} onClose={(e) => this.onChangeLightBox(0, '', false, e)} components={{ Footer: null }} frameProps={{ autoSize: 'height' }}/>
                        </Modal>) : null}
                </ModalGateway>
            </FullCardContianer>);
    }
    promotionContainer(promotion) {
        const kidPercent = promotion['percent-kid'];
        const isKidActive = (kidPercent > 0);
        return (true) ? null :
            (<React.Fragment>
                <AdultPromotionBox>
                    <PromotionLable>{`Adult ${promotion['percent-adult']}%off `}</PromotionLable>
                </AdultPromotionBox>
                {(isKidActive) &&
                <KidPromotionBox>
                        <PromotionLable>{`Kid ${kidPercent}% off `}</PromotionLable>
                    </KidPromotionBox>}
            </React.Fragment>);
    }
}
// ==============================================================================================
const mapStateToProps = (state) => ({
    [reduxKeys.package]: state[reduxKeys.package],
    [reduxKeys.authen]: state[reduxKeys.authen],
    [reduxKeys.booking]: state[reduxKeys.booking],
    [reduxKeys.screen]: state[reduxKeys.screen],
});
export default compose(withRouter, connect(mapStateToProps))(DayTripCardFull);
// interface ICardSize { isMobile?: boolean; isSmall?: boolean; isTranfers?: boolean; }
const { avenirBlack } = Fonts.family;
const customStyles = {
    container: base => ({
        ...base,
        height: '100vh',
    }),
    view: base => ({
        ...base,
        alignItems: 'center',
        display: 'flex ',
        height: 'calc(100vh - 54px)',
        justifyContent: 'center',
        '& > img': {
            maxHeight: 'calc(100vh - 94px)',
        },
    }),
};
const FullCardContianer = styled.div `
margin-bottom: 20px;
position: relative;`;
const AdultPromotionBox = styled.div `
height: 35px;
width: 120px;
background-color: rgba(234, 79, 72, 0.85);
box-shadow: rgba(197, 197, 197, 0.5) 0px 5px 40px 0px;
text-align: center;
position: absolute;
top: 20px;`;
const KidPromotionBox = styled.div `
height: 35px;
width: 120px;
background-color: rgba(45, 45, 45, 0.85);
box-shadow: rgba(197, 197, 197, 0.5) 0px 5px 40px 0px;
text-align: center;
position: absolute;
top: 60px`;
const PromotionLable = styled.label `
color: rgb(255, 255, 255);
font-family: ${avenirBlack};
line-height: 2.1875rem;
text-align: center;`;
// const PackageName = styled.label`
// height: 50px;
// width: 100%;
// color: rgb(53, 53, 53);
// font-family: ${avenir};
// font-size: 1.12rem;
// font-weight: bold;
// line-height: 12px;
// cursor: pointer;
// ${(props: ICardSize) => props.isMobile && css`font-size: 18;`}`;
// =================================================================================================
// const lightboxContainer = { container: { background: 'rgba(0, 0, 0, 0.8)' } };
// =================================================================================================
