import * as React from 'react';
import { connect } from "react-redux";
import { path } from "ramda";
// import { conformToMask } from 'react-text-mask';
import { 
// Styles,
Images, Colors, } from "../../themes";
import { reduxKeys, } from "../../constant";
import Text from "../text";
import localization from '../../services/localization';
// const steps = [
//     `Key *481*111# Press Call on your Mobile Phone`,
//     `Receive Notification SMS`,
//     `Fill up Mobile Phone No. from Step 1 on below box to confirm SMS`,
// ];
// const atmAndCounter = [
//     {
//         title: 'ATM',
//         children: Images.booking.atm,
//     },
//     {
//         title: 'Counter Payment',
//         children: Images.booking.counter,
//     },
// ];
// const CounterAndAtm = (props) => {
//     const accountNumber: any = path([reduxKeys.other, 'banks', 0, 'account_number'], props);
//     // accountNumber = conformToMask(accountNumber, mask.accountNumber);
//     return (
//         <div
//             style={{
//                 ...Styles.unselected,
//                 color: '#717171',
//             }}
//         >
//             <div
//                 style={{
//                     fontWeight: 'bold',
//                 }}
//             >
//                 {`Payment Channel`}
//             </div>
//             <div
//                 style={{
//                     marginTop: '15px',
//                 }}
//             >
//                 {/* <div
//                     style={{
//                         display: 'flex',
//                     }}
//                 >
//                     <div
//                         style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             minWidth: '45px',
//                         }}
//                     >
//                         <img
//                             alt='atm'
//                             src={Images.booking.atm[4]}
//                             style={{
//                                 verticalAlign: 'middle',
//                             }}
//                         />
//                     </div>
//                     <div
//                         style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             flexWrap: 'wrap',
//                         }}
//                     >
//                         <div
//                             style={{
//                                 marginRight: '10px',
//                             }}
//                         >
//                             {path([reduxKeys.other, 'banks', 0, 'name'], props)}
//                         </div>
//                         <div
//                             style={{
//                                 marginRight: '10px',
//                             }}
//                         >
//                             สาขาสยามสแควร์
//                         </div>
//                         <div
//                             style={{
//                                 marginRight: '10px',
//                             }}
//                         >
//                             {`เลขที่บัญชี ${accountNumber}`}
//                         </div>
//                         <div
//                             style={{
//                                 marginRight: '10px',
//                             }}
//                         >
//                             {`ชื่อบัญชี ${path([reduxKeys.other, 'banks', 0, 'account_holder_name'], props)}`}
//                         </div>
//                     </div>
//                 </div> */}
//                 <div>
//                     {`You can make secure and convenient payments via Internet Banking, Mobile Banking or ATM.`}
//                 </div>
//                 <div>
//                     {`Bank: ${path([reduxKeys.other, 'banks', 0, 'name'], props)}`}
//                 </div>
//                 <div>
//                     {`Account Number: ${accountNumber}`}
//                 </div>
//                 <div>
//                     {`Account Holder Name: ${path([reduxKeys.other, 'banks', 0, 'account_holder_name'], props)}`}
//                 </div>
//             </div>
//             {/*
//                     atmAndCounter.map((eachP, iP) => (
//                         <div
//                             style={{
//                                 width: '300px',
//                                 marginTop: '10px',
//                                 marginRight: '75px',
//                                 display: 'inline-block',
//                             }}
//                             key={iP}
//                         >
//                             <div>{eachP.title}</div>
//                             <div
//                                 style={{
//                                     display: 'flex',
//                                     justifyContent: 'space-between',
//                                     marginTop: '10px',
//                                 }}
//                             >
//                                 {
//                                     eachP.children.map((each, i) => (
//                                         <img
//                                             key={i}
//                                             alt='atm'
//                                             src={each}
//                                         />
//                                     ))
//                                 }
//                             </div>
//                         </div>
//                     ))
//                 }
//                 <div
//                     style={{
//                         marginTop: '45px',
//                         marginBottom: '25px',
//                         fontWeight: 'bold',
//                     }}
//                 >
//                     {`Please follow the steps and pay within the specified time limit to complete the payment`}
//                 </div>
//                 {
//                     steps.map((each, i) => (
//                         <Fragment key={i}>
//                             <div
//                                 style={{
//                                     fontWeight: 'bold',
//                                     marginTop: i !== 0 ? '12px' : '',
//                                 }}
//                             >
//                                 {`Step ${i + 1}`}
//                             </div>
//                             <div>
//                                 {each}
//                             </div>
//                         </Fragment>
//                     ))
//                 */}
//             <div
//                 style={{
//                     color: '#9b9b9b',
//                     fontSize: '16px',
//                     marginTop: '35px',
//                 }}
//             >
//                 <div>
//                     {`Conditions`}
//                 </div>
//                 <ul
//                     style={{
//                         marginBottom: 0,
//                     }}
//                 >
//                     {/* <li>{`Maximum 3 transactions/day/phone number.`}</li>
//                         <li>{`Service fee to be collected by mobile operator.`}</li>
//                         <li>{`Support only AIS, DTAC and TRUE mobile operator.`}</li>
//                         <li>{`For payment in Thailand with THB currency only.`}</li>
//                         <li>{`Payment fee will be collected at payment channel.`}</li> */}
//                     {/* <li>{`กรณีโอนผ่านบัญชีธนาคาร ต้องชําระภายใน 2ชั่วโมง
//                     กรณีชําระหลังจากนี้ระบบจะยกเลิก booking อัตโนมัติ`}</li>
//                     <li>{`กรณีชําระเงินเรียบร้อยแล้วแล้วไม่สามารถยกเลิกและคืนเงินเป็นเงินสดได้`}</li> */}
//                     <li>{`Please complete your payment within 2 hours`}</li>
//                     <li>{`No Refund Policies`}</li>
//                 </ul>
//             </div>
//         </div >
//     );
// };
const CounterAndAtm = (props) => {
    const accountNumber = path([reduxKeys.other, 'banks', 0, 'account_number'], props);
    const bankName = path([reduxKeys.other, 'banks', 0, 'name'], props);
    const bankNameTh = path([reduxKeys.other, 'banks', 0, 'name_th'], props);
    const accountName = path([reduxKeys.other, 'banks', 0, 'account_holder_name'], props);
    const accountNameTh = path([reduxKeys.other, 'banks', 0, 'account_holder_name_th'], props);
    const language = path([reduxKeys.settings, 'language'], props);
    const paymentTimes = path([reduxKeys.other, 'paymentTimes', 0, 'time', 'hour'], props);
    return (<React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text.Avenir size={16} family="heavy" color={Colors.grayScale["5b"]}>
                    {`Payment Channel`}
                </Text.Avenir>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <div style={{ marginTop: 6, marginRight: 20 }}>
                        <div style={{
        padding: 2,
        border: `1px solid ${Colors.theme}`,
        borderRadius: '50%', display: 'inline-block',
    }}>
                            <Text.Bullet size={11} color={Colors.theme} style={{ margin: 0 }}/>
                        </div>
                    </div>
                    <img alt="bank logo" src={Images.booking.atm[0]} style={{ marginRight: 12 }}/>
                    <div style={{ color: Colors.grayScale["82"] }}>{language === 'TH' ?
        `${bankNameTh} เลขที่บัญชี ${accountNumber} ชื่อบัญชี ${accountNameTh}` :
        `${bankName}, Account Number: ${accountNumber}, Account Holder Name: ${accountName}`}
                    </div>
                </div>
            </div>
            <div style={{
        color: '#9b9b9b',
        fontSize: '16px',
        marginTop: '35px',
    }}>
                <div>
                    {localization(`conditions`, `BookingLabel`)}
                </div>
                <ul style={{
        marginBottom: 0,
    }}>
                    <li>{localization(`for_payment`, `BookingLabel`)}</li>
                    <li>{localization(`processing_fee`, `BookingLabel`)}</li>
                    <li>{localization(`email_confirm`, `BookingLabel`)}</li>
                    <li>{`${localization(`completed_payment`, `BookingLabel`)} ${paymentTimes}
                     ${localization(`hours`, `BookingLabel`)} ${localization(`to_avoid`, `BookingLabel`)}`}</li>
                </ul>
            </div>
        </React.Fragment>);
};
const mapStateToProps = (state) => ({
    [reduxKeys.other]: state[reduxKeys.other],
    [reduxKeys.settings]: state[reduxKeys.settings],
});
export default connect(mapStateToProps)(CounterAndAtm);
