import { inputType } from "../constant";
import { validateActions } from "../utils/validator";
import localization from "../services/localization";
export const oneDayTripFieldsName = {
    pickupDetail: 'pickupDetail',
    pickupNote: 'pickupNote',
    roomNo: 'roomNo',
    request: 'request',
    specialRequest: 'specialRequest',
};
const oneDayTripForm = ({ appointmentLocationOneDay, customerRequestOptions, isMobile, pickUpListOptions, isPickUp }) => ({
    [oneDayTripFieldsName.pickupDetail]: {
        label: localization(`pick_up_detail`, `BookingLabel`),
        require: true,
        type: isPickUp === false ? null : inputType.SELECT,
        validate: isPickUp === false ? null : (val) => {
            if (val === 0 || val === '') {
                return 'error_require_pickup_detail';
            }
        },
        placeholder: localization(`placeholder_location`, `BookingLabel`),
        value: '',
        choices: Array.isArray(pickUpListOptions) ? pickUpListOptions : appointmentLocationOneDay,
        // choices: appointmentLocationOneDay,
        md: isMobile ? 12 : 6,
        style: {
            default: {
                paddingRight: 10,
            },
            md: {
                paddingRight: 20,
            },
        },
        options: {
            selector: {
                style: {
                    backgroundColor: '#fff',
                },
                collapseStyle: {
                    position: isMobile ? 'relative' : 'absolute',
                },
            },
        },
    },
    [oneDayTripFieldsName.pickupNote]: {
        label: localization(`pick_up_note`, `BookingLabel`),
        require: true,
        placeholder: localization(`placeholder_note`, `BookingLabel`),
        validate: isPickUp === false ? null : validateActions.additional_pickupNote,
        type: isPickUp === false ? null : inputType.TEXT_INPUT,
        value: '',
        md: isMobile ? 12 : 6,
        style: {
            md: {
                paddingLeft: 20,
            },
        },
    },
    [oneDayTripFieldsName.roomNo]: {
        label: localization(`roomNo`, `BookingLabel`),
        placeholder: localization(`roomNo`, `BookingLabel`),
        // validate: validateActions.additional_roomNo,
        type: isPickUp === false ? null : inputType.TEXT_INPUT,
        value: '',
        md: isMobile ? 12 : 6,
        style: {
            md: {
                paddingRight: 10,
            },
        },
    },
    [oneDayTripFieldsName.request]: {
        label: localization(`special_request`, `BookingLabel`),
        type: inputType.SELECT,
        // validate: (val) => {
        //     if (val === 0 || val === '') { return 'error_require_request'; }
        // },
        placeholder: localization(`placeholder_request`, `BookingLabel`),
        value: '',
        choices: customerRequestOptions,
        md: isMobile ? 12 : 6,
        style: {
            default: {
                paddingRight: 10,
            },
            md: {
                paddingLeft: 20,
                paddingRight: 10,
            },
        },
        options: {
            selector: {
                style: {
                    backgroundColor: '#fff',
                },
                collapseStyle: {
                    position: isMobile ? 'relative' : 'absolute',
                },
            },
        },
    },
    [oneDayTripFieldsName.specialRequest]: {
        label: localization(`other`, `BookingLabel`),
        multiline: true,
        minHeight: 90,
        placeholder: localization(`please_explain`, `BookingLabel`),
        type: inputType.TEXT_INPUT,
        value: '',
    },
});
export const transferType = {
    pickUp: 'pickUp',
    dropOff: 'dropOff',
};
export const transferFieldsName = {
    // pick-up
    [transferType.pickUp]: {
        location: `pickUpLocation`,
        roomNo: `roomNo`,
        address: `pickUpAddress`,
        airline: `arrivalAirline`,
        flight: `arrivalFlightNo`,
        time: `arrivalTime`,
    },
    // drop-off
    [transferType.dropOff]: {
        location: `dropOffLocation`,
        address: `dropOffAddress`,
        airline: `departureAirline`,
        flight: `departureFlightNo`,
        time: `departureTime`,
    },
    // more
    language: 'language',
    commentToDriver: 'commentToDriver',
    // other
    specialRequest: 'specialRequest',
};
const transferForm = ({ appointmentLocationOptions, appointmentLocationOneDay, isMobile, isPickUp, isDropOff, pickUpListOptions, dropOffListOptions, transferIn }) => {
    const label = {
        [transferType.pickUp]: {
            location: localization(`pick_up`, `BookingLabel`),
            note: localization(`where_you_want_pick_up`, `BookingLabel`),
            airline: localization(`arrival_airline`, `BookingLabel`),
            flight: localization(`arrival_flight_no`, `BookingLabel`),
            time: localization(`arrival_time`, `BookingLabel`),
        },
        [transferType.dropOff]: {
            location: localization(`drop_off`, `BookingLabel`),
            note: localization(`where_you_want_drop_off`, `BookingLabel`),
            airline: localization(`departure_airline`, `BookingLabel`),
            flight: localization(`departure_flight_no`, `BookingLabel`),
            time: localization(`departure_time`, `BookingLabel`),
        },
    };
    // const selectedLabel = label[selectedTransferType];
    const form = {
        header: {
            text: isPickUp === false ? '' : localization(`pick_up_detail`, `BookingLabel`),
            style: {
                marginBottom: isPickUp === false ? 0 : 30,
                width: isPickUp === false ? 'unset' : '100%',
            },
        },
        [transferFieldsName[transferType.pickUp].location]: {
            label: label[transferType.pickUp].location,
            require: true,
            type: isPickUp === false ? null : inputType.SELECT,
            validate: isPickUp === false ? null : (val) => {
                if (val === 0 || val === '') {
                    return 'error_require_pick_up_location';
                }
            },
            disabled: isPickUp === true ? pickUpListOptions.length > 1 ? null : pickUpListOptions[0].id : null,
            value: pickUpListOptions.length > 1 ? null : pickUpListOptions[0].id,
            placeholder: pickUpListOptions.length > 2 ? localization(`placeholder_location`, `BookingLabel`) : null,
            // value: '',
            // choices: pickUpList,
            choices: isPickUp === false ? appointmentLocationOptions : Array.isArray(pickUpListOptions) ?
                pickUpListOptions : appointmentLocationOptions,
            md: isMobile ? 12 : 6, style: {
                default: { paddingRight: 10 },
                md: { paddingRight: 20 },
            },
            options: {
                selector: {
                    style: { backgroundColor: '#fff' },
                    collapseStyle: {
                        position: isMobile ? 'relative' : 'absolute',
                    },
                },
            },
        },
        [transferFieldsName[transferType.pickUp].address]: {
            label: label[transferType.pickUp].note,
            require: true,
            placeholder: localization(`placeholder_note`, `BookingLabel`),
            type: isPickUp === false ? null : transferIn === true ? null : inputType.TEXT_INPUT,
            validate: isPickUp === false ? null : transferIn === true ? null : validateActions.additional_pickupNote,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.pickUp].roomNo]: {
            label: localization(`roomNo`, `BookingLabel`),
            placeholder: localization(`roomNo`, `BookingLabel`),
            // validate: isPickUp === false ? null : transferIn === true ? null : validateActions.additional_roomNo,
            type: isPickUp === false ? null : transferIn === true ? null : inputType.TEXT_INPUT,
            value: '',
            md: isMobile ? 12 : 6,
            style: {
                md: {
                    paddingRight: 10,
                },
            },
        },
        [transferFieldsName[transferType.pickUp].airline]: {
            label: label[transferType.pickUp].airline,
            require: true,
            type: isPickUp === false ? null : transferIn === false ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_airline`, `BookingLabel`),
            validate: isPickUp === false ? null : transferIn === false ?
                null : validateActions.additional_arrivalAirline,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.pickUp].flight]: {
            label: label[transferType.pickUp].flight,
            require: true,
            type: isPickUp === false ? null : transferIn === false ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_flight_no`, `BookingLabel`),
            validate: isPickUp === false ? null : transferIn === false ?
                null : validateActions.additional_arrivalFlightNo,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.pickUp].time]: {
            label: label[transferType.pickUp].time,
            require: true,
            type: isPickUp === false ? null : transferIn === false ? null : inputType.TIME_PICKER,
            value: '',
            validate: isPickUp === false ? null : transferIn === false ? null : (val) => {
                if (val === '' || val === undefined || val === null || val === 0) {
                    return 'error_require_arrival_time';
                }
            },
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        // header1: {
        //     text: `More Detail`,
        //     style: {
        //         marginBottom: 30,
        //         marginTop: 30,
        //     },
        // },
        header1: {
            text: isDropOff === false ? '' : localization(`drop_off_detail`, `BookingLabel`),
            style: {
                marginBottom: isDropOff === false ? 0 : 30,
                width: isDropOff === false ? 'unset' : '100%',
            },
        },
        [transferFieldsName[transferType.dropOff].location]: {
            label: label[transferType.dropOff].location,
            require: true,
            type: isDropOff === false ? null : inputType.SELECT,
            validate: isDropOff === false ? null : (val) => {
                if (val === 0 || val === '') {
                    return 'error_require_pick_up_location';
                }
            },
            disabled: dropOffListOptions.length > 1 ?
                null : dropOffListOptions[0].id,
            value: dropOffListOptions.length > 1 ? null : dropOffListOptions[0].id,
            placeholder: dropOffListOptions.length > 1 ?
                localization(`placeholder_location`, `BookingLabel`) : null,
            // value: '',
            choices: isDropOff === false ? appointmentLocationOneDay : Array.isArray(dropOffListOptions) ?
                dropOffListOptions : appointmentLocationOneDay,
            md: isMobile ? 12 : 6, style: {
                default: { paddingRight: 10 },
                md: { paddingRight: 20 },
            },
            options: {
                selector: {
                    style: { backgroundColor: '#fff' },
                    collapseStyle: {
                        position: isMobile ? 'relative' : 'absolute',
                    },
                },
            },
        },
        [transferFieldsName[transferType.dropOff].address]: {
            label: label[transferType.dropOff].note,
            require: true,
            placeholder: localization(`placeholder_note`, `BookingLabel`),
            type: isDropOff === false ? null : transferIn === false ? null : inputType.TEXT_INPUT,
            validate: isDropOff === false ? null : transferIn === false ? null : validateActions.additional_dropoffNote,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.dropOff].airline]: {
            label: label[transferType.dropOff].airline,
            require: true,
            type: isDropOff === false ? null : transferIn === true ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_airline`, `BookingLabel`),
            validate: isDropOff === false ? null : transferIn === true ?
                null : validateActions.additional_arrivalAirline,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.dropOff].flight]: {
            label: label[transferType.dropOff].flight,
            require: true,
            type: isDropOff === false ? null : transferIn === true ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_flight_no`, `BookingLabel`),
            validate: isDropOff === false ? null : transferIn === true ?
                null : validateActions.additional_arrivalFlightNo,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.dropOff].time]: {
            label: label[transferType.dropOff].time,
            require: true,
            type: isDropOff === false ? null : transferIn === true ? null : inputType.TIME_PICKER,
            value: '',
            validate: isDropOff === false ? null : transferIn === true ? null : (val) => {
                if (val === '' || val === undefined || val === null || val === 0) {
                    return 'error_require_arrival_time';
                }
            },
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        header2: {
            text: isPickUp === false ? '' : localization(`more_detail`, `BookingLabel`),
            style: {
                marginBottom: isPickUp === false ? 0 : 30,
                width: isPickUp === false ? 'unset' : '100%',
            },
        },
        // [transferFieldsName.language]: {
        //     label: 'Tour/Activity Language',
        //     type: inputType.SELECT,
        //     value: '1', choices: [{ id: '1', label: 'English - Guide' }],
        //     md: 6, style: { md: { paddingRight: 20 } },
        //     options: {
        //         selector: { style: { backgroundColor: '#fff' } },
        //     },
        // },
        // [transferFieldsName.commentToDriver]: {
        //     label: 'Commend for you driver',
        //     type: inputType.TEXT_INPUT,
        //     value: '',
        //     md: 6, style: { md: { paddingLeft: 20 } },
        // },
        [transferFieldsName.specialRequest]: {
            label: localization(`other`, `BookingLabel`),
            multiline: true,
            minHeight: 90,
            placeholder: localization(`please_explain`, `BookingLabel`),
            type: inputType.TEXT_INPUT,
            value: '',
        },
    };
    return form;
};
const ferriesForm = ({ appointmentLocationOptions, appointmentLocationOneDay, isMobile, isPickUp, isDropOff, pickUpListOptions, dropOffListOptions, transferIn }) => {
    const label = {
        [transferType.pickUp]: {
            location: localization(`pick_up`, `BookingLabel`),
            note: localization(`where_you_want_pick_up`, `BookingLabel`),
            airline: localization(`arrival_airline`, `BookingLabel`),
            flight: localization(`arrival_flight_no`, `BookingLabel`),
            time: localization(`arrival_time`, `BookingLabel`),
        },
        [transferType.dropOff]: {
            location: localization(`drop_off`, `BookingLabel`),
            note: localization(`where_you_want_drop_off`, `BookingLabel`),
            airline: localization(`departure_airline`, `BookingLabel`),
            flight: localization(`departure_flight_no`, `BookingLabel`),
            time: localization(`departure_time`, `BookingLabel`),
        },
    };
    // const selectedLabel = label[selectedTransferType];
    const form = {
        header: {
            text: isPickUp === false ? '' : localization(`pick_up_detail`, `BookingLabel`),
            style: {
                marginBottom: isPickUp === false ? 0 : 30,
                width: isPickUp === false ? 'unset' : '100%',
            },
        },
        [transferFieldsName[transferType.pickUp].location]: {
            label: label[transferType.pickUp].location,
            require: true,
            type: isPickUp === false ? null : inputType.SELECT,
            validate: isPickUp === false ? null : (val) => {
                if (val === 0 || val === '') {
                    return 'error_require_pick_up_location';
                }
            },
            // disabled: !!defaultValues[transferFieldsName[selectedTransferType].location],
            // value: defaultValues[transferFieldsName[selectedTransferType].location],
            disabled: pickUpListOptions.length > 1 ?
                null : pickUpListOptions[0].id,
            placeholder: pickUpListOptions.length > 1 ? localization(`placeholder_location`, `BookingLabel`) : null,
            value: pickUpListOptions.length > 1 ? null : pickUpListOptions[0].id,
            choices: isPickUp === false ? appointmentLocationOptions : Array.isArray(pickUpListOptions) ?
                pickUpListOptions : appointmentLocationOptions,
            md: isMobile ? 12 : 6, style: {
                default: { paddingRight: 10 }, md: { paddingRight: 20 },
            },
            options: {
                selector: {
                    style: { backgroundColor: '#fff' },
                    collapseStyle: {
                        position: isMobile ? 'relative' : 'absolute',
                    },
                },
            },
        },
        [transferFieldsName[transferType.pickUp].address]: {
            label: label[transferType.pickUp].note,
            require: true,
            placeholder: localization(`placeholder_note`, `BookingLabel`),
            type: isPickUp === false ? null : inputType.TEXT_INPUT,
            validate: isPickUp === false ? null : validateActions.additional_pickupNote,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.pickUp].roomNo]: {
            label: localization(`roomNo`, `BookingLabel`),
            placeholder: localization(`roomNo`, `BookingLabel`),
            // validate: isPickUp === false ? null : validateActions.additional_roomNo,
            type: isPickUp === false ? null : inputType.TEXT_INPUT,
            value: '',
            md: isMobile ? 12 : 6,
            style: {
                md: {
                    paddingRight: 10,
                },
            },
        },
        [transferFieldsName[transferType.pickUp].airline]: {
            label: label[transferType.pickUp].airline,
            require: true,
            type: isPickUp === false ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_airline`, `BookingLabel`),
            validate: isPickUp === false ? null : validateActions.additional_arrivalAirline,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.pickUp].flight]: {
            label: label[transferType.pickUp].flight,
            require: true,
            type: isPickUp === false ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_flight_no`, `BookingLabel`),
            validate: isPickUp === false ? null : validateActions.additional_arrivalFlightNo,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.pickUp].time]: {
            label: label[transferType.pickUp].time,
            require: true,
            type: isPickUp === false ? null : inputType.TIME_PICKER,
            value: '',
            validate: isPickUp === false ? null : (val) => {
                if (val === '' || val === undefined || val === null || val === 0) {
                    return 'error_require_arrival_time';
                }
            },
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        header1: {
            text: isDropOff === false ? '' : localization(`drop_off_detail`, `BookingLabel`),
            style: {
                marginBottom: isDropOff === false ? 0 : 30,
                width: isDropOff === false ? 'unset' : '100%',
            },
        },
        [transferFieldsName[transferType.dropOff].location]: {
            label: label[transferType.dropOff].location,
            require: true,
            type: isDropOff === false ? null : inputType.SELECT,
            validate: isDropOff === false ? null : (val) => {
                if (val === 0 || val === '') {
                    return 'error_require_pick_up_location';
                }
            },
            disabled: dropOffListOptions.length > 1 ?
                null : dropOffListOptions[0].id,
            value: dropOffListOptions.length > 1 ? null : dropOffListOptions[0].id,
            placeholder: dropOffListOptions.length > 1 ?
                localization(`placeholder_location`, `BookingLabel`) : null,
            // value: '',
            choices: isDropOff === false ? appointmentLocationOneDay : Array.isArray(dropOffListOptions) ?
                dropOffListOptions : appointmentLocationOneDay,
            md: isMobile ? 12 : 6, style: {
                default: { paddingRight: 10 },
                md: { paddingRight: 20 },
            },
            options: {
                selector: {
                    style: { backgroundColor: '#fff' },
                    collapseStyle: {
                        position: isMobile ? 'relative' : 'absolute',
                    },
                },
            },
        },
        [transferFieldsName[transferType.dropOff].address]: {
            label: label[transferType.dropOff].note,
            require: true,
            placeholder: localization(`placeholder_note`, `BookingLabel`),
            type: isDropOff === false ? null : transferIn === false ? null : inputType.TEXT_INPUT,
            validate: isDropOff === false ? null : transferIn === false ? null : validateActions.additional_dropoffNote,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.dropOff].airline]: {
            label: label[transferType.dropOff].airline,
            require: true,
            type: isDropOff === false ? null : transferIn === true ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_airline`, `BookingLabel`),
            validate: isDropOff === false ? null : transferIn === true ?
                null : validateActions.additional_arrivalAirline,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.dropOff].flight]: {
            label: label[transferType.dropOff].flight,
            require: true,
            type: isDropOff === false ? null : transferIn === true ? null : inputType.TEXT_INPUT,
            placeholder: localization(`arrival_flight_no`, `BookingLabel`),
            validate: isDropOff === false ? null : transferIn === true ?
                null : validateActions.additional_arrivalFlightNo,
            value: '',
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        [transferFieldsName[transferType.dropOff].time]: {
            label: label[transferType.dropOff].time,
            require: true,
            type: isDropOff === false ? null : transferIn === true ? null : inputType.TIME_PICKER,
            value: '',
            validate: isDropOff === false ? null : transferIn === true ? null : (val) => {
                if (val === '' || val === undefined || val === null || val === 0) {
                    return 'error_require_arrival_time';
                }
            },
            md: isMobile ? 12 : 6, style: { md: { paddingRight: 20 } },
        },
        // header2: {
        //     text: isPickUp === false ? '' : localization('more_detail'),
        //     style: {
        //         marginBottom: isPickUp === false ? 0 : 30,
        //         width: isPickUp === false ? 'unset' : '100%',
        //     },
        // },
        // [transferFieldsName.language]: {
        //     label: 'Tour/Activity Language',
        //     type: inputType.SELECT,
        //     value: '1', choices: [{ id: '1', label: 'English - Guide' }],
        //     md: 6, style: { md: { paddingRight: 20 } },
        //     options: {
        //         selector: { style: { backgroundColor: '#fff' } },
        //     },
        // },
        // [transferFieldsName.commentToDriver]: {
        //     label: 'Commend for you driver',
        //     type: inputType.TEXT_INPUT,
        //     value: '',
        //     md: 6, style: { md: { paddingLeft: 20 } },
        // },
        [transferFieldsName.specialRequest]: {
            label: localization(`other`, `BookingLabel`),
            multiline: true,
            minHeight: 90,
            placeholder: localization(`please_explain`, `BookingLabel`),
            type: inputType.TEXT_INPUT,
            value: '',
        },
    };
    return form;
};
export default {
    oneDayTripForm,
    transferForm,
    ferriesForm,
};
