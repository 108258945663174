export default {
    about_us: 'ABOUT US',
    how_to_book: 'HOW TO BOOK',
    business_partners: 'BUSINESS PARTNERS',
    wish_list: 'WISH LIST',
    profile: 'PROFILE',
    log_in: 'LOG IN / REGISTER',
    log_out: 'LOG OUT',
    searchPanelTab1: 'DAY TRIPS',
    searchPanelTab2: 'CAR RENTAL',
    searchPanelTab3: 'BOAT CHARTERS',
    destination: 'DESTINATION',
    activities: 'ACTIVITIES',
    pick_up_location: 'PICK-UP LOCATION',
    drop_off_location: 'DROP-OFF LOCATION',
    pick_up_origin: 'ORIGIN',
    drop_off_destination: 'DESTINATION',
    date: 'DATE',
    passenger: 'PASSENGER',
    search: 'SEARCH',
    exclusive_for: 'Exclusive for',
    platforms: ` PHI PHI TOURS `,
    member: 'members',
    extendsDataHeader1: 'The Best Selection',
    extendsDataDes1: 'Whatever you want to do',
    extendsDataHeader2: 'One Stop Travel Service',
    extendsDataDes2: 'Day trips, Tranfer Service & Boat Charters',
    extendsDataHeader3: 'Safe & Easy Booking',
    extendsDataDes3: 'Book online to lock in your tickets',
    our_partner: 'Our Partners',
    price_guarantee: 'Price Guarantee',
    your_experience: 'Your Experience\nis our Experience !',
    the_best: 'The best travel experiences\nin Thailand',
    from: 'from',
    baht: 'baht',
    book_now: 'BOOK NOW',
    book_from: 'Book from 18 - 27 DEC 2018',
    travel_from: 'Travel from 19 DEC - 1 MAY 2019',
    terms_apply: 'Terms and conditions apply.',
    book_easy: 'Book easy & Safe More',
    day_trips_airport: 'Day Trips & Airport Transfer',
    airport_transfer: 'Airport Transfer',
    a_car_for_every: 'A car for every budget and occasion',
    from_low: 'From low cost to luxury, we have something for any of your travel needs',
    select: 'Select...',
    no_option: 'No results found',
    please_login: 'Please log in before add wish list',
    travel_date_calendar: 'Travel Date',
    site_maintenance: 'This site under maintenance',
    // footer
    contact: 'Customer Service',
    privacy_policy: 'Privacy Policy',
    term_conditions: 'Terms & Conditions',
    complaint_policy: 'Complaint Policy',
    cookie_policy: 'Cookie Policy',
    aboutus: 'About Us',
    follow_us: 'Follow us on',
    license: 'License',
    way_you_can_pay: 'Way You Can Pay',
    support: 'Support',
    company: 'Company',
    address: '80 Pisanpop Road,Thumbol Parknam Ampher Muang Krabi 81000',
    businessHours: 'Everyday 08.00 - 17.00',
    emailContact: 'phiphitours.online@gmail.com',
    line: '@phiphitours',
    whatsApp: '+6684-889-7776',
    website: 'www.phiphitours.com',
};
