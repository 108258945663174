import { inputType } from "../constant";
import localization from "../services/localization";
export const fieldsName = {
    refundReasons: 'refundReasons',
    other: 'other',
    refundDetail: 'refundDetail',
    refundAmount: 'refundAmount',
};
export default ({ refundOption, onChangeRefund, checkRefund, isMobiles }) => ({
    [fieldsName.refundReasons]: {
        label: localization(`refund_reasons`, `ProfileLabel`),
        placeholder: '',
        type: inputType.SELECT,
        choices: refundOption,
        validate: (val) => {
            if (val === "" || val === null) {
                return 'error_require_refund_reasons';
            }
        },
        // value: !!defaultValues[regiterFieldsName.countryCode] ? defaultValues[regiterFieldsName.countryCode] : code,
        md: 12,
        style: {
            md: {
                paddingRight: '10px',
            },
        },
        options: {
            selector: {
                style: {
                    backgroundColor: '#fff',
                    // height: isSafari() ? 44 : 41,
                    borderRadius: '2px',
                },
                collapseStyle: {
                    position: isMobiles ? 'relative' : 'absolute',
                    width: isMobiles ? '100%' : '490px',
                },
                labelWrapperStyle: {
                    minWidth: '55px',
                },
            },
        },
        onChange: onChangeRefund,
    },
    [fieldsName.other]: {
        label: localization(`other`, `BookingLabel`),
        multiline: true,
        validate: (val) => {
            if (checkRefund === "RFPI-20181129-000009") {
                if (val === null || val === "" || val === undefined) {
                    return 'error_require_refund_other';
                }
            }
        },
        minHeight: 60,
        placeholder: localization(`other`, `BookingLabel`),
        type: inputType.TEXT_INPUT,
        value: '',
    },
});
