import { path, omit } from 'ramda';
import { put, call } from 'redux-saga/effects';
import UserActions, { USER_KEY } from '../redux/UserRedux';
import AuthenActions from "../redux/AuthenRedux";
// import PackageActions from "../redux/PackageRedux";
import { clearStore } from "./ManageStoreSaga";
import Store from "../redux";
import { socialKeys, pathName } from "../constant";
import errorGenerator from "../services/errorGenerator";
import localization from "../services/localization";
import Dialog from "../components/dialog";
// import store from '../redux';
function* registerRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: USER_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const body = omit(['confirm_password'], payload);
        const res = yield call(api.register, body);
        if (path(['status'], res) === 200) {
            // yield call(() => afterLoginSuccess({ response: res }));
            const isSocial = !!body[socialKeys.FACEBOOK] || !!body[socialKeys.GOOGLE];
            if (isSocial) {
                // info dialog when register by social
                Dialog.loading().remove(USER_KEY);
                Dialog.info({
                    key: USER_KEY,
                    title: `${localization(`info_title_register_success`, `DialogLabel`)}`,
                });
                Store.dispatch(UserActions.registerSuccess());
                yield put(AuthenActions.loginRequest(payload, nextAction));
            }
            else {
                Dialog.loading().remove(USER_KEY);
                // info dialog when register by email
                Dialog.info({
                    key: USER_KEY,
                    title: `${localization(`info_title_register_success`, `DialogLabel`)}`,
                    content: `${localization(`info_content_register_success`, `DialogLabel`)}`,
                });
                yield put(UserActions.registerSuccess());
            }
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(USER_KEY);
            Dialog.error({
                key: USER_KEY,
                ...errorGenerator(res),
            });
            yield put(UserActions.registerFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(USER_KEY);
        yield put(UserActions.registerFailure());
    }
}
function* forgotPasswordRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: USER_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const res = yield call(api.forgotPassword, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(USER_KEY);
            Dialog.info({
                key: USER_KEY,
                title: `${localization(`info_title_forgot_password_success`, `DialogLabel`)}`,
                content: `${localization(`info_content_forgot_password_success`, `DialogLabel`)}`,
            });
            yield put(UserActions.forgotPasswordSuccess());
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(USER_KEY);
            Dialog.error({
                key: USER_KEY,
                ...errorGenerator(res),
            });
            yield put(UserActions.forgotPasswordFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(USER_KEY);
        yield put(UserActions.forgotPasswordFailure());
    }
}
function* getUserRequest(api) {
    try {
        Dialog.loading({
            key: USER_KEY,
            text: `${localization(`loading_user`, `DialogLabel`)}`,
        });
        const res = yield call(api.getUser);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(USER_KEY);
            yield put(UserActions.getUserSuccess({
                data: path(['data', 'result', 'user'], res),
            }));
            // store.dispatch(PackageActions.getWishListRequest({
            //     userId: path(['data', 'result', 'user', '_id'], res),
            // }));
        }
        else {
            Dialog.loading().remove(USER_KEY);
            Dialog.error({
                key: USER_KEY,
                ...errorGenerator(res),
            });
            yield put(UserActions.getUserFailure());
            clearStore();
        }
    }
    catch (e) {
        Dialog.loading().remove(USER_KEY);
        yield put(UserActions.getUserFailure());
    }
}
function* updateUserRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: USER_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const res = yield call(api.updateUser, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(USER_KEY);
            yield put(UserActions.updateUserSuccess({
                data: path(['data', 'result', 'user'], res),
            }));
            if (typeof nextAction === 'function') {
                nextAction(res);
            }
            if (window.location.pathname !== pathName.PAYMENT_DETAIL) {
                Dialog.info({
                    key: USER_KEY,
                    title: `${localization(`info_title_update_profile_success`, `DialogLabel`)}`,
                });
            }
        }
        else {
            Dialog.loading().remove(USER_KEY);
            Dialog.error({
                key: USER_KEY,
                ...errorGenerator(res),
            });
            yield put(UserActions.updateUserFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(USER_KEY);
        yield put(UserActions.updateUserFailure());
    }
}
function* changePasswordRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: USER_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const res = yield call(api.changePassword, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(USER_KEY);
            Dialog.info({
                key: USER_KEY,
                title: `${localization(`info_title_change_password_success`, `DialogLabel`)}`,
            });
            yield put(UserActions.changePasswordSuccess());
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(USER_KEY);
            Dialog.error({
                key: USER_KEY,
                ...errorGenerator(res),
            });
            yield put(UserActions.changePasswordFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(USER_KEY);
        yield put(UserActions.changePasswordFailure());
    }
}
function* createAffiliateIdRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: USER_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const res = yield call(api.createAffiliateId, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(USER_KEY);
            Dialog.info({
                key: USER_KEY,
                title: `${localization(`info_title_request_affiliate_id_success`, `DialogLabel`)}`,
            });
            yield put(UserActions.createAffiliateIdSuccess({
                data: path(['data', 'result', 'user'], res),
            }));
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(USER_KEY);
            Dialog.error({
                key: USER_KEY,
                ...errorGenerator(res),
            });
            yield put(UserActions.createAffiliateIdFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(USER_KEY);
        yield put(UserActions.createAffiliateIdFailure());
    }
}
function* getAffiliateRequest(api, { payload, nextAction, }) {
    try {
        Dialog.loading({
            key: USER_KEY,
            text: `${localization(`loading_please_wait`, `DialogLabel`)}`,
        });
        const res = yield call(api.getAffiliate, payload);
        if (path(['status'], res) === 200) {
            Dialog.loading().remove(USER_KEY);
            // Dialog.info({
            //     key: USER_KEY,
            //     title: localization('info_title_request_affiliate_id_success'),
            // });
            yield put(UserActions.getAffiliateSuccess(res.data));
            if (typeof nextAction === 'function') {
                nextAction();
            }
        }
        else {
            Dialog.loading().remove(USER_KEY);
            Dialog.error({
                key: USER_KEY,
                ...errorGenerator(res),
            });
            yield put(UserActions.getAffiliateFailure());
        }
    }
    catch (e) {
        Dialog.loading().remove(USER_KEY);
        yield put(UserActions.getAffiliateFailure());
    }
}
export { registerRequest, forgotPasswordRequest, getUserRequest, updateUserRequest, changePasswordRequest, createAffiliateIdRequest, getAffiliateRequest, };
