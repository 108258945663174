import React from 'react';
import styled from "styled-components";
import { pathName } from "../../constant";
import { Colors } from "../../themes";
import localization from "../../services/localization";
const Link = styled.a.attrs({ target: '_blank' }) `
    text-decoration: none;
    color: ${Colors.link};
`;
const Term = () => {
    return (<Link href={pathName.TERMS}>
            {localization(`platforms_terms`, `BookingLabel`)}
        </Link>);
};
const Privacy = () => {
    return (<Link href={pathName.PRIVACY_POLICY}>
            {localization(`privacy_statement`, `BookingLabel`)}
        </Link>);
};
const TermAndPrivacy = () => {
    return (<span>
            {localization(`detail`, `BookingLabel`)}
            <Term />
            {localization(`and`, `BookingLabel`)}
            <Privacy />
        </span>);
};
export default {
    Term,
    Privacy,
    TermAndPrivacy,
};
