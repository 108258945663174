import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { reduxKeys, currency } from "../../constant";
import localization from "../../services/localization";
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
import { NumberFormat } from '../../components/common/SetNumberFormat';
import { Fonts, Svg, Colors } from "../../themes";
import FontIcon from '../../components/FontIcon';
import '../../containers/PackageInfoPanel/Card.css';
import styled, { css } from 'styled-components';
import { isSafari } from '../../containers/PackageInfoPanel/GetItemColsPerWidth';
let pageLabel;
// import { Images, Fonts, Svg } from "../../themes";
// const { tips, gift, hotelPickup, cancelPolicy } = Images.package;
class InclutionPanel extends Component {
    constructor() {
        super(...arguments);
        this.state = { InclusionActive: true };
        this.callapsIcon = () => (<ExpandMoreIcon style={_style.expandMoreIcon}/>);
    }
    componentDidMount() {
        document.documentElement.style.setProperty('--main-color', Colors.theme);
    }
    render() {
        pageLabel = localization(wordingKeys.packageLabel);
        const { InclutionPanelInfo, expanded, handleChange, classes } = this.props;
        const { Service, ShouldKnow, ServiceExclusion = [], PickUpPolicy = [], PickUpZoneList = [], CancelPolicy = [], isPickUp, isMobile } = InclutionPanelInfo;
        return (<React.Fragment>
                <PanelContianer>
                    <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <ExpansionPanelSummary classes={{ expanded: classes.expanded }} expandIcon={this.callapsIcon()} style={_style.header_callaps}>
                            <TopicsIconContianer>
                                <Svg.Landing.Gift color={Colors.icon}/>
                            </TopicsIconContianer>
                            <CallpseTopicLabel onChange={handleChange('panel2')}>
                                {pageLabel[wordingKeys.InclusionTopics]}
                            </CallpseTopicLabel>
                            <CallpseIconContianer isMobile={isMobile}/>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.IncludePanel(Service, ServiceExclusion)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </PanelContianer>
                <PanelContianer>
                    <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <ExpansionPanelSummary classes={{ expanded: classes.expanded }} expandIcon={this.callapsIcon()} style={_style.header_callaps}>
                            <TopicsIconContianer>
                                <Svg.Landing.Tips color={Colors.icon}/>
                            </TopicsIconContianer>
                            <CallpseTopicLabel onChange={handleChange('panel3')}>
                                {pageLabel[wordingKeys.ShouldKnowTopics]}
                            </CallpseTopicLabel>
                            <CallpseIconContianer isMobile={isMobile}/>
                        </ExpansionPanelSummary>
                        {(!!ShouldKnow) &&
            <ExpansionPanelDetails style={_style.ExpansionPanelDetails}>
                                {this.ShouldKnowPanel(ShouldKnow)}
                            </ExpansionPanelDetails>}
                    </ExpansionPanel>
                </PanelContianer>
                {(isPickUp && PickUpPolicy.length > 0) &&
            <PanelContianer>
                        <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <ExpansionPanelSummary classes={{ expanded: classes.expanded }} expandIcon={this.callapsIcon()} style={_style.header_callaps}>
                                <TopicsIconContianer>
                                    <Svg.Landing.Car color={Colors.icon}/>
                                </TopicsIconContianer>
                                <CallpseTopicLabel onChange={handleChange('panel4')}>
                                    {pageLabel[wordingKeys.HotelPickUpInformation]}
                                </CallpseTopicLabel>
                                <CallpseIconContianer isMobile={isMobile}/>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={_style.ExpansionPanelDetails}>
                                {this.ShouldKnowPanel(PickUpPolicy)}
                            </ExpansionPanelDetails>
                            {(PickUpZoneList.length > 0) &&
                <ExpansionPanelDetails style={_style.ExpansionPanelDetails}>
                                    {this.PickUpPolicyPanel(PickUpZoneList, isMobile)}
                                </ExpansionPanelDetails>}
                        </ExpansionPanel>
                    </PanelContianer>}
                {(CancelPolicy.length > 0) &&
            <PanelContianer>
                        <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <ExpansionPanelSummary classes={{ expanded: classes.expanded }} expandIcon={this.callapsIcon()} style={_style.header_callaps}>
                                <TopicsIconContianer>
                                    <Svg.Landing.Group color={Colors.icon}/>
                                </TopicsIconContianer>
                                <CallpseTopicLabel onChange={handleChange('panel5')}>
                                    {pageLabel[wordingKeys.CancelationPolicy]}
                                </CallpseTopicLabel>
                                <CallpseIconContianer isMobile={isMobile}/>
                            </ExpansionPanelSummary>
                            {(!!CancelPolicy) &&
                <ExpansionPanelDetails style={_style.ExpansionPanelDetails}>
                                    {this.ShouldKnowPanel(CancelPolicy)}
                                </ExpansionPanelDetails>}
                        </ExpansionPanel>
                    </PanelContianer>}
            </React.Fragment>);
    }
    PickUpPolicyPanel(PickUpZoneList, isMobile) {
        const { other, settings } = this.props;
        const { appointments: AppointmentList = [] } = other;
        const { language } = settings;
        return (<PickUpZoneContianer>
                <HeaderContianer>
                    <ContianerCols><HeaderLable isMobile={isMobile}>
                        {pageLabel[wordingKeys.Area]}
                    </HeaderLable></ContianerCols>
                    <ContianerCols><HeaderLable isMobile={isMobile}>
                        {pageLabel[wordingKeys.PickUpTime]}
                    </HeaderLable></ContianerCols>
                    <ContianerCols><HeaderLable isMobile={isMobile}>
                        {pageLabel[wordingKeys.ExtraCharge]}
                    </HeaderLable></ContianerCols>
                </HeaderContianer>
                {PickUpZoneList.map((item, index) => {
            const { appointmentId, pickUpTime, extraCharge } = item;
            const { startDate, endDate } = pickUpTime;
            const area = AppointmentList.find((items) => items.appointmentId === appointmentId);
            return (<RowsContianer key={index}>
                            <ContianerCols><LocationLable>
                                {`${area[language].name}`}</LocationLable></ContianerCols>
                            <ContianerCols><InfoLable>
                                {`${startDate || 0} - ${endDate || 0}`}</InfoLable></ContianerCols>
                            <ContianerCols><InfoLable>
                                {`${NumberFormat(extraCharge || 0)} ${currency[0].value}`}</InfoLable></ContianerCols>
                        </RowsContianer>);
        })}
            </PickUpZoneContianer>);
    }
    ShouldKnowPanel(ShouldKnow) {
        return (<ShouldKnowContianer>
                <ServiceBullet>
                    {ShouldKnow.map((know, index) => {
            // console.log('ShouldKnow : ', know.split('\n'))
            return (<ServiceLi key={`li_${index}`}>
                                {know.split('\n').map((subKnow, i) => <React.Fragment key={`detail_${index}_${i}`}>
                                        <ServiceLabel>{` ${subKnow}`}</ServiceLabel>
                                        <br />
                                    </React.Fragment>)}
                            </ServiceLi>);
        })}
                </ServiceBullet>
            </ShouldKnowContianer>);
    }
    IncludePanel(serviceList = [], serviceExclusion = []) {
        // console.log('ServicePanel : 'serviceList[0].topic)
        // console.log(serviceList);
        return (<InclutionContianer>
                {serviceList.map((service, index) => (<ServiceItems key={`service_${index}`}>
                        <FontIcon icon={'check'} style={_style.FontIcon}/>
                        <ServiceLabel>{service.topic}</ServiceLabel>
                    </ServiceItems>))}
                {serviceExclusion.map((service, index) => (<ServiceItems key={`exclusion_${index}`}>
                            <FontIcon icon={'times'} style={_style.FontIconCross}/>
                            <ServiceLabel>{service.topic}</ServiceLabel>
                        </ServiceItems>))}
            </InclutionContianer>);
    }
}
// export default withRouter(InclutionPanel);
const mapStateToProps = (state) => ({
    [reduxKeys.other]: state[reduxKeys.other],
    [reduxKeys.settings]: state[reduxKeys.settings],
});
const styles = () => ({ expanded: { '&:first-child': { minHeight: 20 } } });
export default compose(withRouter, connect(mapStateToProps), withStyles(styles))(InclutionPanel);
const { avenir, avenirBlack } = Fonts.family;
const PanelContianer = styled.div `
margin-top: 10px;`;
const CallpseTopicLabel = styled.label `
width: 100%;
color: ${Colors.textLabel};
font-family: ${avenir};
font-size: 17px;
font-weight: 900;
letter-spacing: ${isSafari() ? -1 : 0}px;
cursor:pointer;
line-height: 23px;`;
const TopicsIconContianer = styled.div `
margin: 2px 10px 0px 0px;`;
const InclutionContianer = styled.div `
width: 100%;
height: 100%;
margin-bottom: 0px;`;
const ShouldKnowContianer = styled.div `
display: grid;`;
const ServiceItems = styled.div `
width: 100%;
text-align: left;
margin-top: 10px;`;
const ServiceLabel = styled.label `
height: 33px;
width: 100%;
color: #5B5B5B;
font-family: ${avenir};
font-size: 15px;
font-weight: 500;
letter-spacing: -0.65px;
line-height: 16px;`;
const ServiceLi = styled.li `
padding-bottom: 10px;`;
const ServiceBullet = styled.ul `
list-style-type:disc;`;
const PickUpZoneContianer = styled.div `
width: 100%;
height: auto;
margin-left: 25px;
margin-bottom: 15px;`;
const HeaderContianer = styled.div `
background-color: rgb(53, 53, 53);
display: flex;`;
const RowsContianer = styled.div `
display: flex;
border-bottom: 1px solid rgb(223,223,223);`;
const ContianerCols = styled.div `
flex: 1 1 0%;
min-height: 40px;
text-align: center;`;
const HeaderLable = styled.div `
height: 33px;
width: 100%;
color: white;
font-family: ${avenirBlack};
font-size: 16px;
font-weight: 900;
padding: 10px 5px 10px 5px;
${(props) => props.isMobile && css `font-size: 13px;`}`;
const InfoLable = styled.div `
width: 100%;
color: #5B5B5B;
font-family: ${avenir};
padding: 10px 5px 10px 5px;
font-size: 14px;`;
const LocationLable = styled.div `
width: 100%;
color: #5B5B5B;
text-align: left;
font-family: ${avenir};
padding: 10px 5px 10px 5px;
font-size: 14px;`;
const CallpseIconContianer = styled.div `
                background: ${Colors.panel.header};
                width: 65px;
                height: 49px;
                position: absolute;
                right: 0;
                margin-top: -12px;
                box-shadow:0px 1px 3px 0px rgba(0, 0, 0, 0.2);`;
// =================================================================================================
const _style = {
    packege_container: {
        width: '100%',
        textAlign: 'left',
        marginBottom: '10px',
    },
    label_condition: {
        color: "#4A4A4A",
        fontFamily: "Avenir",
        fontSize: "1.0625em",
        fontWeight: "900",
        lineHeight: "1.4375em",
    },
    callpse_container: {
        height: '100%',
        width: '100%',
        padding: '1px 1px 10px 10px',
        marginRight: '5px',
    },
    subTap_container: {
        height: '100%',
        width: '100%',
        padding: '10px',
        marginTop: '2px',
        borderBottom: '1px solid #DFDFDF ',
        marginBottom: '10px',
        cursor: 'pointer',
    },
    wording_callaps: {
        width: "100%",
        color: "#FFFFFF",
        fontFamily: Fonts.family.avenir,
        fontSize: "17px",
        fontWeight: "900",
        lineHeight: "23px",
    },
    icon_callaps: {
        margin: '3px 10px 0px 0px',
    },
    // header_callaps: { backgroundColor: '#353535', cursor: 'pointer',
    //  height: 48, '&:first-child': { minHeight: 20, padding: '20px 10px 20px 12px' } },
    header_callaps: {
        backgroundColor: 'white', cursor: 'pointer',
        height: 48, borderBottom: 'solid 0.3px #353535bd', boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)',
    },
    expandMoreIcon: { color: 'white' },
    ExpansionPanelDetails: { display: 'flex', padding: '0px 24px 0px 5px' },
    FontIcon: { fontSize: 12, color: '#08a008', marginRight: 10 },
    FontIconCross: { fontSize: 16, color: 'rgb(247, 24, 8)', marginRight: 10 },
};
// =========================================================================================================
