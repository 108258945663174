import React, { Component, Fragment } from 'react';
import { DatePicker as MDatePicker } from 'material-ui-pickers';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment-recur';
import 'moment/locale/th';
import { withStyles } from '@material-ui/styles';
import { equals, path } from "ramda";
import Button from "../../components/button";
import FieldLabel from "../../components/form/FieldLabel";
import FieldError from "../../components/form/FieldError";
import { format } from "../../constant";
// import { Colors, Metrics } from "../../themes";
// import CustomCalendar from '../../components/form/CustomCalendar';
import { getDay, InPeriodTime, ExPeriodTime, SetDateFormat, generateDateRange } from "../../components/common/SetDate";
import { Images } from '../../themes';
import { isMobile } from '../PackageInfoPanel/GetItemColsPerWidth';
import { GetSymbol } from '../../components/common/GetSymbol';
import { NumberFormatNotFixed } from '../../components/common/SetNumberFormat';
import Fallback from '../../components/Fallback';
const CustomCalendar = React.lazy(() => import('../../components/form/CustomCalendar'));
const localeMap = { EN: 'en', TH: 'th' };
class CustomDatePicker extends Component {
    constructor(props) {
        super(props);
        this.locale = null;
        this.arrayPeriod = [];
        this.arrayExPeriod = [];
        // this.state = { selectedDate: props.value || new Date(), };
        this.state = { selectedDate: props.value || null };
        // console.log('CustomDatePicker : ', this.state);
        this.dateTemplate = this.dateTemplate.bind(this);
    }
    componentWillMount() {
        const { checkPeriodTime } = this.props;
        // console.log('checkPeriodTime', checkPeriodTime);
        if (checkPeriodTime !== undefined) {
            const toDay = new Date();
            const mapPeriod = checkPeriodTime.findIndex((each) => InPeriodTime(each.startDate, each.endDate, toDay));
            const findPeriod = checkPeriodTime.filter((each, index) => index >= mapPeriod);
            this.findStartPeriod = findPeriod;
            const periodLength = findPeriod.length;
            let allDates = [];
            let recurrence = [];
            let exDates;
            let serviceDate;
            let i = 0;
            while (i < periodLength) {
                // for (let i = 0, len = findPeriod.length; i < len; i++) {
                if (findPeriod[i].serviceDate.length < 7) {
                    recurrence = moment(findPeriod[i].startDate).recur(findPeriod[i].endDate);
                    allDates = recurrence.all("YYYY-MM-DD");
                    serviceDate = getDay.filter((each) => !(findPeriod[i].serviceDate.some((val) => val === each.label)));
                    this.periodTime = allDates.filter((each) => serviceDate.some((val) => new Date(each).getDay() === val.id));
                    this.arrayPeriod.push(this.periodTime);
                }
                if (findPeriod.length >= 2) {
                    const externalPeriod = findPeriod.filter((each) => (Date.parse(toDay) <= Date.parse(each.startDate)
                        && Date.parse(each.startDate) >= Date.parse(toDay)));
                    // console.log('externalPeriod', externalPeriod);
                    let externalStartPeriod = findPeriod.filter((each) => (Date.parse(externalPeriod[0].startDate) > Date.parse(each.endDate)));
                    if (externalStartPeriod.length === 0) {
                        externalStartPeriod = externalPeriod;
                    }
                    // console.log('externalStartPeriod', externalStartPeriod);
                    const exDate = moment(externalPeriod[0].startDate).diff(moment(externalStartPeriod[0].endDate), 'days');
                    // console.log('external', exDate);
                    if (externalPeriod.length >= 2) {
                        exDates = moment(externalPeriod[1].startDate).diff(moment(externalPeriod[0].endDate), 'days');
                    }
                    if (Array.isArray(externalPeriod)) {
                        if (exDate > 1) {
                            if (externalPeriod.length >= 2) {
                                const startDateRange = SetDateFormat(externalStartPeriod[0].endDate);
                                const endDateRange = SetDateFormat(externalPeriod[0].startDate);
                                const startDateRange1 = SetDateFormat(externalPeriod[0].endDate);
                                const endDateRange1 = SetDateFormat(externalPeriod[1].startDate);
                                const disDay = generateDateRange(startDateRange, endDateRange, 1);
                                const disDays = generateDateRange(startDateRange1, endDateRange1, 1);
                                this.exPeriodTime = disDay.filter((each) => (Date.parse(SetDateFormat(new Date(each))) !== Date.parse(endDateRange) &&
                                    Date.parse(SetDateFormat(new Date(each))) !== Date.parse(startDateRange)));
                                this.exPeriodTimes = disDays.filter((each) => (Date.parse(SetDateFormat(new Date(each))) !== Date.parse(endDateRange1) &&
                                    Date.parse(SetDateFormat(new Date(each))) !== Date.parse(startDateRange1)));
                                this.arrayExPeriod.push(this.exPeriodTime);
                                this.arrayExPeriod.push(this.exPeriodTimes);
                            }
                            else {
                                const startDateRange = SetDateFormat(externalStartPeriod[0].endDate);
                                const endDateRange = SetDateFormat(externalPeriod[0].startDate);
                                const disDay = generateDateRange(startDateRange, endDateRange, 1);
                                this.exPeriodTime = disDay.filter((each) => (Date.parse(SetDateFormat(new Date(each))) !== Date.parse(endDateRange) &&
                                    Date.parse(SetDateFormat(new Date(each))) !== Date.parse(startDateRange)));
                            }
                        }
                    }
                }
                i++;
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.selectedDate)) {
            this.setState({ selectedDate: nextProps.value || null });
            // this.setState({ selectedDate: nextProps.value || new Date(), });
        }
    }
    checkPrice(findPeriod) {
        if (findPeriod.length > 1) {
            for (let i = 0, len = findPeriod.length; i < len; i++) {
                const price = findPeriod.map((each) => each.price.adult);
                return price;
            }
        }
        else {
            const price = findPeriod.map((each) => each.price.adult);
            return price;
        }
    }
    checkPricePromotion(findPeriod) {
        const price = findPeriod.map((each) => each.promotionInfo.adult);
        return price;
    }
    checkStartPeriod(findPeriod) {
        if (findPeriod.length > 1) {
            for (let i = 0, len = findPeriod.length; i < len; i++) {
                const startDate = findPeriod.map((each) => each.startDate);
                return startDate;
            }
        }
        else {
            const startDate = findPeriod.map((each) => each.startDate);
            return startDate;
        }
    }
    checkEndPeriod(findPeriod) {
        if (findPeriod.length > 1) {
            for (let i = 0, len = findPeriod.length; i < len; i++) {
                const endDate = findPeriod.map((each) => each.endDate);
                return endDate;
            }
        }
        else {
            const endDate = findPeriod.map((each) => each.endDate);
            return endDate;
        }
    }
    mapArrayPeriod() {
        if (Array.isArray(this.periodTime)) {
            if (this.arrayPeriod.length > 2) {
                const period = this.arrayPeriod[0].concat(this.arrayPeriod[1]);
                return period.concat(this.arrayPeriod[2]);
            }
            else if (this.arrayPeriod.length === 2) {
                return this.arrayPeriod[0].concat(this.arrayPeriod[1]);
            }
            else {
                return this.periodTime;
            }
        }
    }
    mapArrayExPeriod() {
        if (Array.isArray(this.exPeriodTime)) {
            if (this.arrayExPeriod.length > 2) {
                const period = this.arrayExPeriod[0].concat(this.arrayExPeriod[1]);
                return period.concat(this.arrayExPeriod[2]);
            }
            else if (this.arrayExPeriod.length === 2) {
                return this.arrayExPeriod[0].concat(this.arrayExPeriod[1]);
            }
            else {
                return this.exPeriodTime;
            }
        }
    }
    dateTemplate(date) {
        const { checkPeriodTime, isPromotion, currency } = this.props;
        const toDay = new Date();
        const getToday = toDay.getDate();
        const month = toDay.getMonth();
        // const year = toDay.getFullYear();
        const mapPeriod = checkPeriodTime.findIndex((each) => InPeriodTime(each.startDate, each.endDate, toDay));
        const checkMapPeriod = mapPeriod === -1 ? checkPeriodTime.findIndex((each) => ExPeriodTime(each.startDate, each.endDate, toDay)) : mapPeriod;
        const findPeriod = checkPeriodTime.filter((each, index) => index >= checkMapPeriod);
        const price = this.checkPrice(findPeriod);
        const pricePromotion = this.checkPricePromotion(findPeriod);
        const startPeriod = this.checkStartPeriod(findPeriod);
        const endPeriod = this.checkEndPeriod(findPeriod);
        const promotionInfo = path(['0', 'promotionInfo'], findPeriod);
        const promotionStartDate = new Date(path(['travelDate', 'startDate'], promotionInfo));
        const promotionEndDate = new Date(path(['travelDate', 'endDate'], promotionInfo));
        const DatePeriod = startPeriod.map((each) => (new Date(each)).getDate());
        const monthPeriod = startPeriod.map((each) => (new Date(each)).getMonth());
        const yearPeriod = startPeriod.map((each) => (new Date(each)).getFullYear());
        const DatePeriodEnd = endPeriod.map((each) => (new Date(each)).getDate());
        const monthPeriodEnd = endPeriod.map((each) => (new Date(each)).getMonth());
        const yearPeriodEnd = endPeriod.map((each) => (new Date(each)).getFullYear());
        // console.log('startPeriod', DatePeriod, monthPeriod, yearPeriod);
        switch (true) {
            case (date.otherMonth === true): {
                return null;
            }
            case (date.selectable === false): {
                return date.day;
            }
            case (date.year <= yearPeriodEnd[yearPeriodEnd.length - 1]): {
                return (this.ifDateYear(date, month, getToday, DatePeriod, DatePeriodEnd, monthPeriod, monthPeriodEnd, yearPeriod, yearPeriodEnd, pricePromotion, promotionStartDate, promotionEndDate, findPeriod, isPromotion, currency, price));
            }
            default: {
                return date.day;
            }
        }
    }
    dateForm(day, price, currency, isPromotion, pricePromotion) {
        return (<div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{day}</span>
                {(isPromotion) ?
            <span className="promotion">
                        {`${GetSymbol(currency)}${NumberFormatNotFixed(pricePromotion, currency)}`}</span> :
            <span className="price">{`${GetSymbol(currency)}${NumberFormatNotFixed(price, currency)}`}</span>}
            </div>);
    }
    render() {
        const { label, error, errorId, classes, onChange, language, inputProps, getRef, isSearch, checkPeriodTime } = this.props;
        const { textFieldRoot, textFieldSearch, textFieldInput, textFieldSearchMobile } = classes;
        const toDay = new Date();
        const StartDay = new Date(toDay.getTime() + 86400000);
        const findDate = checkPeriodTime === undefined ? null : checkPeriodTime[checkPeriodTime.length - 1];
        const endDate = path(['endDate'], findDate);
        const startDate = path(['0', 'startDate'], this.findStartPeriod);
        // const minDate = Date.parse(toDay) > Date.parse(new Date(startDate)) ? toDay : new Date(startDate);
        this.locale = localeMap[language];
        moment.locale(this.locale);
        const mapPeriod = checkPeriodTime === undefined ? null :
            checkPeriodTime.findIndex((each) => InPeriodTime(each.startDate, each.endDate, toDay));
        const findPeriod = checkPeriodTime === undefined ? null :
            checkPeriodTime.filter((each, index) => index >= mapPeriod);
        const closeList = Array.isArray(path(['0', 'closeListService'], findPeriod)) ?
            path(['0', 'closeListService'], findPeriod) : null;
        const mapCloseList = Array.isArray(closeList) ?
            closeList.map((each) => new Date(each)) : null;
        const year = toDay.getFullYear();
        const month = toDay.getMonth();
        const dayy = toDay.getDate();
        const setYear = new Date(year + 3, month, dayy);
        const soldOutList = path(['soldOutList'], this.props.checkSoldOut);
        const soldOut = Array.isArray(soldOutList) ? soldOutList.map((each) => new Date(each)) : null;
        const arrayPeriodTime = this.mapArrayPeriod();
        const arrayExPeriodTime = this.mapArrayExPeriod();
        const periodTime = Array.isArray(this.periodTime) ? arrayPeriodTime.map((each) => new Date(each)) : null;
        const exPeriodTime = Array.isArray(this.exPeriodTime) ? arrayExPeriodTime.map((each) => new Date(each)) : null;
        const disable = Array.isArray(soldOut) ? Array.isArray(periodTime) ?
            periodTime.concat(soldOut) : soldOut : periodTime;
        const disableDay = Array.isArray(this.exPeriodTime) ? disable !== null ?
            disable.concat(exPeriodTime) : disable : disable;
        const closeDay = Array.isArray(closeList) ? disableDay !== null ?
            disableDay.concat(mapCloseList) : disableDay : disableDay;
        const beforeStartDate = toDay < new Date(`${startDate}`) ? new Date(`${startDate}`) : StartDay;
        return (<Fragment>
                <FieldLabel label={label} error={error}/>
                {(isSearch) ?
            <MuiPickersUtilsProvider utils={MomentUtils} locale={this.locale} moment={moment}>
                            <MDatePicker ref={(node) => { (typeof getRef === 'function') && getRef(node); }} className={classes.root} InputProps={{
                disableUnderline: true,
                classes: {
                    root: textFieldRoot,
                    input: (isSearch) ? ((isMobile) ?
                        textFieldSearchMobile : textFieldSearch) : textFieldInput,
                },
            }} value={this.state.selectedDate} onChange={(val) => {
                this.setState({ selectedDate: val.toISOString() }, () => { onChange(val.toISOString()); });
            }} format={(isSearch) ? format.DATE_INFORMAL : format.DATE} minDate={toDay} maxDate={setYear} {...inputProps}/>
                        </MuiPickersUtilsProvider>
            :
                <Fragment>
                            <Button.Theme label={(this.state.selectedDate !== null) ?
                    moment(this.state.selectedDate).format('D MMMM YYYY') : ''} onClick={() => { this.CalendarModalRef.show(); }} buttonStyle={{
                    borderRadius: '0px',
                    background: '#fff',
                    border: '1px solid #a6a6a6',
                    height: 30,
                    width: '70%',
                    marginTop: '3px',
                }} labelStyle={{ lineHeight: '18px', color: '#000' }} letterSpacing={0} fontSize={isMobile ? 15 : 16}/>
                            <span style={{ marginTop: 10, marginLeft: '15px', cursor: 'pointer' }}>
                                
                                <img src={Images.profile.Calendra} alt='' onClick={() => this.CalendarModalRef.show()}/>
                            </span>
                            <React.Suspense fallback={<Fallback />}>
                                <CustomCalendar modalRef={(ref) => this.CalendarModalRef = ref} minDate={beforeStartDate} maxDate={new Date(`${endDate}`)} dateTemplate={this.dateTemplate} disabledDates={closeDay} numberOfMonths={2} locales={this.locale} language={language} value={this.state.selectedDate} onChange={(val) => {
                    this.setState({ selectedDate: val.toISOString() }, () => { onChange(val.toISOString()); });
                }} hideDialog={() => { this.CalendarModalRef.hide(); }}/>
                            </React.Suspense>
                        </Fragment>}
                <FieldError error={error} errorId={errorId}/>
            </Fragment>);
    }
    ifPeriodTime(date, monthPeriod, monthPeriodEnd, DatePeriod, DatePeriodEnd, price, dateForm, length, currency) {
        let i = 0;
        while (i < length) {
            i++;
            switch (true) {
                case date.month === monthPeriod[i]: {
                    if (date.day >= DatePeriod[i]) {
                        return (dateForm(date.day, price[i], currency));
                    }
                    else {
                        return (dateForm(date.day, price[i], currency));
                    }
                }
                case date.month < monthPeriodEnd[i] && date.month > monthPeriod[i]: {
                    return (dateForm(date.day, price[i], currency));
                }
                case date.month === monthPeriodEnd[i]: {
                    if (date.day <= DatePeriodEnd[i]) {
                        return (dateForm(date.day, price[i], currency));
                    }
                    else {
                        return date.day;
                    }
                }
                default: {
                    if (date.month < monthPeriod[i]) {
                        return (dateForm(date.day, price[0], currency));
                    }
                    else {
                        return date.day;
                    }
                }
            }
        }
    }
    promotion(date, promotionStartDate, promotionEndDate, price, isPromotion, pricePromotion, currency) {
        switch (true) {
            case (date.year === promotionEndDate.getFullYear()): {
                if (date.month === promotionEndDate.getMonth() && date.day <= promotionEndDate.getDate()) {
                    return (this.dateForm(date.day, price, currency, isPromotion, pricePromotion));
                }
                else {
                    if (date.month < promotionEndDate.getMonth()) {
                        return (this.dateForm(date.day, price, currency, isPromotion, pricePromotion));
                    }
                    else {
                        return (this.dateForm(date.day, price, currency));
                    }
                }
            }
            case (date.year < promotionEndDate.getFullYear()): {
                if (date.month > promotionStartDate.getMonth()) {
                    return (this.dateForm(date.day, price, currency, isPromotion, pricePromotion));
                }
                else {
                    if (date.month === promotionStartDate.getMonth() && date.day >= promotionStartDate.getDate() && date.year < promotionEndDate.getFullYear()) {
                        return (this.dateForm(date.day, price, currency, isPromotion, pricePromotion));
                    }
                }
            }
            default: {
                return (this.dateForm(date.day, price, currency));
            }
        }
    }
    ifPromotion(date, DatePeriod, monthPeriod, yearPeriod, yearPeriodEnd, promotionStartDate, promotionEndDate, price, isPromotion, pricePromotion, currency) {
        switch (true) {
            case (date.year <= yearPeriodEnd[yearPeriodEnd.length - 1] && date.year >= yearPeriod[0]): {
                return this.promotion(date, promotionStartDate, promotionEndDate, price, isPromotion, pricePromotion, currency);
            }
            default: {
                return (this.dateForm(date.day, price, currency));
            }
        }
    }
    ifDateYear(date, month, getToday, DatePeriod, DatePeriodEnd, monthPeriod, monthPeriodEnd, yearPeriod, yearPeriodEnd, pricePromotion, promotionStartDate, promotionEndDate, findPeriod, isPromotion, currency, price) {
        if (isPromotion !== true) {
            if (date.month === month && date.day > getToday) {
                if (findPeriod.length > 1) {
                    return this.ifPeriodTime(date, monthPeriod, monthPeriodEnd, DatePeriod, DatePeriodEnd, price, this.dateForm, findPeriod.length, currency);
                }
                else {
                    return (this.dateForm(date.day, price[0], currency));
                }
            }
            else {
                if (findPeriod.length > 1) {
                    return this.ifPeriodTime(date, monthPeriod, monthPeriodEnd, DatePeriod, DatePeriodEnd, price, this.dateForm, findPeriod.length, currency);
                }
                else {
                    return (this.dateForm(date.day, price[0], currency));
                }
            }
        }
        else {
            return this.ifPromotion(date, DatePeriod, monthPeriod, yearPeriod, yearPeriodEnd, promotionStartDate, promotionEndDate, price[0], isPromotion, pricePromotion[0], currency);
        }
    }
}
const styles = (theme) => ({
    root: { width: '100%' },
    textFieldRoot: { "padding": 0, 'label + &': { marginTop: theme.spacing.unit * 2.2 } },
    textFieldInput: {
        width: '100%',
        borderRadius: 10,
        border: '1px solid #5B5B5B',
        fontSize: 16,
        padding: '10px 15px',
        cursor: 'pointer',
    },
    textFieldSearch: {
        width: '100%',
        height: '40px',
        borderRadius: 0,
        fontSize: 16,
        padding: '10px 15px',
        cursor: 'pointer',
    },
    textFieldSearchMobile: {
        width: '100%',
        height: '40px',
        borderRadius: 0,
        fontSize: 14,
        padding: '10px 5px',
        cursor: 'pointer',
    },
});
export default withStyles(styles)(CustomDatePicker);
// ========================================================================================
