import React from 'react';
import { connect } from "react-redux";
import { path } from "ramda";
import Panel, { PanelHeader, PanelContent } from "../Panel";
import { Form } from '../form';
import formtypes from '../../formtypes';
import { reduxKeys, packageType } from "../../constant";
import { optionsServices } from "../../services";
import { Colors } from "../../themes";
import localization from "../../services/localization";
const AdditionalInfo = (props) => {
    const { formRef, translateTransferIn, getPackageType, defaultValues, isMobile, appointLocation, isPickUp, isDropOff, pickUpList, dropOffList, transferIn, isFerries, getCustomerRequestOptions, } = props;
    const appointmentOptions = optionsServices.getAppointmentOptions();
    const customerRequestOptions = Array.isArray(optionsServices.getCustomerRequestOptions()) ?
        optionsServices.getCustomerRequestOptions() : getCustomerRequestOptions;
    const pickUpListOptions = appointmentOptions.filter((items) => pickUpList.some((each) => each === items.id)) || null;
    const dropOffListOptions = appointmentOptions.filter((items) => dropOffList.some((each) => each === items.id)) || null;
    const appointmentLocationOptions = appointmentOptions.filter((items) => path(['0', 'code'], appointLocation) === items.province);
    const appointmentLocationOneDay = appointmentLocationOptions.filter((items) => items.routingType.some((fil) => transferIn === false ?
        fil === 'airPort' : fil !== 'airPort')).map((each) => each);
    return (<Panel>
            <PanelHeader style={{
        backgroundColor: Colors.panel.header,
        padding: isMobile ? 0 : '0 0 0 40px',
        justifyContent: isMobile ? 'center' : 'flex-start',
    }}>
                {localization(`additional_information`, `BookingLabel`)}
            </PanelHeader>
            <PanelContent style={{ backgroundColor: Colors.panel.content }}>
                <Form form={getPackageType() === packageType.OneDayTrip
        ?
            formtypes.additionGuestInfo.oneDayTripForm({
                appointmentLocationOneDay,
                customerRequestOptions,
                isMobile,
                isPickUp,
                pickUpListOptions,
            })
        :
            isFerries === 'ferries' ?
                formtypes.additionGuestInfo.ferriesForm({
                    appointmentLocationOptions,
                    appointmentLocationOneDay,
                    selectedTransferType: translateTransferIn(),
                    defaultValues,
                    isMobile,
                    isPickUp,
                    isDropOff,
                    pickUpListOptions,
                    dropOffListOptions,
                    transferIn,
                })
                :
                    formtypes.additionGuestInfo.transferForm({
                        appointmentLocationOptions,
                        appointmentLocationOneDay,
                        selectedTransferType: translateTransferIn(),
                        defaultValues,
                        isMobile,
                        isPickUp,
                        isDropOff,
                        pickUpListOptions,
                        dropOffListOptions,
                        transferIn,
                    })} ref={(ref) => {
        if (typeof formRef === 'function') {
            formRef(ref);
        }
    }}/>
            </PanelContent>
        </Panel>);
};
const mapStateToProps = (state) => ({
    [reduxKeys.booking]: state[reduxKeys.booking],
    [reduxKeys.other]: state[reduxKeys.other],
});
export default connect(mapStateToProps)(AdditionalInfo);
