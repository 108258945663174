// import React from "react";
// import { Colors } from "../../themes";
import { StepPanel_OT_EN_0 } from "./staps/Mobile/StepPanel_OT_EN_0";
import { StepPanel_OT_EN_1 } from "./staps/Mobile/StepPanel_OT_EN_1";
import { StepPanel_OT_EN_2 } from "./staps/Mobile/StepPanel_OT_EN_2";
import { StepPanel_OT_EN_3 } from "./staps/Mobile/StepPanel_OT_EN_3";
import { StepPanel_OT_TH_0 } from "./staps/Mobile/StepPanel_OT_TH_0";
import { StepPanel_OT_TH_1 } from "./staps/Mobile/StepPanel_OT_TH_1";
import { StepPanel_OT_TH_2 } from "./staps/Mobile/StepPanel_OT_TH_2";
import { StepPanel_OT_TH_3 } from "./staps/Mobile/StepPanel_OT_TH_3";
import { StepPanel_TF_EN_0 } from "./staps/Mobile/StepPanel_TF_EN_0";
import { StepPanel_TF_EN_1 } from "./staps/Mobile/StepPanel_TF_EN_1";
import { StepPanel_TF_EN_2 } from "./staps/Mobile/StepPanel_TF_EN_2";
import { StepPanel_TF_EN_3 } from "./staps/Mobile/StepPanel_TF_EN_3";
import { StepPanel_TF_TH_0 } from "./staps/Mobile/StepPanel_TF_TH_0";
import { StepPanel_TF_TH_1 } from "./staps/Mobile/StepPanel_TF_TH_1";
import { StepPanel_TF_TH_2 } from "./staps/Mobile/StepPanel_TF_TH_2";
import { StepPanel_TF_TH_3 } from "./staps/Mobile/StepPanel_TF_TH_3";
import { StepPanel_OT_EN_0 as StepPanel_DT_OT_EN_0 } from "./staps/Desktop/StepPanel_OT_EN_0";
import { StepPanel_OT_EN_1 as StepPanel_DT_OT_EN_1 } from "./staps/Desktop/StepPanel_OT_EN_1";
import { StepPanel_OT_EN_2 as StepPanel_DT_OT_EN_2 } from "./staps/Desktop/StepPanel_OT_EN_2";
import { StepPanel_OT_EN_3 as StepPanel_DT_OT_EN_3 } from "./staps/Desktop/StepPanel_OT_EN_3";
import { StepPanel_OT_TH_0 as StepPanel_DT_OT_TH_0 } from "./staps/Desktop/StepPanel_OT_TH_0";
import { StepPanel_OT_TH_1 as StepPanel_DT_OT_TH_1 } from "./staps/Desktop/StepPanel_OT_TH_1";
import { StepPanel_OT_TH_2 as StepPanel_DT_OT_TH_2 } from "./staps/Desktop/StepPanel_OT_TH_2";
import { StepPanel_OT_TH_3 as StepPanel_DT_OT_TH_3 } from "./staps/Desktop/StepPanel_OT_TH_3";
import { StepPanel_TF_EN_0 as StepPanel_DT_TF_EN_0 } from "./staps/Desktop/StepPanel_TF_EN_0";
import { StepPanel_TF_EN_1 as StepPanel_DT_TF_EN_1 } from "./staps/Desktop/StepPanel_TF_EN_1";
import { StepPanel_TF_EN_2 as StepPanel_DT_TF_EN_2 } from "./staps/Desktop/StepPanel_TF_EN_2";
import { StepPanel_TF_EN_3 as StepPanel_DT_TF_EN_3 } from "./staps/Desktop/StepPanel_TF_EN_3";
import { StepPanel_TF_TH_0 as StepPanel_DT_TF_TH_0 } from "./staps/Desktop/StepPanel_TF_TH_0";
import { StepPanel_TF_TH_1 as StepPanel_DT_TF_TH_1 } from "./staps/Desktop/StepPanel_TF_TH_1";
import { StepPanel_TF_TH_2 as StepPanel_DT_TF_TH_2 } from "./staps/Desktop/StepPanel_TF_TH_2";
import { StepPanel_TF_TH_3 as StepPanel_DT_TF_TH_3 } from "./staps/Desktop/StepPanel_TF_TH_3";
export default {
    StepPanel_DT_OT_EN_0,
    StepPanel_DT_OT_EN_1,
    StepPanel_DT_OT_EN_2,
    StepPanel_DT_OT_EN_3,
    StepPanel_DT_OT_TH_0,
    StepPanel_DT_OT_TH_1,
    StepPanel_DT_OT_TH_2,
    StepPanel_DT_OT_TH_3,
    StepPanel_DT_TF_EN_0,
    StepPanel_DT_TF_EN_1,
    StepPanel_DT_TF_EN_2,
    StepPanel_DT_TF_EN_3,
    StepPanel_DT_TF_TH_0,
    StepPanel_DT_TF_TH_1,
    StepPanel_DT_TF_TH_2,
    StepPanel_DT_TF_TH_3,
    StepPanel_OT_EN_0,
    StepPanel_OT_EN_1,
    StepPanel_OT_EN_2,
    StepPanel_OT_EN_3,
    StepPanel_OT_TH_0,
    StepPanel_OT_TH_1,
    StepPanel_OT_TH_2,
    StepPanel_OT_TH_3,
    StepPanel_TF_TH_0,
    StepPanel_TF_TH_1,
    StepPanel_TF_TH_2,
    StepPanel_TF_TH_3,
    StepPanel_TF_EN_0,
    StepPanel_TF_EN_1,
    StepPanel_TF_EN_2,
    StepPanel_TF_EN_3,
};
