export const defaultRange = { min: 0, max: 50000 };
export var ConveyanceType;
(function (ConveyanceType) {
    ConveyanceType["Ferries"] = "ferries";
    ConveyanceType["Van"] = "van";
    ConveyanceType["Bus"] = "bus";
    ConveyanceType["Camry"] = "camry";
    ConveyanceType["Hundai"] = "hundai";
})(ConveyanceType || (ConveyanceType = {}));
export var FilterType;
(function (FilterType) {
    FilterType["Destinations"] = "Destinations";
    FilterType["Appointments"] = "Appointments";
    FilterType["Cartype"] = "ConveyanceType";
    FilterType["Range"] = "PriceRange";
    FilterType["Special"] = "Special";
    FilterType["Star"] = "ReviewStars";
    FilterType["Activities"] = "Activities";
})(FilterType || (FilterType = {}));
;
export var Keyword;
(function (Keyword) {
    Keyword["searchType"] = "searchType";
    Keyword["destination"] = "destination";
    Keyword["activity"] = "activity";
    Keyword["date"] = "date";
    Keyword["dropOff"] = "dropOff";
    Keyword["passenger"] = "passenger";
    Keyword["pickUp"] = "pickUp";
})(Keyword || (Keyword = {}));
export const SpecialList = [
    { name: 'Special Promotion', status: 'isPromotion' },
    { name: 'New Attractions', status: 'isAttractions' },
    { name: 'Recommend Trip', status: 'isRecommends' },
];
export const ConveyanceTypeList = [
    { name: 'Ferries', value: ConveyanceType.Ferries },
    { name: 'Van', value: ConveyanceType.Van },
    { name: 'Bus', value: ConveyanceType.Bus },
    { name: 'Camry', value: ConveyanceType.Camry },
    { name: 'Hundai', value: ConveyanceType.Hundai }
];
export const defaultPanelDispaly = {
    [FilterType.Activities]: false,
    [FilterType.Appointments]: false,
    [FilterType.Destinations]: false,
    [FilterType.Cartype]: false,
    [FilterType.Range]: false,
    [FilterType.Star]: false,
    [FilterType.Special]: false,
};
let Fliter_Range = defaultRange;
let Fliter_Activities = [];
let Fliter_Destinations = [];
let Fliter_Appointments = [];
let Fliter_Cartype = [];
let Fliter_Special = [];
let Fliter_Start = [];
let Fliter_Passenger = 1;
export const FliterList = {
    Fliter_Range,
    Fliter_Activities,
    Fliter_Destinations,
    Fliter_Appointments,
    Fliter_Cartype,
    Fliter_Special,
    Fliter_Start,
    Fliter_Passenger,
};
