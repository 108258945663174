export default {
    info_confirm_payment: 'Are you sure you want to confirm this payment?',
    confirm_discard: 'Do you want to discard?',
    confirm_yes: 'Yes',
    confirm_no: 'No',
    loading_user: 'Getting user data...',
    loading_login: 'Logging in...',
    loading_please_wait: 'Please wait...',
    question_title_register: `You doesn't register yet.`,
    question_content_regiser: `Please fill all required fields.`,
    question_content_regiser_fail: 'To ensure that our mailing list contains only those who wish to be on it, we have sent a verify link to your email address. Please log in to your email account and find the email we have just sent you. It may be in your Spam/Bulk/Junk folder. To complete the process of being added to our mailing list, just click the link in that email.',
    info_title_register_success: 'Register successfully',
    info_content_register_success: 'Please verify your email.',
    info_title_forgot_password_success: 'Rocovery password successfully',
    info_content_forgot_password_success: 'An email will be sent to you in 2-3 minutes.',
    info_title_update_profile_success: 'Update profile successfully',
    info_title_change_password_success: 'Change password successfully',
    info_title_confirm_payment_success: 'Confirm payment successfully',
    info_title_request_affiliate_id_success: 'Request affiliate id successfully',
    info_title_create_review_success: 'Review successfully',
    info_title_get_promotion_code_success: `Congratulation !`,
    info_subtitle_get_promotion_code_success: 'You get best rates only at TravisGO',
    info_title_get_promotion_code_fail: 'This code is invalid or has expired',
    ok: 'OK',
    cancel: 'CANCEL',
    please_accept_terms_and_conditions: 'Please accept terms and conditions',
    save_credit_card: 'Do you want to save this credit card information?',
};
