import * as React from "react";
import { Colors } from "../../themes";
const Bullet = ({ size = 12, color = Colors.grayScale["35"], style = {} }) => (<div style={{
    width: size, height: size,
    backgroundColor: color, borderRadius: '50%',
    marginBottom: 8,
    marginRight: 13,
    ...style,
}}/>);
export default Bullet;
