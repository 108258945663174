import { path } from "ramda";
import { reduxKeys } from "../constant";
import Store from "../redux";
import localization from "./localization";
import { clearStore } from "../sagas/ManageStoreSaga";
export default (res) => {
    const { [reduxKeys.settings]: { language }, [reduxKeys.error]: { errorCodes } } = Store.getState();
    if (path(['problem'], res) === 'TIMEOUT_ERROR') {
        const error = {
            title: `${localization('error_title_connection')}`,
            content: `${localization('error_content_connection')}`,
        };
        return error;
    }
    else {
        const statusCode = path(['status'], res);
        if (statusCode === 498) {
            clearStore();
        }
        let title;
        if (statusCode !== 498) {
            title = path([statusCode, language.toUpperCase()], errorCodes);
            // if (!title) {
            //     // title = localization('default_error');
            // }
        }
        return {
            title,
        };
    }
};
