import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/styles';
import { equals } from "ramda";
import Button from '@material-ui/core/Button';
import FieldLabel from "./FieldLabel";
import FieldError from "./FieldError";
import { Colors } from "../../themes";
const styles = () => ({
    root: {
        minWidth: 0,
        height: '40px',
        width: '60px',
        backgroundColor: '#fff',
    },
});
class Radio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.value || null,
        };
        this.onSelected = this.onSelected.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.value, this.state.selected)) {
            this.setState({
                selected: nextProps.value,
            });
        }
    }
    onSelected(selected) {
        this.setState({ selected }, () => {
            if (typeof this.props.onChange === 'function') {
                this.props.onChange(selected);
                if (typeof this.props.validate === 'function') {
                    this.props.validate(selected);
                }
            }
        });
    }
    render() {
        const { options, label, error, errorId = 'blank', require, } = this.props;
        return (<Fragment>
                <FieldLabel label={label} error={error} require={require}/>
                <div style={{
            display: 'inline-block',
            border: `1px solid ${error ? Colors.error : Colors.input.border}`,
        }}>
                    {Array.isArray(options) && options.map((each, index) => {
            return (<Button onClick={() => {
                this.onSelected(each.id);
            }} key={index} classes={{
                root: this.props.classes.root,
            }} style={{
                borderRight: index !== options.length - 1 ?
                    `1px solid ${Colors.input.border}`
                    :
                        '',
                backgroundColor: each.id === this.state.selected ?
                    '#e2e2e2'
                    :
                        '',
                boxShadow: each.id === this.state.selected ?
                    'inset 0 1px 2px 0 rgba(0,0,0,0.2)'
                    :
                        '',
                borderRadius: 0,
            }}>
                                    {each.label}
                                </Button>);
        })}
                </div>
                <FieldError error={error} errorId={errorId}/>
            </Fragment>);
    }
}
export default withStyles(styles)(Radio);
